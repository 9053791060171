import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { object, string } from 'yup';
import { Formik, FormikHelpers, Field, FormikProps } from 'formik';
import { Button, Grid } from '@inplayer-org/inplayer-ui';

// Components
import FieldError from 'components/Fields/FieldError';
import Input from 'components/Fields/Input';
import AuthFormContainer from 'components/AuthFormContainer';
import { BlueTypography } from 'components/SharedStyledElements';

const { Container, Cell } = Grid;

export interface ActivationCodeFormValues {
  activationCode: string;
}

interface OwnProps {
  onSubmit: (data: ActivationCodeFormValues) => any;
}

const validationSchema = object().shape({
  activationCode: string().required('*Required'),
});

type Props = OwnProps;

export const ActivateAccountForm: FunctionComponent<Props> = ({ onSubmit }) => {
  const initialValues = {
    activationCode: '',
  };

  const onFormSubmit = async (
    values: ActivationCodeFormValues,
    { setSubmitting }: FormikHelpers<ActivationCodeFormValues>
  ) => {
    await onSubmit(values);
    setSubmitting(false);
  };

  return (
    <Formik onSubmit={onFormSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ handleSubmit, isSubmitting }: FormikProps<ActivationCodeFormValues>) => (
        <AuthFormContainer
          title="Activate your merchant account"
          description="Please check your email for the confirmation code. InPlayer will send the confirmation code on the email
              address you&#39;ve provided for your merchant account. Simply copy and paste it in the edit box and press
              &#34;Activate Account&#34; button to finalize the activation process."
        >
          <form onSubmit={handleSubmit}>
            <Container alignContent="space-around" columns={1} gap="1em">
              <Cell center top={2}>
                <BlueTypography variant="h6">Activation code</BlueTypography>
              </Cell>
              <Cell top={3}>
                <Field name="activationCode" type="text" component={Input} placeholder="Activation code" />
                <FieldError name="activationCode" />
              </Cell>
              <Cell center top={4}>
                <NavLink to="/resend">(resend code)</NavLink>
              </Cell>
              <Cell top={5}>
                <Button fullHeight fullWidth buttonModifiers={['buttonPrimary']} type="submit" disabled={isSubmitting}>
                  Activate account
                </Button>
              </Cell>
            </Container>
          </form>
        </AuthFormContainer>
      )}
    </Formik>
  );
};

export default ActivateAccountForm;
