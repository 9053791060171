import { createGlobalStyle } from '@styled';

const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
    font-family: Roboto, 'sans-serif';
  }

  * {
    box-sizing: border-box;
  }

  html, head, body, div, h1, h2, h3, h4, h5, h6, p, ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
  }

  #ipx-tophead {
    background: rgba(0,62,105,1);
    margin: 0;
    position: relative;
  }

  #ipx-tophead * {
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
    text-decoration: none;
    transition: all 300ms ease;
  }

  p, a, em, li {
    line-height: 1.6em;
  }

  #root {
    height: 100%;
  }
`;

export default GlobalStyles;
