import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { API } from 'constants/api';
import http, { ContentType } from 'utils/http';
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';
import RootState from 'ducks/RootState';

// Selected webhooks list data
export enum WebhooksActionTypes {
  START = 'WEBHOOKS_REQUEST',
  SUCCESS = 'WEBHOOKS_SUCCESS',
  FAILURE = 'WEBHOOKS_FAILURE',
}

type LoadWebhooksAction = Action<WebhooksActionTypes>;

export const loadWebhooks = (): ThunkAction<Promise<any>, RootState, any, LoadWebhooksAction> => async (dispatch) => {
  const endpoint = API.HOOKS;

  try {
    dispatch({ type: WebhooksActionTypes.START });
    const response = await http.authenticated().get(endpoint, {});
    dispatch({
      type: WebhooksActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: WebhooksActionTypes.FAILURE });
  }
};

// List all available labels
export enum LabelsActionTypes {
  START = 'LABELS_REQUEST',
  SUCCESS = 'LABELS_SUCCESS',
  FAILURE = 'LABELS_FAILURE',
}

type LoadLabelsAction = Action<LabelsActionTypes>;

export const loadLabels = (): ThunkAction<Promise<any>, RootState, any, LoadLabelsAction> => async (
  dispatch,
  getState
) => {
  const endpoint = API.HOOKS;
  const headers = {
    'Content-Type': ContentType.URLENCODED,
  };

  const {
    webhooks: { labels },
  } = getState();

  if (isEmpty(labels)) {
    try {
      dispatch({ type: LabelsActionTypes.START });
      const response = await http.authenticated().get(endpoint, { headers });
      dispatch({ type: LabelsActionTypes.SUCCESS, payload: { ...response.data } });
    } catch (_) {
      dispatch({ type: LabelsActionTypes.FAILURE });
    }
  }
};

// Save webhook action
export enum SaveWebhookActionTypes {
  START = 'SAVE_WEBHOOK_REQUEST',
  SUCCESS = 'SAVE_WEBHOOK_SUCCESS',
  FAILURE = 'SAVE_WEBHOOK_FAILURE',
}

type SaveWebhookAction = Action<SaveWebhookActionTypes | NotificationActionTypes>;

export const createWebhook = (
  endpointApi: string,
  events: Array<string>
): ThunkAction<Promise<any>, RootState, any, SaveWebhookAction> => async (dispatch) => {
  const endpoint = API.HOOKS;
  const data = { endpoint: endpointApi, events: { ...events } };

  try {
    dispatch({ type: SaveWebhookActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: SaveWebhookActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Webhook successfully created.',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
    dispatch({ type: SaveWebhookActionTypes.FAILURE });
  }
};

// Update webhook
export enum UpdateWebhookActionTypes {
  START = 'UPDATE_WEBHOOK_REQUEST',
  SUCCESS = 'UPDATE_WEBHOOK_SUCCESS',
  FAILURE = 'UPDATE_WEBHOOK_FAILURE',
}

type UpdateWebhookAction = Action<UpdateWebhookActionTypes | NotificationActionTypes>;

export const updateWebhook = (
  id: number | string,
  endpointApi: string,
  events: Array<string>
): ThunkAction<Promise<any>, RootState, any, UpdateWebhookAction> => async (dispatch) => {
  const endpoint = API.HOOKS_DETAILS;

  // uniqEvents is added to remove the duplicated events on update if there are existing events
  const uniqEvents = uniq(events);
  const data = { endpoint: endpointApi, events: { ...uniqEvents } };

  try {
    dispatch({ type: UpdateWebhookActionTypes.START });

    const response = await http.authenticated().put(endpoint, {
      data,
      pathVariables: {
        id,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: UpdateWebhookActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(notifRequested({ type: 'success', title: 'SUCCESS', content: 'Webhook successfully updated.' }));
  } catch (e) {
    const { response } = e;
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: response.data.message,
      })
    );
    dispatch({ type: UpdateWebhookActionTypes.FAILURE });
  }
};

// Delete webhooks
export enum DeleteWebhookActionTypes {
  START = 'DELETE_WEBHOOK_REQUEST',
  SUCCESS = 'DELETE_WEBHOOK_SUCCESS',
  FAILURE = 'DELETE_WEBHOOK_FAILURE',
}

type DeleteWebhookAction = Action<DeleteWebhookActionTypes | NotificationActionTypes>;

export const removeWebhook = (id: number): ThunkAction<Promise<any>, RootState, any, DeleteWebhookAction> => async (
  dispatch
) => {
  const endpoint = API.HOOKS_DETAILS;
  const successData = { id };

  try {
    dispatch({ type: DeleteWebhookActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: { id },
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: DeleteWebhookActionTypes.SUCCESS, payload: { ...successData, ...response.data } });
    dispatch(notifRequested({ title: 'SUCCESS', content: 'Webhook was successfully deleted.', type: 'success' }));
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: DeleteWebhookActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Delete all webhooks
export enum DeleteAllWebhooksActionTypes {
  START = 'DELETE_ALL_WEBHOOKS_REQUEST',
  SUCCESS = 'DELETE_ALL_WEBHOOKS_SUCCESS',
  FAILURE = 'DELETE_ALL_WEBHOOKS_FAILURE',
}

type deleteAllWebhooksAction = Action<DeleteAllWebhooksActionTypes | NotificationActionTypes>;

export const removeAllWebhooks = (): ThunkAction<Promise<any>, RootState, any, deleteAllWebhooksAction> => async (
  dispatch
) => {
  const endpoint = API.HOOKS;

  try {
    dispatch({ type: DeleteAllWebhooksActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: DeleteAllWebhooksActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(notifRequested({ title: 'SUCCESS', content: 'All webhooks were successfully deleted.', type: 'success' }));
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: DeleteAllWebhooksActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Load webhook details
export enum WebhookDetailsActionTypes {
  START = 'WEBHOOK_DETAILS_REQUEST',
  SUCCESS = 'WEBHOOK_DETAILS_SUCCESS',
  FAILURE = 'WEBHOOK_DETAILS_FAILURE',
}

type LoadWebhooksDetailsAction = Action<WebhookDetailsActionTypes>;

export const loadWebhookDetails = (
  id: number | string
): ThunkAction<Promise<any>, RootState, any, LoadWebhooksDetailsAction> => async (dispatch) => {
  const endpoint = API.HOOKS_DETAILS;

  try {
    dispatch({ type: WebhookDetailsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        id,
      },
    });
    dispatch({ type: WebhookDetailsActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: WebhookDetailsActionTypes.FAILURE });
  }
};

// Rest webhook details
export const RESET_WEBHOOK_DETAILS = 'RESET_WEBHOOK_DETAILS';

export const resetWebhookDetails = () => ({
  type: RESET_WEBHOOK_DETAILS,
});
