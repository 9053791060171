import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@inplayer-org/inplayer-ui';

// components
import ThemedMessage, { MessageVariants } from 'components/ThemedMessage';
import ButtonLink from 'components/ButtonLink';

// styled components
import { StyledTypography } from 'pages/MerchantPages/Settings/Integrations/ThirdPartyIntegrations/ThirdPartyIntegration';

// hooks
import { useUncontrolledSwitch } from 'components/Switcher';

// actions
import { createLiveLikeAccount } from 'ducks/merchantDucks/externalAccount/actions';

// local types
import { SwitchableStates } from './MainContainer';

const UnconnectedSection: FC = () => {
  const dispatch = useDispatch();

  const { stateSwitcher } = useUncontrolledSwitch();

  const handleCreateAccount = useCallback(() => {
    dispatch(createLiveLikeAccount());
  }, []);

  return (
    <>
      <ThemedMessage variant={MessageVariants.WARNING}>
        You are currently not connected to a LiveLike account. By using this feature you&apos;ll incur charges.
      </ThemedMessage>
      <StyledTypography variant="p">
        By clicking the button below you permit InPlayer to create a LiveLike sub-account for you and you will be
        automatically connected to the LiveLike chat and be able to enable it for your assets.
      </StyledTypography>
      <StyledTypography variant="p">
        <Button tag="button_create_account" onClick={handleCreateAccount}>
          Create Account
        </Button>
      </StyledTypography>
      <StyledTypography variant="p">
        Already have a LiveLike account? &nbsp;
        <ButtonLink
          tag="button_link_connect_existing_account"
          onClick={stateSwitcher(SwitchableStates.CONNECT_EXISTING_ACCOUNT)}
        >
          Connect
        </ButtonLink>
      </StyledTypography>
    </>
  );
};

export default UnconnectedSection;
