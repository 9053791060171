import { Reducer } from 'redux';
import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import moment from 'moment';
import { DatePickerTypes } from './actions';

export const initialState: any = {
  startDate: moment().subtract(1, 'months'),
  endDate: moment(),
};

type DatePickerReducer = Reducer<any>;

const saveDatePickerPeriod: DatePickerReducer = (state = initialState, { payload }) => {
  return {
    startDate: payload.startDate,
    endDate: payload.endDate,
  };
};

const actionsMap: ActionMap<any> = {
  [DatePickerTypes.SAVE_DATEPICKER_PERIOD]: saveDatePickerPeriod,
};

export default reducerWithActionMap(actionsMap, initialState);
