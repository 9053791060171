import React from 'react';
import styled from 'styled-components';
import { Colors } from '@inplayer-org/inplayer-ui';
import logo from 'assets/pics/icons/inplayer-logo-white.svg';

export const StyledHeader = styled.div`
  width: 100%;
  background: white;
  box-shadow: 0 1px 15px ${Colors.gray};
`;

export const StyledInplayerLogo = styled.img`
  width: 100px;
  margin: 2.25rem 0rem 1rem 10%;
`;

const Header = () => {
  return (
    <StyledHeader>
      <StyledInplayerLogo src={logo} alt="inplayer-logo" title="InPlayer" />
    </StyledHeader>
  );
};

export default Header;
