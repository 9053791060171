import { Reducer } from 'redux';
import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import find from 'lodash/find';
import reduce from 'lodash/reduce';

import {
  LoadGoogleInAppKeysActionTypes,
  LoadAppleInAppKeysActionTypes,
  LoadAmazonInAppKeysActionTypes,
  LoadRokuInAppKeysActionTypes,
  LoadPayPalKeysActionTypes,
} from 'ducks/merchantDucks/inAppIntegrations/actions';
import InAppIntegrationsState from 'ducks/merchantDucks/inAppIntegrations/types';

export const initialState: InAppIntegrationsState = {
  googleInAppKeys: {},
  paypalKeys: {},
  isFetching: false,
};

type InAppIntegrationReducer = Reducer<InAppIntegrationsState>;

const loadGoogleInAppKeysSuccess: InAppIntegrationReducer = (
  state = initialState,
  { payload: { playstore_public_key, site_token } }
) => {
  return {
    ...state,
    googleInAppKeys: {
      playstore_public_key,
      site_token,
    },
    isFetching: false,
  };
};

const loadGoogleInAppKeysFailure: InAppIntegrationReducer = (state = initialState) => {
  return {
    ...state,
    googleInAppKeys: {},
    isFetching: false,
  };
};

const loadGoogleInAppKeysStart: InAppIntegrationReducer = (state = initialState) => {
  return {
    ...state,
    googleInAppKeys: {},
    isFetching: true,
  };
};

const loadAppleInAppKeysSuccess: InAppIntegrationReducer = (state = initialState, { payload }) => {
  const appleInAppKeys = find(payload, { name: 'secretKey' });
  return {
    ...state,
    appleInAppKeys,
    isFetching: false,
  };
};

const loadAppleInAppKeysFailure: InAppIntegrationReducer = (state = initialState) => {
  return {
    ...state,
    appleInAppKeys: {},
    isFetching: false,
  };
};

const loadAppleInAppKeysStart: InAppIntegrationReducer = (state = initialState) => {
  return {
    ...state,
    appleInAppKeys: {},
    isFetching: true,
  };
};

const loadAmazonInAppKeysSuccess: InAppIntegrationReducer = (state = initialState, { payload }) => {
  const amazonInAppKeys = find(payload, { name: 'secretKey' });
  return {
    ...state,
    amazonInAppKeys,
    isFetching: false,
  };
};

const loadAmazonInAppKeysFailure: InAppIntegrationReducer = (state = initialState) => {
  return {
    ...state,
    amazonInAppKeys: {},
    isFetching: false,
  };
};

const loadAmazonInAppKeysStart: InAppIntegrationReducer = (state = initialState) => {
  return {
    ...state,
    amazonInAppKeys: {},
    isFetching: true,
  };
};

const loadRokuInAppKeysSuccess: InAppIntegrationReducer = (state = initialState, { payload }) => {
  const rokuInAppKeys = reduce(
    payload,
    (acc, item) => {
      acc[item.name] = item.value;
      return acc;
    },
    {}
  );
  return {
    ...state,
    rokuInAppKeys,
    isFetching: false,
  };
};

const loadRokuInAppKeysFailure: InAppIntegrationReducer = (state = initialState) => {
  return {
    ...state,
    rokuInAppKeys: {},
    isFetching: false,
  };
};

const loadRokuInAppKeysStart: InAppIntegrationReducer = (state = initialState) => {
  return {
    ...state,
    rokuInAppKeys: {},
    isFetching: true,
  };
};

const loadPaypalKeysSuccess: InAppIntegrationReducer = (state = initialState, { payload }) => {
  const paypalKeys = reduce(
    payload,
    (acc, item) => {
      acc[item.name] = item.value;
      return acc;
    },
    {}
  );
  return {
    ...state,
    paypalKeys,
    isFetching: false,
  };
};

const loadPaypalKeysFailure: InAppIntegrationReducer = (state = initialState) => {
  return {
    ...state,
    paypalKeys: {},
    isFetching: false,
  };
};

const loadPaypalKeysStart: InAppIntegrationReducer = (state = initialState) => {
  return {
    ...state,
    paypalKeys: {},
    isFetching: true,
  };
};

const actionsMap: ActionMap<InAppIntegrationsState> = {
  [LoadGoogleInAppKeysActionTypes.SUCCESS]: loadGoogleInAppKeysSuccess,
  [LoadGoogleInAppKeysActionTypes.FAILURE]: loadGoogleInAppKeysFailure,
  [LoadGoogleInAppKeysActionTypes.START]: loadGoogleInAppKeysStart,
  [LoadAppleInAppKeysActionTypes.SUCCESS]: loadAppleInAppKeysSuccess,
  [LoadAppleInAppKeysActionTypes.FAILURE]: loadAppleInAppKeysFailure,
  [LoadAppleInAppKeysActionTypes.START]: loadAppleInAppKeysStart,
  [LoadAmazonInAppKeysActionTypes.SUCCESS]: loadAmazonInAppKeysSuccess,
  [LoadAmazonInAppKeysActionTypes.FAILURE]: loadAmazonInAppKeysFailure,
  [LoadAmazonInAppKeysActionTypes.START]: loadAmazonInAppKeysStart,
  [LoadRokuInAppKeysActionTypes.SUCCESS]: loadRokuInAppKeysSuccess,
  [LoadRokuInAppKeysActionTypes.FAILURE]: loadRokuInAppKeysFailure,
  [LoadRokuInAppKeysActionTypes.START]: loadRokuInAppKeysStart,
  [LoadPayPalKeysActionTypes.SUCCESS]: loadPaypalKeysSuccess,
  [LoadPayPalKeysActionTypes.FAILURE]: loadPaypalKeysFailure,
  [LoadPayPalKeysActionTypes.START]: loadPaypalKeysStart,
};

export default reducerWithActionMap(actionsMap, initialState);
