import React from 'react';
import { Grid, Button } from '@inplayer-org/inplayer-ui';
import { Formik, FormikHelpers, Field, FormikProps } from 'formik';
import { object, string } from 'yup';

// components
import Input from 'components/Fields/Input';
import FieldError from 'components/Fields/FieldError';

// local components
import { ThirdPartyKeys } from '../ThirdPartyIntegrationsForm';

const { Container, Cell } = Grid;

interface FormValues {
  private_token: string;
}

interface OwnProps {
  onSubmit: (data: FormValues) => void;
  eventbriteKeys: ThirdPartyKeys;
}

type Props = OwnProps;

const validationSchema = object().shape({
  private_token: string().required('*Required'),
});

const EventbriteForm: React.FC<Props> = ({ onSubmit, eventbriteKeys }) => {
  const initialValues: FormValues = {
    private_token: eventbriteKeys?.private_token || '',
  };

  const onFormSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    await onSubmit(values);
    setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={onFormSubmit}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting }: FormikProps<FormValues>) => (
        <form onSubmit={handleSubmit}>
          <Container columns={3} rowGap="1rem">
            <Cell width={3}>
              <Field
                tag="text_eventbrite_private_token"
                name="private_token"
                type="text"
                component={Input}
                label="Private Token"
              />
              <FieldError name="private_token" />
            </Cell>
            <Cell width={1} left={3}>
              <Button tag="button_eventbrite_save" fullWidth type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </Cell>
          </Container>
        </form>
      )}
    </Formik>
  );
};

export default EventbriteForm;
