import React, { SyntheticEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { transparentize } from 'polished';
import { Colors, AnalyticsProps, useAnalytics } from '@inplayer-org/inplayer-ui';

const MenuItemContainer = styled.div<{ active?: boolean }>`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 50px;
  color: ${transparentize(0.4, Colors.white)};
  transition: color 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.font.sizes.small};
  box-sizing: border-box;
  ${ifProp(
    'active',
    css`
      background: ${Colors.navy};
      border-right: ${Colors.skyBlue} 3px solid;
      color: ${Colors.white};
    `
  )};

  &:hover {
    color: ${Colors.white};
  }
`;

const IconContainer = styled.div`
  margin: 0 1rem;
  width: 20px;
  min-width: 20px;
`;

type MenuItemProps = {
  active?: boolean;
  icon?: ReactNode;
  onClick?: (e: SyntheticEvent<HTMLDivElement>) => any;
  children?: any;
} & AnalyticsProps;

const MenuItem = ({ active, icon, onClick, children }: MenuItemProps) => {
  const navName: string = children;
  const { track } = useAnalytics();
  const onItemClick = (e: SyntheticEvent<HTMLDivElement, Event>) => {
    e.stopPropagation();
    if (onClick && typeof onClick === 'function') {
      onClick(e);
    }
  };
  return (
    <MenuItemContainer
      active={active}
      onClick={(e) => {
        track({
          event: 'click',
          type: 'icon',
          tag: `side_nav_${navName.toLowerCase()}`,
        });
        onItemClick(e);
      }}
    >
      <IconContainer>{icon}</IconContainer>
      {children}
    </MenuItemContainer>
  );
};

export default MenuItem;
