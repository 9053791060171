import React, { forwardRef, Ref, useState, useEffect } from 'react';
import { Button, Loader, Grid, Checkbox, Switch } from '@inplayer-org/inplayer-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import difference from 'lodash/difference';

// actions
import { loadAllAccessFees } from 'ducks/merchantDucks/items/actions';
import { saveAccessFeesRules, deleteAccessFeesRule } from 'ducks/merchantDucks/vouchers/actions';

// components
import CommonIcon from 'components/CommonIcon';
import EmptyMessage from 'components/EmptyMessage';

// selectors
import {
  getAssetAccessFees,
  getPackageAccessFees,
  getIsFetchingAccessFees,
} from 'ducks/merchantDucks/vouchers/selectors';

// utils
import { getPriceDescription } from 'pages/MerchantPages/Assets/Vouchers/utils';
import truncateTextWithTooltip from 'utils/truncateTextWithTooltip';

// types
import { AccessFee } from 'ducks/merchantDucks/items/types';
import { RuleTypes } from 'pages/MerchantPages/Assets/Vouchers/components/VoucherDragAndDropContainer';

// styled
import {
  ItemsWrapper,
  ItemContainer,
  Image,
  Title,
  StyledContainer,
  ButtonLink,
  ToggleWrapper,
  ButtonWrapper,
  StyledButton,
  CheckboxWrapper,
} from '../styledVoucherItem';

interface OwnProps {
  image: string;
  title: string;
  displayIcon?: boolean;
  displayAccessFeeSection?: boolean;
  itemId?: number;
  ruleType?: string;
  setSelectedItem: (e: number) => any;
  selectedItem?: number;
  isDragging: boolean;
  isFirstItem: boolean;
}

const { Cell } = Grid;

type Props = OwnProps;

const VoucherItem = forwardRef(
  (
    {
      image,
      title,
      displayIcon = false,
      displayAccessFeeSection = false,
      itemId,
      ruleType,
      setSelectedItem,
      selectedItem,
      isDragging,
      isFirstItem,
      ...rest
    }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const dispatch = useDispatch();
    const { id: paramId } = useParams<{ id: string }>();
    const voucherId = Number(paramId);

    // state
    const [isToggleChecked, setIsToggleChecked] = useState(true);
    const [accessFeesList, setAccessFeesList] = useState<Array<AccessFee>>([]);
    const [checkedAccessFees, setCheckedAccessFees] = useState<any>({});

    // selectors
    const isAccessFeesFetching = useSelector(getIsFetchingAccessFees);
    const assetAccessFees = useSelector(getAssetAccessFees);
    const packageAccessFees = useSelector(getPackageAccessFees);

    const getAddedAccessFee = (fees: Array<AccessFee>) => {
      return fees.filter((item) => item.voucher_rule);
    };

    useEffect(() => {
      const accessFees = ruleType === RuleTypes.assets ? assetAccessFees : packageAccessFees;
      const addedAccessFeeRules = getAddedAccessFee(accessFees);
      setCheckedAccessFees({});

      if (addedAccessFeeRules.length !== 0) {
        const accessFeesObject = addedAccessFeeRules.reduce((res, item) => {
          res[item.id] = true;
          return res;
        }, {});

        setCheckedAccessFees(accessFeesObject);
        setIsToggleChecked(false);
      } else {
        setIsToggleChecked(true);
      }

      setAccessFeesList(accessFees);
    }, [assetAccessFees, packageAccessFees]);

    const onToggleCheckbox = ({ id }: AccessFee) => {
      setCheckedAccessFees((prevCheckedAccessFee: any) => {
        return {
          ...prevCheckedAccessFee,
          [id]: !checkedAccessFees[id],
        };
      });
    };

    const handleDisplayAccessFee = async (id: number) => {
      if (selectedItem !== id) {
        setSelectedItem(id);
        setAccessFeesList([]);
        await dispatch(loadAllAccessFees(id, voucherId));
      } else {
        setSelectedItem(0);
      }
    };

    const onToggleChange = async (checked: boolean) => {
      setIsToggleChecked(checked);

      if (checked) {
        setCheckedAccessFees({});

        const accessFeesObject = getAddedAccessFee(accessFeesList).reduce((res, item) => {
          res[item.id] = true;
          return res;
        }, {});

        setCheckedAccessFees(accessFeesObject);
      }
    };

    const getCheckedAccessFeesIds = (checkedFees: any) => {
      return Object.keys(checkedFees)
        .filter((item) => checkedFees[item])
        .map(Number);
    };

    const onHandleSubmit = async () => {
      if (isToggleChecked) {
        dispatch(deleteAccessFeesRule(voucherId, itemId!));
      } else {
        const checkedAccessFeesIds: Array<number> = getCheckedAccessFeesIds(checkedAccessFees);

        if (checkedAccessFeesIds.length === 0) {
          dispatch(deleteAccessFeesRule(voucherId, itemId!));
        } else {
          dispatch(
            saveAccessFeesRules({
              voucherId,
              itemId: itemId!,
              accessFees: checkedAccessFeesIds,
            })
          );
        }
      }

      setSelectedItem(0);
    };

    const onHandleCancel = () => {
      setSelectedItem(0);
    };

    // disabled button conditions
    const addedAccessFeeRulesIds = getAddedAccessFee(accessFeesList).map((item) => item.id);
    const checkedAccessFeesIds = getCheckedAccessFeesIds(checkedAccessFees);
    const sameCheckedIds = difference(checkedAccessFeesIds, addedAccessFeeRulesIds);
    const sameAddedIds = difference(addedAccessFeeRulesIds, checkedAccessFeesIds);
    const isRemovingExistingFeeRule =
      isToggleChecked && accessFeesList.map((item) => 'voucher_rule' in item).includes(true);
    const isRemovingAllRule =
      !isToggleChecked &&
      checkedAccessFeesIds.length === 0 &&
      accessFeesList.map((item) => 'voucher_rule' in item).includes(true);
    const disabledButton =
      sameCheckedIds.length === 0 && sameAddedIds.length === 0 && !isRemovingExistingFeeRule && !isRemovingAllRule;

    const renderAccessFees = () => {
      return accessFeesList.length > 0 ? (
        <>
          <Cell width={12}>
            <ToggleWrapper>
              <Switch
                checked={isToggleChecked}
                id="toggle"
                label="All price options enabled"
                onChange={onToggleChange}
              />
            </ToggleWrapper>
          </Cell>
          {accessFeesList.map((fee) => {
            const id = fee.id.toString();
            return (
              <CheckboxWrapper width={12} key={id}>
                <Checkbox
                  label={getPriceDescription(fee)}
                  id={id}
                  checked={!!checkedAccessFees[id]}
                  onChange={() => onToggleCheckbox(fee)}
                  disabled={isToggleChecked}
                />
              </CheckboxWrapper>
            );
          })}
          <Cell width={12}>
            <ButtonWrapper>
              <StyledButton size="xs" onClick={onHandleCancel}>
                Cancel
              </StyledButton>
              <Button size="xs" buttonModifiers={['buttonSuccess']} onClick={onHandleSubmit} disabled={disabledButton}>
                Save
              </Button>
            </ButtonWrapper>
          </Cell>
        </>
      ) : (
        <>
          <Cell width={12}>
            <EmptyMessage variant="p" size="small">
              No prices have been set up.
            </EmptyMessage>
          </Cell>
          <Cell width={12}>
            <ButtonWrapper>
              <StyledButton size="xs" onClick={onHandleCancel}>
                Cancel
              </StyledButton>
            </ButtonWrapper>
          </Cell>
        </>
      );
    };

    const tooltipPlacement = isFirstItem ? 'bottom' : 'top';

    return (
      <ItemsWrapper ref={ref} {...rest}>
        <ItemContainer displayAccessFeeSection={displayAccessFeeSection}>
          {displayIcon ? <CommonIcon name={image} /> : <Image alt="preview-img" src={image} />}
          <Title>{truncateTextWithTooltip({ text: title, placement: tooltipPlacement })}</Title>
        </ItemContainer>
        {displayAccessFeeSection && !isDragging && (
          <StyledContainer isFetching columns={12} rowGap="1rem">
            <Cell width={12}>
              <ButtonLink
                buttonModifiers={['buttonLink']}
                type="button"
                onClick={() => handleDisplayAccessFee(itemId!)}
                id={itemId!.toString()}
                disabled={selectedItem !== 0 && !(selectedItem === itemId && accessFeesList.length === 0)}
              >
                Access fees
              </ButtonLink>
            </Cell>
            {itemId === selectedItem && (
              <>
                {isAccessFeesFetching ? (
                  <Cell width={12} center middle>
                    <Loader />
                  </Cell>
                ) : (
                  renderAccessFees()
                )}
              </>
            )}
          </StyledContainer>
        )}
      </ItemsWrapper>
    );
  }
);

export default VoucherItem;
