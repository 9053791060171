export const payments = {
  // Merchant endpoints
  PAYMENTS_ANALYTICS: 'v2/analytics/payments',
  ASSET_PAYMENT_PER_COUNTRY: 'payments/asset-country',
  PAYMENT_METHODS_PER_COUNTRY: 'v2/analytics/payments/country',
  // Admin endpoints
  REFUND_PAYMENT: 'accounting/refund',
  MISSING_TRANSACTIONS: (page: number) => `v2/accounting/transactions/import/failed:list?page=${page}`,
  IMPORT_MISSING_TRANSACTION: (filename: string) => `v2/accounting/transactions/import/signed-url?filename=${filename}`,
};
