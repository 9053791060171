import { Reducer } from 'redux';
import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import moment from 'moment';

// types
import {
  VouchersActionTypes,
  ExcludedRulesActionTypes,
  IncludedRulesActionTypes,
  LoadVoucherAccessTypes,
  VoucherDetailsActionTypes,
  DeleteAllRulesActionTypes,
  AddVoucherRuleActionTypes,
  DeleteVoucherRuleActionTypes,
  VoucherEmailsActionTypes,
  UpdateVoucherDetailsActionTypes,
  UpdateVoucherTitleActionTypes,
  DeleteItemRuleActionTypes,
  BulkEmailActionTypes,
  ListErrorFilesActionTypes,
  GiftVoucherDetailsActionTypes,
} from 'ducks/merchantDucks/vouchers/actions';
import { LoadAllAccessFeesActionTypes } from 'ducks/merchantDucks/items/actions';
import { RuleTypes } from 'pages/MerchantPages/Assets/Vouchers/components/VoucherDragAndDropContainer';
import VouchersState from './types';

export const initialState: VouchersState = {
  collection: [],
  isFetching: false,
  total: 0,
  firstTotal: 0,
  limit: 10,
  rules: {
    isFetchingExcluded: false,
    isFetchingIncluded: false,
    excluded: {
      collection: [],
      total: 0,
      limit: 0,
    },
    included: {
      collection: [],
      total: 0,
      limit: 0,
    },
  },
  voucherDetails: {
    id: 0,
    name: '',
    code: '',
    discount: 0,
    usage_limit: 0,
    suffix: '',
    prefix: '',
    length: '',
    access: '',
    start_date: moment().unix(),
    end_date: moment().unix(),
    discount_duration: 0,
    isFetching: false,
    discount_period: '',
    acc_end_date: moment().unix(),
    acc_duration: '',
    errorFiles: [],
    rules: {
      access_fees: [],
      assets: [],
      packages: [],
    },
    type: '',
  },
  accessFees: [],
  accessFeesFetching: false,
  giftVoucherDetails: {
    id: 0,
    code: '',
    item_title: '',
    item_id: 0,
    buyer_email: '',
    receiver_email: '',
    is_used: false,
    isFetching: false,
    amount: 0,
    currency: '',
  },
};

const loadVouchersSuccess: VouchersReducer = (state = initialState, { payload }) =>
  payload.shouldUpdateTotalCount
    ? {
        ...state,
        ...payload,
        isFetching: false,
        firstTotal: payload.total,
      }
    : {
        ...state,
        ...payload,
        isFetching: false,
      };

const loadVouchersFailure: VouchersReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const loadVouchers: VouchersReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const setVoucherDetailsSuccess: VouchersReducer = (state = initialState, { payload }) => ({
  ...state,
  voucherDetails: {
    ...state.voucherDetails,
    ...payload,
    isFetching: false,
  },
});

const setVoucherDetailsFailure: VouchersReducer = (state = initialState) => ({
  ...state,
  voucherDetails: {
    ...state.voucherDetails,
    isFetching: false,
  },
});

const promptSetVoucherDetails: VouchersReducer = (state = initialState) => ({
  ...state,
  voucherDetails: {
    ...state.voucherDetails,
    isFetching: true,
  },
});

const loadAccessFeesRequest: VouchersReducer = (state = initialState) => ({
  ...state,
  accessFeesFetching: true,
});

const loadAccessFeesSuccess: VouchersReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    accessFeesFetching: false,
    accessFees: payload,
  };
};

const loadAccessFeesFailure: VouchersReducer = (state = initialState) => ({
  ...state,
  accessFeesFetching: false,
});

const loadExcludedRulesSuccess: VouchersReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    rules: {
      ...state.rules,
      isFetchingExcluded: false,
      excluded: payload.appendData
        ? {
            ...payload,
            collection: [...state.rules.excluded.collection, ...payload.collection],
          }
        : payload,
    },
  };
};

const loadExcludedRulesFailure: VouchersReducer = (state = initialState) => ({
  ...state,
  rules: {
    ...state.rules,
    isFetchingExcluded: false,
  },
});

const loadExcludedRules: VouchersReducer = (state = initialState) => ({
  ...state,
  rules: {
    ...state.rules,
    isFetchingExcluded: true,
  },
});

const loadIncludedRulesSuccess: VouchersReducer = (state = initialState, { payload }) => ({
  ...state,
  rules: {
    ...state.rules,
    isFetchingIncluded: false,
    included: payload.appendData
      ? {
          ...payload,
          collection: [
            ...state.rules.included.collection,
            ...payload.collection.filter((item: any) => !state.rules.included.collection.some((x) => x.id === item.id)),
          ],
        }
      : payload,
  },
});

const loadIncludedRulesFailure: VouchersReducer = (state = initialState) => ({
  ...state,
  rules: {
    ...state.rules,
    isFetchingIncluded: false,
    included: {
      collection: [],
      total: 0,
      limit: 0,
    },
  },
});

const loadIncludedRules: VouchersReducer = (state = initialState) => ({
  ...state,
  rules: {
    ...state.rules,
    isFetchingIncluded: true,
  },
});

const removeAllVoucherRules: VouchersReducer = (state = initialState) => ({
  ...state,
  rules: {
    excluded: {
      ...state.rules.excluded,
    },
    included: {
      collection: [],
      total: 0,
      limit: 0,
    },
  },
});

const addVoucherRuleSuccess: VouchersReducer = (
  state = initialState,
  { payload: { item: payloadItem, id, ruleType, isEmailRule } }
) => {
  const {
    rules: {
      excluded: { collection: excludedCollection },
      included: { collection: includedCollection },
    },
  } = state;
  let item = excludedCollection.find((x) => x.id === payloadItem);
  if (!item) {
    if (!isEmailRule) {
      item = { id: payloadItem, title: payloadItem, iconName: payloadItem };
    } else {
      item = { id, title: payloadItem };
    }
  }

  let collection = [...includedCollection];
  if (ruleType !== RuleTypes.accessFees) {
    collection = [...includedCollection, { ...item, rule_id: id }];
  }

  return {
    ...state,
    rules: {
      ...state.rules,
      isFetchingExcluded: false,
      included: {
        ...state.rules.included,
        collection,
      },
    },
  };
};

const deleteVoucherRuleSuccess: VouchersReducer = (state = initialState, { payload: { ruleId } }) => {
  const {
    rules: {
      included: { collection: includedCollection, total },
    },
  } = state;
  return {
    ...state,
    rules: {
      ...state.rules,
      isFetchingExcluded: false,
      included: {
        ...state.rules.included,
        collection: includedCollection.filter((x) => x.rule_id !== ruleId),
        total: total - 1,
      },
    },
  };
};

const loadVoucherAccessTypesSuccess: VouchersReducer = (state = initialState, { payload }) => ({
  ...state,
  rules: {
    ...state.rules,
    isFetchingIncluded: false,
    isFetchingExcluded: false,
    included: {
      ...payload,
      collection: payload.collection.map(({ value, id }: any) => ({
        id: value,
        title: value,
        rule_id: id,
        iconName: value,
      })),
    },
  },
});

const loadVoucherAccessTypesFailure: VouchersReducer = (state = initialState) => ({
  ...state,
  rules: {
    ...state.rules,
    isFetchingIncluded: false,
    isFetchingExcluded: false,
    included: {
      collection: [],
      total: 0,
      limit: 0,
    },
  },
});

const loadVoucherAccessTypes: VouchersReducer = (state = initialState) => ({
  ...state,
  rules: {
    ...state.rules,
    isFetchingIncluded: true,
    isFetchingExcluded: true,
  },
});

const loadVoucherEmailsSuccess: VouchersReducer = (state = initialState, { payload }) => {
  const data = payload.collection.map(({ value, id }: any) => ({
    id,
    title: value,
    rule_id: id,
  }));

  return {
    ...state,
    rules: {
      ...state.rules,
      isFetchingIncluded: false,
      included: {
        ...payload,
        collection: payload.appendData
          ? [
              ...state.rules.included.collection,
              ...data.filter((item: any) => !state.rules.included.collection.some((x) => x.id === item.id)),
            ]
          : data,
      },
    },
  };
};

const loadErrorFilesSuccess: VouchersReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    voucherDetails: {
      ...state.voucherDetails,
      errorFiles: payload,
    },
  };
};

const loadErrorFilesFailure: VouchersReducer = (state = initialState) => {
  return {
    ...state,
    voucherDetails: {
      ...state.voucherDetails,
      errorFiles: [],
    },
  };
};

const promptSetGiftVoucherDetails: VouchersReducer = (state = initialState) => ({
  ...state,
  giftVoucherDetails: {
    ...state.giftVoucherDetails,
    isFetching: true,
  },
});

const setGiftVoucherDetailsSuccess: VouchersReducer = (state = initialState, { payload }) => ({
  ...state,
  giftVoucherDetails: {
    ...payload,
    isFetching: false,
  },
});

const setGiftVoucherDetailsFailure: VouchersReducer = (state = initialState) => ({
  ...state,
  giftVoucherDetails: {
    ...state.giftVoucherDetails,
    isFetching: false,
  },
});

type VouchersReducer = Reducer<VouchersState>;

const actionsMap: ActionMap<VouchersState> = {
  // all vouchers
  [VouchersActionTypes.SUCCESS]: loadVouchersSuccess,
  [VouchersActionTypes.ERROR]: loadVouchersFailure,
  [VouchersActionTypes.START]: loadVouchers,
  // load voucher details
  [VoucherDetailsActionTypes.SUCCESS]: setVoucherDetailsSuccess,
  [VoucherDetailsActionTypes.ERROR]: setVoucherDetailsFailure,
  [VoucherDetailsActionTypes.START]: promptSetVoucherDetails,
  // access fees
  [LoadAllAccessFeesActionTypes.SUCCESS]: loadAccessFeesSuccess,
  [LoadAllAccessFeesActionTypes.START]: loadAccessFeesRequest,
  [LoadAllAccessFeesActionTypes.ERROR]: loadAccessFeesFailure,
  // available rules
  [ExcludedRulesActionTypes.SUCCESS]: loadExcludedRulesSuccess,
  [ExcludedRulesActionTypes.ERROR]: loadExcludedRulesFailure,
  [ExcludedRulesActionTypes.START]: loadExcludedRules,
  // added rules
  [IncludedRulesActionTypes.SUCCESS]: loadIncludedRulesSuccess,
  [IncludedRulesActionTypes.ERROR]: loadIncludedRulesFailure,
  [IncludedRulesActionTypes.START]: loadIncludedRules,
  // access types
  [LoadVoucherAccessTypes.SUCCESS]: loadVoucherAccessTypesSuccess,
  [LoadVoucherAccessTypes.ERROR]: loadVoucherAccessTypesFailure,
  [LoadVoucherAccessTypes.START]: loadVoucherAccessTypes,
  // remove all rules
  [DeleteAllRulesActionTypes.SUCCESS]: removeAllVoucherRules,
  // voucher rule
  [AddVoucherRuleActionTypes.SUCCESS]: addVoucherRuleSuccess,
  [DeleteVoucherRuleActionTypes.SUCCESS]: deleteVoucherRuleSuccess,
  [DeleteItemRuleActionTypes.SUCCESS]: deleteVoucherRuleSuccess,
  // emails
  [VoucherEmailsActionTypes.SUCCESS]: loadVoucherEmailsSuccess,
  [VoucherEmailsActionTypes.ERROR]: loadIncludedRulesFailure,
  [VoucherEmailsActionTypes.START]: loadIncludedRules,

  // update voucher details
  [UpdateVoucherDetailsActionTypes.SUCCESS]: setVoucherDetailsSuccess,
  [UpdateVoucherDetailsActionTypes.START]: promptSetVoucherDetails,
  [UpdateVoucherDetailsActionTypes.ERROR]: setVoucherDetailsFailure,

  // update voucher title
  [UpdateVoucherTitleActionTypes.SUCCESS]: setVoucherDetailsSuccess,
  [UpdateVoucherTitleActionTypes.START]: promptSetVoucherDetails,
  [UpdateVoucherTitleActionTypes.ERROR]: setVoucherDetailsFailure,

  // bulk email
  [BulkEmailActionTypes.START]: loadVouchers,
  [BulkEmailActionTypes.SUCCESS]: loadVouchersFailure,
  [BulkEmailActionTypes.ERROR]: loadVouchersFailure,
  [ListErrorFilesActionTypes.SUCCESS]: loadErrorFilesSuccess,
  [ListErrorFilesActionTypes.ERROR]: loadErrorFilesFailure,

  // gift vouchers
  [GiftVoucherDetailsActionTypes.START]: promptSetGiftVoucherDetails,
  [GiftVoucherDetailsActionTypes.SUCCESS]: setGiftVoucherDetailsSuccess,
  [GiftVoucherDetailsActionTypes.ERROR]: setGiftVoucherDetailsFailure,
};

export default reducerWithActionMap(actionsMap, initialState);
