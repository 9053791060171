export enum Steps {
  BasicInfo,
  MainSettings,
  Limitations,
}

export enum VoucherType {
  Web = '0',
  InApp = '1',
  Vip = '2',
}

export enum ItemTypes {
  Asset = '1',
  Package = '2',
}

export enum AccessPeriodTypes {
  AccessEndDate = 'accessEndDate',
  AccessDuration = 'accessDuration',
}

export interface WebVoucherFormValues {
  code: string;
  isRandomGeneratedCode: boolean;
  discount: string;
  discountPeriod: string;
  length: number;
  suffix: string;
  prefix: string;
  discountDuration: number;
}

export interface InAppVoucherFormValues {
  discount: string;
  discountDuration: number;
  application: string;
  name: string;
}

export interface VipVoucherFormValues {
  code: string;
  isRandomGeneratedCode: boolean;
  length: number;
  suffix: string;
  prefix: string;
  accessEndDate: string;
  timeValue: number;
  timePeriod: string;
  accessPeriodType: string;
}

export type VoucherMainSettingsFormValues = WebVoucherFormValues | InAppVoucherFormValues | VipVoucherFormValues;

export enum TimePeriodValues {
  HOURS = 'h',
  DAYS = 'd',
  WEEKS = 'w',
  MONTHS = 'm',
  YEARS = 'y',
}

export enum ReportPeriods {
  LAST_DAY = 'last_day',
  LAST_WEEK = 'last_week',
  LAST_MONTH = 'last_month',
  MONTH_TO_DATE = 'month_to_date',
  YEAR_TO_DATE = 'year_to_date',
  LASTPERIOD = 'last_period',
}

export const initialWebVoucherValues: WebVoucherFormValues = {
  code: '',
  isRandomGeneratedCode: false,
  discount: '',
  discountPeriod: 'once',
  length: 0,
  suffix: '',
  prefix: '',
  discountDuration: 1,
};

export const initialVipVoucherValues: VipVoucherFormValues = {
  code: '',
  isRandomGeneratedCode: false,
  length: 0,
  suffix: '',
  prefix: '',
  accessEndDate: '',
  timeValue: 0,
  timePeriod: TimePeriodValues.DAYS,
  accessPeriodType: '',
};

export const initialInAppVoucherValues: InAppVoucherFormValues = {
  discount: '',
  discountDuration: 1,
  application: 'android',
  name: '',
};

interface WebLimitationFormValues {
  usage: string;
  startDate: string;
  endDate: string;
}

export interface InAppLimitationValues {
  startDate: string;
  endDate: string;
  selectedAccessFee: string;
  itemType: string;
  itemId: number;
}

export type VoucherLimitationFormValues = WebLimitationFormValues | InAppLimitationValues;

export const initialWebVoucherLimitations: WebLimitationFormValues = {
  usage: '',
  startDate: '',
  endDate: '',
};

export const initialInAppVoucherLimitations: InAppLimitationValues = {
  startDate: '',
  endDate: '',
  selectedAccessFee: '0',
  itemType: ItemTypes.Asset,
  itemId: 0,
};

export const discountPeriodOptions = [
  {
    displayName: 'once',
    value: 'once',
    tag: 'dropdown_cycles_once',
  },
  {
    displayName: 'forever',
    value: 'forever',
    tag: 'dropdown_cycles_forever',
  },
  {
    displayName: 'repeating',
    value: 'repeating',
    tag: 'dropdown_cycles_repeating',
  },
];

export const durationsOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map((digit) => {
  return { value: digit, displayName: digit };
});

export const applicationsOptions = [
  { value: 'android', displayName: 'Android' },
  { value: 'ios', displayName: 'iOS' },
];

export const itemTypesOptions = [
  {
    value: 'asset',
    displayName: 'Asset',
  },
  {
    value: 'package',
    displayName: 'Package',
  },
];

export const timePeriodOptions = [
  { value: TimePeriodValues.HOURS, displayName: 'hours' },
  { value: TimePeriodValues.DAYS, displayName: 'days' },
  { value: TimePeriodValues.WEEKS, displayName: 'weeks' },
  { value: TimePeriodValues.MONTHS, displayName: 'months' },
  { value: TimePeriodValues.YEARS, displayName: 'years' },
];

export enum VoucherTypeName {
  Web = 'web',
  InApp = 'in-app',
  Vip = 'vip',
  Gift = 'gift-voucher',
}
