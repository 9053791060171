import RootState from 'ducks/RootState';

export const getAuthState = (state: RootState) => state.auth;

export const getUserDetails = (state: RootState) => getAuthState(state).user;

export const getTenantUuid = (state: RootState) => getUserDetails(state).tenant_uuid;

export const getMerchantUuid = (state: RootState) => getUserDetails(state).uuid;

export const getMerchantId = (state: RootState) => getUserDetails(state).id;

export const getUserMetadata = (state: RootState) => {
  const userDetails = getUserDetails(state);

  return userDetails ? userDetails.metadata : null;
};

export const isProbabilitySegmentEnabled = (state: RootState) => {
  const userDetailsMetadata = getUserMetadata(state);

  if (userDetailsMetadata) {
    return !!userDetailsMetadata.probability_segment;
  }

  return false;
};

export const getFeatures = (state: RootState) => getAuthState(state).features;
