import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import set from 'lodash/fp/set';
import remove from 'lodash/remove';

import {
  CreateSubscriptionGroupActionTypes,
  AddSubscriptionGroupItemActionTypes,
  DeleteSubscriptionGroupActionTypes,
  DeleteSubscriptionGroupItemActionTypes,
  EditSubscriptionGroupActionTypes,
  SubscriptionGroupState,
} from './types';

export const initialState: SubscriptionGroupState = {
  collection: [],
  isFetching: false,
};

type SubscriptionGroupsReducer = Reducer<SubscriptionGroupState>;

// Subscription group

const createSubscriptionGroupStart: SubscriptionGroupsReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const createSubscriptionGroupSuccess: SubscriptionGroupsReducer = (
  state = initialState,
  { payload: { uuid, created_at, updated_at, ...rest } }
) => {
  return {
    ...state,
    isFetching: false,
    collection: [
      ...state.collection,
      {
        subscriptionGroupId: uuid,
        createdAt: created_at,
        updatedAt: updated_at,
        accessFees: [],
        ...rest,
      },
    ],
  };
};

const createSubscriptionGroupFailure: SubscriptionGroupsReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const editSubscriptionGroupStart: SubscriptionGroupsReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const editSubscriptionGroupSuccess: SubscriptionGroupsReducer = (
  state = initialState,
  { payload: { name, subscriptionGroupId } }
) => {
  const indexOfUpdatedSubscriptionGroup = state.collection.findIndex(
    (group) => group.subscriptionGroupId === subscriptionGroupId
  );

  if (indexOfUpdatedSubscriptionGroup === -1) {
    console.warn(`Unable to find index for ${subscriptionGroupId} when editing subscription group.`);
    return state;
  }

  state = set(`collection[${indexOfUpdatedSubscriptionGroup}].name`, name, state); // eslint-disable-line no-param-reassign

  return {
    ...state,
    isFetching: false,
  };
};

const editSubscriptionGroupFailure: SubscriptionGroupsReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const deleteSubscriptionGroupStart: SubscriptionGroupsReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const deleteSubscriptionGroupSuccess: SubscriptionGroupsReducer = (
  state = initialState,
  { payload: { subscriptionGroupId } }
) => {
  const existingCollection = state.collection.filter((group) => group.subscriptionGroupId !== subscriptionGroupId);

  return {
    ...state,
    isFetching: false,
    collection: existingCollection,
  };
};

const deleteSubscriptionGroupFailure: SubscriptionGroupsReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

// Subscription group item

const addSubscriptionGroupItemStart: SubscriptionGroupsReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const addSubscriptionGroupItemSuccess: SubscriptionGroupsReducer = (
  state = initialState,
  { payload: { id, created_at, updated_at, subscription_group, access_fee, subscriptionGroupId, ...rest } }
) => {
  const indexOfUpdatedSubscriptionGroup = state.collection.findIndex(
    (group) => group.subscriptionGroupId === subscriptionGroupId
  );

  if (indexOfUpdatedSubscriptionGroup === -1) {
    console.warn(`Unable to find index for ${subscriptionGroupId} when adding new subscription group item.`);
    return state;
  }

  const existingItems = state.collection[indexOfUpdatedSubscriptionGroup].accessFees;
  // eslint-disable-next-line no-param-reassign
  state = set(
    `collection[${indexOfUpdatedSubscriptionGroup}].accessFees`,
    [
      ...existingItems,
      {
        subscriptionGroupAccessFeeId: id,
        createdAt: created_at,
        updatedAt: updated_at,
        subscriptionGroup: {
          createdAt: subscription_group.created_at,
          updatedAt: subscription_group.updated_at,
          subscriptionGroupId: subscription_group.uuid,
          name: subscription_group.name,
        },
        accessFee: access_fee,
        ...rest,
      },
    ],
    state
  );

  return {
    ...state,
    isFetching: false,
  };
};

const addSubscriptionGroupItemFailure: SubscriptionGroupsReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const deleteSubscriptionGroupItemStart: SubscriptionGroupsReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const deleteSubscriptionGroupItemSuccess: SubscriptionGroupsReducer = (
  state = initialState,
  { payload: { subscriptionGroupId, accessFeeId } }
) => {
  const indexOfUpdatedSubscriptionGroup = state.collection.findIndex(
    (group) => group.subscriptionGroupId === subscriptionGroupId
  );

  if (indexOfUpdatedSubscriptionGroup === -1) {
    console.warn(`Unable to find index for ${subscriptionGroupId} when deleting subscription group item.`);
    return state;
  }

  const newItemsCollection = remove(
    state.collection[indexOfUpdatedSubscriptionGroup].accessFees,
    (accessFee) => accessFee.subscriptionGroupAccessFeeId === accessFeeId
  );

  state = set(`collection[${indexOfUpdatedSubscriptionGroup}].accessFees`, newItemsCollection, state); // eslint-disable-line no-param-reassign

  return {
    ...state,
    isFetching: false,
  };
};

const deleteSubscriptionGroupItemFailure: SubscriptionGroupsReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const actionsMap: ActionMap<SubscriptionGroupState> = {
  // Subscription group
  [CreateSubscriptionGroupActionTypes.START]: createSubscriptionGroupStart,
  [CreateSubscriptionGroupActionTypes.SUCCESS]: createSubscriptionGroupSuccess,
  [CreateSubscriptionGroupActionTypes.FAILURE]: createSubscriptionGroupFailure,
  [DeleteSubscriptionGroupActionTypes.START]: deleteSubscriptionGroupStart,
  [DeleteSubscriptionGroupActionTypes.SUCCESS]: deleteSubscriptionGroupSuccess,
  [DeleteSubscriptionGroupActionTypes.FAILURE]: deleteSubscriptionGroupFailure,
  [EditSubscriptionGroupActionTypes.START]: editSubscriptionGroupStart,
  [EditSubscriptionGroupActionTypes.SUCCESS]: editSubscriptionGroupSuccess,
  [EditSubscriptionGroupActionTypes.FAILURE]: editSubscriptionGroupFailure,
  // Subscription group item
  [AddSubscriptionGroupItemActionTypes.START]: addSubscriptionGroupItemStart,
  [AddSubscriptionGroupItemActionTypes.SUCCESS]: addSubscriptionGroupItemSuccess,
  [AddSubscriptionGroupItemActionTypes.FAILURE]: addSubscriptionGroupItemFailure,
  [DeleteSubscriptionGroupItemActionTypes.START]: deleteSubscriptionGroupItemStart,
  [DeleteSubscriptionGroupItemActionTypes.SUCCESS]: deleteSubscriptionGroupItemSuccess,
  [DeleteSubscriptionGroupItemActionTypes.FAILURE]: deleteSubscriptionGroupItemFailure,
};

export default reducerWithActionMap(actionsMap, initialState);
