import { Reducer } from 'redux';
import omit from 'lodash/omit';
import values from 'lodash/values';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  LoadItemDetailsActionTypes,
  LoadAllAccessFeesActionTypes,
  LoadJwPlayersActionTypes,
  UpdateItemMetadataActionTypes,
  UpdateItemTitleActionTypes,
  UpdateItemPreviewActionTypes,
  LoadItemAccessTypesActionTypes,
  UpdateAssetContentActionTypes,
  UploadFileActionTypes,
  LoadAssetsActionTypes,
  ReactivateAssetActionTypes,
  RESET_ASSET_DETAILS,
  CreateItemActionTypes,
  SyncOvpVideosActionTypes,
  CreateItemAccessFeeActionTypes,
  SAVE_SELECTED_FEE_ID,
  SET_PANOPTO_CONTENT,
  CLEAR_PANOPTO_CONTENT,
  UpdateItemAccessFeeActionTypes,
  RemoveAccessFeeActionTypes,
  LoadExternalAssetsActionTypes,
  LoadExternalBrightcoveAssetsActionTypes,
  LoadInitialExternalAssetsActionTypes,
  LoadSessionChooserActionTypes,
  RESET_EXTERNAL_ASSETS,
  PullOvpDetailsActionTypes,
  SYNC_PROGRESS_DONE,
  DeleteItemMetadataActionTypes,
  ChangeItemContentTypeActionTypes,
  LoadCodesActionTypes,
  SaveCodeActionTypes,
  UpdateCodeActionTypes,
  UpdateAllCodeActionTypes,
  LoadCodeSessionsActionTypes,
  TerminateCodeSessionActionTypes,
  SET_SELECTED_SECTION_INDEX,
  SET_SHOULD_CLOSE_SECTION,
  SET_SHOULD_CLOSE_PANEL,
  GetSeasonPhasesActionTypes,
  CreateSeasonPhaseActionTypes,
  UpdateSeasonPhaseActionTypes,
  BulkCodeActionTypes,
  LoadErrorFilesActionTypes,
  GetDonationOptionsActionTypes,
  LoadCodesReportsTypes,
  CreateTemplateActionTypes,
  LoadTemplateDetailsActionTypes,
  CreateTemplatePriceActionTypes,
  LoadTemplatesActionTypes,
  SAVE_SELECTED_TEMPLATE_FEE_ID,
  UpdateAssetEventTypeActionTypes,
  UpdateEventDetailsActionTypes,
  UpdateTemplateTitleActionTypes,
  UpdateTemplatePreviewActionTypes,
  UpdateTemplateMetadataActionTypes,
  DeleteTemplateMetadataActionTypes,
  UpdateTemplatePriceActionTypes,
  DeleteTemplatePriceActionTypes,
  UpdateTemplateEventTypeActionTypes,
  UpdateTemplateEventDetailsActionTypes,
  LoadResourceActionTypes,
  CreateLiveLikeChatroomActionTypes,
  GetLiveLikeChatroomActionTypes,
  DeleteLiveLikeChatroomActionTypes,
  CheckSyncProgressActionTypes,
  PullBrightcoveDetailsActionTypes,
  UpdateItemTemplateIdActionTypes,
  UpdateGiftDetailsActionTypes,
  GetIvsChannelsActionTypes,
  GetIvsChannelDetailsActionTypes,
  UpdateIvsChannelDetailsActionTypes,
  CreateIvsChannelActionTypes,
  ChangeItemPlanActionTypes,
} from 'ducks/merchantDucks/items/actions';
import { ResetPackageAssetsActionTypes } from 'ducks/merchantDucks/packages/actions';
import { AccessControlType } from 'ducks/merchantDucks/accesses/actions';
import ItemsState from 'ducks/merchantDucks/items/types';
import {
  CreateTemplateRestrictionsActionTypes,
  DeleteTemplateRestrictionsActionTypes,
  UpdateTemplateRestrictionsActionTypes,
} from 'ducks/merchantDucks/restrictions/actions';

export const initialState: ItemsState = {
  collection: [],
  isFetching: false,
  isExist: false,
  assetId: 0,
  total: 0,
  limit: 10,
  page: 1,
  offset: 0,
  firstTotal: 0,
  externalAssets: {
    total: 0,
    videos: [],
    isFetching: false,
    error: false,
  },
  itemDetails: {
    id: 0,
    merchant_id: 0,
    content: '',
    merchant_uuid: '',
    is_active: true,
    title: '',
    plan_switch_enabled: false,
    access_control_type: {
      id: 0,
      name: '',
      auth: 0,
    },
    item_type: {
      id: 0,
      name: '',
      content_type: '',
      host: '',
      description: '',
    },
    metadata: {},
    created_at: 0,
    updated_at: 0,
    source: '',
    asset_file: {},
    thumbnail_file: {},
    accessFees: [],
    selectedFeeId: 0,
    isCreated: false,
    isSaving: false,
    template_id: 1,
    isAccessFeesFetching: false,
    selectedSectionIndex: -1,
    shouldCloseSection: true,
    shouldClosePanel: false,
    seasonPhases: [],
    is_giftable: false,
    gift_metadata: {
      created_at: 0,
      description: '',
      id: 0,
      item_id: 0,
      updated_at: 0,
    },
    donationDetails: {
      isLoading: false,
      donations: [],
      donation_options: {
        id: 0,
        item_id: 0,
        custom_price_enabled: false,
      },
    },
    event_type: '',
    event_details: {
      id: 0,
      industry: '',
      content_category: '',
      event_name: '',
      web_page: '',
      event_date: 0,
      expected_viewership: '',
      replay_available: false,
      is_advertised: false,
      event_description: '',
    },
    external_integrations: {
      livelike: {
        chatrooms: [],
      },
    },
    isFetching: false,
  },
  fromAssetDetails: false,
  jwPlayers: {
    isFetching: false,
    players: [],
  },
  ovpVideoDetails: {
    id: 1,
    isLoading: false,
    isFetchingBrightcove: false,
    isFetchedBrightcove: false,
    isPulled: false,
    isFromOvp: false,
    custom: {
      created: 0,
    },
    tags: [],
    catalog: {
      id: 1,
    },
    created: '',
  },
  accessTypes: {
    ppv: [],
    subscriptions: [],
    season: [],
  },
  priceInputs: {
    inputs: [],
    nextInput: 0,
  },
  infoInputs: {
    inputs: [],
    nextInput: 0,
  },
  syncInProgress: false,
  isFetchingFormData: false,
  isFormDataFetched: false,
  formdata: {},
  launch_url: '',
  panoptoContent: {},
  codes: {
    total: 0,
    page: 1,
    limit: 10,
    collection: [],
    isFetching: false,
    isCreating: false,
    isUploaded: false,
    isUpdating: false,
    codeSessions: {
      isFetching: false,
      collection: [],
    },
    errorFiles: {
      isFetching: false,
      files: [],
    },
    reports: {
      isFetching: false,
      collection: [],
    },
  },
  templates: {
    templateDetails: {
      selectedTemplateFeeId: 0,
      id: 0,
      merchant_id: 0,
      name: '',
      access_control_type_id: 0,
      preview_text: '',
      restrictions: [],
      prices: [],
      item_type_id: 0,
      metadata: [],
      created_at: 0,
      updated_at: 0,
      event_details: {
        id: 0,
        industry: '',
        content_category: '',
        event_name: '',
        web_page: '',
        event_date: 0,
        expected_viewership: '',
        replay_available: false,
        is_advertised: false,
        event_description: '',
      },
      isSaving: false,
      event_type: '',
    },
    templateCollection: {
      isFetching: false,
      collection: [],
    },
  },
  livelikeResources: {
    savedChatroom: {
      isFetching: false,
      isFetched: false,
      chatroomData: null,
    },
    chatRooms: {
      count: 0,
      collection: [],
      isFetching: false,
      isFetched: false,
      isCreating: false,
      total: 0,
      page: 0,
      size: 0,
    },
  },
  ovps: {
    ivs: {
      channels: {
        status: {
          isFetching: false,
        },
        list: [],
      },
    },
  },
};

type ItemsReducer = Reducer<ItemsState>;

const promptCreateItem: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isSaving: true,
    isCreated: false,
    isExist: false,
  },
});

const createItemSuccess: ItemsReducer = (state = initialState, { payload: { id } }) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isSaving: false,
    isCreated: true,
    isExist: false,
    id,
  },
});

const createItemFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isSaving: false,
    isCreated: false,
    isFailed: true,
  },
});

const setItemDetailsSuccess: ItemsReducer = (state = initialState, { payload }) => {
  // check if access_control_type is not set
  let accessControlTypeId = AccessControlType.PAID;
  if (payload.access_control_type) {
    accessControlTypeId = payload.access_control_type.id;
  }

  return {
    ...state,
    itemDetails: {
      ...state.itemDetails,
      ...payload,
      metadata: {
        ...payload.metadata,
      },
      access_control_type: {
        ...state.itemDetails.access_control_type,
        id: accessControlTypeId,
      },
      isFetching: false,
    },
  };
};

const deleteItemMetadataSuccess: ItemsReducer = (state = initialState, { payload: { keys } }) => {
  const { metadata } = state.itemDetails;

  const newMetadata = { ...omit(metadata, keys) };

  return { ...state, itemDetails: { ...state.itemDetails, metadata: { ...newMetadata } } };
};

const updateGiftDetails: ItemsReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    itemDetails: {
      ...state.itemDetails,
      gift_metadata: {
        ...state.itemDetails.gift_metadata,
        description: payload.gift_metadata?.description || '',
      },
      ...payload,
    },
  };
};

const setItemDetailsFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const changeItemPlanRequest: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isFetching: true,
  },
});

const changeItemPlanSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    plan_switch_enabled: payload.plan_switch_enabled,
    isFetching: false,
  },
});

const changeItemPlanFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isFetching: false,
  },
});

const promptSetItemDetails: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isFetching: true,
  },
});

const setAccessFeesSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    accessFees: payload,
    isAccessFeesFetching: false,
  },
});

const setAccessFeesStart: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isAccessFeesFetching: true,
  },
});

const setAccessFeesFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isAccessFeesFetching: false,
  },
});

const promptSetJwtPlayers: ItemsReducer = (state = initialState) => ({
  ...state,
  jwPlayers: {
    ...state.jwPlayers,
    isLoaded: false,
    isFetching: true,
  },
});

const setJwtPlayersSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  jwPlayers: {
    ...state.jwPlayers,
    ...payload,
    isLoaded: true,
    isFetching: false,
  },
});

const setJwtPlayersFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  jwPlayers: {
    ...state.jwPlayers,
    isLoaded: false,
    isFetching: false,
  },
});

const promptLoadSessionChooser: ItemsReducer = (state = initialState) => ({
  ...state,
  isFetchingFormData: true,
  isFormDataFetched: false,
  formdata: {},
  launch_url: '',
});

const loadSessionChooserFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  isFetchingFormData: false,
});

const loadSessionChooserSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  isFetchingFormData: false,
  isFormDataFetched: true,
  formdata: {
    ...payload.formdata,
  },
  launch_url: payload.launch_url,
});

const setPanoptoContent: ItemsReducer = (state = initialState, { content }) => ({
  ...state,
  panoptoContent: content,
});

const clearPanoptoContent: ItemsReducer = (state = initialState) => ({
  ...state,
  panoptoContent: {},
});

const setItemAccessTypes: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  accessTypes: payload,
});

const promptSetInitialExternalAsset: ItemsReducer = (state = initialState) => ({
  ...state,
  externalAssets: {
    ...state.externalAssets,
    isFetching: true,
  },
});

const setInitialExternalAssetSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  externalAssets: {
    ...state.externalAssets,
    videos: [...payload.videos],
    total: payload.total,
    isFetching: false,
  },
});

const setInitialExternalAssetFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  externalAssets: {
    ...state.externalAssets,
    isFetching: false,
    error: true,
  },
});

const promptSetExternalAsset: ItemsReducer = (state = initialState) => ({
  ...state,
  externalAssets: {
    ...state.externalAssets,
    isFetching: true,
  },
});

const setExternalAssetSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  externalAssets: {
    ...state.externalAssets,
    videos:
      state.externalAssets.videos.length !== 0
        ? [...state.externalAssets.videos, ...payload.videos]
        : [...payload.videos],
    isFetching: false,
  },
});

const setExternalAssetFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  externalAssets: {
    ...state.externalAssets,
    isFetching: false,
  },
});

const promptSetExternalBrightcoveAsset: ItemsReducer = (state = initialState) => ({
  ...state,
  externalAssets: {
    ...state.externalAssets,
    isFetching: true,
  },
});

const setExternalBrightcoveAssetSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  externalAssets: {
    ...state.externalAssets,
    videos: [...payload.videos],
    isFetching: false,
  },
});

const setExternalBrightcoveAssetFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  externalAssets: {
    ...state.externalAssets,
    isFetching: false,
  },
});

const resetExternalAssets: ItemsReducer = (state = initialState) => ({
  ...state,
  externalAssets: {
    ...state.externalAssets,
    videos: [],
  },
});

const promptSetAssetContent: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isFetching: true,
  },
});

const setAssetContentSuccess: ItemsReducer = (state = initialState, { payload: { content } }) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    content,
    isFetching: false,
  },
});

const setAssetContentFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isFetching: false,
  },
});

const uploadFile: ItemsReducer = (state = initialState, { payload }) => {
  if (payload.thumbnail) {
    return {
      ...state,
      itemDetails: {
        ...state.itemDetails,
        thumbnail_file: {
          asset_url: payload.asset_url,
          filename: payload.asset_filename,
          filesize: payload.asset_filesize,
          thumbnail_url: payload.thumbnail_url,
        },
      },
    };
  }
  return {
    ...state,
    itemDetails: {
      ...state.itemDetails,
      asset_file: {
        asset_url: payload.asset_url,
        filename: payload.asset_name,
        filesize: payload.asset_size,
      },
    },
  };
};

const setAssetsSuccess: ItemsReducer = (state = initialState, { payload }) => {
  if (payload.concat) {
    return {
      ...state,
      collection: [...state.collection, ...payload.collection],
      isFetching: false,
      isExist: false,
    };
  }
  if (payload.search.length === 0) {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isExist: false,
      firstTotal: payload.total,
    };
  }
  return {
    ...state,
    ...payload,
    isFetching: false,
    isExist: false,
  };
};

const toggleSetAssets = (isFetching = false, isExist = false): ItemsReducer => (state = initialState) => ({
  ...state,
  isFetching,
  isExist,
  // Quick fix for asset quick add (asset is created automatically with kept setting from previously created asset)
  ovpVideoDetails: {
    ...state.ovpVideoDetails,
    isPulled: false,
  },
});

const reactivateAssetSuccess: ItemsReducer = (state = initialState, { payload: { id } }) => ({
  ...state,
  collection: state.collection.filter((item) => item.id !== id),
  isExist: false,
});

const reactivateAssetFailure: ItemsReducer = (
  state = initialState,
  { payload: { code, errors: { assetID } = { assetID: null } } }
) => ({
  ...state,
  isExist: code === 409,
  assetId: code === 409 ? assetID : null,
});

const resetitemDetails: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...initialState.itemDetails,
  },
  formdata: {},
  launch_url: '',
  panoptoContent: {},
  isFetchingFormData: false,
  isFormDataFetched: false,
});

const syncOvpVideos: ItemsReducer = (state = initialState, { payload: { isFromitemDetails } }) => ({
  ...state,
  fromitemDetails: isFromitemDetails,
  syncInProgress: true,
});

const checkSyncProgressSuccess: ItemsReducer = (state = initialState) => ({
  ...state,
  syncInProgress: false,
});

const checkSyncProgressFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  syncInProgress: false,
});

const setCreateItemAccessFeeSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    accessFees: [...state.itemDetails.accessFees, payload],
    isFetching: false,
  },
});

const setCreateItemAccessFeeFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const promptCreateItemAccessFee: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isFetching: true,
  },
});

const setSelectedFeeId: ItemsReducer = (state = initialState, { id }) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    selectedFeeId: id,
  },
});

const updateItemAccessFee: ItemsReducer = (state = initialState, { payload }) => {
  const itemIndex = state.itemDetails.accessFees.findIndex((fee) => fee.id === payload.id);

  return {
    ...state,
    itemDetails: {
      ...state.itemDetails,
      accessFees: [
        ...state.itemDetails.accessFees.slice(0, itemIndex),
        payload,
        ...state.itemDetails.accessFees.slice(itemIndex + 1),
      ],
    },
  };
};

const removeAccessFeeSuccess: ItemsReducer = (state = initialState, { payload: { feeId } }) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    accessFees: state.itemDetails.accessFees.filter((item) => item.id !== feeId),
  },
});

const promptSetOvpDetails: ItemsReducer = (state = initialState) => ({
  ...state,
  ovpVideoDetails: {
    ...state.ovpVideoDetails,
    isLoading: true,
    isPulled: false,
  },
});

const setOvpDetailsSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  ovpVideoDetails: {
    ...state.ovpVideoDetails,
    ...payload.video,
    player_id: payload.player_id,
    isLoading: false,
    isPulled: true,
  },
});

const setOvpDetailsFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  ovpVideoDetails: {
    ...state.ovpVideoDetails,
    isLoading: false,
    isPulled: false,
  },
});

const syncProgressDone: ItemsReducer = (state = initialState) => ({
  ...state,
  syncInProgress: false,
});

const resetPackageAssets: ItemsReducer = (state = initialState) => ({
  ...state,
  collection: [],
  total: 0,
  page: 1,
  limit: 5,
  offset: 0,
  isFetching: false,
  firstTotal: 0,
});

const promptLoadCodes: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    isFetching: true,
  },
});

const loadCodesFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    isFetching: false,
  },
});

const loadCodesSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  codes: {
    ...state.codes,
    ...payload,
    isFetching: false,
  },
});

const promptCreateCode: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    isCreating: true,
  },
});

const createCodeFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    isCreating: false,
  },
});

const createCodeSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  codes: {
    ...state.codes,
    collection: [payload, ...state.codes.collection],
    isCreating: false,
  },
});

const promptUpdateCode: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    isUpdating: true,
  },
});

const updateCodeFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    isUpdating: false,
  },
});

const updateCodeSuccess: ItemsReducer = (state = initialState, { payload }) => {
  const codeItemIndex = state.codes.collection.findIndex((item) => item.code === payload.code);

  return {
    ...state,
    codes: {
      ...state.codes,
      collection: [
        ...state.codes.collection.slice(0, codeItemIndex),
        payload,
        ...state.codes.collection.slice(codeItemIndex + 1),
      ],
      isUpdating: false,
    },
  };
};

const promptUpdateAllCodes: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    isUpdating: true,
  },
});

const updateAllCodesFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    isUpdating: false,
  },
});

const updateAllCodesSuccess: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    isUpdating: false,
  },
});

const promptLoadCodeSessions: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    codeSessions: {
      ...state.codes.codeSessions,
      isFetching: true,
    },
  },
});

const loadCodeSessionsSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  codes: {
    ...state.codes,
    codeSessions: {
      ...state.codes.codeSessions,
      collection: Object.values(payload),
      isFetching: false,
    },
  },
});

const loadCodeSessionsFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    codeSessions: {
      ...state.codes.codeSessions,
      isFetching: false,
    },
  },
});

const terminateCodeSessionSuccess: ItemsReducer = (state = initialState, { payload: { browserFingerprint } }) => ({
  ...state,
  codes: {
    ...state.codes,
    codeSessions: {
      ...state.codes.codeSessions,
      collection: state.codes.codeSessions.collection.filter((session) => {
        return session.browser_fingerprint !== browserFingerprint;
      }),
    },
  },
});

const bulkCreateCodeSuccess: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    isCreating: false,
    isUploaded: true,
  },
});

const promptLoadErrorFiles: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    errorFiles: {
      ...state.codes.errorFiles,
      isFetching: true,
    },
  },
});

const loadErrorFilesSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  codes: {
    ...state.codes,
    errorFiles: {
      isFetching: false,
      files: payload,
    },
  },
});

const loadErrorFilesFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    errorFiles: {
      ...state.codes.errorFiles,
      isFetching: false,
    },
  },
});

const promptLoadCodesReports: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    reports: {
      ...state.codes.reports,
      isFetching: true,
    },
  },
});

const loadCodesReportsSuccess: ItemsReducer = (state = initialState, { payload = [] }) => ({
  ...state,
  codes: {
    ...state.codes,
    reports: {
      isFetching: false,
      collection: payload,
    },
  },
});

const loadCodesReportsFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  codes: {
    ...state.codes,
    reports: {
      ...state.codes.reports,
      isFetching: false,
    },
  },
});

const handleSetSelectedSectionIndex: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    selectedSectionIndex: payload,
  },
});

const handleSetShouldCloseSection: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    shouldCloseSection: payload,
  },
});

const handleSetShouldClosePanel: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    shouldClosePanel: payload,
  },
});

const setSeasonPhasesSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    seasonPhases: payload,
  },
});

const createSeasonPhaseSuccess: ItemsReducer = (state = initialState, { payload }) => {
  const itemIndex = state.itemDetails.seasonPhases.filter((item) => item.expires_at < payload.starts_at).length;
  return {
    ...state,
    itemDetails: {
      ...state.itemDetails,
      seasonPhases: [
        ...state.itemDetails.seasonPhases.slice(0, itemIndex),
        payload,
        ...state.itemDetails.seasonPhases.slice(itemIndex),
      ],
    },
  };
};

const updateSeasonPhaseSuccess: ItemsReducer = (state = initialState, { payload }) => {
  const itemIndex = state.itemDetails.seasonPhases.findIndex((item) => item.id === payload.id);
  return {
    ...state,
    itemDetails: {
      ...state.itemDetails,
      seasonPhases: [
        ...state.itemDetails.seasonPhases.slice(0, itemIndex),
        payload,
        ...state.itemDetails.seasonPhases.slice(itemIndex + 1),
      ],
    },
  };
};

const loadDonationListStart: ItemsReducer = (state = initialState) => {
  return {
    ...state,
    itemDetails: {
      ...state.itemDetails,
      donationDetails: {
        ...state.itemDetails.donationDetails,
        isLoading: true,
      },
    },
  };
};

const loadDonationListSuccess: ItemsReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    itemDetails: {
      ...state.itemDetails,
      donationDetails: {
        isLoading: false,
        ...payload,
      },
    },
  };
};

const loadDonationListFailure: ItemsReducer = (state = initialState) => {
  return {
    ...state,
    itemDetails: {
      ...state.itemDetails,
      donationDetails: {
        isLoading: false,
        donations: [],
        donation_options: {
          id: 0,
          item_id: 0,
          custom_price_enabled: false,
        },
      },
    },
  };
};

const createTemplateStart: ItemsReducer = (state = initialState) => ({
  ...state,
  templates: {
    ...state.templates,
    templateDetails: {
      ...state.templates.templateDetails,
      isSaving: true,
      isCreated: false,
      isExist: false,
    },
  },
});

const createTemplateSuccess: ItemsReducer = (state = initialState, { payload: { id } }) => ({
  ...state,
  templates: {
    ...state.templates,
    templateDetails: {
      ...state.templates.templateDetails,
      isSaving: false,
      isCreated: true,
      isExist: false,
      id,
    },
  },
});

const createTemplateFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  templates: {
    ...state.templates,
    templateDetails: {
      ...state.templates.templateDetails,
      isSaving: false,
      isCreated: false,
    },
  },
});

const setTemplateDetailsStart: ItemsReducer = (state = initialState) => ({
  ...state,
  templates: {
    ...state.templates,
    templateDetails: {
      ...state.templates.templateDetails,
      isFetching: true,
    },
  },
});

const setTemplateDetailsSuccess: ItemsReducer = (state = initialState, { payload }) => {
  // check if access_control_type is not set
  let accessControlTypeId = AccessControlType.PAID;
  if (payload.access_control_type) {
    accessControlTypeId = payload.access_control_type.id;
  }

  return {
    ...state,
    templates: {
      ...state.templates,
      templateDetails: {
        ...state.templates.templateDetails,
        ...payload,
        access_control_type_id: accessControlTypeId,
        metadata: [...(payload?.metadata || [])],
        isFetching: false,
        template_id: payload.preview_id,
      },
    },
  };
};

const fetchTemplatesStart: ItemsReducer = (state = initialState) => ({
  ...state,
  templates: {
    ...state.templates,
    templateCollection: {
      ...state.templates.templateCollection,
      isFetching: true,
    },
  },
});

const fetchTemplatesSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  templates: {
    ...state.templates,
    templateCollection: {
      ...state.templates.templateCollection,
      isFetching: false,
      collection: payload,
    },
  },
});

const fetchTemplatesFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  templates: {
    ...state.templates,
    templateCollection: {
      ...state.templates.templateCollection,
      isFetching: false,
      collection: [],
    },
  },
});

const setTemplateDetailsFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const createTemplatePriceSuccess: ItemsReducer = (state = initialState, { payload }) => {
  const stateTemplatePrices = state.templates.templateDetails.prices;
  const templatePrices = stateTemplatePrices ? [...stateTemplatePrices, payload] : [payload];

  return {
    ...state,
    templates: {
      ...state.templates,
      templateDetails: {
        ...state.templates.templateDetails,
        prices: templatePrices,
        isFetching: false,
      },
    },
  };
};

const updateTemplatePriceSuccess: ItemsReducer = (state = initialState, { payload }) => {
  const templateIndex = state.templates.templateDetails.prices.findIndex((price) => price.id === payload.id);

  return {
    ...state,
    templates: {
      ...state.templates,
      templateDetails: {
        ...state.templates.templateDetails,
        prices: [
          ...state.templates.templateDetails.prices.slice(0, templateIndex),
          payload,
          ...state.templates.templateDetails.prices.slice(templateIndex + 1),
        ],
      },
    },
  };
};

const deleteTemplatePriceSuccess: ItemsReducer = (state = initialState, { payload: { priceId } }) => ({
  ...state,
  templates: {
    ...state.templates,
    templateDetails: {
      ...state.templates.templateDetails,
      prices: state.templates.templateDetails.prices.filter((price) => price.id !== priceId),
    },
  },
});

const setSelectedTemplateFeeId: ItemsReducer = (state = initialState, { id }) => ({
  ...state,
  templates: {
    ...state.templates,
    templateDetails: {
      ...state.templates.templateDetails,
      selectedTemplateFeeId: id,
    },
  },
});

const promptItemEventType: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isSaving: true,
  },
});

const setItemEventTypeSuccess: ItemsReducer = (state = initialState, { payload }) => {
  const { event_type: eventType, event_details: eventDetails } = payload;
  const updatedInitialObj = { ...initialState.itemDetails.event_details, content_category: eventType };

  return {
    ...state,
    itemDetails: {
      ...state.itemDetails,
      event_type: eventType,
      // In case of VOD or Other set content_category gotten from response
      // to the initial event_details object.
      // Otherwise, in case of Live set event_details object gotten from response
      event_details: eventDetails || updatedInitialObj,
      isSaving: false,
    },
  };
};

const setItemEventTypeFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isSaving: false,
  },
});

const promptItemEventDetails: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isSaving: true,
  },
});

const setItemEventDetailsSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    isSaving: false,
    event_details: {
      ...payload,
    },
  },
});

const setItemEventDetailsFailure: ItemsReducer = (state = initialState, { payload }) => {
  const {
    eventDetails,
    eventDetails: { eventDate },
  } = payload;

  return {
    ...state,
    itemDetails: {
      ...state.itemDetails,
      // set previous chosen values for event details
      // to prevent form reset when error occures
      event_details: { ...eventDetails, event_date: eventDate.unix() },
      isSaving: false,
    },
  };
};

const setTemplateTitleSuccess: ItemsReducer = (state = initialState, { payload: { name } }) => ({
  ...state,
  templates: {
    ...state.templates,
    templateDetails: {
      ...state.templates.templateDetails,
      name,
    },
  },
});

const setTemplateMetadataSuccess: ItemsReducer = (state = initialState, { payload: { metadata } }) => {
  const newMetadata = metadata
    ? [...state.templates.templateDetails.metadata, ...metadata]
    : state.templates.templateDetails.metadata;

  return {
    ...state,
    templates: {
      ...state.templates,
      templateDetails: {
        ...state.templates.templateDetails,
        metadata: newMetadata,
      },
    },
  };
};

const deleteTemplateMetadataSuccess: ItemsReducer = (state = initialState, { payload: { keys } }) => {
  const {
    templates: {
      templateDetails: { metadata },
    },
  } = state;

  const newMetadata = metadata.filter((item) => !keys.includes(item.name));

  return {
    ...state,
    templates: {
      ...state.templates,
      templateDetails: {
        ...state.templates.templateDetails,
        metadata: newMetadata,
      },
    },
  };
};

const promptTemplateEventType: ItemsReducer = (state = initialState) => ({
  ...state,
  templates: {
    ...state.templates,
    templateDetails: {
      ...state.templates.templateDetails,
      isSaving: true,
    },
  },
});

const setTemplateEventTypeSuccess: ItemsReducer = (state = initialState, { payload }) => {
  const { event_type: eventType, event_details: eventDetails } = payload;
  const updatedInitialObj = { ...initialState.itemDetails.event_details, content_category: eventType };

  return {
    ...state,
    templates: {
      ...state.templates,
      templateDetails: {
        ...state.templates.templateDetails,
        event_type: eventType,
        // In case of VOD or Other set content_category gotten from response
        // to the initial event_details object.
        // Otherwise, in case of Live set event_details object gotten from response
        event_details: eventDetails || updatedInitialObj,
        isSaving: false,
      },
    },
  };
};

const setTemplateEventTypeFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  templates: {
    ...state.templates,
    templateDetails: {
      ...state.templates.templateDetails,
      isSaving: false,
    },
  },
});

const promptTemplateEventDetails: ItemsReducer = (state = initialState) => ({
  ...state,
  templates: {
    ...state.templates,
    templateDetails: {
      ...state.templates.templateDetails,
      isSaving: true,
    },
  },
});

const setTemplateEventDetailsSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  templates: {
    ...state.templates,
    templateDetails: {
      ...state.templates.templateDetails,
      isSaving: false,
      event_details: {
        ...payload,
      },
    },
  },
});

const setTemplateEventDetailsFailure: ItemsReducer = (state = initialState, { payload }) => {
  const {
    eventDetails,
    eventDetails: { eventDate },
  } = payload;

  return {
    ...state,
    templates: {
      ...state.templates,
      templateDetails: {
        ...state.templates.templateDetails,
        // set previous chosen values for event details
        // to prevent form reset when error occures
        event_details: { ...eventDetails, event_date: eventDate.unix() },
        isSaving: false,
      },
    },
  };
};

const setTemplateRestrictions: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  templates: {
    ...state.templates,
    templateDetails: {
      ...state.templates.templateDetails,
      restrictions: [...values(payload)],
    },
  },
});

const deleteTemplateRestrictions: ItemsReducer = (state = initialState, { payload: { restrictionId } }) => ({
  ...state,
  templates: {
    ...state.templates,
    templateDetails: {
      ...state.templates.templateDetails,
      restrictions: state.templates.templateDetails.restrictions.filter(
        (restriction) => restriction.id !== restrictionId
      ),
    },
  },
});

const promptLiveLikeResources: ItemsReducer = (state = initialState) => ({
  ...state,
  livelikeResources: {
    ...state.livelikeResources,
    chatRooms: {
      ...state.livelikeResources.chatRooms,
      isFetching: true,
    },
  },
});

const failLiveLikeResources: ItemsReducer = (state = initialState) => ({
  ...state,
  livelikeResources: {
    ...state.livelikeResources,
    chatRooms: {
      ...state.livelikeResources.chatRooms,
      isFetched: true,
      isFetching: false,
    },
  },
});

const setLiveLikeResources: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  livelikeResources: {
    ...state.livelikeResources,
    chatRooms: {
      ...state.livelikeResources.chatRooms,
      ...payload,
      isFetched: true,
      isFetching: false,
    },
  },
});

const promptCreateChatRoom: ItemsReducer = (state = initialState) => ({
  ...state,
  livelikeResources: {
    ...state.livelikeResources,
    chatRooms: {
      ...state.livelikeResources.chatRooms,
      isCreating: true,
    },
  },
});

const createChatRoomSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    external_integrations: {
      ...state.itemDetails.external_integrations,
      livelike: {
        ...state.itemDetails.external_integrations.livelike,
        chatrooms: [payload],
      },
    },
  },
  livelikeResources: {
    ...state.livelikeResources,
    chatRooms: {
      ...state.livelikeResources.chatRooms,
      count: payload.count,
      collection: [...state.livelikeResources.chatRooms.collection, payload],
      isCreating: false,
    },
    savedChatroom: {
      ...state.livelikeResources.savedChatroom,
      isFetched: true,
      chatroomData: payload,
    },
  },
});

const createChatRoomFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  livelikeResources: {
    ...state.livelikeResources,
    chatRooms: {
      ...state.livelikeResources.chatRooms,
      isCreating: false,
    },
  },
});

const getChatroomPrompt: ItemsReducer = (state = initialState) => ({
  ...state,
  livelikeResources: {
    ...state.livelikeResources,
    savedChatroom: {
      ...state.livelikeResources.savedChatroom,
      isFetching: true,
    },
  },
});

const getChatroomSuccess: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  livelikeResources: {
    ...state.livelikeResources,
    savedChatroom: {
      ...state.livelikeResources.savedChatroom,
      isFetching: false,
      isFetched: true,
      chatroomData: payload,
    },
  },
});

const getChatroomFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  livelikeResources: {
    ...state.livelikeResources,
    savedChatroom: {
      ...state.livelikeResources.savedChatroom,
      isFetching: false,
      isFetched: true,
    },
  },
});

const deleteLiveLikeResources: ItemsReducer = (state = initialState) => ({
  ...state,
  itemDetails: {
    ...state.itemDetails,
    external_integrations: {
      ...state.itemDetails.external_integrations,
      livelike: {
        ...state.itemDetails.external_integrations.livelike,
        chatrooms: [],
      },
    },
  },
  livelikeResources: {
    ...state.livelikeResources,
    savedChatroom: {
      ...state.livelikeResources.savedChatroom,
      isFetched: false,
      isFetching: false,
      chatroomData: null,
    },
  },
});

const promptPullBrightcoveDetails: ItemsReducer = (state = initialState) => ({
  ...state,
  ovpVideoDetails: {
    ...state.ovpVideoDetails,
    isFromOvp: false,
  },
});

const pullBrightcoveDetailsSuccess: ItemsReducer = (state = initialState) => ({
  ...state,
  ovpVideoDetails: {
    ...state.ovpVideoDetails,
    isFromOvp: true,
  },
});

const pullBrightcoveDetailsFailure: ItemsReducer = (state = initialState) => ({
  ...state,
  ovpVideoDetails: {
    ...state.ovpVideoDetails,
    isFromOvp: false,
  },
});

const startGetIvsChannels: ItemsReducer = (state = initialState) => ({
  ...state,
  ovps: {
    ...state.ovps,
    ivs: {
      ...state.ovps.ivs,
      channels: {
        ...state.ovps.ivs.channels,
        status: {
          ...state.ovps.ivs.channels.status,
          isFetching: true,
        },
      },
    },
  },
});

const successGetIvsChannels: ItemsReducer = (state = initialState, { payload }) => ({
  ...state,
  ovps: {
    ...state.ovps,
    ivs: {
      ...state.ovps.ivs,
      channels: {
        ...state.ovps.ivs.channels,
        status: {
          ...state.ovps.ivs.channels.status,
          isFetching: false,
        },
        list: payload,
      },
    },
  },
});

const errorGetIvsChannels: ItemsReducer = (state = initialState) => ({
  ...state,
  ovps: {
    ...state.ovps,
    ivs: {
      ...state.ovps.ivs,
      channels: {
        ...state.ovps.ivs.channels,
        status: {
          ...state.ovps.ivs.channels.status,
          isFetching: false,
        },
      },
    },
  },
});

const startGetIvsChannelDetails: ItemsReducer = (state = initialState) => ({
  ...state,
  ovps: {
    ...state.ovps,
    ivs: {
      ...state.ovps.ivs,
      channels: {
        ...state.ovps.ivs.channels,
        status: {
          ...state.ovps.ivs.channels.status,
          isFetching: true,
        },
      },
    },
  },
});

const stopGetIvsChannelDetails: ItemsReducer = (state = initialState) => ({
  ...state,
  ovps: {
    ...state.ovps,
    ivs: {
      ...state.ovps.ivs,
      channels: {
        ...state.ovps.ivs.channels,
        status: {
          ...state.ovps.ivs.channels.status,
          isFetching: false,
        },
      },
    },
  },
});

const actionsMap: ActionMap<ItemsState> = {
  [CreateItemActionTypes.START]: promptCreateItem,
  [CreateItemActionTypes.SUCCESS]: createItemSuccess,
  [CreateItemActionTypes.ERROR]: createItemFailure,
  [LoadItemDetailsActionTypes.SUCCESS]: setItemDetailsSuccess,
  [LoadItemDetailsActionTypes.ERROR]: setItemDetailsFailure,
  [LoadItemDetailsActionTypes.START]: promptSetItemDetails,
  [LoadAllAccessFeesActionTypes.SUCCESS]: setAccessFeesSuccess,
  [LoadAllAccessFeesActionTypes.START]: setAccessFeesStart,
  [LoadAllAccessFeesActionTypes.ERROR]: setAccessFeesFailure,
  [LoadJwPlayersActionTypes.START]: promptSetJwtPlayers,
  [LoadJwPlayersActionTypes.SUCCESS]: setJwtPlayersSuccess,
  [LoadJwPlayersActionTypes.ERROR]: setJwtPlayersFailure,
  [UpdateItemMetadataActionTypes.START]: promptSetItemDetails,
  [UpdateItemMetadataActionTypes.SUCCESS]: setItemDetailsSuccess,
  [UpdateItemMetadataActionTypes.ERROR]: setItemDetailsFailure,
  [UpdateItemTitleActionTypes.START]: promptSetItemDetails,
  [UpdateItemTitleActionTypes.SUCCESS]: setItemDetailsSuccess,
  [UpdateItemTitleActionTypes.ERROR]: setItemDetailsFailure,
  [UpdateItemPreviewActionTypes.START]: promptSetItemDetails,
  [UpdateItemPreviewActionTypes.SUCCESS]: setItemDetailsSuccess,
  [UpdateItemPreviewActionTypes.ERROR]: setItemDetailsFailure,
  [LoadSessionChooserActionTypes.START]: promptLoadSessionChooser,
  [LoadSessionChooserActionTypes.SUCCESS]: loadSessionChooserSuccess,
  [LoadSessionChooserActionTypes.ERROR]: loadSessionChooserFailure,
  [LoadItemAccessTypesActionTypes.SUCCESS]: setItemAccessTypes,
  [SET_PANOPTO_CONTENT]: setPanoptoContent,
  [CLEAR_PANOPTO_CONTENT]: clearPanoptoContent,
  [LoadInitialExternalAssetsActionTypes.START]: promptSetInitialExternalAsset,
  [LoadInitialExternalAssetsActionTypes.SUCCESS]: setInitialExternalAssetSuccess,
  [LoadInitialExternalAssetsActionTypes.ERROR]: setInitialExternalAssetFailure,
  [LoadExternalAssetsActionTypes.START]: promptSetExternalAsset,
  [LoadExternalAssetsActionTypes.SUCCESS]: setExternalAssetSuccess,
  [LoadExternalAssetsActionTypes.ERROR]: setExternalAssetFailure,
  [LoadExternalBrightcoveAssetsActionTypes.START]: promptSetExternalBrightcoveAsset,
  [LoadExternalBrightcoveAssetsActionTypes.SUCCESS]: setExternalBrightcoveAssetSuccess,
  [LoadExternalBrightcoveAssetsActionTypes.ERROR]: setExternalBrightcoveAssetFailure,
  [RESET_EXTERNAL_ASSETS]: resetExternalAssets,
  [UpdateAssetContentActionTypes.START]: promptSetAssetContent,
  [UpdateAssetContentActionTypes.SUCCESS]: setAssetContentSuccess,
  [UpdateAssetContentActionTypes.ERROR]: setAssetContentFailure,
  [UploadFileActionTypes.SUCCESS]: uploadFile,
  [LoadAssetsActionTypes.SUCCESS]: setAssetsSuccess,
  [LoadAssetsActionTypes.ERROR]: toggleSetAssets(),
  [LoadAssetsActionTypes.START]: toggleSetAssets(true),
  [ReactivateAssetActionTypes.START]: toggleSetAssets(),
  [ReactivateAssetActionTypes.SUCCESS]: reactivateAssetSuccess,
  [ReactivateAssetActionTypes.ERROR]: reactivateAssetFailure,
  [RESET_ASSET_DETAILS]: resetitemDetails,
  [SyncOvpVideosActionTypes.SUCCESS]: syncOvpVideos,
  [CheckSyncProgressActionTypes.SUCCESS]: checkSyncProgressSuccess,
  [CheckSyncProgressActionTypes.ERROR]: checkSyncProgressFailure,
  [CreateItemAccessFeeActionTypes.START]: promptCreateItemAccessFee,
  [CreateItemAccessFeeActionTypes.SUCCESS]: setCreateItemAccessFeeSuccess,
  [CreateItemAccessFeeActionTypes.ERROR]: setCreateItemAccessFeeFailure,
  [SAVE_SELECTED_FEE_ID]: setSelectedFeeId,
  [UpdateItemAccessFeeActionTypes.START]: promptSetItemDetails,
  [UpdateItemAccessFeeActionTypes.SUCCESS]: updateItemAccessFee,
  [UpdateItemAccessFeeActionTypes.ERROR]: setItemDetailsFailure,
  [RemoveAccessFeeActionTypes.SUCCESS]: removeAccessFeeSuccess,
  [PullOvpDetailsActionTypes.START]: promptSetOvpDetails,
  [PullOvpDetailsActionTypes.SUCCESS]: setOvpDetailsSuccess,
  [PullOvpDetailsActionTypes.ERROR]: setOvpDetailsFailure,
  [SYNC_PROGRESS_DONE]: syncProgressDone,
  [ResetPackageAssetsActionTypes.SUCCESS]: resetPackageAssets,
  [DeleteItemMetadataActionTypes.SUCCESS]: deleteItemMetadataSuccess,
  [ChangeItemPlanActionTypes.START]: changeItemPlanRequest,
  [ChangeItemPlanActionTypes.SUCCESS]: changeItemPlanSuccess,
  [ChangeItemPlanActionTypes.ERROR]: changeItemPlanFailure,
  [ChangeItemContentTypeActionTypes.START]: promptSetItemDetails,
  [ChangeItemContentTypeActionTypes.SUCCESS]: setItemDetailsSuccess,
  [ChangeItemContentTypeActionTypes.ERROR]: setItemDetailsFailure,
  [UpdateItemTemplateIdActionTypes.START]: promptSetItemDetails,
  [UpdateItemTemplateIdActionTypes.SUCCESS]: setItemDetailsSuccess,
  [UpdateItemTemplateIdActionTypes.ERROR]: setItemDetailsFailure,
  [UpdateGiftDetailsActionTypes.SUCCESS]: updateGiftDetails,
  // load codes
  [LoadCodesActionTypes.START]: promptLoadCodes,
  [LoadCodesActionTypes.SUCCESS]: loadCodesSuccess,
  [LoadCodesActionTypes.ERROR]: loadCodesFailure,
  // add code
  [SaveCodeActionTypes.START]: promptCreateCode,
  [SaveCodeActionTypes.SUCCESS]: createCodeSuccess,
  [SaveCodeActionTypes.ERROR]: createCodeFailure,
  // update code
  [UpdateCodeActionTypes.START]: promptUpdateCode,
  [UpdateCodeActionTypes.SUCCESS]: updateCodeSuccess,
  [UpdateCodeActionTypes.ERROR]: updateCodeFailure,
  // update all code - bulk action
  [UpdateAllCodeActionTypes.START]: promptUpdateAllCodes,
  [UpdateAllCodeActionTypes.SUCCESS]: updateAllCodesSuccess,
  [UpdateAllCodeActionTypes.ERROR]: updateAllCodesFailure,
  // load private code sessions
  [LoadCodeSessionsActionTypes.START]: promptLoadCodeSessions,
  [LoadCodeSessionsActionTypes.SUCCESS]: loadCodeSessionsSuccess,
  [LoadCodeSessionsActionTypes.ERROR]: loadCodeSessionsFailure,
  // terminate private code session
  [TerminateCodeSessionActionTypes.SUCCESS]: terminateCodeSessionSuccess,
  // bulk code - upload file
  [BulkCodeActionTypes.START]: promptCreateCode,
  [BulkCodeActionTypes.SUCCESS]: bulkCreateCodeSuccess,
  [BulkCodeActionTypes.ERROR]: createCodeFailure,
  // load bulk codes error files
  [LoadErrorFilesActionTypes.START]: promptLoadErrorFiles,
  [LoadErrorFilesActionTypes.SUCCESS]: loadErrorFilesSuccess,
  [LoadErrorFilesActionTypes.ERROR]: loadErrorFilesFailure,
  // load codes reports
  [LoadCodesReportsTypes.START]: promptLoadCodesReports,
  [LoadCodesReportsTypes.SUCCESS]: loadCodesReportsSuccess,
  [LoadCodesReportsTypes.ERROR]: loadCodesReportsFailure,
  [SET_SELECTED_SECTION_INDEX]: handleSetSelectedSectionIndex,
  [SET_SHOULD_CLOSE_SECTION]: handleSetShouldCloseSection,
  [SET_SHOULD_CLOSE_PANEL]: handleSetShouldClosePanel,
  [GetSeasonPhasesActionTypes.SUCCESS]: setSeasonPhasesSuccess,
  [CreateSeasonPhaseActionTypes.SUCCESS]: createSeasonPhaseSuccess,
  [UpdateSeasonPhaseActionTypes.SUCCESS]: updateSeasonPhaseSuccess,
  // donations
  [GetDonationOptionsActionTypes.START]: loadDonationListStart,
  [GetDonationOptionsActionTypes.SUCCESS]: loadDonationListSuccess,
  [GetDonationOptionsActionTypes.ERROR]: loadDonationListFailure,
  // fetch templates
  [LoadTemplatesActionTypes.START]: fetchTemplatesStart,
  [LoadTemplatesActionTypes.SUCCESS]: fetchTemplatesSuccess,
  [LoadTemplatesActionTypes.ERROR]: fetchTemplatesFailure,
  // create template
  [CreateTemplateActionTypes.START]: createTemplateStart,
  [CreateTemplateActionTypes.SUCCESS]: createTemplateSuccess,
  [CreateTemplateActionTypes.ERROR]: createTemplateFailure,
  // fetch template details
  [LoadTemplateDetailsActionTypes.START]: setTemplateDetailsStart,
  [LoadTemplateDetailsActionTypes.SUCCESS]: setTemplateDetailsSuccess,
  [LoadTemplateDetailsActionTypes.ERROR]: setTemplateDetailsFailure,
  // template price
  [CreateTemplatePriceActionTypes.SUCCESS]: createTemplatePriceSuccess,
  [UpdateTemplatePriceActionTypes.SUCCESS]: updateTemplatePriceSuccess,
  [DeleteTemplatePriceActionTypes.SUCCESS]: deleteTemplatePriceSuccess,
  // save template fee
  [SAVE_SELECTED_TEMPLATE_FEE_ID]: setSelectedTemplateFeeId,
  // Update asset event type
  [UpdateAssetEventTypeActionTypes.START]: promptItemEventType,
  [UpdateAssetEventTypeActionTypes.SUCCESS]: setItemEventTypeSuccess,
  [UpdateAssetEventTypeActionTypes.ERROR]: setItemEventTypeFailure,
  // Update event details
  [UpdateEventDetailsActionTypes.START]: promptItemEventDetails,
  [UpdateEventDetailsActionTypes.SUCCESS]: setItemEventDetailsSuccess,
  [UpdateEventDetailsActionTypes.ERROR]: setItemEventDetailsFailure,
  // Update template title
  [UpdateTemplateTitleActionTypes.START]: setTemplateDetailsStart,
  [UpdateTemplateTitleActionTypes.SUCCESS]: setTemplateTitleSuccess,
  [UpdateTemplateTitleActionTypes.ERROR]: setTemplateDetailsFailure,
  // Update template preview
  [UpdateTemplatePreviewActionTypes.START]: setTemplateDetailsStart,
  [UpdateTemplatePreviewActionTypes.SUCCESS]: setTemplateMetadataSuccess,
  [UpdateTemplatePreviewActionTypes.ERROR]: setTemplateDetailsFailure,
  // Update template metadata
  [UpdateTemplateMetadataActionTypes.START]: setTemplateDetailsStart,
  [UpdateTemplateMetadataActionTypes.SUCCESS]: setTemplateMetadataSuccess,
  [UpdateTemplateMetadataActionTypes.ERROR]: setTemplateDetailsFailure,
  // Delete template metadata
  [DeleteTemplateMetadataActionTypes.SUCCESS]: deleteTemplateMetadataSuccess,
  // Update template event type
  [UpdateTemplateEventTypeActionTypes.START]: promptTemplateEventType,
  [UpdateTemplateEventTypeActionTypes.SUCCESS]: setTemplateEventTypeSuccess,
  [UpdateTemplateEventTypeActionTypes.ERROR]: setTemplateEventTypeFailure,
  // Update template event details
  [UpdateTemplateEventDetailsActionTypes.START]: promptTemplateEventDetails,
  [UpdateTemplateEventDetailsActionTypes.SUCCESS]: setTemplateEventDetailsSuccess,
  [UpdateTemplateEventDetailsActionTypes.ERROR]: setTemplateEventDetailsFailure,
  // Create template restrictions
  [CreateTemplateRestrictionsActionTypes.SUCCESS]: setTemplateRestrictions,
  [UpdateTemplateRestrictionsActionTypes.SUCCESS]: setTemplateRestrictions,
  [DeleteTemplateRestrictionsActionTypes.SUCCESS]: deleteTemplateRestrictions,
  // LiveLike Resources
  [LoadResourceActionTypes.START]: promptLiveLikeResources,
  [LoadResourceActionTypes.SUCCESS]: setLiveLikeResources,
  [LoadResourceActionTypes.ERROR]: failLiveLikeResources,
  [CreateLiveLikeChatroomActionTypes.START]: promptCreateChatRoom,
  [CreateLiveLikeChatroomActionTypes.SUCCESS]: createChatRoomSuccess,
  [CreateLiveLikeChatroomActionTypes.ERROR]: createChatRoomFailure,
  [GetLiveLikeChatroomActionTypes.START]: getChatroomPrompt,
  [GetLiveLikeChatroomActionTypes.SUCCESS]: getChatroomSuccess,
  [GetLiveLikeChatroomActionTypes.ERROR]: getChatroomFailure,
  [DeleteLiveLikeChatroomActionTypes.SUCCESS]: deleteLiveLikeResources,
  [PullBrightcoveDetailsActionTypes.START]: promptPullBrightcoveDetails,
  [PullBrightcoveDetailsActionTypes.SUCCESS]: pullBrightcoveDetailsSuccess,
  [PullBrightcoveDetailsActionTypes.ERROR]: pullBrightcoveDetailsFailure,
  // IVS OVP Resources
  [GetIvsChannelsActionTypes.START]: startGetIvsChannels,
  [GetIvsChannelsActionTypes.SUCCESS]: successGetIvsChannels,
  [GetIvsChannelsActionTypes.ERROR]: errorGetIvsChannels,
  [GetIvsChannelDetailsActionTypes.START]: startGetIvsChannelDetails,
  [GetIvsChannelDetailsActionTypes.SUCCESS]: stopGetIvsChannelDetails,
  [GetIvsChannelDetailsActionTypes.ERROR]: stopGetIvsChannelDetails,
  [UpdateIvsChannelDetailsActionTypes.START]: startGetIvsChannelDetails,
  [UpdateIvsChannelDetailsActionTypes.SUCCESS]: stopGetIvsChannelDetails,
  [UpdateIvsChannelDetailsActionTypes.ERROR]: stopGetIvsChannelDetails,
  [CreateIvsChannelActionTypes.START]: startGetIvsChannelDetails,
  [CreateIvsChannelActionTypes.SUCCESS]: stopGetIvsChannelDetails,
  [CreateIvsChannelActionTypes.ERROR]: stopGetIvsChannelDetails,
};

export default reducerWithActionMap(actionsMap, initialState);
