import { Reducer } from 'redux';
import pullAll from 'lodash/pullAll';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  CUSTOM_FIELD_DETAILS_SUCCESS,
  RESET_FIELD_DETAILS,
  ADD_OPTION_INPUT,
  RESET_OPTION_INPUT,
  REMOVE_OPTION_INPUT,
  RESET_FIELD_COLLECTION,
  LoadCustomFieldsActionTypes,
  AddCustomFieldActionTypes,
  UpdateCustomFieldActionTypes,
  RemoveCustomFieldActionTypes,
} from 'ducks/merchantDucks/customFields/actions';
import CustomFieldsState, { CustomField, CustomFieldVariants } from 'ducks/merchantDucks/customFields/types';

const initialOption = {
  key: '',
  value: '',
};

const initialInput = {
  id: undefined,
  option: initialOption,
};

export const initialState: CustomFieldsState = {
  collection: [
    {
      id: 1,
      label: 'Full name',
      name: 'fullname',
      placeholder: 'Full name',
      required: true,
      type: CustomFieldVariants.TEXT_INPUT,
      default_value: '',
      options: [],
    },
    {
      id: 2,
      label: 'email',
      name: 'email',
      placeholder: 'Email address',
      required: true,
      type: CustomFieldVariants.TEXT_INPUT,
      default_value: '',
      options: [],
    },
    {
      id: 3,
      label: 'password',
      name: 'password',
      placeholder: 'Password',
      required: true,
      type: CustomFieldVariants.TEXT_INPUT,
      default_value: '',
      options: [],
    },
    {
      id: 4,
      label: 'repeatPassword',
      name: 'repeatPassword',
      placeholder: 'Repeat password',
      required: true,
      type: CustomFieldVariants.TEXT_INPUT,
      default_value: '',
      options: [],
    },
  ],
  newCollection: [],
  isSaved: false,
  isFetching: false,
  customFieldDetails: {
    id: 0,
    name: '',
    label: '',
    default_value: '',
    placeholder: '',
    type: CustomFieldVariants.TEXT_INPUT,
    required: false,
    options: [],
    isFetching: false,
  },
  optionInputs: {
    inputs: [],
    nextInput: 0,
  },
};

type CustomFieldsReducer = Reducer<CustomFieldsState>;

const loadCustomFieldsSuccess: CustomFieldsReducer = (state = initialState, { payload: { collection } }) => {
  let startCollection = [...state.collection, ...collection];

  const newCollection = startCollection.filter((item) => ['first_name', 'surname'].includes(item.name));

  if (newCollection.length) {
    startCollection = startCollection.filter((item) => item.name !== 'fullname');
  }

  const confirmEmailField = startCollection.find((field) => field.name === 'email_confirmation');
  if (confirmEmailField) {
    startCollection = startCollection.filter((field) => field.name !== 'email_confirmation');
    const index = startCollection.findIndex((field) => field.name === 'email');
    startCollection.splice(index + 1, 0, confirmEmailField);
  }

  pullAll(startCollection, newCollection);

  return {
    ...state,
    collection: startCollection,
    newCollection,
    isFetching: false,
  };
};

const setIsFetching = (isFetching: boolean): CustomFieldsReducer => (state = initialState) => ({
  ...state,
  isFetching,
});

const setIsSaved = (isSaved: boolean): CustomFieldsReducer => (state = initialState) => ({
  ...state,
  isSaved,
});

const addCustomFieldSuccess: CustomFieldsReducer = (state = initialState, { payload }) => {
  const { id } = payload;
  let { customField } = payload;
  customField = {
    ...customField,
    id,
  };

  let startCollection = [...state.collection, customField];
  const newCollection: Array<any> = startCollection.filter((item) => ['first_name', 'surname'].includes(item.name));

  if (newCollection.length !== 0) {
    startCollection = startCollection.filter((item: any) => item.name !== 'fullname');
  }

  pullAll(startCollection, newCollection);

  return {
    ...state,
    collection: [...startCollection],
    newCollection: [...state.newCollection, ...newCollection],
    isSaved: true,
  };
};

const updateCustomFieldSuccess: CustomFieldsReducer = (state = initialState, { payload }) => {
  const { customField: field } = payload;

  const newCollection = state.collection.map((item, index) =>
    index === state.collection.findIndex((customFieldItem) => customFieldItem.id === payload.id) ? field : item
  );

  return {
    ...state,
    collection: newCollection,
    isSaved: true,
  };
};

const removeCustomFieldSuccess: CustomFieldsReducer = (state = initialState, { payload: { id, merge } }) => {
  const startCollection = state.collection.filter((item) => item.id !== id);

  const mergeCollections = () => {
    if (merge) {
      return {
        collection: [initialState.collection[0], ...startCollection],
        newCollection: [],
      };
    }

    return {
      collection: [...startCollection],
      newCollection: [...state.newCollection],
    };
  };

  return {
    ...state,
    ...mergeCollections(),
  };
};

const loadCustomFieldDetails: CustomFieldsReducer = (state = initialState, { payload: { id } }) => {
  const { collection } = state;
  const customFieldDetails = collection.find((field: CustomField) => field.id === id);

  if (!customFieldDetails) {
    return state;
  }

  return {
    ...state,
    customFieldDetails: {
      ...state.customFieldDetails,
      ...customFieldDetails,
      isFetching: false,
    },
  };
};

const resetCustomFieldDetails: CustomFieldsReducer = (state = initialState) => ({
  ...state,
  customFieldDetails: { ...initialState.customFieldDetails },
});

const addOptionInput: CustomFieldsReducer = (state = initialState, { input }) => {
  const option = {
    ...initialOption,
    ...(input.option || {}),
  };

  const optionInput = {
    ...initialInput,
    id: state.optionInputs.nextInput,
    option,
  };

  return {
    ...state,
    optionInputs: {
      ...state.optionInputs,
      inputs: [...state.optionInputs.inputs, optionInput],
      nextInput: state.optionInputs.nextInput + 1,
    },
  };
};

const removeOptionInput: CustomFieldsReducer = (state = initialState, { payload: { id } }) => {
  const inputs = state.optionInputs.inputs.filter((item) => item.id !== id);

  return {
    ...state,
    optionInputs: {
      ...state.optionInputs,
      nextInput: state.optionInputs.nextInput - 1,
      inputs,
    },
  };
};

const resetOptionInputs: CustomFieldsReducer = (state = initialState) => ({
  ...state,
  optionInputs: {
    ...initialState.optionInputs,
  },
});

const resetFieldCollection: CustomFieldsReducer = (state = initialState) => ({
  ...state,
  collection: [...initialState.collection],
});

const actionsMap: ActionMap<CustomFieldsState> = {
  [LoadCustomFieldsActionTypes.SUCCESS]: loadCustomFieldsSuccess,
  [LoadCustomFieldsActionTypes.START]: setIsFetching(true),
  [LoadCustomFieldsActionTypes.ERROR]: setIsFetching(false),
  [AddCustomFieldActionTypes.START]: setIsSaved(false),
  [AddCustomFieldActionTypes.ERROR]: setIsSaved(false),
  [AddCustomFieldActionTypes.SUCCESS]: addCustomFieldSuccess,
  [UpdateCustomFieldActionTypes.START]: setIsSaved(false),
  [UpdateCustomFieldActionTypes.ERROR]: setIsSaved(false),
  [UpdateCustomFieldActionTypes.SUCCESS]: updateCustomFieldSuccess,
  [RemoveCustomFieldActionTypes.SUCCESS]: removeCustomFieldSuccess,
  [CUSTOM_FIELD_DETAILS_SUCCESS]: loadCustomFieldDetails,
  [RESET_FIELD_DETAILS]: resetCustomFieldDetails,
  [ADD_OPTION_INPUT]: addOptionInput,
  [REMOVE_OPTION_INPUT]: removeOptionInput,
  [RESET_OPTION_INPUT]: resetOptionInputs,
  [RESET_FIELD_COLLECTION]: resetFieldCollection,
};

export default reducerWithActionMap(actionsMap, initialState);
