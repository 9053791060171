import React from 'react';
import styled from 'styled-components';

// components
import Input from 'components/Fields/Input';
import { Colors } from './colors';

const StyledInput = styled(Input)`
  border-radius: 5px;
  > input {
    border: 1px solid ${Colors.black};
    padding: 1rem;
    ::placeholder {
      color: ${Colors.gray};
      font-family: Montserrat, 'sans-serif';
    }
    :focus {
      border-bottom: 1px solid ${Colors.green};
    }
    :hover {
      border: 2px solid ${Colors.black};
      margin: -1px;
    }
  }
`;

const AuthInput = (props: any) => {
  return <StyledInput {...props} />;
};

export default AuthInput;
