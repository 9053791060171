import { Reducer } from 'redux';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  CreateApplicationActionTypes,
  UpdateApplicationActionTypes,
  ApplicationSecretActionTypes,
  ApplicationsActionTypes,
  LoadDomainsActionTypes,
  AddDomainsActionTypes,
  ADD_DOMAIN_INPUT,
} from 'ducks/merchantDucks/oauth/actions';
import OAuthState from 'ducks/merchantDucks/oauth/types';

export const initialState: OAuthState = {
  collection: [],
  applicationDetails: {},
  secret: '',
  domainInputs: {
    inputs: [],
    origins: [],
    nextInput: 0,
  },
  subdomain: '',
};

type OAuthReducer = Reducer<OAuthState>;

const loadApplicationsSuccess: OAuthReducer = (state = initialState, { payload: applicationsList }) => {
  const applicationsListNew = map(applicationsList, (item) => {
    return item;
  });

  return { ...state, collection: applicationsListNew };
};

const loadApplicationSecretStart = (state = initialState) => ({
  ...state,
  secret: '',
});

const loadApplicationSecretSuccess: OAuthReducer = (state = initialState, { payload: { secret } }) => ({
  ...state,
  secret,
});

const createApplicationSuccess: OAuthReducer = (state = initialState, { payload }) => ({
  ...state,
  collection: [...state.collection, { ...payload }],
});

const updateApplicationSuccess: OAuthReducer = (state = initialState, { payload }) => {
  const appIndex = findIndex(state.collection, (app) => app.id === payload.id);

  if (appIndex === -1) {
    return state;
  }

  return {
    ...state,
    collection: [...state.collection.slice(0, appIndex), { ...payload }, ...state.collection.slice(appIndex + 1)],
  };
};

const loadDomainsSuccess: OAuthReducer = (
  state = initialState,
  { payload: { allowed_origins: allowedOrigins, sub_domain_name: subdomain } }
) => {
  const inputs = [...allowedOrigins].map((item, i) => ({
    id: i + 1,
    domain: item,
  }));

  return {
    ...state,
    domainInputs: {
      ...state.domainInputs,
      inputs,
      origins: [...allowedOrigins],
      nextInput: inputs.length + 1,
    },
    subdomain,
  };
};

const addDomainsSuccess: OAuthReducer = (
  state = initialState,
  { payload: { allowed_origins: allowedOrigins, sub_domain_name: subdomain } }
) => ({
  ...state,
  domainInputs: {
    ...state.domainInputs,
    origins: [...allowedOrigins],
    inputs: allowedOrigins.map((domain: any, index: number) => ({ id: index, domain })),
    nextInput: allowedOrigins.length,
  },
  subdomain,
});

const addDomainInput: OAuthReducer = (state = initialState, action) => {
  const {
    payload: { input },
    successData: { isInitial },
  } = action;

  const origins = [...state.domainInputs.origins];
  const inputs = [...state.domainInputs.inputs];

  // Add button click
  if (!isInitial) {
    if (input.domain) {
      origins.push(input.domain);
    }

    inputs.push({
      id: state.domainInputs.nextInput,
      domain: input.domain,
      isNotSaved: input.isNotSaved,
    });
  }

  const nextInput = (inputs.length && inputs[inputs.length - 1].id) + 1;

  return {
    ...state,
    domainInputs: {
      ...state.domainInputs,
      inputs,
      origins,
      nextInput,
    },
  };
};

const actionsMap: ActionMap<OAuthState> = {
  [ApplicationsActionTypes.SUCCESS]: loadApplicationsSuccess,
  [ApplicationSecretActionTypes.START]: loadApplicationSecretStart,
  [ApplicationSecretActionTypes.SUCCESS]: loadApplicationSecretSuccess,
  [CreateApplicationActionTypes.SUCCESS]: createApplicationSuccess,
  [UpdateApplicationActionTypes.SUCCESS]: updateApplicationSuccess,
  [LoadDomainsActionTypes.SUCCESS]: loadDomainsSuccess,
  [AddDomainsActionTypes.SUCCESS]: addDomainsSuccess,
  [ADD_DOMAIN_INPUT]: addDomainInput,
};

export default reducerWithActionMap(actionsMap, initialState);
