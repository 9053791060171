import { API } from 'constants/api';
import http, { ContentType, isAxiosError } from 'utils/http';
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';
import { ApiAction } from 'ducks/types';

// local constants
import { ThirdPartyIntegrationTypes } from 'pages/MerchantPages/Settings/Integrations/ThirdPartyIntegrations/ThirdPartyIntegrationsForm';

// External account details
export enum ExternalAccountDetailsActionTypes {
  START = 'EXTERNAL_ACCOUNT_DETAILS_REQUEST',
  SUCCESS = 'EXTERNAL_ACCOUNT_DETAILS_SUCCESS',
  ERROR = 'EXTERNAL_ACCOUNT_DETAILS_ERROR',
}

export const loadExternalAccount = (ovpName: string): ApiAction<ExternalAccountDetailsActionTypes> => async (
  dispatch
) => {
  const endpoint = API.EXTERNAL_ACCOUNT;
  const successData = { ovp_name: ovpName };

  try {
    dispatch({ type: ExternalAccountDetailsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        ovpName,
      },
    });
    dispatch({
      type: ExternalAccountDetailsActionTypes.SUCCESS,
      payload: { ...response.data, ...successData },
    });
  } catch (_) {
    dispatch({ type: ExternalAccountDetailsActionTypes.ERROR });
  }
};

// Add external account keys
export enum ExternalAccountActionTypes {
  START = 'EXTERNAL_ACCOUNT_REQUEST',
  SUCCESS = 'EXTERNAL_ACCOUNT_SUCCESS',
  ERROR = 'EXTERNAL_ACCOUNT_ERROR',
}

type ExternalAccountTypes = ExternalAccountActionTypes | NotificationActionTypes | ExternalAccountDetailsActionTypes;

export const saveExternalAccount = (ovpName: string, keys: any): ApiAction<ExternalAccountTypes> => async (
  dispatch
) => {
  const endpoint = API.EXTERNAL_ACCOUNT;

  try {
    dispatch({ type: ExternalAccountActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data: { keys, ovp: ovpName },
      pathVariables: {
        ovpName,
      },
      contentType: ContentType.URLENCODED,
      responseType: 'text',
    });
    dispatch({
      type: ExternalAccountActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(loadExternalAccount(ovpName));
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'External account successfully connected.',
      })
    );
  } catch (error) {
    dispatch({ type: ExternalAccountActionTypes.ERROR });

    let message = error.message || 'An unknown error occurred.';

    if (isAxiosError(error)) {
      const { response: { data: { errors: { explain = String(error) } = {} } = {} } = {} } = error;
      message = explain;
    }

    dispatch(
      notifRequested({
        type: 'error',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

export const RESET_EXTERNAL_KEYS = 'RESET_EXTERNAL_KEYS';

export const resetExternalAccountKeys = () => ({ type: RESET_EXTERNAL_KEYS });

// Delete exteranl account
export enum DeleteExternalAccountActionTypes {
  START = 'DELETE_EXTERNAL_ACCOUNT_REQUEST',
  SUCCESS = 'DELETE_EXTERNAL_ACCOUNT_SUCCESS',
  ERROR = 'DELETE_EXTERNAL_ACCOUNT_ERROR',
}

type DeleteExternalAccountTypes = DeleteExternalAccountActionTypes | NotificationActionTypes;

export const removeExternalAccount = (ovpName: string): ApiAction<DeleteExternalAccountTypes> => async (dispatch) => {
  const endpoint = API.EXTERNAL_ACCOUNT;

  try {
    dispatch({ type: DeleteExternalAccountActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        ovpName,
      },
      contentType: ContentType.URLENCODED,
      responseType: 'text',
    });
    dispatch({
      type: DeleteExternalAccountActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'External account successfully deleted.',
      })
    );
  } catch (e) {
    dispatch({ type: DeleteExternalAccountActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'error',
        title: 'ERROR',
        content: e.response.data.message,
      })
    );
  }
};

// Get eventbrite organizations
export enum EventbriteOrganizationsActionTypes {
  START = 'EVENTBRITE_ORGANIZATIONS_REQUEST',
  SUCCESS = 'EVENTBRITE_ORGANIZATIONS_SUCCESS',
  ERROR = 'EVENTBRITE_ORGANIZATIONS_ERROR',
}

export const fetchEventbriteOrganizations = (
  continuationToken?: string
): ApiAction<EventbriteOrganizationsActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_EVENTBRITE_ORGANIZATIONS;
  const paramsObject = { continuation: continuationToken || null };

  try {
    dispatch({ type: EventbriteOrganizationsActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params: paramsObject });
    dispatch({
      type: EventbriteOrganizationsActionTypes.SUCCESS,
      payload: { data: { ...response.data }, isNew: !continuationToken },
    });
  } catch (_) {
    dispatch({ type: EventbriteOrganizationsActionTypes.ERROR });
  }
};

// Get eventbrite videos
export enum EventbriteVideosActionTypes {
  START = 'EVENTBRITE_VIDEOS_REQUEST',
  SUCCESS = 'EVENTBRITE_VIDEOS_SUCCESS',
  ERROR = 'EVENTBRITE_VIDEOS_ERROR',
}

export const fetchEventbriteVideos = (
  organizationId: number,
  continuationToken?: string
): ApiAction<EventbriteVideosActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_EVENTBRITE_VIDEOS;
  const paramsObject = { organization_id: organizationId, limit: 10, continuation: continuationToken || null };

  try {
    dispatch({ type: EventbriteVideosActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      params: paramsObject,
    });
    dispatch({
      type: EventbriteVideosActionTypes.SUCCESS,
      payload: { data: { ...response.data }, isNew: !continuationToken },
    });
  } catch (_) {
    dispatch({ type: EventbriteVideosActionTypes.ERROR });
  }
};

// Create LiveLike account
export enum CreateLiveLikeAccountActionTypes {
  START = 'CREATE_LIVELIKE_ACCOUNT_REQUEST',
  SUCCESS = 'CREATE_LIVELIKE_ACCOUNT_SUCCESS',
  ERROR = 'CREATE_LIVELIKE_ACCOUNT_ERROR',
}

export const createLiveLikeAccount = (): ApiAction<
  CreateLiveLikeAccountActionTypes | NotificationActionTypes
> => async (dispatch) => {
  const endpoint = API.CREATE_LIVELIKE_ACCOUNT;

  try {
    dispatch({ type: CreateLiveLikeAccountActionTypes.START });

    const response = await http.authenticated().post(endpoint, {
      contentType: ContentType.URLENCODED,
      responseType: 'text',
    });

    dispatch({ type: CreateLiveLikeAccountActionTypes.SUCCESS, payload: { ...response.data, ovp_name: 'livelike' } });

    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'External account successfully connected.',
      })
    );
  } catch (error) {
    dispatch({ type: CreateLiveLikeAccountActionTypes.ERROR });

    let message = (error as any)?.message || 'External account was not successfully connected.';

    if (isAxiosError(error as any)) {
      const { response: { data: { errors: { explain = String(error) } = {} } = {} } = {} } = error as any;
      message = explain;
    }

    dispatch(
      notifRequested({
        type: 'error',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Disconnect LiveLike account
export enum DisconnectLiveLikeAccountActionTypes {
  START = 'DISCONNECT_LIVELIKE_ACCOUNT_REQUEST',
  SUCCESS = 'DISCONNECT_LIVELIKE_ACCOUNT_SUCCESS',
  ERROR = 'DISCONNECT_LIVELIKE_ACCOUNT_ERROR',
}

export const disconnectLiveLikeAccount = (): ApiAction<
  DisconnectLiveLikeAccountActionTypes | NotificationActionTypes
> => async (dispatch) => {
  const endpoint = API.DISCONNECT_LIVELIKE_ACCOUNT;

  try {
    dispatch({ type: DisconnectLiveLikeAccountActionTypes.START });

    await http.authenticated().delete(endpoint, {
      contentType: ContentType.URLENCODED,
      responseType: 'text',
    });

    dispatch({
      type: DisconnectLiveLikeAccountActionTypes.SUCCESS,
      payload: { ovpName: ThirdPartyIntegrationTypes.LIVELIKE },
    });

    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'External account successfully disconnected.',
      })
    );
  } catch (error) {
    dispatch({ type: DisconnectLiveLikeAccountActionTypes.ERROR });

    let message = (error as any)?.message || 'Error while attempting to disconnect external account.';

    if (isAxiosError(error as any)) {
      const { response: { data: { errors: { explain = String(error) } = {} } = {} } = {} } = error as any;
      message = explain;
    }

    dispatch(
      notifRequested({
        type: 'error',
        title: 'ERROR',
        content: message,
      })
    );
  }
};
