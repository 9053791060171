import React, { FunctionComponent } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { Formik, FormikHelpers, Field, FormikProps } from 'formik';
import { object, string } from 'yup';

// utils
import isEmpty from 'lodash/isEmpty';

// constants
import Rules from 'restrictions/roles/constants/rules';
import { API_ROOT } from 'constants/index';

// hooks
import useRestrictionRules from 'restrictions/roles/hooks/useRestrictionRules';

// types
import { ButtonConfig } from 'pages/MerchantPages/Settings/Integrations/OvpIntegrations/components/BrightcoveForm';

// styled components
import { DescriptionContainer } from 'pages/MerchantPages/Settings/Integrations/InAppIntegrations/components/GoogleInAppForm';
import { ItalicDescription } from 'components/SharedStyledElements';

// Components
import { Grid, Button } from '@inplayer-org/inplayer-ui';
import Restricted from 'restrictions/roles/components/Restricted';
import TextArea from 'components/Fields/TextArea';
import FieldError from 'components/Fields/FieldError';
import ClipBoardButton from 'components/ClipBoardButton';

const { Container, Cell } = Grid;

interface FormValues {
  secretKey: string;
}

interface OwnProps {
  onSubmit: (data: FormValues) => any;
  appleInAppKeys: { value: string; name: string; id: number };
  merchantUuid: string;
}

const validationSchema = object().shape({
  secretKey: string()
    .required('*Required')
    .test('secretKey', '*Required', (value) => value?.trim().length > 0),
});

type Props = OwnProps;

const AppleInAppForm: FunctionComponent<Props> = ({ onSubmit, appleInAppKeys, merchantUuid }) => {
  const initialValues = {
    secretKey: (appleInAppKeys && appleInAppKeys.value) || '',
  };

  const { hasRule } = useRestrictionRules();

  const onFormSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    await onSubmit(values);
    setSubmitting(false);
  };

  const saveButtonProps: ButtonConfig = {
    label: 'Save',
  };

  const updateButtonProps: ButtonConfig = {
    label: 'Update',
    modifier: 'buttonPrimary',
  };

  const buttonProps = !isEmpty(appleInAppKeys) ? updateButtonProps : saveButtonProps;
  const buttonModifiers = buttonProps.modifier ? [buttonProps.modifier] : [];
  const appleNotificationUrl = `${API_ROOT}v2/hooks/apple-in-app/${merchantUuid}`;

  const isReadOnly = hasRule(Rules.READONLY_IN_APP_INTEGRATIONS);

  return (
    <Formik
      onSubmit={onFormSubmit}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting }: FormikProps<FormValues>) => (
        <form onSubmit={handleSubmit}>
          <Container columns={3} rowGap="1rem">
            <Cell width={3}>
              <Field
                tag="text_app_store_app_specific_shared_secret"
                name="secretKey"
                type="text"
                component={TextArea}
                label="App-Specific Shared Secret"
                disabled={isReadOnly}
              />
              <FieldError name="secretKey" />
            </Cell>
            <Cell width={3}>
              <DescriptionContainer columns={6}>
                <Cell width={1} middle>
                  <ClipBoardButton
                    tag="app_store_copy_url"
                    clipboardText={appleNotificationUrl}
                    tooltipText="Copied!"
                    buttonModifiers={['buttonPrimary']}
                    fullWidth
                    placement="top"
                  >
                    Copy URL
                  </ClipBoardButton>
                </Cell>
                <Cell width={5} left={2}>
                  <ItalicDescription variant="p">
                    <MdInfoOutline />
                    Add this notification URL to your Apple dashboard to connect the InPlayer and Apple platforms.
                  </ItalicDescription>
                </Cell>
              </DescriptionContainer>
            </Cell>
            <Restricted isRestricted={isReadOnly}>
              <Cell width={1} left={3}>
                <Button
                  tag={`button_app_store_${buttonProps.label.toLowerCase()}`}
                  type="submit"
                  buttonModifiers={buttonModifiers}
                  fullWidth
                  disabled={isSubmitting}
                >
                  {buttonProps.label}
                </Button>
              </Cell>
            </Restricted>
          </Container>
        </form>
      )}
    </Formik>
  );
};

export default AppleInAppForm;
