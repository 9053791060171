import { ThunkAction } from 'redux-thunk';
import { replace } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';

import { API } from 'constants/api';
import http, { ContentType, isAxiosError } from 'utils/http';
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';
import { REGISTERED_CUSTOMERS } from 'pages/MerchantPages/Analytics/Customers/CustomersInfoBox';
import { REVENUE } from 'pages/MerchantPages/Analytics/Revenue/RevenueInfoBox';
import { PAYMENTS } from 'pages/MerchantPages/Analytics/Payments/PaymentsInfoBox';
import { SUBSCRIPTIONS } from 'pages/MerchantPages/Analytics/Subscriptions/SubscriptionsInfoBox';
import { ApiAction, RouterApiAction } from 'ducks/types';
import { ReportModalTypes } from 'components/Modals/sharedTypes';
import RootState from 'ducks/RootState';
import { Action } from 'redux';
import { TimePeriodValues } from 'components/Modals/ScheduleReport/options';
import { ScheduleEndDateType } from 'components/Modals/ScheduleReport/ScheduleEndDate';
import { ReportTypes } from 'pages/AdminPages/AdminMerchants/Commercials/options';
import camelCase from 'lodash/camelCase';
import { rfc3339 } from 'utils/rfc3339format';
import { UniqueCustomersReportData } from './types';
import { uploadFileBulk, getSignedUrl } from '../common/api';

// Load payments
export enum LoadPaymentsActionTypes {
  START = 'LOAD_PAYMENTS_REQUEST',
  SUCCESS = 'LOAD_PAYMENTS_SUCCESS',
  ERROR = 'LOAD_PAYMENTS_ERROR',
}

export enum UserType {
  ADMIN = 'Admin',
  MERCHANT = 'Merchant',
}

export interface LoadPaymentsParams {
  startDate: string | number;
  endDate: string | number;
  page: number;
  action?: any;
  filter?: Array<any>;
  size?: number;
  type?: string;
  unique?: boolean;
}

export const loadPayments = (paymentsParams: LoadPaymentsParams): ApiAction<LoadPaymentsActionTypes> => async (
  dispatch
) => {
  const { filter = [], size = 15, type, ...rest } = paymentsParams;
  const params = {
    filter,
    size,
    type: type !== 'all' ? type : '',
    ...rest,
  };

  const endpoint = API.FETCH_PAYMENTS;

  try {
    dispatch({
      type: LoadPaymentsActionTypes.START,
    });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: LoadPaymentsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({
      type: LoadPaymentsActionTypes.ERROR,
    });
  }
};

// Load subscriptions list
export enum LoadSubscriptionsActionTypes {
  START = 'LOAD_SUBSCRIPTIONS_REQUEST',
  SUCCESS = 'LOAD_SUBSCRIPTIONS_SUCCESS',
  ERROR = 'LOAD_SUBSCRIPTIONS_ERROR',
}

export interface LoadSubscriptionsParams {
  startDate: string | number;
  endDate: string | number;
  page?: number;
  filter?: Array<any>;
  size?: number;
  status?: string;
  unique?: boolean;
}

export const loadSubscriptions = ({
  filter = [],
  size = 15,
  status,
  unique,
  ...rest
}: LoadSubscriptionsParams): ApiAction<LoadSubscriptionsActionTypes> => async (dispatch) => {
  const params = {
    filter,
    size,
    status: status !== 'all' ? status : '',
    unique,
    ...rest,
  };

  const endpoint = API.FETCH_SUBSCRIPTIONS;

  try {
    dispatch({ type: LoadSubscriptionsActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: LoadSubscriptionsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadSubscriptionsActionTypes.ERROR });
  }
};

// Load subscriptions count
export enum LoadSubscriptionsCountActionTypes {
  START = 'LOAD_SUBSCRIPTIONS_COUNT_REQUEST',
  SUCCESS = 'LOAD_SUBSCRIPTIONS_COUNT_SUCCESS',
  ERROR = 'LOAD_SUBSCRIPTIONS_COUNT_ERROR',
}

export interface LoadReportsCommonParams {
  startDate?: string | number;
  endDate: string | number;
  interval?: number | string;
}

export const loadSubscriptionsCount = (
  params: LoadReportsCommonParams
): ApiAction<LoadSubscriptionsCountActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_SUBSCRIPTIONS_COUNT;

  try {
    dispatch({ type: LoadSubscriptionsCountActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: LoadSubscriptionsCountActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadSubscriptionsCountActionTypes.ERROR });
  }
};

// Load country subscriptions count
export enum LoadCountrySubscriptionsCountActionTypes {
  START = 'LOAD_COUNTRY_SUBSCRIPTIONS_COUNT_REQUEST',
  SUCCESS = 'LOAD_COUNTRY_SUBSCRIPTIONS_COUNT_SUCCESS',
  ERROR = 'LOAD_COUNTRY_SUBSCRIPTIONS_COUNT_ERROR',
}

export const loadSubscriptionsCountByCountries = (
  params: LoadReportsCommonParams
): ApiAction<LoadCountrySubscriptionsCountActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_SUBSCRIPTIONS_COUNT_COUNTRIES;

  try {
    dispatch({ type: LoadCountrySubscriptionsCountActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: LoadCountrySubscriptionsCountActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadCountrySubscriptionsCountActionTypes.ERROR });
  }
};

// Registered users count
export enum LoadRegisteredUsersCountActionTypes {
  START = 'LOAD_REGISTERED_USERS_COUNT_REQUEST',
  SUCCESS = 'LOAD_REGISTERED_USERS_COUNT_SUCCESS',
  ERROR = 'LOAD_REGISTERED_USERS_COUNT_ERROR',
}

export const loadRegisteredCustomersCount = (
  params: LoadReportsCommonParams
): ApiAction<LoadRegisteredUsersCountActionTypes> => async (dispatch) => {
  const endpoint = API.REGISTERED_USERS_COUNT;

  try {
    dispatch({ type: LoadRegisteredUsersCountActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: LoadRegisteredUsersCountActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadRegisteredUsersCountActionTypes.ERROR });
  }
};

// Load country users count
export enum LoadCountryUsersCountActionTypes {
  START = 'LOAD_COUNTRY_USERS_COUNT_REQUEST',
  SUCCESS = 'LOAD_COUNTRY_USERS_COUNT_SUCCESS',
  ERROR = 'LOAD_COUNTRY_USERS_COUNT_ERROR',
}

export const loadRegisteredCustomersCountByCountries = (
  params: LoadReportsCommonParams
): ApiAction<LoadCountryUsersCountActionTypes> => async (dispatch) => {
  const endpoint = API.COUNTRY_USERS_COUNT;

  try {
    dispatch({ type: LoadCountryUsersCountActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: LoadCountryUsersCountActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadCountryUsersCountActionTypes.ERROR });
  }
};

// Load sale count
export enum LoadSalesCountActionTypes {
  START = 'LOAD_SALES_COUNT_REQUEST',
  SUCCESS = 'LOAD_SALES_COUNT_SUCCESS',
  ERROR = 'LOAD_SALES_COUNT_ERROR',
}

export const loadSalesCount = (params?: LoadReportsCommonParams): ApiAction<LoadSalesCountActionTypes> => async (
  dispatch
) => {
  const endpoint = API.SALES_COUNT;

  try {
    dispatch({ type: LoadSalesCountActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: LoadSalesCountActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadSalesCountActionTypes.ERROR });
  }
};

// Country sale count
export enum LoadCountrySalesCountActionTypes {
  START = 'LOAD_COUNTRY_SALES_COUNT_REQUEST',
  SUCCESS = 'LOAD_COUNTRY_SALES_COUNT_SUCCESS',
  ERROR = 'LOAD_COUNTRY_SALES_COUNT_ERROR',
}

export const loadSalesCountByCountries = (
  params: LoadReportsCommonParams
): ApiAction<LoadCountrySalesCountActionTypes> => async (dispatch) => {
  const endpoint = API.COUNTRY_SALES_COUNT;

  try {
    dispatch({ type: LoadCountrySalesCountActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: LoadCountrySalesCountActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadCountrySalesCountActionTypes.ERROR });
  }
};

// Revenue count
export enum LoadRevenueCountActionTypes {
  START = 'LOAD_REVENUE_COUNT_REQUEST',
  SUCCESS = 'LOAD_REVENUE_COUNT_SUCCESS',
  ERROR = 'LOAD_REVENUE_COUNT_ERROR',
}

export const loadRevenueCount = (params: LoadReportsCommonParams): ApiAction<LoadRevenueCountActionTypes> => async (
  dispatch
) => {
  const endpoint = API.REVENUE_COUNT;

  try {
    dispatch({ type: LoadRevenueCountActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: LoadRevenueCountActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadRevenueCountActionTypes.ERROR });
  }
};

export enum FilterMerchantId {
  SAVE_MERCHANT_ID_ON_FILTER = 'SAVE_MERCHANT_ID_ON_FILTER',
}

export const saveFilterMerchantId = (merchantId: number): Action<FilterMerchantId> & { payload: number } => {
  return {
    type: FilterMerchantId.SAVE_MERCHANT_ID_ON_FILTER,
    payload: merchantId,
  };
};

// Load reports
export enum LoadReportsActionTypes {
  START = 'LOAD_REPORTS_REQUEST',
  SUCCESS = 'LOAD_REPORTS_SUCCESS',
  ERROR = 'LOAD_REPORTS_ERROR',
}

export const loadReports = (type: string, merchantID?: number): ApiAction<LoadReportsActionTypes> => async (
  dispatch
) => {
  // we need to pass reportType in order to update redux store generically
  const reportType = type === ReportTypes.COMMERCIALS ? camelCase(type) : 'csvReports';
  const endpoint = API.REPORTS;
  const pathVariables = { type };
  interface RequestConfigParams {
    pathVariables: { type: string };
    params?: { merchantID: number };
  }

  let requestConfig: RequestConfigParams = {
    pathVariables,
  };

  if (merchantID) {
    const params = { merchantID };
    requestConfig = {
      ...requestConfig,
      params,
    };
  }

  try {
    dispatch({ type: LoadReportsActionTypes.START, payload: { reportType } });
    const response = await http.authenticated().get(endpoint, requestConfig);
    dispatch({
      type: LoadReportsActionTypes.SUCCESS,
      payload: { ...response.data, reportType },
    });
  } catch (_) {
    dispatch({ type: LoadReportsActionTypes.ERROR, payload: { reportType } });
  }
};

export enum StoreEditingScheduledReport {
  STORE = 'STORE_EDITING_SCHEDULING_REPORT',
  RESET = 'RESET_EDITING_SCHEDULED_REPORT',
}

export const storeEditingScheduledReport = (report: any): Action<StoreEditingScheduledReport> & { payload: any } => ({
  type: StoreEditingScheduledReport.STORE,
  payload: report,
});

export const resetEditingScheduledReport = (): Action<StoreEditingScheduledReport> => ({
  type: StoreEditingScheduledReport.RESET,
});

// Load missing transcations
export enum LoadMissingTransactionsActionTypes {
  START = 'LOAD_MISSING_TRANSACTIONS_REQUEST',
  SUCCESS = 'LOAD_MISSING_TRANSACTIONS_SUCCESS',
  ERROR = 'LOAD_MISSING_TRANSACTIONS_ERROR',
}

export const loadMissingTransactions = (page?: number): ApiAction<LoadMissingTransactionsActionTypes> => async (
  dispatch
) => {
  const endpoint = API.MISSING_TRANSACTIONS(page ?? 1);

  try {
    dispatch({ type: LoadMissingTransactionsActionTypes.START });
    const response = await http.authenticated().get(endpoint);
    dispatch({
      type: LoadMissingTransactionsActionTypes.SUCCESS,
      payload: response.data,
    });
  } catch (_) {
    dispatch({ type: LoadMissingTransactionsActionTypes.ERROR });
  }
};

// Import missing transaction
export enum ImportMissingTransactionActionTypes {
  START = 'IMPORT_MISSING_TRANSACTION_REQUEST',
  SUCCESS = 'IMPORT_MISSING_TRANSACTION_SUCCESS',
  ERROR = 'IMPORT_MISSING_TRANSACTION_ERROR',
}

type ImportMissingTransactionTypes = ImportMissingTransactionActionTypes | NotificationActionTypes;

export const importMissingTransaction = (file: File): ApiAction<ImportMissingTransactionTypes> => async (dispatch) => {
  const endpoint = API.IMPORT_MISSING_TRANSACTION(file.name);

  try {
    dispatch({ type: ImportMissingTransactionActionTypes.START });
    const response = await getSignedUrl(endpoint);
    const { url: signedUrl, headers } = response;
    await uploadFileBulk(signedUrl, file, headers);
    dispatch({
      type: ImportMissingTransactionActionTypes.SUCCESS,
      payload: response.data,
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'File uploaded successfully',
        type: 'success',
      })
    );
    setTimeout(() => {
      dispatch(loadMissingTransactions());
    }, 1000);
  } catch (_) {
    dispatch({ type: ImportMissingTransactionActionTypes.ERROR });
    notifRequested({
      title: 'ERROR',
      content: 'File upload failed',
      type: 'danger',
    });
  }
};

// Load scheduled reports
export enum LoadScheduledReportsActionTypes {
  START = 'LOAD_SCHEDULED_REPORTS_REQUEST',
  SUCCESS = 'LOAD_SCHEDULED_REPORTS_SUCCESS',
  ERROR = 'LOAD_SCHEDULED_REPORTS_ERROR',
}

export const loadScheduledReports = (type: string): ApiAction<LoadScheduledReportsActionTypes> => async (dispatch) => {
  const endpoint = API.LIST_SCHEDULED_REPORTS;

  try {
    dispatch({ type: LoadScheduledReportsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      params: {
        report_type: type,
      },
    });
    dispatch({
      type: LoadScheduledReportsActionTypes.SUCCESS,
      payload: response.data,
    });
  } catch (_) {
    dispatch({ type: LoadScheduledReportsActionTypes.ERROR });
  }
};

export enum DeleteScheduledReportActionTypes {
  START = 'DELETE_SCHEDULED_REPORT_REQUEST',
  SUCCESS = 'DELETE_SCHEDULED_REPORT_SUCCESS',
  ERROR = 'DELETE_SCHEDULED_REPORT_ERROR',
}

type DeleteScheduledReportAction = DeleteScheduledReportActionTypes | NotificationActionTypes;

export const deleteScheduledReport = (reportId: number, type: string): ApiAction<DeleteScheduledReportAction> => async (
  dispatch
) => {
  const endpoint = API.DELETE_SCHEDULED_REPORT;

  try {
    dispatch({ type: DeleteScheduledReportActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      pathVariables: { report_id: reportId },
      contentType: ContentType.URLENCODED,
    });
    const { message: successMessage } = response.data;
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: successMessage,
      })
    );
    dispatch(loadScheduledReports(type));
  } catch (_) {
    dispatch({ type: DeleteScheduledReportActionTypes.ERROR });
  }
};

export interface RenameReportParams {
  id: number;
  fieldName: string;
  newFieldName: string;
  reportType: string;
  merchantId?: number;
}

export enum RenameReportTypes {
  SUCCESS = 'RENAME_REPORT_SUCCESS',
  ERROR = 'RENAME_REPORT_ERROR',
}

type RenameReportAction = Action<RenameReportTypes | NotificationActionTypes>;

export interface RenameReportsSuccessData {
  id: number;
  newFieldName: string;
}
export const renameReport = ({
  id,
  fieldName,
  newFieldName,
  reportType,
  merchantId = 0,
}: RenameReportParams): ThunkAction<Promise<any>, RootState, any, RenameReportAction> => async (dispatch) => {
  const endpoint = API.RENAME_REPORT;
  interface RequestConfigParams {
    pathVariables: { report_type: string };
    data: {
      file_name: string;
      new_file_name: string;
    };
    contentType: ContentType;
    params?: { merchant_id: number };
  }

  const requestConfig: RequestConfigParams = {
    pathVariables: { report_type: reportType },
    data: {
      file_name: fieldName,
      new_file_name: newFieldName,
    },
    contentType: ContentType.URLENCODED,
    ...(merchantId && { params: { merchant_id: merchantId } }),
  };

  const successData: RenameReportsSuccessData = {
    id,
    newFieldName,
  };

  try {
    const response = await http.authenticated().put(endpoint, requestConfig);
    dispatch({ type: RenameReportTypes.SUCCESS, payload: successData });
    const { message: successMessage } = response.data;
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: successMessage,
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

export const loadUniqueAudienceReports = (): ApiAction<LoadReportsActionTypes> => async (dispatch) => {
  const endpoint = API.GET_GENERATED_FILES_FOR_UNIQUE_AUDIENCE;
  const reportType = 'csvReports';

  try {
    dispatch({ type: LoadReportsActionTypes.START, payload: { reportType } });
    const response = await http.authenticated().get(endpoint);

    const mappedResponseData =
      response.data?.map((data: UniqueCustomersReportData) => ({
        filename: data.name,
        created_at: data.created_at,
      })) ?? [];

    dispatch({
      type: LoadReportsActionTypes.SUCCESS,
      payload: {
        reports: [...mappedResponseData],
        reportType,
      },
    });
  } catch (_) {
    dispatch({ type: LoadReportsActionTypes.ERROR, payload: { reportType } });
  }
};

export const downloadReport = async (filename: string) => {
  const endpoint = `${API.GET_URL_FOR_UNIQUE_AUDIENCE_REPORT}/${filename}`;

  const response = await http.authenticated().get(endpoint);

  return response.data.redirect_url;
};

// Delete report
export enum RemoveReportActionTypes {
  START = 'REMOVE_REPORT_REQUEST',
  SUCCESS = 'REMOVE_REPORT_SUCCESS',
  ERROR = 'REMOVE_REPORT_ERROR',
}

type RemoveReportTypes = RemoveReportActionTypes | NotificationActionTypes;

export interface RemoveReportParams {
  type: string;
  filename: string;
  token?: string | null;
}

export const removeReport = ({ type, filename, token }: RemoveReportParams): ApiAction<RemoveReportTypes> => async (
  dispatch
) => {
  const isDeleteUniqueCustomers = type === ReportModalTypes.UniqueCustomers;
  const endpoint = isDeleteUniqueCustomers ? API.DELETE_UNIQUE_CUSTOMERS_REPORT : API.DELETE_REPORT;
  const pathVariables = {
    filename,
    ...(!isDeleteUniqueCustomers && { type }),
  };
  try {
    dispatch({ type: RemoveReportActionTypes.START });

    const { data: responseData } = await http.authenticated().delete(endpoint, {
      pathVariables,
      ...(!isDeleteUniqueCustomers && { params: { token } }),
      contentType: ContentType.URLENCODED,
    });

    dispatch({
      type: RemoveReportActionTypes.SUCCESS,
      payload: { responseData, filename },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Report was deleted successfully',
        type: 'success',
      })
    );
  } catch (error) {
    dispatch({ type: RemoveReportActionTypes.ERROR });

    let message = 'An unknown error occurred.';
    if (isAxiosError(error)) {
      const { response } = error;

      if (response && response.data) {
        message = response.data.message;
      }
    }

    dispatch(notifRequested({ title: 'ERROR', content: message, type: 'danger' }));
  }
};

// Load payment details
export enum LoadPaymentDetailsActionTypes {
  START = 'LOAD_PAYMENT_DETAILS_REQUEST',
  SUCCESS = 'LOAD_PAYMENT_DETAILS_SUCCESS',
  ERROR = 'LOAD_PAYMENT_DETAILS_ERROR',
}

export const loadPaymentDetails = (id: string): RouterApiAction<LoadPaymentDetailsActionTypes> => async (dispatch) => {
  const endpoint = API.PAYMENT_DETAILS;
  const pathVariables = { id };

  try {
    dispatch({ type: LoadPaymentDetailsActionTypes.START });
    const { data } = await http.authenticated().get(endpoint, { pathVariables });
    if (isEmpty(data)) {
      dispatch(replace(`/404.html`));
    } else {
      dispatch({
        type: LoadPaymentDetailsActionTypes.SUCCESS,
        payload: { ...data },
      });
    }
  } catch (_) {
    dispatch({ type: LoadPaymentDetailsActionTypes.ERROR });
  }
};

// Load report info
export enum LoadReportInformationActionTypes {
  START = 'LOAD_REPORT_INFORMATION_REQUEST',
  SUCCESS = 'LOAD_REPORT_INFORMATION_SUCCESS',
  ERROR = 'LOAD_REPORT_INFORMATION_ERROR',
}

export const loadReportInformations = (
  params: LoadReportsCommonParams
): ApiAction<LoadReportInformationActionTypes> => async (dispatch) => {
  const endpoint = API.REPORT_INFORMATION;

  try {
    dispatch({ type: LoadReportInformationActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: LoadReportInformationActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadReportInformationActionTypes.ERROR });
  }
};

// Load revenue
export enum LoadRevenueActionTypes {
  START = 'LOAD_REVENUE_REQUEST',
  SUCCESS = 'LOAD_REVENUE_SUCCESS',
  ERROR = 'LOAD_REVENUE_ERROR',
}

export const loadRevenuePerCurrency = (params: LoadReportsCommonParams): ApiAction<LoadRevenueActionTypes> => async (
  dispatch
) => {
  const endpoint = API.REVENUE;

  try {
    dispatch({ type: LoadRevenueActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: LoadRevenueActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadRevenueActionTypes.ERROR });
  }
};

// Generate report
export enum GenerateReportActionTypes {
  START = 'GENERATE_REPORT_REQUEST',
  SUCCESS = 'GENERATE_REPORT_SUCCESS',
  ERROR = 'GENERATE_REPORT_ERROR',
}

type GenerateReportTypes = GenerateReportActionTypes | NotificationActionTypes;

export interface GenerateReportInfoParams {
  reportType: string;
  startDate?: string | number;
  endDate?: string | number;
  reportFields?: Array<any>;
  oauthApp?: string;
  status?: string;
  type?: string;
  unique?: boolean;
  actionType?: string;
  rangeBy?: string;
  exportType?: string;
  merchantID?: number;
  filterParams?: any;
}

interface DataParams {
  unique: boolean;
  startDate?: string | number;
  endDate?: string | number;
  merchantID?: number;
  reportFields?: Array<any>;
  client_id?: string;
  status?: string;
  type?: string;
  action_type?: string;
  range_by?: string;
  export_type?: string;
  paying_customers?: string;
  filterParams?: any;
}

export const generateReportInformation = ({
  reportType,
  startDate,
  endDate,
  reportFields,
  oauthApp,
  status = 'all',
  type = 'all',
  unique = false,
  actionType,
  rangeBy,
  exportType,
  merchantID,
  filterParams = null,
}: GenerateReportInfoParams): ApiAction<GenerateReportTypes> => async (dispatch) => {
  const endpoint = API.GENERATE_REPORT;
  const pathVariables = { reportType };
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

  const data: DataParams = {
    unique,
  };

  if (startDate) {
    data.startDate = startDate;
  }

  if (endDate) {
    data.endDate = endDate;
  }

  if (merchantID) {
    data.merchantID = merchantID;
  }

  if (reportFields) {
    data.reportFields = reportFields;
  }

  if (oauthApp) {
    data.client_id = oauthApp;
  }

  if (reportType === 'access' && status !== 'all') {
    data.status = status;
    if (type !== 'all') {
      data.type = type;
    }
  }

  if (actionType) {
    data.action_type = actionType;
  }

  if (rangeBy) {
    data.range_by = rangeBy;
  }

  if (exportType) {
    data.export_type = exportType;
  }

  if (exportType === 'registered-only-customers') {
    data.paying_customers = 'false';
  }

  try {
    dispatch({ type: GenerateReportActionTypes.START });

    const response = await http.authenticated().post(endpoint, {
      headers,
      data: {
        ...data,
        ...filterParams,
      },
      pathVariables,
      contentType: ContentType.URLENCODED,
    });

    dispatch({
      type: GenerateReportActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Generating report. Please wait.',
        type: 'success',
      })
    );
  } catch (error) {
    dispatch({ type: GenerateReportActionTypes.ERROR });

    let message = 'An unknown error occurred.';
    if (isAxiosError(error)) {
      const { response } = error;

      if (response && response.data) {
        message = response.data.message;
      }
    }

    dispatch(notifRequested({ title: 'ERROR', content: message, type: 'danger' }));
  }
};

export enum ScheduleReportActionTypes {
  START = 'SCHEDULE_REPORT_REQUEST',
  SUCCESS = 'SCHEDULE_REPORT_SUCCESS',
  ERROR = 'SCHEDULE_REPORT_ERROR',
}

type ScheduleReportTypes = ScheduleReportActionTypes | NotificationActionTypes;

export interface SchedulingReportsParams {
  reportId?: boolean;
  reportName?: string;
  type: string;
  recurring: boolean;
  dateTime: string;
  dataForDuration: string;
  dataForPeriodValue: TimePeriodValues;
  repeatDuration: string;
  repeatValue: TimePeriodValues;
  endDateType: ScheduleEndDateType;
  endDate: string;
  endDateAfterValue: number;
  isCustomPeriodSelected: boolean;
  filters?: any;
}

export const scheduleReport = (params: SchedulingReportsParams): ApiAction<ScheduleReportTypes> => async (dispatch) => {
  const {
    recurring,
    dateTime,
    dataForDuration,
    dataForPeriodValue,
    type: reportType,
    repeatDuration,
    repeatValue,
    reportName,
    reportId = 0,
    endDateType,
    endDateAfterValue,
    endDate,
    isCustomPeriodSelected,
    filters = null,
  } = params;
  const endpoint = API.SCHEDULE_REPORT;
  const pathVariables = { report_type: reportType };

  const data = {
    report_id: reportId,
    ...(reportName && {
      report_name: reportName,
    }),
    start_date_resolution: dataForPeriodValue,
    start_date_resolution_before: dataForDuration,
    scheduled_at: rfc3339(dateTime),
    custom_start_date_resolution: isCustomPeriodSelected,
    ...(recurring && {
      recurrent: true,
      scheduled_resolution: repeatValue,
      scheduled_resolution_before: repeatDuration,
      recurrent_end_type: endDateType,
      recurrent_occurrences: endDateAfterValue,
      recurrent_end_date: rfc3339(endDate),
    }),
    ...(filters && {
      ...filters,
    }),
  };

  try {
    dispatch({ type: ScheduleReportActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      pathVariables,
      data,
      contentType: ContentType.URLENCODED,
    });
    const {
      data: { message },
    } = response;

    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: message,
        type: 'success',
      })
    );
  } catch (e) {
    dispatch({ type: ScheduleReportActionTypes.ERROR });
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch(notifRequested({ title: 'ERROR', content: message, type: 'danger' }));
  }
};

// Generate Commercial report
export enum CommercialReportActionTypes {
  START = 'COMMERCIAL_REPORT_REQUEST',
  SUCCESS = 'COMMERCIAL_REPORT_SUCCESS',
  ERROR = 'COMMERCIAL_REPORT_ERROR',
}

type CommercialReportTypes = CommercialReportActionTypes | NotificationActionTypes;

export const generateCommercialReport = (date: string): ApiAction<CommercialReportTypes> => async (dispatch) => {
  const endpoint = API.GENERATE_COMMERCIAL_REPORT;
  const pathVariables = { report_type: ReportTypes.COMMERCIALS };

  const data = {
    date: rfc3339(date),
  };

  try {
    dispatch({ type: CommercialReportActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      pathVariables,
      data,
      contentType: ContentType.URLENCODED,
    });
    const {
      data: { message },
    } = response;

    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: message,
        type: 'success',
      })
    );

    dispatch(loadReports(ReportTypes.COMMERCIALS));
  } catch (e) {
    dispatch({ type: CommercialReportActionTypes.ERROR });
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch(notifRequested({ title: 'ERROR', content: message, type: 'danger' }));
  }
};

// Load report fields
export enum LoadReportFieldsActionTypes {
  START = 'LOAD_REPORT_FIELDS_REQUEST',
  SUCCESS = 'LOAD_REPORT_FIELDS_SUCCESS',
  ERROR = 'LOAD_REPORT_FIELDS_ERROR',
}

export const loadReportFields = (reportType: string): ApiAction<LoadReportFieldsActionTypes> => async (dispatch) => {
  const endpoint = API.GET_REPORT_FIELDS;
  const pathVariables = { reportType };

  try {
    dispatch({ type: LoadReportFieldsActionTypes.START });
    const response = await http.authenticated().get(endpoint, { pathVariables });
    dispatch({
      type: LoadReportFieldsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadReportFieldsActionTypes.ERROR });
  }
};

// Generate unique audience report
export interface GenerateUniqueAudienceReportParams {
  itemId: number | null;
  startDate: string;
  endDate: string;
}

export enum GenerateUniqueAudienceReportActionTypes {
  START = 'LOAD_UNIQUE_AUDIENCE_REQUEST',
  SUCCESS = 'LOAD_UNIQUE_AUDIENCE_SUCCESS',
  ERROR = 'LOAD_UNIQUE_AUDIENCE_ERROR',
}

type GenerateUniqueAudienceReportTyes = GenerateUniqueAudienceReportActionTypes | NotificationActionTypes;

export const generateUniqueAudienceReport = ({
  itemId,
  startDate,
  endDate,
}: GenerateUniqueAudienceReportParams): ApiAction<GenerateUniqueAudienceReportTyes> => async (dispatch) => {
  const endpoint = API.GENERATE_UNIQUE_AUDIENCE_REPORT;
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

  const data: any = {
    item_id: itemId,
    start_date: startDate,
    end_date: endDate,
  };

  try {
    dispatch({ type: GenerateUniqueAudienceReportActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      headers,
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: GenerateUniqueAudienceReportActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Generating report. Please wait.',
        type: 'success',
      })
    );
  } catch (error) {
    dispatch({ type: GenerateUniqueAudienceReportActionTypes.ERROR });

    let message = 'An unknown error occurred.';
    if (isAxiosError(error)) {
      const { response } = error;

      if (response && response.data) {
        message = response.data.message;
      }
      dispatch(notifRequested({ title: 'ERROR', content: message, type: 'danger' }));
    }
  }
};

// Fetch report
export interface FetchReportParams {
  icon: number;
  startDate: string | number;
  endDate: string | number;
  interval: number | string;
  lineChart: boolean;
}

type FetchReportActionTypes =
  | LoadCountryUsersCountActionTypes
  | LoadRegisteredUsersCountActionTypes
  | LoadCountrySalesCountActionTypes
  | LoadRevenueCountActionTypes
  | LoadSalesCountActionTypes
  | LoadCountrySubscriptionsCountActionTypes
  | LoadSubscriptionsCountActionTypes;

type FetchReportReturnCasesType = (params: LoadReportsCommonParams) => ApiAction<FetchReportActionTypes>;

export const fetchReport = ({
  icon,
  startDate,
  endDate,
  interval,
  lineChart,
}: FetchReportParams): ApiAction<FetchReportActionTypes> => async (dispatch) => {
  const cases: { [key: string]: FetchReportReturnCasesType } = {
    [REGISTERED_CUSTOMERS]: lineChart ? loadRegisteredCustomersCount : loadRegisteredCustomersCountByCountries,
    [REVENUE]: lineChart ? loadRevenueCount : loadSalesCountByCountries,
    [PAYMENTS]: lineChart ? loadSalesCount : loadSalesCountByCountries,
    [SUBSCRIPTIONS]: lineChart ? loadSubscriptionsCount : loadSubscriptionsCountByCountries,
  };

  const actionFn = cases[icon];
  const params = lineChart ? { startDate, endDate, interval } : { startDate, endDate };

  await dispatch(actionFn(params));
};

export const RESET_REPORT_FIELDS = 'RESET_REPORT_FIELDS';

export const resetReportFields = () => ({
  type: RESET_REPORT_FIELDS,
});
