export const restrictions = {
  SAVE_COUNTRY: 'restrictions/geo/set',
  COUNTRY: 'restrictions/geo/set/{countrySetId}',
  DELETE_COUNTRY: 'restrictions/geo/country-set/{countrySetId}/country/{country}',
  SAVE_DOMAIN_REST: 'restrictions/domain/{type}',
  DOMAINS_REST: 'restrictions/domain',
  SAVE_ASSET_COUNTRY: 'restrictions/geo/country-set/{countrySetId}/item/{itemId}',
  SAVE_ASSET_DOMAINS: 'restrictions/domain/{type}/item/{itemId}',
  ASSSET_COUNTRY_SET: 'restrictions/geo/items/{itemId}/country-set',
  ASSET_DOMAINS: 'restrictions/domain/item/{itemId}',
  DELETE_ASSET_COUNTRY: 'restrictions/geo/country-set/item/{itemId}',
  AGE_RESTRICTION: '/restrictions/age',
  ASSET_AGE_RESTRICTION: '/restrictions/age/item/{assetId}',
  AGE_RESTRICTION_ENABLED: '/restrictions/settings/{tenantUuid}',
  // templates
  CREATE_TEMPLATE_RESTRICTIONS: '/v2/items/templates/{template_id}/restrictions',
  DELETE_TEMPLATE_RESTRICTIONS: '/v2/items/templates/{template_id}/restrictions/{restriction_id}',
};
