import React, { FunctionComponent } from 'react';
import { object, string } from 'yup';
import { Formik, FormikHelpers, Field, FormikProps } from 'formik';
import { Grid } from '@inplayer-org/inplayer-ui';

// components
import FieldError from 'components/Fields/FieldError';
import FormContainer from 'pages/MerchantPages/Authentication/components/FormContainer';
import Input from 'pages/MerchantPages/Authentication/components/Input';
import Button from 'pages/MerchantPages/Authentication/components/Button';
import { StyledNavLink, ButtonWrapper } from 'pages/MerchantPages/Authentication/components/sharedComponents';

const { Container, Cell } = Grid;

export interface ForgotPasswordFormValues {
  email: string;
}

interface OwnProps {
  onSubmit: (data: ForgotPasswordFormValues) => any;
}

type Props = OwnProps;

const validationSchema = object().shape({
  email: string().email('Please enter a valid email address').required('*Required'),
});

export const ForgotPasswordForm: FunctionComponent<Props> = ({ onSubmit }) => {
  const initialValues = {
    email: '',
  };

  const onFormSubmit = async (
    values: ForgotPasswordFormValues,
    { setSubmitting }: FormikHelpers<ForgotPasswordFormValues>
  ) => {
    await onSubmit(values);
    setSubmitting(false);
  };

  return (
    <Formik onSubmit={onFormSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ handleSubmit, isSubmitting }: FormikProps<ForgotPasswordFormValues>) => (
        <FormContainer description="Recover your account." title="Forgot your password?" paddingTop="5rem">
          <form onSubmit={handleSubmit}>
            <Container alignContent="space-around" columns={1} gap="1em">
              <Cell>
                <Field name="email" type="text" component={Input} placeholder="Email" />
                <FieldError name="email" />
              </Cell>
              <Cell center>
                <StyledNavLink to="/login">Back to login</StyledNavLink>
              </Cell>
              <ButtonWrapper textAlign="center">
                <Button
                  buttonModifiers={['buttonPrimary']}
                  type="submit"
                  disabled={isSubmitting}
                  padding="1rem 2.75rem"
                >
                  Reset Password
                </Button>
              </ButtonWrapper>
            </Container>
          </form>
        </FormContainer>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
