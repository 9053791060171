import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import difference from 'lodash/differenceBy';
import { useParams } from 'react-router';

// hooks
import useRestrictionRules from 'restrictions/roles/hooks/useRestrictionRules';

// constants
import Rules from 'restrictions/roles/constants/rules';

// actions
import {
  loadExcludedRules,
  loadIncludedRules,
  addVoucherRule,
  loadVoucherAccessTypes,
  removeAllVoucherRules,
  deleteVoucherRule,
  deleteItemRule,
} from 'ducks/merchantDucks/vouchers/actions';
import { openModal } from 'ducks/merchantDucks/modal/actions';

// selectors
import {
  getAvailableItems,
  getTotalAvailableItems,
  getAddedItems,
  getIsFetchingExcluded,
  getIsFetchingIncluded,
  getTotalAddedItems,
} from 'ducks/merchantDucks/vouchers/selectors';

// utils
import { Optional } from 'utils/types';
import { transformSearchParams } from 'utils/helpers';

// types
import { ModalNames } from 'ducks/merchantDucks/modal/types';

// components
import ConfirmModal from 'components/Modals/ConfirmModal';
import DroppableColumn from './VoucherDroppableColumn';

const DragDropContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  padding: 2rem 6%;
`;

export enum DroppableNames {
  available = 'Available',
  added = 'Added',
}

export enum RuleTypes {
  assets = 'assets',
  packages = 'packages',
  accessTypes = 'access-types',
  emails = 'emails',
  accessFees = 'access-fees',
}

interface SearchParams {
  id: Optional<number>;
  title: string;
}

interface Props {
  ruleType: string;
}

const accessTypes = ['ppv', 'subscription'];

const VoucherDragAndDropContainer = ({ ruleType }: Props) => {
  const { id } = useParams();
  const voucherId = Number(id);

  const { hasRule } = useRestrictionRules();
  const isReadOnly = hasRule(Rules.READONLY_VOUCHERS);

  const dispatch = useDispatch();

  const [availableItemsPage, setAvailableItemsPage] = useState(1);
  const [addedItemsPage, setAddedItemsPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [availableAccessTypes, setAvailableAccessTypes] = useState<Array<any>>([]);
  const [availableItemsTags, setAvailableItemsTags] = useState<SearchParams>({ id: null, title: '' });
  const [addedItemsTags, setAddedItemsTags] = useState<SearchParams>({ id: null, title: '' });

  const available = useSelector(getAvailableItems);
  const totalAvailableItems = useSelector(getTotalAvailableItems);
  const addedItems = useSelector(getAddedItems);
  const totalAddedItems = useSelector(getTotalAddedItems);
  const isFetchingExcluded = useSelector(getIsFetchingExcluded);
  const isFetchingIncluded = useSelector(getIsFetchingIncluded);

  const isAccessTypesSection = ruleType === RuleTypes.accessTypes;
  const availableItems = ruleType !== RuleTypes.accessTypes ? available : availableAccessTypes;

  const loadItems = () => {
    if (!isAccessTypesSection) {
      dispatch(loadExcludedRules({ voucherId, ruleType }));
      dispatch(loadIncludedRules({ voucherId, ruleType }));
    } else {
      dispatch(loadVoucherAccessTypes(voucherId));
    }
  };

  useEffect(() => {
    loadItems();
  }, []);

  useEffect(() => {
    if (isAccessTypesSection) {
      const availableTypes = difference(
        accessTypes,
        addedItems.map((item: any) => item.title)
      );
      setAvailableAccessTypes(availableTypes.map((item: any) => ({ id: item, title: item, iconName: item })));
    }
  }, [addedItems]);

  const handleLoadMore = (droppableId: string) => () => {
    if (droppableId === DroppableNames.available) {
      const search = transformSearchParams(availableItemsTags);
      dispatch(loadExcludedRules({ voucherId, page: availableItemsPage + 1, ruleType, search }));
      setAvailableItemsPage(availableItemsPage + 1);
    } else {
      const search = transformSearchParams(addedItemsTags);
      dispatch(loadIncludedRules({ voucherId, ruleType, page: addedItemsPage + 1, search }));
      setAddedItemsPage(addedItemsPage + 1);
    }
  };

  const handleAddition = (droppableId: string) => (searchParams: SearchParams) => {
    const isAvailableContainer = droppableId === DroppableNames.available;
    const search = transformSearchParams(searchParams);

    if (isAvailableContainer) {
      setAvailableItemsTags(searchParams);
      setAvailableItemsPage(1);
      dispatch(loadExcludedRules({ voucherId, ruleType, search }));
    } else {
      setAddedItemsTags(searchParams);
      setAddedItemsPage(1);
      dispatch(loadIncludedRules({ voucherId, ruleType, search }));
    }
  };

  const clearAvailableItemsFilter = () => {
    const filter = { id: null, title: '' };
    setAvailableItemsTags(filter);
  };

  const clearAddedItemsFilter = () => {
    const filter = { id: null, title: '' };
    setAddedItemsTags(filter);
  };

  const handleDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId !== destination.droppableId) {
      setIsLoading(true);
      const isAddVoucherRule = destination.droppableId === DroppableNames.added;
      const collection = isAddVoucherRule ? availableItems : addedItems;
      const draggedItem = collection.find(
        (item: { id: number }) => item.id === (isAccessTypesSection ? draggableId : Number(draggableId))
      );
      if (isAddVoucherRule) {
        await dispatch(addVoucherRule({ voucherId, item: draggedItem.id, ruleType }));
      } else if (ruleType === RuleTypes.accessTypes) {
        await dispatch(
          deleteVoucherRule({
            voucherId,
            ruleId: draggedItem.rule_id,
            ruleType,
          })
        );
      } else {
        await dispatch(
          deleteItemRule({
            voucherId,
            ruleId: draggedItem.rule_id,
            itemId: draggedItem.id,
            ruleType,
          })
        );
      }
      if (!isAccessTypesSection) {
        const emptyTag = { id: null, title: '' };
        let page = availableItemsPage;
        let search = availableItemsTags;
        if (availableItems.length === 1 || (!isAddVoucherRule && (availableItemsTags.id || availableItemsTags.title))) {
          search = emptyTag;
          page = 1;
          setAvailableItemsTags(emptyTag);
          setAvailableItemsPage(1);
        }
        dispatch(
          loadExcludedRules({
            voucherId,
            ruleType,
            limit: page * 15,
            search: transformSearchParams(search),
          })
        );
        if (addedItems.length === 1 || (isAddVoucherRule && (addedItemsTags.id || addedItemsTags.title))) {
          dispatch(loadIncludedRules({ voucherId, ruleType }));
          setAddedItemsTags(emptyTag);
          setAddedItemsPage(1);
        }
      }
      setIsLoading(false);
    }
  };

  const resetFields = () => {
    clearAddedItemsFilter();
    clearAvailableItemsFilter();
    setAddedItemsPage(1);
    setAvailableItemsPage(1);
  };

  const removeAllRules = async () => {
    await dispatch(removeAllVoucherRules(voucherId, ruleType));
    dispatch(loadExcludedRules({ voucherId, ruleType }));
    resetFields();
  };

  const handleOpenModal = () => {
    dispatch(openModal(ModalNames.RemoveItemsFromVoucher));
  };

  const handleCloseModal = () => {
    removeAllRules();
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <DragDropContainer>
          <DroppableColumn
            title={DroppableNames.available}
            items={availableItems}
            droppableId={DroppableNames.available}
            buttonText="Load more"
            buttonAction={handleLoadMore(DroppableNames.available)}
            isLoaderVisible={isFetchingExcluded || isLoading}
            totalItems={totalAvailableItems}
            showButton={!isAccessTypesSection}
            handleAddition={handleAddition(DroppableNames.available)}
            displayIcon={isAccessTypesSection}
            displaySearchInput={!isAccessTypesSection}
            isReadOnly={isReadOnly}
            filter={availableItemsTags}
            clearFilter={clearAvailableItemsFilter}
            ruleType={ruleType}
          />
          <DroppableColumn
            title={DroppableNames.added}
            items={addedItems}
            droppableId={DroppableNames.added}
            isLoaderVisible={isFetchingIncluded || isLoading}
            buttonText="Load more"
            buttonAction={handleLoadMore(DroppableNames.added)}
            showButton={!isAccessTypesSection}
            showCustomButton={!isAccessTypesSection && !isReadOnly}
            customButtonText="Remove all"
            customButtonAction={handleOpenModal}
            handleAddition={handleAddition(DroppableNames.added)}
            displayIcon={isAccessTypesSection}
            displaySearchInput={!isAccessTypesSection}
            isReadOnly={isReadOnly}
            filter={addedItemsTags}
            clearFilter={clearAddedItemsFilter}
            totalItems={totalAddedItems}
            ruleType={ruleType}
            displayAccessFeeSection={ruleType !== RuleTypes.accessTypes}
          />
        </DragDropContainer>
      </DragDropContext>
      <ConfirmModal
        modalName={ModalNames.RemoveItemsFromVoucher}
        message={`Are you sure you want to remove all ${ruleType} from this voucher?`}
        handleConfirm={handleCloseModal}
      />
    </>
  );
};

export default VoucherDragAndDropContainer;
