import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@inplayer-org/inplayer-ui';

// actions
import { openModal } from 'ducks/merchantDucks/modal/actions';
import { disconnectLiveLikeAccount } from 'ducks/merchantDucks/externalAccount/actions';

// types
import { ModalNames } from 'ducks/merchantDucks/modal/types';

// components
import ConfirmModal from 'components/Modals/ConfirmModal';
import ThemedMessage, { MessageVariants } from 'components/ThemedMessage';

// styled components
import { StyledTypography } from 'pages/MerchantPages/Settings/Integrations/ThirdPartyIntegrations/ThirdPartyIntegration';

const ConnectedSection: FC = () => {
  const dispatch = useDispatch();

  const openConfirmationModal = useCallback(() => {
    dispatch(openModal(ModalNames.DisconnectLiveLike));
  }, []);

  const handledDisconnectAccount = useCallback(() => {
    dispatch(disconnectLiveLikeAccount());
  }, []);

  return (
    <>
      <ThemedMessage variant={MessageVariants.INFO}>You are currently connected to a LiveLike account.</ThemedMessage>
      <StyledTypography variant="p">
        By clicking the button below your LiveLike account will be disconnected, your chat rooms will be deleted and you
        won&apos;t be able to add chat options to your assets.
      </StyledTypography>
      <Button tag="button_disconnect_account" onClick={openConfirmationModal}>
        Disconnect LiveLike
      </Button>
      <ConfirmModal
        modalName={ModalNames.DisconnectLiveLike}
        title="Are you sure?"
        message="Your LiveLike account will be disconnected, your chat rooms will be deleted
                and you won't be able to add chat option to your assets."
        cancelText="Discard"
        confirmText="Disconnect Account"
        handleConfirm={handledDisconnectAccount}
        buttonText="Reset"
      />
    </>
  );
};

export default ConnectedSection;
