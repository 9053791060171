import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@inplayer-org/inplayer-ui';
import { useLocation } from 'react-router';
import { AiFillQuestionCircle } from 'react-icons/ai';

// components
import TourGuide from './TourGuide';

// utils
import { getSectionName } from './tourGuideConfig';

const StyledIcon = styled(AiFillQuestionCircle)`
  width: 2rem;
  height: 2rem;
`;

const StyledButton = styled(Button)<{ withSideNav: boolean }>`
  position: absolute;
  right: 0;
  ${({ withSideNav }) =>
    withSideNav &&
    css`
      top: -0.75rem;
      right: -7%;
    `}
`;

const Wrapper = styled.div`
  position: relative;
`;

interface Props {
  withSideNav?: boolean;
}

const TourGuideWrapper = ({ withSideNav }: Props) => {
  const location = useLocation();

  const [showSectionTour, setShowSectionTour] = useState(false);

  const onClick = () => {
    setShowSectionTour(true);
  };

  const onClose = () => {
    setShowSectionTour(false);
  };

  return (
    <Wrapper>
      <StyledButton
        tag="tour"
        buttonModifiers={['buttonLink']}
        onClick={onClick}
        title={`Take a tour of the ${getSectionName(location.pathname)} section!`}
        withSideNav={!!withSideNav}
      >
        <StyledIcon />
      </StyledButton>
      <TourGuide showSectionTour={showSectionTour} onClose={onClose} />
    </Wrapper>
  );
};

export default TourGuideWrapper;
