import { API } from 'constants/api';
import http, { ContentType, isAxiosError } from 'utils/http';
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';
import { ApiAction } from 'ducks/types';

// types
import { BulkAssetType } from './types';

//  Load package details
export enum PackageDetailsActionTypes {
  START = 'PACKAGE_REQUEST',
  SUCCESS = 'PACKAGE_SUCCESS',
  ERROR = 'PACKAGE_FAILURE',
}

export const loadPackage = (id: number): ApiAction<PackageDetailsActionTypes> => async (dispatch) => {
  const endpoint = API.PACKAGE;

  try {
    dispatch({ type: PackageDetailsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        id,
      },
    });
    dispatch({
      type: PackageDetailsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (e) {
    const { response } = e;
    dispatch({ type: PackageDetailsActionTypes.ERROR, payload: { ...response.data } });
  }
};

// Load added assets in package
export enum PackageAssetsActionTypes {
  START = 'PACKAGE_ASSETS_REQUEST',
  SUCCESS = 'PACKAGE_ASSETS_SUCCESS',
  FAILURE = 'PACKAGE_ASSETS_FAILURE',
}

export interface LoadPackageAssetsPayload {
  id: number;
  excluded?: boolean;
  search?: Array<any>;
  page?: number;
  limit?: number;
}

export const loadPackageAssets = ({
  id,
  excluded,
  search = [],
  page = 1,
  limit = 15,
}: LoadPackageAssetsPayload): ApiAction<PackageAssetsActionTypes> => async (dispatch) => {
  const endpoint = API.PACKAGE_ITEMS;
  const params = { page, excluded, limit, search };

  try {
    dispatch({ type: PackageAssetsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        id,
      },
      params,
    });
    dispatch({
      type: PackageAssetsActionTypes.SUCCESS,
      payload: { ...response.data, appendData: page !== 1 },
    });
  } catch (_) {
    dispatch({ type: PackageAssetsActionTypes.FAILURE });
  }
};

// Load all available assets
export enum AllAssetsActionTypes {
  START = 'EXCLUDED_ASSETS_REQUEST',
  SUCCESS = 'EXCLUDED_ASSETS_SUCCESS',
  FAILURE = 'EXCLUDED_ASSETS_FAILURE',
}

export const loadAssetExcludedFromPackage = ({
  id,
  page = 1,
  excluded = true,
  limit = 15,
  search = [],
}: LoadPackageAssetsPayload): ApiAction<AllAssetsActionTypes> => async (dispatch) => {
  const endpoint = API.PACKAGE_ITEMS;
  const params = { page, excluded, limit, search };

  try {
    dispatch({ type: AllAssetsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        id,
      },
      params,
    });
    dispatch({
      type: AllAssetsActionTypes.SUCCESS,
      payload: { ...response.data, appendData: page !== 1 },
    });
  } catch (_) {
    dispatch({ type: AllAssetsActionTypes.FAILURE });
  }
};

// Reset package assets
export enum ResetPackageAssetsActionTypes {
  SUCCESS = 'RESET_PACKAGE_ASSETS',
}

export const resetPackageAssets = () => ({ type: ResetPackageAssetsActionTypes.SUCCESS });

// Delete all assets in package
export enum DeleteAllAssetsActionTypes {
  START = 'DELETE_ALL_ITEMS_PACKAGE_REQUEST',
  SUCCESS = 'DELETE_ALL_ITEMS_PACKAGE_SUCCESS',
  FAILURE = 'DELETE_ALL_ITEMS_PACKAGE_FAILURE',
}

type deleteAllAssetsTypes = DeleteAllAssetsActionTypes | ResetPackageAssetsActionTypes | NotificationActionTypes;

export const removeItemsFromPackage = (packageId: number): ApiAction<deleteAllAssetsTypes> => async (dispatch) => {
  const endpoint = API.DELETE_ALL_ITEMS_PACKAGE;

  try {
    dispatch({ type: DeleteAllAssetsActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        packageId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteAllAssetsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(resetPackageAssets());
    dispatch(loadAssetExcludedFromPackage({ id: packageId }));
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'All assets successfully deleted.',
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: DeleteAllAssetsActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Load all packages
export enum PackagesActionTypes {
  START = 'PACKAGES_REQUEST',
  SUCCESS = 'PACKAGES_SUCCESS',
  FAILURE = 'PACKAGES_FAILURE',
}

export interface LoadPackagePayload {
  tenantUuid: string;
  page?: number;
  isActive?: boolean;
  limit?: number;
  meta?: any;
  search?: Array<any>;
  isInAppVoucher?: boolean;
}

export const loadPackages = ({
  tenantUuid,
  page,
  isActive,
  limit = 14,
  meta = {},
  search = [],
  isInAppVoucher,
}: LoadPackagePayload): ApiAction<PackagesActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_PACKAGES;
  const params = {
    page,
    limit,
    is_active: isActive,
    search,
  };
  const successData = { meta, concat: isInAppVoucher };

  try {
    dispatch({ type: PackagesActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        tenantUuid,
      },
      params,
    });
    dispatch({
      type: PackagesActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
  } catch (_) {
    dispatch({ type: PackagesActionTypes.FAILURE });
  }
};

// Reactivate package
export enum ReactivatePackageActionTypes {
  START = 'REACTIVATE_PACKAGE_REQUEST',
  SUCCESS = 'REACTIVATE_PACKAGE_SUCCESS',
  FAILURE = 'REACTIVATE_PACKAGE_FAILURE',
}

type ReactivatePackageType = ReactivatePackageActionTypes | NotificationActionTypes;

export const reactivatePackage = (id: number): ApiAction<ReactivatePackageType> => async (dispatch) => {
  const endpoint = API.REACTIVATE_PACKAGE;
  const successData = { id };

  try {
    dispatch({ type: ReactivatePackageActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        id,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: ReactivatePackageActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Package successfully reactivated.',
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: ReactivatePackageActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Bulk assets to package
export enum SaveBulkPackageAssetsActionTypes {
  START = 'SAVE_BULK_PACKAGE_ASSETS_REQUEST',
  SUCCESS = 'SAVE_BULK_PACKAGE_ASSETS_SUCCESS',
  ERROR = 'SAVE_BULK_PACKAGE_ASSETS_ERROR',
}

type SaveBulkPackageAssetsTypes = SaveBulkPackageAssetsActionTypes | NotificationActionTypes;

export const saveBulkPackageAssets = (
  packageId: number,
  addAssetIds: BulkAssetType,
  removeAssetIds: BulkAssetType
): ApiAction<SaveBulkPackageAssetsTypes> => async (dispatch) => {
  const endpoint = API.SAVE_BULK_ASSETS;
  const data = {
    add_asset_ids: addAssetIds,
    remove_asset_ids: removeAssetIds,
  };

  try {
    dispatch({ type: SaveBulkPackageAssetsActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        id: packageId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: SaveBulkPackageAssetsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Package assets successfully updated.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: SaveBulkPackageAssetsActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Add all assets in package
export enum AddAllAssetsActionTypes {
  START = 'ADD_ALL_ASSETS_PACKAGE_REQUEST',
  SUCCESS = 'ADD_ALL_ASSETS_PACKAGE_SUCCESS',
  FAILURE = 'ADD_ALL_ASSETS_PACKAGE_FAILURE',
}

export const addAllAssetsToPackage = (
  packageId: number
): ApiAction<AddAllAssetsActionTypes | NotificationActionTypes | ResetPackageAssetsActionTypes> => async (dispatch) => {
  const endpoint = API.ADD_ALL_ASSETS_TO_PACKAGE;

  try {
    dispatch({ type: AddAllAssetsActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        packageId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: AddAllAssetsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'All assets successfully added.',
        type: 'success',
      })
    );
    dispatch(loadPackageAssets({ id: packageId }));
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: AddAllAssetsActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};
