/* eslint-disable no-param-reassign */
import * as React from 'react';
import styled, { StyledComponent } from 'styled-components';
import moment from 'moment';
import {
  TableRowData,
  TableColumn,
  PillLabelModifier,
  RowActionsRender,
  Option,
  Grid,
  Theme,
  AnalyticsComponent,
} from '@inplayer-org/inplayer-ui';
import { prop } from 'styled-tools';
import startsWith from 'lodash/startsWith';
import { IoIosAddCircleOutline } from 'react-icons/io';
// Utils
import { convertArrayToCamelCase, unescapeText } from 'utils/helpers';
import linkTextWithTooltip from 'utils/linkTextWithTooltip';
import truncateTextWithTooltip from 'utils/truncateTextWithTooltip';

// Types
import { CustomerAccess, Customer } from 'ducks/merchantDucks/audience/types';
import SubscriptionState from 'ducks/merchantDucks/subscriptions/types';
import PaymentsState from 'ducks/merchantDucks/payments/types';
import { LoadSubscriptionsParams, LoadPaymentsParams } from 'ducks/merchantDucks/reports/actions';
import { LoadCustomerAccessQueryParams } from 'ducks/merchantDucks/audience/actions';
import { ItemType } from 'ducks/merchantDucks/items/actions';

// styled components
import { BlueTypography } from 'components/SharedStyledElements';
import { StyledPill } from 'pages/MerchantPages/Transactions/Payments/components/PaymentsTable';

// Components
import TableActionMenu from 'components/Table/TableActionMenu';
import GenericTable from 'components/Table/GenericTable';
import ExtendedDropdown from 'components/ExtendedDropdown';
import StyledContainer from 'components/StyledContainer';
import CommonIcon from 'components/CommonIcon';
import TableClipBoardIcon from 'components/Table/TableClipBoardIcon';
import Restricted from 'restrictions/roles/components/Restricted';

const { Cell } = Grid;

const {
  ActionMenuContainer,
  ActionMenuContent,
  ActionMenuDropdown,
  ActionMenuDropdownItem,
  ActionMenuLink,
} = TableActionMenu;

export const HeadingContainer = styled.div<{ gridTemplateColumns?: string }>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns || '1fr 1fr'};
  margin: 1rem 0;
  max-height: 3rem;

  @media screen and (max-width: 1040px) {
    grid-template-columns: 1fr;
    text-align: center;
    max-height: none;
  }
`;

export const HeadingActionsContainer = styled.div`
  display: grid;
  grid-gap: 0.3rem;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: end;

  @media screen and (max-width: 1040px) {
    margin-top: 1rem;
  }
`;

interface HeadingActionsCellProps {
  columnStart: number;
}

const HeadingActionsCell: StyledComponent<'span', Theme, HeadingActionsCellProps> = styled.span<
  HeadingActionsCellProps
>`
  grid-column-start: ${prop('columnStart')};
  width: 100%;
  height: 100%;
`;

export const Heading = styled(BlueTypography)`
  margin: 0;
`;

const serviceTypeOptions: Array<Option> = [
  { value: 'access', displayName: 'access' },
  { value: 'subscriptions', displayName: 'subscriptions' },
  { value: 'payments', displayName: 'payments' },
];

const activeOptions: Array<Option> = [
  { value: 'active', displayName: 'active' },
  { value: 'inactive', displayName: 'inactive' },
];

const activeFilterOptions: Array<Option> = [
  { value: 'all', displayName: 'all' },
  { value: 'granted', displayName: 'granted' },
  { value: 'purchased', displayName: 'purchased' },
  { value: 'full-discount', displayName: 'full-discount' },
  { value: 'access-voucher', displayName: 'access-voucher' },
];

const inactiveFilterOptions: Array<Option> = [
  { value: 'all', displayName: 'all' },
  { value: 'expired', displayName: 'expired' },
  { value: 'revoked', displayName: 'revoked' },
];

const subscriptionFilterOptions: Array<Option> = [
  { value: 'all', displayName: 'all' },
  { value: 'recurrent', displayName: 'recurrent' },
  { value: 'past_due', displayName: 'past due' },
  { value: 'unpaid', displayName: 'unpaid' },
  { value: 'canceled', displayName: 'canceled' },
  { value: 'free-trial', displayName: 'free trial' },
  { value: 'ended', displayName: 'ended' },
  { value: 'incomplete', displayName: 'incomplete' },
  { value: 'incomplete_expired', displayName: 'incomplete expired' },
  { value: 'paused', displayName: 'paused' },
];

const paymentsFilterOptions: Array<Option> = [
  { value: 'all', displayName: 'all' },
  { value: 'recurrent', displayName: 'recurrent' },
  { value: 'charge', displayName: 'charge' },
  { value: 'refund', displayName: 'refund' },
  { value: 'pending', displayName: 'pending' },
  { value: 'donation', displayName: 'donation' },
  { value: 'gift-payment', displayName: 'gift payment' },
];

interface CustomerTableData extends TableRowData {
  itemType: string;
  subscriptionId: string;
  paymentHistoryId: number;
  description: string;
  country: string;
  expiresAt: any;
  type: string;
  actionType: string;
  itemTitle: string;
  itemId: number;
  chargedAmount: number;
  currency: string;
  itemAccessId: number;
  unsubscribeUrl: string;
  createdAt: any;
  subscriptionPrice: number;
  currencyIso: string;
  transactionToken: string;
  trxToken: string;
  purchasedAccessFeeDescription: string;
  accessFeeDescription: string;
}

interface Props {
  customerAccess: CustomerAccess;
  loadCustomerAccess: (params: LoadCustomerAccessQueryParams) => any;
  loadSubscriptions: (params: LoadSubscriptionsParams) => any;
  loadPayments: (params: LoadPaymentsParams) => any;
  handleRevokeAccess: (itemId: number, itemAccessId: number) => void;
  handleExtendAccess: (itemId: number, expiresAt: any, itemTitle: string, type: string) => void;
  handleShow: (unsubscribeUrl: string) => void;
  handleClickMore: (subscriptionId: string) => void;
  handleGrantAccess: () => void;
  handleShowPaymentDetails: (trxToken: string) => void;
  id: number;
  startDate: string;
  endDate: string;
  subscriptions: SubscriptionState;
  payments: PaymentsState;
  isReadOnly?: boolean;
}

interface State {
  selectedValue: string;
  statusValue: string;
  typeValue: string;
  subscriptionType: string;
  paymentType: string;
  isActive: boolean;
}

export default class CustomerDetailsTable extends React.PureComponent<Props, State> {
  state: State = {
    selectedValue: 'access',
    statusValue: 'active',
    typeValue: 'all',
    subscriptionType: 'all',
    paymentType: 'all',
    isActive: true,
  };

  static defaultProps = { isReadOnly: false };

  componentDidMount() {
    const {
      customerAccess: { isFetching, page },
      loadCustomerAccess,
      id,
    } = this.props;
    const { statusValue } = this.state;

    if (!isFetching) {
      loadCustomerAccess({
        status: statusValue,
        customerID: id,
        page,
      });
    }
  }

  getHeadingText = (value: string) => {
    if (value === 'access') return 'Customer accesses';
    if (value === 'subscriptions') return 'Customer subscriptions';
    return 'Customer payments';
  };

  renderTableHeaderSection = () => {
    const { selectedValue, typeValue, statusValue, subscriptionType, paymentType } = this.state;

    return (
      <HeadingContainer>
        <Heading variant="h4">{this.getHeadingText(selectedValue)}</Heading>
        <HeadingActionsContainer>
          <HeadingActionsCell columnStart={selectedValue === 'access' ? 2 : 3}>
            <ExtendedDropdown
              tag="dropdown_access_management"
              fullHeight
              fullWidth
              options={serviceTypeOptions}
              value={selectedValue}
              onChange={this.handleSelect}
            />
          </HeadingActionsCell>
          {selectedValue === 'access' && (
            <>
              <ExtendedDropdown
                tag={
                  statusValue === 'active'
                    ? 'dropdown_access_active_management_access'
                    : 'dropdown_access_inactive_management_access'
                }
                fullHeight
                fullWidth
                options={statusValue === 'active' ? activeFilterOptions : inactiveFilterOptions}
                value={typeValue}
                onChange={this.handleFilterType}
              />
              <ExtendedDropdown
                tag="dropdown_access_management_access_type"
                fullHeight
                fullWidth
                options={activeOptions}
                value={statusValue}
                onChange={this.handleFilterStatus}
              />
            </>
          )}
          {selectedValue === 'subscriptions' && (
            <ExtendedDropdown
              fullHeight
              options={subscriptionFilterOptions}
              value={subscriptionType}
              onChange={this.handleSubscriptionType}
            />
          )}
          {selectedValue === 'payments' && (
            <ExtendedDropdown
              fullHeight
              options={paymentsFilterOptions}
              value={paymentType}
              onChange={this.handlePaymentType}
            />
          )}
        </HeadingActionsContainer>
      </HeadingContainer>
    );
  };

  public render() {
    const { subscriptions, payments, customerAccess, handleGrantAccess, isReadOnly } = this.props;
    const { selectedValue } = this.state;

    let limit = 5;
    if (selectedValue === 'subscriptions') {
      limit = subscriptions.limit;
    } else if (selectedValue === 'payments') {
      limit = payments.limit;
    } else {
      limit = customerAccess.limit;
    }

    let data: {
      collection: any[];
      total: number;
      isFetching: boolean;
      page: number;
    } = { collection: [], total: 0, isFetching: false, page: 0 };
    if (selectedValue === 'subscriptions') {
      data = subscriptions;
      data.collection.forEach((item) => {
        item.table_price = item.charged_amount;
      });
    } else if (selectedValue === 'payments') {
      data = payments;
      data.collection.forEach((item) => {
        item.table_price = item.charged_amount;
      });
    } else if (selectedValue === 'access') {
      data = customerAccess;
    }

    const collection = convertArrayToCamelCase<Customer, CustomerTableData>(data.collection);

    const rowActions =
      isReadOnly && !['payments', 'subscriptions'].includes(selectedValue) ? undefined : this.renderRowActions;

    return (
      <StyledContainer>
        <Cell width={12}>
          <GenericTable<CustomerTableData>
            hasBottomPagination
            data={collection}
            columns={this.generateColumns()}
            currentPage={data.page + 1}
            itemsPerPage={limit}
            total={data.total}
            handlePageClick={this.handlePageClick}
            pageRangeDisplayed={3}
            showLoader={data.isFetching}
            type={selectedValue}
            options={{
              ...(rowActions ? { rowActions } : {}),
              headerSection: this.renderTableHeaderSection(),
            }}
            tableButton={
              !isReadOnly && selectedValue === 'access'
                ? [
                    {
                      label: 'Grant access',
                      icon: <IoIosAddCircleOutline />,
                      onClick: handleGrantAccess,
                      type: 'button',
                    },
                  ]
                : []
            }
            renderEmptyTable={!isReadOnly && selectedValue === 'access'}
            actionsRowTitle="Edit"
          />
        </Cell>
      </StyledContainer>
    );
  }

  private handlePageClick = (page: number) => {
    const { loadCustomerAccess, id, startDate, endDate, loadSubscriptions, loadPayments } = this.props;
    const { typeValue, statusValue, selectedValue, subscriptionType, paymentType } = this.state;

    if (selectedValue === 'access') {
      loadCustomerAccess({
        status: statusValue,
        customerID: id,
        type: typeValue === 'all' ? undefined : typeValue,
        page: page - 1,
      });
    } else {
      const subscribsAndPaymentsParams = {
        startDate,
        endDate,
        page: page - 1,
      };
      if (selectedValue === 'subscriptions') {
        loadSubscriptions({
          ...subscribsAndPaymentsParams,
          filter: [`ConsumerId:${id}`],
          status: subscriptionType,
        });
      }

      if (selectedValue === 'payments') {
        loadPayments({
          ...subscribsAndPaymentsParams,
          filter: [`consumer_id:${id}`],
          type: paymentType,
        });
      }
    }
  };

  private renderRowActions: RowActionsRender<CustomerTableData> = ({ row }) => {
    const {
      handleRevokeAccess,
      handleExtendAccess,
      handleShow,
      handleClickMore,
      handleShowPaymentDetails,
      isReadOnly,
    } = this.props;
    const { selectedValue, statusValue, isActive } = this.state;
    return selectedValue === 'payments' ? (
      <ActionMenuContainer>
        <CommonIcon
          hoverStatus="arrowRight"
          name="arrowRight"
          onClick={(e) => {
            e.preventDefault();
            handleShowPaymentDetails(row.trxToken);
          }}
        />
      </ActionMenuContainer>
    ) : (
      <AnalyticsComponent>
        {({ pages, tracker, merchantId, ip }) => (
          <ActionMenuContainer
          // bug analytics
          // onMouseDown={() => this.setState({ isActive: true })}
          // onMouseOut={() => this.setState({ isActive: false })}
          // onClick={() =>
          //   !isActive &&
          //   tracker.track({
          //     event: 'click',
          //     type: 'icon',
          //     tag: `icon_settings_${row.itemId}`,
          //     pages,
          //     merchantId,
          //     ip,
          //   })
          // }
          >
            <ActionMenuContent>
              <ActionMenuDropdown>
                {selectedValue === 'access' && statusValue === 'active' && (
                  <Restricted isRestricted={isReadOnly}>
                    <ActionMenuLink
                      href="#/"
                      onClick={(e) => {
                        tracker.track({
                          event: 'click',
                          type: 'icon',
                          tag: `settings_${row.itemId}_test`,
                          pages,
                          merchantId,
                          ip,
                        });
                        e.preventDefault();
                        handleRevokeAccess(row.itemId, row.itemAccessId);
                      }}
                    >
                      <ActionMenuDropdownItem>Revoke access</ActionMenuDropdownItem>
                    </ActionMenuLink>
                    <ActionMenuLink
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        handleExtendAccess(row.itemId, row.expiresAt, row.itemTitle, row.type);
                      }}
                    >
                      <ActionMenuDropdownItem>Extend access</ActionMenuDropdownItem>
                    </ActionMenuLink>
                  </Restricted>
                )}
                {selectedValue === 'subscriptions' && (
                  <>
                    {row.actionType !== 'canceled' && (
                      <Restricted isRestricted={isReadOnly}>
                        <ActionMenuLink
                          href="#/"
                          onClick={(e) => {
                            e.preventDefault();
                            handleShow(row.unsubscribeUrl);
                          }}
                        >
                          <ActionMenuDropdownItem>Cancel subscription</ActionMenuDropdownItem>
                        </ActionMenuLink>
                      </Restricted>
                    )}
                    <ActionMenuLink
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClickMore(row.subscriptionId);
                      }}
                    >
                      <ActionMenuDropdownItem>Subscription details</ActionMenuDropdownItem>
                    </ActionMenuLink>
                  </>
                )}
              </ActionMenuDropdown>
            </ActionMenuContent>
            <CommonIcon hoverStatus="settings" name="settings" />
          </ActionMenuContainer>
        )}
      </AnalyticsComponent>
    );
  };

  private generateColumns = (): Array<TableColumn<CustomerTableData>> => {
    const { selectedValue, statusValue } = this.state;

    let descriptionTitle = 'Description';
    if (selectedValue === 'payments') {
      descriptionTitle = 'Country';
    } else if (selectedValue === 'access') {
      descriptionTitle = 'Expires at (UTC)';
    }

    let columns: Array<TableColumn<CustomerTableData>> = [
      {
        title: 'Asset/Package name',
        key: 'itemTitle',
        render: ({ value, rowValues }) => {
          const assetLink = rowValues.itemType === ItemType.Package ? rowValues.itemType : ItemType.Asset;

          const assetUrl = assetLink === ItemType.Package ? '/assets/packages' : '/assets';

          return (
            <TableClipBoardIcon
              tag={`icon_copy_asset_name_${rowValues.itemId}`}
              clipboardText={unescapeText(value)}
              isLinkText
            >
              {linkTextWithTooltip({
                text: unescapeText(value),
                linkTo: `${assetUrl}/edit/${rowValues.itemId}`,
                tooltipContent: `Go to ${assetLink}`,
                tag: `link_asset_name_${rowValues.itemId}`,
              })}
            </TableClipBoardIcon>
          );
        },
      },
      {
        title: 'Type',
        key: 'actionType',
        render: ({ value, rowValues }) => {
          let type = 'expired';
          if (selectedValue === 'access') {
            if (statusValue === 'inactive' && rowValues.type !== 'revoked') {
              type = 'expired';
            } else {
              type = rowValues.type;
            }
          } else {
            type = value;
          }

          const pillLabelModifiers: {
            [key: string]: PillLabelModifier;
          } = {
            // access action types
            expired: 'danger',
            granted: 'success',
            purchased: 'info',
            // payments action types
            refund: 'danger',
            recurrent: 'success',
            charge: 'info',
            // subscriptions action types
            canceled: 'danger',
            unpaid: 'info',
            past_due: 'primary',
          };

          const isDonationActionType = value === 'donation';

          return (
            <StyledPill isDonationActionType={isDonationActionType} size="xs" modifiers={[pillLabelModifiers[type]]}>
              {type.toUpperCase()}
            </StyledPill>
          );
        },
      },
      {
        title: 'Description',
        key: 'purchasedAccessFeeDescription',
        render: ({ value, rowValues }) => {
          if (selectedValue === 'access') {
            return rowValues.type === 'purchased' || rowValues.type === 'full-discount' ? (
              <TableClipBoardIcon clipboardText={value}>
                {truncateTextWithTooltip({ text: value, textLimit: 30 })}
              </TableClipBoardIcon>
            ) : (
              '/'
            );
          }
          return (
            <TableClipBoardIcon clipboardText={rowValues.accessFeeDescription}>
              {truncateTextWithTooltip({ text: rowValues.accessFeeDescription, textLimit: 30 })}
            </TableClipBoardIcon>
          );
        },
      },
      {
        title: 'Price',
        key: 'tablePrice',
        render: ({ value, rowValues }) => {
          const priceCurrency = selectedValue === 'payments' ? rowValues.currencyIso : rowValues.currency;
          const priceAmount = selectedValue === 'subscriptions' ? rowValues.subscriptionPrice : value;
          return `${priceCurrency} ${priceAmount}`;
        },
      },
      {
        title: descriptionTitle,
        key: 'description',
        render: ({ value, rowValues }) => {
          let descriptionValue = value;
          if (selectedValue === 'payments') {
            descriptionValue = rowValues.country;
          } else if (selectedValue === 'access') {
            if (rowValues.type === 'revoked' || startsWith(rowValues.expiresAt, '-')) {
              descriptionValue = '/';
            } else {
              descriptionValue = moment(rowValues.expiresAt * 1000)
                .utc()
                .format('YYYY/MM/DD HH:mm');
            }
          }

          return (
            <TableClipBoardIcon clipboardText={descriptionValue}>
              {truncateTextWithTooltip({ text: descriptionValue, textLimit: 30 })}
            </TableClipBoardIcon>
          );
        },
      },
      {
        title: 'Created at (UTC)',
        key: 'createdAt',
        render: ({ value }) => {
          const time = value.toString().length === 10 ? value * 1000 : value; // The BE returns different types of timestamps, so we must do this stupid check

          return selectedValue === 'payments'
            ? value.split('-').join('/')
            : moment(time).utc().format('YYYY/MM/DD HH:mm');
        },
      },
    ];

    if (selectedValue === 'access') {
      columns = columns.filter((column) => column.key !== 'tablePrice');
    }
    if (selectedValue === 'subscriptions') {
      columns = columns.filter((column) => column.key !== 'purchasedAccessFeeDescription');
    }

    return columns;
  };

  private handleSelect = (selectedValue: string) => {
    const { subscriptionType, paymentType } = this.state;
    const {
      id,
      customerAccess: { page },
      loadCustomerAccess,
      loadSubscriptions,
      loadPayments,
      startDate,
      endDate,
    } = this.props;

    this.setState({ selectedValue });

    if (selectedValue === 'subscriptions') {
      loadSubscriptions({
        startDate,
        endDate,
        page,
        filter: [`ConsumerId:${id}`],
        status: subscriptionType,
      });
    } else if (selectedValue === 'payments') {
      loadPayments({
        startDate,
        endDate,
        page,
        filter: [`consumer_id:${id}`],
        type: paymentType,
      });
    } else if (selectedValue === 'access') {
      loadCustomerAccess({ status: 'active', customerID: id, page });
      this.setState({ statusValue: 'active' });
    }
  };

  private handleFilterStatus = (statusValue: string) => {
    const {
      id,
      customerAccess: { page },
      loadCustomerAccess,
    } = this.props;

    this.setState({ statusValue, typeValue: 'all' }, () =>
      loadCustomerAccess({
        status: statusValue,
        customerID: id,
        page,
      })
    );
  };

  private handleFilterType = (filterTypeVal: string) => {
    const { statusValue } = this.state;
    const {
      id,
      customerAccess: { page },
      loadCustomerAccess,
    } = this.props;

    this.setState({ typeValue: filterTypeVal });
    let type = '';
    if (filterTypeVal !== 'all') {
      type = filterTypeVal;
    }
    loadCustomerAccess({
      status: statusValue,
      customerID: id,
      type,
      page,
    });
  };

  private handleSubscriptionType = (subscriptionType: string) => {
    const {
      startDate,
      endDate,
      subscriptions: { page },
      id,
      loadSubscriptions,
    } = this.props;

    this.setState({ subscriptionType });
    let type = '';
    if (subscriptionType !== 'all') {
      type = subscriptionType;
    }

    loadSubscriptions({
      startDate,
      endDate,
      page,
      filter: [`ConsumerId:${id}`],
      status: type,
    });
  };

  private handlePaymentType = (paymentType: string) => {
    const {
      startDate,
      endDate,
      payments: { page },
      id,
      loadPayments,
    } = this.props;

    this.setState({ paymentType });
    let type = '';
    if (paymentType !== 'all') {
      type = paymentType;
    }

    loadPayments({
      startDate,
      endDate,
      page,
      filter: [`consumer_id:${id}`],
      type,
    });
  };
}
