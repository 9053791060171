import * as React from 'react';
import styled from 'styled-components';
import { AnalyticsProps, AnalyticsComponent } from '@inplayer-org/inplayer-ui';

// components
import ClipBoardButton from 'components/ClipBoardButton';
import CommonIcon from 'components/CommonIcon';

const ClipBoardButtonWrapper = styled.div<{ isLinkText: boolean }>`
  display: none;
  position: absolute;
  padding-right: 1rem;

  span {
    ${({ isLinkText }) => isLinkText && `margin-top: 0.2rem;`}
    margin: 0 1rem 0 0.5rem;
  }
`;

const CellWrapper = styled.div`
  position: relative;
  width: fit-content;

  &:hover {
    ${ClipBoardButtonWrapper} {
      display: inline;
    }
  }
`;

interface Props extends AnalyticsProps {
  isLinkText?: boolean;
  clipboardText: string;
  children: any;
}

const TableClipBoardIcon = ({ isLinkText = false, clipboardText, children, tag = '' }: Props) => {
  return (
    <CellWrapper>
      {children}
      <AnalyticsComponent>
        {({ pages, tracker, merchantId, ip }) => (
          <ClipBoardButtonWrapper
            onClick={() =>
              tracker.track({
                event: 'click',
                type: 'icon',
                tag,
                pages,
                merchantId,
                ip,
              })
            }
            isLinkText={isLinkText}
          >
            <ClipBoardButton clipboardText={clipboardText} tooltipText="Copied" isLabel>
              <CommonIcon name="copy" isLabelIcon />
            </ClipBoardButton>
          </ClipBoardButtonWrapper>
        )}
      </AnalyticsComponent>
    </CellWrapper>
  );
};

export default TableClipBoardIcon;
