import { useSelector } from 'react-redux';
import RootState from 'ducks/RootState';

export const useTourGuideState = () => {
  const isTourActive = useSelector((state: RootState) => state.auth.tourGuide.isTourGuideActive);
  const isLogoClicked = useSelector((state: RootState) => state.auth.tourGuide.isLogoClicked);
  const isSectionTour = useSelector((state: RootState) => state.auth.tourGuide.isSectionTour);
  const isCloseTourClicked = useSelector((state: RootState) => state.auth.tourGuide.isCloseTourClicked);
  const previousLocation = useSelector((state: RootState) => state.auth.tourGuide.previousLocation);
  const currentStep = useSelector((state: RootState) => state.auth.tourGuide.currentStep);
  return {
    isTourActive,
    isLogoClicked,
    isSectionTour,
    isCloseTourClicked,
    previousLocation,
    currentStep,
  };
};
