import React, { FC, useCallback, MouseEvent } from 'react';
import styled from 'styled-components';
import { AnalyticsProps, useAnalytics } from '@inplayer-org/inplayer-ui';

const ButtonElement = styled.button`
  ${({ theme }) => `
    font-family: arial, sans-serif;
    border: none;
    background: none;
    padding: 0;
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.weights.normal};
    color: ${theme.palette.primary.main};

    :not(:disabled) {
      cursor: pointer;
    }

    :disabled {
      color: gray;
    }
  `}
`;

interface OwnProps {
  onClick?: (e: MouseEvent) => void;
  children: string;
}

type Props = OwnProps & AnalyticsProps;

const ButtonLink: FC<Props> = ({ tag, onClick, children: label }) => {
  const { track } = useAnalytics();

  const clickHandler = useCallback(
    (e: MouseEvent) => {
      if (tag) {
        track({ tag, event: 'click', type: 'button' });
      }

      if (onClick) {
        onClick(e);
      }
    },
    [track, tag, onClick]
  );

  return <ButtonElement onClick={clickHandler}>{label}</ButtonElement>;
};

export default ButtonLink;
