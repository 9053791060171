// lib imports
import React, { PureComponent, SyntheticEvent } from 'react';
import { Button, ButtonProps, Tooltip, Placement, Label } from '@inplayer-org/inplayer-ui';

// utils
import { copyToClipboard } from 'utils/copyToClipboard';

interface Props {
  clipboardText: string;
  disabled?: boolean;
  tooltipText?: string;
  tooltipDuration?: number;
  placement?: Placement;
  onClick?: (e?: any) => any;
  isLabel?: boolean;
}

interface State {
  tooltipVisible: boolean;
}

const defaultProps = {
  tooltipText: '',
  tooltipDuration: 1000,
  fullWidth: false,
  onClick: null,
};

export default class ClipBoardButton extends PureComponent<Props & ButtonProps<Button>, State> {
  static defaultProps = defaultProps;

  tooltipRef: React.RefObject<Tooltip> = React.createRef();

  copyToClipboard = async (
    e: SyntheticEvent<HTMLButtonElement> | React.MouseEvent<HTMLLabelElement, globalThis.MouseEvent>
  ) => {
    const { clipboardText, onClick } = this.props;

    if (onClick) {
      onClick(e);
    }

    const copied = await copyToClipboard(clipboardText);

    if (copied) {
      this.popUpTooltip();
    }
  };

  popUpTooltip = () => {
    const { current: tooltip } = this.tooltipRef;

    if (tooltip) {
      tooltip.flashTooltip();
    }
  };

  render() {
    const {
      isLabel = false,
      tooltipText,
      tooltipDuration,
      children,
      disabled,
      fullWidth,
      placement,
      tag = '',
    } = this.props;

    return (
      <Tooltip
        tag={`tooltip_${tag}`}
        ref={this.tooltipRef}
        behavior="ref"
        durationOnClick={tooltipDuration}
        placement={placement || 'right'}
        content={tooltipText}
      >
        {isLabel ? (
          <Label onClick={this.copyToClipboard}>{children}</Label>
        ) : (
          <Button
            tag={`button_${tag}`}
            size="sm"
            fullWidth={fullWidth}
            disabled={disabled}
            type="button"
            onClick={this.copyToClipboard}
          >
            {children}
          </Button>
        )}
      </Tooltip>
    );
  }
}
