import { useSelector } from 'react-redux';

import { getFeatures } from 'ducks/merchantDucks/user/selectors';

const useRestrictionFeatures = () => {
  const features = useSelector(getFeatures);

  const hasFeature = (feature: string) => !!features.find((feat) => feat.name === feature && feat.enabled);

  return { hasFeature };
};

export default useRestrictionFeatures;
