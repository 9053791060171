import React, { PureComponent, ReactNode } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { History } from 'history';

// components
import ForgotPasswordForm, {
  ForgotPasswordFormValues,
} from 'pages/MerchantPages/Authentication/ForgotPassword/forms/ForgotPassword';
import Loader from 'components/Loader';
import Header from 'pages/MerchantPages/Authentication/components/Header';

// actions
import { requestNewPassword as requestNewPasswordAction } from 'ducks/merchantDucks/user/actions';
import { resetForms as resetFormsAction } from 'ducks/merchantDucks/form/actions';

// types
import RootState from 'ducks/RootState';

interface OwnProps {
  location: Location;
  history: History;
}

interface DispatchProps {
  requestNewPassword: (email: string) => any;
  resetForms: () => any;
}

interface StateProps {
  forgotPasswordForm: any;
}

type Props = OwnProps & StateProps & DispatchProps;

export class ForgotPassword extends PureComponent<Props> {
  componentDidMount() {
    const { resetForms } = this.props;
    resetForms();
  }

  componentDidUpdate(prevProps: Props) {
    const { forgotPasswordForm, history } = prevProps;
    const { forgotPasswordForm: nextForgotPasswordForm } = this.props;
    const { requestedNewPassword } = nextForgotPasswordForm;

    if (forgotPasswordForm !== nextForgotPasswordForm && requestedNewPassword) {
      history.push('/new-password');
    }
  }

  requestNewPassword = (data: ForgotPasswordFormValues) => {
    const { requestNewPassword } = this.props;
    requestNewPassword(data.email);
  };

  render(): ReactNode {
    const {
      forgotPasswordForm: { isUpdating },
    } = this.props;

    if (isUpdating) {
      return <Loader />;
    }

    return (
      <>
        <Header />
        <ForgotPasswordForm onSubmit={this.requestNewPassword} />
      </>
    );
  }
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch) =>
  bindActionCreators(
    {
      requestNewPassword: requestNewPasswordAction,
      resetForms: resetFormsAction,
    },
    dispatch
  );

export const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  forgotPasswordForm: state.forgotPasswordForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
