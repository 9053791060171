export enum TourTypes {
  Analytics = '/dashboard',
  Audience = '/audience',
  Assets = '/assets',
  Packages = '/assets/packages',
  Vouchers = '/vouchers',
  InAppVouchers = '/vouchers/in-app',
  GiftVouchers = '/vouchers/gifts',
  Segmentation = '/audience/segmentation',
  SegmentationReports = '/audience/segmentation-reports',
  AudienceReports = '/audience/reports',
  Payments = '/transactions/payments',
  Subscriptions = '/transactions/subscriptions',
  Access = '/access',
  AccessReports = '/access/reports',
  Auth = '/settings/auth/social',
  OAuth = '/settings/auth/oauth',
  ConcurrentSessions = '/settings/auth/sessions',
  GeoRestrictions = '/settings/auth/geo-restrictions',
  DomainRestrictions = '/settings/auth/domain-restrictions',
  CustomFields = '/settings/auth/custom-fields',
  AgeRestrictions = '/settings/auth/age-restrictions',
  Branding = '/settings/branding',
  Integrations = '/settings/integrations/ovp-integrations',
  InAppIntegrations = '/settings/integrations/in-app-integrations',
  ThirdPartyIntegrations = '/settings/integrations/third-party-integrations',
  AccountDetails = '/account/details',
  PasswordManagement = '/account/details/password',
  InPlayerRelate = '/account/relate',
  Billing = '/billing-plan/payment-settings',
  Webhooks = '/webhooks',
}

export enum TourNames {
  Dashboard = 'Dashboard',
  Analytics = 'Analytics',
  Audience = 'Audience',
  Assets = 'Content',
  Vouchers = 'Promotions',
  Payments = 'Transactions',
  Access = 'Access',
  Settings = 'Settings',
  AccountDetails = 'Account details',
}

export enum ArrowType {
  left,
  right,
  top,
}
