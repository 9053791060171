import { Feature } from 'ducks/merchantDucks/user/types';

export const getStripeTooltipContent = (
  hasConnectedStripe: boolean,
  isDisconnected: boolean,
  isStripeFeatureEnabled?: Feature
) => {
  if (hasConnectedStripe && !isDisconnected) {
    return 'The merchant has their Stripe account \nconnected and are receiving their share \nreal time once a payment is completed. \nBy disabling this feature (toggle off)\n their account will be disconnect.';
  }

  return `The merchant uses our Stripe account. \nBy ${
    isStripeFeatureEnabled ? 'dis' : 'en'
  }abling this feature (toggle ${isStripeFeatureEnabled ? 'off' : 'on'})\nyou can ${
    isStripeFeatureEnabled ? 'dis' : 'en'
  }able the Stripe Connect option.`;
};

export const getActivateRelateTooltipContent = (isMaster: boolean) => {
  if (isMaster) {
    return `By disabling this feature (toggle off) this merchant won't be\n able to create relations with other merchant accounts and\n won't be able to login as any of the related accounts or \nmanage their settings.`;
  }

  return 'Enabling this feature (toggle on) would allow this merchant\n to create relations with other merchant accounts. In addition,\n the merchant will be able to login as any of the related accounts \nas well as manage their settings.';
};

export const getAgeVerificationTooltipContent = (isAgeVerificationEnabled: boolean) => {
  if (isAgeVerificationEnabled) {
    return `By disabling this feature (toggle off), age restriction managment\n will no longer be available in the merchant panel, and customers \nwill not receive PIN codes for access in the registration emails.`;
  }

  return 'Enabling this feature (toggle on) will provide age restriction management\n in the merchant panel, and will also include PIN codes for access to\n age restricted content in the registration email towards customers.';
};

export const getStripeModalMessage = (stripeUserId: string, hasInheritance: boolean) => {
  if (stripeUserId) {
    return `The merchant has their Stripe account connected and are receiving their share real time once a payment is completed. ${
      !hasInheritance
        ? `You can disconnect their account by disabling the StripeConnectIntegration feature in the 'Manage Features' section.`
        : ''
    } `;
  }
  if (hasInheritance) {
    return 'The parent merchant uses our stripe account.';
  }

  return `The merchant uses our Stripe account, you can enable the Stripe Connect Integration by enabling the StripeConnectIntegration feature in the 'Manage Features' section.`;
};
