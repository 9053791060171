import React, { lazy } from 'react';
import { Switch, Route } from 'react-router';

// components
const AdminAudience = lazy(() => import('pages/AdminPages/AdminAudience/AdminAudience'));
const AdminAudienceDetails = lazy(() => import('pages/AdminPages/AdminAudience/AdminAudienceDetails'));

const SubAudience = () => (
  <Switch>
    <Route path="/admin/audience/edit/:id">
      <AdminAudienceDetails />
    </Route>
    <Route key="/admin-audience" exact path="/admin/audience">
      <AdminAudience />
    </Route>
  </Switch>
);

export default SubAudience;
