import React, { lazy, Suspense, memo, FunctionComponent } from 'react';
import { Switch, Redirect, useRouteMatch } from 'react-router-dom';

// components
import Loader from 'components/Loader';

// routes
import Route from 'restrictions/roles/routes/RoleRestrictedRoute';
import AdminMailingList from 'pages/AdminPages/AdminMailingList/AdminMailingList';
import GenericAdminRoutes from 'pages/AdminPages/components/GenericAdminRoutes';
import { AdminComponentTypes } from 'pages/AdminPages/types';
import { InsideWrapper } from 'components/SharedStyledElements';

// Pages
const Error = lazy(() => import('components/Error'));
const Account = lazy(() => import('pages/MerchantPages/Account/Account'));
const AdminAnalytics = lazy(() => import('pages/AdminPages/AdminAnalytics/AdminAnalytics'));

const InsideLoader = () => (
  <InsideWrapper>
    <Loader />
  </InsideWrapper>
);

interface OwnProps {}

type Props = OwnProps;

const AdminDashboard: FunctionComponent<Props> = () => {
  const { url } = useRouteMatch();

  return (
    <Suspense fallback={<InsideLoader />}>
      <Switch>
        <Route key="/dashboard" path={`${url}/dashboard`}>
          <AdminAnalytics />
        </Route>
        <Route key="/merchants" path={`${url}/merchants`}>
          <GenericAdminRoutes componentType={AdminComponentTypes.MERCHANTS} />
        </Route>
        <Route key="/admin-audience" path={`${url}/audience`}>
          <GenericAdminRoutes componentType={AdminComponentTypes.AUDIENCE} />
        </Route>
        <Route key="/admin-payments" path={`${url}/payments`}>
          <GenericAdminRoutes componentType={AdminComponentTypes.PAYMENTS} />
        </Route>
        <Route key="/admin-subscriptions" path={`${url}/subscriptions`}>
          <GenericAdminRoutes componentType={AdminComponentTypes.SUBSCRIPTIONS} />
        </Route>
        <Route key="/admin-mailing-list" path={`${url}/mailing-list`}>
          <AdminMailingList />
        </Route>
        <Route key="/admin-access" path={`${url}/access`}>
          <GenericAdminRoutes componentType={AdminComponentTypes.ACCESS} />
        </Route>
        <Route path={`${url}/account`}>
          <Account />
        </Route>
        <Route path="/404.html">
          <Error />
        </Route>
        <Redirect exact from={`${url}/`} to={`${url}/dashboard`} />
        <Route path="*">
          <Error />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default memo(AdminDashboard);
