import { createStore, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import { History } from 'history';
import { routerMiddleware } from 'connected-react-router';

// utils
import jwt from 'middleware/jwt';
import notificationMiddleware from 'middleware/notifications';
import unauthorizedMiddleware from 'middleware/unauthorizedRequests';

// types
import RootState from 'ducks/RootState';

import createRootReducer from 'ducks/rootReducer';

/**
 *
 * @param {RootState} preloadedState - The initial preloaded store state.
 * @param {History} browserHistory - The browder history.
 * @returns {Store} - The created store.
 */
export function configureProdStore(preloadedState: RootState | undefined, browserHistory: History): Store<RootState> {
  return createStore(
    createRootReducer(browserHistory),
    preloadedState,
    applyMiddleware(thunk, jwt, unauthorizedMiddleware, routerMiddleware(browserHistory), notificationMiddleware)
  );
}
