import React, { FunctionComponent } from 'react';
import { Grid } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';

// styled components
import { BlueTypography } from 'components/SharedStyledElements';

interface Props {
  title: string;
}

const { Cell } = Grid;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-top: 5rem;
  grid-column-gap: 5rem;
  height: 5vh;

  @media screen and (max-width: 1510px) {
    grid-template-columns: 2fr 1fr;
  }
`;

const SubpageHeader: FunctionComponent<Props> = ({ title, children }) => (
  <GridContainer>
    <Cell left={1}>
      <BlueTypography
        style={{
          margin: 0,
        }}
        variant="h1"
      >
        {title}
      </BlueTypography>
    </Cell>
    <Cell middle left={2}>
      {children}
    </Cell>
  </GridContainer>
);

export default SubpageHeader;
