import React from 'react';
import { useParams } from 'react-router-dom';

// components
import { Switcher, Switchee } from 'components/Switcher';
import Eventbrite from './Eventbrite';
import LiveLike from './LiveLike';

export enum ThirdPartyIntegrationTypes {
  EVENTBRITE = 'eventbrite',
  LIVELIKE = 'livelike',
}

export interface ThirdPartyKeys {
  [key: string]: any;
}

const ThirdPartyIntegrationsForm: React.FC = () => {
  const { type: thirdPartyName } = useParams<{ type: string }>();

  return (
    <Switcher value={thirdPartyName}>
      <Switchee value={ThirdPartyIntegrationTypes.EVENTBRITE}>
        <Eventbrite />
      </Switchee>
      <Switchee value={ThirdPartyIntegrationTypes.LIVELIKE}>
        <LiveLike />
      </Switchee>
    </Switcher>
  );
};

export default ThirdPartyIntegrationsForm;
