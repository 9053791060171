import React, { FC, ComponentType, useMemo } from 'react';
import { PiecefulProvider } from 'react-pieceful-state';
import isFunction from 'lodash/isFunction';

type RegionGetter<P> = (ownProps: P) => string | undefined;

export default function withPiecefulProvider<P>(Component: ComponentType<P>, getRegion?: string | RegionGetter<P>) {
  const displayName = `withPiecefulState(${Component.displayName || Component.name || 'Component'})`;

  const WrapperComponent: FC<P> = (props) => {
    const region = useMemo(() => (isFunction(getRegion) ? getRegion?.(props) : getRegion), [getRegion, props]);

    return (
      <PiecefulProvider region={region}>
        <Component {...(props as P)} />
      </PiecefulProvider>
    );
  };

  WrapperComponent.displayName = displayName;

  return WrapperComponent;
}
