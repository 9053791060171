import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Loader, AnalyticsPage, Typography, useAnalytics } from '@inplayer-org/inplayer-ui';

// actions
import { loadExternalAccount } from 'ducks/merchantDucks/externalAccount/actions';

// selectors
import { getExternalAccount } from 'ducks/merchantDucks/externalAccount/selectors';

// styled components
import { StyledTypography } from 'pages/MerchantPages/Settings/Integrations/ThirdPartyIntegrations/ThirdPartyIntegration';

// hooks
import useWillMount from 'hooks/useWillMount';
import { useUncontrolledSwitch } from 'components/Switcher';

// components
import MainHolder from 'components/MainHolder';
import ContentHolder from 'components/ContentHolder';
import ConnectedSection from './ConnectedSection';
import UnconnectedSection from './UnconnectedSection';

export interface ThirdPartyKeys {
  [key: string]: any;
}

const DescriptionContainer = styled.div`
  margin: 2.5rem 2.5rem 0;
`;

const CenteredFlex = styled.div`
  display: flex;
  height: 50vh;
  justify-content: center;
  align-items: center;
`;

const Intro: React.FC = () => {
  const dispatch = useDispatch();

  const { trackCallback } = useAnalytics();

  const {
    isFetching,
    isSaving,
    saveOvpError,
    keys: { livelike },
  } = useSelector(getExternalAccount);

  const { type: thirdPartyName } = useParams<{ type: string }>();

  const isConnected = useMemo(() => !!livelike, [livelike]);

  const { metaValue } = useUncontrolledSwitch();

  useWillMount(() => {
    if (!isFetching && !saveOvpError && !metaValue?.dontFetch) {
      dispatch(loadExternalAccount(thirdPartyName));
    }
  });

  if (isFetching || isSaving) {
    return (
      <CenteredFlex>
        <Loader />
      </CenteredFlex>
    );
  }

  return (
    <AnalyticsPage tag={thirdPartyName} type="page">
      <MainHolder>
        <ContentHolder margin="2.5rem">
          <Typography variant="h1">Connect to LiveLike</Typography>
        </ContentHolder>
        <DescriptionContainer>
          <StyledTypography variant="p">
            <a
              onClick={trackCallback({
                event: 'click',
                type: 'link',
                tag: `link_livelike`,
              })}
              href="https://livelike.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LiveLike
            </a>
            &nbsp; is an audience engagement platform. You can use this integration to transform streaming into
            viewer-driven interactive experience.
          </StyledTypography>
          {isConnected ? <ConnectedSection /> : <UnconnectedSection />}
        </DescriptionContainer>
      </MainHolder>
    </AnalyticsPage>
  );
};

export default Intro;
