import React from 'react';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import { Colors, AnalyticsProps, AnalyticsComponent } from '@inplayer-org/inplayer-ui';
import { snakeCase } from 'lodash';
import UserMenuDropdown, { Props as UserMenuDropdownProps } from './UserMenuDropdown';

export const ProfileImage = styled.img`
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

export const MenuButton = styled.div`
  align-items: center;
  background: transparent;
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  min-width: 30px;
  transition: all 0.5s ease;

  &:hover {
    background: ${Colors.lightGray};
  }
`;

export const MenuArrow = styled.i<{ open: boolean }>`
  border: solid ${Colors.darkGray};
  border-width: 1px 0 0 1px;
  display: inline-block;
  padding: 4px;
  transform: ${ifProp('open', 'rotate(45deg)', 'rotate(-135deg)')};
  transition: all 0.5s ease;
`;

export const UserMenuContainer = styled.div<AnalyticsProps>`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
  position: relative;
  padding: 0 10px;
`;

export const CloseLayer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

export const MenuTitleContainer = styled.span`
  cursor: pointer;
  display: flex;
  align-items: baseline;
`;

export const MenuTitle = styled.span`
  color: ${({ theme }) => theme.palette.text.light};
  transition: color 0.3s ease;
  font-size: ${({ theme }) => theme.font.sizes.medium};
  padding: 0 3px 0 10px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.text.main};
  }
`;

export type Props = UserMenuDropdownProps & {
  image?: string;
  menuTitle?: string;
  id: string;
  open: Partial<{ userMenu: boolean; relateMenu: boolean }>;
  setOpen: (open: { [key: string]: boolean }) => void;
} & AnalyticsProps;

const UserMenu = ({ open, setOpen, id, image, menuItems, actionItem, menuTitle, linkItem, tag = '' }: Props) => {
  return (
    <AnalyticsComponent>
      {({ pages, tracker, merchantId, ip }) => (
        <>
          <UserMenuContainer tag={tag} id="user-menu">
            {image && <ProfileImage src={image} />}
            <MenuTitleContainer
              onClick={() => {
                tracker.track({
                  event: 'click',
                  type: 'link',
                  tag: `menu_${snakeCase(menuTitle)}`,
                  pages,
                  merchantId,
                  ip,
                });
                setOpen({ [id]: !open[id] });
              }}
            >
              <MenuTitle>{menuTitle}</MenuTitle>
              <MenuButton>
                <MenuArrow open={open[id]} />
              </MenuButton>
            </MenuTitleContainer>
            {open[id] && (
              <UserMenuDropdown
                id={id}
                menuItems={menuItems}
                actionItem={actionItem}
                linkItem={linkItem}
                onClick={() => setOpen({ [id]: !open[id] })}
              />
            )}
          </UserMenuContainer>
          {open[id] && <CloseLayer onClick={() => setOpen({ [id]: !open[id] })} />}
        </>
      )}
    </AnalyticsComponent>
  );
};

export default UserMenu;
