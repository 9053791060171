import { Reducer } from 'redux';
import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import EmbedCodeOptionsState from './types';
import { SetEmbedCodeObjectActionTypes } from './actions';

export const initialState: EmbedCodeOptionsState = {
  globalOptions: {
    brandingId: 0,
    oauthAppKey: '',
    useSSO: false,
    allowDestroy: true,
    allowRemove: true,
    userMenuPosition: 'right',
    dontAskForAnotherDonation: false,
  },
  optionsPerAsset: {
    assetId: '',
    dontAskForAnotherDonation: false,
  },
};

type EmbedCodeOptionsReducer = Reducer<EmbedCodeOptionsState>;

const setGlobalEmbedCodeObject: EmbedCodeOptionsReducer = (state = initialState, { payload }: Record<string, any>) => ({
  ...state,
  globalOptions: {
    ...state.globalOptions,
    ...payload,
  },
});

const setOptionsPerAssetEmbedCodeObject: EmbedCodeOptionsReducer = (
  state = initialState,
  { payload }: Record<string, any>
) => ({
  ...state,
  optionsPerAsset: {
    ...state.optionsPerAsset,
    ...payload,
  },
});

const resetOptionsPerAssetEmbedCodeObject: EmbedCodeOptionsReducer = (state = initialState) => {
  return {
    ...state,
    optionsPerAsset: {},
  };
};

const actionsMap: ActionMap<EmbedCodeOptionsState> = {
  [SetEmbedCodeObjectActionTypes.GLOBAL_OBJECT]: setGlobalEmbedCodeObject,
  [SetEmbedCodeObjectActionTypes.PER_ASSET]: setOptionsPerAssetEmbedCodeObject,
  [SetEmbedCodeObjectActionTypes.RESET_PER_ASSET_OPTIONS]: resetOptionsPerAssetEmbedCodeObject,
};

export default reducerWithActionMap(actionsMap, initialState);
