import { PaymentDetails } from 'ducks/merchantDucks/payments/types';
import { TableRowData } from '@inplayer-org/inplayer-ui';

export enum ResultKeys {
  AUDIENCE = 'audience',
  PAYMENTS = 'payments',
  SUBSCRIPTIONS = 'subscriptions',
  MERCHANTS = 'merchants',
  MAILING_LIST = 'mailingList',
  ASSET_ACCESSES = 'assetAccesses',
  MAIL_TEMPLATES = 'mailTemplates',
}

export interface PaymentsState {
  [page: number]: Array<PaymentDetails>;
  total: number;
  isFetching: boolean;
  refundRequest: boolean;
}

export interface AudienceState {
  [page: number]: Array<AudienceDetails>;
  total: number;
  isFetching: boolean;
}

export interface PaginationsState {
  [ResultKeys.AUDIENCE]: PaginatorState<AudienceDetails>;
  [ResultKeys.PAYMENTS]: PaginatorState<PaymentDetails>;
  [ResultKeys.SUBSCRIPTIONS]: PaginatorState<SubscriptionDetails>;
  [ResultKeys.MERCHANTS]: PaginatorState<MerchantDetails>;
  [ResultKeys.MAILING_LIST]: PaginatorState<MailingListDetails>;
  [ResultKeys.ASSET_ACCESSES]: PaginatorState<AssetAccessDetails>;
  [ResultKeys.MAIL_TEMPLATES]: PaginatorState<MailTemplatesDetails>;
}

export interface PaginatorState<T extends any> {
  [page: number]: Array<T>;
  total: number;
  isFetching: boolean;
  refundRequest?: boolean;
}

export type DataItemsType =
  | AudienceDetails
  | PaymentDetails
  | MerchantDetails
  | SubscriptionDetails
  | MailingListDetails
  | AssetAccessDetails
  | MailTemplatesDetails;

// AUDIENCE TYPE
export interface AudienceDetails {
  account_uuid: string;
  active: boolean;
  avatar_url: boolean;
  country: string;
  created_at: number;
  email: string;
  full_name: string;
  id: number | string;
  is_master: boolean;
  master_id: boolean;
  merchant_id: number;
  referrer: string;
  scopes: Array<any>;
}

export interface AudienceTableDetails extends TableRowData {
  accountUuid: string;
  active: boolean;
  avatarUrl: boolean;
  country: string;
  createdAt: number;
  email: string;
  fullName: string;
  id: number | string;
  isMaster: boolean;
  masterId: boolean;
  merchantId: number;
  referrer: string;
  scopes: Array<any>;
}

// MERCHANTS TYPE
export interface MerchantDetails extends AudienceDetails {}

export interface MerchantsTableDetails extends AudienceTableDetails {}

export interface CommercialsTable {
  created_at: number;
  filename: string;
}
export interface CommercialsTableDetails extends TableRowData {
  createdAt: number;
  filename: string;
}

export interface SubscriptionDetails {
  item_type: string;
  consumer_id: number;
  consumer_email: string;
  action_type: string;
  item_id: number;
  item_title: string;
  description: string;
  currency: string;
  charged_amount: number;
  created_at: number;
  unsubscribe_url: string;
  payment_method_name: string;
  subscription_price: number;
  subscription_id: string;
  cancel_token: string;
  client_id: string;
  continent: string;
  country: string;
  country_iso: string;
  formatted_amount: string;
  ip_address: string;
  merchant_id: number;
  next_rebill_date: number;
  payment_tool_token: string;
  updated_at: number;
  voucher: string;
}

export interface SubscriptionTableData extends TableRowData {
  itemType: string;
  consumerId: number;
  consumerEmail: string;
  actionType: string;
  itemId: string;
  itemTitle: string;
  description: string;
  currency: string;
  chargedAmount: string;
  createdAt: string;
  unsubscribeUrl: string;
  paymentMethodName: string;
  subscriptionPrice: number;
  subscriptionId: string;
  cancelToken: string;
  clientId: string;
  continent: string;
  country: string;
  countryIso: string;
  formattedAmount: string;
  ipAddress: string;
  merchantId: number;
  nextRebillDate: number;
  paymentToolTaken: string;
  updatedAt: number;
  voucher: string;
}

// MAILING-LIST TYPE

export type MailingListDetails = {
  id: number | string;
  email: string;
  blacklisted: boolean;
  reason: string;
  created_at: number;
  updated_at: number;
};

export interface MailingListTableDetails extends TableRowData {
  id: number | string;
  email: string;
  blacklisted: boolean;
  reason: string;
  createdAt: number;
  updatedAt: number;
}
export interface AssetAccessDetails {
  type: string;
  item_access_id: number;
  customer_id: number;
  item_id: number;
  item_title: string;
  consumer_email: string;
  created_at: number;
  expires_at: any;
  is_trial: boolean;
  merchant_id: number;
  parent_resource_id: string;
  payment_method: string;
  payment_tool: string;
  purchased_access_fee_description: string;
  purchased_access_fee_id: number;
  purchased_access_fee_type: string;
  purchased_amount: number;
  purchased_currency: string;
  revoked: number;
  starts_at: number;
}

export interface AssetAccessTableData extends TableRowData {
  type: string;
  itemAccessId: number;
  customerId: number;
  itemTitle: string;
  itemId: number;
  consumerEmail: string;
  createdAt: number;
  expiresAt: number;
  isTrial: boolean;
  merchantId: number;
  parentResourceId: string;
  paymentMethod: string;
  paymentTool: string;
  purchasedAccessFeeDescription: string;
  purchasedAccessFeeId: number;
  purchasedAccessFeeType: string;
  purchasedAmount: number;
  purchasedCurrency: string;
  revoked: number;
  startsAt: number;
}

// MAIL TEMPLATES TYPE
export interface MailTemplatesDetails {
  id: number;
  template_name: string;
  subject: string;
  from_email: string;
  branding_id: number;
  active: boolean;
  emailName: string;
  domain: string;
  transport: string;
}

export interface MailTemplatesTableDetails extends TableRowData {
  id: number;
  templateName: string;
  subject: string;
  fromEmail: string;
  brandingId: number;
  active: boolean;
  emailName: string;
  domain: string;
  transport: string;
}
