import React, { lazy, Suspense, memo, FunctionComponent } from 'react';
import { Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { AnalyticsPage } from '@inplayer-org/inplayer-ui';
import { useSelector } from 'react-redux';

// components
import Loader from 'components/Loader';
import { InsideWrapper, BlueTypography } from 'components/SharedStyledElements';

// routes
import Route from 'restrictions/roles/routes/RoleRestrictedRoute';

// types
import RootState from 'ducks/RootState';

// Pages
const Error = lazy(() => import('components/Error'));
const Analytics = lazy(() => import('pages/MerchantPages/Analytics/Analytics'));
const Billing = lazy(() => import('pages/MerchantPages/Billing/Billing'));
const Account = lazy(() => import('pages/MerchantPages/Account/Account'));
const Api = lazy(() => import('pages/MerchantPages/Api/Api'));
const Access = lazy(() => import('pages/MerchantPages/Access/Access'));
const Transactions = lazy(() => import('pages/MerchantPages/Transactions/Transactions'));
const Settings = lazy(() => import('pages/MerchantPages/Settings/Settings'));
const Assets = lazy(() => import('pages/MerchantPages/Assets/Assets'));
const Audience = lazy(() => import('pages/MerchantPages/Audience/Audience'));
const Vouchers = lazy(() => import('pages/MerchantPages/Assets/subpages/Vouchers'));

const InsideLoader = () => (
  <InsideWrapper>
    <Loader />
  </InsideWrapper>
);

interface OwnProps {}

type Props = OwnProps;

const Dashboard: FunctionComponent<Props> = () => {
  const { url } = useRouteMatch();
  const merchantId = useSelector((state: RootState) => state.auth.user.id);

  const shouldDisplayEmbeddingPages = merchantId === 2180995 || merchantId === 3244389;

  return (
    <AnalyticsPage tag="" type="page" merchantId={merchantId}>
      <Suspense fallback={<InsideLoader />}>
        <Switch>
          {shouldDisplayEmbeddingPages && (
            <Route path="/thoughtspot">
              <BlueTypography variant="h3">Thoughspot</BlueTypography>
            </Route>
          )}
          {shouldDisplayEmbeddingPages && (
            <Route path="/domo">
              <BlueTypography variant="h3">Domo</BlueTypography>
            </Route>
          )}
          <Route key="/dashboard" path="/dashboard">
            <Analytics />
          </Route>
          <Route key="/assets" path="/assets">
            <Assets />
          </Route>
          <Route key="/vouchers" path="/vouchers">
            <Vouchers />
          </Route>
          <Route key="/audience" path="/audience">
            <Audience />
          </Route>
          <Route key="/settings" path="/settings">
            <Settings />
          </Route>
          <Route key="/transactions" path="/transactions">
            <Transactions />
          </Route>
          <Route key="/access" path="/access">
            <Access />
          </Route>
          <Route key="/webhooks" path="/webhooks">
            <Api />
          </Route>
          <Route key="/billing-plan" path="/billing-plan">
            <Billing />
          </Route>
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/404.html">
            <Error />
          </Route>
          <Redirect exact from={url} to="/assets" />
          <Route path="*">
            <Error />
          </Route>
        </Switch>
      </Suspense>
    </AnalyticsPage>
  );
};

export default memo(Dashboard);
