import { replace } from 'connected-react-router';
import { ApiAction, RouterApiAction } from 'ducks/types';
import { API } from 'constants/api';
import http, { ContentType, isAxiosError } from 'utils/http';

// types
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';
import { RuleTypes } from 'pages/MerchantPages/Assets/Vouchers/components/VoucherDragAndDropContainer';
import { BulkAssetType } from 'ducks/merchantDucks/packages/types';
import { Optional } from 'utils/types';
import { VoucherDetails } from 'ducks/merchantDucks/vouchers/types';
import { VoucherTypeName } from 'pages/MerchantPages/Assets/Vouchers/shared/sharedTypes';

// api
import { mapDataWithNewKey, downloadFile } from 'ducks/merchantDucks/common/utils';
import { getSignedUrl, uploadFileBulk, postFileBulk } from 'ducks/merchantDucks/common/api';

// List all vouchers
export enum VouchersActionTypes {
  START = 'VOUCHERS_REQUEST',
  SUCCESS = 'VOUCHERS_SUCCESS',
  ERROR = 'VOUCHERS_ERROR',
}

export interface LoadVouchersParams {
  page: number;
  giftVouchers: boolean;
  limit?: number;
  search?: Array<any>;
  shouldUpdateTotalCount?: boolean;
}

export const loadVouchers = ({
  page,
  giftVouchers,
  limit = 15,
  search = [],
  shouldUpdateTotalCount = false,
}: LoadVouchersParams): ApiAction<VouchersActionTypes> => async (dispatch) => {
  const params = {
    page,
    limit,
    search,
    ...(giftVouchers && { size: limit }),
  };
  const endpoint = giftVouchers ? API.GIFT_VOUCHERS : API.VOUCHERS;

  const successData = { search, shouldUpdateTotalCount };

  try {
    dispatch({ type: VouchersActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({ type: VouchersActionTypes.SUCCESS, payload: { ...successData, ...response.data } });
  } catch (_) {
    dispatch({ type: VouchersActionTypes.ERROR });
  }
};

// Voucher details
export enum VoucherDetailsActionTypes {
  START = 'VOUCHER_DETAILS_REQUEST',
  SUCCESS = 'VOUCHER_DETAILS_SUCCESS',
  ERROR = 'VOUCHER_DETAILS_ERROR',
}

export enum CodeTypes {
  Android = 'playstore',
  Ios = 'apple',
}

export const loadVoucherDetails = (
  id: string
): ApiAction<VoucherDetailsActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.VOUCHER_DETAILS;

  try {
    dispatch({ type: VoucherDetailsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        id,
      },
    });
    dispatch({ type: VoucherDetailsActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: VoucherDetailsActionTypes.ERROR,
      payload: { ...response.data },
    });
  }
};

export enum ExcludedRulesActionTypes {
  START = 'EXCLUDED_RULES_REQUEST',
  SUCCESS = 'EXCLUDED_RULES_SUCCESS',
  ERROR = 'EXCLUDED_RULES_ERROR',
}

export interface LoadRulesParams {
  voucherId: number;
  ruleType: string;
  page?: number;
  limit?: number;
  search?: Array<any>;
}

export const loadExcludedRules = ({
  voucherId,
  ruleType,
  page = 0,
  limit = 15,
  search = [],
}: LoadRulesParams): ApiAction<ExcludedRulesActionTypes> => async (dispatch) => {
  const endpoint = API.RULES;
  const params = { rule_type: ruleType, excluded: true, page, limit, search };
  const successData = { rule_type: ruleType };

  try {
    dispatch({ type: ExcludedRulesActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        voucherId,
      },
      params,
    });
    dispatch({
      type: ExcludedRulesActionTypes.SUCCESS,
      payload: { ...successData, ...response.data, appendData: page !== 0 },
    });
  } catch (_) {
    dispatch({ type: ExcludedRulesActionTypes.ERROR });
  }
};

export enum IncludedRulesActionTypes {
  START = 'INCLUDED_RULES_REQUEST',
  SUCCESS = 'INCLUDED_RULES_SUCCESS',
  ERROR = 'INCLUDED_RULES_ERROR',
}

export const loadIncludedRules = ({
  voucherId,
  ruleType,
  page = 0,
  limit = 15,
  search,
}: LoadRulesParams): ApiAction<IncludedRulesActionTypes> => async (dispatch) => {
  const endpoint = API.RULES;
  const params = { rule_type: ruleType, excluded: false, page, limit, search };
  const successData = { rule_type: ruleType };

  try {
    dispatch({ type: IncludedRulesActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        voucherId,
      },
      params,
    });
    dispatch({
      type: IncludedRulesActionTypes.SUCCESS,
      payload: { ...successData, ...response.data, appendData: page !== 0 },
    });
  } catch (_) {
    dispatch({ type: IncludedRulesActionTypes.ERROR });
  }
};

export const ruleTypes = {
  assets: 'Asset',
  packages: 'Package',
  'access-fees': 'Access fee',
  'access-types': 'Access type',
  emails: 'Email',
};

export enum AddVoucherRuleActionTypes {
  START = 'ADD_VOUCHER_RULE_REQUEST',
  SUCCESS = 'ADD_VOUCHER_RULE_SUCCESS',
  ERROR = 'ADD_VOUCHER_RULE_ERROR',
}

interface AddVoucherParams {
  voucherId: number;
  item: string | number;
  ruleType: string;
  displaySuccessNotification?: boolean;
}

export const addVoucherRule = ({
  voucherId,
  item,
  ruleType,
  displaySuccessNotification = true,
}: AddVoucherParams): ApiAction<AddVoucherRuleActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.VOUCHER_RULE;
  const data = { value: item };
  const successData = { item, ruleType };

  try {
    dispatch({ type: AddVoucherRuleActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      pathVariables: {
        id: voucherId,
        ruleType,
      },
      contentType: ContentType.URLENCODED,
      data,
    });
    dispatch({
      type: AddVoucherRuleActionTypes.SUCCESS,
      payload: { ...successData, ...response.data, isEmailRule: ruleType === RuleTypes.emails },
    });
    if (displaySuccessNotification) {
      const ruleTypeName = ruleType === RuleTypes.emails ? 'Customer email' : ruleTypes[ruleType];
      dispatch(
        notifRequested({
          title: 'SUCCESS',
          content: `${ruleTypeName} was successfully added to the voucher.`,
          type: 'success',
        })
      );
    }
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: AddVoucherRuleActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Save voucher
export enum CreateVoucherActionTypes {
  START = 'SAVE_VOUCHER_REQUEST',
  SUCCESS = 'SAVE_VOUCHER_SUCCESS',
  ERROR = 'SAVE_VOUCHER_ERROR',
}

export const createVoucher = (
  voucherDetails: Partial<VoucherDetails>,
  itemId?: number,
  itemType?: string
): RouterApiAction<CreateVoucherActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.VOUCHERS;

  try {
    dispatch({ type: CreateVoucherActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data: voucherDetails,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: CreateVoucherActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Voucher was successfully created.',
        type: 'success',
      })
    );
    const { id, type } = response.data;
    if (itemId && itemType) {
      const { code } = voucherDetails;
      const accessFee = code!.split('-').pop() || '';
      dispatch(addVoucherRule({ voucherId: id, item: itemId, ruleType: itemType, displaySuccessNotification: false }));
      dispatch(
        addVoucherRule({
          voucherId: id,
          item: accessFee,
          ruleType: RuleTypes.accessFees,
          displaySuccessNotification: false,
        })
      );
    }
    const url = type === VoucherTypeName.InApp ? `/vouchers/in-app/edit/${id}` : `/vouchers/edit/${id}`;
    dispatch(replace(url));
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: CreateVoucherActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
    throw new Error(message);
  }
};

// Remove access type rule and email rule
interface SuccessDataTypes {
  ruleId: number;
  ruleType?: string;
}

interface DeleteVoucherTypes {
  voucherId: number;
  ruleId: number;
  ruleType: string;
}

export enum DeleteVoucherRuleActionTypes {
  START = 'DELETE_VOUCHER_RULE_REQUEST',
  SUCCESS = 'DELETE_VOUCHER_RULE_SUCCESS',
  ERROR = 'DELETE_VOUCHER_RULE_ERROR',
}

export const deleteVoucherRule = ({
  voucherId,
  ruleId,
  ruleType,
}: DeleteVoucherTypes): ApiAction<DeleteVoucherRuleActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.DELETE_VOUCHER_RULE;

  let successData: SuccessDataTypes = { ruleId };
  if (ruleType) {
    successData = { ...successData, ruleType };
  }

  try {
    dispatch({ type: DeleteVoucherRuleActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        id: voucherId,
        ruleId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteVoucherRuleActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `${ruleTypes[ruleType]} was successfully removed from the voucher.`,
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: DeleteVoucherRuleActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

export enum LoadVoucherAccessTypes {
  START = 'LOAD_VOUCHER_ACCESS_TYPES_REQUEST',
  SUCCESS = 'LOAD_VOUCHER_ACCESS_TYPES_SUCCESS',
  ERROR = 'lOAD_VOUCHER_ACCESS_TYPES_ERROR',
}

export const loadVoucherAccessTypes = (id: number): ApiAction<LoadVoucherAccessTypes> => async (dispatch) => {
  const endpoint = API.VOUCHER_ACCESS_TYPE;

  try {
    dispatch({ type: LoadVoucherAccessTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        id,
      },
    });
    dispatch({ type: LoadVoucherAccessTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: LoadVoucherAccessTypes.ERROR });
  }
};

// Delete all emails in a voucher
export enum DeleteAllRulesActionTypes {
  START = 'DELETE_ALL_VOUCHER_RULES_REQUEST',
  SUCCESS = 'DELETE_ALL_VOUCHER_RULES_SUCCESS',
  ERROR = 'DELETE_ALL_VOUCHER_RULES_ERROR',
}

type RemoveAllVoucherRulesTypes = DeleteAllRulesActionTypes | NotificationActionTypes | ExcludedRulesActionTypes;

export const removeAllVoucherRules = (id: number, ruleType: string): ApiAction<RemoveAllVoucherRulesTypes> => async (
  dispatch
) => {
  const endpoint = API.VOUCHER_RULE;

  try {
    dispatch({ type: DeleteAllRulesActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        id,
        ruleType,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteAllRulesActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `All ${ruleType} in the voucher successfully deleted.`,
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: DeleteAllRulesActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Load voucher emails/
export enum VoucherEmailsActionTypes {
  START = 'VOUCHER_EMAILS_REQUEST',
  SUCCESS = 'VOUCHER_EMAILS_SUCCESS',
  ERROR = 'VOUCHER_EMAILS_ERROR',
}

export interface LoadVoucherEmailsParams {
  id: number;
  page?: number;
  limit?: number;
}

export const loadVoucherEmails = ({
  id,
  page = 1,
  limit = 15,
}: LoadVoucherEmailsParams): ApiAction<VoucherEmailsActionTypes> => async (dispatch) => {
  const endpoint = API.VOUCHER_EMAILS;
  const params = { page, limit };

  try {
    dispatch({ type: VoucherEmailsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        id,
      },
      params,
    });
    dispatch({ type: VoucherEmailsActionTypes.SUCCESS, payload: { ...response.data, appendData: page !== 1 } });
  } catch (_) {
    dispatch({ type: VoucherEmailsActionTypes.ERROR });
  }
};

// Update voucher title
export enum UpdateVoucherTitleActionTypes {
  START = 'UPDATE_VOUCHER_TITLE_REQUEST',
  SUCCESS = 'UPDATE_VOUCHER_TITLE_SUCCESS',
  ERROR = 'UPDATE_VOUCHER_TITLE_ERROR',
}

type UpdateVoucherTitleTypes = UpdateVoucherTitleActionTypes | NotificationActionTypes;

export const updateVoucherTitle = (name: string, voucherId: number): ApiAction<UpdateVoucherTitleTypes> => async (
  dispatch
) => {
  const endpoint = API.UPDATE_VOUCHER;

  const data = {
    name,
  };

  try {
    dispatch({ type: UpdateVoucherTitleActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        id: voucherId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateVoucherTitleActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Voucher title successfully updated.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateVoucherTitleActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Update voucher details
export enum UpdateVoucherDetailsActionTypes {
  START = 'UPDATE_VOUCHER_DETAILS_REQUEST',
  SUCCESS = 'UPDATE_VOUCHER_DETAILS_SUCCESS',
  ERROR = 'UPDATE_VOUCHER_DETAILS_ERROR',
}

type UpdateVoucherDetailsTypes = UpdateVoucherDetailsActionTypes | NotificationActionTypes;

export interface UpdateVoucherTypes {
  discount?: number;
  rebillDiscount?: number;
  startDate: string;
  endDate: string;
  code?: string;
  usageLimit?: number | string;
  usageCounter?: number;
  discountPeriod?: string;
  discountDuration?: number;
  accessEndDate?: Optional<string>;
  accessDuration?: Optional<string>;
}

export const updateVoucherDetails = ({
  discount,
  rebillDiscount,
  startDate,
  endDate,
  code,
  usageLimit,
  usageCounter,
  discountPeriod,
  discountDuration,
  accessEndDate,
  accessDuration,
}: UpdateVoucherTypes): ApiAction<UpdateVoucherDetailsTypes> => async (dispatch, getState) => {
  const endpoint = API.UPDATE_VOUCHER;

  const data = {
    discount,
    rebill_discount: rebillDiscount,
    start_date: startDate,
    end_date: endDate,
    code,
    usage_limit: usageLimit,
    usage_counter: usageCounter,
    discount_period: discountPeriod,
    discount_duration: discountDuration,
    acc_end_date: accessEndDate,
    acc_duration: accessDuration,
  };

  const {
    vouchers: {
      voucherDetails: { id: voucherId },
    },
  } = getState();

  try {
    dispatch({ type: UpdateVoucherDetailsActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        id: voucherId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateVoucherDetailsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Voucher details successfully updated.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateVoucherDetailsActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Delete voucher
export enum DeleteVoucherActionTypes {
  START = 'DELETE_VOUCHER_REQUEST',
  SUCCESS = 'DELETE_VOUCHER_SUCCESS',
  ERROR = 'DELETE_VOUCHER_ERROR',
}

export const removeVoucher = (
  id: number
): RouterApiAction<DeleteVoucherActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.VOUCHER_DETAILS;
  const successData = { id };

  try {
    dispatch({ type: DeleteVoucherActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        id,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: DeleteVoucherActionTypes.SUCCESS, payload: { ...successData, ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Voucher successfully deleted.',
        type: 'success',
      })
    );
    dispatch(replace('/vouchers'));
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: DeleteVoucherActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Add access fees rules
export enum SaveAccessFeesRulesActionTypes {
  START = 'SAVE_ACCESS_FEES_RULES_REQUEST',
  SUCCESS = 'SAVE_ACCESS_FEES_RULES_SUCCESS',
  ERROR = 'SAVE_ACCESS_FEES_RULES_ERROR',
}

type SaveAccessFeeRulesTypes = SaveAccessFeesRulesActionTypes | NotificationActionTypes;

interface SaveAccessFeeTypes {
  voucherId: number;
  itemId: number;
  accessFees: BulkAssetType;
}

export const saveAccessFeesRules = ({
  voucherId,
  itemId,
  accessFees,
}: SaveAccessFeeTypes): ApiAction<SaveAccessFeeRulesTypes> => async (dispatch) => {
  const endpoint = API.ACCESS_FEES_RULES;
  const data = {
    access_fees: accessFees,
  };

  try {
    dispatch({ type: SaveAccessFeesRulesActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        voucher_id: voucherId,
        item_id: itemId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: SaveAccessFeesRulesActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Access fees rules were successfully updated.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: SaveAccessFeesRulesActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

export enum DeleteAccessFeesRulesActionTypes {
  START = 'DELETE_ACCESS_FEES_RULES_REQUEST',
  SUCCESS = 'DELETE_ACCESS_FEES_RULES_SUCCESS',
  ERROR = 'DELETE_ACCESS_FEES_RULES_ERROR',
}

export const deleteAccessFeesRule = (
  voucherId: number,
  itemId: number
): ApiAction<DeleteAccessFeesRulesActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.ACCESS_FEES_RULES;

  try {
    dispatch({ type: DeleteAccessFeesRulesActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        voucher_id: voucherId,
        item_id: itemId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteAccessFeesRulesActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Access fees were successfully removed from the voucher.',
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: DeleteAccessFeesRulesActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};
// Delete asset and package rule
export enum DeleteItemRuleActionTypes {
  START = 'DELETE_ITEM_RULE_REQUEST',
  SUCCESS = 'DELETE_ITEM_RULE_SUCCESS',
  ERROR = 'DELETE_ITEM_RULE_ERROR',
}

interface DeleteItemRuleTypes {
  voucherId: number;
  itemId: number;
  ruleId: number;
  ruleType: string;
}

export const deleteItemRule = ({
  voucherId,
  itemId,
  ruleId,
  ruleType,
}: DeleteItemRuleTypes): ApiAction<DeleteItemRuleActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.ITEM_RULE;
  const successData = { ruleId };

  try {
    dispatch({ type: DeleteItemRuleActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        voucher_id: voucherId,
        item_id: itemId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteItemRuleActionTypes.SUCCESS,
      payload: { ...response.data, ...successData },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `${ruleTypes[ruleType]} was successfully removed from the voucher.`,
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: DeleteItemRuleActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Get email template
export enum EmailTemplateActionTypes {
  START = 'EMAIL_TEMPLATE_REQUEST',
  SUCCESS = 'EMAIL_TEMPLATE_SUCCESS',
  ERROR = 'EMAIL_TEMPLATE_ERROR',
}

export const getBulkMailTemplate = (): ApiAction<EmailTemplateActionTypes> => async (dispatch) => {
  const endpoint = API.GET_EMAIL_TEMPLATE;
  const fileName = 'email_voucher_rule_import.csv';
  const template = 'voucher-rules-emails';

  try {
    dispatch({ type: EmailTemplateActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        template,
      },
    });

    const { download_url: url } = response.data;

    downloadFile(url, fileName);

    dispatch({ type: EmailTemplateActionTypes.SUCCESS });
  } catch (_) {
    dispatch({ type: EmailTemplateActionTypes.ERROR });
  }
};

/** Bulk Adding of Emails as Voucher Rule logic
 * 1. Fetch signed url
 * 2. Upload the file (PUT request to that url along with the file content)
 * 3. Post request with the file name (returned in signed-url response )
 */
export enum PostBulkEmailsActionTypes {
  START = 'POST_BULK_EMAILS_REQUEST',
  SUCCESS = 'POST_BULK_EMAILS_SUCCESS',
  ERROR = 'POST_BULK_EMAILS_ERROR',
}

export const postFileBulkEmail = (
  fileName: string,
  voucherId: number
): ApiAction<PostBulkEmailsActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.BULK_EMAILS;
  const data = {
    file_name: fileName,
  };
  try {
    dispatch({ type: PostBulkEmailsActionTypes.START });

    await http.authenticated().post(endpoint, {
      pathVariables: {
        voucher_id: voucherId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: PostBulkEmailsActionTypes.SUCCESS });

    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `Bulk voucher rules in progress`,
        type: 'success',
      })
    );
  } catch (_) {
    dispatch({ type: PostBulkEmailsActionTypes.ERROR });
  }
};

export enum BulkEmailActionTypes {
  START = 'BULK_EMAIL_REQUEST',
  SUCCESS = 'BULK_EMAIL_SUCCESS',
  ERROR = 'BULK_EMAIL_ERROR',
}
export const uploadEmailFile = (file: File, voucherId: number): ApiAction<BulkEmailActionTypes> => async (dispatch) => {
  try {
    dispatch({ type: BulkEmailActionTypes.START });
    const response = await getSignedUrl(API.GET_SIGNED_URL);

    const { url: signedUrl, file_name: fileName, headers } = response;
    const endpoint = API.BULK_EMAILS;
    const pathVariables = { voucher_id: voucherId };

    await uploadFileBulk(signedUrl, file, headers);
    await postFileBulk({ fileName, endpoint, pathVariables });

    dispatch({ type: BulkEmailActionTypes.SUCCESS });
  } catch (_) {
    dispatch({ type: BulkEmailActionTypes.ERROR });
  }
};

export enum ListErrorFilesActionTypes {
  START = 'LOAD_ERROR_FILES_REQUEST',
  SUCCESS = 'LOAD_ERROR_FILES_SUCCESS',
  ERROR = 'LOAD_ERROR_FILES_ERROR',
}

export const getErrorFiles = (): ApiAction<ListErrorFilesActionTypes> => async (dispatch, getState) => {
  const endpoint = API.GET_BULK_FAILED_LIST;
  const action = 'voucher-rules';

  const {
    auth: {
      user: { id },
    },
  } = getState();

  try {
    dispatch({ type: ListErrorFilesActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        action,
      },
      params: { merchant_id: id },
    });

    const mappedResponse = mapDataWithNewKey(response.data, 'created_at', 'createdAt');

    dispatch({ type: ListErrorFilesActionTypes.SUCCESS, payload: mappedResponse });
  } catch (_) {
    dispatch({ type: ListErrorFilesActionTypes.ERROR });
  }
};

// Gift Voucher details
export enum GiftVoucherDetailsActionTypes {
  START = 'GIFT_VOUCHER_DETAILS_REQUEST',
  SUCCESS = 'GIFT_VOUCHER_DETAILS_SUCCESS',
  ERROR = 'GIFT_VOUCHER_DETAILS_ERROR',
}

export const loadGiftVoucherDetails = (
  id: string
): ApiAction<GiftVoucherDetailsActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.GIFT_VOUCHER_DETAILS;

  try {
    dispatch({ type: GiftVoucherDetailsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        id,
      },
    });
    dispatch({ type: GiftVoucherDetailsActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: GiftVoucherDetailsActionTypes.ERROR,
      payload: { ...response.data },
    });
  }
};
