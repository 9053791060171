import React from 'react';
import { Loader as InplayerLoader } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';

interface Props {
  position?: string;
}

export const LoaderContainer = styled.div<Props>`
  ${({ position }) => `position: ${position || 'fixed'};`}
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const Loader = ({ position }: Props) => (
  <LoaderContainer position={position}>
    <InplayerLoader />
  </LoaderContainer>
);

export default Loader;
