import React, { PureComponent } from 'react';
import { FormikConsumer, FormikErrors } from 'formik';

interface StringHashMap {
  [key: string]: string | FormikErrors<any> | undefined;
}

interface FormikPersistorProps {
  name: string;
  values: StringHashMap;
  errors: FormikErrors<any>;
  setValues: (values: StringHashMap) => void;
  setErrors: (errors: FormikErrors<any>) => void;
  exclusions?: Array<string>;
}

class FormikPersistor extends PureComponent<FormikPersistorProps> {
  UNSAFE_componentWillMount() {
    window.addEventListener('beforeunload', this.clear);
  }

  componentDidMount() {
    const { setValues, setErrors } = this.props;
    const data = sessionStorage.getItem(this.storageKey);
    if (data) {
      const { values, errors } = JSON.parse(data);
      setValues(values);
      setErrors(errors);
    }
  }

  componentDidUpdate() {
    const { values: allValues, errors, exclusions = [] } = this.props;
    const values = { ...allValues };

    exclusions.forEach((key: string) => {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        values[key] = '';
      }
    });

    sessionStorage.setItem(this.storageKey, JSON.stringify({ values, errors }));
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.clear);
  }

  get storageKey() {
    const { name } = this.props;
    return `formik.form.${name}`;
  }

  clear = () => {
    sessionStorage.removeItem(this.storageKey);
  };

  render() {
    return null;
  }
}

const FormikPersist = ({ name, exclusions }: { name: string; exclusions?: Array<string> }) => (
  <FormikConsumer>
    {({ values, errors, setValues, setErrors }) => (
      <FormikPersistor
        name={name}
        setValues={setValues}
        setErrors={setErrors}
        values={values}
        errors={errors}
        exclusions={exclusions}
      />
    )}
  </FormikConsumer>
);

export default FormikPersist;
