import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Typography, Colors, AnalyticsComponent } from '@inplayer-org/inplayer-ui';

// images
import eventbrite from 'assets/pics/integrations/eventbrite.png';
import livelike from 'assets/pics/integrations/livelike.png';

// selectors
import { getFeatures } from 'ducks/merchantDucks/user/selectors';

// types
import { FeatureNames } from 'ducks/merchantDucks/user/types';

// components
import SubheaderWithBorder from 'components/SubheaderWithBorder';
import ContentHolder from 'components/ContentHolder';
import OvpContainer from 'components/OvpContainer';
import OvpCard from 'components/OvpCard';
import TourGuideWrapper from 'components/TourGuide';

// styled components
import { StyledTypographyProps } from 'components/StyledTypographyMargin';
import { BlueTypography } from 'components/SharedStyledElements';
import { Container } from 'pages/MerchantPages/Settings/Integrations/OvpIntegrations/containers/OvpIntegration';

export const StyledTypography = styled(Typography)<StyledTypographyProps>`
  ${({ isHeading }) => `
    margin-bottom: 2rem;
    ${isHeading && `color: ${Colors.darkBlue};`}
  `}
`;

const ThirdPartyIntegration: React.FC = () => {
  const { path } = useRouteMatch();
  const isLiveLikeFeatureEnabled = useSelector(getFeatures).find((feature) => feature.name === FeatureNames.LIVELIKE)
    ?.enabled;

  const thirdPartyContent = (url: string) => {
    const thirdParties = [
      {
        url: `${url}/eventbrite`,
        logo: eventbrite,
        tag: 'eventbrite',
      },
    ];

    if (isLiveLikeFeatureEnabled) {
      thirdParties.push({
        url: `${url}/livelike`,
        logo: livelike,
        tag: 'livelike',
      });
    }

    return thirdParties;
  };

  return (
    <>
      <TourGuideWrapper withSideNav />
      <SubheaderWithBorder>
        <ContentHolder>
          <BlueTypography variant="h1">Third-party integrations</BlueTypography>
        </ContentHolder>
      </SubheaderWithBorder>
      <Container>
        <OvpContainer>
          {thirdPartyContent(path).map(({ url: thirdPartyUrl, logo, tag }) => (
            <AnalyticsComponent key={tag}>
              {({ pages, tracker, merchantId, ip }) => (
                <NavLink
                  onClick={() =>
                    tracker.track({
                      event: 'click',
                      type: 'box',
                      tag: `box_${tag}`,
                      pages,
                      merchantId,
                      ip,
                    })
                  }
                  key={thirdPartyUrl}
                  to={thirdPartyUrl}
                >
                  <OvpCard logo={logo} />
                </NavLink>
              )}
            </AnalyticsComponent>
          ))}
        </OvpContainer>
      </Container>
    </>
  );
};

export default ThirdPartyIntegration;
