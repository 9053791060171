import React from 'react';
import { Grid, Button } from '@inplayer-org/inplayer-ui';
import { Formik, FormikHelpers, Field, FormikProps } from 'formik';
import { object, string } from 'yup';

// components
import Input from 'components/Fields/Input';
import FieldError from 'components/Fields/FieldError';
import { ThirdPartyKeys } from 'pages/MerchantPages/Settings/Integrations/ThirdPartyIntegrations/ThirdPartyIntegrationsForm';

// hooks
import { useUncontrolledSwitch } from 'components/Switcher';

const { Container, Cell } = Grid;

interface FormValues {
  client_id: string;
  access_token: string;
}

interface OwnProps {
  onSubmit: (data: FormValues) => void;
  livelikeKeys: ThirdPartyKeys;
}

type Props = OwnProps;

const validationSchema = object().shape({
  client_id: string().required('*Required'),
  access_token: string().required('*Required'),
});

const defaultValues: FormValues = {
  client_id: '',
  access_token: '',
};

const LiveLikeForm: React.FC<Props> = ({ onSubmit, livelikeKeys }) => {
  const initialValues: FormValues = {
    ...defaultValues,
    ...livelikeKeys,
  };

  const { resetToDefault } = useUncontrolledSwitch();

  const onFormSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    await onSubmit(values);
    setSubmitting(false);
  };

  const handleCancel = () => {
    resetToDefault({ dontFetch: true });
  };

  return (
    <Formik
      onSubmit={onFormSubmit}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting }: FormikProps<FormValues>) => (
        <form onSubmit={handleSubmit}>
          <Container columns={3} rowGap="1rem">
            <Cell width={3}>
              <Field tag="text_clientid" name="client_id" type="text" component={Input} label="Client ID" />
              <FieldError name="client_id" />
            </Cell>
            <Cell width={3}>
              <Field tag="text_access_token" name="access_token" type="text" component={Input} label="Access Token" />
              <FieldError name="access_token" />
            </Cell>
            <Cell>
              <Button tag="button_save" fullWidth type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </Cell>
            <Cell>
              <Button tag="button_cancel" fullWidth disabled={isSubmitting} onClick={handleCancel}>
                Cancel
              </Button>
            </Cell>
          </Container>
        </form>
      )}
    </Formik>
  );
};

export default LiveLikeForm;
