export interface CountryGeoData {
  name: string;
  code: string;
  coordinates: {
    lat: number;
    lng: number;
  };
}

const countries: Array<CountryGeoData> = [
  {
    name: 'Aruba',
    code: 'AW',
    coordinates: {
      lat: 12.5,
      lng: -69.96666666,
    },
  },
  {
    name: 'Afghanistan',
    code: 'AF',
    coordinates: {
      lat: 33,
      lng: 65,
    },
  },
  {
    name: 'Angola',
    code: 'AO',
    coordinates: {
      lat: -12.5,
      lng: 18.5,
    },
  },
  {
    name: 'Anguilla',
    code: 'AI',
    coordinates: {
      lat: 18.25,
      lng: -63.16666666,
    },
  },
  {
    name: 'Åland Islands',
    code: 'AX',
    coordinates: {
      lat: 60.116667,
      lng: 19.9,
    },
  },
  {
    name: 'Albania',
    code: 'AL',
    coordinates: {
      lat: 41,
      lng: 20,
    },
  },
  {
    name: 'Andorra',
    code: 'AD',
    coordinates: {
      lat: 42.5,
      lng: 1.5,
    },
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    coordinates: {
      lat: 24,
      lng: 54,
    },
  },
  {
    name: 'Argentina',
    code: 'AR',
    coordinates: {
      lat: -34,
      lng: -64,
    },
  },
  {
    name: 'Armenia',
    code: 'AM',
    coordinates: {
      lat: 40,
      lng: 45,
    },
  },
  {
    name: 'American Samoa',
    code: 'AS',
    coordinates: {
      lat: -14.33333333,
      lng: -170,
    },
  },
  {
    name: 'Antarctica',
    code: 'AQ',
    coordinates: {
      lat: -90,
      lng: 0,
    },
  },
  {
    name: 'French Southern and Antarctic Lands',
    code: 'TF',
    coordinates: {
      lat: -49.25,
      lng: 69.167,
    },
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
    coordinates: {
      lat: 17.05,
      lng: -61.8,
    },
  },
  {
    name: 'Australia',
    code: 'AU',
    coordinates: {
      lat: -27,
      lng: 133,
    },
  },
  {
    name: 'Austria',
    code: 'AT',
    coordinates: {
      lat: 47.33333333,
      lng: 13.33333333,
    },
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
    coordinates: {
      lat: 40.5,
      lng: 47.5,
    },
  },
  {
    name: 'Burundi',
    code: 'BI',
    coordinates: {
      lat: -3.5,
      lng: 30,
    },
  },
  {
    name: 'Belgium',
    code: 'BE',
    coordinates: {
      lat: 50.83333333,
      lng: 4,
    },
  },
  {
    name: 'Benin',
    code: 'BJ',
    coordinates: {
      lat: 9.5,
      lng: 2.25,
    },
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    coordinates: {
      lat: 13,
      lng: -2,
    },
  },
  {
    name: 'Bangladesh',
    code: 'BD',
    coordinates: {
      lat: 24,
      lng: 90,
    },
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    coordinates: {
      lat: 43,
      lng: 25,
    },
  },
  {
    name: 'Bahrain',
    code: 'BH',
    coordinates: {
      lat: 26,
      lng: 50.55,
    },
  },
  {
    name: 'Bahamas',
    code: 'BS',
    coordinates: {
      lat: 24.25,
      lng: -76,
    },
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    coordinates: {
      lat: 44,
      lng: 18,
    },
  },
  {
    name: 'Saint Barthélemy',
    code: 'BL',
    coordinates: {
      lat: 18.5,
      lng: -63.41666666,
    },
  },
  {
    name: 'Belarus',
    code: 'BY',
    coordinates: {
      lat: 53,
      lng: 28,
    },
  },
  {
    name: 'Belize',
    code: 'BZ',
    coordinates: {
      lat: 17.25,
      lng: -88.75,
    },
  },
  {
    name: 'Bermuda',
    code: 'BM',
    coordinates: {
      lat: 32.33333333,
      lng: -64.75,
    },
  },
  {
    name: 'Bolivia',
    code: 'BO',
    coordinates: {
      lat: -17,
      lng: -65,
    },
  },
  {
    name: 'Brazil',
    code: 'BR',
    coordinates: {
      lat: -10,
      lng: -55,
    },
  },
  {
    name: 'Barbados',
    code: 'BB',
    coordinates: {
      lat: 13.16666666,
      lng: -59.53333333,
    },
  },
  {
    name: 'Brunei',
    code: 'BN',
    coordinates: {
      lat: 4.5,
      lng: 114.66666666,
    },
  },
  {
    name: 'Bhutan',
    code: 'BT',
    coordinates: {
      lat: 27.5,
      lng: 90.5,
    },
  },
  {
    name: 'Bouvet Island',
    code: 'BV',
    coordinates: {
      lat: -54.43333333,
      lng: 3.4,
    },
  },
  {
    name: 'Botswana',
    code: 'BW',
    coordinates: {
      lat: -22,
      lng: 24,
    },
  },
  {
    name: 'Central African Republic',
    code: 'CF',
    coordinates: {
      lat: 7,
      lng: 21,
    },
  },
  {
    name: 'Canada',
    code: 'CA',
    coordinates: {
      lat: 60,
      lng: -95,
    },
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    coordinates: {
      lat: -12.5,
      lng: 96.83333333,
    },
  },
  {
    name: 'Switzerland',
    code: 'CH',
    coordinates: {
      lat: 47,
      lng: 8,
    },
  },
  {
    name: 'Chile',
    code: 'CL',
    coordinates: {
      lat: -30,
      lng: -71,
    },
  },
  {
    name: 'China',
    code: 'CN',
    coordinates: {
      lat: 35,
      lng: 105,
    },
  },
  {
    name: 'Ivory Coast',
    code: 'CI',
    coordinates: {
      lat: 8,
      lng: -5,
    },
  },
  {
    name: 'Cameroon',
    code: 'CM',
    coordinates: {
      lat: 6,
      lng: 12,
    },
  },
  {
    name: 'DR Congo',
    code: 'CD',
    coordinates: {
      lat: 0,
      lng: 25,
    },
  },
  {
    name: 'Republic of the Congo',
    code: 'CG',
    coordinates: {
      lat: -1,
      lng: 15,
    },
  },
  {
    name: 'Cook Islands',
    code: 'CK',
    coordinates: {
      lat: -21.23333333,
      lng: -159.76666666,
    },
  },
  {
    name: 'Colombia',
    code: 'CO',
    coordinates: {
      lat: 4,
      lng: -72,
    },
  },
  {
    name: 'Comoros',
    code: 'KM',
    coordinates: {
      lat: -12.16666666,
      lng: 44.25,
    },
  },
  {
    name: 'Cape Verde',
    code: 'CV',
    coordinates: {
      lat: 16,
      lng: -24,
    },
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    coordinates: {
      lat: 10,
      lng: -84,
    },
  },
  {
    name: 'Cuba',
    code: 'CU',
    coordinates: {
      lat: 21.5,
      lng: -80,
    },
  },
  {
    name: 'Curaçao',
    code: 'CW',
    coordinates: {
      lat: 12.116667,
      lng: -68.933333,
    },
  },
  {
    name: 'Christmas Island',
    code: 'CX',
    coordinates: {
      lat: -10.5,
      lng: 105.66666666,
    },
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    coordinates: {
      lat: 19.5,
      lng: -80.5,
    },
  },
  {
    name: 'Cyprus',
    code: 'CY',
    coordinates: {
      lat: 35,
      lng: 33,
    },
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
    coordinates: {
      lat: 49.75,
      lng: 15.5,
    },
  },
  {
    name: 'Germany',
    code: 'DE',
    coordinates: {
      lat: 51,
      lng: 9,
    },
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    coordinates: {
      lat: 11.5,
      lng: 43,
    },
  },
  {
    name: 'Dominica',
    code: 'DM',
    coordinates: {
      lat: 15.41666666,
      lng: -61.33333333,
    },
  },
  {
    name: 'Denmark',
    code: 'DK',
    coordinates: {
      lat: 56,
      lng: 10,
    },
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    coordinates: {
      lat: 19,
      lng: -70.66666666,
    },
  },
  {
    name: 'Algeria',
    code: 'DZ',
    coordinates: {
      lat: 28,
      lng: 3,
    },
  },
  {
    name: 'Ecuador',
    code: 'EC',
    coordinates: {
      lat: -2,
      lng: -77.5,
    },
  },
  {
    name: 'Egypt',
    code: 'EG',
    coordinates: {
      lat: 27,
      lng: 30,
    },
  },
  {
    name: 'Eritrea',
    code: 'ER',
    coordinates: {
      lat: 15,
      lng: 39,
    },
  },
  {
    name: 'Western Sahara',
    code: 'EH',
    coordinates: {
      lat: 24.5,
      lng: -13,
    },
  },
  {
    name: 'Spain',
    code: 'ES',
    coordinates: {
      lat: 40,
      lng: -4,
    },
  },
  {
    name: 'Estonia',
    code: 'EE',
    coordinates: {
      lat: 59,
      lng: 26,
    },
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    coordinates: {
      lat: 8,
      lng: 38,
    },
  },
  {
    name: 'Finland',
    code: 'FI',
    coordinates: {
      lat: 64,
      lng: 26,
    },
  },
  {
    name: 'Fiji',
    code: 'FJ',
    coordinates: {
      lat: -18,
      lng: 175,
    },
  },
  {
    name: 'Falkland Islands',
    code: 'FK',
    coordinates: {
      lat: -51.75,
      lng: -59,
    },
  },
  {
    name: 'France',
    code: 'FR',
    coordinates: {
      lat: 46,
      lng: 2,
    },
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
    coordinates: {
      lat: 62,
      lng: -7,
    },
  },
  {
    name: 'Micronesia',
    code: 'FM',
    coordinates: {
      lat: 6.91666666,
      lng: 158.25,
    },
  },
  {
    name: 'Gabon',
    code: 'GA',
    coordinates: {
      lat: -1,
      lng: 11.75,
    },
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    coordinates: {
      lat: 54,
      lng: -2,
    },
  },
  {
    name: 'Georgia',
    code: 'GE',
    coordinates: {
      lat: 42,
      lng: 43.5,
    },
  },
  {
    name: 'Guernsey',
    code: 'GG',
    coordinates: {
      lat: 49.46666666,
      lng: -2.58333333,
    },
  },
  {
    name: 'Ghana',
    code: 'GH',
    coordinates: {
      lat: 8,
      lng: -2,
    },
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    coordinates: {
      lat: 36.13333333,
      lng: -5.35,
    },
  },
  {
    name: 'Guinea',
    code: 'GN',
    coordinates: {
      lat: 11,
      lng: -10,
    },
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    coordinates: {
      lat: 16.25,
      lng: -61.583333,
    },
  },
  {
    name: 'Gambia',
    code: 'GM',
    coordinates: {
      lat: 13.46666666,
      lng: -16.56666666,
    },
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
    coordinates: {
      lat: 12,
      lng: -15,
    },
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    coordinates: {
      lat: 2,
      lng: 10,
    },
  },
  {
    name: 'Greece',
    code: 'GR',
    coordinates: {
      lat: 39,
      lng: 22,
    },
  },
  {
    name: 'Grenada',
    code: 'GD',
    coordinates: {
      lat: 12.11666666,
      lng: -61.66666666,
    },
  },
  {
    name: 'Greenland',
    code: 'GL',
    coordinates: {
      lat: 72,
      lng: -40,
    },
  },
  {
    name: 'Guatemala',
    code: 'GT',
    coordinates: {
      lat: 15.5,
      lng: -90.25,
    },
  },
  {
    name: 'French Guiana',
    code: 'GF',
    coordinates: {
      lat: 4,
      lng: -53,
    },
  },
  {
    name: 'Guam',
    code: 'GU',
    coordinates: {
      lat: 13.46666666,
      lng: 144.78333333,
    },
  },
  {
    name: 'Guyana',
    code: 'GY',
    coordinates: {
      lat: 5,
      lng: -59,
    },
  },
  {
    name: 'Hong Kong',
    code: 'HK',
    coordinates: {
      lat: 22.267,
      lng: 114.188,
    },
  },
  {
    name: 'Honduras',
    code: 'HN',
    coordinates: {
      lat: 15,
      lng: -86.5,
    },
  },
  {
    name: 'Croatia',
    code: 'HR',
    coordinates: {
      lat: 45.16666666,
      lng: 15.5,
    },
  },
  {
    name: 'Haiti',
    code: 'HT',
    coordinates: {
      lat: 19,
      lng: -72.41666666,
    },
  },
  {
    name: 'Hungary',
    code: 'HU',
    coordinates: {
      lat: 47,
      lng: 20,
    },
  },
  {
    name: 'Indonesia',
    code: 'ID',
    coordinates: {
      lat: -5,
      lng: 120,
    },
  },
  {
    name: 'Isle of Man',
    code: 'IM',
    coordinates: {
      lat: 54.25,
      lng: -4.5,
    },
  },
  {
    name: 'India',
    code: 'IN',
    coordinates: {
      lat: 20,
      lng: 77,
    },
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    coordinates: {
      lat: -6,
      lng: 71.5,
    },
  },
  {
    name: 'Ireland',
    code: 'IE',
    coordinates: {
      lat: 53,
      lng: -8,
    },
  },
  {
    name: 'Iran',
    code: 'IR',
    coordinates: {
      lat: 32,
      lng: 53,
    },
  },
  {
    name: 'Iraq',
    code: 'IQ',
    coordinates: {
      lat: 33,
      lng: 44,
    },
  },
  {
    name: 'Iceland',
    code: 'IS',
    coordinates: {
      lat: 65,
      lng: -18,
    },
  },
  {
    name: 'Israel',
    code: 'IL',
    coordinates: {
      lat: 31.47,
      lng: 35.13,
    },
  },
  {
    name: 'Italy',
    code: 'IT',
    coordinates: {
      lat: 42.83333333,
      lng: 12.83333333,
    },
  },
  {
    name: 'Jamaica',
    code: 'JM',
    coordinates: {
      lat: 18.25,
      lng: -77.5,
    },
  },
  {
    name: 'Jersey',
    code: 'JE',
    coordinates: {
      lat: 49.25,
      lng: -2.16666666,
    },
  },
  {
    name: 'Jordan',
    code: 'JO',
    coordinates: {
      lat: 31,
      lng: 36,
    },
  },
  {
    name: 'Japan',
    code: 'JP',
    coordinates: {
      lat: 36,
      lng: 138,
    },
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    coordinates: {
      lat: 48,
      lng: 68,
    },
  },
  {
    name: 'Kenya',
    code: 'KE',
    coordinates: {
      lat: 1,
      lng: 38,
    },
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    coordinates: {
      lat: 41,
      lng: 75,
    },
  },
  {
    name: 'Cambodia',
    code: 'KH',
    coordinates: {
      lat: 13,
      lng: 105,
    },
  },
  {
    name: 'Kiribati',
    code: 'KI',
    coordinates: {
      lat: 1.41666666,
      lng: 173,
    },
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    coordinates: {
      lat: 17.33333333,
      lng: -62.75,
    },
  },
  {
    name: 'South Korea',
    code: 'KR',
    coordinates: {
      lat: 37,
      lng: 127.5,
    },
  },
  {
    name: 'Kosovo',
    code: 'XK',
    coordinates: {
      lat: 42.666667,
      lng: 21.166667,
    },
  },
  {
    name: 'Kuwait',
    code: 'KW',
    coordinates: {
      lat: 29.5,
      lng: 45.75,
    },
  },
  {
    name: 'Laos',
    code: 'LA',
    coordinates: {
      lat: 18,
      lng: 105,
    },
  },
  {
    name: 'Lebanon',
    code: 'LB',
    coordinates: {
      lat: 33.83333333,
      lng: 35.83333333,
    },
  },
  {
    name: 'Liberia',
    code: 'LR',
    coordinates: {
      lat: 6.5,
      lng: -9.5,
    },
  },
  {
    name: 'Libya',
    code: 'LY',
    coordinates: {
      lat: 25,
      lng: 17,
    },
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    coordinates: {
      lat: 13.88333333,
      lng: -60.96666666,
    },
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    coordinates: {
      lat: 47.26666666,
      lng: 9.53333333,
    },
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    coordinates: {
      lat: 7,
      lng: 81,
    },
  },
  {
    name: 'Lesotho',
    code: 'LS',
    coordinates: {
      lat: -29.5,
      lng: 28.5,
    },
  },
  {
    name: 'Lithuania',
    code: 'LT',
    coordinates: {
      lat: 56,
      lng: 24,
    },
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    coordinates: {
      lat: 49.75,
      lng: 6.16666666,
    },
  },
  {
    name: 'Latvia',
    code: 'LV',
    coordinates: {
      lat: 57,
      lng: 25,
    },
  },
  {
    name: 'Macau',
    code: 'MO',
    coordinates: {
      lat: 22.16666666,
      lng: 113.55,
    },
  },
  {
    name: 'Saint Martin',
    code: 'MF',
    coordinates: {
      lat: 18.08333333,
      lng: -63.95,
    },
  },
  {
    name: 'Morocco',
    code: 'MA',
    coordinates: {
      lat: 32,
      lng: -5,
    },
  },
  {
    name: 'Monaco',
    code: 'MC',
    coordinates: {
      lat: 43.73333333,
      lng: 7.4,
    },
  },
  {
    name: 'Moldova',
    code: 'MD',
    coordinates: {
      lat: 47,
      lng: 29,
    },
  },
  {
    name: 'Madagascar',
    code: 'MG',
    coordinates: {
      lat: -20,
      lng: 47,
    },
  },
  {
    name: 'Maldives',
    code: 'MV',
    coordinates: {
      lat: 3.25,
      lng: 73,
    },
  },
  {
    name: 'Mexico',
    code: 'MX',
    coordinates: {
      lat: 23,
      lng: -102,
    },
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
    coordinates: {
      lat: 9,
      lng: 168,
    },
  },
  {
    name: 'Macedonia',
    code: 'MK',
    coordinates: {
      lat: 41.83333333,
      lng: 22,
    },
  },
  {
    name: 'Mali',
    code: 'ML',
    coordinates: {
      lat: 17,
      lng: -4,
    },
  },
  {
    name: 'Malta',
    code: 'MT',
    coordinates: {
      lat: 35.83333333,
      lng: 14.58333333,
    },
  },
  {
    name: 'Myanmar',
    code: 'MM',
    coordinates: {
      lat: 22,
      lng: 98,
    },
  },
  {
    name: 'Montenegro',
    code: 'ME',
    coordinates: {
      lat: 42.5,
      lng: 19.3,
    },
  },
  {
    name: 'Mongolia',
    code: 'MN',
    coordinates: {
      lat: 46,
      lng: 105,
    },
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
    coordinates: {
      lat: 15.2,
      lng: 145.75,
    },
  },
  {
    name: 'Mozambique',
    code: 'MZ',
    coordinates: {
      lat: -18.25,
      lng: 35,
    },
  },
  {
    name: 'Mauritania',
    code: 'MR',
    coordinates: {
      lat: 20,
      lng: -12,
    },
  },
  {
    name: 'Montserrat',
    code: 'MS',
    coordinates: {
      lat: 16.75,
      lng: -62.2,
    },
  },
  {
    name: 'Martinique',
    code: 'MQ',
    coordinates: {
      lat: 14.666667,
      lng: -61,
    },
  },
  {
    name: 'Mauritius',
    code: 'MU',
    coordinates: {
      lat: -20.28333333,
      lng: 57.55,
    },
  },
  {
    name: 'Malawi',
    code: 'MW',
    coordinates: {
      lat: -13.5,
      lng: 34,
    },
  },
  {
    name: 'Malaysia',
    code: 'MY',
    coordinates: {
      lat: 2.5,
      lng: 112.5,
    },
  },
  {
    name: 'Mayotte',
    code: 'YT',
    coordinates: {
      lat: -12.83333333,
      lng: 45.16666666,
    },
  },
  {
    name: 'Namibia',
    code: 'NA',
    coordinates: {
      lat: -22,
      lng: 17,
    },
  },
  {
    name: 'New Caledonia',
    code: 'NC',
    coordinates: {
      lat: -21.5,
      lng: 165.5,
    },
  },
  {
    name: 'Niger',
    code: 'NE',
    coordinates: {
      lat: 16,
      lng: 8,
    },
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    coordinates: {
      lat: -29.03333333,
      lng: 167.95,
    },
  },
  {
    name: 'Nigeria',
    code: 'NG',
    coordinates: {
      lat: 10,
      lng: 8,
    },
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    coordinates: {
      lat: 13,
      lng: -85,
    },
  },
  {
    name: 'Niue',
    code: 'NU',
    coordinates: {
      lat: -19.03333333,
      lng: -169.86666666,
    },
  },
  {
    name: 'Netherlands',
    code: 'NL',
    coordinates: {
      lat: 52.5,
      lng: 5.75,
    },
  },
  {
    name: 'Norway',
    code: 'NO',
    coordinates: {
      lat: 62,
      lng: 10,
    },
  },
  {
    name: 'Nepal',
    code: 'NP',
    coordinates: {
      lat: 28,
      lng: 84,
    },
  },
  {
    name: 'Nauru',
    code: 'NR',
    coordinates: {
      lat: -0.53333333,
      lng: 166.91666666,
    },
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    coordinates: {
      lat: -41,
      lng: 174,
    },
  },
  {
    name: 'Oman',
    code: 'OM',
    coordinates: {
      lat: 21,
      lng: 57,
    },
  },
  {
    name: 'Pakistan',
    code: 'PK',
    coordinates: {
      lat: 30,
      lng: 70,
    },
  },
  {
    name: 'Panama',
    code: 'PA',
    coordinates: {
      lat: 9,
      lng: -80,
    },
  },
  {
    name: 'Pitcairn Islands',
    code: 'PN',
    coordinates: {
      lat: -25.06666666,
      lng: -130.1,
    },
  },
  {
    name: 'Peru',
    code: 'PE',
    coordinates: {
      lat: -10,
      lng: -76,
    },
  },
  {
    name: 'Philippines',
    code: 'PH',
    coordinates: {
      lat: 13,
      lng: 122,
    },
  },
  {
    name: 'Palau',
    code: 'PW',
    coordinates: {
      lat: 7.5,
      lng: 134.5,
    },
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    coordinates: {
      lat: -6,
      lng: 147,
    },
  },
  {
    name: 'Poland',
    code: 'PL',
    coordinates: {
      lat: 52,
      lng: 20,
    },
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    coordinates: {
      lat: 18.25,
      lng: -66.5,
    },
  },
  {
    name: 'North Korea',
    code: 'KP',
    coordinates: {
      lat: 40,
      lng: 127,
    },
  },
  {
    name: 'Portugal',
    code: 'PT',
    coordinates: {
      lat: 39.5,
      lng: -8,
    },
  },
  {
    name: 'Paraguay',
    code: 'PY',
    coordinates: {
      lat: -23,
      lng: -58,
    },
  },
  {
    name: 'Palestine',
    code: 'PS',
    coordinates: {
      lat: 31.9,
      lng: 35.2,
    },
  },
  {
    name: 'French Polynesia',
    code: 'PF',
    coordinates: {
      lat: -15,
      lng: -140,
    },
  },
  {
    name: 'Qatar',
    code: 'QA',
    coordinates: {
      lat: 25.5,
      lng: 51.25,
    },
  },
  {
    name: 'Réunion',
    code: 'RE',
    coordinates: {
      lat: -21.15,
      lng: 55.5,
    },
  },
  {
    name: 'Romania',
    code: 'RO',
    coordinates: {
      lat: 46,
      lng: 25,
    },
  },
  {
    name: 'Russia',
    code: 'RU',
    coordinates: {
      lat: 60,
      lng: 100,
    },
  },
  {
    name: 'Rwanda',
    code: 'RW',
    coordinates: {
      lat: -2,
      lng: 30,
    },
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    coordinates: {
      lat: 25,
      lng: 45,
    },
  },
  {
    name: 'Sudan',
    code: 'SD',
    coordinates: {
      lat: 15,
      lng: 30,
    },
  },
  {
    name: 'Senegal',
    code: 'SN',
    coordinates: {
      lat: 14,
      lng: -14,
    },
  },
  {
    name: 'Singapore',
    code: 'SG',
    coordinates: {
      lat: 1.36666666,
      lng: 103.8,
    },
  },
  {
    name: 'South Georgia',
    code: 'GS',
    coordinates: {
      lat: -54.5,
      lng: -37,
    },
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
    coordinates: {
      lat: 78,
      lng: 20,
    },
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    coordinates: {
      lat: -8,
      lng: 159,
    },
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    coordinates: {
      lat: 8.5,
      lng: -11.5,
    },
  },
  {
    name: 'El Salvador',
    code: 'SV',
    coordinates: {
      lat: 13.83333333,
      lng: -88.91666666,
    },
  },
  {
    name: 'San Marino',
    code: 'SM',
    coordinates: {
      lat: 43.76666666,
      lng: 12.41666666,
    },
  },
  {
    name: 'Somalia',
    code: 'SO',
    coordinates: {
      lat: 10,
      lng: 49,
    },
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
    coordinates: {
      lat: 46.83333333,
      lng: -56.33333333,
    },
  },
  {
    name: 'Serbia',
    code: 'RS',
    coordinates: {
      lat: 44,
      lng: 21,
    },
  },
  {
    name: 'South Sudan',
    code: 'SS',
    coordinates: {
      lat: 7,
      lng: 30,
    },
  },
  {
    name: 'São Tomé and Príncipe',
    code: 'ST',
    coordinates: {
      lat: 1,
      lng: 7,
    },
  },
  {
    name: 'Suriname',
    code: 'SR',
    coordinates: {
      lat: 4,
      lng: -56,
    },
  },
  {
    name: 'Slovakia',
    code: 'SK',
    coordinates: {
      lat: 48.66666666,
      lng: 19.5,
    },
  },
  {
    name: 'Slovenia',
    code: 'SI',
    coordinates: {
      lat: 46.11666666,
      lng: 14.81666666,
    },
  },
  {
    name: 'Sweden',
    code: 'SE',
    coordinates: {
      lat: 62,
      lng: 15,
    },
  },
  {
    name: 'Swaziland',
    code: 'SZ',
    coordinates: {
      lat: -26.5,
      lng: 31.5,
    },
  },
  {
    name: 'Sint Maarten',
    code: 'SX',
    coordinates: {
      lat: 18.033333,
      lng: -63.05,
    },
  },
  {
    name: 'Seychelles',
    code: 'SC',
    coordinates: {
      lat: -4.58333333,
      lng: 55.66666666,
    },
  },
  {
    name: 'Syria',
    code: 'SY',
    coordinates: {
      lat: 35,
      lng: 38,
    },
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    coordinates: {
      lat: 21.75,
      lng: -71.58333333,
    },
  },
  {
    name: 'Chad',
    code: 'TD',
    coordinates: {
      lat: 15,
      lng: 19,
    },
  },
  {
    name: 'Togo',
    code: 'TG',
    coordinates: {
      lat: 8,
      lng: 1.16666666,
    },
  },
  {
    name: 'Thailand',
    code: 'TH',
    coordinates: {
      lat: 15,
      lng: 100,
    },
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    coordinates: {
      lat: 39,
      lng: 71,
    },
  },
  {
    name: 'Tokelau',
    code: 'TK',
    coordinates: {
      lat: -9,
      lng: -172,
    },
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    coordinates: {
      lat: 40,
      lng: 60,
    },
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
    coordinates: {
      lat: -8.83333333,
      lng: 125.91666666,
    },
  },
  {
    name: 'Tonga',
    code: 'TO',
    coordinates: {
      lat: -20,
      lng: -175,
    },
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    coordinates: {
      lat: 11,
      lng: -61,
    },
  },
  {
    name: 'Tunisia',
    code: 'TN',
    coordinates: {
      lat: 34,
      lng: 9,
    },
  },
  {
    name: 'Turkey',
    code: 'TR',
    coordinates: {
      lat: 39,
      lng: 35,
    },
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    coordinates: {
      lat: -8,
      lng: 178,
    },
  },
  {
    name: 'Taiwan',
    code: 'TW',
    coordinates: {
      lat: 23.5,
      lng: 121,
    },
  },
  {
    name: 'Tanzania',
    code: 'TZ',
    coordinates: {
      lat: -6,
      lng: 35,
    },
  },
  {
    name: 'Uganda',
    code: 'UG',
    coordinates: {
      lat: 1,
      lng: 32,
    },
  },
  {
    name: 'Ukraine',
    code: 'UA',
    coordinates: {
      lat: 49,
      lng: 32,
    },
  },
  {
    name: 'United States Minor Outlying Islands',
    code: 'UM',
    coordinates: {
      lat: 19.2911437,
      lng: 166.618332,
    },
  },
  {
    name: 'Uruguay',
    code: 'UY',
    coordinates: {
      lat: -33,
      lng: -56,
    },
  },
  {
    name: 'United States',
    code: 'US',
    coordinates: {
      lat: 38,
      lng: -97,
    },
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
    coordinates: {
      lat: 41,
      lng: 64,
    },
  },
  {
    name: 'Vatican City',
    code: 'VA',
    coordinates: {
      lat: 41.9,
      lng: 12.45,
    },
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    coordinates: {
      lat: 13.25,
      lng: -61.2,
    },
  },
  {
    name: 'Venezuela',
    code: 'VE',
    coordinates: {
      lat: 8,
      lng: -66,
    },
  },
  {
    name: 'British Virgin Islands',
    code: 'VG',
    coordinates: {
      lat: 18.431383,
      lng: -64.62305,
    },
  },
  {
    name: 'United States Virgin Islands',
    code: 'VI',
    coordinates: {
      lat: 18.35,
      lng: -64.933333,
    },
  },
  {
    name: 'Vietnam',
    code: 'VN',
    coordinates: {
      lat: 16.16666666,
      lng: 107.83333333,
    },
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    coordinates: {
      lat: -16,
      lng: 167,
    },
  },
  {
    name: 'Wallis and Futuna',
    code: 'WF',
    coordinates: {
      lat: -13.3,
      lng: -176.2,
    },
  },
  {
    name: 'Samoa',
    code: 'WS',
    coordinates: {
      lat: -13.58333333,
      lng: -172.33333333,
    },
  },
  {
    name: 'Yemen',
    code: 'YE',
    coordinates: {
      lat: 15,
      lng: 48,
    },
  },
  {
    name: 'South Africa',
    code: 'ZA',
    coordinates: {
      lat: -29,
      lng: 24,
    },
  },
  {
    name: 'Zambia',
    code: 'ZM',
    coordinates: {
      lat: -15,
      lng: 30,
    },
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    coordinates: {
      lat: -20,
      lng: 30,
    },
  },
];

export default countries;
