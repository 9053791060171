// types
import { Roles } from 'ducks/merchantDucks/user/types';

// constants
import Rules from 'restrictions/roles/constants/rules';

const config: Record<Roles, Rules[]> = {
  [Roles.INPLAYER]: [],
  [Roles.OWNER]: [],
  [Roles.FOLLOWER]: [],
  [Roles.MERCHANT]: [],
  [Roles.MASTER]: [],
  [Roles.ADMIN]: [Rules.DISABLE_ORGANISATION_SECTION],
  [Roles.CONTENT_MANAGER]: [
    Rules.DISABLE_ORGANISATION_SECTION,
    Rules.DISABLE_TRANSACTIONS_SECTION,
    Rules.DISABLE_AUDIENCE_SECTION,
    Rules.DISABLE_ASSET_ACCESS_SECTION,
    Rules.DISABLE_ANALYTICS_SECTION,
    Rules.DISABLE_RELATE_SECTION,
    Rules.DISABLE_BILLING_AND_PLAN_SECTION,
    Rules.DISABLE_WEBHOOKS_SECTION,
  ],
  [Roles.ANALYST]: [
    Rules.DISABLE_ORGANISATION_SECTION,
    Rules.DISABLE_SETTINGS_SECTION,
    Rules.DISABLE_ASSETS_SECTION,
    Rules.DISABLE_RELATE_SECTION,
    Rules.DISABLE_BILLING_AND_PLAN_SECTION,
    Rules.DISABLE_WEBHOOKS_SECTION,
    Rules.DISABLE_DEACTIVATING_CUSTOMER_ACCOUNT,
    Rules.DISABLE_DELETING_CUSTOMER_ACCOUNT,
    Rules.READONLY_CUSTOMER_DETAILS_AND_METADATA,
    Rules.READONLY_PAYMENTS_SUBSCRIPTIONS_AND_ACCESSES,
    Rules.DISABLE_LANDING_PAGE_BUILDR_SECTION,
    Rules.DISABLE_EMAIL_TEMPLATES_SECTION,
  ],
  [Roles.CUSTOMER_SUPPORT]: [
    Rules.DISABLE_ORGANISATION_SECTION,
    Rules.DISABLE_ANALYTICS_SECTION,
    Rules.DISABLE_RELATE_SECTION,
    Rules.DISABLE_BILLING_AND_PLAN_SECTION,
    Rules.DISABLE_ALL_REPORTS_SECTIONS,
    Rules.DISABLE_DELETING_CUSTOMER_ACCOUNT,
    Rules.DISABLE_SEGMENTATION_SECTION,
    Rules.DISABLE_SEGMENTATION_REPORTS_SECTION,
    Rules.DISABLE_ADD_NEW_VOUCHER_SECTION,
    Rules.DISABLE_ADD_NEW_BRANDING_THEME_SECTION,
    Rules.HIDE_ASSETS_SECTION_TOP_STATS,
    Rules.HIDE_VOUCHERS_SECTION_TOP_STATS,
    Rules.HIDE_AUDIENCE_SECTION_TOP_STATS,
    Rules.READONLY_ASSETS_AND_PACKAGES,
    Rules.READONLY_VOUCHERS,
    Rules.READONLY_SOCIAL_AUTH_SETUP,
    Rules.READONLY_OAUTH_AND_SSO_SETUP,
    Rules.READONLY_CONCURRENT_SESSIONS,
    Rules.READONLY_GEO_RESTRICTIONS,
    Rules.READONLY_DOMAIN_RESTRICTIONS,
    Rules.READONLY_CUSTOM_FIELDS,
    Rules.READONLY_AGE_RESTRICTIONS,
    Rules.READONLY_BRANDING_THEMES,
    Rules.READONLY_OVP_INTEGRATIONS,
    Rules.READONLY_IN_APP_INTEGRATIONS,
    Rules.DISABLE_LANDING_PAGE_BUILDR_SECTION,
    Rules.DISABLE_EMAIL_TEMPLATES_SECTION,
  ],
};

export default config;
