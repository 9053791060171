export const copyToClipboard = (value: string, callback?: Function) => {
  const textarea = document.createElement('textarea');
  textarea.value = value;
  document.body.appendChild(textarea);
  textarea.select();

  let copied = false;

  try {
    document.execCommand('copy');
    copied = true;
  } catch (_) {
  } finally {
    document.body.removeChild(textarea);
  }

  if (callback) {
    return callback(copied);
  }

  return new Promise((resolve) => resolve(copied));
};
