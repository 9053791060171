import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import findLast from 'lodash/findLast';
import includes from 'lodash/includes';

// Hooks
import useRestrictionRules from 'restrictions/roles/hooks/useRestrictionRules';
import useRestrictionFeatures from 'restrictions/roles/hooks/useRestrictionFeatures';

// types
import RootState from 'ducks/RootState';
import { Roles } from 'ducks/merchantDucks/user/types';

// Navigation Routes
import getRoutesByRole, { AppRoute } from 'components/Navigation/navRoutes';

// Components
import TopNav from 'components/Navigation/TopNav';

const withTopNav = <T extends {}>(Component: React.ComponentType<T>): React.ComponentType<T> => {
  const WrappedComponent: FunctionComponent<T> = (props) => {
    const location = useLocation();
    const { hasRule } = useRestrictionRules();
    const { hasFeature } = useRestrictionFeatures();
    const userRoles = useSelector((state: RootState) => state.auth.user.roles);
    const role = userRoles.includes(Roles.INPLAYER) ? Roles.INPLAYER : Roles.MERCHANT;

    const filteredRoutes = getRoutesByRole(role);
    const selectedNavigationTab = findLast(filteredRoutes, (route) => includes(location.pathname, route.path));

    let tabsToRender: Array<AppRoute> = [];

    if (selectedNavigationTab) {
      const { subpaths = [], includeOriginalInSubpaths, topNavTitle } = selectedNavigationTab;

      const tabsToConsider = includeOriginalInSubpaths
        ? [{ ...selectedNavigationTab, path: '', ...(topNavTitle && { title: topNavTitle }) }, ...subpaths]
        : subpaths;

      tabsToRender = tabsToConsider.filter(({ restrictionRule, restrictionFeature }: AppRoute) => {
        if (restrictionRule) {
          return !hasRule(restrictionRule);
        }

        if (restrictionFeature) {
          return hasFeature(restrictionFeature);
        }

        return true;
      });
    }

    return (
      <>
        <TopNav tabs={tabsToRender} />
        <Component {...props} />
      </>
    );
  };

  WrappedComponent.displayName = `withTopNav(${Component.displayName || Component.name})`;

  return WrappedComponent;
};

export default withTopNav;
