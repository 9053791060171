import React from 'react';
import { Button } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';
import { Colors } from './colors';

const StyledButton = styled(Button)<{ padding: string }>`
  padding: ${({ padding }) => padding || '0.75rem 2.5rem'};
  background-color: ${Colors.green};
  color: ${Colors.black};
  font-family: Montserrat, 'sans-serif';
  font-weight: ${({ theme }) => theme.font.weights.semiBold};
  :hover {
    background-color: ${Colors.black};
  }
`;
const AuthButton = ({ padding, ...props }: any) => {
  return <StyledButton padding={padding} {...props} />;
};

export default AuthButton;
