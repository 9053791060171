import React from 'react';
import { CenteredForm } from 'components/AuthFormContainer';
import { Grid } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';

// components
import { Colors } from './colors';
import { StyledTypography as Typography } from './sharedComponents';

const { Container, Cell } = Grid;

const StyledContainer = styled(Container)<{ paddingTop?: string }>`
  margin: 7rem 3rem;
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop};`}
`;

const FormMargin = styled.div`
  max-width: 35rem;
  width: 100%;
  margin-bottom: 3rem;
`;

const StyledDescription = styled.span`
  font-size: ${({ theme }) => theme.font.sizes.medium};
  color: ${Colors.black};
  font-family: Montserrat, 'sans-serif';
`;

const StyledTypography = styled(Typography)`
  font-size: 35px;
  margin: 0.75rem;
  padding: 0;
  font-weight: ${({ theme }) => theme.font.weights.bold};
`;

const FormContainer = ({ title, description, paddingTop, children }: any) => {
  return (
    <CenteredForm>
      <FormMargin>
        <StyledContainer alignContent="space-around" columns={1} gap="2.25rem" paddingTop={paddingTop}>
          {title && (
            <Cell center>
              <StyledTypography variant="h3">{title}</StyledTypography>
              {description && (
                <Cell center>
                  <StyledDescription>{description}</StyledDescription>
                </Cell>
              )}
            </Cell>
          )}
          {children}
        </StyledContainer>
      </FormMargin>
    </CenteredForm>
  );
};

export default FormContainer;
