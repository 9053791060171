import { AccessFee } from '../items/types';

// Subscription Group

export interface SubscriptionGroupState {
  collection: Array<SubscriptionGroup>;
  isFetching: boolean;
}

export interface SubscriptionGroup {
  subscriptionGroupId: number;
  name: string;
  createdAt: number;
  updatedAt: number;
  accessFees: Array<SubscriptionGroupItemState>;
}

export enum CreateSubscriptionGroupActionTypes {
  START = 'CREATE_SUBSCRIPTION_GROUP_REQUEST',
  SUCCESS = 'CREATE_SUBSCRIPTION_GROUP_SUCCESS',
  FAILURE = 'CREATE_SUBSCRIPTION_GROUP_FAILURE',
}

export enum DeleteSubscriptionGroupActionTypes {
  START = 'DELETE_SUBSCRIPTION_GROUP_REQUEST',
  SUCCESS = 'DELETE_SUBSCRIPTION_GROUP_SUCCESS',
  FAILURE = 'DELETE_SUBSCRIPTION_GROUP_FAILURE',
}

export enum EditSubscriptionGroupActionTypes {
  START = 'EDIT_SUBSCRIPTION_GROUP_REQUEST',
  SUCCESS = 'EDIT_SUBSCRIPTION_GROUP_SUCCESS',
  FAILURE = 'EDIT_SUBSCRIPTION_GROUP_FAILURE',
}

// Subscription Group Item
export interface SubscriptionGroupItemParams {
  access_fee_id: number;
}

export interface SubscriptionGroupItemState {
  subscriptionGroupAccessFeeId: number;
  subscriptionGroup: SubscriptionGroup;
  createdAt: number;
  updatedAt: number;
  accessFee: AccessFee;
}

export enum AddSubscriptionGroupItemActionTypes {
  START = 'ADD_SUBSCRIPTION_GROUP_ITEM_REQUEST',
  SUCCESS = 'ADD_SUBSCRIPTION_GROUP_ITEM_SUCCESS',
  FAILURE = 'ADD_SUBSCRIPTION_GROUP_ITEM_FAILURE',
}

export enum DeleteSubscriptionGroupItemActionTypes {
  START = 'DELETE_SUBSCRIPTION_GROUP_ITEM_REQUEST',
  SUCCESS = 'DELETE_SUBSCRIPTION_GROUP_ITEM_SUCCESS',
  FAILURE = 'DELETE_SUBSCRIPTION_GROUP_ITEM_FAILURE',
}
