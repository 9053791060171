import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { AiFillQuestionCircle } from 'react-icons/ai';

// actions
import { setTourGuideState } from 'ducks/merchantDucks/user/actions';

// components
import { AnalyticsComponent } from '@inplayer-org/inplayer-ui';
import { StyledButtonLink, StyledButton } from './TourGuideButton';

// hooks
import { useTourGuideState } from './useTourGuideState';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  justify-content: space-around;
  margin-bottom: -22px;
`;

const TourGuideCloseStep = () => {
  const dispatch = useDispatch();

  const { isSectionTour, currentStep } = useTourGuideState();

  const onContinueTourClick = () => {
    dispatch(setTourGuideState({ isRequestCloseClicked: false }));
  };

  const onCloseTourClick = () => {
    dispatch(setTourGuideState({ isCloseTourClicked: true }));
  };

  return (
    <AnalyticsComponent>
      {({ pages, tracker, merchantId, ip }) => (
        <Wrapper>
          <h2>End tour?</h2>
          <p>
            If you need to take the tour again, just click the{' '}
            {isSectionTour ? (
              <>
                <AiFillQuestionCircle /> button at the top right.
              </>
            ) : (
              'InPlayer logo at the top left.'
            )}
          </p>
          <ButtonWrapper>
            <StyledButtonLink
              onClick={() => {
                tracker.track({
                  event: 'click',
                  type: 'button',
                  tag: `link_continue_${currentStep}`,
                  pages,
                  merchantId,
                  ip,
                });
                onContinueTourClick();
              }}
            >
              Continue
            </StyledButtonLink>
            <StyledButton
              onClick={() => {
                tracker.track({
                  event: 'click',
                  type: 'button',
                  tag: `button_end_tour_${currentStep}`,
                  pages,
                  merchantId,
                  ip,
                });
                onCloseTourClick();
              }}
            >
              End Tour
            </StyledButton>
          </ButtonWrapper>
        </Wrapper>
      )}
    </AnalyticsComponent>
  );
};

export default TourGuideCloseStep;
