import { Reducer, combineReducers } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import cloneDeep from 'lodash/cloneDeep';
import { PaymentDetails } from 'ducks/merchantDucks/payments/types';
import {
  PaginatorState,
  ResultKeys,
  AudienceDetails,
  MerchantDetails,
  SubscriptionDetails,
  MailingListDetails,
  AssetAccessDetails,
  MailTemplatesDetails,
} from './types';
import { RefundActionTypes, FetchPaginatedDataTypes, ResetStateActionTypes } from './actions';

export const initialPaginatorState: PaginatorState<any> = {
  total: 0,
  isFetching: false,
};

type PaginatorReducer = Reducer<PaginatorState<any>>;

const fetchDataRequest: PaginatorReducer = (state = initialPaginatorState) => ({
  ...state,
  isFetching: true,
});

// resetPages is true when chaning the filter in the table
const fetchDataSuccess: PaginatorReducer = (
  state = initialPaginatorState,
  { payload: { data, paginationPage, resetPages } }
) => {
  const { isFetching, total, ...rest } = cloneDeep(state);
  const pages = resetPages ? { [paginationPage]: data.collection } : { ...rest, [paginationPage]: data.collection };

  return {
    ...pages,
    total: data.total,
    isFetching: false,
    refundRequest: false,
  };
};

const fetchDataFailure: PaginatorReducer = () => ({
  ...initialPaginatorState,
});

const refundPaymentStart: PaginatorReducer = (state = initialPaginatorState) => ({
  ...state,
  refundRequest: true,
});

const refundPaymentEnd: PaginatorReducer = (state = initialPaginatorState) => ({
  ...state,
  refundRequest: false,
});

const resetState: PaginatorReducer = () => ({
  ...initialPaginatorState,
});

const actionsMap: ActionMap<PaginatorState<any>> = {
  /* FETCH PAGINATED DATA */
  [FetchPaginatedDataTypes.START]: fetchDataRequest,
  [FetchPaginatedDataTypes.SUCCESS]: fetchDataSuccess,
  [FetchPaginatedDataTypes.ERROR]: fetchDataFailure,
  /* REFUND PAYMENT */
  [RefundActionTypes.START]: refundPaymentStart,
  [RefundActionTypes.SUCCESS]: refundPaymentEnd,
  [RefundActionTypes.ERROR]: refundPaymentEnd,
  /* RESET STATE */
  [ResetStateActionTypes.RESET_STATE]: resetState,
};

const paginatorReducer = reducerWithActionMap(actionsMap, initialPaginatorState);

const onlyForType = <T extends any>(
  reducer: Reducer<PaginatorState<T>>,
  resultKey: string
): Reducer<PaginatorState<T>> => (state = initialPaginatorState, action) => {
  return typeof action.meta === 'undefined' || action.meta.resultKey !== resultKey ? state : reducer(state, action);
};

export default combineReducers({
  [ResultKeys.AUDIENCE]: onlyForType<AudienceDetails>(paginatorReducer, ResultKeys.AUDIENCE),
  [ResultKeys.PAYMENTS]: onlyForType<PaymentDetails>(paginatorReducer, ResultKeys.PAYMENTS),
  [ResultKeys.SUBSCRIPTIONS]: onlyForType<SubscriptionDetails>(paginatorReducer, ResultKeys.SUBSCRIPTIONS),
  [ResultKeys.MERCHANTS]: onlyForType<MerchantDetails>(paginatorReducer, ResultKeys.MERCHANTS),
  [ResultKeys.MAILING_LIST]: onlyForType<MailingListDetails>(paginatorReducer, ResultKeys.MAILING_LIST),
  [ResultKeys.ASSET_ACCESSES]: onlyForType<AssetAccessDetails>(paginatorReducer, ResultKeys.ASSET_ACCESSES),
  [ResultKeys.MAIL_TEMPLATES]: onlyForType<MailTemplatesDetails>(paginatorReducer, ResultKeys.MAIL_TEMPLATES),
});
