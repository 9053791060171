import { Typography, Colors } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Title = styled(Typography)`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.font.weights.bold};
  padding-bottom: 0.5rem;
  color: ${Colors.fontGray};
`;

interface ContainerProps {
  width?: string;
  margin?: string;
}

export const Container = styled.div<ContainerProps>`
  background-color: ${Colors.white};
  box-shadow: 0 0 4px 1px ${transparentize(0.87, Colors.fontDarkGray)};
  border-radius: 5px;
  padding: 0.8rem;
  border-radius: 5px;
  display: inline-block;
  font-size: ${({ theme }) => theme.font.sizes.small};
  width: ${({ width }) => width || '25%'};
  height: 100%;
  margin: ${({ margin }) => margin || '0%'};
`;
