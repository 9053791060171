import React, { FunctionComponent } from 'react';

// types
import { ModalNames } from 'ducks/merchantDucks/modal/types';

// components
import BaseModal, { ModalActions } from 'components/Modals/BaseModal';
import PaymentFeesForm from 'pages/AdminPages/AdminMerchants/PaymentManagement/components/forms/PaymentFeesForm';

interface OwnProps {
  canEditPaymentGateways: boolean;
  hasInheritance: boolean;
  selectedGateway: string;
}

type Props = OwnProps;

const SetFeesModal: FunctionComponent<Props> = ({ canEditPaymentGateways, hasInheritance, selectedGateway }: Props) => {
  return (
    <BaseModal
      isOverflowVisible
      title="Payment Fees"
      modalName={ModalNames.SetFees}
      render={({ closeModal }: ModalActions) => (
        <PaymentFeesForm
          handleCloseModal={closeModal}
          canEditPaymentGateways={canEditPaymentGateways}
          hasInheritance={hasInheritance}
          selectedGateway={selectedGateway}
        />
      )}
    />
  );
};

export default SetFeesModal;
