import { device } from 'aws-iot-device-sdk';
import moment from 'moment';

// types
import { Optional } from './types';

interface IamToken {
  region: string;
  accessKey: string;
  secretKey: string;
  sessionToken: string;
  expires: number;
}

interface MasterToken {
  masterToken: Optional<string>;
  masterExpires: Optional<number | string>;
  masterRefreshToken: Optional<string>;
  full_name: Optional<string>;
  avatar_url: Optional<string>;
}

const HALF_HOUR = 1000 * 60 * 30;

export interface AdminToken {
  adminToken: Optional<string>;
  adminExpires: Optional<number | string>;
  adminRefreshToken: Optional<string>;
  full_name: Optional<string>;
}

export class TokenManager {
  private _key = 'inplayer_key';

  private _refreshToken = 'refresh_token';

  private _IAMKey = 'aws_token';

  private _IAMAdminKey = 'aws_admin_token';

  private _expires = 'expires';

  private _isFetching = false;

  private _client: Optional<device> = null;

  private _clientAdmin: Optional<device> = null;

  private _master = 'master';

  private _adminKey = 'admin';

  private _admin: Optional<AdminToken> = null;

  get token(): Optional<string> {
    return localStorage.getItem(this._key);
  }

  set token(token: Optional<string>) {
    if (token) {
      localStorage.setItem(this._key, token);
    }
  }

  get IAMToken(): Optional<IamToken> {
    const tokenString = localStorage.getItem(this._IAMKey);
    if (!tokenString) {
      return null;
    }

    const token = JSON.parse(tokenString) as IamToken;

    const now = new Date().getTime();

    return now < token.expires ? token : null;
  }

  set IAMToken(token: Optional<IamToken>) {
    localStorage.setItem(
      this._IAMKey,
      JSON.stringify({
        ...token,
        expires: new Date().getTime() + HALF_HOUR,
      })
    );
  }

  get IAMAdminToken(): Optional<IamToken> {
    const tokenString = localStorage.getItem(this._IAMAdminKey);
    if (!tokenString) {
      return null;
    }

    const token = JSON.parse(tokenString) as IamToken;

    const now = new Date().getTime();

    return now < token.expires ? token : null;
  }

  set IAMAdminToken(token: Optional<IamToken>) {
    localStorage.setItem(
      this._IAMAdminKey,
      JSON.stringify({
        ...token,
        expires: new Date().getTime() + HALF_HOUR,
      })
    );
  }

  get refreshToken(): Optional<string> {
    return localStorage.getItem(this._refreshToken);
  }

  set refreshToken(refreshToken: Optional<string>) {
    if (refreshToken) {
      localStorage.setItem(this._refreshToken, refreshToken);
    }
  }

  get expires(): Optional<number | string> {
    const expires = localStorage.getItem(this._expires);
    return expires ? Number(localStorage.getItem(this._expires)) : null;
  }

  set expires(expires: Optional<number | string>) {
    if (expires) {
      localStorage.setItem(this._expires, expires.toString());
    }
  }

  get isExpired() {
    if (!this.expires) {
      return false;
    }
    return this.expires < moment().unix();
  }

  get isAdminExpired() {
    if (!this.admin?.adminExpires) {
      return false;
    }
    return this.admin.adminExpires < moment().unix();
  }

  set client(client: Optional<device>) {
    this._client = client;
  }

  get client(): Optional<device> {
    return this._client;
  }

  set clientAdmin(client: Optional<device>) {
    this._clientAdmin = client;
  }

  get clientAdmin(): Optional<device> {
    return this._clientAdmin;
  }

  setIsFetching() {
    this._isFetching = !this._isFetching;
  }

  getIsFetching() {
    return this._isFetching;
  }

  clear() {
    localStorage.removeItem(this._key);
    localStorage.removeItem(this._IAMKey);
    this.clearClient();
    localStorage.removeItem(this._master);
  }

  clearAdmin() {
    localStorage.removeItem(this._adminKey);
    localStorage.removeItem(this._IAMAdminKey);
    this.clearClient();
  }

  clearClient() {
    if (this.client) {
      this.client.end();
    }

    this.client = null;
  }

  set master(master: Optional<MasterToken>) {
    localStorage.setItem(
      this._master,
      JSON.stringify({
        ...master,
      })
    );
  }

  get master(): Optional<MasterToken> {
    const master = localStorage.getItem(this._master);
    if (!master) {
      return null;
    }
    return JSON.parse(master);
  }

  set admin(admin: Optional<AdminToken>) {
    localStorage.setItem(
      this._adminKey,
      JSON.stringify({
        ...admin,
      })
    );
  }

  get admin(): Optional<AdminToken> {
    const admin = localStorage.getItem(this._adminKey);
    if (!admin) {
      return null;
    }
    return JSON.parse(admin);
  }
}

export default new TokenManager();
