const mediaMaxWidthQuery = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;
const mediaMinWidthQuery = (minWidth: number) => `@media (min-width: ${minWidth}px)`;
const mediaMaxHeightQuery = (maxHeight: number) => `@media (max-height: ${maxHeight}px)`;

const media = {
  customMaxWidth: mediaMaxWidthQuery,
  customMinWidth: mediaMinWidthQuery,
  customMaxHeight: mediaMaxHeightQuery,
  phone: mediaMaxWidthQuery(576),
  tablet: mediaMaxWidthQuery(768),
  desktop: mediaMaxWidthQuery(1280),
};

export default media;
