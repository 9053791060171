// components
import { findRouteByPath } from 'components/Navigation/navRoutes';

// hooks
import useRestrictionRules from 'restrictions/roles/hooks/useRestrictionRules';

const useNavRoutes = (path: string): [boolean, string] => {
  const { hasRule } = useRestrictionRules();

  const { restrictionRule, whenRestrictedRedirectTo = '' } = findRouteByPath(path) || {};
  const isRestricted = (restrictionRule && hasRule(restrictionRule)) || false;
  const redirectTo = isRestricted ? whenRestrictedRedirectTo : '';

  return [isRestricted, redirectTo];
};

export default useNavRoutes;
