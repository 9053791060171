import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  RegisteredOnlyCustomerActionTypes,
  CustomersInteractionsActionTypes,
  CustomerProbabilityActionTypes,
} from 'ducks/merchantDucks/segmentation/actions';
import SegmentationState from 'ducks/merchantDucks/segmentation/types';

const initialState: SegmentationState = {
  interaction_cycle_period: 0,
  interaction_cycle_start: 0,
  segment_size: 0,
  total_users: 0,
  users: [],
  selectedCustomer: {
    created_at: 0,
    customer_email: '',
    customer_id: 0,
    expected_number_of_interactions: 0,
    first_interaction: 0,
    last_interaction: 0,
    monetary_first: 0,
    monetary_sum: 0,
    number_of_interactions: 0,
    probability_of_interaction: 0,
  },
  registeredOnlyCustomers: {
    collection: [],
    total: 0,
  },
  fetchCounter: 0,
  hasNoInteractions: false,
};

const interactionRequest: Reducer<SegmentationState> = (state = initialState) => ({
  ...state,
  fetchCounter: state.fetchCounter + 1,
});

const customerInteractionSuccess: Reducer<SegmentationState> = (state = initialState, { payload }) => ({
  ...state,
  selectedCustomer: payload,
  fetchCounter: state.fetchCounter - 1,
});

const customerInteractionFailure: Reducer<SegmentationState> = (state = initialState) => ({
  ...state,
  selectedCustomer: {
    ...initialState.selectedCustomer,
  },
  fetchCounter: state.fetchCounter - 1,
});

const allCustomersInteractionsSuccess: Reducer<SegmentationState> = (state = initialState, { payload }) => ({
  ...state,
  ...payload,
  fetchCounter: state.fetchCounter - 1,
});

const allCustomersInteractionFailure: Reducer<SegmentationState> = (state = initialState) => ({
  ...initialState,
  fetchCounter: state.fetchCounter - 1,
  hasNoInteractions: true,
});

const registeredOnlyCustomersRequest: Reducer<SegmentationState> = (state = initialState) => ({
  ...state,
  fetchCounter: state.fetchCounter + 1,
});

const registeredOnlyCustomersSuccess: Reducer<SegmentationState> = (state = initialState, { payload }) => {
  return {
    ...state,
    registeredOnlyCustomers: payload,
    fetchCounter: state.fetchCounter - 1,
  };
};

const registeredOnlyCustomersFailure: Reducer<SegmentationState> = (state = initialState) => {
  return {
    ...state,
    registeredOnlyCustomers: { ...initialState.registeredOnlyCustomers },
    fetchCounter: state.fetchCounter - 1,
  };
};

const actionsMap: ActionMap<SegmentationState> = {
  [CustomerProbabilityActionTypes.START]: interactionRequest,
  [CustomerProbabilityActionTypes.SUCCESS]: customerInteractionSuccess,
  [CustomerProbabilityActionTypes.ERROR]: customerInteractionFailure,
  [CustomersInteractionsActionTypes.START]: interactionRequest,
  [CustomersInteractionsActionTypes.SUCCESS]: allCustomersInteractionsSuccess,
  [CustomersInteractionsActionTypes.ERROR]: allCustomersInteractionFailure,
  [RegisteredOnlyCustomerActionTypes.START]: registeredOnlyCustomersRequest,
  [RegisteredOnlyCustomerActionTypes.SUCCESS]: registeredOnlyCustomersSuccess,
  [RegisteredOnlyCustomerActionTypes.ERROR]: registeredOnlyCustomersFailure,
};

export default reducerWithActionMap(actionsMap, initialState);
