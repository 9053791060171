import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  WebhooksActionTypes,
  LabelsActionTypes,
  SaveWebhookActionTypes,
  UpdateWebhookActionTypes,
  DeleteWebhookActionTypes,
  DeleteAllWebhooksActionTypes,
  WebhookDetailsActionTypes,
  RESET_WEBHOOK_DETAILS,
} from 'ducks/merchantDucks/webhooks/actions';
import WebhooksState from 'ducks/merchantDucks/webhooks/types';

export const initialState: WebhooksState = {
  hooks: [],
  labels: {},
  isFetching: false,
  webhookDetails: {
    hook: {
      created_at: 0,
      events: [],
      id: 0,
      merchant_id: 0,
      secret_token: '',
      updated_at: 0,
      uri: '',
    },
    labels: {},
    isSaving: false,
    isCreated: false,
    isFetching: false,
  },
  editLabels: {},
};

type WebhooksReducer = Reducer<WebhooksState>;

const loadWebhooksSuccess: WebhooksReducer = (state = initialState, { payload }) => ({
  ...state,
  hooks: payload.hooks || [],
  isFetching: false,
  editLabels: {
    ...state.editLabels,
    ...payload.labels,
  },
});

const loadWebhooks: WebhooksReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const loadWebhooksFailure: WebhooksReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const deleteAllWebhooks: WebhooksReducer = (state = initialState) => ({
  ...state,
  hooks: [],
});

const deleteWebhook: WebhooksReducer = (state = initialState, { payload: { id } }) => ({
  ...state,
  hooks: state.hooks.filter((item) => item.id !== id),
});

const fetchWebhookDetailsSuccess: WebhooksReducer = (state = initialState, { payload }) => ({
  ...state,
  webhookDetails: {
    ...state.webhookDetails,
    hook: payload,
    isFetching: false,
  },
});

const fetchWebhookDetails: WebhooksReducer = (state = initialState) => ({
  ...state,
  webhookDetails: {
    ...state.webhookDetails,
    isFetching: true,
  },
});

const fetchWebhookDetailsFailure: WebhooksReducer = (state = initialState) => ({
  ...state,
  webhookDetails: {
    ...state.webhookDetails,
    isFetching: false,
  },
});

const saveWebhook: WebhooksReducer = (state = initialState) => ({
  ...state,
  webhookDetails: {
    ...state.webhookDetails,
    isSaving: true,
    isCreated: false,
  },
});

const saveWebhookSuccess: WebhooksReducer = (state = initialState) => ({
  ...state,
  webhookDetails: {
    ...state.webhookDetails,
    isSaving: false,
    isCreated: true,
  },
});

const saveWebhookFailure: WebhooksReducer = (state = initialState) => ({
  ...state,
  webhookDetails: {
    ...state.webhookDetails,
    isSaving: false,
    isCreated: false,
  },
});

const updateWebhook: WebhooksReducer = (state = initialState) => ({
  ...state,
  webhookDetails: {
    ...state.webhookDetails,
    isSaving: true,
  },
});

const updateWebhookSuccess: WebhooksReducer = (state = initialState) => ({
  ...state,
  webhookDetails: {
    ...state.webhookDetails,
    isCreated: true,
    isSaving: false,
  },
});

const updateWebhookFailure: WebhooksReducer = (state = initialState) => ({
  ...state,
  webhookDetails: {
    ...state.webhookDetails,
    isSaving: false,
  },
});

const resetWebhookDetails: WebhooksReducer = (state = initialState) => ({
  ...state,
  webhookDetails: initialState.webhookDetails,
});

const loadLabels: WebhooksReducer = (state = initialState, { payload: { labels } }) => ({
  ...state,
  editLabels: {
    ...state.editLabels,
    ...labels,
  },
});

const actionsMap: ActionMap<WebhooksState> = {
  [WebhooksActionTypes.SUCCESS]: loadWebhooksSuccess,
  [WebhooksActionTypes.START]: loadWebhooks,
  [WebhooksActionTypes.FAILURE]: loadWebhooksFailure,
  [DeleteAllWebhooksActionTypes.SUCCESS]: deleteAllWebhooks,
  [DeleteWebhookActionTypes.SUCCESS]: deleteWebhook,
  [WebhookDetailsActionTypes.SUCCESS]: fetchWebhookDetailsSuccess,
  [WebhookDetailsActionTypes.START]: fetchWebhookDetails,
  [WebhookDetailsActionTypes.FAILURE]: fetchWebhookDetailsFailure,
  [SaveWebhookActionTypes.START]: saveWebhook,
  [SaveWebhookActionTypes.SUCCESS]: saveWebhookSuccess,
  [SaveWebhookActionTypes.FAILURE]: saveWebhookFailure,
  [UpdateWebhookActionTypes.START]: updateWebhook,
  [UpdateWebhookActionTypes.SUCCESS]: updateWebhookSuccess,
  [UpdateWebhookActionTypes.FAILURE]: updateWebhookFailure,
  [RESET_WEBHOOK_DETAILS]: resetWebhookDetails,
  [LabelsActionTypes.SUCCESS]: loadLabels,
};

export default reducerWithActionMap(actionsMap, initialState);
