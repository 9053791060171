import React, { lazy } from 'react';
import { Switch, Route } from 'react-router';

// components
const PaymentsDetails = lazy(() => import('pages/MerchantPages/Transactions/Payments/components/PaymentsDetails'));
const AdminPayments = lazy(() => import('pages/AdminPages/AdminPayments/AdminPayments'));

const SubPayments = () => (
  <Switch>
    <Route path="/admin/payments/:trxToken">
      <PaymentsDetails />
    </Route>
    <Route key="/admin/payments" exact path="/admin/payments">
      <AdminPayments />
    </Route>
  </Switch>
);

export default SubPayments;
