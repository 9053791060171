import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Moment } from 'moment';

import http, { ContentType } from 'utils/http';
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';
import { API } from 'constants/api';
import RootState from 'ducks/RootState';
import { ApiAction } from 'ducks/types';

// Audience table data
export enum LoadAudienceActionTypes {
  START = 'AUDIENCE_REQUEST',
  SUCCESS = 'AUDIENCE_SUCCESS',
  ERROR = 'AUDIENCE_FAILURE',
}

export type LoadAudienceQueryParams = Partial<{
  page: number;
  size: number;
  active: boolean;
  search: any;
}>;

type LoadAudienceAction = Action<LoadAudienceActionTypes>;

const defaultAudienceParams = { page: 0, size: 15, active: true, search: [] };

export const loadAudience = ({
  page = 0,
  size = 15,
  active = true,
  search = [],
}: LoadAudienceQueryParams = defaultAudienceParams): ThunkAction<
  Promise<any>,
  RootState,
  any,
  LoadAudienceAction
> => async (dispatch) => {
  const endpoint = API.FETCH_AUDIENCE;
  const params = { page, size, active, search };

  try {
    dispatch({ type: LoadAudienceActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({ type: LoadAudienceActionTypes.SUCCESS, payload: { ...response.data, search } });
  } catch (_) {
    dispatch({ type: LoadAudienceActionTypes.ERROR });
  }
};

// Customer details data
export enum CustomerDetailsActionTypes {
  START = 'CUSTOMER_DETAILS_REQUEST',
  SUCCESS = 'CUSTOMER_DETAILS_SUCCESS',
  ERROR = 'CUSTOMER_DETAILS_FAILURE',
}

export const loadCustomerDetails = (id: number): ApiAction<CustomerDetailsActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_CUSTOMER_DETAILS;

  try {
    dispatch({ type: CustomerDetailsActionTypes.START });
    const response = await http.authenticated().get(endpoint, { pathVariables: { id } });
    dispatch({ type: CustomerDetailsActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    const { response } = e;
    dispatch({ type: CustomerDetailsActionTypes.ERROR, payload: { ...response.data } });
  }
};

// Customer activity data
export enum CustomerActivityActionTypes {
  START = 'FETCH_CUSTOMER_ACTIVITY_REQUEST',
  SUCCESS = 'FETCH_CUSTOMER_ACTIVITY_SUCCESS',
  ERROR = 'FETCH_CUSTOMER_ACTIVITY_FAILURE',
}

export const fetchCustomerActivity = (
  id: number,
  merchantId?: number
): ApiAction<CustomerActivityActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_CUSTOMER_ACTIVITY;

  try {
    dispatch({ type: CustomerActivityActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      params: {
        merchant_id: merchantId,
      },
      pathVariables: { id },
    });
    dispatch({ type: CustomerActivityActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    const { response } = e;
    dispatch({ type: CustomerActivityActionTypes.ERROR, payload: { ...response.data } });
  }
};

// Customer lifetime values data
export enum CustomerLifetimeValuesActionTypes {
  START = 'FETCH_CUSTOMER_LIFETIME_VALUE_REQUEST',
  SUCCESS = 'FETCH_CUSTOMER_LIFETIME_VALUE_SUCCESS',
  ERROR = 'FETCH_CUSTOMER_LIFETIME_VALUE_FAILURE',
}

export const fetchCustomerLifetimeValues = (
  id: number,
  merchantId?: number
): ApiAction<CustomerLifetimeValuesActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_CUSTOMER_LIFETIME_VALUES;

  try {
    dispatch({ type: CustomerLifetimeValuesActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      params: {
        merchant_id: merchantId,
      },
      pathVariables: { id },
    });
    dispatch({ type: CustomerLifetimeValuesActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    const { response } = e;
    dispatch({ type: CustomerLifetimeValuesActionTypes.ERROR, payload: { ...response.data } });
  }
};

// Customer Payment History
export type CustomerPaymentHistoryParams = Partial<{
  id: number;
  page: number;
  limit: number;
}>;

export enum CustomerPaymentsActionTypes {
  START = 'CUSTOMER_PAYMENTS_REQUEST',
  SUCCESS = 'CUSTOMER_PAYMENTS_SUCCESS',
  ERROR = 'CUSTOMER_PAYMENTS_FAILURE',
}

export const loadCustomerPaymentHistory = (
  params?: CustomerPaymentHistoryParams
): ApiAction<CustomerPaymentsActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_CUSTOMER_PAYMENTS;

  try {
    dispatch({ type: CustomerPaymentsActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({ type: CustomerPaymentsActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: CustomerPaymentsActionTypes.ERROR });
  }
};

// Grant Access
export enum GrantAccessActionTypes {
  START = 'GRANT_ACCESS_REQUEST',
  SUCCESS = 'GRANT_ACCESS_SUCCESS',
  ERROR = 'GRANT_ACCESS_FAILURE',
}

type GrantAccessTypes = GrantAccessActionTypes | NotificationActionTypes;

export const grantAccess = (
  consumerId: number,
  expiresAt: number | string,
  itemId: number
): ApiAction<GrantAccessTypes> => async (dispatch) => {
  const endpoint = API.GRANT_ACCESS;
  const data = {
    consumer_id: consumerId,
    expires_at: expiresAt,
    item_id: itemId,
  };

  try {
    dispatch({ type: GrantAccessActionTypes.START });
    const response = await http.authenticated().post(endpoint, { data, contentType: ContentType.URLENCODED });
    dispatch({ type: GrantAccessActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(notifRequested({ type: 'success', title: 'SUCCESS', content: 'Access successfully granted.' }));
  } catch (_) {
    dispatch({ type: GrantAccessActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: 'The user already has an active access for this asset.',
      })
    );
  }
};

// LOAD CUSTOMER ACCESS data
export enum CustomerAccessActionTypes {
  START = 'CUSTOMER_ACCESS_REQUEST',
  SUCCESS = 'CUSTOMER_ACCESS_SUCCESS',
  ERROR = 'CUSTOMER_ACCESS_FAILURE',
}

export interface LoadCustomerAccessQueryParams {
  status?: string;
  customerID?: number;
  type?: string | null;
  page?: number;
  size?: number;
  tags?: Array<any>;
}

export const loadCustomerAccess = ({
  status = 'active',
  page = 0,
  size = 15,
  tags = [],
  type,
  customerID,
}: LoadCustomerAccessQueryParams): ApiAction<CustomerAccessActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_ACCESSES;
  const params = { status, page, size, tags, type, customerID };

  try {
    dispatch({ type: CustomerAccessActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({ type: CustomerAccessActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: CustomerAccessActionTypes.ERROR });
  }
};

// Update metadata
export enum UpdateMetadataActionTypes {
  START = 'UPDATE_METADATA_REQUEST',
  SUCCESS = 'UPDATE_METADATA_SUCCESS',
  ERROR = 'UPDATE_METADATA_FAILURE',
}

export interface CustomerMetadata {
  id: number;
  metadata?: any;
  full_name?: string;
}

type UpdateMetadataTypes = UpdateMetadataActionTypes | NotificationActionTypes;

export const updateCustomerMetadata = ({
  id,
  metadata,
  full_name,
}: CustomerMetadata): ApiAction<UpdateMetadataTypes> => async (dispatch) => {
  const endpoint = API.UPDATE_METADATA;
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  let data: any = {
    id,
  };

  if (metadata) {
    data = { ...data, metadata };
  } else {
    data = { ...data, full_name };
  }
  const successData = { metadata, full_name };

  try {
    dispatch({ type: UpdateMetadataActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      headers,
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: UpdateMetadataActionTypes.SUCCESS, payload: { ...successData, ...response.data } });
    dispatch(notifRequested({ type: 'success', title: 'SUCCESS', content: 'Metadata successfully updated.' }));
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: UpdateMetadataActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Reset Password
export enum ResetPasswordActionTypes {
  START = 'RESET_PASSWORD_REQUEST',
  SUCCESS = 'RESET_PASSWORD_SUCCESS',
  ERROR = 'RESET_PASSWORD_FAILURE',
}

type ResetPasswordTypes = ResetPasswordActionTypes | NotificationActionTypes;

export const resetAccountPassword = (id: number, brandingId?: string): ApiAction<ResetPasswordTypes> => async (
  dispatch
) => {
  const endpoint = API.RESET_PASSWORD;
  const data = brandingId
    ? {
        branding_id: brandingId,
      }
    : null;
  try {
    dispatch({ type: ResetPasswordActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      contentType: ContentType.URLENCODED,
      pathVariables: {
        id,
      },
      data,
    });
    dispatch({ type: ResetPasswordActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: "Password was successfully reset and an email was sent to the user's email address",
      })
    );
  } catch (e) {
    dispatch({ type: ResetPasswordActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: e.response.data.message,
      })
    );
  }
};

// Deactivate account
export enum DeactivateAccountActionTypes {
  START = 'DEACTIVATE_ACCOUNT_REQUEST',
  SUCCESS = 'DEACTIVATE_ACCOUNT_SUCCESS',
  ERROR = 'DEACTIVATE_ACCOUNT_FAILURE',
}

type DeactivateAccountTypes = DeactivateAccountActionTypes | NotificationActionTypes;

export const deactivateAccount = (customerID: number): ApiAction<DeactivateAccountTypes> => async (dispatch) => {
  const endpoint = API.DEACTIVATE_ACCOUNT;
  const params = { customerID };

  try {
    dispatch({ type: DeactivateAccountActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      contentType: ContentType.URLENCODED,
      params,
    });
    dispatch({
      type: DeactivateAccountActionTypes.SUCCESS,
      payload: { ...response.data, customerID },
    });
    dispatch(loadAudience());
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'The account was successfully deactivated.',
      })
    );
  } catch (e) {
    dispatch({ type: DeactivateAccountActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: e.response.data.message,
      })
    );
  }
};

// Extend access
export enum ExtendAccessActionTypes {
  START = 'EXTEND_ACCESS_REQUEST',
  SUCCESS = 'EXTEND_ACCESS_SUCCESS',
  ERROR = 'EXTEND_ACCESS_FAILURE',
}

export interface ExtendAccessQueryParams {
  consumerId: number;
  itemId: number;
  expiresAt: string | Moment;
  type: string;
  email: string;
}

type ExtendAccessTypes = ExtendAccessActionTypes | NotificationActionTypes;

export const extendAccess = ({
  consumerId,
  itemId,
  expiresAt,
  type,
  email,
}: ExtendAccessQueryParams): ApiAction<ExtendAccessTypes> => async (dispatch) => {
  const endpoint = API.EXTEND_ACCESS;
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  const data = {
    consumer_id: consumerId,
    item_id: itemId,
    expires_at: expiresAt,
  };

  try {
    dispatch({ type: ExtendAccessActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      headers,
      contentType: ContentType.URLENCODED,
      data,
    });
    dispatch({
      type: ExtendAccessActionTypes.SUCCESS,
      payload: { ...response.data, type, email },
    });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'The access was successfully extended.',
      })
    );
  } catch (e) {
    dispatch({ type: ExtendAccessActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: e.response.data.message,
      })
    );
  }
};

// Revoke access
export enum RevokeAccessActionTypes {
  START = 'REVOKE_ACCESS_REQUEST',
  SUCCESS = 'REVOKE_ACCESS_SUCCESS',
  ERROR = 'REVOKE_ACCESS_FAILURE',
}

type RevokeAccessTypes = RevokeAccessActionTypes | NotificationActionTypes;

export const revokeAccess = (
  itemId: number,
  consumerId: number,
  itemAccessId: number
): ApiAction<RevokeAccessTypes> => async (dispatch) => {
  const endpoint = API.REVOKE_ACCESS;
  const data = { consumer_id: consumerId };
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  const successData = { itemAccessId };

  try {
    dispatch({ type: RevokeAccessActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: { id: itemId },
      headers,
      contentType: ContentType.URLENCODED,
      data,
    });
    dispatch({ type: RevokeAccessActionTypes.SUCCESS, payload: { ...response.data, successData } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Access successfully revoked.',
      })
    );
  } catch (e) {
    dispatch({ type: RevokeAccessActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: e.response.data.message,
      })
    );
  }
};

// Customers with purchase
export enum PurchasedCustomersActionTypes {
  START = 'UNIQUE_CUSTOMERS_REQUEST',
  SUCCESS = 'UNIQUE_CUSTOMERS_SUCCESS',
  ERROR = 'UNIQUE_CUSTOMERS_FAILURE',
}

type PurchasedCustomersTypes = PurchasedCustomersActionTypes;

export const loadUniqueCustomers = (): ApiAction<PurchasedCustomersTypes> => async (dispatch) => {
  const endpoint = API.UNIQUE_CUSTOMERS;
  try {
    dispatch({ type: PurchasedCustomersActionTypes.START });
    const response = await http.authenticated().get(endpoint, {});
    dispatch({ type: PurchasedCustomersActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    dispatch({ type: PurchasedCustomersActionTypes.ERROR });
  }
};

// Reactivate account
export enum ReactivateAccountActionTypes {
  START = 'REACTIVATE_ACCOUNT_REQUEST',
  SUCCESS = 'REACTIVATE_ACCOUNT_SUCCESS',
  ERROR = 'REACTIVATE_ACCOUNT_FAILURE',
}

type ReactivateAccountAction = ReactivateAccountActionTypes | NotificationActionTypes;

export const reactivateAccount = (id: number): ApiAction<ReactivateAccountAction> => async (dispatch) => {
  const endpoint = API.REACTIVATE_ACCOUNT;

  try {
    dispatch({ type: ReactivateAccountActionTypes.START });
    const response = await http
      .authenticated()
      .put(endpoint, { pathVariables: { id }, contentType: ContentType.URLENCODED });
    dispatch({ type: ReactivateAccountActionTypes.SUCCESS, payload: { ...response.data, id } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Account successfully reactivated.',
      })
    );
  } catch (e) {
    dispatch({ type: ReactivateAccountActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: e.response.data.message,
      })
    );
  }
};

// Export account data
export enum ExportDataActionTypes {
  START = 'EXPORT_DATA_REQUEST',
  SUCCESS = 'EXPORT_DATA_SUCCESS',
  ERROR = 'EXPORT_DATA_FAILURE',
}

type ExportDataTypes = ExportDataActionTypes | NotificationActionTypes;

export interface ExportAccDataParams {
  customerID: number;
  sendEmailToCustomer: boolean;
  password: string;
}

export const exportAccountData = (params: ExportAccDataParams): ApiAction<ExportDataTypes> => async (dispatch) => {
  const endpoint = API.EXPORT_ACCOUNT_DATA;
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

  try {
    dispatch({ type: ExportDataActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      headers,
      contentType: ContentType.URLENCODED,
      data: params,
    });
    dispatch({ type: ExportDataActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'The account data is being exported. It will be sent to your merchant email address.',
      })
    );
  } catch (e) {
    dispatch({ type: ExportDataActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: e.response.data.message,
      })
    );
    throw e;
  }
};

export enum FetchRegistrationsActionTypes {
  START = 'FETCH_REGISTRATIONS_REQUEST',
  SUCCESS = 'FETCH_REGISTRATIONS_SUCCESS',
  ERROR = 'FETCH_REGISTRATIONS_FAILURE',
}
export const fetchRegistrations = (): ApiAction<FetchRegistrationsActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_REGISTRATIONS;
  try {
    dispatch({ type: FetchRegistrationsActionTypes.START });
    const response = await http.authenticated().get(endpoint);
    dispatch({ type: FetchRegistrationsActionTypes.SUCCESS, payload: response.data });
  } catch (e) {
    dispatch({ type: FetchRegistrationsActionTypes.ERROR });
  }
};

export enum FetchLoginsActionTypes {
  START = 'FETCH_LOGINS_REQUEST',
  SUCCESS = 'FETCH_LOGINS_SUCCESS',
  ERROR = 'FETCH_LOGINS_FAILURE',
}
export const fetchLogins = (): ApiAction<FetchLoginsActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_LOGINS;
  try {
    dispatch({ type: FetchLoginsActionTypes.START });
    const response = await http.authenticated().get(endpoint);
    dispatch({ type: FetchLoginsActionTypes.SUCCESS, payload: response.data });
  } catch (e) {
    dispatch({ type: FetchLoginsActionTypes.ERROR });
  }
};

// Erase account
export enum EraseAccountActionTypes {
  START = 'ERASE_ACCOUNT_REQUEST',
  SUCCESS = 'ERASE_ACCOUNT_SUCCESS',
  ERROR = 'ERASE_ACCOUNT_FAILURE',
}

type EraseAccountTypes = EraseAccountActionTypes | NotificationActionTypes;

export const eraseAccount = (password: string, customerId: number): ApiAction<EraseAccountTypes> => async (
  dispatch
) => {
  const endpoint = API.ERASE_ACCOUNT;
  const data = {
    password,
    customer_id: customerId,
  };

  try {
    dispatch({ type: EraseAccountActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      contentType: ContentType.URLENCODED,
      data,
    });
    dispatch({
      type: EraseAccountActionTypes.SUCCESS,
      payload: { ...response.data, customerId },
    });
    dispatch(loadAudience());
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'The account is being erased. You will receive a confirmation on your merchant email address.',
      })
    );
  } catch (e) {
    dispatch({ type: EraseAccountActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: e.response.data.message,
      })
    );
    throw e;
  }
};

export const RESET_CUSTOMER_DETAILS = 'RESET_CUSTOMER_DETAILS';

export const resetCustomerDetails = () => ({
  type: RESET_CUSTOMER_DETAILS,
});

export const RESET_DETAILS_DATA = 'RESET_DETAILS_DATA';

export const resetDetailsData = () => ({
  type: RESET_DETAILS_DATA,
});
