import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  PurchasedCustomersActionTypes,
  LoadAudienceActionTypes,
  CustomerDetailsActionTypes,
  CustomerPaymentsActionTypes,
  GrantAccessActionTypes,
  CustomerAccessActionTypes,
  CustomerActivityActionTypes,
  CustomerLifetimeValuesActionTypes,
  UpdateMetadataActionTypes,
  RevokeAccessActionTypes,
  ExtendAccessActionTypes,
  ReactivateAccountActionTypes,
  DeactivateAccountActionTypes,
  RESET_CUSTOMER_DETAILS,
  RESET_DETAILS_DATA,
  EraseAccountActionTypes,
  FetchRegistrationsActionTypes,
  FetchLoginsActionTypes,
} from 'ducks/merchantDucks/audience/actions';
import { ApplicationsActionTypes } from 'ducks/merchantDucks/oauth/actions';
import AudienceState from 'ducks/merchantDucks/audience/types';

export const initialState: AudienceState = {
  collection: [],
  total: 0,
  page: 1,
  limit: 15,
  offset: 0,
  isFetching: false,
  firstTotal: 0,
  customerPayments: {
    total: 0,
    page: 1,
    limit: 15,
    offset: 0,
    collection: [],
  },
  customerDetails: {
    merchant_id: 0,
    merchant_uuid: '',
    id: 0,
    metadata: {},
    email: '',
    full_name: '',
    referrer: '',
    roles: [],
    created_at: 0,
    uuid: '',
    isFetching: false,
    data: '',
    geodata: {
      continent: '',
      country: '',
      country_name: '',
    },
    analytics: {
      last_login: {
        timestamp: 0,
        merchant_id: 0,
        merchant_email: '',
        account_id: 0,
        name: '',
        consumer_email: '',
        account_role: [],
        geo_data: {
          ipAddress: '',
          continent: '',
          country: '',
          country_name: '',
        },
        date: '',
        device: {
          model: '',
          os: '',
          type: '',
        },
        clinet_id: '',
      },
      content_requests_log: {
        item_id: 0,
        item_title: '',
        created_at: {
          date: '',
          timezone: '',
          timezone_type: 0,
        },
        updated_at: {
          date: '',
          timezone: '',
          timezone_type: 0,
        },
        item_type: '',
        access_control_type: null,
        consumer_id: 0,
        consumer_email: '',
        metadata: {},
        ip_address: '',
        country: '',
        country_iso: '',
        state: '',
        city: '',
        device: {
          model: '',
          os: '',
          type: '',
        },
        date: '',
        referrer: '',
      },
      total_assets_bought: 0,
      total_transactions: 0,
      money_spent: [],
      first_purchase: {
        id: 0,
        perent_id: 0,
        merchant_id: 0,
        consumer_id: 0,
        account_payment_provider_id: 0,
        transaction_id: '',
        payment_tool_token: '',
        payment_tool_info: '',
        payment_method_name: '',
        action_type: '',
        item_access_id: 0,
        item_id: 0,
        item_type: '',
        item_title: '',
        charged_amount: 0,
        currency_iso: '',
        issued_by: 0,
        exchange_rate: 0.0,
        settlement_currency: '',
        note: '',
        voucher: '',
        revenue_account: '',
        branding_id: 0,
        created_at: '',
      },
      last_purchase: {
        id: 0,
        perent_id: 0,
        merchant_id: 0,
        consumer_id: 0,
        account_payment_provider_id: 0,
        transaction_id: '',
        payment_tool_token: '',
        payment_tool_info: '',
        payment_method_name: '',
        action_type: '',
        item_access_id: 0,
        item_id: 0,
        item_type: '',
        item_title: '',
        charged_amount: 0,
        currency_iso: '',
        issued_by: 0,
        exchange_rate: 0.0,
        settlement_currency: '',
        note: '',
        voucher: '',
        revenue_account: '',
        branding_id: 0,
        created_at: '',
      },
    },
  },
  customerAccess: {
    collection: [],
    total: 0,
    page: 0,
    limit: 15,
    isFetching: false,
  },
  totalUniqueCustomers: 0,
  userApplications: {},
  registrations: {
    total: 0,
    per_device: [],
    per_register_source: [],
  },
  logins: {
    total: 0,
    per_device: [],
    per_country_code: [],
  },
};

type AudienceReducer = Reducer<AudienceState>;

const amountOfUniqueCustomersSuccess: AudienceReducer = (state = initialState, { payload }) => ({
  ...state,
  totalUniqueCustomers: Number(payload.total_customers),
  isFetching: false,
});

const promptAmountOfUniqueCustomers: AudienceReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const amountOfUniqueCustomersFailure: AudienceReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const setAudienceSuccess: AudienceReducer = (state = initialState, { payload }) => {
  if (payload.search.length === 0) {
    return {
      ...state,
      ...payload,
      isFetching: false,
      firstTotal: payload.total,
    };
  }
  return {
    ...state,
    ...payload,
    isFetching: false,
  };
};

const setAudienceFailure: AudienceReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const promptSetAudience: AudienceReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const setCustomerPaymentsSuccess: AudienceReducer = (state = initialState, { payload }) => ({
  ...state,
  customerPayments: {
    ...state.customerPayments,
    collection: [...payload.collection],
    limit: payload.limit,
    offset: payload.offset,
    page: payload.page,
    total: payload.total,
  },
});

const setCustomerDetailsSuccess: AudienceReducer = (state = initialState, { payload }) => ({
  ...state,
  customerDetails: {
    ...state.customerDetails,
    ...payload.account,
    isFetching: false,
    isFetched: true,
    isErasedUser: false,
  },
});

const promptSetCustomerDetails: AudienceReducer = (state = initialState) => ({
  ...state,
  customerDetails: {
    ...state.customerDetails,
    isFetching: true,
    isErasedUser: false,
  },
});

const setCustomerDetailsFailure: AudienceReducer = (state = initialState, { payload }) => {
  const isErasedUser = payload.message === 'Requested account does not exist';
  return {
    ...state,
    customerDetails: {
      ...state.customerDetails,
      isFetching: false,
      data: payload.message,
      code: payload.code,
      isFetched: true,
      isErasedUser,
    },
  };
};

const setCustomerActivitySuccess: AudienceReducer = (state = initialState, { payload }) => ({
  ...state,
  customerDetails: {
    ...state.customerDetails,
    analytics: {
      ...state.customerDetails.analytics,
      ...payload,
    },
  },
});

const promptSetCustomerActivity: AudienceReducer = (state = initialState) => ({
  ...state,
  customerDetails: {
    ...state.customerDetails,
  },
});

const setCustomerActivityFailure: AudienceReducer = (state = initialState) => ({
  ...state,
  customerDetails: {
    ...state.customerDetails,
  },
});

const setCustomerLifetimeValuesSuccess: AudienceReducer = (state = initialState, { payload }) => ({
  ...state,
  customerDetails: {
    ...state.customerDetails,
    analytics: {
      ...state.customerDetails.analytics,
      ...payload,
    },
  },
});

const promptSetCustomerLifetimeValues: AudienceReducer = (state = initialState) => ({
  ...state,
  customerDetails: {
    ...state.customerDetails,
  },
});

const setCustomerLifetimeValuesFailure: AudienceReducer = (state = initialState) => ({
  ...state,
  customerDetails: {
    ...state.customerDetails,
  },
});

const updateMetadataSuccess: AudienceReducer = (state = initialState, { payload }) => ({
  ...state,
  customerDetails: {
    ...state.customerDetails,
    metadata: payload.metadata,
    full_name: payload.full_name,
  },
});

const setCustomerAccessSuccess: AudienceReducer = (state = initialState, { payload }) => ({
  ...state,
  customerAccess: {
    ...state.customerAccess,
    ...payload,
    isFetching: false,
  },
});

const promptSetCustomerAccess: AudienceReducer = (state = initialState) => ({
  ...state,
  customerAccess: {
    ...state.customerAccess,
    isFetching: true,
  },
});

const setCustomerAccessFailure: AudienceReducer = (state = initialState) => ({
  ...state,
  customerAccess: {
    ...state.customerAccess,
    isFetching: false,
  },
});

const grantAccessSuccess: AudienceReducer = (state = initialState, { payload }) => {
  const { collection, total } = state.customerAccess;

  return {
    ...state,
    customerAccess: {
      ...state.customerAccess,
      collection: [
        ...collection,
        {
          created_at: payload.created_at,
          customer_id: payload.customer_id,
          expires_at: payload.expires_at,
          item_access_id: payload.id,
          item_id: payload.item.id,
          item_title: payload.item.title,
          merchant_id: payload.account_id,
          type: 'granted',
        },
      ],
      total: total + 1,
    },
  };
};

const revokeAccessSuccess: AudienceReducer = (state = initialState, { payload }) => {
  const { collection, total } = state.customerAccess;

  return {
    ...state,
    customerAccess: {
      ...state.customerAccess,
      collection: collection.filter((item) => item.item_access_id !== payload.successData.itemAccessId),
      total: total - 1,
    },
  };
};

const extendCustomerAccessSuccess: AudienceReducer = (state = initialState, { payload }) => {
  const { collection } = state.customerAccess;

  const newCollection = collection.map((item, index) => {
    return index === collection.findIndex((customerAccess) => customerAccess.item_access_id === payload.id)
      ? {
          created_at: payload.created_at,
          customer_id: payload.customer_id,
          expires_at: payload.expires_at,
          item_access_id: payload.id,
          item_id: payload.item.id,
          item_title: payload.item.title,
          merchant_id: payload.account_id,
          type: payload.type,
        }
      : item;
  });

  return {
    ...state,
    customerAccess: {
      ...state.customerAccess,
      collection: [...newCollection],
    },
  };
};

const resetCustomerDetails: AudienceReducer = (state = initialState) => ({
  ...state,
  customerDetails: initialState.customerDetails,
});

const resetDetailsData: AudienceReducer = (state = initialState) => ({
  ...state,
  customerDetails: {
    ...state.customerDetails,
    data: '',
    code: 0,
  },
});

const setApplicationsSuccess: AudienceReducer = (state = initialState, { payload }) => ({
  ...state,
  userApplications: payload,
});

const reactivateAccountSuccess: AudienceReducer = (state = initialState, { payload }) => {
  const { collection } = state;
  return {
    ...state,
    collection: collection.filter((item) => item.id !== payload.id),
  };
};

const deactivateAccountSuccess: AudienceReducer = (state = initialState, { payload }) => {
  const { collection } = state;
  return {
    ...state,
    collection: collection.filter((item) => item.id !== payload.customerID),
  };
};

const eraseAccountSuccess: AudienceReducer = (state = initialState, { payload }) => {
  const { collection } = state;
  return { ...state, collection: collection.filter((item) => item.id !== payload.customerId) };
};

const setRegistrationDevices: AudienceReducer = (state = initialState, { payload }) => ({
  ...state,
  registrations: { ...payload },
});

const setLoginDevices: AudienceReducer = (state = initialState, { payload }) => ({
  ...state,
  logins: { ...payload },
});

const actionsMap: ActionMap<AudienceState> = {
  [PurchasedCustomersActionTypes.SUCCESS]: amountOfUniqueCustomersSuccess,
  [PurchasedCustomersActionTypes.START]: promptAmountOfUniqueCustomers,
  [PurchasedCustomersActionTypes.ERROR]: amountOfUniqueCustomersFailure,
  [LoadAudienceActionTypes.SUCCESS]: setAudienceSuccess,
  [LoadAudienceActionTypes.ERROR]: setAudienceFailure,
  [LoadAudienceActionTypes.START]: promptSetAudience,
  [CustomerPaymentsActionTypes.SUCCESS]: setCustomerPaymentsSuccess,
  [CustomerDetailsActionTypes.SUCCESS]: setCustomerDetailsSuccess,
  [CustomerDetailsActionTypes.START]: promptSetCustomerDetails,
  [CustomerDetailsActionTypes.ERROR]: setCustomerDetailsFailure,
  [CustomerActivityActionTypes.SUCCESS]: setCustomerActivitySuccess,
  [CustomerActivityActionTypes.START]: promptSetCustomerActivity,
  [CustomerActivityActionTypes.ERROR]: setCustomerActivityFailure,
  [CustomerLifetimeValuesActionTypes.SUCCESS]: setCustomerLifetimeValuesSuccess,
  [CustomerLifetimeValuesActionTypes.START]: promptSetCustomerLifetimeValues,
  [CustomerLifetimeValuesActionTypes.ERROR]: setCustomerLifetimeValuesFailure,
  [UpdateMetadataActionTypes.SUCCESS]: updateMetadataSuccess,
  [CustomerAccessActionTypes.SUCCESS]: setCustomerAccessSuccess,
  [CustomerAccessActionTypes.START]: promptSetCustomerAccess,
  [CustomerAccessActionTypes.ERROR]: setCustomerAccessFailure,
  [GrantAccessActionTypes.SUCCESS]: grantAccessSuccess,
  [RevokeAccessActionTypes.SUCCESS]: revokeAccessSuccess,
  [ExtendAccessActionTypes.SUCCESS]: extendCustomerAccessSuccess,
  [RESET_CUSTOMER_DETAILS]: resetCustomerDetails,
  [RESET_DETAILS_DATA]: resetDetailsData,
  [ApplicationsActionTypes.SUCCESS]: setApplicationsSuccess,
  [ReactivateAccountActionTypes.SUCCESS]: reactivateAccountSuccess,
  [DeactivateAccountActionTypes.SUCCESS]: deactivateAccountSuccess,
  [EraseAccountActionTypes.SUCCESS]: eraseAccountSuccess,
  [ApplicationsActionTypes.SUCCESS]: setApplicationsSuccess,
  [FetchRegistrationsActionTypes.SUCCESS]: setRegistrationDevices,
  [FetchLoginsActionTypes.SUCCESS]: setLoginDevices,
};

export default reducerWithActionMap(actionsMap, initialState);
