import React, { PureComponent, ReactNode } from 'react';
import { bindActionCreators } from 'redux';
import { connect, MapStateToProps, MapDispatchToPropsFunction } from 'react-redux';
import { History } from 'history';
import styled from 'styled-components';

// Actions
import { registerUser as registerUserAction } from 'ducks/merchantDucks/user/actions';

// Types
import { Optional } from 'utils/types';
import RootState from 'ducks/RootState';
import { AuthState } from 'ducks/merchantDucks/user/types';

// Components
import RegisterForm, { RegisterUserFormValues } from 'pages/MerchantPages/Authentication/Register/forms/Register';
import Loader from 'components/Loader';
import Header from 'pages/MerchantPages/Authentication/components/Header';
import { FormMargin, CenteredContainer } from 'pages/MerchantPages/Authentication/components/sharedComponents';
import RegisterInformation from './RegisterInformation';

interface OwnProps {
  auth: {
    isRegistered: boolean;
    isRegistering: boolean;
    isAuthenticated: boolean;
    isActive: boolean;
  };
  history: History;
}

interface DispatchProps {
  registerUser: (data: RegisterUserFormValues, captchaToken: Optional<string>) => void;
}

interface StateProps {
  auth: AuthState;
}

interface RegisterContainerProps {
  isRegistering: boolean;
}

const RegisterContainer = styled.div<RegisterContainerProps>`
  display: ${({ isRegistering }) => (isRegistering ? 'none' : 'contents')};
`;

export type Props = OwnProps & StateProps & DispatchProps;

export class Register extends PureComponent<Props> {
  componentDidMount() {
    this.redirectIfRegistered(this.props);
  }

  componentDidUpdate() {
    this.redirectIfRegistered(this.props);
  }

  redirectIfRegistered = (props: Props) => {
    const {
      auth: { isAuthenticated, isRegistered, isActive, isAdmin },
      history,
    } = props;
    if (isAuthenticated && !isAdmin) {
      history.replace('/dashboard');
    }

    if (isRegistered) {
      if (isActive) {
        history.replace('/login');
      } else {
        history.replace('/activate');
      }
    }
  };

  onRegisterUser = (data: RegisterUserFormValues, captchaToken: Optional<string>) => {
    const { registerUser } = this.props;

    registerUser(data, captchaToken);
  };

  render(): ReactNode {
    const {
      auth: { isRegistering },
    } = this.props;

    return (
      <RegisterContainer isRegistering={false}>
        {isRegistering && <Loader />}
        <RegisterContainer isRegistering={isRegistering}>
          <Header />
          <CenteredContainer flexDirection="row">
            <FormMargin maxWidth="35rem">
              <RegisterInformation />
            </FormMargin>
            <FormMargin>
              <RegisterForm onSubmit={this.onRegisterUser} {...this.props} />
            </FormMargin>
          </CenteredContainer>
        </RegisterContainer>
      </RegisterContainer>
    );
  }
}

export const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, OwnProps> = (dispatch) =>
  bindActionCreators(
    {
      registerUser: registerUserAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Register);
