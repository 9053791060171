import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Loader, AnalyticsPage, AnalyticsComponent } from '@inplayer-org/inplayer-ui';

// actions
import { loadExternalAccount, saveExternalAccount } from 'ducks/merchantDucks/externalAccount/actions';

// selectors
import { getExternalAccount } from 'ducks/merchantDucks/externalAccount/selectors';

// styled components
import { BlueTypography } from 'components/SharedStyledElements';
import { StyledTypography } from 'pages/MerchantPages/Settings/Integrations/ThirdPartyIntegrations/ThirdPartyIntegration';
import { MarginTypography } from 'pages/MerchantPages/Settings/Integrations/InAppIntegrations/components/InAppIntegrationsForm';

// components
import MainHolder from 'components/MainHolder';
import SubheaderWithBorder from 'components/SubheaderWithBorder';
import ContentHolder from 'components/ContentHolder';

// local components
import InputsForm from './InputsForm';

export interface ThirdPartyKeys {
  [key: string]: any;
}

const FormContainer = styled.div`
  width: 50%;
  margin: 0 2.5rem;

  @media screen and (max-width: 871px) {
    width: 86%;
  }
`;

const DescriptionContainer = styled.div`
  margin: 2.5rem 2.5rem 0;
`;

const ThirdPartyIntegrationsForm: React.FC = () => {
  const dispatch = useDispatch();
  const externalAccount = useSelector(getExternalAccount);
  const { type: thirdPartyName } = useParams<{ type: string }>();
  const thirdPartyKeys = externalAccount.keys[thirdPartyName]?.keys || {};
  const { isFetching, isSaving, saveOvpError } = externalAccount;

  useEffect(() => {
    if (!isFetching && !saveOvpError) {
      dispatch(loadExternalAccount(thirdPartyName));
    }
  }, [loadExternalAccount]);

  const handleSubmit = (keys: ThirdPartyKeys) => {
    dispatch(saveExternalAccount(thirdPartyName, keys));
  };

  if (isSaving) {
    return <Loader />;
  }

  return (
    <AnalyticsPage tag={thirdPartyName} type="page">
      <MainHolder>
        <SubheaderWithBorder>
          <ContentHolder>
            <BlueTypography variant="h1">Third-party Integrations</BlueTypography>
          </ContentHolder>
        </SubheaderWithBorder>
        <DescriptionContainer>
          <StyledTypography variant="p">
            <AnalyticsComponent>
              {({ pages, tracker, merchantId, ip }) => (
                <>
                  <a
                    onClick={() => {
                      tracker.track({
                        event: 'click',
                        type: 'link',
                        tag: `link_eventbrite`,
                        pages,
                        merchantId,
                        ip,
                      });
                    }}
                    href="https://www.eventbrite.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Eventbrite
                  </a>
                  &nbsp; is an event management and ticketing website. You can use this integration to monetize an
                  Eventbrite event via the InPlayer platform.
                </>
              )}
            </AnalyticsComponent>
          </StyledTypography>
        </DescriptionContainer>
        <FormContainer>
          <MarginTypography variant="h5">Connect your account</MarginTypography>
          <InputsForm eventbriteKeys={thirdPartyKeys} onSubmit={handleSubmit} />
        </FormContainer>
      </MainHolder>
    </AnalyticsPage>
  );
};

export default ThirdPartyIntegrationsForm;
