import styled, { css } from 'styled-components';
import { Colors, Button, Grid } from '@inplayer-org/inplayer-ui';

const { Container, Cell } = Grid;

const sharedHoverBoxStyle = () => css`
  box-shadow: 0 0.2rem 0.5rem ${Colors.gray};
  transform: scale(1.03);
  /* show truncate text tooltip */
  z-index: 1;
`;

const sharedBoxStyle = () => css`
  transition: transform 0.3s ease-in-out 0s;

  &:hover {
    ${sharedHoverBoxStyle}
  }
`;

const displayAccessFeeStyle = () => css`
  margin-bottom: 0;
  border-radius: 3px 3px 0 0;
`;

export const ItemsWrapper = styled.div`
  ${sharedBoxStyle}
`;

export const ItemContainer = styled.div<{ displayAccessFeeSection?: boolean }>`
  display: flex;
  width: 100%;
  height: 4rem;
  margin: 1rem 0;
  border: 1px solid ${Colors.fontLightGray};
  border-radius: 0.3rem;
  align-items: center;
  background-color: ${Colors.white};
  padding: 0.2rem;

  ${({ displayAccessFeeSection }) => (displayAccessFeeSection ? displayAccessFeeStyle : sharedBoxStyle)}
`;

export const Image = styled.img`
  width: 30%;
  height: 100%;
`;

export const Title = styled.span`
  color: ${Colors.fontGray};
  font-size: ${({ theme }) => theme.font.sizes.small};
  font-weight: ${({ theme }) => theme.font.weights.bold};
  width: 100%;
  margin-left: 0.7rem;
  word-break: break-word;

  /* Style for truncate title tooltip */
  > div > div {
    width: fit-content;
  }
`;

export const ButtonLink = styled(Button)`
  padding: 0;
  align-self: flex-start;
  font-size: ${({ theme }) => theme.font.sizes.small};
  color: ${Colors.asbestos};
  font-weight: ${({ theme }) => theme.font.weights.normal};
  display: flex;

  span {
    padding: 0;
  }
`;

export const StyledContainer = styled(Container)<{ isFetching: boolean }>`
  border: 1px solid ${Colors.fontLightGray};
  border-top: 0;
  padding: 15px;
  box-shadow: 0 0.2rem 0.5rem ${Colors.gray};
  margin: 0;
  width: 100%;

  svg {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ToggleWrapper = styled.div`
  justify-content: flex-start;
  width: 100%;
  text-align: left;

  label {
    font-size: 0.8rem;
    margin-right: 1.5rem;
  }
`;

export const StyledButton = styled(Button)`
  margin-right: 0.4rem;
`;

export const CheckboxWrapper = styled(Cell)`
  label {
    font-size: 0.8rem;
  }
`;
