import { Option } from '@inplayer-org/inplayer-ui';

export enum ReportTypes {
  COMMERCIALS = 'commercial-setup',
  MAU = 'monthly_active_users',
  MERCHANT_ANALYTICS = 'merchant-analytics',
}

export const monthOptions: Array<Option> = [
  { value: '01', displayName: 'January' },
  { value: '02', displayName: 'February' },
  { value: '03', displayName: 'March' },
  { value: '04', displayName: 'April' },
  { value: '05', displayName: 'May' },
  { value: '06', displayName: 'June' },
  { value: '07', displayName: 'July' },
  { value: '08', displayName: 'August' },
  { value: '09', displayName: 'September' },
  { value: '10', displayName: 'October' },
  { value: '11', displayName: 'November' },
  { value: '12', displayName: 'December' },
];

export const yearOptions: Array<Option> = [
  { value: '2022', displayName: '2022' },
  { value: '2023', displayName: '2023' },
  { value: '2024', displayName: '2024' },
  { value: '2025', displayName: '2025' },
  { value: '2026', displayName: '2026' },
];

export const reportOptions: Array<Option> = [
  { value: ReportTypes.COMMERCIALS, displayName: 'Commercial setup report' },
  { value: ReportTypes.MAU, displayName: 'MAUs report' },
];
