import * as React from 'react';
import styled from 'styled-components';
import { Colors, AnalyticsComponent } from '@inplayer-org/inplayer-ui';

// components
import Tile from 'pages/MerchantPages/Analytics/components/Tile';
import { StyledLink } from 'pages/MerchantPages/Analytics/components/TopInfo';
import EmptyMessage from 'components/EmptyMessage';

interface Props {
  title: string;
  link: string;
  topInfoList: {
    length: number;
  };
}

const AnalyticsList = styled.ul`
  width: 100%;
  height: 200px;
  word-break: break-all;
  list-style: none;
  text-align: left;
  padding: 5px;

  > span {
    font-weight: 400;
    font-style: italic;
    margin-right: 10px;
  }

  > li {
    margin-bottom: 12px;
    color: ${Colors.fontGray};
    font-size: ${({ theme }) => theme.font.sizes.medium};
  }
`;

const Container = styled.div`
  width: 100%;
  height: 260px;
`;

const EmptyMessageStyled = styled(EmptyMessage)`
  float: left;
  margin-top: 15px;
  margin-left: 20px;
`;

const ListTopInfo: React.FunctionComponent<Props> = ({ title, topInfoList, link }) => (
  <Tile
    title={title}
    tileWidth="49%"
    tileMargin="1rem 0"
    tileContent={
      <AnalyticsComponent>
        {({ pages, tracker, merchantId, ip }) => (
          <Container>
            {topInfoList.length > 0 ? (
              <div data-testid="tile-content">
                <AnalyticsList>{topInfoList}</AnalyticsList>
                <div>
                  <hr />
                  <StyledLink
                    onClick={() => {
                      tracker.track({
                        event: 'click',
                        type: 'link',
                        tag: 'link_see_all',
                        pages,
                        merchantId,
                        ip,
                      });
                    }}
                    to={`/${link}`}
                  >
                    See all
                  </StyledLink>
                </div>
              </div>
            ) : (
              <EmptyMessageStyled variant="p">There is no available data for this time period.</EmptyMessageStyled>
            )}
          </Container>
        )}
      </AnalyticsComponent>
    }
  />
);

export default ListTopInfo;
