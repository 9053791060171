import React from 'react';
import { Button, Grid, Typography, AnalyticsProps } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';

// types
import { ModalNames } from 'ducks/merchantDucks/modal/types';

// styled components
import { BlueTypography, CancelButton, Description } from 'components/SharedStyledElements';

// components
import StyledContainer from 'components/StyledContainer';
import BaseModal, { ModalActions } from './BaseModal';

const { Cell } = Grid;

export const HeaderWrapper = styled.div`
  margin-bottom: 1rem;
`;

interface Props extends AnalyticsProps {
  message?: string;
  buttonText?: string;
  handleConfirm: () => any;
  shouldCloseModal?: boolean;
  modalName: ModalNames;
  modalInstance?: string;
  buttonsDisabled?: boolean;
  renderContent?: any;
  title?: string;
  description?: string;
  subheader?: string;
  handleCloseModal?: () => void;
  confirmText?: string;
  renderComponent?: React.ReactNode;
  cancelText?: string;
  showButtons?: boolean;
  isOverflowVisible?: boolean;
  confirmButtonTag?: string;
}

const ConfirmModal = (props: Props) => {
  const {
    message,
    buttonText,
    handleConfirm,
    shouldCloseModal = true,
    modalName,
    modalInstance = '',
    renderContent,
    buttonsDisabled,
    title,
    description,
    subheader,
    handleCloseModal,
    confirmText,
    cancelText,
    renderComponent,
    showButtons = true,
    isOverflowVisible = false,
    confirmButtonTag = '',
    tag = '',
  } = props;

  return (
    <BaseModal
      tag={tag}
      isOverflowVisible={isOverflowVisible}
      modalName={modalName}
      modalInstance={modalInstance}
      title={title || 'Confirm your action'}
      render={({ closeModal }: ModalActions) => (
        <>
          <HeaderWrapper>
            <BlueTypography variant="h4">{message}</BlueTypography>
          </HeaderWrapper>
          {subheader && <Typography variant="p">{subheader}</Typography>}
          {description && <Description variant="p">{description}</Description>}
          {renderContent && renderContent()}
          {renderComponent && renderComponent}
          {showButtons && (
            <StyledContainer columns={4} alignContent="center" justifyContent="center" margin="2rem 0 0 0">
              <Cell middle left={3}>
                <CancelButton
                  tag="button_no"
                  fullWidth
                  type="button"
                  onClick={() => {
                    closeModal();
                    if (handleCloseModal) handleCloseModal();
                  }}
                >
                  {cancelText || 'Oops, no!'}
                </CancelButton>
              </Cell>
              <Cell middle>
                <Button
                  tag={confirmButtonTag || 'button_yes'}
                  disabled={buttonsDisabled}
                  fullWidth
                  type="submit"
                  onClick={async () => {
                    try {
                      await handleConfirm();
                    } catch (_) {
                    } finally {
                      if (shouldCloseModal) {
                        closeModal();
                      }
                    }
                  }}
                  buttonModifiers={['buttonPrimary']}
                >
                  {confirmText || (buttonText ? `Yes, ${buttonText}!` : 'Yes, delete!')}
                </Button>
              </Cell>
            </StyledContainer>
          )}
        </>
      )}
    />
  );
};

export default ConfirmModal;
