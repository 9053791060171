import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import { FieldProps } from 'formik';
import { Label, TextArea as InPlayerTextArea, Tooltip, Grid } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';
import { MdInfoOutline } from 'react-icons/md';
import { snakeCase } from 'lodash';

const { Container, Cell } = Grid;

const RowCell = styled(Cell)`
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
`;

const MarginTooltip = styled(Tooltip)`
  margin-left: 0.3rem;
`;

const StyledIcon = styled(MdInfoOutline)`
  width: 19px;
  height: 19px;
`;

interface OwnProps {
  label?: string;
  tooltip?: string;
  inlineLabel?: boolean;
}

type Props = InputHTMLAttributes<HTMLInputElement> & FieldProps & OwnProps;

const TextArea: FunctionComponent<Props> = ({
  id,
  label,
  required,
  field: { name: fieldName, ...fieldRest },
  tooltip,
  inlineLabel,
  ...rest
}) => {
  return (
    <Container>
      {(label || tooltip) && (
        <RowCell width={inlineLabel ? 2 : 12} middle>
          {label && <Label htmlFor={fieldName}>{`${label}${required ? ' *' : ''}`}</Label>}
          {tooltip && (
            <MarginTooltip tag={tooltip && `tooltip_${snakeCase(label)}`} placement="right" content={tooltip}>
              <StyledIcon />
            </MarginTooltip>
          )}
        </RowCell>
      )}
      <Cell width={inlineLabel ? 10 : 12}>
        <InPlayerTextArea id={id || fieldName} name={fieldName} {...fieldRest} {...rest} />
      </Cell>
    </Container>
  );
};

export default TextArea;
