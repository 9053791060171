import isEmpty from 'lodash/isEmpty';

import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';
import http, { ContentType } from 'utils/http';
import { API } from 'constants/api';
import {
  IOS_PAYMENT_PROVIDER_ID,
  AMAZON_PAYMENT_PROVIDER_ID,
  ROKU_PAYMENT_PROVIDER_ID,
  PAYPAL_PAYMENT_PROVIDER_ID,
} from 'constants/index';
import { ApiAction } from 'ducks/types';

// Google in-app actions

// Load google in-app integration keys
export enum LoadGoogleInAppKeysActionTypes {
  START = 'LOAD_GOOGLE_IN_APP_KEYS_REQUEST',
  SUCCESS = 'LOAD_GOOGLE_IN_APP_KEYS_SUCCESS',
  FAILURE = 'LOAD_GOOGLE_IN_APP_KEYS_FAILURE',
}

export const loadGoogleInAppKeys = (): ApiAction<LoadGoogleInAppKeysActionTypes> => async (dispatch) => {
  const endpoint = API.GOOGLE_IN_APP_INTEGRATION;

  try {
    dispatch({ type: LoadGoogleInAppKeysActionTypes.START });
    const { data } = await http.authenticated().get(endpoint, {});
    dispatch({ type: LoadGoogleInAppKeysActionTypes.SUCCESS, payload: { ...data } });
  } catch (_) {
    dispatch({ type: LoadGoogleInAppKeysActionTypes.FAILURE });
  }
};

// Add google in-app integration keys
export enum GooleInappIntegrationActionTypes {
  START = 'ADD_GOOGLE_IN_APP_INTEGRATION_REQUEST',
  SUCCESS = 'ADD_GOOGLE_IN_APP_INTEGRATION_SUCCESS',
  FAILURE = 'ADD_GOOGLE_IN_APP_INTEGRATION_FAILURE',
}

export const addGoogleInAppIntegration = (metadata: {
  site_token: string;
  playstore_public_key: string;
}): ApiAction<GooleInappIntegrationActionTypes | NotificationActionTypes> => async (dispatch, getState) => {
  const endpoint = API.GOOGLE_IN_APP_INTEGRATION;

  const { site_token, playstore_public_key } = metadata;
  const params = { site_token, playstore_public_key };

  const { googleInAppKeys } = getState().inAppIntegrations;
  let actionType = 'saved';
  if (!isEmpty(googleInAppKeys)) actionType = 'updated';

  const actionParams = {
    data: params,
    contentType: ContentType.URLENCODED,
  };

  try {
    dispatch({ type: GooleInappIntegrationActionTypes.START });
    const { data } = !isEmpty(googleInAppKeys)
      ? await http.authenticated().put(endpoint, actionParams)
      : await http.authenticated().post(endpoint, actionParams);
    dispatch({
      type: GooleInappIntegrationActionTypes.SUCCESS,
      payload: { ...data },
    });
    dispatch(loadGoogleInAppKeys());
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: `Google in-app integration was successfully ${actionType}.`,
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;

    dispatch({ type: GooleInappIntegrationActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Apple in-app actions

// Load apple in-app integration keys
export enum LoadAppleInAppKeysActionTypes {
  START = 'LOAD_APPLE_IN_APP_KEYS_REQUEST',
  SUCCESS = 'LOAD_APPLE_IN_APP_KEYS_SUCCESS',
  FAILURE = 'LOAD_APPLE_IN_APP_KEYS_FAILURE',
}

export const loadAppleInAppKeys = (): ApiAction<LoadAppleInAppKeysActionTypes> => async (dispatch) => {
  const endpoint = API.IN_APP_INTEGRATION;

  try {
    dispatch({ type: LoadAppleInAppKeysActionTypes.START });
    const { data } = await http.authenticated().get(endpoint, {
      pathVariables: {
        id: IOS_PAYMENT_PROVIDER_ID,
      },
    });
    dispatch({ type: LoadAppleInAppKeysActionTypes.SUCCESS, payload: { ...data.metadata } });
  } catch (_) {
    dispatch({ type: LoadAppleInAppKeysActionTypes.FAILURE });
  }
};

// Add apple in-app integration fields
export enum AppleInAppIntegrationActionTypes {
  START = 'ADD_APPLE_IN_APP_INTEGRATION_REQUEST',
  SUCCESS = 'ADD_APPLE_IN_APP_INTEGRATION_SUCCESS',
  FAILURE = 'ADD_APPLE_IN_APP_INTEGRATION_FAILURE',
}

export const addAppleInAppIntegration = (
  metadata: any
): ApiAction<AppleInAppIntegrationActionTypes | NotificationActionTypes> => async (dispatch, getState) => {
  const endpoint = API.ADD_IN_APP_INTEGRATION;
  const params = { payment_provider_id: IOS_PAYMENT_PROVIDER_ID, metadata };
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

  const { appleInAppKeys } = getState().inAppIntegrations;
  let actionType = 'saved';
  if (!isEmpty(appleInAppKeys)) actionType = 'updated';

  try {
    dispatch({ type: AppleInAppIntegrationActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      data: params,
      contentType: ContentType.URLENCODED,
      headers,
    });
    dispatch({
      type: AppleInAppIntegrationActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(loadAppleInAppKeys());
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: `Apple in-app integration was successfully ${actionType}.`,
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;

    dispatch({ type: AppleInAppIntegrationActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Amazon in-app actions
// Load amazon in-app integration keys
export enum LoadAmazonInAppKeysActionTypes {
  START = 'LOAD_AMAZON_IN_APP_KEYS_REQUEST',
  SUCCESS = 'LOAD_AMAZON_IN_APP_KEYS_SUCCESS',
  FAILURE = 'LOAD_AMAZON_IN_APP_KEYS_FAILURE',
}

export const loadAmazonInAppKeys = (): ApiAction<LoadAmazonInAppKeysActionTypes> => async (dispatch) => {
  const endpoint = API.IN_APP_INTEGRATION;

  try {
    dispatch({ type: LoadAmazonInAppKeysActionTypes.START });
    const { data } = await http.authenticated().get(endpoint, {
      pathVariables: {
        id: AMAZON_PAYMENT_PROVIDER_ID,
      },
    });
    dispatch({ type: LoadAmazonInAppKeysActionTypes.SUCCESS, payload: { ...data.metadata } });
  } catch (_) {
    dispatch({ type: LoadAmazonInAppKeysActionTypes.FAILURE });
  }
};

// Add Amazon in-app integration fields
export enum AmazonInAppIntegrationActionTypes {
  START = 'ADD_AMAZON_IN_APP_INTEGRATION_REQUEST',
  SUCCESS = 'ADD_AMAZON_IN_APP_INTEGRATION_SUCCESS',
  FAILURE = 'ADD_AMAZON_IN_APP_INTEGRATION_FAILURE',
}

export const addAmazonInAppIntegration = (
  metadata: any
): ApiAction<AmazonInAppIntegrationActionTypes | NotificationActionTypes> => async (dispatch, getState) => {
  const endpoint = API.ADD_IN_APP_INTEGRATION;
  const params = { payment_provider_id: AMAZON_PAYMENT_PROVIDER_ID, metadata };
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

  const { amazonInAppKeys } = getState().inAppIntegrations;
  let actionType = 'saved';
  if (!isEmpty(amazonInAppKeys)) actionType = 'updated';

  try {
    dispatch({ type: AmazonInAppIntegrationActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      data: params,
      contentType: ContentType.URLENCODED,
      headers,
    });
    dispatch({
      type: AmazonInAppIntegrationActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(loadAmazonInAppKeys());
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: `Amazon in-app integration was successfully ${actionType}.`,
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;

    dispatch({ type: AmazonInAppIntegrationActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Roku in-app actions
// Load roku in-app integration keys
export enum LoadRokuInAppKeysActionTypes {
  START = 'LOAD_ROKU_IN_APP_KEYS_REQUEST',
  SUCCESS = 'LOAD_ROKU_IN_APP_KEYS_SUCCESS',
  FAILURE = 'LOAD_ROKU_IN_APP_KEYS_FAILURE',
}

export const loadRokuInAppKeys = (): ApiAction<LoadRokuInAppKeysActionTypes> => async (dispatch) => {
  const endpoint = API.IN_APP_INTEGRATION;

  try {
    dispatch({ type: LoadRokuInAppKeysActionTypes.START });
    const { data } = await http.authenticated().get(endpoint, {
      pathVariables: {
        id: ROKU_PAYMENT_PROVIDER_ID,
      },
    });
    dispatch({ type: LoadRokuInAppKeysActionTypes.SUCCESS, payload: { ...data.metadata } });
  } catch (_) {
    dispatch({ type: LoadRokuInAppKeysActionTypes.FAILURE });
  }
};

// Add Roku in-app integration fields
export enum RokuInAppIntegrationActionTypes {
  START = 'ADD_ROKU_IN_APP_INTEGRATION_REQUEST',
  SUCCESS = 'ADD_ROKU_IN_APP_INTEGRATION_SUCCESS',
  FAILURE = 'ADD_ROKU_IN_APP_INTEGRATION_FAILURE',
}

export const addRokuInAppIntegration = (
  metadata: any
): ApiAction<RokuInAppIntegrationActionTypes | NotificationActionTypes> => async (dispatch, getState) => {
  const endpoint = API.ADD_IN_APP_INTEGRATION;
  const params = { payment_provider_id: ROKU_PAYMENT_PROVIDER_ID, metadata };
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

  const { rokuInAppKeys } = getState().inAppIntegrations;
  let actionType = 'saved';
  if (!isEmpty(rokuInAppKeys)) actionType = 'updated';

  try {
    dispatch({ type: RokuInAppIntegrationActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      data: params,
      contentType: ContentType.URLENCODED,
      headers,
    });
    dispatch({
      type: RokuInAppIntegrationActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(loadRokuInAppKeys());
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: `Roku in-app integration was successfully ${actionType}.`,
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;

    dispatch({ type: RokuInAppIntegrationActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// PayPal actions
// Load PayPal integration keys
export enum LoadPayPalKeysActionTypes {
  START = 'LOAD_PAYPAL_KEYS_REQUEST',
  SUCCESS = 'LOAD_PAYPAL_KEYS_SUCCESS',
  FAILURE = 'LOAD_PAYPAL_KEYS_FAILURE',
}

export const loadPaypalKeys = (): ApiAction<LoadPayPalKeysActionTypes> => async (dispatch) => {
  const endpoint = API.IN_APP_INTEGRATION;

  try {
    dispatch({ type: LoadPayPalKeysActionTypes.START });
    const { data } = await http.authenticated().get(endpoint, {
      pathVariables: {
        id: PAYPAL_PAYMENT_PROVIDER_ID,
      },
    });
    dispatch({
      type: LoadPayPalKeysActionTypes.SUCCESS,
      payload: { ...data.metadata },
    });
  } catch (_) {
    dispatch({ type: LoadPayPalKeysActionTypes.FAILURE });
  }
};

// Add Paypal integration fields
export enum PaypalIntegrationActionTypes {
  START = 'ADD_PAYPAL_INTEGRATION_REQUEST',
  SUCCESS = 'ADD_PAYPAL_INTEGRATION_SUCCESS',
  FAILURE = 'ADD_PAYPAL_INTEGRATION_FAILURE',
}

export const addPaypalIntegration = (
  metadata: any
): ApiAction<PaypalIntegrationActionTypes | NotificationActionTypes> => async (dispatch, getState) => {
  const endpoint = API.ADD_IN_APP_INTEGRATION;
  const params = {
    payment_provider_id: PAYPAL_PAYMENT_PROVIDER_ID,
    metadata,
  };
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const { paypalKeys } = getState().inAppIntegrations;
  let actionType = 'saved';
  if (!isEmpty(paypalKeys)) actionType = 'updated';

  try {
    dispatch({ type: PaypalIntegrationActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      data: params,
      contentType: ContentType.URLENCODED,
      headers,
    });
    dispatch({
      type: PaypalIntegrationActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(loadPaypalKeys());
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: `PayPal integration was successfully ${actionType}.`,
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;

    dispatch({ type: PaypalIntegrationActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};
