import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { API } from 'constants/api';
import { INPLAYER_UUID } from 'constants/index';
import http, { ContentType, isAxiosError } from 'utils/http';
import TokenManager from 'utils/TokenManager';
import RootState from 'ducks/RootState';
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';

export enum LoadFollowersActionTypes {
  START = 'MASTER_FOLLOWERS_REQUEST',
  SUCCESS = 'MASTER_FOLLOWERS_SUCCESS',
  FAILURE = 'MASTER_FOLLOWERS_FAILURE',
}

export interface QueryParams {
  page?: number;
  size?: number;
  search?: Array<string>;
  order?: string;
}

type LoadFollowersAction = Action<LoadFollowersActionTypes>;

export const loadFollowers = (
  params?: QueryParams
): ThunkAction<Promise<any>, RootState, any, LoadFollowersAction> => async (dispatch) => {
  const endpoint = API.MASTER_FOLLOWERS;

  const headers = {
    'Content-Type': ContentType.URLENCODED,
  };

  try {
    dispatch({ type: LoadFollowersActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params, headers });
    dispatch({ type: LoadFollowersActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: LoadFollowersActionTypes.FAILURE });
  }
};

export enum LoadClientsActionTypes {
  START = 'MASTER_CLIENTS_REQUEST',
  SUCCESS = 'MASTER_CLIENTS_SUCCESS',
  FAILURE = 'MASTER_CLIENTS_FAILURE',
}

type LoadClientsAction = Action<LoadClientsActionTypes>;

export const loadClients = (
  params: QueryParams
): ThunkAction<Promise<any>, RootState, any, LoadClientsAction> => async (dispatch) => {
  const endpoint = API.MASTER_FOLLOWERS;

  const headers = {
    'Content-Type': ContentType.URLENCODED,
  };

  try {
    dispatch({ type: LoadClientsActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params, headers });
    dispatch({ type: LoadClientsActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: LoadClientsActionTypes.FAILURE });
  }
};

/* Login as follower */

export enum LoginAsFollowerActionTypes {
  START = 'LOGIN_AS_FOLLOWER_REQUEST',
  SUCCESS = 'LOGIN_AS_FOLLOWER_SUCCESS',
  FAILURE = 'LOGIN_AS_FOLLOWER_FAILURE',
}

type LoginAsFollowerAction = Action<LoginAsFollowerActionTypes | NotificationActionTypes>;

export interface LoginAsFollowerData {
  password: string;
  sign_in_as: number;
  uuid: string;
  email: string;
  client_id: string;
  grant_type: string;
}

export const loginAsFollower = (
  data: LoginAsFollowerData
): ThunkAction<Promise<any>, RootState, void, LoginAsFollowerAction> => async (dispatch, getState) => {
  const endpoint = API.LOGIN_AS_FOLLOWER;
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

  try {
    dispatch({ type: LoginAsFollowerActionTypes.START });
    const { token: masterToken, expires: masterExpires, refreshToken: masterRefreshToken } = TokenManager;
    const {
      auth: {
        user: {
          full_name: fullName,
          metadata: { avatar_url: avatarUrl },
        },
      },
    } = getState();

    const master = {
      masterToken,
      masterExpires,
      masterRefreshToken,
      full_name: fullName,
      avatar_url: avatarUrl,
    };

    const response = await http.authenticated().post(endpoint, {
      headers,
      data,
      contentType: ContentType.URLENCODED,
    });

    TokenManager.master = master;

    const { token, refresh_token: refreshToken, expires } = response.data;
    TokenManager.token = token;
    TokenManager.refreshToken = refreshToken;
    TokenManager.expires = expires;
    dispatch({ type: LoginAsFollowerActionTypes.SUCCESS, payload: { ...response.data } });
    window.location.reload();
  } catch (e) {
    dispatch({ type: LoginAsFollowerActionTypes.FAILURE });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: 'Incorrect password provided' }));
    // Throw error again to catch it in component so modal is only closed on success
    throw e;
  }
};

/* Login as master */
export enum ReloginMasterActionTypes {
  START = 'RELOGIN_MASTER_REQUEST',
  SUCCESS = 'RELOGIN_MASTER_SUCCESS',
  FAILURE = 'RELOGIN_MASTER_FAILURE',
}

type ReloginMasterAction = Action<ReloginMasterActionTypes>;

export const loginAsMaster = (
  masterRefreshToken: string
): ThunkAction<Promise<any>, RootState, void, ReloginMasterAction> => async (dispatch) => {
  const endpoint = API.MASTER_LOGIN;
  const fd = new FormData();
  fd.append('refresh_token', masterRefreshToken);
  fd.append('client_id', INPLAYER_UUID);
  fd.append('grant_type', 'refresh_token');

  try {
    dispatch({ type: ReloginMasterActionTypes.START });
    const response = await http.post(endpoint, { data: fd, contentType: ContentType.FORM_DATA });
    const { access_token: accessToken, refresh_token: refreshToken, expires } = response.data;

    TokenManager.clear();
    TokenManager.token = accessToken;
    TokenManager.refreshToken = refreshToken;
    TokenManager.expires = expires;

    const queryParams = {
      size: 6,
      order: 'createdAt',
    };

    dispatch({ type: ReloginMasterActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(loadClients(queryParams));
    window.location.reload();
  } catch (_) {
    dispatch({ type: ReloginMasterActionTypes.FAILURE });
  }
};

export enum CreateFollowerAccountActionTypes {
  START = 'CREATE_FOLLOWER_ACCOUNT_REQUEST',
  SUCCESS = 'CREATE_FOLLOWER_ACCOUNT_SUCCESS',
  FAILURE = 'CREATE_FOLLOWER_ACCOUNT_FAILURE',
}

type CreateFollowerAccountAction = Action<CreateFollowerAccountActionTypes | NotificationActionTypes>;
interface CreateFollowerPayload {
  full_name: string;
  email: string;
  password: string;
  methods: {
    [key: number]: boolean;
  };
  scopes?: Array<string>;
  master_id: string;
}

export type UpdateFollowerPayload = Partial<CreateFollowerPayload>;

export const createFollowerAccount = (
  clientData: UpdateFollowerPayload
): ThunkAction<Promise<any>, void, RootState, CreateFollowerAccountAction> => async (dispatch) => {
  const endpoint = API.CREATE_FOLLOWER_ACCOUNT;

  try {
    dispatch({ type: CreateFollowerAccountActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data: clientData,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: CreateFollowerAccountActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({ type: 'success', title: 'SUCCESS', content: 'Successfully related a new merchant account.' })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: CreateFollowerAccountActionTypes.FAILURE });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
    // Throw error again to catch it in component so modal is only closed on success
    throw e;
  }
};

export enum UpdateFollowerAccountActionTypes {
  START = 'UPDATE_FOLLOWER_ACCOUNT_REQUEST',
  SUCCESS = 'UPDATE_FOLLOWER_ACCOUNT_SUCCESS',
  FAILURE = 'UPDATE_FOLLOWER_ACCOUNT_FAILURE',
}

type UpdateFollowerAccountAction = Action<UpdateFollowerAccountActionTypes | NotificationActionTypes>;

export const updateFollowerAccount = (
  clientData: UpdateFollowerPayload
): ThunkAction<Promise<any>, void, RootState, UpdateFollowerAccountAction> => async (dispatch) => {
  const endpoint = API.UPDATE_DELETE_FOLLOWER_ACCOUNT;

  let updatedClientData = clientData;

  const { scopes } = clientData;
  if (!scopes || !scopes.length) {
    updatedClientData = {
      ...clientData,
      scopes: [''],
    };
  }

  try {
    dispatch({ type: UpdateFollowerAccountActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      data: updatedClientData,
      contentType: ContentType.URLENCODED,
    });

    const { follower_id: id, created_at, master_id, scopes: updatedScopes } = response.data;

    const updatedFollowerDetails = {
      id,
      created_at,
      master_id,
      scopes: updatedScopes,
    };

    dispatch({ type: UpdateFollowerAccountActionTypes.SUCCESS, payload: { ...updatedFollowerDetails } });
    dispatch(notifRequested({ type: 'success', title: 'SUCCESS', content: 'Successfully updated relation.' }));
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: UpdateFollowerAccountActionTypes.FAILURE });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
    // Throw error again to catch it in component so modal is only closed on success
    throw e;
  }
};

export enum DeleteFollowerAccountActionTypes {
  START = 'DELETE_FOLLOWER_ACCOUNT_REQUEST',
  SUCCESS = 'DELETE_FOLLOWER_ACCOUNT_SUCCESS',
  FAILURE = 'DELETE_FOLLOWER_ACCOUNT_FAILURE',
}

type DeleteFollowerAccountAction = Action<DeleteFollowerAccountActionTypes | NotificationActionTypes>;

export const deleteFollowerAccount = (
  followerId: number
): ThunkAction<Promise<any>, void, RootState, DeleteFollowerAccountAction> => async (dispatch) => {
  const endpoint = API.UPDATE_DELETE_FOLLOWER_ACCOUNT;

  try {
    dispatch({ type: DeleteFollowerAccountActionTypes.START });

    const response = await http.authenticated().delete(endpoint, {
      data: {
        follower_id: followerId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: DeleteFollowerAccountActionTypes.SUCCESS, payload: { ...response.data, followerId } });
    dispatch(notifRequested({ type: 'success', title: 'SUCCESS', content: 'Successfully removed related account.' }));
  } catch (error) {
    dispatch({ type: DeleteFollowerAccountActionTypes.FAILURE });

    let message = 'Could not remove related account.';
    if (isAxiosError(error)) {
      const { response } = error;

      if (response && response.data) {
        message = response.data.message || message;
      }
    }

    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

export enum LoadPaymentMethodsActionTypes {
  START = 'LOAD_PAYMENT_METHODS_REQUEST',
  SUCCESS = 'LOAD_PAYMENT_METHODS_SUCCESS',
  FAILURE = 'LOAD_PAYMENT_METHODS_FAILURE',
}

type LoadPaymentMethodsAction = Action<LoadPaymentMethodsActionTypes>;

export const loadPaymentMethods = (): ThunkAction<Promise<any>, void, RootState, LoadPaymentMethodsAction> => async (
  dispatch
) => {
  const endpoint = API.GET_PAYMENT_METHODS;

  const headers = {
    'Content-Type': ContentType.URLENCODED,
  };

  try {
    dispatch({ type: LoadPaymentMethodsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      headers,
    });
    dispatch({ type: LoadPaymentMethodsActionTypes.SUCCESS, payload: { methods: [...response.data] } });
  } catch (_) {
    dispatch({ type: LoadPaymentMethodsActionTypes.FAILURE });
  }
};

export enum LoadFollowerPaymentMethodsActionTypes {
  START = 'LOAD_FOLLOWER_PAYMENT_METHODS_REQUEST',
  SUCCESS = 'LOAD_FOLLOWER_PAYMENT_METHODS_SUCCESS',
  FAILURE = 'LOAD_FOLLOWER_PAYMENT_METHODS_FAILURE',
}

type LoadFollowerPaymentMethodsAction = Action<LoadFollowerPaymentMethodsActionTypes>;

export const loadFollowerPaymentMethods = ({
  merchantID,
  all,
}: {
  merchantID: number;
  all: boolean | string;
}): ThunkAction<Promise<any>, void, RootState, LoadFollowerPaymentMethodsAction> => async (dispatch) => {
  const endpoint = API.GET_PAYMENT_METHODS;

  const headers = {
    'Content-Type': ContentType.URLENCODED,
  };

  const params = {
    merchantID,
    all,
  };

  try {
    dispatch({ type: LoadFollowerPaymentMethodsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      headers,
      params,
    });
    dispatch({ type: LoadFollowerPaymentMethodsActionTypes.SUCCESS, payload: { methods: [...response.data] } });
  } catch (_) {
    dispatch({ type: LoadFollowerPaymentMethodsActionTypes.FAILURE });
  }
};
