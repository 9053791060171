import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Tooltip, Loader, Colors } from '@inplayer-org/inplayer-ui';
import { IoIosMore } from 'react-icons/io';

// images
import revenueIcon from 'assets/pics/icons/revenue.svg';

// utils
import formatNumber from 'utils/formatNumber';

// types
import { RevenuePerCurrency } from 'ducks/merchantDucks/reports/types';
import { FetchReportParams, LoadReportsCommonParams } from 'ducks/merchantDucks/reports/actions';

// components
import AnalyticsInfoBox from 'pages/MerchantPages/Analytics/components/AnalyticsInfoBox';

export const REVENUE = 2;

interface Props {
  revenuePerCurrency: RevenuePerCurrency;
  active: boolean;
  startDate: string | number;
  endDate: string | number;
  interval: string;
  lineChart: boolean;
  color: string;
  loadReportInformations: (params: LoadReportsCommonParams) => any;
  loadRevenuePerCurrency: (params: LoadReportsCommonParams) => any;
  fetchReport: (params: FetchReportParams) => any;
  handleIconClick: (icon: number) => any;
}

const StyledSpan = styled.span`
  margin-top: 2rem;
  position: relative;
  display: inline-block;
`;

class RevenueInfoBox extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { active: prevActive, startDate: prevStartDate, endDate: prevEndDate } = prevProps;
    const {
      fetchReport,
      loadReportInformations,
      loadRevenuePerCurrency,
      active,
      startDate,
      endDate,
      interval,
      lineChart,
    } = this.props;

    if (active && (prevActive !== active || prevStartDate !== startDate || prevEndDate !== endDate)) {
      fetchReport({ icon: REVENUE, startDate, endDate, interval, lineChart });

      const params = { startDate, endDate };
      loadReportInformations(params);
      loadRevenuePerCurrency(params);
    }
  }

  handleIconClick = () => {
    const { handleIconClick } = this.props;
    handleIconClick(REVENUE);
  };

  renderMoreRevenue = (
    value: {
      amount: number;
      currency_iso: string;
    },
    i: number
  ) => {
    return (
      <li key={i}>
        {formatNumber(value.amount)} {value.currency_iso}
      </li>
    );
  };

  renderRevenue = (
    value: {
      amount: number;
      currency_iso: string;
    },
    i: number
  ) => {
    return (
      <h6 key={i}>
        {formatNumber(value.amount)} {value.currency_iso}
      </h6>
    );
  };

  renderContent = () => {
    const {
      revenuePerCurrency: { revenue, isFetching },
    } = this.props;

    const sortedRevenue = [...revenue].sort((amount1: any, amount2: any) => amount2.amount - amount1.amount);
    if (isFetching) {
      return <Loader color={Colors.white} />;
    }
    if (sortedRevenue.length === 0) {
      return <h6>0</h6>;
    }
    if (sortedRevenue.length <= 2) {
      return sortedRevenue.map(this.renderRevenue);
    }
    if (sortedRevenue.length > 2) {
      return (
        <div>
          {sortedRevenue.slice(0, 2).map(this.renderRevenue)}
          <StyledSpan>
            <Tooltip
              placement="top"
              content={<ul>{sortedRevenue.slice(2, sortedRevenue.length).map(this.renderMoreRevenue)}</ul>}
            >
              <IoIosMore data-tip data-for="revenue" />
            </Tooltip>
          </StyledSpan>
        </div>
      );
    }

    return null;
  };

  render() {
    const { active, color } = this.props;
    return (
      <AnalyticsInfoBox
        title="Revenue"
        icon={revenueIcon}
        active={active}
        handleIconClick={this.handleIconClick}
        renderContent={this.renderContent}
        color={color}
      />
    );
  }
}

export default RevenueInfoBox;
