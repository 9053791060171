import React, { ReactNode, CSSProperties, useEffect } from 'react';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Modal, AnalyticsProps } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';

// types
import { ModalNames } from 'ducks/merchantDucks/modal/types';
import RootState from 'ducks/RootState';

// actions
import { closeModal as closeModalAction } from 'ducks/merchantDucks/modal/actions';

export const ChildrenContainer = styled.div`
  &&& {
    width: 86%;
    margin: 0 auto;
    padding: 15px 0 15px;
    max-width: 1400px;
    position: relative;
    display: grid;
  }
`;

export interface ModalActions {
  closeModal: () => void;
}

interface OwnProps extends AnalyticsProps {
  className?: string;
  type?: string;
  children?: any;
  hide?: (type?: any) => any;
  title?: string;
  isOverflowVisible?: boolean;
  render: (modalActions: ModalActions) => ReactNode;
  modalName: ModalNames;
  modalInstance?: string;
  style?: CSSProperties;
  onClose?: () => void;
  onOpen?: () => void;
}
interface StateProps {
  isOpen: boolean;
}

interface DispatchProps {
  closeModal: () => void;
}

type Props = OwnProps & StateProps & DispatchProps;

export const BaseModal = ({
  title = '',
  type,
  render,
  closeModal,
  isOpen,
  isOverflowVisible,
  className,
  onClose,
  onOpen,
  tag = '',
}: Props) => {
  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen]);

  const renderModalContent = () => {
    return type !== 'sso' ? (
      <ChildrenContainer data-testid="sso-container">{render({ closeModal })}</ChildrenContainer>
    ) : (
      render({ closeModal })
    );
  };

  const handleCloseModal = () => {
    closeModal();

    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      tag={tag}
      isOverflowVisible={isOverflowVisible}
      className={className}
      title={title!}
      isModalOpen={isOpen}
      closeModal={handleCloseModal}
    >
      {isOpen ? renderModalContent() : null}
    </Modal>
  );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  { modal: { activeModalName, activeModalInstance } },
  { modalName, modalInstance }
) => ({
  isOpen: activeModalName === modalName && (modalInstance ? activeModalInstance === modalInstance : true),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  closeModal: closeModalAction,
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(BaseModal);
