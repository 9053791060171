import React, { FunctionComponent, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

// utils
import { convertArrayToCamelCase } from 'utils/helpers';

// actions
import { closeModal, openModal } from 'ducks/merchantDucks/modal/actions';
import { loadSingleFee, setPaymentProvider, loadDefaultFee } from 'ducks/adminDucks/merchants/actions';

// selectors
import { getAdminMerchantDetailsState, getPaymentSetup } from 'ducks/adminDucks/merchants/selectors';

// types
import { PaymentGateway, PaymentMethodsTableData } from 'ducks/adminDucks/merchants/types';
import { ModalNames } from 'ducks/merchantDucks/modal/types';
import { Roles } from 'ducks/merchantDucks/user/types';

// Styled components
import { HeadingContainer } from 'pages/MerchantPages/Audience/components/CustomerDetailsTable';
import { FlexContainer } from 'pages/AdminPages/components/SharedStyledElements';
import { Label } from 'components/SharedStyledElements';

// components
import { RowActionsRender, TableColumn, Button } from '@inplayer-org/inplayer-ui';
import GenericTable from 'components/Table/GenericTable';
import TableActionMenu from 'components/Table/TableActionMenu';
import CommonIcon from 'components/CommonIcon';
import SetGatewayModal from 'pages/AdminPages/AdminMerchants/PaymentManagement/components/modals/SetGatewayModal';
import SetFeesModal from 'pages/AdminPages/AdminMerchants/PaymentManagement/components/modals/SetFeesModal';
import StripeSettingsModal from 'pages/AdminPages/AdminMerchants/PaymentManagement/components/modals/StripeSettingsModal';
import IntegrationAppModal from 'pages/AdminPages/AdminMerchants/PaymentManagement/components/modals/IntegrationAppModal';
import ConfirmModal from 'components/Modals/ConfirmModal';

export enum ContentIndexes {
  GATEWAY = 0,
  SETUP = 1,
}

export enum ProviderNames {
  STRIPE = 'stripe',
  IDEAL = 'Ideal',
  PAYPAL = 'paypal',
}

export enum InAppTypes {
  GOOGLE = 'google',
  APPLE = 'apple',
}

export enum Scopes {
  INHERITANCE = 'inheritance',
  EMAILINHERITANCE = 'email_inheritance',
}

const {
  ActionMenuContainer,
  ActionMenuContent,
  ActionMenuDropdown,
  ActionMenuDropdownItem,
  ActionMenuLink,
} = TableActionMenu;

const renderGatewayData = (rowValues: PaymentMethodsTableData) => {
  const { name, metadata } = rowValues;

  const isStripeConnectUrl = name.toLowerCase().includes(ProviderNames.STRIPE) && !!metadata?.stripeUserId;
  const isPaypalExternal = name.toLowerCase().includes(ProviderNames.PAYPAL) && !!metadata?.business;

  if (isStripeConnectUrl) {
    return (
      <>
        <span>{name} </span>
        <a href={`https://dashboard.stripe.com/${metadata.stripeUserId}/`} target="_blank" rel="noopener noreferrer">
          Go to Stripe Account
        </a>
      </>
    );
  }

  if (isPaypalExternal) {
    return (
      <>
        {name} <i>{metadata.business}</i>
      </>
    );
  }

  return name;
};

const generateColumns = (): Array<TableColumn<PaymentMethodsTableData>> => {
  const columns: Array<TableColumn<PaymentMethodsTableData>> = [
    {
      title: 'Type',
      key: 'paymentMethod',
      render: ({ value }) => value.name,
    },
    {
      title: 'Gateway',
      key: 'name',
      render: ({ rowValues }) => renderGatewayData(rowValues),
    },
  ];

  return columns;
};

const initialPayment = {
  accountPaymentProviderId: 0,
  active: false,
  current: false,
  external: false,
  id: 0,
  metadata: {
    descriptor: '',
    stripeUserId: '',
  },
  name: '',
  paymentMethod: {
    id: 0,
    name: '',
  },
};

const PaymentSetup: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const merchantId = Number(id);

  const [selectedGateway, setSelectedGateway] = useState('');
  const [contentIndex, setContentIndex] = useState(ContentIndexes.GATEWAY);
  const [selectedPaymentProviderId, setSelectedPaymentProviderId] = useState(0);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState(initialPayment);
  const [inAppType, setInAppType] = useState('');

  const merchantDetails = useSelector(getAdminMerchantDetailsState);
  const { currentPaymentMethodsCollection, isFetching } = useSelector(getPaymentSetup);

  const currentPaymentMethods = convertArrayToCamelCase<PaymentGateway, PaymentMethodsTableData>(
    currentPaymentMethodsCollection
  );

  const handleCommercials = (accountPaymentProviderId: number, selectedMethodName: string) => (
    e: MouseEvent<HTMLElement>
  ) => {
    e.preventDefault();
    setSelectedGateway(selectedMethodName);
    dispatch(loadSingleFee({ accountPaymentProviderId, merchantId }));
    dispatch(openModal(ModalNames.SetFees));
  };

  const handleStripeSettings = (row: PaymentMethodsTableData) => (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setSelectedPayment(row);
    dispatch(openModal(ModalNames.StripeSettingsModal));
  };

  // On hold to be finished integarion from BE
  const handleGoogleInAppSettings = (row: PaymentMethodsTableData) => (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setSelectedPayment(row);
    setInAppType(InAppTypes.GOOGLE);
    dispatch(openModal(ModalNames.IntegrationAppModal));
  };

  // On hold to be finished integarion from BE
  const handleAppleInAppSettings = (row: PaymentMethodsTableData) => (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setSelectedPayment(row);
    setInAppType(InAppTypes.APPLE);
    dispatch(openModal(ModalNames.IntegrationAppModal));
  };

  const handleShowDeleteModal = (paymentProviderId: number, paymentMethodId: number) => (
    e: MouseEvent<HTMLElement>
  ) => {
    e.preventDefault();
    setSelectedPaymentProviderId(paymentProviderId);
    setSelectedPaymentMethodId(paymentMethodId);
    dispatch(openModal(ModalNames.DeletePaymentMethod));
  };

  const handleDeletePaymentMethod = () => {
    dispatch(
      setPaymentProvider({
        merchantId,
        paymentProviderId: selectedPaymentProviderId,
        methodId: selectedPaymentMethodId,
        current: false,
      })
    );
    dispatch(closeModal());
  };

  const handleSetGateway = () => {
    setContentIndex(ContentIndexes.GATEWAY);
    dispatch(loadDefaultFee(merchantId));
    dispatch(openModal(ModalNames.SetGateway));
  };

  const canEditPaymentGateways = () => {
    const { roles } = merchantDetails;

    if (roles) return roles.includes(Roles.MASTER) || roles.includes(Roles.MERCHANT);
    return false;
  };

  const hasInheritance = () => {
    const { roles, scopes } = merchantDetails;

    if (roles && scopes) {
      return roles.includes(Roles.FOLLOWER) && scopes.includes(Scopes.INHERITANCE);
    }
    return false;
  };

  const handleSelectedGatewayChange = (name: string) => {
    setSelectedGateway(name);
  };

  const handleContentIndexChange = (index: number) => {
    setContentIndex(index);
  };

  const renderRowActions: RowActionsRender<PaymentMethodsTableData> = ({ row }) => {
    const {
      name,
      id: paymentProviderId,
      accountPaymentProviderId,
      paymentMethod: { id: paymentMethodId },
    } = row;
    const showStripeSettingsButton = name.toLowerCase().includes(ProviderNames.STRIPE) || name === ProviderNames.IDEAL;
    // On hold to be finished integarion from BE
    const showGoogleSettingsButton = name.toLowerCase().includes(InAppTypes.GOOGLE);
    const showAppleSettingsButton = name.toLowerCase().includes(InAppTypes.APPLE);

    return (
      <ActionMenuContainer>
        <ActionMenuContent>
          <ActionMenuDropdown>
            <ActionMenuLink href="#/" onClick={handleCommercials(accountPaymentProviderId, name)}>
              <ActionMenuDropdownItem>Commercials</ActionMenuDropdownItem>
            </ActionMenuLink>
            {showStripeSettingsButton && (
              <ActionMenuLink href="#/" onClick={handleStripeSettings(row)}>
                <ActionMenuDropdownItem>Stripe settings</ActionMenuDropdownItem>
              </ActionMenuLink>
            )}
            {/* >>> On hold to be finished integarion from BE */}
            {/* {showGoogleSettingsButton && (
              <ActionMenuLink href="#/" onClick={handleGoogleInAppSettings(row)}>
                <ActionMenuDropdownItem>Google settings</ActionMenuDropdownItem>
              </ActionMenuLink>
            )}
            {showAppleSettingsButton && (
              <ActionMenuLink href="#/" onClick={handleAppleInAppSettings(row)}>
                <ActionMenuDropdownItem>Apple settings</ActionMenuDropdownItem>
              </ActionMenuLink>
            )} */}
            {canEditPaymentGateways() && !hasInheritance() && (
              <ActionMenuLink
                data-testid="delete-button"
                href="#/"
                onClick={handleShowDeleteModal(paymentProviderId, paymentMethodId)}
              >
                <ActionMenuDropdownItem>Delete</ActionMenuDropdownItem>
              </ActionMenuLink>
            )}
          </ActionMenuDropdown>
        </ActionMenuContent>
        <CommonIcon name="settings" hoverStatus="settings" />
      </ActionMenuContainer>
    );
  };

  const renderTableHeaderSection = () => {
    return (
      <HeadingContainer gridTemplateColumns="5fr 1fr">
        <Label title variant="p">
          Payment Setup
        </Label>
        <FlexContainer justifyContent="flex-end" width="100%">
          <Button onClick={handleSetGateway}>Set gateway</Button>
        </FlexContainer>
      </HeadingContainer>
    );
  };

  const showHeaderSection = canEditPaymentGateways() && !hasInheritance();
  const headerSection = showHeaderSection ? renderTableHeaderSection() : null;

  return (
    <>
      <GenericTable<PaymentMethodsTableData>
        data={currentPaymentMethods}
        columns={generateColumns()}
        showLoader={isFetching}
        options={{
          rowActions: renderRowActions,
          headerSection,
        }}
        type="payment methods"
      />
      <SetGatewayModal
        canEditPaymentGateways={canEditPaymentGateways()}
        hasInheritance={hasInheritance()}
        selectedGateway={selectedGateway}
        contentIndex={contentIndex}
        handleSelectedGatewayChange={handleSelectedGatewayChange}
        handleContentIndexChange={handleContentIndexChange}
      />
      <SetFeesModal
        canEditPaymentGateways={canEditPaymentGateways()}
        hasInheritance={hasInheritance()}
        selectedGateway={selectedGateway}
      />
      <StripeSettingsModal hasInheritance={hasInheritance()} selectedPayment={selectedPayment} />
      <IntegrationAppModal inAppType={inAppType} />
      <ConfirmModal
        modalName={ModalNames.DeletePaymentMethod}
        message="Are you sure you want to delete the payment method?"
        handleConfirm={handleDeletePaymentMethod}
        buttonText="Delete"
      />
    </>
  );
};

export default PaymentSetup;
