import proj4 from 'proj4';

interface Environment {
  inplayerUuid: string;
  stripeClientId: string;
  awsIotUrl: string;
  apiRoot: string;
  gatewayPaypal: string;
  subdomainUrl: string;
  iotEndpoint: string;
  captchaPublicKey: string;
  iosPaymentProviderId: number;
  googlePaymentProviderId: number;
  amazonPaymentProviderId: number;
  rokuPaymentProviderId: number;
  paypalPaymentProviderId: number;
  paywallV2Script: string;
  paywallScript: string;
  mailTransport: Array<Record<string, number | string>>;
  applePaymentProviderId: number;
  merchantPanelURL: string;
  adminPanelURL: string;
  landingPageBuilderUrl: string;
  newDashboardUrl: string;
}

interface EnvironmentVariables {
  [index: string]: Environment;
}

const DEVELOPMENT_VARIABLES: Environment = {
  inplayerUuid: '3b39b5ab-b5fc-4ba3-b770-73155d20e61f',
  stripeClientId: 'ca_LKXF1hBLXTV6CBwLACjHVZLeiSx8Vlsw',
  awsIotUrl: 'https://daily-notifications.inplayer.com/iot/keys',
  apiRoot: 'https://services-daily.inplayer.com/',
  gatewayPaypal: 'paypal(sandbox)',
  subdomainUrl: 'accounts.daily-v2.inplayer.com',
  iotEndpoint: 'a3gkl64duktvc4-ats.iot.eu-west-1.amazonaws.com',
  captchaPublicKey: '6Le-SQgfAAAAAPcEifuIACnnWedglzPtT1eJ2jgk',
  iosPaymentProviderId: 12,
  googlePaymentProviderId: 14,
  amazonPaymentProviderId: 17,
  rokuPaymentProviderId: 18,
  paypalPaymentProviderId: 10,
  paywallV2Script: 'https://assets.inplayer.com/paywall/sandbox/paywall.min.js',
  paywallScript: 'https://assets.inplayer.com/paywall/v3/sandbox/paywall.min.js',
  mailTransport: [{ value: 'smtp', displayName: 'smtp' }],
  applePaymentProviderId: 12,
  merchantPanelURL: 'https://dashboard-sandbox.inplayer.com',
  adminPanelURL: 'https://dashboard-sandbox.inplayer.com/admin',
  landingPageBuilderUrl: 'https://pagebuilder-stage.inplayer.com/',
  newDashboardUrl: 'https://new-dashboard-daily.inplayer.com',
};

const ENVIRONMENT_VARIABLES: EnvironmentVariables = {
  sandbox: {
    inplayerUuid: '3b39b5ab-b5fc-4ba3-b770-73155d20e61f',
    stripeClientId: 'ca_LKXFWKtx0Hsf73KZ175KVexAOnaseOD6',
    awsIotUrl: 'https://daily-notifications.inplayer.com/iot/keys',
    apiRoot: 'https://services-daily.inplayer.com/',
    gatewayPaypal: 'paypal(sandbox)',
    subdomainUrl: 'accounts.daily-v2.inplayer.com',
    iotEndpoint: 'a3gkl64duktvc4-ats.iot.eu-west-1.amazonaws.com',
    captchaPublicKey: '6Le-SQgfAAAAAPcEifuIACnnWedglzPtT1eJ2jgk',
    iosPaymentProviderId: 12,
    googlePaymentProviderId: 14,
    amazonPaymentProviderId: 17,
    rokuPaymentProviderId: 18,
    paypalPaymentProviderId: 10,
    paywallV2Script: 'https://assets.inplayer.com/paywall/sandbox/paywall.min.js',
    paywallScript: 'https://assets.inplayer.com/paywall/v3/sandbox/paywall.min.js',
    mailTransport: [{ value: 'smtp', displayName: 'smtp' }],
    applePaymentProviderId: 12,
    merchantPanelURL: 'https://dashboard-sandbox.inplayer.com',
    adminPanelURL: 'https://dashboard-sandbox.inplayer.com/admin',
    landingPageBuilderUrl: 'http://pagebuilder-stage.inplayer.com/',
    newDashboardUrl: 'https://new-dashboard-daily.inplayer.com',
  },
  staging: {
    inplayerUuid: '3b39b5ab-b5fc-4ba3-b770-73155d20e61f',
    stripeClientId: 'ca_7wyd6njqUe2mwsqMj1iR7DFAbhqHVRd8',
    awsIotUrl: 'https://staging-notifications.inplayer.com/iot/keys',
    apiRoot: 'https://staging-v2.inplayer.com/',
    gatewayPaypal: 'paypal(sandbox)',
    subdomainUrl: 'accounts.staging-v2.inplayer.com',
    iotEndpoint: 'a3gkl64duktvc4-ats.iot.eu-west-1.amazonaws.com',
    captchaPublicKey: '6LcTvO8eAAAAABOuYtjjLR1w05fbEwKeqlinWiuN',
    iosPaymentProviderId: 12,
    googlePaymentProviderId: 14,
    amazonPaymentProviderId: 17,
    rokuPaymentProviderId: 18,
    paypalPaymentProviderId: 10,
    paywallV2Script: 'https://assets.inplayer.com/paywall/staging/paywall.min.js',
    paywallScript: 'https://assets.inplayer.com/paywall/v3/staging/paywall.min.js',
    mailTransport: [{ value: 'smtp', displayName: 'smtp' }],
    applePaymentProviderId: 12,
    merchantPanelURL: 'https://dashboard-stage.inplayer.com',
    adminPanelURL: 'https://dashboard-stage.inplayer.com/admin',
    landingPageBuilderUrl: 'https://pagebuilder-stage.inplayer.com/',
    newDashboardUrl: 'https://new-dashboard-stage.inplayer.com',
  },
  production: {
    inplayerUuid: '3b39b5ab-b5fc-4ba3-b770-73155d20e61f',
    stripeClientId: 'ca_Bp0R3ZxGCIwMebBaeroCzr7ILR3eucGX',
    awsIotUrl: 'https://notifications.inplayer.com/iot/keys',
    apiRoot: 'https://services.inplayer.com/',
    gatewayPaypal: 'paypal',
    subdomainUrl: 'accounts.inplayer.com',
    iotEndpoint: 'a3gkl64duktvc4-ats.iot.eu-west-1.amazonaws.com',
    captchaPublicKey: '6LfuGJ0UAAAAAGZkoWT9faZ4aR36q3PW18aPXY0Y',
    iosPaymentProviderId: 13,
    googlePaymentProviderId: 15,
    amazonPaymentProviderId: 17,
    rokuPaymentProviderId: 20,
    paypalPaymentProviderId: 8,
    paywallV2Script: 'https://assets.inplayer.com/paywall/latest/paywall.min.js',
    paywallScript: 'https://assets.inplayer.com/paywall/v3/paywall.min.js',
    mailTransport: [
      { value: 'mailgun', displayName: 'mailgun' },
      { value: 'ses', displayName: 'ses' },
    ],
    applePaymentProviderId: 9,
    merchantPanelURL: 'https://dashboard.inplayer.com',
    adminPanelURL: 'https://dashboard.inplayer.com/admin',
    landingPageBuilderUrl: 'https://pagebuilder.inplayer.com/',
    newDashboardUrl: 'https://app.inplayer.com',
  },
};

interface GrecaptchaRenderParameters {
  sitekey: string;
  theme?: string;
  size?: string;
  tabindex?: number;
  callback?(token: string): any;
  callback?: (token: string) => any;
  ['expired-callback']?: () => any;
  ['error-callback']?: () => any;
}

interface Grecaptcha {
  render(container: HTMLElement | string, parameters: GrecaptchaRenderParameters): number | string;
  reset(widgetId?: number | string): any;
  getResponse(widgetId?: number | string): any;
  ready(callback: () => any): any;
}

declare global {
  interface Window {
    piAId: string;
    piCId: string;
    piHostname: string;
    Typekit: any;
    grecaptcha: Grecaptcha;
    proj4: any;
  }
}

window.piAId = '433232';
window.piCId = '40947';
window.proj4 = proj4;

const { REACT_APP_NODE_ENV } = process.env;

let environmentVariables = DEVELOPMENT_VARIABLES;

if (REACT_APP_NODE_ENV && ENVIRONMENT_VARIABLES[REACT_APP_NODE_ENV]) {
  environmentVariables = ENVIRONMENT_VARIABLES[REACT_APP_NODE_ENV];
}

export const {
  inplayerUuid: INPLAYER_UUID,
  apiRoot: API_ROOT,
  awsIotUrl: AWS_IOT_URL,
  gatewayPaypal: GATEWAY_PAYPAL,
  stripeClientId: STRIPE_CLIENT_ID,
  subdomainUrl: SUB_DOMAIN_URL,
  iotEndpoint: IOT_ENDPOINT,
  captchaPublicKey: CAPTCHA_PUBLIC_KEY,
  iosPaymentProviderId: IOS_PAYMENT_PROVIDER_ID,
  amazonPaymentProviderId: AMAZON_PAYMENT_PROVIDER_ID,
  rokuPaymentProviderId: ROKU_PAYMENT_PROVIDER_ID,
  googlePaymentProviderId: GOOGLE_PAYMENT_PROVIDER_ID,
  paypalPaymentProviderId: PAYPAL_PAYMENT_PROVIDER_ID,
  paywallV2Script: PAYWALL_V2_SCRIPT,
  paywallScript: PAYWALL_SCRIPT,
  mailTransport: MAIL_TRANSPORT,
  applePaymentProviderId: APPLE_PAYMENT_PROVIDER_ID,
  merchantPanelURL: MERCHANT_PANEL_URL,
  adminPanelURL: ADMIN_PANEL_URL,
  landingPageBuilderUrl: LANDING_PAGE_BUILDER_URL,
  newDashboardUrl: NEW_DASHBOARD_URL,
} = environmentVariables;
