export const merchants = {
  FETCH_MERCHANT_DETAILS: 'accounts/{id}',
  ADD_MAIL_TEMPLATES: 'v2/mailer/provider',
  UPDATE_DELETE_MAIL_TEMPLATE: 'v2/mailer/settings/{id}',
  BULK_MAIL_TEMPLATE: 'v2/mailer/settings/global',
  PAYMENT_PROVIDERS: 'payments/providers',
  SINGLE_FEE: 'accounting/revenue-share/{id}',
  DEFAULT_FEE: 'accounting/revenue-share',
  BANK_STATEMENT: 'v2/payments/providers/bank-statement',
  SUBSCRIPTIONS_ANALYTICS: `v2/subscriptions/analytics`,
  ACCOUNTING_ANALYTICS: `v2/accounting/analytics`,
  ENABLE_OR_DISABLE_MASTER_ROLE: `accounts/{id}/roles/{roleId}`,
  MERCHANT_FEATURES: 'v2/features/merchant/{id}',
  DISCONNECT: 'v2/payments/stripe-connect/deauthorize',
  APPROVED_FOR_CONNECT: 'payments/stripe-connect-request',
  ADMIN_AGE_RESTRICTION: `restrictions/settings`,
  GET_ADMIN_AGE_RESTRICTION: `restrictions/settings/{merchant_uuid}`,
  IMRESONATE_AS_AGENT: `accounts/impersonate`,
  IMRESONATE_AS_AGENT_V2: `v2/accounts/impersonate`,
  ACCEES_FEE_TYPES: 'v2/items/access-types',
  GET_REVENUE_SETUP: 'v2/accounting/revenue-setup',
  NON_RECURRING_FEES: 'v2/accounting/non-recurring-fees',
  DELETE_NON_RECURRING_FEES: 'v2/accounting/non-recurring-fees/{non_recurring_fee_id}',
  REVENUE_SETUP: 'v2/accounting/revenue-setup/{merchant_id}',
  RECURRING_FEES: 'v2/accounting/recurring-fees',
};
