import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

// types
import RootState from 'ducks/RootState';
import { ArrowType } from './tourGuideConfig/TourGuideTypes';

// components
import TourGuideCloseStep from './TourGuideCloseStep';

interface Props {
  children: ReactNode;
  arrowType?: ArrowType;
}

const TourStep = styled.div<{ arrowType?: ArrowType }>`
  :after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    bottom: 0;
    margin: auto;
    ${({ arrowType }) =>
      (arrowType === ArrowType.left &&
        css`
          border-width: 30px 30px 30px 0;
          border-color: transparent white transparent transparent;
          left: -11px;
          top: -50%;
        `) ||
      (arrowType === ArrowType.right &&
        css`
          border-width: 30px 0 30px 30px;
          border-color: transparent transparent transparent white;
          right: -12px;
          top: -50%;
        `) ||
      (arrowType === ArrowType.top &&
        css`
          border-width: 0 30px 30px 30px;
          border-color: transparent transparent white transparent;
          left: 5%;
          top: -95%;
        `)}
  }
`;

const TourGuideStep = ({ children, arrowType }: Props) => {
  const isRequestCloseClicked = useSelector((state: RootState) => state.auth.tourGuide.isRequestCloseClicked);

  return <TourStep arrowType={arrowType}>{!isRequestCloseClicked ? children : <TourGuideCloseStep />}</TourStep>;
};

export default TourGuideStep;
