import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  ForgotPasswordActionTypes,
  NewPasswordActionTypes,
  UpdateUserActionTypes,
  AddAvatarActionTypes,
} from 'ducks/merchantDucks/user/actions';
import { AddPreviewImageActionTypes } from 'ducks/merchantDucks/items/actions';
import { RESET_FORM } from 'ducks/merchantDucks/form/actions';
import FormsState from 'ducks/merchantDucks/form/types';

export const initialState: FormsState = {
  requestedNewPassword: false,
  isUpdating: false,
  setNewPassword: false,
  uploadImageForm: {
    url: '',
    thumbnail_url: '',
    isUploading: false,
    isUploaded: false,
  },
};

type FormsReducer = Reducer<FormsState>;

const requestNewPassword: FormsReducer = (state = initialState) => ({
  ...state,
  isUpdating: true,
  requestedNewPassword: false,
  setNewPassword: false,
});

const setIsUpdating = (isUpdating: boolean): FormsReducer => (state = initialState) => ({
  ...state,
  isUpdating,
});

const requestNewPasswordSuccess: FormsReducer = (state = initialState) => ({
  ...state,
  isUpdating: false,
  requestedNewPassword: true,
  setNewPassword: false,
});

const addNewPasswordSuccess: FormsReducer = (state = initialState) => ({
  ...state,
  isUpdating: false,
  requestedNewPassword: false,
  setNewPassword: true,
});

const resetForm: FormsReducer = () => ({ ...initialState });

const startImageUpload: FormsReducer = (state = initialState) => ({
  ...state,
  uploadImageForm: {
    ...state.uploadImageForm,
    isUploading: true,
  },
});

const uploadImageSuccess: FormsReducer = (
  state = initialState,
  { payload: { full_size_image_url: fullSizeImageUrl, thumbnail_image_url: thumbnailImageUrl } }
) => ({
  ...state,
  uploadImageForm: {
    ...state.uploadImageForm,
    isUploading: false,
    isUploaded: true,
    url: fullSizeImageUrl,
    thumbnail_url: thumbnailImageUrl,
  },
});

const setIsImageUploaded = (isUploaded: boolean): FormsReducer => (state = initialState) => ({
  ...state,
  uploadImageForm: {
    ...state.uploadImageForm,
    isUploaded,
  },
});

const handleImageUploadError: FormsReducer = (state = initialState) => ({
  ...state,
  uploadImageForm: {
    ...state.uploadImageForm,
    isUploading: false,
    isUploaded: false,
  },
});

const actionsMap: ActionMap<FormsState> = {
  [ForgotPasswordActionTypes.START]: requestNewPassword,
  [ForgotPasswordActionTypes.SUCCESS]: requestNewPasswordSuccess,
  [ForgotPasswordActionTypes.FAILURE]: setIsUpdating(false),
  [NewPasswordActionTypes.START]: requestNewPassword,
  [NewPasswordActionTypes.SUCCESS]: addNewPasswordSuccess,
  [NewPasswordActionTypes.FAILURE]: setIsUpdating(false),
  [RESET_FORM]: resetForm,
  [AddAvatarActionTypes.START]: startImageUpload,
  [AddAvatarActionTypes.SUCCESS]: uploadImageSuccess,
  [AddAvatarActionTypes.FAILURE]: handleImageUploadError,
  [AddPreviewImageActionTypes.START]: startImageUpload,
  [AddPreviewImageActionTypes.SUCCESS]: uploadImageSuccess,
  [AddPreviewImageActionTypes.ERROR]: handleImageUploadError,
  [UpdateUserActionTypes.START]: setIsImageUploaded(true),
  [UpdateUserActionTypes.SUCCESS]: setIsImageUploaded(true),
  [UpdateUserActionTypes.FAILURE]: setIsImageUploaded(true),
};

export default reducerWithActionMap(actionsMap, initialState);
