import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

// actions
import { resendCode as resendCodeAction } from 'ducks/merchantDucks/user/actions';

// components
import ResendCodeForm from 'pages/MerchantPages/Authentication/ResendCode/forms/ResendCode';

interface Props {
  resendCode: (email: string) => any;
}

export class ResendCode extends PureComponent<Props> {
  handleSubmit = (data: { email: string }) => {
    const { resendCode } = this.props;
    return resendCode(data.email);
  };

  render() {
    return <ResendCodeForm onSubmit={this.handleSubmit} />;
  }
}

export default connect(null, {
  resendCode: resendCodeAction,
})(ResendCode);
