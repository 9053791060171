import { AnyAction, Reducer } from 'redux';

// utils
import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import tokenManager, { AdminToken } from 'utils/TokenManager';

// types
import { ReloginUserActionTypes } from 'middleware/jwt';
import { ExternalAccountActionTypes } from 'ducks/merchantDucks/externalAccount/actions';
import {
  LoginActionTypes,
  LogoutUserActionTypes,
  UserActionTypes,
  RegisterUserActionTypes,
  ChangePasswordActionTypes,
  UpdateUserActionTypes,
  SocialAppsActionTypes,
  SocialFieldsActionTypes,
  AddSocialActionTypes,
  ActivateAccountActionTypes,
  AddSessionActionTypes,
  SessionUserActionTypes,
  DeleteSessionActionTypes,
  StripeConnectActionTypes,
  PaymentProvidersActionTypes,
  FeesActionTypes,
  IAMTokenActionTypes,
  GetOrganisationMembersTypes,
  AddOrganisationMemberTypes,
  EditOrganisationMemberTypes,
  DeleteOrganisationMemberTypes,
  GetFeaturesTypes,
  CreateOrganisationTypes,
  GetOrganisationTypes,
  SET_TOUR_GUIDE_STATE,
  RevenueSetupActionTypes,
} from 'ducks/merchantDucks/user/actions';
import { LoginAsFollowerActionTypes, ReloginMasterActionTypes } from 'ducks/merchantDucks/agency/actions';
import { AuthState, Roles } from 'ducks/merchantDucks/user/types';

// config
import rolesRestrictionsConfig from 'restrictions/roles/config/rolesRestrictions';

export const initialState: AuthState = {
  user: {
    id: 1,
    master_id: 1,
    uuid: '',
    tenant_uuid: '',
    full_name: '',
    email: '',
    username: '',
    referrer: '',
    isUpdating: false,
    completed: true,
    merchant_id: 1,
    merchant_uuid: '',
    created_at: 1471012837,
    updated_at: 1562844377,
    date_of_birth: null,
    roles: [Roles.MERCHANT],
    roleRestrictions: [],
    scopes: [],
    metadata: {
      avatar_url: '',
      currency: '',
      social: '',
      // TODO: Check data type if it's boolean
      probability_segment: false,
    },
    social_apps_metadata: {},
  },
  token: '',
  refresh_token: '',
  expires: '',
  isAuthenticating: false,
  isLoading: false,
  isAuthenticated: false,
  isAdmin: false,
  isRegistered: false,
  isActive: false,
  isUpdating: false,
  isRegistering: false,
  isActivating: false,
  isFetching: false,
  social: [],
  socialAppsMetadata: [],
  concurrent_sessions: 0,
  sub_domain_name: '',
  stripeRequest: false,
  paymentProviders: null,
  getFeesRequest: false,
  fees: null,
  bankStatementName: '',
  isSubscribed: false,
  social_fields: [],
  share: 0,
  isFetchingPaymentProviders: false,
  isFetchingRevenueSetup: false,
  hasOrganisation: true,
  organisation: {
    collection: [],
    page: 0,
    total: 0,
    size: 0,
  },
  organisationDetails: {
    id: 0,
    name: '',
  },
  features: [],
  tourGuide: {
    isTourGuideActive: false,
    isLogoClicked: false,
    isSectionTour: false,
    isRequestCloseClicked: false,
    isCloseTourClicked: false,
    previousLocation: '',
    currentStep: 1,
  },
};

type AuthReducer = Reducer<AuthState, AnyAction>;

const reloginUserSuccess: AuthReducer = (state = initialState, { payload }) => {
  const isAdmin = payload.account.roles.includes(Roles.INPLAYER);

  if (isAdmin) {
    const adminToken: AdminToken = {
      adminToken: payload.access_token,
      adminExpires: payload.expires,
      adminRefreshToken: payload.refresh_token,
      full_name: '',
    };

    tokenManager.admin = adminToken;
  } else {
    tokenManager.token = payload.access_token;
    tokenManager.refreshToken = payload.refresh_token;
    tokenManager.expires = payload.expires;
  }

  const roleRestrictions = payload.account.roles.reduce(
    (rules: string[], role: Roles) => [...rules, ...(rolesRestrictionsConfig[role] || [])],
    []
  );

  return {
    ...state,
    isAuthenticated: true,
    isAuthenticating: false,
    isAdmin,
    isActive: true,
    isRegistered: true,
    expires: payload.expires,
    token: payload.access_token,
    user: {
      ...payload.account,
      roleRestrictions,
    },
    refresh_token: payload.refresh_token,
  };
};

const promptUserLogin: AuthReducer = (state = initialState) => ({
  ...state,
  isAuthenticating: true,
});

const loginUserSuccess: AuthReducer = (state = initialState, { payload }) => {
  const { access_token: accessToken, account } = payload;
  const {
    metadata: { social: payloadSocial },
    social_apps_metadata: socialAppsMetadata,
    roles,
  } = account;

  let social: Record<string, boolean> = {};

  if (payloadSocial) {
    social = payloadSocial.split('|').reduce((acc: Record<string, boolean>, s: string) => ({ ...acc, [s]: true }), {});
  }

  const roleRestrictions = roles.reduce(
    (rules: string[], role: Roles) => [...rules, ...(rolesRestrictionsConfig[role] || [])],
    []
  );

  const isAdmin = roles.includes(Roles.INPLAYER);

  return {
    ...state,
    isAuthenticated: true,
    isAdmin,
    isAuthenticating: false,
    isActive: true,
    isRegistered: true,
    token: accessToken,
    user: {
      ...account,
      roleRestrictions,
    },
    social,
    socialAppsMetadata,
  };
};

const userLoginFailure: AuthReducer = () => ({
  ...initialState,
});

const promptRegisterUser: AuthReducer = (state = initialState) => ({
  ...state,
  isRegistering: true,
});

const registerUserSuccess: AuthReducer = (state = initialState) => ({
  ...state,
  isAuthenticated: false,
  isActive: false,
  isRegistered: true,
  isRegistering: false,
});

const registerUserFailure: AuthReducer = () => ({
  ...initialState,
});

const setIsActivating = (isActivating: boolean): AuthReducer => (state = initialState) => ({
  ...state,
  isActivating,
});

const activateAccountSuccess: AuthReducer = (state = initialState) => ({
  ...state,
  isActivating: false,
  isActive: true,
});

const promtUserAction: AuthReducer = (state = initialState, { payload: isSocialAuth }) => ({
  ...state,
  isLoading: !isSocialAuth,
});

const userActionSuccess: AuthReducer = (state = initialState, { payload }) => {
  const {
    metadata: { social: payloadSocial },
    social_apps_metadata: socialAppsMetadata,
    roles,
  } = payload;

  let social: Record<string, boolean> = {};

  if (payloadSocial) {
    social = payloadSocial.split('|').reduce((acc: Record<string, boolean>, s: string) => ({ ...acc, [s]: true }), {});
  }

  const roleRestrictions = roles.reduce(
    (rules: string[], role: Roles) => [...rules, ...(rolesRestrictionsConfig[role] || [])],
    []
  );

  const isAdmin = roles.includes(Roles.INPLAYER);

  return {
    ...state,
    isAuthenticated: true,
    isAdmin,
    isAuthenticating: false,
    isLoading: false,
    token: tokenManager.token,
    user: {
      ...state.user,
      ...payload,
      roleRestrictions,
    },
    social,
    socialAppsMetadata,
  };
};

const setSocialAppsSuccess: AuthReducer = (state = initialState, { payload }) => {
  const { social: payloadSocial } = payload;
  let social: Record<string, boolean> = {};

  if (payloadSocial) {
    social = payloadSocial.reduce(
      (acc: Record<string, boolean>, s: string) => ({
        ...acc,
        [s]: true,
      }),
      {}
    );
  }

  return {
    ...state,
    social,
  };
};

const addSocialAppSuccess: AuthReducer = (state = initialState, { payload }) => ({
  ...state,
  socialAppsMetadata: payload.social_apps_metadata,
});

const setSocialFieldsSuccess: AuthReducer = (state = initialState, { payload }) => ({
  ...state,
  ...payload,
});

const userActionFailure: AuthReducer = (state = initialState) => {
  return {
    ...state,
    isLoading: false,
  };
};

const logoutUserSuccess: AuthReducer = (state = initialState) => {
  return {
    ...state,
    isAuthenticated: false,
  };
};

const setIsUpdating = (isUpdating: boolean): AuthReducer => (state = initialState) => ({
  ...state,
  isUpdating,
});

const updateUserSuccess: AuthReducer = (state = initialState, { payload }) => ({
  ...state,
  isUpdating: false,
  user: {
    ...state.user,
    metadata: payload.metadata,
  },
});

const addSessionSuccess: AuthReducer = (state = initialState, { payload }) => ({
  ...state,
  isUpdating: false,
  concurrent_sessions: payload.concurrent_sessions,
});

const createUserSessionSuccess: AuthReducer = (state = initialState, { payload }) => ({
  ...state,
  concurrent_sessions: payload.concurrent_sessions,
  sub_domain_name: payload.sub_domain_name,
  isUpdating: false,
});

const promptCreateUserSession: AuthReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const createUserSessionFailure: AuthReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
  concurrent_sessions: '',
  // must be empty string for removing infinite loop in componentDidMount
  sub_domain_name: '',
});

const prompStripeConnect: AuthReducer = (state = initialState) => ({
  ...state,
  stripeRequest: true,
});

const stripeConnectSuccess: AuthReducer = (state = initialState) => ({
  ...state,
  stripeRequest: false,
});

const stripeConnectFailure: AuthReducer = (state = initialState) => ({
  ...state,
  stripeRequest: false,
});

const promptSetPaymentProviders: AuthReducer = (state = initialState) => ({
  ...state,
  isFetchingPaymentProviders: true,
});

const setPaymentProvidersSuccess: AuthReducer = (state = initialState, { payload }) => {
  const availableProviders: Array<any> = [];

  Object.keys(payload.payment_methods).forEach((key: any) => {
    const selectedProviders = payload.payment_methods[key].providers.filter((object: any) => object.current === true);
    availableProviders[key] = [...selectedProviders];
  });

  return {
    ...state,
    paymentProviders: { ...availableProviders },
    bankStatementName: payload.bank_statement || '',
    isFetchingPaymentProviders: false,
  };
};

const setPaymentProvidersFailure: AuthReducer = (state = initialState) => ({
  ...state,
  paymentProviders: [],
  isFetchingPaymentProviders: false,
});

const promptSetFees: AuthReducer = (state = initialState) => ({
  ...state,
  getFeesRequest: true,
});

const setFeesSuccess: AuthReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    getFeesRequest: false,
    fees: payload,
  };
};

const setRevenueSetupStart: AuthReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    isFetchingRevenueSetup: true,
  };
};

const setRevenueSetupSuccess: AuthReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    fees: {
      account_revenue_shares: [],
      default_revenue_share: {
        fixed_fee: payload.payment_provider_fixed_fee,
        share_percent: payload.inplayer_share_percent,
        currency: payload.contract_currency,
        psp_share: payload.payment_provider_fee_percent || '',
        psp_fixed_fee: payload.payment_provider_fixed_fee || '',
        minimum_amount: payload.inplayer_share_minimum_amount || '',
      },
    },
    isFetchingRevenueSetup: false,
  };
};

const setFeesFailure: AuthReducer = (state = initialState) => ({
  ...state,
  getFeesRequest: false,
  fees: 'Not Set',
  share: 'Not Set',
});

const setIamTokenSuccess: AuthReducer = (state = initialState, { payload }) => {
  return {
    ...state,
  };
};

const setIamTokenFailure: AuthReducer = (state = initialState) => {
  return {
    ...state,
  };
};

const handleUserLoginSuccess: AuthReducer = (state = initialState, { payload }) => {
  const {
    account: {
      metadata: { social: payloadSocial },
      social_apps_metadata: socialAppsMetadata,
      roles,
    },
  } = payload;

  let social: Record<string, boolean> = {};

  if (payloadSocial) {
    social = payloadSocial.split('|').reduce((acc: Record<string, boolean>, s: string) => ({ ...acc, [s]: true }), {});
  }

  const roleRestrictions = roles.reduce(
    (rules: string[], role: Roles) => [...rules, ...(rolesRestrictionsConfig[role] || [])],
    []
  );

  const isAdmin = roles.includes(Roles.INPLAYER);

  return {
    ...state,
    isAuthenticated: true,
    isAdmin,
    isAuthenticating: false,
    isActive: true,
    isRegistered: true,
    expires: payload.expires,
    token: payload.access_token,
    user: {
      ...payload.account,
      roleRestrictions,
    },
    refresh_token: payload.refresh_token,
    // Refresh payment settings page after follower/master logged in
    paymentProviders: null,
    getFeesRequest: false,
    fees: null,
    share: null,
    social,
    socialAppsMetadata,
    concurrent_sessions: ' ',
    isFetching: false,
    saveOvpError: false,
  };
};

const createOrganisationSuccess: AuthReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    organisationDetails: payload,
    hasOrganisation: true,
  };
};

const getOrganisationMembersRequest: AuthReducer = (state = initialState) => {
  return {
    ...state,
    isFetching: true,
  };
};

const getOrganisationMembersSuccess: AuthReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    isFetching: false,
    organisation: payload,
  };
};

const getOrganisationMembersFailure: AuthReducer = (state = initialState) => {
  return {
    ...state,
    isFetching: false,
  };
};

const removeOrganisationMemberSuccess: AuthReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    organisation: {
      ...state.organisation,
      collection: state.organisation.collection.filter((item: any) => item.account_id !== payload.accountId),
    },
  };
};

const fetchFeaturesRequest: AuthReducer = (state = initialState) => {
  return {
    ...state,
  };
};

const fetchFeaturesSuccess: AuthReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    features: payload,
  };
};

const fetchFeaturesFailure: AuthReducer = (state = initialState) => {
  return {
    ...state,
  };
};

const getOrganisationRequest: AuthReducer = (state = initialState) => {
  return {
    ...state,
    isFetching: true,
  };
};

const getOrganisationSuccess: AuthReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    isFetching: false,
    organisationDetails: payload,
  };
};

const getOrganisationFailure: AuthReducer = (state = initialState) => {
  return {
    ...state,
    isFetching: false,
    hasOrganisation: false,
  };
};

const handleSetTourGuideState: AuthReducer = (state = initialState, { payload }) => {
  const isCloseTourClicked =
    (payload.isRequestCloseClicked && state.tourGuide.isRequestCloseClicked) || state.tourGuide.isCloseTourClicked;

  return {
    ...state,
    tourGuide: {
      ...state.tourGuide,
      isCloseTourClicked,
      ...payload,
    },
  };
};

const actionsMap: ActionMap<AuthState> = {
  [ReloginUserActionTypes.SUCCESS]: reloginUserSuccess,
  [LoginActionTypes.START]: promptUserLogin,
  [LoginActionTypes.SUCCESS]: loginUserSuccess,
  [LoginActionTypes.FAILURE]: userLoginFailure,
  [RegisterUserActionTypes.SUCCESS]: registerUserSuccess,
  [RegisterUserActionTypes.START]: promptRegisterUser,
  [RegisterUserActionTypes.FAILURE]: registerUserFailure,
  [ActivateAccountActionTypes.START]: setIsActivating(true),
  [ActivateAccountActionTypes.SUCCESS]: activateAccountSuccess,
  [ActivateAccountActionTypes.FAILURE]: setIsActivating(false),
  [UserActionTypes.START]: promtUserAction,
  [UserActionTypes.SUCCESS]: userActionSuccess,
  [UserActionTypes.FAILURE]: userActionFailure,
  [SocialAppsActionTypes.SUCCESS]: setSocialAppsSuccess,
  [AddSocialActionTypes.SUCCESS]: addSocialAppSuccess,
  [SocialFieldsActionTypes.SUCCESS]: setSocialFieldsSuccess,
  [LogoutUserActionTypes.SUCCESS]: logoutUserSuccess,
  [ChangePasswordActionTypes.START]: setIsUpdating(true),
  [ChangePasswordActionTypes.SUCCESS]: setIsUpdating(false),
  [ChangePasswordActionTypes.FAILURE]: setIsUpdating(false),
  [ExternalAccountActionTypes.START]: setIsUpdating(true),
  [ExternalAccountActionTypes.SUCCESS]: setIsUpdating(false),
  [ExternalAccountActionTypes.ERROR]: setIsUpdating(false),
  [UpdateUserActionTypes.START]: setIsUpdating(true),
  [UpdateUserActionTypes.SUCCESS]: updateUserSuccess,
  [UpdateUserActionTypes.FAILURE]: setIsUpdating(false),
  [AddSessionActionTypes.START]: setIsUpdating(true),
  [AddSessionActionTypes.SUCCESS]: addSessionSuccess,
  [AddSessionActionTypes.FAILURE]: setIsUpdating(false),
  [SessionUserActionTypes.SUCCESS]: createUserSessionSuccess,
  [SessionUserActionTypes.START]: promptCreateUserSession,
  [SessionUserActionTypes.FAILURE]: createUserSessionFailure,
  [DeleteSessionActionTypes.START]: setIsUpdating(true),
  [DeleteSessionActionTypes.SUCCESS]: setIsUpdating(false),
  [DeleteSessionActionTypes.FAILURE]: setIsUpdating(false),
  /* STRIPE */
  [StripeConnectActionTypes.START]: prompStripeConnect,
  [StripeConnectActionTypes.SUCCESS]: stripeConnectSuccess,
  [StripeConnectActionTypes.FAILURE]: stripeConnectFailure,

  /* PAYMENT PROVIDERS */
  [PaymentProvidersActionTypes.START]: promptSetPaymentProviders,
  [PaymentProvidersActionTypes.SUCCESS]: setPaymentProvidersSuccess,
  [PaymentProvidersActionTypes.FAILURE]: setPaymentProvidersFailure,

  /* PAYMENT FEES */
  [FeesActionTypes.START]: promptSetFees,
  [FeesActionTypes.SUCCESS]: setFeesSuccess,
  [FeesActionTypes.FAILURE]: setFeesFailure,
  [IAMTokenActionTypes.SUCCESS]: setIamTokenSuccess,
  [IAMTokenActionTypes.FAILURE]: setIamTokenFailure,

  [RevenueSetupActionTypes.START]: setRevenueSetupStart,
  [RevenueSetupActionTypes.SUCCESS]: setRevenueSetupSuccess,

  /* AGENCY SETTINGS */
  [LoginAsFollowerActionTypes.SUCCESS]: handleUserLoginSuccess,
  [ReloginMasterActionTypes.SUCCESS]: handleUserLoginSuccess,

  /* ORGANISATION */
  [CreateOrganisationTypes.SUCCESS]: createOrganisationSuccess,

  [GetOrganisationTypes.START]: getOrganisationRequest,
  [GetOrganisationTypes.SUCCESS]: getOrganisationSuccess,
  [GetOrganisationTypes.FAILURE]: getOrganisationFailure,

  [GetOrganisationMembersTypes.START]: getOrganisationMembersRequest,
  [GetOrganisationMembersTypes.SUCCESS]: getOrganisationMembersSuccess,
  [GetOrganisationMembersTypes.FAILURE]: getOrganisationMembersFailure,

  [AddOrganisationMemberTypes.START]: setIsUpdating(true),
  [AddOrganisationMemberTypes.SUCCESS]: setIsUpdating(false),
  [AddOrganisationMemberTypes.FAILURE]: setIsUpdating(false),

  [EditOrganisationMemberTypes.START]: setIsUpdating(true),
  [EditOrganisationMemberTypes.SUCCESS]: setIsUpdating(false),
  [EditOrganisationMemberTypes.FAILURE]: setIsUpdating(false),

  [DeleteOrganisationMemberTypes.SUCCESS]: removeOrganisationMemberSuccess,

  [GetFeaturesTypes.START]: fetchFeaturesRequest,
  [GetFeaturesTypes.SUCCESS]: fetchFeaturesSuccess,
  [GetFeaturesTypes.FAILURE]: fetchFeaturesFailure,

  [SET_TOUR_GUIDE_STATE]: handleSetTourGuideState,
};

export default reducerWithActionMap(actionsMap, initialState);
