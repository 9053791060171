import RootState from 'ducks/RootState';

export const getAdminMerchantState = (state: RootState) => state.merchants;
export const getAdminMerchantDetailsState = (state: RootState) => getAdminMerchantState(state).details.account;
export const getAdminSubscriptionAnalytics = (state: RootState) => getAdminMerchantState(state).analytics.subscriptions;
export const getAdminAccountingAnalytics = (state: RootState) => getAdminMerchantState(state).analytics.accounting;
export const getPaymentSetup = (state: RootState) => getAdminMerchantState(state).paymentSetup;
export const getPaymentFees = (state: RootState) => getAdminMerchantState(state).paymentFees;
export const getMerchantFeatures = (state: RootState) => getAdminMerchantState(state).featureFlags;
export const getIsAgeVerificationEnabled = (state: RootState) => getAdminMerchantState(state).ageVerificationEnabled;
export const getCustomerRevenueSetup = (state: RootState) =>
  getAdminMerchantState(state).analytics.accounting.revenueSetup;
export const getContractCurrency = (state: RootState) =>
  getAdminMerchantState(state).analytics.accounting.revenueSetup.contract_currency;
export const getRecurringFeesState = (state: RootState) =>
  getAdminMerchantState(state).analytics.accounting.recurringFees;
export const getNonRecurringFeesState = (state: RootState) =>
  getAdminMerchantState(state).analytics.accounting.nonRecurringFees;
