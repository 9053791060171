import React, { useState, ReactNode } from 'react';
import { Checkbox } from '@inplayer-org/inplayer-ui';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { AiFillQuestionCircle } from 'react-icons/ai';

// types
import RootState from 'ducks/RootState';
import { TourNames } from './tourGuideConfig/TourGuideTypes';

// components
import TourGuideCloseStep from './TourGuideCloseStep';

export const Wrapper = styled.div<{ isRequestCloseClicked: boolean; isFirstStep: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ${({ isRequestCloseClicked, isFirstStep }) =>
    !isRequestCloseClicked &&
    css`
      min-height: ${isFirstStep ? '250' : '200'}px;
      ${isFirstStep && 'margin-top: 0.5rem;'}
    `}
`;

const MarginWrapper = styled.p`
  margin: 0.75rem 0 1.25rem 0;
`;

interface Props {
  title: string;
  children?: ReactNode;
  isFirstStep?: boolean;
  isLastSectionStep?: boolean;
  sectionLink?: string;
  sectionName?: string;
}

const TourGuideCustomStep = ({ title, children, isFirstStep, isLastSectionStep, sectionLink, sectionName }: Props) => {
  const isRequestCloseClicked = useSelector((state: RootState) => state.auth.tourGuide.isRequestCloseClicked);

  const [isTourGuideDisabled, setIsTourGuideDisabled] = useState(!!localStorage.getItem('tourGuideDisabled'));

  const onCheckboxChange = (value: boolean) => {
    if (value) {
      localStorage.setItem('tourGuideDisabled', 'true');
    } else {
      localStorage.removeItem('tourGuideDisabled');
    }
    setIsTourGuideDisabled(value);
  };

  return (
    <Wrapper isRequestCloseClicked={isRequestCloseClicked} isFirstStep={!!isFirstStep}>
      {isRequestCloseClicked ? (
        <TourGuideCloseStep />
      ) : (
        <>
          <h2>{title}</h2>
          <p>{children}</p>
          {isFirstStep && (
            <div>
              <p>
                Here you will get to know the various sections of the InPlayer Dashboard. You can retake this tour at
                any time by clicking the InPlayer logo at the top left.
              </p>
              <MarginWrapper>
                To see a tour of each section individually, you can click the <AiFillQuestionCircle /> button at the top
                right of each section.
              </MarginWrapper>
              <Checkbox
                tag="checbox_dont_show_again"
                checked={isTourGuideDisabled}
                name="isTourGuideDisabled"
                id="isTourGuideDisabled"
                label="Don't show this again!"
                onChange={onCheckboxChange}
              />
            </div>
          )}
          {isLastSectionStep && (
            <>
              {sectionName !== TourNames.Dashboard && (
                <p>
                  You should now be more familiar with the {sectionName} section. If you need to take the tour again,
                  just click the <AiFillQuestionCircle /> button at the top right.
                </p>
              )}
              <p>
                To find more detailed info about the {sectionName}, take a look at our{' '}
                {/*  Using target="_blank" without rel="noopener noreferrer" is a security risk */}
                <a href={sectionLink} target="_blank" rel="noopener noreferrer">
                  InPlayer Handbook
                </a>
                .
              </p>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default TourGuideCustomStep;
