import React, { PureComponent, ReactNode } from 'react';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Redirect } from 'react-router-dom';

// actions
import { activateUser as activateUserAction } from 'ducks/merchantDucks/user/actions';

// components
import ActivateAccountForm, {
  ActivationCodeFormValues,
} from 'pages/MerchantPages/Authentication/ActivateAccount/forms/ActivateAccount';
import Loader from 'components/Loader';

// types
import RootState from 'ducks/RootState';
import { AuthState } from 'ducks/merchantDucks/user/types';

interface OwnProps {
  redirectIfActive: (data: any) => any;
}

interface DispatchProps {
  activateUser: (code: string) => any;
}

interface StateProps {
  auth: AuthState;
}

export type Props = OwnProps & StateProps & DispatchProps;

export class ActivateAccount extends PureComponent<Props> {
  onActivateUser = (data: ActivationCodeFormValues) => {
    const { activateUser } = this.props;
    activateUser(data.activationCode);
  };

  render(): ReactNode {
    const {
      auth: { isActivating, isAuthenticated, isActive },
    } = this.props;

    if (isAuthenticated) {
      return <Redirect to="dashboard" />;
    }

    if (isActive) {
      return <Redirect to="activation-message" />;
    }

    if (isActivating) {
      return <Loader />;
    }

    return <ActivateAccountForm onSubmit={this.onActivateUser} {...this.props} />;
  }
}

export const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  activateUser: activateUserAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);
