import React from 'react';
import styled from 'styled-components';
import { Pagination } from '@inplayer-org/inplayer-ui';

interface Props {
  totalData: number;
  paginationPage: number;
  handlePageChange: (page: number) => void;
  numberOfPagesDisplayed: number;
  limit: number;
}

export const PaginationContainer = styled.div`
  margin: 1.5rem;
`;

const AssetsPagination: React.FC<Props> = ({
  totalData,
  paginationPage,
  handlePageChange,
  numberOfPagesDisplayed,
  limit,
}) => {
  return (
    <PaginationContainer>
      <Pagination
        totalItems={totalData}
        startPage={paginationPage}
        onPageChange={handlePageChange}
        numberOfPagesDisplayed={numberOfPagesDisplayed}
        itemsPerPage={limit}
      />
    </PaginationContainer>
  );
};

export default AssetsPagination;
