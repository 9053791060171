import React, { FunctionComponent } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

// Images
import livestream from 'assets/pics/integrations/livestream-logo.png';
import brightcove from 'assets/pics/integrations/brightcove-logo.png';
import kaltura from 'assets/pics/integrations/kaltura-logo.png';
import jwPlayer from 'assets/pics/integrations/jwplayer-logo.png';
import qBrick from 'assets/pics/integrations/qbrick-logo.png';
import ooyala from 'assets/pics/integrations/ooyala-logo.png';
import panopto from 'assets/pics/integrations/panopto-logo.png';
import dacast from 'assets/pics/integrations/dacast-logo.png';
import cloudfront from 'assets/pics/integrations/cloudfront-logo.png';
import sportradar from 'assets/pics/integrations/sportradar-logo.png';
import sportone from 'assets/pics/integrations/sportone-logo.jpg';
import streamAMG from 'assets/pics/integrations/streamamg-logo.png';
import flowplayer from 'assets/pics/integrations/flowplayer-logo.png';
import ivs from 'assets/pics/integrations/ivs-logo.png';

// Components
import OvpContainer from 'components/OvpContainer';
import OvpCard from 'components/OvpCard';
import ContentHolder from 'components/ContentHolder';
import SubheaderWithBorder from 'components/SubheaderWithBorder';
import TourGuideWrapper from 'components/TourGuide';
import { BlueTypography } from 'components/SharedStyledElements';
import { AnalyticsComponent } from '@inplayer-org/inplayer-ui';

const ovpContent = (url: string) => [
  {
    url: `${url}/livestream`,
    logo: livestream,
    tag: 'livestream',
  },
  {
    url: `${url}/brightcove`,
    logo: brightcove,
    tag: 'brightcove',
  },
  {
    url: `${url}/kaltura`,
    logo: kaltura,
    tag: 'kaltura',
  },
  {
    url: `${url}/jwplayer`,
    logo: jwPlayer,
    tag: 'jwplayer',
  },
  {
    url: `${url}/qbrick`,
    logo: qBrick,
    tag: 'qbrick',
  },
  {
    url: `${url}/ooyala`,
    logo: ooyala,
    tag: 'ooyala',
  },
  {
    url: `${url}/panopto`,
    logo: panopto,
    tag: 'panopto',
  },
  {
    url: `${url}/dacast`,
    logo: dacast,
    tag: 'dacast',
  },
  {
    url: `${url}/cloudfront`,
    logo: cloudfront,
    tag: 'cloudfront',
  },
  {
    url: `${url}/sportradar`,
    logo: sportradar,
    tag: 'sportradar',
  },
  {
    url: `${url}/sport1`,
    logo: sportone,
    tag: 'sport1',
  },
  {
    url: `${url}/streamamg`,
    logo: streamAMG,
    tag: 'streamamg',
  },
  {
    url: `${url}/flowplayer`,
    logo: flowplayer,
    tag: 'flowplayer',
  },
  {
    url: `${url}/ivs`,
    logo: ivs,
  },
];

export const Container = styled.div`
  width: 100%;
  padding: 2.5rem;
`;

const OvpIntegration: FunctionComponent<{}> = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <TourGuideWrapper withSideNav />
      <SubheaderWithBorder>
        <ContentHolder>
          <BlueTypography variant="h1">Online Video Platform integrations</BlueTypography>
        </ContentHolder>
      </SubheaderWithBorder>
      <Container>
        <OvpContainer>
          {ovpContent(path).map(({ url: ovpUrl, logo, tag }) => (
            <AnalyticsComponent key={tag}>
              {({ pages, tracker, merchantId, ip }) => (
                <NavLink
                  onClick={() =>
                    tracker.track({
                      event: 'click',
                      type: 'box',
                      tag: `box_${tag}`,
                      pages,
                      merchantId,
                      ip,
                    })
                  }
                  key={ovpUrl}
                  to={ovpUrl}
                >
                  <OvpCard tag={tag} isOvpIntegration logo={logo} />
                </NavLink>
              )}
            </AnalyticsComponent>
          ))}
        </OvpContainer>
      </Container>
    </>
  );
};

export default OvpIntegration;
