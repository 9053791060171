import React from 'react';
import { History } from 'history';

// components
import TourGuideCustomStep from 'components/TourGuide/TourGuideCustomStep';
import { Feature, Roles } from 'ducks/merchantDucks/user/types';
import TourGuideStep from '../TourGuideStep';

// types
import { TourTypes, TourNames, ArrowType } from './TourGuideTypes';

import {
  assetsTourConfig,
  analyticsTourConfig,
  accessTourConfig,
  settingsTourTourConfig,
  paymentsTourConfig,
  audienceTourConfig,
  accountDetailsConfig,
  vouchersTourConfig,
} from './tourGuideSectionConfig';
import { TourStep } from '../TourGuide';

export const getSectionName = (name: string) => {
  switch (name) {
    case TourTypes.Analytics:
      return TourNames.Analytics;
    case TourTypes.Assets:
      return TourNames.Assets;
    case TourTypes.Packages:
      return TourNames.Assets;
    case TourTypes.Vouchers:
      return TourNames.Vouchers;
    case TourTypes.InAppVouchers:
      return TourNames.Vouchers;
    case TourTypes.GiftVouchers:
      return TourNames.Vouchers;
    case TourTypes.SegmentationReports:
      return TourNames.Audience;
    case TourTypes.Segmentation:
      return TourNames.Audience;
    case TourTypes.Audience:
      return TourNames.Audience;
    case TourTypes.AudienceReports:
      return TourNames.Audience;
    case TourTypes.Payments:
      return TourNames.Payments;
    case TourTypes.Subscriptions:
      return TourNames.Payments;
    case TourTypes.Access:
      return TourNames.Access;
    case TourTypes.AccessReports:
      return TourNames.Access;
    case TourTypes.Auth:
      return TourNames.Settings;
    case TourTypes.OAuth:
      return TourNames.Settings;
    case TourTypes.ConcurrentSessions:
      return TourNames.Settings;
    case TourTypes.GeoRestrictions:
      return TourNames.Settings;
    case TourTypes.DomainRestrictions:
      return TourNames.Settings;
    case TourTypes.CustomFields:
      return TourNames.Settings;
    case TourTypes.AgeRestrictions:
      return TourNames.Settings;
    case TourTypes.Branding:
      return TourNames.Settings;
    case TourTypes.Integrations:
      return TourNames.Settings;
    case TourTypes.InAppIntegrations:
      return TourNames.Settings;
    case TourTypes.ThirdPartyIntegrations:
      return TourNames.Settings;
    case TourTypes.AccountDetails:
      return TourNames.AccountDetails;
    case TourTypes.PasswordManagement:
      return TourNames.AccountDetails;
    case TourTypes.InPlayerRelate:
      return TourNames.AccountDetails;
    default:
      return TourNames.Analytics;
  }
};

export const goToSection = (history: History, location: string, path: string) => () => {
  if (location !== path) history.push(path);
};

export const getSectionSteps = (sectionConfig: Array<any>) => {
  return sectionConfig.slice(1, -1);
};

const filterStepsByRestrictionFeature = (steps: Array<TourStep>, features: Array<Feature>, userRoles: Roles[]) => {
  const filteredSteps: Array<TourStep> = [];

  steps.forEach((step) => {
    if (step.restrictForRole) {
      let stepShouldBeRemoved = false;
      step.restrictForRole.forEach((restrictedRole: string) => {
        if (userRoles.includes(restrictedRole as Roles)) {
          stepShouldBeRemoved = true;
        }
      });
      if (!stepShouldBeRemoved) filteredSteps.push(step);
    } else if (
      !step.restrictionFeature ||
      (step.restrictionFeature && features.find((feature) => feature.name === step.restrictionFeature)?.enabled)
    ) {
      filteredSteps.push(step);
    }
  });

  return filteredSteps;
};

const sharedStyle = {
  minWidth: '600px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const getAllTourSteps: Function = (
  history: History,
  location: string,
  features: Array<Feature>,
  userRoles: Roles[]
) => {
  const stepsArray = [
    {
      selector: '[data-tut="reactour__analytics-section"]',
      content: () => {
        return <TourGuideCustomStep isFirstStep title="Welcome to the InPlayer Dashboard Guided Tour!" />;
      },
      style: sharedStyle,
      location: TourTypes.Analytics,
      action: goToSection(history, location, TourTypes.Analytics),
      restrictForRole: [Roles.CONTENT_MANAGER, Roles.CUSTOMER_SUPPORT],
    },
    {
      selector: '[data-tut="reactour__tab-analytics"]',
      content: (
        <TourGuideStep arrowType={ArrowType.left}>
          Analytics shows an overview of your account’s payments, customers, subscriptions and more.
        </TourGuideStep>
      ),
      restrictForRole: [Roles.CONTENT_MANAGER],
    },
    ...getSectionSteps(analyticsTourConfig(history, location)),
    {
      selector: '[data-tut="reactour__tab-content"]',
      content: (
        <TourGuideStep arrowType={ArrowType.left}>
          Content is where you’ll be setting up all the content you’ll be monetizing. This is where most of the work
          happens.
        </TourGuideStep>
      ),
      location: TourTypes.Assets,
      action: goToSection(history, location, TourTypes.Assets),
      restrictForRole: [Roles.ANALYST],
    },
    ...getSectionSteps(assetsTourConfig(history, location)),
    {
      selector: '[data-tut="reactour__tab-promotions"]',
      content: (
        <TourGuideStep arrowType={ArrowType.left}>
          In the Vouchers tab, you can create codes your customers can use to get discounts on payments or free access
          to content.
        </TourGuideStep>
      ),
      location: TourTypes.Vouchers,
      action: goToSection(history, location, TourTypes.Vouchers),
      restrictForRole: [Roles.ANALYST],
    },
    ...getSectionSteps(vouchersTourConfig(history, location)),
    {
      selector: '[data-tut="reactour__tab-audience"]',
      content: (
        <TourGuideStep arrowType={ArrowType.left}>
          Audience is where you can manage and review customer accounts.
        </TourGuideStep>
      ),
      location: TourTypes.Audience,
      action: goToSection(history, location, TourTypes.Audience),
      restrictForRole: [Roles.CONTENT_MANAGER],
    },
    ...getSectionSteps(audienceTourConfig(history, location)),
    {
      selector: '[data-tut="reactour__tab-transactions"]',
      content: (
        <TourGuideStep arrowType={ArrowType.left}>
          Transactions is where you can manage and review customer payments and subscriptions.
        </TourGuideStep>
      ),
      location: TourTypes.Payments,
      action: goToSection(history, location, TourTypes.Payments),
      restrictForRole: [Roles.CONTENT_MANAGER],
    },
    ...getSectionSteps(paymentsTourConfig(history, location)),
    {
      selector: '[data-tut="reactour__tab-access"]',
      content: (
        <TourGuideStep arrowType={ArrowType.left}>Access shows you which customer has access to what.</TourGuideStep>
      ),
      location: TourTypes.Access,
      action: goToSection(history, location, TourTypes.Access),
      restrictForRole: [Roles.CONTENT_MANAGER],
    },
    ...getSectionSteps(accessTourConfig(history, location)),
    {
      selector: '[data-tut="reactour__tab-settings"]',
      content: (
        <TourGuideStep arrowType={ArrowType.left}>
          Settings is where you set up the various InPlayer Dashboard settings. This is an important section loaded with
          many awesome features, so make sure to have a look around!
        </TourGuideStep>
      ),
      location: TourTypes.Auth,
      action: goToSection(history, location, TourTypes.Auth),
      restrictForRole: [Roles.ANALYST],
    },
    ...getSectionSteps(settingsTourTourConfig(history, location)),
    {
      selector: '[id="user-menu"]',
      content: (
        <TourGuideStep arrowType={ArrowType.right}>
          The account management menu contains all the settings related to your InPlayer account.
        </TourGuideStep>
      ),
      position: 'left',
    },
    ...getSectionSteps(accountDetailsConfig(history, location)),
    {
      selector: '[id="billing & plan"]',
      content: (
        <TourGuideStep arrowType={ArrowType.top}>
          In Billing & plan you can review the financial details like the fees and InPlayer revenue share deducted from
          each customer payment, as well as connect your own Stripe account, enabling you to receive all your revenue on
          your own bank account in real time.
        </TourGuideStep>
      ),
      location: TourTypes.Billing,
      action: goToSection(history, location, TourTypes.Billing),
      position: 'bottom',
      restrictForRole: [Roles.ANALYST, Roles.CONTENT_MANAGER, Roles.CUSTOMER_SUPPORT],
    },
    {
      selector: '[id="webhooks"]',
      content: (
        <TourGuideStep arrowType={ArrowType.top}>
          In Webhooks you can set up webhook notifications that will be sent to your app whenever certain customer
          actions occur on the paywall.
        </TourGuideStep>
      ),
      location: TourTypes.Webhooks,
      action: goToSection(history, location, TourTypes.Webhooks),
      position: 'bottom',
      restrictForRole: [Roles.ANALYST, Roles.CONTENT_MANAGER],
    },
    {
      content: () => {
        return (
          <TourGuideCustomStep
            title="That’s it! You have completed the tour!"
            isLastSectionStep
            sectionName={TourNames.Dashboard}
            sectionLink="https://inplayer.com/docs/"
          >
            You should now feel more at home when navigating through the InPlayer Dashboard. If you need to take the
            tour again, just click the InPlayer logo at the top left.
          </TourGuideCustomStep>
        );
      },
      style: sharedStyle,
    },
  ];

  return filterStepsByRestrictionFeature(stepsArray, features, userRoles);
};

const getSectionTourConfig = (history: History, location: string) => {
  switch (location) {
    case TourTypes.Analytics:
      return analyticsTourConfig(history, location);
    case TourTypes.Assets:
      return assetsTourConfig(history, location);
    case TourTypes.Packages:
      return assetsTourConfig(history, location);
    case TourTypes.Vouchers:
      return vouchersTourConfig(history, location);
    case TourTypes.InAppVouchers:
      return vouchersTourConfig(history, location);
    case TourTypes.GiftVouchers:
      return vouchersTourConfig(history, location);
    case TourTypes.SegmentationReports:
      return audienceTourConfig(history, location);
    case TourTypes.Segmentation:
      return audienceTourConfig(history, location);
    case TourTypes.Audience:
      return audienceTourConfig(history, location);
    case TourTypes.AudienceReports:
      return audienceTourConfig(history, location);
    case TourTypes.Payments:
      return paymentsTourConfig(history, location);
    case TourTypes.Subscriptions:
      return paymentsTourConfig(history, location);
    case TourTypes.Access:
      return accessTourConfig(history, location);
    case TourTypes.AccessReports:
      return accessTourConfig(history, location);
    case TourTypes.Auth:
      return settingsTourTourConfig(history, location);
    case TourTypes.OAuth:
      return settingsTourTourConfig(history, location);
    case TourTypes.ConcurrentSessions:
      return settingsTourTourConfig(history, location);
    case TourTypes.GeoRestrictions:
      return settingsTourTourConfig(history, location);
    case TourTypes.DomainRestrictions:
      return settingsTourTourConfig(history, location);
    case TourTypes.CustomFields:
      return settingsTourTourConfig(history, location);
    case TourTypes.AgeRestrictions:
      return settingsTourTourConfig(history, location);
    case TourTypes.Branding:
      return settingsTourTourConfig(history, location);
    case TourTypes.Integrations:
      return settingsTourTourConfig(history, location);
    case TourTypes.InAppIntegrations:
      return settingsTourTourConfig(history, location);
    case TourTypes.ThirdPartyIntegrations:
      return settingsTourTourConfig(history, location);
    case TourTypes.AccountDetails:
      return accountDetailsConfig(history, location);
    case TourTypes.PasswordManagement:
      return accountDetailsConfig(history, location);
    case TourTypes.InPlayerRelate:
      return accountDetailsConfig(history, location);
    default:
      return getAllTourSteps(history, location);
  }
};

export const getSectionTourSteps = (
  history: History,
  location: string,
  features: Array<Feature>,
  userRoles: Roles[]
) => {
  return filterStepsByRestrictionFeature(getSectionTourConfig(history, location), features, userRoles);
};
