import { formatDate } from 'utils/formatDate';

export const downloadFile = (url: string, name: string) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const mapDataWithNewKey = (data: Array<Record<string, any>>, targetKey: string, newKey: string) => {
  return data?.map((file: Record<string, any>) => {
    const newFile = { ...file, [newKey]: formatDate(file[targetKey], 'YYYY/MM/DD HH:mm') };
    delete newFile[targetKey];

    return newFile;
  });
};

export const reverseKeyValues = (obj: Record<string, any>) =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k]));
