import React, { useMemo, useState, useEffect } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Colors, Button, Loader, Option } from '@inplayer-org/inplayer-ui';

// images
import defaultPreviewImg from 'assets/pics/ip-preview-premium.jpg';
import packagePreviewImg from 'assets/pics/package_def.jpg';

// utils
import { Optional } from 'utils/types';

// types
import { ItemType } from 'ducks/merchantDucks/items/actions';

// styled components
import { BlueTypography } from 'components/SharedStyledElements';

// components
import Filter from 'components/Filter';
import VoucherItem from './VoucherItem';

const Container = styled.div`
  width: 48%;
  height: 100%;
`;

const DroppableContainer = styled.div`
  padding: 1rem;
  border: 1px solid ${Colors.fontLightGray};
  border-radius: 0.3rem;
  width: 100%;
  height: 75%;
  overflow: scroll;
  margin-top: 1rem;
`;

const StyledTypography = styled(BlueTypography)`
  margin-bottom: 10px;
`;

const LoaderContainer = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin: 1rem 0;
`;

interface SearchParams {
  id: Optional<number>;
  title: string;
}

interface OwnProps {
  droppableId: string;
  items: Array<any>;
  title: string;
  buttonText?: string;
  buttonAction: (droppableId: string) => void;
  isLoaderVisible: boolean;
  totalItems: number;
  isReadOnly?: boolean;
  showButton: boolean;
  handleAddition: (searchParams: any) => void;
  filter: SearchParams;
  clearFilter: (filter: any) => void;
  displayIcon: boolean;
  displaySearchInput: boolean;
  showCustomButton?: boolean;
  customButtonText?: string;
  customButtonAction?: () => void;
  ruleType?: string;
  displayAccessFeeSection?: boolean;
}

type Props = OwnProps;

const VoucherDroppableColumn = ({
  droppableId,
  items,
  title,
  buttonText,
  buttonAction,
  isLoaderVisible,
  totalItems,
  isReadOnly = false,
  showButton = true,
  displayIcon = false,
  displaySearchInput = true,
  handleAddition,
  filter,
  clearFilter,
  showCustomButton = false,
  customButtonText,
  customButtonAction,
  ruleType,
  displayAccessFeeSection = false,
}: Props) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const lowercasedTitle = title.toLowerCase();

  const suggestions: Array<Option> = [
    { value: 'id', displayName: 'ID' },
    { value: 'title', displayName: 'Name' },
  ];

  useEffect(() => {
    setSelectedItem(0);
  }, [items]);

  // Rerender these items only if the list of items changes
  const itemsForRender = useMemo(() => {
    return items.map((item, index) => {
      const previewImage = item.item_type?.name === ItemType.Package ? packagePreviewImg : defaultPreviewImg;
      const image = displayIcon
        ? item.iconName
        : item.metadata?.paywall_cover_photo || item.metadata?.thumbnail_image || previewImage;

      return (
        <Draggable
          key={item.id}
          draggableId={String(item.id)}
          index={index}
          isDragDisabled={isReadOnly || selectedItem !== 0}
        >
          {(providedDraggable, snapshot) => (
            <VoucherItem
              ref={providedDraggable.innerRef}
              {...providedDraggable.draggableProps}
              {...providedDraggable.dragHandleProps}
              title={item.title}
              image={image}
              displayIcon={displayIcon}
              displayAccessFeeSection={displayAccessFeeSection}
              itemId={item.id}
              ruleType={ruleType}
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
              isDragging={snapshot.isDragging}
              isFirstItem={index === 0}
            />
          )}
        </Draggable>
      );
    });
  }, [items, selectedItem]);

  const handleButtonAction = () => {
    buttonAction(droppableId);
  };

  return (
    <Container>
      <StyledTypography variant="h6">{title}</StyledTypography>
      {displaySearchInput && (
        <Filter
          inputTag={`text_search_${lowercasedTitle}_${ruleType}`}
          dropdownTag={`dropdown_${lowercasedTitle}_${ruleType}`}
          options={suggestions}
          onSearch={handleAddition}
          externalFilters={filter}
          clearExternalFilter={clearFilter}
        />
      )}
      <Droppable droppableId={droppableId} isDropDisabled={isReadOnly}>
        {(provided) => (
          <DroppableContainer ref={provided.innerRef} {...provided.droppableProps}>
            {isLoaderVisible ? (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            ) : (
              itemsForRender
            )}
            {provided.placeholder}
          </DroppableContainer>
        )}
      </Droppable>
      {items.length > 0 && showButton ? (
        <>
          <StyledButton
            tag={`button_load_${lowercasedTitle}`}
            onClick={handleButtonAction}
            fullWidth
            type="button"
            disabled={isReadOnly || totalItems <= items.length}
          >
            {buttonText}
          </StyledButton>
          {showCustomButton && (
            <StyledButton onClick={customButtonAction} fullWidth type="button">
              {customButtonText}
            </StyledButton>
          )}
        </>
      ) : null}
    </Container>
  );
};

export default VoucherDroppableColumn;
