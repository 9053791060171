import React, { memo, ReactNode, ComponentType } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';

// hooks
import useNavRoutes from 'restrictions/roles/hooks/useNavRoutes';

interface Props {
  exact?: boolean;
  path: string;
  children?: ReactNode;
  component?: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
}

const RoleRestrictedRoute = ({ exact = false, path, children, ...rest }: Props) => {
  const [isRestricted, redirectTo] = useNavRoutes(path);

  if (isRestricted) {
    return <Redirect to={redirectTo || '/404.html'} />;
  }

  return (
    <Route exact={exact} path={path} {...rest}>
      {children}
    </Route>
  );
};

export default memo(RoleRestrictedRoute);
