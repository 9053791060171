import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import { ModalActionTypes } from 'ducks/merchantDucks/modal/actions';
import { ModalState, ModalNames } from 'ducks/merchantDucks/modal/types';

export const initialState: ModalState = {
  activeModalName: ModalNames.None,
  activeModalInstance: '',
};

const openModal: Reducer<ModalState> = (state, { payload: { modalName, instance = '' } }) => ({
  ...state,
  activeModalName: modalName,
  activeModalInstance: instance,
});

const closeModal: Reducer<ModalState> = () => ({
  ...initialState,
});

const actionsMap: ActionMap<ModalState> = {
  [ModalActionTypes.OPEN]: openModal,
  [ModalActionTypes.CLOSE]: closeModal,
};

export default reducerWithActionMap(actionsMap, initialState);
