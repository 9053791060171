import reduce from 'lodash/reduce';
import param from 'jquery-param';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';

// utils
import { API_ROOT } from 'constants/index';
import TokenManager from './TokenManager';

export const api = axios.create({
  baseURL: API_ROOT,
});

interface PathVariables extends Object {
  [key: string]: any;
}

export const replacePathVariables = (url: string, pathVariables: PathVariables): string =>
  reduce(
    url.replace(/^(\/)/, '').split('/'),
    (newUrl, urlChunk) => {
      if (urlChunk[0] !== '{') {
        return `${newUrl}/${urlChunk}`;
      }

      const closingBraceIndex = urlChunk.indexOf('}');
      const pathVariableName = urlChunk.slice(1, closingBraceIndex);
      if (!pathVariables[pathVariableName] || !Object.prototype.hasOwnProperty.call(pathVariables, pathVariableName)) {
        throw new Error('Path variable value not included');
      }
      return `${newUrl}/${urlChunk.replace(`{${pathVariableName}}`, pathVariables[pathVariableName])}`;
    },
    ''
  );

export const isAxiosError = (error: Error): error is AxiosError => 'response' in error;

interface RequestConfig extends AxiosRequestConfig {
  pathVariables?: PathVariables;
}

const httpGet = (url: string, { pathVariables, ...rest }: RequestConfig = { pathVariables: {} }) =>
  api.get(replacePathVariables(url, pathVariables as PathVariables), rest);

export enum ContentType {
  JSON = 'application/json',
  URLENCODED = 'application/x-www-form-urlencoded',
  FORM_DATA = 'multipart/form-data',
  TEXT_CSV = 'text/csv; charset=utf-8',
}

interface PostRequestConfig extends RequestConfig {
  contentType: ContentType;
}

const httpPost = (url: string, { pathVariables = {}, contentType, headers, data, ...rest }: PostRequestConfig) => {
  const headersWithContentType = {
    ...headers,
    'Content-Type': contentType,
  };

  const formattedData = contentType === ContentType.URLENCODED ? param(data) : data;

  return api.post(replacePathVariables(url, pathVariables), formattedData, {
    ...rest,
    headers: headersWithContentType,
  });
};

type PutRequestConfig = PostRequestConfig;

const httpPut = (url: string, { pathVariables = {}, contentType, headers, data, ...rest }: PutRequestConfig) => {
  const headersWithContentType = {
    ...headers,
    'Content-Type': contentType,
  };

  const formattedData = contentType === ContentType.URLENCODED ? param(data) : data;

  return api.put(replacePathVariables(url, pathVariables), formattedData, {
    ...rest,
    headers: headersWithContentType,
  });
};

const httpPatch = (url: string, { pathVariables = {}, contentType, headers, data, ...rest }: PutRequestConfig) => {
  const headersWithContentType = {
    ...headers,
    'Content-Type': contentType,
  };

  const formattedData = contentType === ContentType.URLENCODED ? param(data) : data;

  return api.patch(replacePathVariables(url, pathVariables), formattedData, {
    ...rest,
    headers: headersWithContentType,
  });
};

type DeleteRequestConfig = PostRequestConfig;

const httpDelete = (url: string, { pathVariables = {}, contentType, headers, data, ...rest }: DeleteRequestConfig) => {
  const headersWithContentType = {
    ...headers,
    'Content-Type': contentType,
  };

  const formattedData = contentType === ContentType.URLENCODED ? param(data) : data;

  return api.delete(replacePathVariables(url, pathVariables), {
    ...rest,
    data: formattedData,
    headers: headersWithContentType,
  });
};

export const isUserAdmin = () => {
  const {
    location: { pathname },
  } = window;

  return pathname.includes('/admin');
};

const authenticatedGet = (url: string, { headers, ...rest }: RequestConfig = { headers: {} }) => {
  const authenticatedHeaders = {
    ...headers,
    Authorization: `Bearer ${isUserAdmin() ? TokenManager.admin?.adminToken : TokenManager.token}`,
  };

  // AWS is caching all the GET endpoints, srkey is a random query param to evade this
  const restParams = { ...rest, params: { ...rest.params, srkey: Math.random() } };

  return httpGet(url, { headers: authenticatedHeaders, ...restParams });
};

const authenticatedPost = (url: string, { headers, ...rest }: PostRequestConfig) => {
  const authenticatedHeaders = {
    ...headers,
    Authorization: `Bearer ${isUserAdmin() ? TokenManager.admin?.adminToken : TokenManager.token}`,
  };

  return httpPost(url, { headers: authenticatedHeaders, ...rest });
};

const authenticatedPut = (url: string, { headers, ...rest }: PutRequestConfig) => {
  const authenticatedHeaders = {
    ...headers,
    Authorization: `Bearer ${isUserAdmin() ? TokenManager.admin?.adminToken : TokenManager.token}`,
  };

  return httpPut(url, { headers: authenticatedHeaders, ...rest });
};

const authenticatedDelete = (url: string, { headers, ...rest }: DeleteRequestConfig) => {
  const authenticatedHeaders = {
    ...headers,
    Authorization: `Bearer ${isUserAdmin() ? TokenManager.admin?.adminToken : TokenManager.token}`,
  };

  return httpDelete(url, { headers: authenticatedHeaders, ...rest });
};

const authenticatedPatch = (url: string, { headers, ...rest }: DeleteRequestConfig) => {
  const authenticatedHeaders = {
    ...headers,
    Authorization: `Bearer ${isUserAdmin() ? TokenManager.admin?.adminToken : TokenManager.token}`,
  };

  return httpPatch(url, { headers: authenticatedHeaders, ...rest });
};

const authenticated = () => ({
  get: authenticatedGet,
  post: authenticatedPost,
  put: authenticatedPut,
  delete: authenticatedDelete,
  patch: authenticatedPatch,
});

export default {
  get: httpGet,
  post: httpPost,
  put: httpPut,
  delete: httpDelete,
  patch: httpPatch,
  authenticated,
};
