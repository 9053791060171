import React, { FC } from 'react';

// components
import { Switcher, Switchee } from 'components/Switcher';

// local components
import Intro from './Intro';
import ConnectExistingAccount from './ConnectExistingAccount';

export enum SwitchableStates {
  INTRO = 'intro',
  CONNECT_EXISTING_ACCOUNT = 'connect-existing-account',
}

const MainContainer: FC = () => (
  <Switcher initialValue={SwitchableStates.INTRO}>
    <Switchee value={SwitchableStates.INTRO}>
      <Intro />
    </Switchee>
    <Switchee value={SwitchableStates.CONNECT_EXISTING_ACCOUNT}>
      <ConnectExistingAccount />
    </Switchee>
  </Switcher>
);

export default MainContainer;
