import React, { FunctionComponent } from 'react';
import { object, string } from 'yup';
import { Formik, FormikHelpers, Field, FormikProps } from 'formik';
import { Grid } from '@inplayer-org/inplayer-ui';

// Components
import FormikPersist from 'components/FormikPersist';
import FieldError from 'components/Fields/FieldError';
import Input from 'pages/MerchantPages/Authentication/components/Input';
import Button from 'pages/MerchantPages/Authentication/components/Button';
import Header from 'pages/MerchantPages/Authentication/components/Header';
import FormContainer from 'pages/MerchantPages/Authentication/components/FormContainer';
import { StyledNavLink, ButtonWrapper } from 'pages/MerchantPages/Authentication/components/sharedComponents';

const { Cell, Container } = Grid;

interface FormValues {
  email: string;
  password: string;
}
interface OwnProps {
  onSubmit: (data: FormValues) => any;
}

const validationSchema = object().shape({
  email: string().email('Please enter a valid email address').required('*Required'),
  password: string().min(8).required('*Required'),
});

type Props = OwnProps;

const LoginForm: FunctionComponent<Props> = ({ onSubmit }) => {
  const initialValues = {
    email: '',
    password: '',
  };

  const onFormSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    await onSubmit(values);
    setSubmitting(false);
  };

  return (
    <Formik onSubmit={onFormSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ handleSubmit, isSubmitting }: FormikProps<FormValues>) => (
        <>
          <Header />
          <FormContainer title="Welcome!" description="Please login to your account.">
            <form onSubmit={handleSubmit}>
              <Container alignContent="space-around" columns={1} gap="1em">
                <Cell>
                  <Field name="email" type="email" component={Input} placeholder="Email" />
                  <FieldError name="email" />
                </Cell>
                <Cell>
                  <Field name="password" type="password" component={Input} placeholder="Password" />
                  <FieldError name="password" />
                </Cell>

                <ButtonWrapper textAlign="center">
                  <Button
                    buttonModifiers={['buttonPrimary']}
                    type="submit"
                    disabled={isSubmitting}
                    padding="1rem 2.75rem"
                  >
                    Login
                  </Button>
                </ButtonWrapper>
              </Container>
            </form>
            <Cell center>
              <StyledNavLink to="/forgot-password">Forgot your password?</StyledNavLink>
            </Cell>
            <FormikPersist name="login-form" exclusions={['password']} />
          </FormContainer>
        </>
      )}
    </Formik>
  );
};

export default LoginForm;
