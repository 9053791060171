import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

// constants
import { API } from 'constants/api';

// utils;
import http, { ContentType } from 'utils/http';

// actions
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';
import { fetchAdminMailTemplates } from 'ducks/adminDucks/paginations/actions';

// types
import { accessTypeNames } from 'components/PaywallPreviews/PaywallElements/PriceCard';
import RootState from 'ducks/RootState';
import { RevenueSharesItem, Period } from 'ducks/adminDucks/merchants/types';
import { Roles } from 'ducks/merchantDucks/user/types';
import moment from 'moment';

/* Fetch merchant details */
export enum FetchAdminMerchantDetailsActionTypes {
  START = 'MERCHANT_DETAILS_REQUEST',
  SUCCESS = 'MERCHANT_DETAILS_SUCCESS',
  ERROR = 'MERCHANT_DETAILS_FAILURE',
  RESET = 'RESET_MERCHANT_DETAILS_STATE',
}

type FetchAdminMerchantDetailsAction = Action<FetchAdminMerchantDetailsActionTypes>;

export const fetchAdminMerchantDetails = (
  id: number | string
): ThunkAction<Promise<any>, RootState, any, FetchAdminMerchantDetailsAction> => async (dispatch) => {
  const endpoint = API.FETCH_MERCHANT_DETAILS;

  try {
    dispatch({ type: FetchAdminMerchantDetailsActionTypes.START });
    const response = await http.authenticated().get(endpoint, { pathVariables: { id } });
    dispatch({ type: FetchAdminMerchantDetailsActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    dispatch({ type: FetchAdminMerchantDetailsActionTypes.ERROR });
  }
};

export const resetAdminMerchantDetailsState = (): Action<FetchAdminMerchantDetailsActionTypes> => ({
  type: FetchAdminMerchantDetailsActionTypes.RESET,
});

// MAIL TEMPLATES
export interface MailTemplateProps {
  merchantId: number;
  fromEmail: string;
  subject: string;
  templateName: string;
  active: boolean | string;
  emailName: string;
  brandingId: number | string;
  domain: string;
  transport: string;
}

/* Add mail template */
export enum AddMailTemplateActionTypes {
  START = 'ADD_MAIL_TEMPLATE_REQUEST',
  SUCCESS = 'ADD_MAIL_TEMPLATE_SUCCESS',
  ERROR = 'ADD_MAIL_TEMPLATE_FAILURE',
}

type AddMailTemplateAction = Action<AddMailTemplateActionTypes | NotificationActionTypes>;

export const addMailTemplate = ({
  merchantId,
  fromEmail,
  subject,
  templateName,
  active,
  emailName,
  brandingId,
  domain,
  transport,
}: MailTemplateProps): ThunkAction<Promise<any>, RootState, any, AddMailTemplateAction> => async (dispatch) => {
  const data = {
    merchant_id: merchantId,
    from_email: fromEmail,
    subject,
    template_name: templateName,
    active,
    email_name: emailName,
    branding_id: brandingId,
    domain: domain || 'inplayer.com',
    transport,
  };

  const endpoint = API.ADD_MAIL_TEMPLATES;

  try {
    dispatch({ type: AddMailTemplateActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: AddMailTemplateActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(fetchAdminMailTemplates({ merchant_id: merchantId, page: 0, size: 15 }));
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Mail template successfully saved.',
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: AddMailTemplateActionTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

/* Bulk mail template */
export enum BulkMailTemplatesActionTypes {
  START = 'BULK_MAIL_TEMPLATES_REQUEST',
  SUCCESS = 'BULK_MAIL_TEMPLATES_SUCCESS',
  ERROR = 'BULK_MAIL_TEMPLATES_ERROR',
}

type BulkMailTemplatesAction = Action<BulkMailTemplatesActionTypes | NotificationActionTypes>;

export const bulkMailTemplates = ({
  merchantId,
  fromEmail,
  subject,
  templateName,
  active,
  emailName,
  brandingId,
  domain,
  transport,
}: MailTemplateProps): ThunkAction<Promise<any>, RootState, any, BulkMailTemplatesAction> => async (dispatch) => {
  const data = {
    merchant_id: merchantId,
    from_email: fromEmail,
    subject,
    template_name: templateName,
    active,
    email_name: emailName,
    branding_id: brandingId,
    domain,
    transport,
  };

  const endpoint = API.BULK_MAIL_TEMPLATE;

  try {
    dispatch({ type: BulkMailTemplatesActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      params: { merchant_id: merchantId },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: BulkMailTemplatesActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(fetchAdminMailTemplates({ merchant_id: merchantId, page: 0, size: 15 }));
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Mail templates successfully updated.',
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: BulkMailTemplatesActionTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

/* Update mail template */
export enum UpdateMailTemplateActionTypes {
  START = 'UPDATE_MAIL_TEMPLATE_REQUEST',
  SUCCESS = 'UPDATE_MAIL_TEMPLATE_SUCCESS',
  ERROR = 'UPDATE_MAIL_TEMPLATE_FAILURE',
}

export interface UpdateMailTemplateProps extends MailTemplateProps {
  mailTemplateId: number;
}

type UpdateMailTemplateAction = Action<UpdateMailTemplateActionTypes | NotificationActionTypes>;

export const updateMailTemplate = ({
  merchantId,
  fromEmail,
  subject,
  templateName,
  active,
  emailName,
  brandingId,
  domain,
  transport,
  mailTemplateId,
}: UpdateMailTemplateProps): ThunkAction<Promise<any>, RootState, any, UpdateMailTemplateAction> => async (
  dispatch
) => {
  const data = {
    merchant_id: merchantId,
    from_email: fromEmail,
    subject,
    template_name: templateName,
    active,
    email_name: emailName,
    branding_id: brandingId,
    domain,
    transport,
  };

  const endpoint = API.UPDATE_DELETE_MAIL_TEMPLATE;

  try {
    dispatch({ type: UpdateMailTemplateActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: { id: mailTemplateId },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: UpdateMailTemplateActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(fetchAdminMailTemplates({ merchant_id: merchantId, page: 0, size: 15 }));
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Mail template was successfully updated.',
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: UpdateMailTemplateActionTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

/* Delete mail template */
export enum DeleteMailTemplateActionTypes {
  START = 'DELETE_MAIL_TEMPLATE_REQUEST',
  SUCCESS = 'DELETE_MAIL_TEMPLATE_SUCCESS',
  ERROR = 'DELETE_MAIL_TEMPLATE_FAILURE',
}

export interface DeleteMailTemplateProps {
  merchantId: number;
  mailTemplateId: number;
}

type DeleteMailTemplateAction = Action<DeleteMailTemplateActionTypes | NotificationActionTypes>;

export const deleteMailTemplate = ({
  merchantId,
  mailTemplateId,
}: DeleteMailTemplateProps): ThunkAction<Promise<any>, RootState, any, DeleteMailTemplateAction> => async (
  dispatch
) => {
  const endpoint = API.UPDATE_DELETE_MAIL_TEMPLATE;

  try {
    dispatch({ type: DeleteMailTemplateActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: { id: mailTemplateId },
      params: { merchant_id: merchantId },
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: DeleteMailTemplateActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(fetchAdminMailTemplates({ merchant_id: merchantId, page: 0, size: 15 }));
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Mail template was successfully deleted.',
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: DeleteMailTemplateActionTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

// AdminMerchant subscription analytics
export enum SubscriptionAnalyticsActionTypes {
  START = 'GET_SUBSCRIPTION_ANALYTICS_REQUEST',
  SUCCESS = 'GET_SUBSCRIPTION_ANALYTICS_SUCCESS',
  ERROR = 'GET_SUBSCRIPTION_ANALYTICS_ERROR',
}

type SubscriptionAnalyticsAction = Action<SubscriptionAnalyticsActionTypes>;

export interface SubscriptionParams {
  merchantId: number;
  startDate: string;
  endDate: string;
}

export const getSubscriptionAnalytics = ({
  merchantId,
  startDate,
  endDate,
}: SubscriptionParams): ThunkAction<Promise<any>, RootState, any, SubscriptionAnalyticsAction> => async (dispatch) => {
  const endpoint = API.SUBSCRIPTIONS_ANALYTICS;

  try {
    dispatch({ type: SubscriptionAnalyticsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      params: {
        merchant_id: merchantId,
        start_date: startDate,
        end_date: endDate,
      },
    });
    dispatch({
      type: SubscriptionAnalyticsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: SubscriptionAnalyticsActionTypes.ERROR });
  }
};

// AdminMerchant accounting analytics
export enum AccountingAnalyticsActionTypes {
  START = 'GET_ACCOUNTING_ANALYTICS_REQUEST',
  SUCCESS = 'GET_ACCOUNTING_ANALYTICS_SUCCESS',
  ERROR = 'GET_ACCOUNTING_ANALYTICS_ERROR',
}

export enum ResetAnalyticsStateType {
  RESET_STATE = 'RESET_ANALYTICS_STATE',
}

type AccountingAnalyticsAction = Action<AccountingAnalyticsActionTypes>;

export interface AccountingParams extends SubscriptionParams {
  currency: string;
}

export const getAccountingAnalytics = ({
  merchantId,
  startDate,
  endDate,
  currency,
}: AccountingParams): ThunkAction<Promise<any>, RootState, any, AccountingAnalyticsAction> => async (dispatch) => {
  const endpoint = API.ACCOUNTING_ANALYTICS;

  try {
    dispatch({ type: AccountingAnalyticsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      params: {
        merchant_id: merchantId,
        start_date: startDate,
        end_date: endDate,
        currency,
      },
    });
    dispatch({
      type: AccountingAnalyticsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (err) {
    dispatch({ type: AccountingAnalyticsActionTypes.ERROR });
  }
};

/* Activate Relate - Enable master role */
export enum EnableMasterRoleActionTypes {
  START = 'ENABLE_MASTER_ROLE_REQUEST',
  SUCCESS = 'ENABLE_MASTER_ROLE_SUCCESS',
  ERROR = 'ENABLE_MASTER_ROLE_FAILURE',
}
type EnableMasterRoleAction = Action<EnableMasterRoleActionTypes | NotificationActionTypes>;

export const enableMasterRole = (
  merchantId: number,
  roleId: number
): ThunkAction<Promise<any>, RootState, any, EnableMasterRoleAction> => async (dispatch) => {
  const endpoint = API.ENABLE_OR_DISABLE_MASTER_ROLE;

  try {
    dispatch({ type: EnableMasterRoleActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        id: merchantId,
        roleId,
      },
      contentType: ContentType.JSON,
    });
    dispatch({ type: EnableMasterRoleActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Master role has been activated.',
        type: 'success',
      })
    );
  } catch (_) {
    dispatch({ type: EnableMasterRoleActionTypes.ERROR });
  }
};

/* Deactivate Relate - Disable master role */
export enum DisableMasterRoleActionTypes {
  START = 'DISABLE_MASTER_ROLE_REQUEST',
  SUCCESS = 'DISABLE_MASTER_ROLE_SUCCESS',
  ERROR = 'DISABLE_MASTER_ROLE_FAILURE',
}
type DisableMasterRoleAction = Action<DisableMasterRoleActionTypes | NotificationActionTypes>;

export const disableMasterRole = (
  merchantId: number,
  roleId: number
): ThunkAction<Promise<any>, RootState, any, DisableMasterRoleAction> => async (dispatch) => {
  const endpoint = API.ENABLE_OR_DISABLE_MASTER_ROLE;

  try {
    dispatch({ type: DisableMasterRoleActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        id: merchantId,
        roleId,
      },
      contentType: ContentType.JSON,
    });
    dispatch({ type: DisableMasterRoleActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Master role has been deactivated.',
        type: 'success',
      })
    );
  } catch (_) {
    dispatch({ type: DisableMasterRoleActionTypes.ERROR });
  }
};

// PAYMENT SETUP
/* Load payment providers */
export enum FetchPaymentProvidersActionTypes {
  START = 'PAYMENT_PROVIDERS_REQUEST',
  SUCCESS = 'PAYMENT_PROVIDERS_SUCCESS',
  ERROR = 'PAYMENT_PROVIDERS_FAILURE',
}

type LoadPaymentProvidersAction = Action<FetchPaymentProvidersActionTypes>;

export const fetchPaymentProviders = (
  merchantId: number
): ThunkAction<Promise<any>, RootState, any, LoadPaymentProvidersAction> => async (dispatch) => {
  const endpoint = API.PAYMENT_PROVIDERS;

  try {
    dispatch({ type: FetchPaymentProvidersActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      params: { merchantID: merchantId },
    });
    dispatch({ type: FetchPaymentProvidersActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    dispatch({ type: FetchPaymentProvidersActionTypes.ERROR });
  }
};

/* Set or update payment fees */
export enum SetUpdateFeesActionTypes {
  START = 'SET_UPDATE_FEES_REQUEST',
  SUCCESS = 'SET_UPDATE_FEES_SUCCESS',
  ERROR = 'SET_UPDATE_FEES_FAILURE',
}

export interface PaymentFeesProps {
  merchantId: number;
  sharePercent: number;
  fixedFee: number;
  isSet: boolean;
  accountPaymentProviderId: number;
  paymentType: string;
  currency?: string;
}

type SetUpdateFeesAction = Action<SetUpdateFeesActionTypes | NotificationActionTypes>;

export const setPaymentFees = ({
  merchantId,
  sharePercent,
  fixedFee,
  isSet,
  accountPaymentProviderId,
  paymentType,
  currency,
}: PaymentFeesProps): ThunkAction<Promise<any>, RootState, any, SetUpdateFeesAction> => async (dispatch, getState) => {
  const data = {
    merchant_id: merchantId,
    fixed_fee: fixedFee,
    share_percent: sharePercent,
    account_payment_provider_id: accountPaymentProviderId,
    payment_type: paymentType,
    fixed_fee_currency: currency,
  };
  const {
    merchants: {
      details: {
        account: { roles },
      },
    },
  } = getState();
  const isFollower = roles.includes(Roles.FOLLOWER);

  const endpoint = API.SINGLE_FEE;
  const methodAction = isSet ? http.authenticated().put : http.authenticated().post;

  try {
    dispatch({ type: SetUpdateFeesActionTypes.START });
    const response = await methodAction(endpoint, {
      pathVariables: { id: merchantId },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: SetUpdateFeesActionTypes.SUCCESS, payload: { ...response.data } });
    const successContent = isSet ? 'updated' : 'saved';
    const content = `Payment fees successfully ${successContent}.${
      !isFollower && ' The changes will be reflected to the follower accounts after caching period of 5 minutes.'
    }`;
    dispatch(notifRequested({ type: 'success', title: 'SUCCESS', content }));
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: SetUpdateFeesActionTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

/* Set default fees */
export interface DefaultFeesProps {
  merchantId: number;
  accountPaymentProviderId: number;
  accountRevenueShares: Array<RevenueSharesItem>;
  defaultRevenueShare: RevenueSharesItem;
  dispatch: any;
}

export const setDefaultFees = ({
  merchantId,
  accountPaymentProviderId,
  accountRevenueShares,
  defaultRevenueShare,
  dispatch,
}: DefaultFeesProps) => {
  const paymentFeesData = {
    merchantId,
    sharePercent: defaultRevenueShare.share_percent,
    fixedFee: defaultRevenueShare.fixed_fee,
    currency: defaultRevenueShare.fixed_fee_currency,
    isSet: false,
    accountPaymentProviderId,
  };

  switch (accountRevenueShares.length) {
    case 0:
      dispatch(setPaymentFees({ ...paymentFeesData, paymentType: accessTypeNames.SUBSCRIPTION }));
      dispatch(setPaymentFees({ ...paymentFeesData, paymentType: accessTypeNames.PPV }));
      break;
    case 1: {
      const paymentType =
        accountRevenueShares[0].payment_type === accessTypeNames.SUBSCRIPTION
          ? accessTypeNames.PPV
          : accessTypeNames.SUBSCRIPTION;

      dispatch(setPaymentFees({ ...paymentFeesData, paymentType }));
      break;
    }
    default:
      break;
  }
};

/* Load single payment fee */
export enum LoadSingleFeeActionTypes {
  START = 'GET_SINGLE_FEE_REQUEST',
  SUCCESS = 'GET_SINGLE_FEE_SUCCESS',
  ERROR = 'GET_SINGLE_FEE_FAILURE',
}

export interface LoadSingleFeeProps {
  accountPaymentProviderId: number;
  merchantId: number;
  shouldSetDefaultFees?: boolean;
}

type LoadSingleFeeAction = Action<LoadSingleFeeActionTypes>;

export const loadSingleFee = ({
  accountPaymentProviderId,
  merchantId,
  shouldSetDefaultFees = false,
}: LoadSingleFeeProps): ThunkAction<Promise<any>, RootState, any, LoadSingleFeeAction> => async (dispatch) => {
  const endpoint = API.SINGLE_FEE;

  try {
    dispatch({ type: LoadSingleFeeActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: { id: accountPaymentProviderId },
      params: { merchantID: merchantId },
    });
    dispatch({ type: LoadSingleFeeActionTypes.SUCCESS, payload: { ...response.data } });
    if (shouldSetDefaultFees) {
      const {
        data: { account_revenue_shares: accountRevenueShares, default_revenue_share: defaultRevenueShare },
      } = response;
      setDefaultFees({
        merchantId,
        accountPaymentProviderId,
        accountRevenueShares,
        defaultRevenueShare,
        dispatch,
      });
    }
  } catch (e) {
    dispatch({ type: LoadSingleFeeActionTypes.ERROR });
  }
};

/* Load default payment fee */
export enum LoadDefaultFeeActionTypes {
  START = 'GET_DEFAULT_FEE_REQUEST',
  SUCCESS = 'GET_DEFAULT_FEE_SUCCESS',
  ERROR = 'GET_DEFAULT_FEE_FAILURE',
}

type LoadDefaultFeeAction = Action<LoadDefaultFeeActionTypes>;

export const loadDefaultFee = (
  merchantId: number
): ThunkAction<Promise<any>, RootState, any, LoadDefaultFeeAction> => async (dispatch) => {
  const endpoint = API.DEFAULT_FEE;

  try {
    dispatch({ type: LoadDefaultFeeActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      params: { merchantID: merchantId },
    });
    dispatch({ type: LoadDefaultFeeActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    dispatch({ type: LoadDefaultFeeActionTypes.ERROR });
  }
};

/* Set payment providers */
export enum SetProviderActionTypes {
  START = 'SET_PAYMENT_PROVIDER_REQUEST',
  SUCCESS = 'SET_PAYMENT_PROVIDER_SUCCESS',
  ERROR = 'SET_PAYMENT_PROVIDER_FAILURE',
}

export interface SetProviderProps {
  merchantId: number;
  paymentProviderId: number;
  methodId: number;
  current: boolean;
}

export interface SetProvidersProps {
  gateways: Array<SetProviderProps>;
  merchantId: number;
}

export interface SuccessData {
  paymentProviderId: number;
  methodId: number;
  current: boolean;
}

type SetProviderAction = Action<SetProviderActionTypes | NotificationActionTypes>;

export const setPaymentProviders = ({
  gateways,
  merchantId,
}: SetProvidersProps): ThunkAction<Promise<any>, RootState, any, SetProviderAction> => async (dispatch) => {
  let successData: Array<SuccessData> = [];
  const endpoint = API.PAYMENT_PROVIDERS;

  const setPaymentProvider = (gateway: any) => {
    const { paymentProviderId, methodId, current } = gateway;
    const data = {
      payment_provider_id: paymentProviderId,
      method_id: methodId,
      current,
    };

    successData = [...successData, { methodId, paymentProviderId, current }];

    return http.authenticated().post(endpoint, {
      params: { merchantID: merchantId },
      data,
      contentType: ContentType.URLENCODED,
    });
  };

  dispatch({ type: SetProviderActionTypes.START });

  Promise.all(gateways.map(setPaymentProvider))
    .then((response) => {
      dispatch({ type: SetProviderActionTypes.SUCCESS, payload: { ...successData, ...response } });
      dispatch(
        notifRequested({
          type: 'success',
          title: 'SUCCESS',
          content: 'Payment gateway(s) successfully added.',
        })
      );
      dispatch(fetchPaymentProviders(merchantId));
    })
    .catch((error) => {
      const {
        response: {
          data: { message },
        },
      } = error;

      dispatch({ type: SetProviderActionTypes.ERROR });
      dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
    });
};

export const setPaymentProvider = ({
  merchantId,
  paymentProviderId,
  methodId,
  current,
}: SetProviderProps): ThunkAction<Promise<any>, RootState, any, SetProviderAction> => async (dispatch) => {
  const data = {
    payment_provider_id: paymentProviderId,
    method_id: methodId,
    current,
  };
  const successData = { methodId, paymentProviderId, current };

  const endpoint = API.PAYMENT_PROVIDERS;

  try {
    dispatch({ type: SetProviderActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      params: { merchantID: merchantId },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: SetProviderActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Payment gateway successfully saved.',
      })
    );
    dispatch(fetchPaymentProviders(merchantId));
    const {
      data: { account_payment_provider_id: accountPaymentProviderId },
    } = response;
    // First we need to load the fees for the provider and then set default fees if none
    dispatch(loadSingleFee({ accountPaymentProviderId, merchantId, shouldSetDefaultFees: true }));
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: SetProviderActionTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

// BANK STATEMENT
/* Save bank statement name */
export enum AddBankStatementActionTypes {
  START = 'ADD_BANK_STATEMENT_REQUEST',
  SUCCESS = 'ADD_BANK_STATEMENT_SUCCESS',
  ERROR = 'ADD_BANK_STATEMENT_FAILURE',
}

export interface BankStatementProps {
  bankStatement: string;
  merchantId: number;
}

type AddBankStatementAction = Action<AddBankStatementActionTypes | NotificationActionTypes>;

export const saveBankStatementName = ({
  bankStatement,
  merchantId,
}: BankStatementProps): ThunkAction<Promise<any>, RootState, any, AddBankStatementAction> => async (dispatch) => {
  const endpoint = API.BANK_STATEMENT;

  const data = {
    bank_statement: bankStatement,
    merchant_id: merchantId,
  };

  try {
    dispatch({ type: AddBankStatementActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: AddBankStatementActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(fetchPaymentProviders(merchantId));
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'The bank statement was successfully set.',
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: AddBankStatementActionTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

/* Delete bank statement name */
export enum DeleteBankStatementActionTypes {
  START = 'DELETE_BANK_STATEMENT_REQUEST',
  SUCCESS = 'DELETE_BANK_STATEMENT_SUCCESS',
  ERROR = 'DELETE_BANK_STATEMENT_FAILURE',
}

type DeleteBankStatementAction = Action<DeleteBankStatementActionTypes | NotificationActionTypes>;

export const deleteBankStatementName = (
  merchantId: number
): ThunkAction<Promise<any>, RootState, any, DeleteBankStatementAction> => async (dispatch) => {
  const endpoint = API.BANK_STATEMENT;

  const data = {
    merchant_id: merchantId,
  };

  try {
    dispatch({ type: DeleteBankStatementActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      params: data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: DeleteBankStatementActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(fetchPaymentProviders(merchantId));
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'The bank statement was successfully removed.',
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: DeleteBankStatementActionTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

// MERCHANT FEATURES
/* Fetch merchant features */
export enum FetchMerchantFeaturesActionTypes {
  START = 'FETCH_MERCHANT_FEATURES_REQUEST',
  SUCCESS = 'FETCH_MERCHANT_FEATURES_SUCCESS',
  ERROR = 'FETCH_MERCHANT_FEATURES_FAILURE',
}

type FetchMerchantFeaturesAction = Action<FetchMerchantFeaturesActionTypes>;

export const fetchMerchantFeatures = (
  merchantId: number
): ThunkAction<Promise<any>, RootState, any, FetchMerchantFeaturesAction> => async (dispatch) => {
  const endpoint = API.MERCHANT_FEATURES;

  try {
    dispatch({ type: FetchMerchantFeaturesActionTypes.START });
    const response = await http.authenticated().get(endpoint, { pathVariables: { id: merchantId } });
    dispatch({ type: FetchMerchantFeaturesActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    dispatch({ type: FetchMerchantFeaturesActionTypes.ERROR });
  }
};

/* Update merchant feature flags */
export enum UpdateMerchantFeaturesActionTypes {
  START = 'UPDATE_MERCHANT_FEATURES_REQUEST',
  SUCCESS = 'UPDATE_MERCHANT_FEATURES_SUCCESS',
  ERROR = 'UPDATE_MERCHANT_FEATURES_FAILURE',
}

type UpdateMerchantFeaturesAction = Action<UpdateMerchantFeaturesActionTypes | NotificationActionTypes>;

export const updateMerchantFeatures = (
  merchantId: number,
  featureIds: number[]
): ThunkAction<Promise<any>, RootState, any, UpdateMerchantFeaturesAction> => async (dispatch) => {
  const endpoint = API.MERCHANT_FEATURES;

  const dataObject = featureIds.reduce((acc, id, index) => {
    acc[`feature_ids[${index}]`] = id;
    return acc;
  }, {});
  const data = { ...dataObject };

  try {
    dispatch({ type: UpdateMerchantFeaturesActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: { id: merchantId },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: UpdateMerchantFeaturesActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Feature flags were successfully updated.',
      })
    );
    dispatch(fetchMerchantFeatures(merchantId));
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: UpdateMerchantFeaturesActionTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

/* Disconnect stripe */
export enum DisconnectStripeActionTypes {
  START = 'DISCONNECT_STRIPE_REQUEST',
  SUCCESS = 'DISCONNECT_STRIPE_SUCCESS',
  ERROR = 'DISCONNECT_STRIPE_ERROR',
}

type DisconnectStripeAction = Action<DisconnectStripeActionTypes | NotificationActionTypes>;

export const disconnectStripe = (
  merchantId: number
): ThunkAction<Promise<any>, RootState, any, DisconnectStripeAction> => async (dispatch) => {
  const endpoint = API.DISCONNECT;

  const data = { merchant_id: merchantId.toString() };

  try {
    dispatch({ type: DisconnectStripeActionTypes.START });

    const response = await http.authenticated().post(endpoint, {
      pathVariables: { id: merchantId },
      data,
      contentType: ContentType.URLENCODED,
    });

    dispatch({
      type: DisconnectStripeActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (error) {
    const {
      response: { data: errorData },
    } = error;

    dispatch({ type: DisconnectStripeActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: 'The account cannot be disconnected at the moment, please try again later.',
      })
    );
    return errorData;
  }
};

/* Request for connect with Stripe account */
export enum ApprovedForConnectActionTypes {
  START = 'POST_CONNECT_REQUEST',
  SUCCESS = 'POST_CONNECT_SUCCESS',
  ERROR = 'POST_CONNECT_ERROR',
}

export interface ApprovedForConnectProps {
  merchantId: number;
  status: string;
  stripeConnect: boolean;
}

type ApprovedForConnectAction = Action<ApprovedForConnectActionTypes | NotificationActionTypes>;

export const approvedRequestForConnect = ({
  merchantId,
  status,
  stripeConnect,
}: ApprovedForConnectProps): ThunkAction<Promise<any>, RootState, any, ApprovedForConnectAction> => async (
  dispatch
) => {
  const endpoint = API.APPROVED_FOR_CONNECT;
  const successData = { stripeConnect };
  const successStatus = stripeConnect ? 'approved' : 'declined';

  try {
    dispatch({ type: ApprovedForConnectActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      params: { merchantID: merchantId, status },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: ApprovedForConnectActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `Request for connect with stripe account has been ${successStatus}.`,
        type: 'success',
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: ApprovedForConnectActionTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

/* Get age restriction */
export enum GetAgeRestrictionActionTypes {
  START = 'AGE_RESTRICTION_REQUEST',
  SUCCESS = 'AGE_RESTRICTION_SUCCESS',
  ERROR = 'AGE_RESTRICTION_FAILURE',
}

type GetAgeRestrictionAction = Action<GetAgeRestrictionActionTypes>;

export const fetchAgeRestriction = (
  merchantUuid: string
): ThunkAction<Promise<any>, RootState, any, GetAgeRestrictionAction> => async (dispatch) => {
  const endpoint = API.GET_ADMIN_AGE_RESTRICTION;

  try {
    dispatch({ type: GetAgeRestrictionActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        merchant_uuid: merchantUuid,
      },
    });
    dispatch({ type: GetAgeRestrictionActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: GetAgeRestrictionActionTypes.ERROR });
  }
};

/* Enable/Disable age restriction */
export enum SetAgeRestrictionActionTypes {
  START = 'SET_AGE_RESTRICTION_REQUEST',
  SUCCESS = 'SET_AGE_RESTRICTION_SUCCESS',
  ERROR = 'SET_AGE_RESTRICTION_FAILURE',
}

export interface SetAgeRestrictionProps {
  ageVerificationEnabled: boolean;
  ageVerificationType: string;
  merchantUuid: string;
}

type SetAgeRestrictionAction = Action<SetAgeRestrictionActionTypes | NotificationActionTypes>;

export const setAgeRestriction = ({
  ageVerificationEnabled,
  ageVerificationType,
  merchantUuid,
}: SetAgeRestrictionProps): ThunkAction<Promise<any>, RootState, any, SetAgeRestrictionAction> => async (dispatch) => {
  const endpoint = API.ADMIN_AGE_RESTRICTION;

  const data = {
    age_verification_enabled: ageVerificationEnabled,
    age_verification_type: ageVerificationType,
    merchant_uuid: merchantUuid,
  };
  const successData = { ageVerificationEnabled };
  const type = ageVerificationEnabled ? 'enabled' : 'disabled';
  try {
    dispatch({ type: SetAgeRestrictionActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      contentType: ContentType.URLENCODED,
      data,
    });
    dispatch({
      type: SetAgeRestrictionActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: `Age verification feature successfully ${type}`,
      })
    );
  } catch (_) {
    dispatch({ type: SetAgeRestrictionActionTypes.ERROR });
  }
};

/* Login in As */
export enum ImpersonateAsAgentTypes {
  START = 'IMPERSONATE_AS_AGENT_REQUEST',
  SUCCESS = 'IMPERSONATE_AS_AGENT_SUCCESS',
  ERROR = 'IMPERSONATE_AS_AGENT_FAILURE',
  NEW_DASHBOARD_SUCCESS = 'IMPERSONATE_NEW_DASHBOARD_SUCCESS',
}

type SetImpersonateAsAgentAction = Action<ImpersonateAsAgentTypes | NotificationActionTypes>;

export interface ImpersonateAsAgentArgs {
  [x: string]: any;
  sign_in_as: number;
  email: string;
  grant_type: string;
  uuid: string;
  client_id: string;
  password: string;
  newDashboard: boolean;
}

export const impersonateAsAgent = (
  args: ImpersonateAsAgentArgs
): ThunkAction<Promise<any>, RootState, any, SetImpersonateAsAgentAction> => async (dispatch) => {
  const { newDashboard } = args;
  const endpoint = newDashboard ? API.IMRESONATE_AS_AGENT_V2 : API.IMRESONATE_AS_AGENT;

  const body = Object.keys(args).reduce((acc, k) => {
    if (!(k === 'email' || k === 'sign_in_as') || !newDashboard) {
      acc[k] = args[k];
    } else {
      acc[k === 'email' ? 'customer_email' : 'customer_id'] = args[k];
    }
    return acc;
  }, {});

  try {
    dispatch({ type: ImpersonateAsAgentTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data: body,
      contentType: ContentType.URLENCODED,
    });
    if (newDashboard) {
      dispatch({ type: ImpersonateAsAgentTypes.NEW_DASHBOARD_SUCCESS, payload: { ...response.data } });
    } else {
      dispatch({ type: ImpersonateAsAgentTypes.SUCCESS, payload: { ...response.data } });
    }
    dispatch(notifRequested({ type: 'success', title: 'SUCCESS', content: 'Successfully impersonated' }));
  } catch (error) {
    let message = '';
    if (error?.response?.data?.errors?.[403]) {
      message = error?.response?.data?.errors?.[403];
    } else {
      message = error?.response?.data?.message;
    }
    dispatch({ type: ImpersonateAsAgentTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

// ACCESS FEE DURATIONS
/* Load access types */
export enum FetchAccessFeeTypesActionTypes {
  START = 'FETCH_ACCESS_FEE_TYPES_REQUEST',
  SUCCESS = 'FETCH_ACCESS_FEE_TYPES_SUCCESS',
  ERROR = 'FETCH_ACCESS_FEE_TYPES_FAILURE',
}

type LoadAccessFeeTypesAction = Action<FetchAccessFeeTypesActionTypes>;

export const fetchAccessFeeTypes = (
  merchantId: number
): ThunkAction<Promise<any>, RootState, any, LoadAccessFeeTypesAction> => async (dispatch) => {
  const endpoint = API.ACCEES_FEE_TYPES;

  try {
    dispatch({ type: FetchAccessFeeTypesActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params: { merchant_id: merchantId } });
    dispatch({ type: FetchAccessFeeTypesActionTypes.SUCCESS, payload: response.data });
  } catch (_) {
    dispatch({ type: FetchAccessFeeTypesActionTypes.ERROR });
  }
};

/* Add access fee duration */
export enum AddAccessFeeDurationActionTypes {
  START = 'ADD_FEE_DURATION_REQUEST',
  SUCCESS = 'ADD_FEE_DURATION_SUCCESS',
  ERROR = 'ADD_FEE_DURATION_FAILURE',
}

export interface AccessFeeProps {
  merchantId: number;
  name: string;
  quantity: string;
  period: Period;
}

type AddAccessFeeDurationAction = Action<AddAccessFeeDurationActionTypes | NotificationActionTypes>;

export const addAccessFeeDuration = ({
  merchantId,
  name,
  quantity,
  period,
}: AccessFeeProps): ThunkAction<Promise<any>, RootState, any, AddAccessFeeDurationAction> => async (dispatch) => {
  const endpoint = API.ACCEES_FEE_TYPES;

  const data = {
    name,
    quantity,
    period,
  };

  try {
    dispatch({ type: AddAccessFeeDurationActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      params: { merchant_id: merchantId },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: AddAccessFeeDurationActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Payment fees successfully saved',
      })
    );
    dispatch(fetchAccessFeeTypes(merchantId));
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: AddAccessFeeDurationActionTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

export enum GetRevenueSetupTypes {
  START = 'GET_REVENUE_SETUP_REQUEST',
  SUCCESS = 'GET_REVENUE_SETUP_SUCCESS',
  ERROR = 'GET_REVENUE_SETUP_FAILURE',
}

type GetRevenueSetupAction = Action<GetRevenueSetupTypes>;

// helper function for revenueSetup month
const extractMonthFromDate = (timestamp: number) => {
  // @ts-ignore: Expected 0-1 arguments, but got 2.
  return moment.unix(timestamp).format('MM', { trim: false });
};

export const getRevenueSetup = (
  merchantId: number | string
): ThunkAction<Promise<any>, RootState, any, GetRevenueSetupAction> => async (dispatch) => {
  const endpoint = API.GET_REVENUE_SETUP;

  try {
    dispatch({
      type: GetRevenueSetupTypes.START,
      payload: { isRevenueSetupFetching: true, isRevenueShareFetching: true },
    });
    const {
      data: { platform_fee_rebill_month, ...rest },
    } = await http.authenticated().get(endpoint, {
      params: { merchant_id: merchantId },
    });

    const handledRebillMonth =
      platform_fee_rebill_month === 0 ? 'Select' : extractMonthFromDate(platform_fee_rebill_month);

    dispatch({
      type: GetRevenueSetupTypes.SUCCESS,
      payload: {
        ...rest,
        platform_fee_rebill_month: handledRebillMonth,
        isRevenueSetupFetching: false,
        isRevenueShareFetching: false,
      },
    });
  } catch (error) {
    dispatch({
      type: GetRevenueSetupTypes.ERROR,
      payload: { isRevenueSetupFetching: false, isRevenueShareFetching: false },
    });
  }
};

export enum UpdateRevenueSetupTypes {
  START = 'UPDATE_REVENUE_SETUP_REQUEST',
  SUCCESS = 'UPDATE_REVENUE_SETUP_SUCCESS',
  ERROR = 'UPDATE_REVENUE_SETUP_FAILURE',
}
export interface RevenueSetupProps {
  merchantId: number;
  contractCurrency?: string;
  sharePercent?: number;
  shareMinAmount?: number;
  paymentFeePercent?: number;
  paymentFixedFee?: number;
  platformFee?: number;
  platformFeeCycle?: string;
  platformFeeRebillMonth?: string;
  monthlyActiveUserFee?: number;
}

type UpdateRevenueSetupAction = Action<UpdateRevenueSetupTypes | NotificationActionTypes>;

export const updateRevenueSetup = ({
  merchantId,
  contractCurrency,
  platformFee,
  platformFeeCycle,
  platformFeeRebillMonth,
  monthlyActiveUserFee,
}: RevenueSetupProps): ThunkAction<Promise<any>, RootState, any, UpdateRevenueSetupAction> => async (dispatch) => {
  const endpoint = API.REVENUE_SETUP;

  const data = {
    contract_currency: contractCurrency,
    platform_fee: platformFee,
    platform_fee_cycle: platformFeeCycle,
    mau_fee: monthlyActiveUserFee,
    ...(platformFeeRebillMonth && { platform_fee_rebill_month: platformFeeRebillMonth }),
  };

  try {
    dispatch({
      type: UpdateRevenueSetupTypes.START,
      payload: { isRevenueSetupFetching: true, isRevenueShareFetching: false },
    });
    const {
      data: { platform_fee_rebill_month, ...rest },
    } = await http.authenticated().patch(endpoint, {
      pathVariables: { merchant_id: merchantId },
      data,
      contentType: ContentType.URLENCODED,
    });

    const handledRebillMonth =
      platform_fee_rebill_month === 0 ? 'Select' : extractMonthFromDate(platform_fee_rebill_month);

    dispatch({
      type: UpdateRevenueSetupTypes.SUCCESS,
      payload: {
        ...rest,
        platform_fee_rebill_month: handledRebillMonth,
        isRevenueSetupFetching: false,
        isRevenueShareFetching: false,
      },
    });
    dispatch(notifRequested({ type: 'success', title: 'SUCCESS', content: 'Changes saved' }));
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({
      type: UpdateRevenueSetupTypes.ERROR,
      payload: { isRevenueSetupFetching: false, isRevenueShareFetching: false },
    });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

export enum UpdateRevenueShareTypes {
  START = 'UPDATE_REVENUE_SHARE_REQUEST',
  SUCCESS = 'UPDATE_REVENUE_SHARE_SUCCESS',
  ERROR = 'UPDATE_REVENUE_SHARE_FAILURE',
}

type UpdateRevenueShareAction = Action<UpdateRevenueShareTypes | NotificationActionTypes>;

export const updateRevenueShare = ({
  merchantId,
  sharePercent,
  shareMinAmount,
}: RevenueSetupProps): ThunkAction<Promise<any>, RootState, any, UpdateRevenueShareAction> => async (dispatch) => {
  const endpoint = API.REVENUE_SETUP;

  const data = {
    inplayer_share_percent: sharePercent,
    inplayer_share_minimum_amount: shareMinAmount,
  };

  try {
    dispatch({
      type: UpdateRevenueShareTypes.START,
      payload: { isRevenueShareFetching: true, isRevenueSetupFetching: false },
    });
    const res = await http.authenticated().patch(endpoint, {
      pathVariables: { merchant_id: merchantId },
      data,
      contentType: ContentType.URLENCODED,
    });

    dispatch({
      type: UpdateRevenueShareTypes.SUCCESS,
      payload: { ...res.data, isRevenueShareFetching: false, isRevenueSetupFetching: false },
    });
    dispatch(notifRequested({ type: 'success', title: 'SUCCESS', content: 'Changes saved' }));
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({
      type: UpdateRevenueShareTypes.ERROR,
      payload: { isRevenueShareFetching: false, isRevenueSetupFetching: false },
    });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

export enum GetRecurringFeesTypes {
  START = 'GET_RECURRING_FEES_REQUEST',
  SUCCESS = 'GET_RECURRING_FEES_SUCCESS',
  ERROR = 'GET_RECURRING_FEES_FAILURE',
}

type GetRecurringFeesAction = Action<GetRecurringFeesTypes | NotificationActionTypes>;

export const getRecurringFees = (
  merchantId?: number | string
): ThunkAction<Promise<any>, RootState, any, GetRecurringFeesAction> => async (dispatch) => {
  const endpoint = API.RECURRING_FEES;

  try {
    dispatch({
      type: GetRecurringFeesTypes.START,
    });
    const { data } = await http.authenticated().get(endpoint, {
      params: { merchant_id: merchantId },
    });
    dispatch({
      type: GetRecurringFeesTypes.SUCCESS,
      payload: JSON.parse(data.data),
    });
  } catch (error) {
    dispatch({
      type: GetRecurringFeesTypes.ERROR,
    });
  }
};

export interface RecurringFeesProps {
  isStreamingActive: boolean;
  streamingPerEventBox: boolean;
  eventFeeValue: number;
  oneOffServiceBox: boolean;
  oneOffServiceValue: number;
  perViewingHourBox: boolean;
  perViewingHourValue: number;
  isHostingActive: boolean;
  hostingFee: number;
  contractCurrency: string;
}

export interface SetRecurringFeesProps {
  merchantId: number;
  recurringFees: RecurringFeesProps;
}

export enum SetRecurringFeesTypes {
  START = 'SET_RECURRING_FEES_REQUEST',
  SUCCESS = 'SET_RECURRING_FEES_SUCCESS',
  ERROR = 'SET_RECURRING_FEES_FAILURE',
}

type SetRecurringFeesAction = Action<SetRecurringFeesTypes | NotificationActionTypes>;

export const setRecurringFees = ({
  merchantId,
  recurringFees,
}: SetRecurringFeesProps): ThunkAction<Promise<any>, RootState, any, SetRecurringFeesAction> => async (dispatch) => {
  const endpoint = API.RECURRING_FEES;

  const {
    isStreamingActive,
    streamingPerEventBox,
    eventFeeValue,
    oneOffServiceBox,
    oneOffServiceValue,
    perViewingHourBox,
    perViewingHourValue,
    isHostingActive,
    hostingFee,
    contractCurrency,
  } = recurringFees;

  const data = {
    merchant_id: merchantId,
    data: {
      streaming: {
        active: isStreamingActive,
        ...(isStreamingActive && {
          one_off_setup_fee: {
            active: oneOffServiceBox,
            currency: contractCurrency,
            amount: oneOffServiceValue,
          },
          per_event: {
            active: streamingPerEventBox,
            currency: contractCurrency,
            amount: eventFeeValue,
          },
          per_viewing_hour: {
            active: perViewingHourBox,
            amount: perViewingHourValue,
          },
        }),
      },
      hosting_page: {
        active: isHostingActive,
        ...(isHostingActive && {
          monthly_fee: {
            currency: contractCurrency,
            amount: hostingFee,
          },
        }),
      },
    },
  };

  try {
    dispatch({ type: SetRecurringFeesTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data: { ...data, data: JSON.stringify(data.data) },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: SetRecurringFeesTypes.SUCCESS,
      payload: JSON.parse(response.data.data),
    });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Recurring fees successfully saved.',
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;
    dispatch({
      type: SetRecurringFeesTypes.ERROR,
    });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

export enum GetNonRecurringFeesType {
  START = 'GET_NON_RECURRING_FEES_REQUEST',
  SUCCESS = 'GET_NON_RECURRING_FEES_SUCCESS',
  ERROR = 'GET_NON_RECURRING_FEES_FAILURE',
}

type GetNonRecurringFeesAction = Action<GetNonRecurringFeesType>;

export const getNonRecurringFees = (
  merchantId: number | string
): ThunkAction<Promise<any>, RootState, any, GetNonRecurringFeesAction> => async (dispatch) => {
  const endpoint = API.NON_RECURRING_FEES;

  try {
    dispatch({
      type: GetNonRecurringFeesType.START,
    });
    const { data } = await http.authenticated().get(endpoint, {
      params: { merchant_id: merchantId },
    });

    dispatch({
      type: GetNonRecurringFeesType.SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GetNonRecurringFeesType.ERROR,
    });
  }
};

export enum AddNonRecurringFeeType {
  START = 'ADD_NON_RECURRING_FEE_REQUEST',
  SUCCESS = 'ADD_NON_RECURRING_FEE_SUCCESS',
  ERROR = 'ADD_NON_RECURRING_FEE_FAILURE',
}

type AddNonRecurringFeeAction = Action<AddNonRecurringFeeType>;

export interface NonRecurringFeesProps {
  merchantId: number;
  nonRecurringFeeAmount: string | number;
  date: string;
  revenueStreamId: number;
  contractCurrency: string;
}

export const addNonRecurringFee = ({
  merchantId,
  nonRecurringFeeAmount,
  revenueStreamId,
  date,
  contractCurrency,
}: NonRecurringFeesProps): ThunkAction<Promise<any>, RootState, any, AddNonRecurringFeeAction> => async (dispatch) => {
  const endpoint = API.NON_RECURRING_FEES;

  try {
    dispatch({
      type: AddNonRecurringFeeType.START,
    });

    const data = {
      merchant_id: merchantId,
      revenue_stream_id: revenueStreamId,
      amount: nonRecurringFeeAmount,
      currency: contractCurrency,
      date,
    };

    const response = await http.authenticated().post(endpoint, {
      params: { merchant_id: merchantId },
      data,
      contentType: ContentType.URLENCODED,
    });

    dispatch({
      type: AddNonRecurringFeeType.SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: AddNonRecurringFeeType.ERROR,
    });
  }
};

export enum DeleteNonRecurringFeeType {
  START = 'DELETE_NON_RECURRING_FEE_REQUEST',
  SUCCESS = 'DELETE_NON_RECURRING_FEE_SUCCESS',
  ERROR = 'DELETE_NON_RECURRING_FEE_FAILURE',
}

type DeleteNonRecurringFeeAction = Action<DeleteNonRecurringFeeType>;

export const deleteNonRecurringFee = (
  nonRecurringFeeId: number
): ThunkAction<Promise<any>, RootState, any, DeleteNonRecurringFeeAction> => async (dispatch) => {
  const endpoint = API.DELETE_NON_RECURRING_FEES;

  try {
    dispatch({
      type: DeleteNonRecurringFeeType.START,
    });

    await http.authenticated().delete(endpoint, {
      pathVariables: { non_recurring_fee_id: nonRecurringFeeId },
      contentType: ContentType.URLENCODED,
    });

    dispatch({
      type: DeleteNonRecurringFeeType.SUCCESS,
      payload: nonRecurringFeeId,
    });
  } catch (error) {
    dispatch({
      type: DeleteNonRecurringFeeType.ERROR,
    });
  }
};
