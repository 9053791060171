import { API } from 'constants/api';
import http, { ContentType } from 'utils/http';
import { ApiAction } from 'ducks/types';

// Load the customers interactions
export enum CustomersInteractionsActionTypes {
  START = 'CUSTOMERS_INTERACTIONS_REQUEST',
  SUCCESS = 'CUSTOMERS_INTERACTIONS_SUCCESS',
  ERROR = 'CUSTOMERS_INTERACTIONS_FAILURE',
}

export interface LoadCustomersInteractionsParams {
  monetaryFrom?: number;
  monetaryTo?: number;
  probabilityFrom?: number;
  probabilityTo?: number;
  email?: string;
  page?: number;
  size?: number;
}

export const loadCustomersInteractions = (
  data?: LoadCustomersInteractionsParams
): ApiAction<CustomersInteractionsActionTypes> => async (dispatch) => {
  const endpoint = API.CUSTOMERS_INTERACTIONS;
  const headers = {
    'Content-Type': ContentType.URLENCODED,
  };

  const params = {
    monetary_from: data?.monetaryFrom,
    monetary_to: data?.monetaryTo,
    probability_from: data?.probabilityFrom,
    probability_to: data?.probabilityTo,
    email: data?.email,
    page: data?.page,
    size: data?.size,
  };

  try {
    dispatch({ type: CustomersInteractionsActionTypes.START });
    const response = await http.authenticated().get(endpoint, { headers, params });
    dispatch({
      type: CustomersInteractionsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: CustomersInteractionsActionTypes.ERROR });
  }
};

// Load interaction for a customer
export enum CustomerProbabilityActionTypes {
  START = 'CUSTOMER_PROBABILITY_REQUEST',
  SUCCESS = 'CUSTOMER_PROBABILITY_SUCCESS',
  ERROR = 'CUSTOMER_PROBABILITY_FAILURE',
}

export const loadCustomerInteractionPerUser = (id: number): ApiAction<CustomerProbabilityActionTypes> => async (
  dispatch
) => {
  const endpoint = API.CUSTOMER_PROBABILITY;
  const headers = {
    'Content-Type': ContentType.URLENCODED,
  };

  try {
    dispatch({ type: CustomerProbabilityActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      headers,
      pathVariables: {
        id,
      },
    });
    dispatch({
      type: CustomerProbabilityActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: CustomerProbabilityActionTypes.ERROR });
  }
};

// load registered only customers
export enum RegisteredOnlyCustomerActionTypes {
  START = 'REGISTERED_ONLY_CUSTOMERS_REQUEST',
  SUCCESS = 'REGISTERED_ONLY_CUSTOMERS_SUCCESS',
  ERROR = 'REGISTERED_ONLY_CUSTOMERS_FAILURE',
}

interface LoadRegisteredOnlyParams {
  startDate: string;
  endDate: string;
  page: number;
  search?: Array<any>;
  size?: number;
}

export const loadRegisteredOnlyCustomers = (
  loadRegisteredOnlyParams: LoadRegisteredOnlyParams
): ApiAction<RegisteredOnlyCustomerActionTypes> => async (dispatch) => {
  const { search = [], size, ...rest } = loadRegisteredOnlyParams;
  const params = {
    search,
    size,
    paying_customers: false,
    ...rest,
  };

  const endpoint = API.FETCH_AUDIENCE;

  try {
    dispatch({ type: RegisteredOnlyCustomerActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: RegisteredOnlyCustomerActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: RegisteredOnlyCustomerActionTypes.ERROR });
  }
};
