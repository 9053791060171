import React from 'react';

// components
import Select from 'components/Fields/Select';

const AuthSelect = (props: any) => {
  return <Select addAdditionalStyle {...props} />;
};

export default AuthSelect;
