export const user = {
  // Merchant endpoints
  ACCOUNTS: 'accounts',
  LOGIN: 'accounts/authenticate',
  LOGOUT: 'accounts/logout',
  SOCIAL: 'accounts/social',
  DELETE_SOCIAL: 'accounts/social/{type}',
  SOCIAL_FIELDS: 'accounts/social-apps-metadata-keys/{type}',
  SOCIAL_SETUP: 'accounts/social-apps-metadata',
  PASSWORD: 'accounts/change-password',
  FORGOT_PASSWORD: 'accounts/forgot-password',
  NEW_PASSWORD: 'accounts/forgot-password/{token}',
  ADD_AVATAR: 'images/avatar',
  ACTIVATE_USER: 'accounts/activate/{code}',
  SESSION: 'accounts/auth-setting',
  RESEND_CODE: 'accounts/activate',
  STRIPE_CONNECT: 'v2/payments/stripe-connect',
  FETCH_PAYMENT_PROVIDERS: 'payments/providers',
  FETCH_PAYMENT_FEES: 'accounting/revenue-shares',
  SEND_BANK_STATEMENT: 'payments/providers/bank-statement',
  POST_REQUEST_FOR_CONNECT: 'payments/stripe-connect-request',
  ORGANISATION: 'v2/accounts/organisations',
  ORGANISATION_MEMBERS: 'v2/accounts/organisations/{org_id}/members',
  ORGANISATION_MEMBERS_ACCOUNT: 'v2/accounts/organisations/{org_id}/members/{account_id}',
  FEATURES: 'v2/features',
  // Admin endpoints
  ALL_MERCHANTS: 'accounts/all/merchant',
  MAIL_TEMPLATES: 'v2/mailer/settings',
};
