import React, { ReactNode } from 'react';
import { AnalyticsProps } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';
import { IoMdAnalytics, IoMdPricetags } from 'react-icons/io';
import { FaUserFriends, FaRegImages } from 'react-icons/fa';
import { AiFillCalculator, AiOutlineSetting, AiFillShop } from 'react-icons/ai';
import { FiLock, FiMail } from 'react-icons/fi';
import { MdSubscriptions } from 'react-icons/md';

// utils
import TokenManager from 'utils/TokenManager';

// icons
import pageBuilder from 'assets/pics/icons/pagebuilder.svg';
import emailEditor from 'assets/pics/icons/emaileditor.svg';

// constants
import Rules from 'restrictions/roles/constants/rules';
import { LANDING_PAGE_BUILDER_URL } from 'constants/index';

// types
import { FeatureNames, Roles } from 'ducks/merchantDucks/user/types';

// components
import { StyledIcon } from 'components/Navigation/SideMenu/styled';

export interface AppRoute extends AnalyticsProps {
  path: string;
  title?: string;
  topNavTitle?: string;
  icon?: string | ReactNode;
  subpaths?: Array<AppRoute>;
  includeOriginalInSubpaths?: boolean;
  showInSideNav?: boolean;
  restrictionRule?: Rules;
  restrictionFeature?: string;
  whenRestrictedRedirectTo?: string;
  subTag?: string;
  redirectTo?: string;
  onClick?: Function;
}

const createStyles = () => ({
  width: '1.2rem',
  height: '1.2rem',
});

const CommonIcon = styled(AiFillShop)`
  ${createStyles}
`;

const Icon = styled.img`
  ${createStyles}
`;

const SUPPORT_URL = 'https://client.support.inplayer.com';

const setCookieForLandingPage = (): void => {
  const expires = new Date();
  expires.setMonth(expires.getMonth() + 1);
  document.cookie = `accessToken=${TokenManager.token};expires=${expires};domain=.inplayer.com;path=/;SameSite=None;Secure`;
};

const merchantNavRoutes: Array<AppRoute> = [
  {
    path: '/assets',
    title: 'Content',
    topNavTitle: 'Assets',
    subpaths: [
      {
        path: '/packages',
        title: 'Packages',
        tag: 'nav_nav_packages',
      },
      {
        path: '/templates',
        title: 'Templates',
        tag: 'nav_nav_templates',
      },
    ],
    icon: <CommonIcon as={FaRegImages} />,
    includeOriginalInSubpaths: true,
    showInSideNav: true,
    restrictionRule: Rules.DISABLE_ASSETS_SECTION,
    tag: 'nav_content',
    subTag: 'nav_nav_assets',
  },
  {
    path: '/dashboard',
    title: 'Analytics',
    subpaths: [],
    includeOriginalInSubpaths: true,
    showInSideNav: true,
    icon: <CommonIcon as={IoMdAnalytics} />,
    restrictionRule: Rules.DISABLE_ANALYTICS_SECTION,
    whenRestrictedRedirectTo: '/assets',
    tag: 'nav_analytics',
  },
  {
    path: '/vouchers',
    title: 'Promotions',
    topNavTitle: 'Vouchers',
    icon: <CommonIcon as={IoMdPricetags} />,
    includeOriginalInSubpaths: true,
    subpaths: [
      {
        path: '/in-app',
        title: 'In-app introductory offers',
        tag: 'nav_nav_inapp',
      },
      {
        path: '/gifts',
        title: 'Gifts',
        restrictionFeature: 'Gifts',
        tag: 'nav_nav_gifts',
      },
    ],
    showInSideNav: true,
    restrictionRule: Rules.DISABLE_ASSETS_SECTION,
    tag: 'nav_promotions',
    subTag: 'nav_nav_vouchers',
  },
  {
    path: '/audience',
    title: 'Audience',
    subpaths: [
      {
        path: '/segmentation',
        title: 'Segmentation',
        restrictionRule: Rules.DISABLE_SEGMENTATION_SECTION,
        tag: 'nav_nav_segmentation',
      },
      {
        path: '/reports',
        title: 'Audience reports',
        restrictionRule: Rules.DISABLE_ALL_REPORTS_SECTIONS,
        tag: 'nav_nav_audiencereports',
      },
      {
        path: '/segmentation-reports',
        title: 'Segmentation reports',
        restrictionRule: Rules.DISABLE_SEGMENTATION_REPORTS_SECTION,
        tag: 'nav_nav_segmentationreports',
      },
    ],
    includeOriginalInSubpaths: true,
    showInSideNav: true,
    icon: <CommonIcon as={FaUserFriends} />,
    restrictionRule: Rules.DISABLE_AUDIENCE_SECTION,
    tag: 'nav_audience',
    subTag: 'nav_nav_audience',
  },
  {
    path: '/transactions',
    title: 'Transactions',
    subpaths: [
      {
        path: '/payments',
        title: 'Payments',
        subpaths: [
          {
            path: '/reports',
            title: 'Payments reports',
            restrictionRule: Rules.DISABLE_ALL_REPORTS_SECTIONS,
          },
        ],
        tag: 'nav_nav_payments',
      },
      {
        path: '/subscriptions',
        title: 'Subscriptions',
        subpaths: [
          {
            path: '/reports',
            title: 'Subscriptions reports',
            restrictionRule: Rules.DISABLE_ALL_REPORTS_SECTIONS,
          },
        ],
        tag: 'nav_nav_subscriptions',
      },
    ],
    showInSideNav: true,
    icon: <CommonIcon as={AiFillCalculator} />,
    restrictionRule: Rules.DISABLE_TRANSACTIONS_SECTION,
    tag: 'nav_transactions',
  },
  {
    path: '/access',
    title: 'Access',
    includeOriginalInSubpaths: true,
    subpaths: [
      {
        path: '/reports',
        title: 'Access reports',
        restrictionRule: Rules.DISABLE_ALL_REPORTS_SECTIONS,
        tag: 'nav_nav_accessreports',
      },
      {
        path: '/bulk-actions',
        title: 'Bulk actions',
        restrictionFeature: 'BulkAccessManagement',
        tag: 'nav_nav_bulkactions',
      },
    ],
    showInSideNav: true,
    icon: <CommonIcon as={FiLock} />,
    restrictionRule: Rules.DISABLE_ASSET_ACCESS_SECTION,
    tag: 'nav_access',
    subTag: 'nav_nav_access',
  },
  {
    path: '/settings',
    title: 'Settings',
    subpaths: [
      {
        path: '/auth',
        title: 'Authentication',
        tag: 'nav_nav_authentication',
      },
      {
        path: '/branding',
        title: 'Branding',
        subpaths: [
          {
            path: '/add',
            title: 'Add new branding theme',
            restrictionRule: Rules.DISABLE_ADD_NEW_BRANDING_THEME_SECTION,
          },
        ],
        tag: 'nav_nav_branding',
      },
      {
        path: '/integrations',
        title: 'Integrations',
        tag: 'nav_nav_integrations',
      },
    ],
    showInSideNav: true,
    icon: <CommonIcon as={AiOutlineSetting} />,
    restrictionRule: Rules.DISABLE_SETTINGS_SECTION,
    tag: 'nav_settings',
  },
  {
    path: '/account',
    subpaths: [
      {
        path: '/details',
        title: 'Account details',
      },
      {
        path: '/relate',
        title: 'Inplayer Relate',
        restrictionRule: Rules.DISABLE_RELATE_SECTION,
      },
    ],
  },
  {
    path: '/billing-plan',
    subpaths: [
      {
        path: '/payment-settings',
        title: 'Billing & plan',
      },
    ],
    restrictionRule: Rules.DISABLE_BILLING_AND_PLAN_SECTION,
  },
  {
    path: '/webhooks',
    subpaths: [
      {
        path: '',
        title: 'Webhooks',
      },
    ],
    restrictionRule: Rules.DISABLE_WEBHOOKS_SECTION,
  },
  {
    path: '/pagebuilder',
    title: 'Page builder',
    subpaths: [],
    includeOriginalInSubpaths: true,
    showInSideNav: true,
    icon: <Icon alt="page-builder-icon" src={pageBuilder} />,
    restrictionRule: Rules.DISABLE_LANDING_PAGE_BUILDR_SECTION,
    tag: 'nav_pagebuilder',
    onClick: setCookieForLandingPage,
    redirectTo: `${LANDING_PAGE_BUILDER_URL}login`,
    restrictionFeature: FeatureNames.LANDINGPAGE,
  },
  {
    path: '/emailtemplates',
    title: 'Email templates',
    subpaths: [],
    includeOriginalInSubpaths: true,
    showInSideNav: true,
    icon: <Icon alt="email-templates-icon" src={emailEditor} />,
    restrictionRule: Rules.DISABLE_EMAIL_TEMPLATES_SECTION,
    tag: 'nav_emialtemplates',
    redirectTo: `${LANDING_PAGE_BUILDER_URL}email-templates/`,
    restrictionFeature: FeatureNames.EMAIL_TEMPLATES,
  },
  {
    path: '/support',
    title: 'Support',
    subpaths: [],
    includeOriginalInSubpaths: true,
    showInSideNav: true,
    icon: <StyledIcon />,
    tag: 'nav_support',
    redirectTo: SUPPORT_URL,
  },
];

const adminNavRoutes: Array<AppRoute> = [
  {
    path: '/admin/dashboard',
    title: 'Analytics',
    subpaths: [],
    includeOriginalInSubpaths: true,
    showInSideNav: true,
    icon: <CommonIcon as={IoMdAnalytics} />,
  },
  {
    path: '/admin/merchants',
    title: 'Merchants',
    subpaths: [
      {
        path: '/commercials',
        title: 'Commercials',
        restrictionRule: Rules.DISABLE_ALL_REPORTS_SECTIONS,
      },
    ],
    includeOriginalInSubpaths: true,
    showInSideNav: true,
    icon: <CommonIcon as={AiFillShop} />,
  },
  {
    path: '/admin/audience',
    title: 'Audience',
    subpaths: [
      {
        path: '/reports',
        title: 'Audience reports',
        restrictionRule: Rules.DISABLE_ALL_REPORTS_SECTIONS,
      },
    ],
    includeOriginalInSubpaths: true,
    showInSideNav: true,
    icon: <CommonIcon as={FaUserFriends} />,
  },
  {
    path: '/admin/payments',
    title: 'Payments',
    subpaths: [
      {
        path: '/reports',
        title: 'Payments reports',
        restrictionRule: Rules.DISABLE_ALL_REPORTS_SECTIONS,
      },
      {
        path: '/missing-transactions',
        title: 'Missing transactions',
        restrictionRule: Rules.DISABLE_ALL_REPORTS_SECTIONS,
      },
    ],
    includeOriginalInSubpaths: true,
    showInSideNav: true,
    icon: <CommonIcon as={AiFillCalculator} />,
  },
  {
    path: '/admin/subscriptions',
    title: 'Subscriptions',
    subpaths: [
      {
        path: '/reports',
        title: 'Subscriptions reports',
        restrictionRule: Rules.DISABLE_ALL_REPORTS_SECTIONS,
      },
    ],
    includeOriginalInSubpaths: true,
    showInSideNav: true,
    icon: <CommonIcon as={MdSubscriptions} />,
  },
  {
    path: '/admin/mailing-list',
    title: 'Mailing List',
    subpaths: [],
    includeOriginalInSubpaths: true,
    showInSideNav: true,
    icon: <CommonIcon as={FiMail} />,
  },
  {
    path: '/admin/access',
    title: 'Access',
    subpaths: [
      {
        path: '/reports',
        title: 'Access reports',
        restrictionRule: Rules.DISABLE_ALL_REPORTS_SECTIONS,
      },
    ],
    includeOriginalInSubpaths: true,
    showInSideNav: true,
    icon: <CommonIcon as={FiLock} />,
  },
  {
    path: '/account',
    subpaths: [
      {
        path: '/details',
        title: 'Account details',
      },
    ],
  },
];

const navRoutes: Array<AppRoute> = [...adminNavRoutes, ...merchantNavRoutes];

export const findRouteByPath = (path: string) => {
  let matchedRoute: AppRoute | undefined;

  const findRoute = (prePath = '') => (route: AppRoute): any => {
    const fullPath = `${prePath}${route.path}`;

    if (fullPath === path) {
      matchedRoute = route;
      return;
    }

    if (route.subpaths && RegExp(`^${fullPath}`).test(path)) {
      route.subpaths.forEach(findRoute(fullPath));
    }
  };

  navRoutes.forEach(findRoute());

  return matchedRoute;
};

const getRoutesByRole = (role: Roles) => {
  switch (role) {
    case Roles.INPLAYER:
      return adminNavRoutes;
    default:
      return merchantNavRoutes;
  }
};

export default getRoutesByRole;
