import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

// constants
import { API } from 'constants/api';

// utils
import http, { ContentType } from 'utils/http';

// types
import { ApiAction } from 'ducks/types';

// actions
import { NotificationActionTypes, notifRequested } from 'middleware/notifications/actions';

// state
import RootState from 'ducks/RootState';

// utils
import { downloadFile, mapDataWithNewKey } from 'ducks/merchantDucks/common/utils';

export enum AccessControlType {
  PAID = 1,
  CODEONLY = 2,
  DATA = 3,
  CODEAUTH = 6,
  DATACAPTURE = 7,
}

/* Fetch a list of all accesses */
export enum FetchAccessActionTypes {
  START = 'FETCH_ACCESSES_REQUEST',
  SUCCESS = 'FETCH_ACCESSES_SUCCESS',
  ERROR = 'FETCH_ACCESSES_FAILURE',
}
export interface FetchAccessQueryParams {
  page: number;
  size: number;
  search?: Array<any>;
  status: string;
  type?: string | null;
}

type FetchAccessesAction = Action<FetchAccessActionTypes>;

export const fetchAccesses = (
  params: FetchAccessQueryParams
): ThunkAction<Promise<any>, RootState, any, FetchAccessesAction> => async (dispatch) => {
  const endpoint = API.FETCH_ACCESSES;

  try {
    dispatch({ type: FetchAccessActionTypes.START });
    const response = await http.authenticated().get(endpoint, { params: { ...params, order: 'createdAt' } });
    dispatch({ type: FetchAccessActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: FetchAccessActionTypes.ERROR });
  }
};

export enum BulkAccessActionActionTypes {
  START = 'BULK_ACCESS_ACTIONS_REQUEST',
  SUCCESS = 'BULK_ACCESS_ACTIONS_SUCCESS',
  ERROR = 'BULK_ACCESS_ACTIONS_ERROR',
}

interface BulkAccessData {
  file: File;
  actionType: string;
  merchantId?: string;
}

export const bulkAccessActions = ({
  file,
  actionType,
  merchantId,
}: BulkAccessData): ApiAction<BulkAccessActionActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.BULK_ACCESS_ACTIONS;

  const data = new FormData();
  data.append('data', file, file.name);
  if (merchantId) {
    data.append('merchant_id', merchantId);
  }
  try {
    dispatch({ type: BulkAccessActionActionTypes.START });

    const action = `${actionType}-access`;

    await http.authenticated().post(endpoint, {
      pathVariables: {
        action,
      },
      data,
      contentType: ContentType.FORM_DATA,
    });

    dispatch({ type: BulkAccessActionActionTypes.SUCCESS });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Upload in progress',
        type: 'success',
      })
    );
  } catch (e) {
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: e.response.data.message,
      })
    );
    dispatch({ type: BulkAccessActionActionTypes.ERROR });
  }
};
export enum TemplateActionTypes {
  START = 'TEMPLATE_ACTION_REQUEST',
  SUCCESS = 'TEMPLATE_ACTION_SUCCESS',
  ERROR = 'TEMPLATE_ACTION_ERROR',
}

export const getTemplateFile = (actionType: string): ApiAction<TemplateActionTypes> => async (dispatch) => {
  const endpoint = API.GET_TEMPLATE_FILE;
  try {
    dispatch({ type: TemplateActionTypes.START });
    const action = `${actionType}-access`;

    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        action,
      },
    });

    const { download_url: url } = response.data;

    downloadFile(url, 'template.csv');

    dispatch({ type: TemplateActionTypes.SUCCESS });
  } catch (_) {
    dispatch({ type: TemplateActionTypes.ERROR });
  }
};

export enum ListErrorFilesActionTypes {
  START = 'LOAD_ERROR_FILES_REQUEST',
  SUCCESS = 'LOAD_ERROR_FILES_SUCCESS',
  ERROR = 'LOAD_ERROR_FILES_ERROR',
}

export const getErrorFiles = (action: string): ApiAction<ListErrorFilesActionTypes> => async (dispatch, getState) => {
  const endpoint = API.GET_FAILED_LIST;

  const {
    auth: {
      user: { id },
    },
    merchants: {
      details: {
        account: { id: adminMerchantId },
      },
    },
  } = getState();

  try {
    dispatch({ type: ListErrorFilesActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        action,
      },
      params: { merchant_id: adminMerchantId || id },
    });

    const mappedResponse = mapDataWithNewKey(response.data, 'created_at', 'createdAt');

    dispatch({ type: ListErrorFilesActionTypes.SUCCESS, payload: mappedResponse });
  } catch (e) {
    dispatch({ type: ListErrorFilesActionTypes.ERROR });
  }
};
