import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Typography, Grid } from '@inplayer-org/inplayer-ui';
import { Colors } from './colors';

const { Cell } = Grid;

export const StyledNavLink = styled(NavLink)`
  color: ${Colors.red};
  text-decoration: underline;
  font-size: ${({ theme }) => theme.font.sizes.small};
  display: block;
  margin-top: 0.5rem;
  font-family: Montserrat, 'sans-serif';
`;

export const FormMargin = styled.div<{ maxWidth?: string }>`
  margin: 7rem 3rem;
  max-width: ${({ maxWidth }) => maxWidth || '30rem'};
`;

export const StyledTypography = styled(Typography)`
  color: ${Colors.black};
  font-family: Montserrat, 'sans-serif';
`;

export const StyledCell = styled(Cell)`
  margin: 2rem auto;
`;

export const CenteredContainer = styled.div<{ flexDirection?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
`;

export const ButtonWrapper = styled(Cell)<{ textAlign?: string }>`
  text-align: ${({ textAlign }) => textAlign || 'right'};
  margin-top: 1rem;
`;
