import axios from 'axios';
import http, { ContentType } from 'utils/http';

import { downloadFile } from 'ducks/merchantDucks/common/utils';

export const getSignedUrl = async (endpoint: string, pathVariables?: Record<string, number>) => {
  const response = await http.authenticated().get(endpoint, { pathVariables });
  return response.data;
};

interface PostFileParams {
  fileName: string;
  endpoint: string;
  pathVariables: Record<string, number>;
}

export const postFileBulk = async ({ fileName, endpoint, pathVariables }: PostFileParams) => {
  const data = {
    file_name: fileName,
  };

  await http.authenticated().post(endpoint, {
    pathVariables,
    data,
    contentType: ContentType.URLENCODED,
  });
};

export const uploadFileBulk = async (signedUrl: string, file: File, headers: any) => {
  const config = {
    headers: {
      'Content-Type': headers['content-type'],
      'x-amz-tagging': headers['X-Amz-Tagging'],
    },
  };

  await axios.put(signedUrl, file, config);
};

interface DownloadErrorFilePRops {
  endpoint: string;
  fileName: string;
  merchantId?: number;
  action?: string;
}

export const downloadErrorFile = async ({ endpoint, fileName, merchantId, action }: DownloadErrorFilePRops) => {
  const response = await http.authenticated().get(endpoint, {
    pathVariables: {
      action,
      file_name: fileName,
    },
    params: { merchant_id: merchantId },
  });

  const { download_url: url } = response.data;

  downloadFile(url, fileName);
};
