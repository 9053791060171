export const audience = {
  // Merchant endpoints
  FETCH_AUDIENCE: 'accounts/registered-users',
  FETCH_CUSTOMER_DETAILS: 'accounts/{id}',
  FETCH_CUSTOMER_PAYMENTS: 'accounting/consumer-transactions',
  FETCH_CUSTOMER_ACTIVITY: 'v2/analytics/customers/{id}/activity',
  FETCH_CUSTOMER_LIFETIME_VALUES: 'v2/analytics/customers/{id}/lifetime',
  GRANT_ACCESS: 'items/access',
  EXTEND_ACCESS: 'items/extend-access',
  REVOKE_ACCESS: 'items/{id}/access',
  UPDATE_METADATA: 'accounts',
  RESET_PASSWORD: 'accounts/reset-password/{id}',
  DEACTIVATE_ACCOUNT: 'accounts',
  UNIQUE_CUSTOMERS: 'accounting/total-customers',
  REACTIVATE_ACCOUNT: 'accounts/reactivate/{id}',
  EXPORT_ACCOUNT_DATA: 'accounts/export',
  ERASE_ACCOUNT: 'accounts/erase',
  FETCH_REGISTRATIONS: 'v2/analytics/customers/registrations',
  FETCH_LOGINS: '/v2/analytics/customers/logins',
  // Admin endpoints
  ALL_AUDIENCE: 'accounts/all/consumer',
};
