import React from 'react';
import { Tooltip, Placement, AnalyticsProps, AnalyticsComponent } from '@inplayer-org/inplayer-ui';

// components
import TableLink from 'components/Table/TableLink';

interface LinkTextWithTooltipProps extends AnalyticsProps {
  text: string;
  linkTo: string;
  tooltipContent: string;
  placement?: Placement;
}

const linkTextWithTooltip = ({
  text,
  linkTo,
  tooltipContent,
  placement = 'top',
  tag = '',
}: LinkTextWithTooltipProps) => {
  return (
    <AnalyticsComponent>
      {({ pages, tracker, merchantId, ip }) => (
        <TableLink
          onClick={() =>
            tracker.track({
              event: 'click',
              type: 'button',
              tag,
              pages,
              merchantId,
              ip,
            })
          }
          to={linkTo}
        >
          <Tooltip tag={tag} placement={placement} content={tooltipContent}>
            {text}
          </Tooltip>
        </TableLink>
      )}
    </AnalyticsComponent>
  );
};

export default linkTextWithTooltip;
