import styled from 'styled-components';
import { Colors, Typography } from '@inplayer-org/inplayer-ui';
import { switchProp } from 'styled-tools';

interface MessageProps {
  size?: string;
}

const EmptyMessage = styled(Typography)<MessageProps>`
  font-style: italic;
  font-size: ${switchProp('size', {
    medium: ({ theme }) => theme.font.sizes.medium,
    small: ({ theme }) => theme.font.sizes.small,
  })};

  font-weight: ${({ theme }) => theme.font.weights.bold};
  color: ${Colors.fontGray};
`;

export default EmptyMessage;
