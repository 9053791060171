import RootState from 'ducks/RootState';
import { SelectorWithInputs } from './types';

export const getExternalAccount = (state: RootState) => state.externalAccount;

const getExternalAccountKeys = (state: RootState) => getExternalAccount(state).keys;

export const getIntegrationKeysForOvp: SelectorWithInputs<any> = (ovpType: string) => (state) => {
  const externalAccountKeys = getExternalAccountKeys(state)[ovpType]?.keys;

  return externalAccountKeys || {};
};

const getEventbriteAccount = (state: RootState) => getExternalAccount(state).eventbriteAccount;

export const getEventbriteOrganizations = (state: RootState) => getEventbriteAccount(state).organizations;

export const getEventbriteVideos = (state: RootState) => getEventbriteAccount(state).events;

export const getIsFetching = (state: RootState) => getExternalAccount(state).isFetching;
