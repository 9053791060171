import styled from 'styled-components';
import { Colors } from '@inplayer-org/inplayer-ui';
import { ifProp, prop } from 'styled-tools';

interface CustomCardProps {
  direction: 'column' | 'row';
  padding?: string;
  height?: string;
  width?: string;
  margin?: string;
  alignItems?: string;
}

export const CustomCard = styled.div<CustomCardProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: space-around;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  position: relative;
  border: 1px solid ${Colors.gray};
  border-radius: 3px;
  background: ${Colors.white};
  line-height: 1.5;
  box-sizing: border-box;
  margin: ${ifProp('margin', prop('margin'), 0)};
  padding: ${ifProp('padding', prop('padding'), 0)};
  list-style: none;
  height: ${ifProp('height', prop('height'))};
  width: ${ifProp('width', prop('width'))};
`;
