import React, { FunctionComponent } from 'react';
import { Formik, FormikProps, FormikHelpers, FastField } from 'formik';
import { object, string } from 'yup';
import { Button, Grid, Label } from '@inplayer-org/inplayer-ui';

// components
import Input from 'components/Fields/Input';
import FieldError from 'components/Fields/FieldError';
import StyledContainer from 'components/StyledContainer';

const { Container, Cell } = Grid;

export interface LoginAsFollowerFormValues {
  password: string;
}

interface OwnProps {
  onSubmit: (values: LoginAsFollowerFormValues) => any;
  handleCloseModal: () => any;
}

type Props = OwnProps;

const validationSchema = object().shape({
  password: string().required('*Required').min(8, 'Password must be minimum 8 characters'),
});

const ImpersonateAsFollowerForm: FunctionComponent<Props> = ({ onSubmit, handleCloseModal }) => {
  const onFormSubmit = async (
    values: LoginAsFollowerFormValues,
    { setSubmitting }: FormikHelpers<LoginAsFollowerFormValues>
  ) => {
    await onSubmit(values);
    setSubmitting(false);
    handleCloseModal();
  };

  return (
    <Formik onSubmit={onFormSubmit} validationSchema={validationSchema} initialValues={{ password: '' }}>
      {({ handleSubmit, isSubmitting }: FormikProps<LoginAsFollowerFormValues>) => (
        <form onSubmit={handleSubmit}>
          <Container columns="1fr 1fr">
            <Cell middle>
              <Label htmlFor="password">Please enter your master account&rsquo;s password:</Label>
            </Cell>
            <Cell>
              <FastField name="password" type="password" component={Input} placeholder="Password" />
              <FieldError name="password" />
            </Cell>
          </Container>
          <StyledContainer columns={3} alignContent="center" justifyContent="center" margin="2rem 0 0 0">
            <Cell middle left={2}>
              <Button type="button" fullWidth onClick={handleCloseModal}>
                Oops, no!
              </Button>
            </Cell>
            <Cell middle left={3}>
              <Button fullWidth type="submit" disabled={isSubmitting} buttonModifiers={['buttonPrimary']}>
                Sign in
              </Button>
            </Cell>
          </StyledContainer>
        </form>
      )}
    </Formik>
  );
};

export default ImpersonateAsFollowerForm;
