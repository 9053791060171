import React, { FunctionComponent, InputHTMLAttributes, useCallback, useEffect } from 'react';
import { FieldProps } from 'formik';
import {
  Label,
  Option,
  DefaultOption,
  Grid,
  DropdownModifier,
  Colors,
  AnalyticsProps,
} from '@inplayer-org/inplayer-ui';
import styled, { css } from 'styled-components';

// components
import ExtendedDropdown from 'components/ExtendedDropdown';
import { Colors as NewColors } from 'pages/MerchantPages/Authentication/components/colors';

interface ExtendedDropdownProps {
  disabled: boolean;
  addAdditionalStyle?: boolean;
  value: string;
}

const disabledDropdown = () => css`
  cursor: default;
  border-color: ${Colors.gray};
`;

const Dropdown = styled(ExtendedDropdown)<ExtendedDropdownProps>`
  ${({ disabled }) =>
    disabled &&
    css`
      ${disabledDropdown}
      &:hover,
      &:focus {
        ${disabledDropdown}
      }
    `}
  ${({ addAdditionalStyle }) =>
    addAdditionalStyle &&
    css`
      border: 1px solid ${Colors.black};
      font-size: ${({ theme }) => theme.font.sizes.medium};
      padding: 0.75rem 1rem;
      font-family: Montserrat, 'sans-serif';
      color: ${({ value }) =>
        // dropdown value is a string
        value !== 'undefined' && value !== '' ? NewColors.black : NewColors.gray};
      &&&:hover {
        color: ${NewColors.gray};
        border: 2px solid ${Colors.black};
        margin: -1px;
      }
    `}
`;

const { Container, Cell } = Grid;

interface OwnProps {
  label?: string;
  inlineLabel?: boolean;
  defaultOption?: DefaultOption;
  options: Array<Option & AnalyticsProps>;
  modifiers?: Array<DropdownModifier>;
  handleChange?: (type: string) => void;
  disabled?: boolean;
  addAdditionalStyle?: boolean;
  form?: any;
  hasContainer?: boolean;
}

export type Props = Omit<InputHTMLAttributes<HTMLSelectElement>, 'form'> & FieldProps & OwnProps & AnalyticsProps;

const Select: FunctionComponent<Props> = ({
  label,
  inlineLabel,
  required,
  options,
  defaultOption,
  modifiers,
  handleChange,
  disabled = false,
  addAdditionalStyle,
  tag,
  form,
  value,
  field,
  hasContainer = true,
}) => {
  const handleSelect = useCallback(
    (val: string) => {
      if (handleChange) {
        handleChange(val);
      }

      form.setFieldValue(field.name, val);
    },
    [handleChange]
  );

  const val = value ?? field.value?.toString() ?? String(field.value);

  useEffect(() => {
    form.setFieldValue(field.name, val);
  }, [val]);

  return hasContainer ? (
    <Container>
      {label && (
        <Cell width={inlineLabel ? 2 : 12} middle>
          <Label disabled={disabled}>{`${label}${required ? ' *' : ''}`}</Label>
        </Cell>
      )}
      <Cell width={inlineLabel ? 10 : 12}>
        <Dropdown
          fullWidth
          options={options}
          value={val}
          onChange={handleSelect}
          defaultOption={defaultOption}
          modifiers={modifiers}
          disabled={disabled}
          addAdditionalStyle={addAdditionalStyle}
          tag={tag}
        />
      </Cell>
    </Container>
  ) : (
    <Dropdown
      fullWidth
      options={options}
      value={val}
      onChange={handleSelect}
      defaultOption={defaultOption}
      modifiers={modifiers}
      disabled={disabled}
      addAdditionalStyle={addAdditionalStyle}
      tag={tag}
    />
  );
};

export default Select;
