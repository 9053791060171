import React from 'react';
import { Tooltip, Placement } from '@inplayer-org/inplayer-ui';
import truncate from 'lodash/truncate';

interface TextWithTooltipProps {
  text: string;
  textLimit?: number;
  placement?: Placement;
}

const truncateTextWithTooltip = ({ text, textLimit = 30, placement = 'top' }: TextWithTooltipProps) => {
  if (text.length > textLimit) {
    return (
      <Tooltip placement={placement} content={text}>
        {truncate(text, { length: textLimit })}
      </Tooltip>
    );
  }

  return text;
};

export default truncateTextWithTooltip;
