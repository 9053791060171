import React, { FunctionComponent, useRef, useState, ChangeEvent } from 'react';
import { object, string, ref } from 'yup';
import { Formik, FormikHelpers, Field } from 'formik';
import styled from 'styled-components';
import { Grid, Option } from '@inplayer-org/inplayer-ui';

// Types
import { Optional } from 'utils/types';

// Utils
import useScript from 'hooks/useScript';
import { onPassChangeValidate } from 'utils/validatePassword';

// Constants
import { CAPTCHA_PUBLIC_KEY } from 'constants/index';

// data
import countries from 'data/countries';

// styled components
import { ButtonWrapper, StyledNavLink } from 'pages/MerchantPages/Authentication/components/sharedComponents';
import { ItalicDescription } from 'components/SharedStyledElements';

// Components
import ErrorMessage from 'components/ErrorMessage';
import FieldError, { ErrorMessageWrapperSpan } from 'components/Fields/FieldError';
import Input from 'pages/MerchantPages/Authentication/components/Input';
import Select from 'pages/MerchantPages/Authentication/components/Select';
import Button from 'pages/MerchantPages/Authentication/components/Button';

const { Cell, Container } = Grid;

const StyledCell = styled(Cell)`
  justify-self: right;
`;

const LoginDescription = styled(ItalicDescription)`
  text-align: right;
`;

const LoginLink = styled(StyledNavLink)`
  display: inline-block;
`;

export interface RegisterUserFormValues {
  fullName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  company?: string;
  country?: string;
  serviceQuestion: string;
  serviceOtherReason: string;
  interestQuestion: string;
  interestOtherReason: string;
}
interface OwnProps {
  onSubmit: (data: RegisterUserFormValues, captchaToken: Optional<string>) => any;
}

type Props = OwnProps;

export enum Services {
  Payment = 'Payments/Subscriptions services',
  Auth = 'Authentication services',
  ContentEntitlement = 'Content entitlement services',
  Other = 'Other....',
}

export enum Interests {
  LiveEvent = "I'd like to monetize an upcoming live event.",
  SellVideos = 'I have videos-on-demand that I want to sell.',
  StreamVideos = 'I offer streaming/player services and I need monetization tools',
  PlayAround = "I'd like to test the platform and play around.",
  WatchVideos = "I'm a viewer. I'd like to watch content online.",
  Other = 'Other....',
}

const serviceQuestionOptions = [
  {
    value: Services.Payment,
    displayName: Services.Payment,
  },
  {
    value: Services.Auth,
    displayName: Services.Auth,
  },
  {
    value: Services.ContentEntitlement,
    displayName: Services.ContentEntitlement,
  },
  { value: Services.Other, displayName: Services.Other },
];

const interestQuestionOptions: Array<Option> = [
  {
    value: Interests.LiveEvent,
    displayName: Interests.LiveEvent,
  },
  {
    value: Interests.SellVideos,
    displayName: Interests.SellVideos,
  },
  {
    value: Interests.StreamVideos,
    displayName: Interests.StreamVideos,
  },
  {
    value: Interests.PlayAround,
    displayName: Interests.PlayAround,
  },
  {
    value: Interests.WatchVideos,
    displayName: Interests.WatchVideos,
  },
  { value: Interests.Other, displayName: Interests.Other },
];

const countryOptions: Array<Option> = countries.map((country) => {
  return {
    value: country.code,
    displayName: country.name,
  };
});

const validationSchema = object().shape({
  fullName: string().min(2).required('*Required'),
  email: string().email('Please enter a valid email address').required('*Required'),
  password: string().required('*Required'),
  passwordConfirmation: string()
    .oneOf([ref('password'), ''], 'Passwords do not match')
    .required('*Required'),
  company: string().required('*Required'),
  country: string().required('*Required'),
  serviceQuestion: string().required('*Required'),
  serviceOtherReason: string().when('serviceQuestion', {
    is: (val) => val === Services.Other,
    then: string().min(5).required('*Required'),
    otherwise: string().min(0),
  }),
  interestQuestion: string().required('*Required'),
  interestOtherReason: string().when('interestQuestion', {
    is: (val) => val === Interests.Other,
    then: string().min(5).required('*Required'),
    otherwise: string().min(0),
  }),
});

const RegisterForm: FunctionComponent<Props> = ({ onSubmit }) => {
  const [validPassword, setValidPassword] = useState(false);
  const [passwordWarning, setPasswordWarning] = useState('');
  const recaptchaRef = useRef<HTMLDivElement>(null);

  const [captchaToken, setCaptchaToken] = useState<Optional<string>>(null);

  useScript('https://www.google.com/recaptcha/api.js?render=explicit', {
    async: true,
    defer: true,
    onLoad: () => {
      const { current: recaptcha } = recaptchaRef;

      if (recaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha.render(recaptcha, {
            sitekey: CAPTCHA_PUBLIC_KEY,
            callback: (token: string) => {
              setCaptchaToken(token);
            },
          });
        });
      }
    },
  });

  const initialValues: RegisterUserFormValues = {
    fullName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    company: '',
    country: '',
    serviceQuestion: '',
    serviceOtherReason: '',
    interestQuestion: '',
    interestOtherReason: '',
  };

  const onFormSubmit = async (
    values: RegisterUserFormValues,
    { setSubmitting }: FormikHelpers<RegisterUserFormValues>
  ) => {
    await onSubmit(values, captchaToken);
    setSubmitting(false);
  };

  return (
    <Formik onSubmit={onFormSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, errors }) => (
        <form onSubmit={handleSubmit}>
          <LoginDescription variant="p" margin="0 0 0.5rem 0">
            Already have an account? <LoginLink to="/login">Log In</LoginLink>
          </LoginDescription>
          <Container alignContent="space-around" columns={1} gap="0.5rem">
            <Cell>
              <Field placeholder="Full Name*" name="fullName" type="text" component={Input} />
              <FieldError name="fullName" />
            </Cell>
            <Cell>
              <Field placeholder="Email*" name="email" type="email" component={Input} />
              <FieldError name="email" />
            </Cell>
            <Cell>
              <Field
                showPasswordIcon
                placeholder="Password*"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onPassChangeValidate(e, setFieldValue, setPasswordWarning, setValidPassword)
                }
                name="password"
                type="password"
                component={Input}
              />
              {errors.password ? (
                <FieldError name="password" />
              ) : (
                !validPassword && <ErrorMessageWrapperSpan>{passwordWarning}</ErrorMessageWrapperSpan>
              )}
            </Cell>
            <Cell>
              <Field placeholder="Repeat password*" name="passwordConfirmation" type="password" component={Input} />
              <FieldError name="passwordConfirmation" />
            </Cell>
            <Cell>
              <Field name="company" type="text" component={Input} placeholder="Company*" />
              <FieldError name="company" />
            </Cell>
            <Cell>
              <Field
                name="country"
                type="select"
                component={Select}
                modifiers={['fontSizeLarge']}
                defaultOption={{ displayName: 'Country*' }}
                options={countryOptions}
              />
              <FieldError name="country" />
            </Cell>
            <Cell>
              <Field
                name="serviceQuestion"
                type="select"
                component={Select}
                modifiers={['fontSizeLarge']}
                defaultOption={{
                  displayName: 'What kind of services do you need?*',
                }}
                options={serviceQuestionOptions}
              />
              <FieldError name="serviceQuestion" />
            </Cell>
            {values.serviceQuestion === Services.Other && (
              <Cell>
                <Field
                  name="serviceOtherReason"
                  type="text"
                  component={Input}
                  required={values.serviceQuestion === Services.Other}
                  placeholder="Please type your answer here..."
                  hidden={values.serviceQuestion !== Services.Other}
                />
                <FieldError name="serviceOtherReason" />
              </Cell>
            )}
            <Cell>
              <Field
                name="interestQuestion"
                type="select"
                component={Select}
                modifiers={['fontSizeLarge']}
                defaultOption={{ displayName: 'What is your specific interest?*' }}
                options={interestQuestionOptions}
              />
              <FieldError name="interestQuestion" />
            </Cell>
            {values.interestQuestion === Interests.Other && (
              <Cell>
                <Field
                  name="interestOtherReason"
                  type="text"
                  component={Input}
                  required={values.interestQuestion === Interests.Other}
                  placeholder="Please type your answer here..."
                />
                <FieldError name="interestOtherReason" />
              </Cell>
            )}
            {values.interestQuestion === Interests.WatchVideos && (
              <ErrorMessage>
                Dear customer, please contact our support. This website is not used for watching content and does not
                contain any whatsoever.
              </ErrorMessage>
            )}
            <StyledCell>
              <div ref={recaptchaRef} />
            </StyledCell>
            <ButtonWrapper>
              <Button
                type="submit"
                padding="1rem 1.5rem"
                buttonModifiers={['buttonPrimary']}
                disabled={
                  values.interestQuestion === Interests.WatchVideos || isSubmitting || !captchaToken || !validPassword
                }
              >
                Create my Account
              </Button>
            </ButtonWrapper>
          </Container>
        </form>
      )}
    </Formik>
  );
};

export default RegisterForm;
