import { Option } from '@inplayer-org/inplayer-ui';
import moment from 'moment';

export const headerTabs: Record<number, string> = {
  0: 'audience',
  1: 'account-logins',
};
export const accountLogins: Array<Option> = [
  { value: 'all', displayName: 'all' },
  { value: 'unique', displayName: 'unique' },
];
export const accessTypes: Array<Option> = [
  { value: 'all', displayName: 'all' },
  { value: 'active', displayName: 'active' },
  { value: 'inactive', displayName: 'inactive' },
];
export const activeAccessTypes: Array<Option> = [
  { value: 'all', displayName: 'all' },
  { value: 'granted', displayName: 'granted' },
  { value: 'purchased', displayName: 'purchased' },
  { value: 'full-discount', displayName: 'full-discount' },
  { value: 'access-voucher', displayName: 'access-voucher' },
];
export const inactiveAccessTypes: Array<Option> = [
  { value: 'all', displayName: 'all' },
  { value: 'expired', displayName: 'expired' },
  { value: 'revoked', displayName: 'revoked' },
];

export enum ReportModalTypes {
  Audience = 'audience',
  Subscriptions = 'subscriptions',
  Transactions = 'transactions',
  AccountLogins = 'account-logins',
  UniqueCustomers = 'unique-customers',
}

const format = 'YYYY-M-D H:m:s';

export const initialParams = {
  startDate: moment().subtract(1, 'month').startOf('day').format(format),
  endDate: moment().endOf('day').format(format),
  status: 'active',
  type: 'all',
  reportFields: [],
  oauthApp: '',
  unique: false,
};
