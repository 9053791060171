import React, { SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { transparentize } from 'polished';
import { AnalyticsProps, AnalyticsComponent } from '@inplayer-org/inplayer-ui';
import { snakeCase } from 'lodash';

const Container = styled.div`
  align-items: flex-start;
  box-shadow: 0 1px 5px 0 ${transparentize(0.8, '#000')};
  z-index: 1000;
  min-width: 15%;
  background: ${({ theme }) => theme.palette.background.main};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.125rem 0;
  position: absolute;
  top: 40px;

  &::before {
    content: '';
    display: block;
    box-shadow: -1px -1px 1px 0 ${transparentize(0.9, '#000')};
    background: ${({ theme }) => theme.palette.background.main};
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    position: absolute;
    top: -5px;
    right: 11px;
  }
`;

const Item = styled.a`
  color: ${({ theme }) => theme.palette.text.light};
  box-sizing: border-box;
  font-weight: ${({ theme }) => theme.font.weights.light};
  letter-spacing: 0.02em;
  line-height: 2;
  padding: 0.375rem 0.625rem;
  text-decoration: none;
  transition: color 0.3s ease;
  white-space: nowrap;
  width: 100%;
  font-size: ${({ theme }) => theme.font.sizes.medium};

  &:hover {
    color: ${({ theme }) => theme.palette.text.main};
  }
`;

type ActionItemProps = {
  moreThanOneItem: boolean;
  smallSize?: boolean;
};

const ActionItem = styled(Item)<ActionItemProps>`
  ${({
    moreThanOneItem,
    theme: {
      palette: { text },
    },
  }) =>
    moreThanOneItem
      ? `border-top: 1px solid ${text.disabled}
                  margin-top: 0.75rem;
                  padding-top: 1.125rem;`
      : null};
  ${ifProp(
    'smallSize',
    css`
      font-size: ${({ theme }) => theme.font.sizes.small};
    `
  )};
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ItemImage = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-left: 0.625rem;
`;

type MenuItem = {
  title: string;
  href?: string;
  image?: string;
  onClick?: (e: SyntheticEvent) => any;
  smallSize?: boolean;
} & AnalyticsProps;

export type Props = {
  menuItems?: Array<MenuItem>;
  actionItem?: MenuItem | null;
  onClick?: any;
  linkItem?: MenuItem | null;
  id?: string;
};

const UserMenuDropdown = ({ menuItems = [], actionItem = null, linkItem = null, id }: Props) => {
  const isRelatedMenu = id === 'relateMenu';
  return (
    <Container>
      <AnalyticsComponent>
        {({ pages, tracker, merchantId, ip }) => (
          <>
            {menuItems.map((item, i) => (
              <ItemContainer
                onClick={() => {
                  tracker.track({
                    event: 'click',
                    type: 'link',
                    tag: isRelatedMenu
                      ? `dropdown_related_${snakeCase(item.title)}`
                      : `dropdown_merchant_${snakeCase(item.title)}`,
                    pages,
                    merchantId,
                    ip,
                  });
                }}
                key={i}
              >
                {item.image && <ItemImage src={item.image} />}
                <Item key={i} href={item.href} onClick={item.onClick}>
                  {item.title}
                </Item>
              </ItemContainer>
            ))}
            {linkItem && (
              <ItemContainer>
                <Item
                  onClick={(e) => {
                    tracker.track({
                      event: 'click',
                      type: 'link',
                      tag: linkItem.tag || '',
                      pages,
                      merchantId,
                      ip,
                    });
                    return linkItem.onClick?.(e);
                  }}
                >
                  {linkItem.title}
                </Item>
              </ItemContainer>
            )}
            {actionItem && (
              <ActionItem
                moreThanOneItem={menuItems.length > 0}
                href={actionItem.href}
                onClick={(e) => {
                  tracker.track({
                    event: 'click',
                    type: 'link',
                    tag: isRelatedMenu ? 'dropdown_related_all' : 'dropdown_merchant_logout',
                    pages,
                    merchantId,
                    ip,
                  });
                  return actionItem.onClick?.(e);
                }}
                smallSize={actionItem.smallSize}
              >
                {actionItem.title}
              </ActionItem>
            )}
          </>
        )}
      </AnalyticsComponent>
    </Container>
  );
};

export default UserMenuDropdown;
