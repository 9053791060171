import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

// api
import { API } from 'constants/api';

// utils
import http, { ContentType } from 'utils/http';

// actions
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';

// types
import { ApiAction } from 'ducks/types';
import RootState from 'ducks/RootState';
import { City, TemplateRestrictionsRequest } from './types';

// Load the country sets
export enum CountrySetsActionTypes {
  START = 'COUNTRY_SETS_REQUEST',
  SUCCESS = 'COUNTRY_SETS_SUCCESS',
  FAILURE = 'COUNTRY_SETS_FAILURE',
}

export const loadCountrySets = (): ApiAction<CountrySetsActionTypes> => async (dispatch) => {
  const endpoint = API.SAVE_COUNTRY;
  try {
    dispatch({ type: CountrySetsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {});

    dispatch({
      type: CountrySetsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: CountrySetsActionTypes.FAILURE });
  }
};

// Add new county set
export enum CreateCountrySetActionTypes {
  START = 'ADD_COUNTRIES_REQUEST',
  SUCCESS = 'ADD_COUNTRIES_SUCCESS',
  FAILURE = 'ADD_COUNTRIES_FAILURE',
}

export interface CreateCountrySetPayload {
  merchantId: number;
  name: string;
  type: string;
  global: number;
  countries: Array<string>;
  regions?: Array<string>;
  cities?: Array<City>;
}

export const createCountrySet = ({
  merchantId,
  name,
  type,
  global,
  countries,
  regions,
  cities,
}: CreateCountrySetPayload): ApiAction<CreateCountrySetActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.SAVE_COUNTRY;
  const data = { name, type, global, countries, regions, cities };
  const successData = { merchantId, name, type, global, countries, regions, cities };

  try {
    dispatch({ type: CreateCountrySetActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: CreateCountrySetActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(loadCountrySets());
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'New country set successfully is added.',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;

    dispatch({ type: CreateCountrySetActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Update country set
export enum UpdateCountrySetActionTypes {
  START = 'UPDATE_COUNTRIES_REQUEST',
  SUCCESS = 'UPDATE_COUNTRIES_SUCCESS',
  FAILURE = 'UPDATE_COUNTRIES_FAILURE',
}

export interface UpdateCountrySetPayload {
  merchantId: number;
  countrySetId: string;
  name: string;
  type: string;
  global: number;
  countries?: string[];
  regions?: string[];
  cities?: Array<City>;
}

export const updateCountrySet = ({
  merchantId,
  countrySetId,
  name,
  type,
  global,
  countries,
  regions,
  cities,
}: UpdateCountrySetPayload): ApiAction<UpdateCountrySetActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.COUNTRY;

  const data = { name, type, global, countries, regions, cities };
  const successData = { merchantId, name, type, global, countries, id: countrySetId, regions, cities };

  try {
    dispatch({ type: UpdateCountrySetActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        countrySetId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateCountrySetActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(loadCountrySets());
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Country set successfully updated.',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: UpdateCountrySetActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Delete country set
export enum DeleteCountrySetActionTypes {
  START = 'DELETE_COUNTRY_SET_REQUEST',
  SUCCESS = 'DELETE_COUNTRY_SET_SUCCESS',
  FAILURE = 'DELETE_COUNTRY_SET_FAILURE',
}

export const deleteCountrySet = (
  countrySetId: string | number
): ApiAction<DeleteCountrySetActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.COUNTRY;
  const successData = { country_set_id: countrySetId };

  try {
    dispatch({ type: DeleteCountrySetActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: { countrySetId },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteCountrySetActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Country set successfully deleted.',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: DeleteCountrySetActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Delete asset country set
export enum DeleteAssetCountrySetActionTypes {
  START = 'DELETE_ASSET_COUNTRY_SET_REQUEST',
  SUCCESS = 'DELETE_ASSET_COUNTRY_SET_SUCCESS',
  FAILURE = 'DELETE_ASSET_COUNTRY_SET_FAILURE',
}

export const deleteAssetCountrySet = (
  itemId: number
): ApiAction<DeleteAssetCountrySetActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.DELETE_ASSET_COUNTRY;

  try {
    dispatch({ type: DeleteAssetCountrySetActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      contentType: ContentType.URLENCODED,
      pathVariables: {
        itemId,
      },
    });
    dispatch({
      type: DeleteAssetCountrySetActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Country set successfully deleted.',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: DeleteAssetCountrySetActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Delete country from country set
export enum DeleteCountryActionTypes {
  START = 'DELETE_COUNTRY_REQUEST',
  SUCCESS = 'DELETE_COUNTRY_SUCCESS',
  FAILURE = 'DELETE_COUNTRY_FAILURE',
}

export const deleteCountry = (
  countrySetId: string | number,
  country: number
): ApiAction<DeleteCountryActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.DELETE_COUNTRY;

  try {
    dispatch({ type: DeleteCountryActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      contentType: ContentType.URLENCODED,
      pathVariables: {
        countrySetId,
        country,
      },
    });
    dispatch({ type: DeleteCountryActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Country successfully deleted.',
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: DeleteCountryActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Load the countries in the set
export enum LoadCountriesActionTypes {
  START = 'COUNTRIES_REQUEST',
  SUCCESS = 'COUNTRIES_SUCCESS',
  FAILURE = 'COUNTRIES_FAILURE',
}

export const loadCountries = (countrySetId: number | string): ApiAction<LoadCountriesActionTypes> => async (
  dispatch
) => {
  const endpoint = API.COUNTRY;

  try {
    dispatch({ type: LoadCountriesActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        countrySetId,
      },
    });
    dispatch({
      type: LoadCountriesActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadCountriesActionTypes.FAILURE });
  }
};

// Add country set per asset
export enum CreateAssetCountrySetActionTypes {
  START = 'CREATE_ASSET_COUNTRY_SET_REQUEST',
  SUCCESS = 'CREATE_ASSET_COUNTRY_SET_SUCCESS',
  FAILURE = 'CREATE_ASSET_COUNTRY_SET_FAILURE',
}
export const createAssetCountrySet = (
  countrySetId: number,
  itemId: number
): ApiAction<CreateAssetCountrySetActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.SAVE_ASSET_COUNTRY;
  const successData = { country_set_id: countrySetId };

  try {
    dispatch({ type: CreateAssetCountrySetActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      pathVariables: {
        countrySetId,
        itemId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: CreateAssetCountrySetActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'New country set successfully is added.',
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: CreateAssetCountrySetActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Load country set for asset
export enum AssetCountrySetActionTypes {
  START = 'ASSET_COUNTRY_SET_REQUEST',
  SUCCESS = 'ASSET_COUNTRY_SET_SUCCESS',
  FAILURE = 'ASSET_COUNTRY_SET_FAILURE',
}

export const loadAssetCountrySet = (itemId: string): ApiAction<AssetCountrySetActionTypes> => async (dispatch) => {
  const endpoint = API.ASSSET_COUNTRY_SET;

  try {
    dispatch({ type: AssetCountrySetActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        itemId,
      },
    });
    dispatch({
      type: AssetCountrySetActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: AssetCountrySetActionTypes.FAILURE });
  }
};

// Update country set for asset
export enum UpdateAssetCountrySetActionTypes {
  START = 'UPDATE_ASSET_COUNTRY_SET_REQUEST',
  SUCCESS = 'UPDATE_ASSET_COUNTRY_SET_SUCCESS',
  FAILURE = 'UPDATE_ASSET_COUNTRY_SET_FAILURE',
}

export const updateAssetCountrySet = (
  countrySetId: number,
  itemId: number
): ApiAction<UpdateAssetCountrySetActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.SAVE_ASSET_COUNTRY;
  const successData = { country_set_id: countrySetId };

  try {
    dispatch({ type: UpdateAssetCountrySetActionTypes.START });

    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        countrySetId,
        itemId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateAssetCountrySetActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'New country set successfully is added.',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: UpdateAssetCountrySetActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Load all domains
export enum LoadDomainsRestActionTypes {
  START = 'LOAD_DOMAINS_REST_REQUEST',
  SUCCESS = 'LOAD_DOMAINS_REST_SUCCESS',
  FAILURE = 'LOAD_DOMAINS_REST_FAILURE',
}

export const loadDomainsRest = (): ApiAction<LoadDomainsRestActionTypes> => async (dispatch) => {
  const endpoint = API.DOMAINS_REST;

  try {
    dispatch({ type: LoadDomainsRestActionTypes.START });

    const response = await http.authenticated().get(endpoint, {});
    dispatch({
      type: LoadDomainsRestActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadDomainsRestActionTypes.FAILURE });
  }
};

// Add new domain
export enum CreateDomainActionTypes {
  START = 'ADD_DOMAIN_REQUEST',
  SUCCESS = 'ADD_DOMAIN_SUCCESS',
  FAILURE = 'ADD_DOMAIN_FAILURE',
}

export const createDomain = (
  domains: Array<any>,
  type: string
): ApiAction<CreateDomainActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.SAVE_DOMAIN_REST;
  const data = { domains };

  try {
    dispatch({ type: CreateDomainActionTypes.START });

    const response = await http.authenticated().post(endpoint, {
      data,
      pathVariables: {
        type,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: CreateDomainActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(loadDomainsRest());
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'New domain successfully added.',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: CreateDomainActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Delete domain
export enum DeleteDomainActionTypes {
  START = 'DELETE_DOMAIN_REQUEST',
  SUCCESS = 'DELETE_DOMAIN_SUCCESS',
  FAILURE = 'DELETE_DOMAIN_FAILURE',
}

export const deleteDomain = (domain: any): ApiAction<DeleteDomainActionTypes | NotificationActionTypes> => async (
  dispatch
) => {
  const endpoint = API.DOMAINS_REST;
  const successData = { domain };

  const domainName = domain.domain;
  const params = { domain: domainName };

  try {
    dispatch({ type: DeleteDomainActionTypes.START });

    const response = await http.authenticated().delete(endpoint, {
      params,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteDomainActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(loadDomainsRest());
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Domain successfully deleted.',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: DeleteDomainActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Load domains for item
export enum AssetDomainsActionTypes {
  START = 'ASSET_DOMAINS_REQUEST',
  SUCCESS = 'ASSET_DOMAINS_SUCCESS',
  FAILURE = 'ASSET_DOMAINS_FAILURE',
}

export const loadAssetDomains = (itemId: string): ApiAction<AssetDomainsActionTypes> => async (dispatch) => {
  const endpoint = API.ASSET_DOMAINS;

  try {
    dispatch({ type: AssetDomainsActionTypes.START });

    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        itemId,
      },
    });
    dispatch({
      type: AssetDomainsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: AssetDomainsActionTypes.FAILURE });
  }
};

// Add domains for asset
export enum ChangeAssetDomainsActionTypes {
  START = 'CHANGE_ASSET_DOMAINS_REQUEST',
  SUCCESS = 'CHANGE_ASSET_DOMAINS_SUCCESS',
  FAILURE = 'CHANGE_ASSET_DOMAINS_FAILURE',
}

export const changeAssetDomains = (
  domains: Array<any>,
  itemId: string
): ApiAction<ChangeAssetDomainsActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.SAVE_ASSET_DOMAINS;
  const data = { domains };

  try {
    dispatch({ type: ChangeAssetDomainsActionTypes.START });

    const response = await http.authenticated().put(endpoint, {
      data,
      pathVariables: {
        type: 'whitelist',
        itemId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: ChangeAssetDomainsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(loadAssetDomains(itemId));
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Url restrictions successfully changed.',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: ChangeAssetDomainsActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Get the global age restriction
export enum GlobalAgeRestrictionActionTypes {
  START = 'GLOBAL_AGE_RESTRICTION_REQUEST',
  SUCCESS = 'GLOBAL_AGE_RESTRICTION_SUCCESS',
  FAILURE = 'GLOBAL_AGE_RESTRICTION_FAILURE',
}

export const loadGlobalAgeRestriction = (): ApiAction<GlobalAgeRestrictionActionTypes> => async (
  dispatch,
  getState
) => {
  const endpoint = API.AGE_RESTRICTION;
  const enabledEndpoint = API.AGE_RESTRICTION_ENABLED;
  const { tenant_uuid: tenantUuid } = getState().auth.user;
  dispatch({ type: GlobalAgeRestrictionActionTypes.START });

  try {
    const enabledResponse = await http.authenticated().get(enabledEndpoint, {
      pathVariables: {
        tenantUuid,
      },
    });

    if (enabledResponse.data.age_verification_enabled) {
      const response = await http.authenticated().get(endpoint);
      dispatch({ type: GlobalAgeRestrictionActionTypes.SUCCESS, payload: response.data });
    } else {
      dispatch({ type: GlobalAgeRestrictionActionTypes.SUCCESS, payload: enabledResponse.data });
    }
  } catch (error) {
    dispatch({ type: GlobalAgeRestrictionActionTypes.FAILURE });
  }
};

// Add a global age restriction
export enum AddGlobalAgeRestrictionActionTypes {
  START = 'ADD_GLOBAL_AGE_RESTRICTION_REQUEST',
  SUCCESS = 'ADD_GLOBAL_AGE_RESTRICTION_SUCCESS',
  FAILURE = 'ADD_GLOBAL_AGE_RESTRICTION_FAILURE',
}

export const addGlobalAgeRestriction = (
  minimumAge: number
): ApiAction<AddGlobalAgeRestrictionActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.AGE_RESTRICTION;
  const data = { min_age: minimumAge };
  dispatch({ type: AddGlobalAgeRestrictionActionTypes.START });

  try {
    const response = await http.authenticated().post(endpoint, { data, contentType: ContentType.URLENCODED });

    // Set the payload to contain min_age so we can use the same reducer function as for loading
    dispatch({ type: AddGlobalAgeRestrictionActionTypes.SUCCESS, payload: { min_age: minimumAge } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: response.data.message,
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: AddGlobalAgeRestrictionActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Delete the global age restriction
export enum DeleteGlobalAgeRestrictionActionTypes {
  START = 'DELETE_GLOBAL_AGE_RESTRICTION_START',
  SUCCESS = 'DELETE_GLOBAL_AGE_RESTRICTION_SUCCESS',
  FAILURE = 'DELETE_GLOBAL_AGE_RESTRICTION_FAILURE',
}

export const deleteGlobalAgeRestriction = (): ApiAction<
  DeleteGlobalAgeRestrictionActionTypes | NotificationActionTypes
> => async (dispatch) => {
  const endpoint = API.AGE_RESTRICTION;
  dispatch({ type: DeleteGlobalAgeRestrictionActionTypes.START });

  try {
    const response = await http.authenticated().delete(endpoint, { contentType: ContentType.URLENCODED });

    dispatch({ type: DeleteGlobalAgeRestrictionActionTypes.SUCCESS });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: response.data.message,
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: DeleteGlobalAgeRestrictionActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Get the asset age restriction
export enum AssetAgeRestrictionActionTypes {
  SUCCESS = 'ASSET_AGE_RESTRICTION_SUCCESS',
  FAILURE = 'ASSET_AGE_RESTRICTION_FAILURE',
}

export const loadAssetAgeRestriction = (assetId: number): ApiAction<AssetAgeRestrictionActionTypes> => async (
  dispatch,
  getState
) => {
  const endpoint = API.ASSET_AGE_RESTRICTION;
  const enabledEndpoint = API.AGE_RESTRICTION_ENABLED;
  const { tenant_uuid: tenantUuid } = getState().auth.user;

  try {
    const enabledResponse = await http.authenticated().get(enabledEndpoint, {
      pathVariables: {
        tenantUuid,
      },
    });

    if (enabledResponse.data.age_verification_enabled) {
      const response = await http.authenticated().get(endpoint, {
        pathVariables: {
          assetId,
        },
      });
      dispatch({ type: AssetAgeRestrictionActionTypes.SUCCESS, payload: response.data });
    } else {
      dispatch({ type: AssetAgeRestrictionActionTypes.SUCCESS, payload: enabledResponse.data });
    }
  } catch (error) {
    dispatch({ type: AssetAgeRestrictionActionTypes.FAILURE });
  }
};

// Add an asset age restriction
export enum AddAssetAgeRestrictionActionTypes {
  START = 'ADD_ASSET_AGE_RESTRICTION_REQUEST',
  SUCCESS = 'ADD_ASSET_AGE_RESTRICTION_SUCCESS',
  FAILURE = 'ADD_ASSET_AGE_RESTRICTION_FAILURE',
}

export const addAssetAgeRestriction = (
  minimumAge: number,
  assetId: number
): ApiAction<AddAssetAgeRestrictionActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.ASSET_AGE_RESTRICTION;
  const data = { min_age: minimumAge };
  dispatch({ type: AddAssetAgeRestrictionActionTypes.START });

  try {
    const response = await http
      .authenticated()
      .post(endpoint, { pathVariables: { assetId }, data, contentType: ContentType.URLENCODED });

    // Set the payload to contain min_age so we can use the same reducer function as for loading
    dispatch({ type: AddAssetAgeRestrictionActionTypes.SUCCESS, payload: { min_age: minimumAge } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: response.data.message,
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;
    dispatch({ type: AddAssetAgeRestrictionActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Delete an asset age restriction
export enum DeleteAssetAgeRestrictionActionTypes {
  START = 'DELETE_ASSET_AGE_RESTRICTION_REQUEST',
  SUCCESS = 'DELETE_ASSET_AGE_RESTRICTION_SUCCESS',
  FAILURE = 'DELETE_ASSET_AGE_RESTRICTION_FAILURE',
}

export const deleteAssetAgeRestriction = (
  assetId: number
): ApiAction<DeleteAssetAgeRestrictionActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.ASSET_AGE_RESTRICTION;
  dispatch({ type: DeleteAssetAgeRestrictionActionTypes.START });

  try {
    const response = await http
      .authenticated()
      .delete(endpoint, { pathVariables: { assetId }, contentType: ContentType.URLENCODED });

    // Set the payload to contain min_age so we can use the same reducer function as for loading
    dispatch({ type: DeleteAssetAgeRestrictionActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Age restriction successfully deleted.',
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;
    dispatch({ type: DeleteAssetAgeRestrictionActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Delete all domains per asset
export enum DeleteAllAssetDomainsActionTypes {
  START = 'DELETE_ALL_ASSET_DOMAINS_REQUEST',
  SUCCESS = 'DELETE_ALL_ASSET_DOMAINS_SUCCESS',
  FAILURE = 'DELETE_ALL_ASSET_DOMAINS_FAILURE',
}

export const deleteAllAssetDomains = (
  itemId: number
): ApiAction<DeleteAllAssetDomainsActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.ASSET_DOMAINS;

  try {
    dispatch({ type: DeleteAllAssetDomainsActionTypes.START });

    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        itemId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteAllAssetDomainsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Asset domains successfully deleted.',
      })
    );
  } catch (_) {
    dispatch({ type: DeleteAllAssetDomainsActionTypes.FAILURE });
  }
};

export const SET_ASSET_DOMAIN_VALUE = 'SET_ASSET_DOMAIN_VALUE';

type setAssetDomainValueAction = Action<typeof SET_ASSET_DOMAIN_VALUE>;

export const setAssetDomainValue = (
  domain: any,
  id: number,
  type: string | undefined
): ThunkAction<void, RootState, void, setAssetDomainValueAction> => {
  const successData = { id, domain, type };
  return (dispatch, getState) => {
    const { id: merchantId } = getState().auth.user;

    return dispatch({
      type: SET_ASSET_DOMAIN_VALUE,
      successData: {
        ...successData,
        merchant_id: merchantId,
      },
    });
  };
};

export enum SetRestDomainValueActionTypes {
  SUCCESS = 'SET_REST_DOMAIN_VALUE',
}

export const setRestDomainValue = (
  domain: any,
  id: number,
  type: string
): ApiAction<SetRestDomainValueActionTypes> => async (dispatch, getState) => {
  const successData = { id, domain, type };

  const { id: merchantId } = getState().auth.user;

  try {
    dispatch({
      type: SetRestDomainValueActionTypes.SUCCESS,
      successData: {
        ...successData,
        merchant_id: merchantId,
      },
    });
  } catch (_) {}
};

export const ADD_ASSET_DOMAIN_INPUT = 'ADD_ASSET_DOMAIN_INPUT';

interface AddAssetDomainInputAction extends Action<typeof ADD_ASSET_DOMAIN_INPUT> {
  input: any;
  successData: any;
}

export const addAssetDomainInput = (input: any, isInitial: boolean): AddAssetDomainInputAction => ({
  type: ADD_ASSET_DOMAIN_INPUT,
  input,
  successData: { isInitial },
});

export const REMOVE_REST_DOMAIN_INPUT = 'REMOVE_REST_DOMAIN_INPUT';
interface RemoveRestDomainInputAction extends Action<typeof REMOVE_REST_DOMAIN_INPUT> {
  item: any;
}

export const removeRestDomainInput = (item: any): RemoveRestDomainInputAction => ({
  type: REMOVE_REST_DOMAIN_INPUT,
  item,
});

export const REMOVE_ASSET_DOMAIN_INPUT = 'REMOVE_ASSET_DOMAIN_INPUT';

interface RemoveAssetDomainInputAction extends Action<typeof REMOVE_ASSET_DOMAIN_INPUT> {
  item: any;
}

export const removeAssetDomainInput = (item: any): RemoveAssetDomainInputAction => ({
  type: REMOVE_ASSET_DOMAIN_INPUT,
  item,
});

export const RESET_RESTRICTIONS = 'RESET_RESTRICTIONS';

export const resetRestrictions = (): Action<typeof RESET_RESTRICTIONS> => ({ type: RESET_RESTRICTIONS });

export const RESET_COUNTRIES = 'RESET_COUNTRIES';

export const resetCountries = (): Action<typeof RESET_COUNTRIES> => ({ type: RESET_COUNTRIES });

// Save asset restrictions
export enum SaveAssetRestrinctionsActionTypes {
  SUCCESS = 'SAVE_ASSET_RESTRICTIONS_SUCCESS',
}

export interface SaveAssetRestrictionsPayload {
  itemId: number;
  assetCountrySetId: number;
  assetDomainRestrictions: Array<any>;
  age: number;
}

// New asset - save asset restrictions
export const saveAssetRestrictions = ({
  itemId,
  assetCountrySetId,
  assetDomainRestrictions,
  age,
}: SaveAssetRestrictionsPayload): ApiAction<SaveAssetRestrinctionsActionTypes | NotificationActionTypes> => async (
  dispatch,
  getState
) => {
  const {
    restrictions,
    items: {
      itemDetails: { id },
    },
  } = getState();
  const {
    assetCountrySet,
    assetDomainsInputs: { inputs: prevAssetDomainRestrictions },
    ageRestriction: prevAge,
  } = restrictions;
  const { id: prevAssetCountrySetId } = assetCountrySet;

  try {
    if (!assetCountrySetId && assetCountrySetId !== prevAssetCountrySetId) {
      dispatch(deleteAssetCountrySet(itemId));
    }
    if (assetCountrySetId && assetCountrySetId !== prevAssetCountrySetId) {
      if (isEmpty(assetCountrySet)) {
        dispatch(createAssetCountrySet(Number(assetCountrySetId), itemId));
      } else {
        dispatch(updateAssetCountrySet(Number(assetCountrySetId), itemId));
      }
    }

    if (
      assetDomainRestrictions.length === 0 &&
      !isEqual(prevAssetDomainRestrictions.sort(), assetDomainRestrictions.sort())
    ) {
      dispatch(deleteAllAssetDomains(itemId));
    }
    if (
      assetDomainRestrictions.length !== 0 &&
      !isEqual(prevAssetDomainRestrictions.sort(), assetDomainRestrictions.sort())
    ) {
      dispatch(changeAssetDomains(assetDomainRestrictions, itemId.toString()));
    }
    if (age && age !== prevAge) {
      dispatch(addAssetAgeRestriction(age, id));
    }

    dispatch({
      type: SaveAssetRestrinctionsActionTypes.SUCCESS,
    });
  } catch (_) {
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: 'An error occurred while updating asset restrictions.',
      })
    );
  }
};

export enum CreateTemplateRestrictionsActionTypes {
  START = 'CREATE_TEMPLATE_RESTRICTIONS_REQUEST',
  SUCCESS = 'CREATE_TEMPLATE_RESTRICTIONS_SUCCESS',
  FAILURE = 'CREATE_TEMPLATE_RESTRICTIONS_FAILURE',
}

export const createTemplateRestrictions = (
  restrictionsObject: TemplateRestrictionsRequest
): ApiAction<CreateTemplateRestrictionsActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.CREATE_TEMPLATE_RESTRICTIONS;

  dispatch({ type: CreateTemplateRestrictionsActionTypes.START });

  const restriction = [];
  const { age, assetCountrySetId, assetDomainRestrictions, itemId } = restrictionsObject;

  // check if restriction object contains age restriction
  if (age !== -1) {
    restriction.push({ category: 'age', additional_data: age });
  }

  // check if restriction object contains geo restriction
  if (assetCountrySetId !== undefined) {
    restriction.push({ category: 'geo', additional_data: assetCountrySetId });
  }

  // check if restriction object contains url restriction
  if (assetDomainRestrictions?.length) {
    assetDomainRestrictions.forEach((domainRestriction: any) => {
      restriction.push({ category: 'domain', type: 'whitelist', additional_data: domainRestriction.domain });
    });
  }

  try {
    const response = await http.authenticated().post(endpoint, {
      data: {
        restriction,
      },
      contentType: ContentType.URLENCODED,
      pathVariables: {
        template_id: itemId,
      },
    });
    dispatch({ type: CreateTemplateRestrictionsActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Template restriction successfully created.',
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: CreateTemplateRestrictionsActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

export enum UpdateTemplateRestrictionsActionTypes {
  START = 'UPDATE_TEMPLATE_RESTRICTIONS_REQUEST',
  SUCCESS = 'UPDATE_TEMPLATE_RESTRICTIONS_SUCCESS',
  FAILURE = 'UPDATE_TEMPLATE_RESTRICTIONS_FAILURE',
}

export const updateTemplateRestrictions = (
  restrictionsObject: TemplateRestrictionsRequest
): ApiAction<UpdateTemplateRestrictionsActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.CREATE_TEMPLATE_RESTRICTIONS;

  dispatch({ type: UpdateTemplateRestrictionsActionTypes.START });

  const restriction = [];
  const { age, assetCountrySetId, assetDomainRestrictions, itemId } = restrictionsObject;

  // check if restriction object contains age restriction
  if (age !== -1 && !!age) {
    restriction.push({ category: 'age', additional_data: age });
  }

  // check if restriction object contains geo restriction
  if (assetCountrySetId) {
    restriction.push({ category: 'geo', additional_data: assetCountrySetId });
  }

  // check if restriction object contains url restriction
  if (assetDomainRestrictions?.length) {
    assetDomainRestrictions.forEach((domainRestriction: any) => {
      restriction.push({ category: 'domain', type: 'whitelist', additional_data: domainRestriction.domain });
    });
  }

  try {
    const response = await http.authenticated().put(endpoint, {
      data: {
        restriction,
      },
      pathVariables: {
        template_id: itemId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: UpdateTemplateRestrictionsActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: response.data.message,
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: UpdateTemplateRestrictionsActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

export enum DeleteTemplateRestrictionsActionTypes {
  START = 'DELETE_TEMPLATE_RESTRICTIONS_REQUEST',
  SUCCESS = 'DELETE_TEMPLATE_RESTRICTIONS_SUCCESS',
  FAILURE = 'DELETE_TEMPLATE_RESTRICTIONS_FAILURE',
}

export const deleteTemplateRestrictions = (
  templateId: number,
  restrictionId: number
): ApiAction<DeleteTemplateRestrictionsActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.DELETE_TEMPLATE_RESTRICTIONS;
  const successData = { restrictionId };

  dispatch({ type: DeleteTemplateRestrictionsActionTypes.START });

  try {
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        template_id: templateId,
        restriction_id: restrictionId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: DeleteTemplateRestrictionsActionTypes.SUCCESS, payload: { ...successData, ...response.data } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: response.data.message,
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: DeleteTemplateRestrictionsActionTypes.FAILURE });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};
