import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Formik, FormikHelpers, Field, FormikProps } from 'formik';
import { object, string } from 'yup';

// utils
import values from 'lodash/values';

// actions
import { setPaymentProvider } from 'ducks/adminDucks/merchants/actions';

// selectors
import { getPaymentSetup } from 'ducks/adminDucks/merchants/selectors';

// types
import { ContentIndexes } from 'pages/AdminPages/AdminMerchants/PaymentManagement/components/PaymentSetup';
import { PaymentMethodKeyItem, ProviderItem } from 'ducks/adminDucks/merchants/types';

// styled components
import { CancelButton, ItalicDescription } from 'components/SharedStyledElements';

// components
import { Button, Grid } from '@inplayer-org/inplayer-ui';
import FieldError from 'components/Fields/FieldError';
import StyledContainer from 'components/StyledContainer';
import Select from 'components/Fields/Select';

const { Container, Cell } = Grid;

interface GatewayOptions {
  displayName: string;
  value: number;
}

interface FormValues {
  method: string;
  gateway: string;
}

interface OwnProps {
  handleCloseModal: () => void;
  handleContentIndexChange: (index: number) => void;
  handleSelectedGatewayChange: (name: string) => void;
}

type Props = OwnProps;

const validationSchema = object().shape({
  method: string().required('*Required'),
  gateway: string().required('*Required'),
});

const PaymentGatewayForm: FunctionComponent<Props> = ({
  handleCloseModal,
  handleContentIndexChange,
  handleSelectedGatewayChange,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const merchantId = Number(id);

  const [isMethodSelected, setIsMethodSelected] = useState(false);
  const [gatewayOptions, setGatewayOptions] = useState<Array<any>>([]);
  const [descriptionPaymentName, setDescriptionPaymentName] = useState('');

  const { paymentMethods } = useSelector(getPaymentSetup);

  const paymentMethodOptions = values(paymentMethods).map((method: PaymentMethodKeyItem) => ({
    displayName: method.name,
    value: method.id,
  }));

  const initialValues = {
    method: '',
    gateway: '',
  };

  const handlePaymentMethodChange = (methodId: string) => {
    setIsMethodSelected(true);
    const paymentMethod = values(paymentMethods).find((method: PaymentMethodKeyItem) => method.id === Number(methodId));
    const { name, providers } = paymentMethod;
    const paymentName = ['Ideal', 'Direct Debit'].includes(name) ? name : '';
    setDescriptionPaymentName(paymentName);
    const providersOptions = values(providers).map((provider: ProviderItem) => ({
      displayName: provider.name,
      value: provider.id,
    }));
    setGatewayOptions(providersOptions);
  };

  const onFormSubmit = async (formikValues: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    const { method, gateway } = formikValues;
    const methodId = Number(method);
    const paymentProviderId = Number(gateway);
    const selectedGateway = gatewayOptions
      .find((option: GatewayOptions) => option.value === paymentProviderId)
      .displayName.trim();

    try {
      await dispatch(
        setPaymentProvider({
          merchantId,
          methodId,
          paymentProviderId,
          current: true,
        })
      );
      handleContentIndexChange(ContentIndexes.SETUP);
      handleSelectedGatewayChange(selectedGateway);
      resetForm();
      setSubmitting(false);
    } catch (e) {
      resetForm();
      setSubmitting(false);
    }
  };

  const handleClose = (resetForm: () => void) => () => {
    resetForm();
    handleCloseModal();
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, isSubmitting, resetForm, isValid, dirty }: FormikProps<FormValues>) => {
        const disableSubmitButton = !isValid || isSubmitting || !dirty;

        return (
          <form onSubmit={handleSubmit}>
            <Container alignContent="space-around" columns={1}>
              <Cell>
                <Field
                  name="method"
                  label="Payment method"
                  component={Select}
                  defaultOption={{ displayName: 'Select payment method' }}
                  options={paymentMethodOptions}
                  handleChange={handlePaymentMethodChange}
                />
                <FieldError name="method" />
              </Cell>
              <Cell>
                <Field
                  name="gateway"
                  label="Payment gateway"
                  component={Select}
                  defaultOption={{ displayName: 'Select payment gateway' }}
                  options={gatewayOptions}
                  disabled={!isMethodSelected}
                />
                <FieldError name="subject" />
              </Cell>
              {descriptionPaymentName && (
                <Cell>
                  <ItalicDescription variant="p">
                    {descriptionPaymentName} is a payment method which happens via direct bank transfers. Because of
                    this, InPlayer can be vulnerable to fraud via customer cancelations or refunds. <br /> To avoid this
                    risk, we strongly suggest enabling these payment methods only for merchants with Stripe Connect.
                  </ItalicDescription>
                </Cell>
              )}
            </Container>

            <StyledContainer columns={4} alignContent="center" justifyContent="center" margin="2rem 0 0 0">
              <Cell middle left={3}>
                <CancelButton type="button" fullWidth onClick={handleClose(resetForm)}>
                  Cancel
                </CancelButton>
              </Cell>
              <Cell middle left={4}>
                <Button type="submit" fullWidth buttonModifiers={['buttonPrimary']} disabled={disableSubmitButton}>
                  Save
                </Button>
              </Cell>
            </StyledContainer>
          </form>
        );
      }}
    </Formik>
  );
};

export default PaymentGatewayForm;
