import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import { FetchGoogleInAppKeysActionTypes, FetchAppleInAppKeysActionTypes } from 'ducks/adminDucks/integrations/actions';
import { MerchantIntegrations } from 'ducks/adminDucks/integrations/types';
import find from 'lodash/find';

export const initialState: MerchantIntegrations = {
  googleInAppKeys: {},
  appleInAppKeys: {},
  isFetching: false,
};

type AdminMerchantIntegrations = Reducer<MerchantIntegrations>;

const promptGoogleInAppKeys: AdminMerchantIntegrations = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const loadGoogleInAppKeysSuccess: AdminMerchantIntegrations = (state = initialState, { payload }) => {
  if (payload) {
    return {
      ...state,
      googleInAppKeys: {
        playstore_public_key: payload.playstore_public_key,
        site_token: payload.site_token,
      },
      isFetching: false,
    };
  }

  return state;
};

const loadGoogleInAppKeysFailure: AdminMerchantIntegrations = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const promptAppleInAppKeys: AdminMerchantIntegrations = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const loadAppleInAppKeysSuccess: AdminMerchantIntegrations = (state = initialState, { payload }) => {
  const appleInAppKeys = find(payload.metadata, {
    name: 'secretKey',
  });

  return {
    ...state,
    appleInAppKeys,
    isFetching: false,
  };
};

const loadAppleInAppKeysFailure: AdminMerchantIntegrations = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const actionsMap: ActionMap<any> = {
  [FetchGoogleInAppKeysActionTypes.START]: promptGoogleInAppKeys,
  [FetchGoogleInAppKeysActionTypes.SUCCESS]: loadGoogleInAppKeysSuccess,
  [FetchGoogleInAppKeysActionTypes.ERROR]: loadGoogleInAppKeysFailure,
  [FetchAppleInAppKeysActionTypes.START]: promptAppleInAppKeys,
  [FetchAppleInAppKeysActionTypes.SUCCESS]: loadAppleInAppKeysSuccess,
  [FetchAppleInAppKeysActionTypes.ERROR]: loadAppleInAppKeysFailure,
};

export default reducerWithActionMap(actionsMap, initialState);
