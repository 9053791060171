import React, { memo, FunctionComponent } from 'react';
import styled from 'styled-components';
import { Colors } from 'pages/MerchantPages/Authentication/components/colors';
import { AnalyticsComponent } from '@inplayer-org/inplayer-ui';

interface FooterLinkProps {
  displayBorder: boolean;
}

const FooterLink = styled.a<FooterLinkProps>`
  display: inline;
  padding: 0 1.5rem;
  ${({ displayBorder }) => displayBorder && `border-left: 0.5px solid ${Colors.black};`}
  font-size: ${({ theme }) => theme.font.sizes.small};
  color: ${Colors.black};

  &:hover {
    color: ${({ theme }) => theme.palette.text.main};
  }
`;

interface FooterLink {
  text: string;
  options: {
    href: string;
  };
}

interface Props {
  link: FooterLink;
  displayBorder: boolean;
}

const FooterLinks: FunctionComponent<Props> = ({ link, displayBorder }) => (
  <AnalyticsComponent>
    {({ pages, tracker, merchantId, ip }) => (
      <FooterLink
        data-testid={`footer-link-${link.text}`}
        target="_blank"
        key={link.text}
        href={link.options.href}
        displayBorder={displayBorder}
        onClick={() => {
          tracker.track({
            event: 'click',
            type: 'link',
            tag: `footer_link_${link.text.toLowerCase()}`,
            pages,
            merchantId,
            ip,
          });
        }}
      >
        {link.text}
      </FooterLink>
    )}
  </AnalyticsComponent>
);

export default memo(FooterLinks);
