import React, { FunctionComponent } from 'react';
import { ErrorMessage, ErrorMessageProps } from 'formik';
import { Colors } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';

const ErrorMessageWrapper = styled(ErrorMessage)<ErrorMessageProps>`
  margin-top: 0.5rem;
  display: block;
  color: ${Colors.red};
`;

export const ErrorMessageWrapperSpan = styled.span`
  margin-top: 0.5rem;
  display: block;
  color: ${Colors.red};
`;

const FieldError: FunctionComponent<ErrorMessageProps> = ({ className, ...rest }) => (
  <ErrorMessageWrapper component="span" className={className} {...rest} />
);

export default FieldError;
