import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import awsIot from 'aws-iot-device-sdk';

import { IOT_ENDPOINT } from 'constants/index';
import { notifRequested } from 'middleware/notifications/actions';
import tokenManager from 'utils/TokenManager';
import {
  syncProgressNotification,
  setPanoptoContent,
  getBulkErrorFiles,
  BulkCodeActionTypes,
  loadCodes,
} from 'ducks/merchantDucks/items/actions';
import RootState from 'ducks/RootState';
import { loadVoucherEmails, getErrorFiles as getBulkVoucherErrorFiles } from 'ducks/merchantDucks/vouchers/actions';
import { getErrorFiles as getBulkAccessErrorFiles } from 'ducks/merchantDucks/accesses/actions';

export enum UserNotificationActionTypes {
  NOTIFY_REQUEST = 'NOTIFY_REQUEST',
  NOTIFY_SUCCESS = 'NOTIFY_SUCCESS',
  NOTIFY_ERROR = 'NOTIFY_FAILURE',
}

interface AMToken {
  region: string;
  accessKey: string;
  secretKey: string;
  sessionToken: string;
}

type Protocol = 'wss' | 'mqtts';

const connectToSocket = (isAdmin = false) => {
  const protocol: Protocol = 'wss';
  const { IAMToken, IAMAdminToken } = tokenManager;

  if ((!isAdmin && !IAMToken) || (isAdmin && !IAMAdminToken)) {
    return null;
  }

  const credentials = {
    port: 443,
    protocol,
    host: IOT_ENDPOINT,
    region: isAdmin ? IAMAdminToken?.region : IAMToken?.region,
    accessKeyId: isAdmin ? IAMAdminToken?.accessKey : IAMToken?.accessKey,
    secretKey: isAdmin ? IAMAdminToken?.secretKey : IAMToken?.secretKey,
    sessionToken: isAdmin ? IAMAdminToken?.sessionToken : IAMToken?.sessionToken,
  };

  // eslint-disable-next-line new-cap
  return new awsIot.device(credentials);
};

export const notifyUser = (uuid: string): ThunkAction<void, RootState, void, AnyAction> => (dispatch, getState) => {
  const {
    notifications: { isSubscribed, isSubscribing, isFailed },
    auth: { isAdmin },
  } = getState();

  if (isSubscribed || isSubscribing || isFailed) {
    // stop trying if the user is subscribed or it is failed
    return null;
  }

  dispatch({ type: UserNotificationActionTypes.NOTIFY_REQUEST });

  const client: awsIot.device | null = connectToSocket(isAdmin);

  if (client) {
    client.on('connect', () => {
      dispatch({ type: UserNotificationActionTypes.NOTIFY_SUCCESS });
      if (isAdmin) {
        tokenManager.clientAdmin = client;
      } else {
        tokenManager.client = client;
      }
      client.subscribe(uuid);
    });

    client.on('message', (_: any, message: string) => {
      const decodedMessage = JSON.parse(message.toString());

      const { type, resource } = decodedMessage;

      const isBulkAccessActions =
        type === 'bulk.grant.access' || type === 'bulk.revoke.access' || type === 'bulk.extend.access';

      if (isBulkAccessActions) {
        if (resource.code > 400) {
          dispatch(
            notifRequested({
              title: 'ERROR',
              content: resource.message || 'Check the generated file for more details on the error',
              type: 'danger',
            })
          );
          dispatch(getBulkAccessErrorFiles(type.split('.')[1]));
        } else {
          dispatch(
            notifRequested({
              title: 'SUCCESS',
              content: resource.message || 'The file upload is completed',
              type: 'success',
            })
          );
        }
      }

      if (type === 'bulk.voucher.rules.succeed') {
        dispatch(
          notifRequested({
            title: 'SUCCESS',
            content: resource.message,
            type: 'success',
          })
        );

        const {
          vouchers: {
            voucherDetails: { id: voucherId },
          },
        } = getState();
        dispatch(loadVoucherEmails({ id: Number(voucherId) }));
        dispatch(getBulkVoucherErrorFiles());
      }

      if (type === 'bulk.voucher.rules.failed') {
        dispatch(
          notifRequested({
            title: 'ERROR',
            content: resource.message,
            type: 'danger',
          })
        );
        dispatch(getBulkVoucherErrorFiles());
      }

      if (type === 'bulk.access.codes.succeed') {
        dispatch({ type: BulkCodeActionTypes.SUCCESS });
        dispatch(
          notifRequested({
            title: 'SUCCESS',
            content: resource.message,
            type: 'success',
          })
        );
        const {
          items: {
            itemDetails: { id: itemId },
          },
        } = getState();
        dispatch(loadCodes({ itemId, page: 0 }));
        dispatch(getBulkErrorFiles());
      }

      if (type === 'bulk.access.codes.failed') {
        dispatch({ type: BulkCodeActionTypes.ERROR });
        dispatch(
          notifRequested({
            title: 'ERROR',
            content: resource.message,
            type: 'danger',
          })
        );
        dispatch(getBulkErrorFiles());
      }

      if (type === 'report.create' && resource.status === 'created') {
        dispatch(
          notifRequested({
            title: 'SUCCESS',
            content: 'The report was successfully generated. Click on View Reports button to see it.',
            type: 'success',
          })
        );
      } else if (type === 'report.create' && resource.status === 'failed') {
        dispatch(
          notifRequested({
            title: 'ERROR',
            content: resource.message,
            type: 'danger',
          })
        );
      }

      // Panopto asset
      if (type === 'panopto.postback') {
        dispatch(setPanoptoContent(resource));
      }

      /* SYNC OVPs */
      if (type === 'sync.success') {
        if (resource.done) {
          dispatch(syncProgressNotification());
          dispatch(
            notifRequested({
              title: 'SUCCESS',
              content: resource.message,
              type: 'success',
            })
          );
        } else {
          notifRequested({
            title: 'ERROR',
            content: resource.message,
            type: 'danger',
          });
        }
      } else if (type === 'sync.failed') {
        notifRequested({
          title: 'ERROR',
          content: resource.message,
          type: 'danger',
        });
      }
    });
    client.on('close', () => console.log('Connection closed.'));
    client.on('error', () => {
      console.log('Error occured');
    });
  } else {
    dispatch({ type: UserNotificationActionTypes.NOTIFY_ERROR });
  }
};
