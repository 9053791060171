import React, { FC, ReactElement, useLayoutEffect } from 'react';
import { isUndefined } from 'lodash';
import { useStatePiece } from 'react-pieceful-state';

// hoc
import withPiecefulProvider from 'components/hocs/withPiecefulProvider';

// local components
import SwitchIterator from './SwitchIterator';

// local types
import { Props as SwitcheeProps } from './Switchee';

export const SWITCHER_BASE = 'switchState';
export const SWITCHER_REGION = '__switcher';

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
type XorY<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

interface ControlledValue {
  value: any;
}

interface UncontrolledInitialValue {
  initialValue: any;
}

type SwitchValueProps = XorY<ControlledValue, UncontrolledInitialValue>;

export type Props = {
  switchId?: string;
  children: ReactElement<SwitcheeProps> | ReactElement<SwitcheeProps>[];
} & SwitchValueProps;

export type SwitchProviderProps = Props;

const Switcher: FC<Props> = ({ value, initialValue, children, switchId = SWITCHER_REGION }) => {
  const [{ value: currentValue, isControlled }, setValue] = useStatePiece(
    SWITCHER_BASE,
    {
      value: !isUndefined(value) ? value : initialValue,
      initialValue: !isUndefined(value) ? value : initialValue,
      isControlled: !isUndefined(value),
      meta: undefined as any,
    },
    switchId
  );

  useLayoutEffect(() => {
    if (isControlled && value !== currentValue) {
      setValue((state) => ({ ...state, value }));
    }
  }, [value]);

  return <SwitchIterator switchId={switchId}>{children}</SwitchIterator>;
};

export default withPiecefulProvider(Switcher, ({ switchId = SWITCHER_REGION }) => switchId);
