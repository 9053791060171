import { Action, Middleware, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

// utils
import { API } from 'constants/api';
import { INPLAYER_UUID } from 'constants/index';
import http, { ContentType } from 'utils/http';
import TokenManager from 'utils/TokenManager';
import inRange from 'lodash/inRange';
import includes from 'lodash/includes';

// types
import RootState from 'ducks/RootState';

export const RELOGIN_USER_REQUEST = 'RELOGIN_USER_REQUEST';
export const RELOGIN_USER_SUCCESS = 'RELOGIN_USER_SUCCESS';
export const RELOGIN_USER_FAILURE = 'RELOGIN_USER_FAILURE';

export enum ReloginUserActionTypes {
  START = 'RELOGIN_USER_REQUEST',
  SUCCESS = 'RELOGIN_USER_SUCCESS',
  FAILURE = 'RELOGIN_USER_FAILURE',
}

type ReloginUserAction = Action<ReloginUserActionTypes>;

export const refreshToken = (): ThunkAction<void, RootState, void, ReloginUserAction> => async (dispatch) => {
  const {
    location: { pathname },
  } = window;
  const isAdminRequest = pathname.includes('/admin');
  const isExpired = isAdminRequest ? TokenManager.isAdminExpired : TokenManager.isExpired;
  if (isExpired && !TokenManager.getIsFetching()) {
    TokenManager.setIsFetching();
    dispatch({ type: ReloginUserActionTypes.START });

    const formData = new FormData();

    const refreshTokenString = isAdminRequest ? TokenManager.admin?.adminRefreshToken : TokenManager.refreshToken;

    try {
      if (refreshTokenString) {
        formData.append('refresh_token', refreshTokenString);
        formData.append('client_id', INPLAYER_UUID);
        formData.append('grant_type', 'refresh_token');

        const response = await http.post(API.LOGIN, {
          data: formData,
          contentType: ContentType.FORM_DATA,
        });

        TokenManager.setIsFetching();

        if (inRange(response.status, 100, 600) && (response.status < 200 || response.status >= 300)) {
          dispatch({ type: ReloginUserActionTypes.FAILURE });
        } else {
          dispatch({
            type: ReloginUserActionTypes.SUCCESS,
            payload: { ...response.data },
          });
        }
      }
    } catch (_) {
      dispatch({ type: ReloginUserActionTypes.FAILURE });
    }
  }
};

const jwt: Middleware<{}, RootState, ThunkDispatch<RootState, void, AnyAction>> = ({ dispatch }) => (next) => async (
  action
) => {
  if (includes(Object.values(ReloginUserActionTypes), action.type)) {
    return next(action);
  }

  if (!TokenManager.getIsFetching()) {
    await dispatch(refreshToken());
  }

  return next(action);
};

export default jwt;
