import styled from 'styled-components';
import { transparentize } from 'polished';
import { FaRegEdit } from 'react-icons/fa';
import { IoIosCheckmark } from 'react-icons/io';

// styled components
import { BlueTypography } from 'components/SharedStyledElements';
import { CustomCard } from 'components/CustomCard';

// components
import { Tooltip, Colors, Button } from '@inplayer-org/inplayer-ui';
import ContentHolder from 'components/ContentHolder';

interface FlexContainerProps {
  alignItems?: string;
  justifyContent?: string;
  flexDirection?: string;
  width?: string;
  margin?: string;
  height?: string;
}

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};
  ${({ width }) => width && `width: ${width}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ height }) => height && `height: ${height}`};
`;

interface ContentHolderProps {
  customerID?: number;
}

export const CustomerContentHolder = styled(ContentHolder)<ContentHolderProps>`
  ${({ customerID }: ContentHolderProps) => customerID && 'width: 100%'};
`;

export const StyledLabel = styled.label`
  margin-top: 1.5rem;
  margin-bottom: 0.3rem;
`;

// Tabs
export const TabsWrapper = styled.div`
  margin: 2% 25%;
`;

// Table
export const TableContainer = styled.div`
  margin: 1.5rem 0;
`;

export const HeaderContainer = styled.div<{ fullWidth?: boolean }>`
  display: grid;
  grid-template-columns: ${({ fullWidth }) => (fullWidth ? '1fr' : '5fr 1fr')};
  grid-gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
`;

export const StyledTooltip = styled(Tooltip)`
  grid-column: 1 / span 2;
  width: fit-content;
`;

export const Heading = styled(BlueTypography)`
  margin: 0;
`;

export const SideBySideDropdowns = styled.div<{ paddingRight?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  grid-auto-rows: min-content;
  grid-gap: 2%;
  grid-auto-columns: minmax(max-content, 1fr);
  ${({ paddingRight }) => paddingRight && 'padding-right: 1rem;'}
`;

export const StyledSideBySideDropdowns = styled(SideBySideDropdowns)`
  grid-column-start: 3;
`;

// Card
export const StyledCustomCard = styled(CustomCard)`
  border-radius: 5px;
  box-shadow: 0 0 8px 1px ${transparentize(0.87, Colors.fontDarkGray)};
`;

export const StyledFFCustomCard = styled(CustomCard)`
  border-radius: 5px;
  box-shadow: 0 0 8px 1px ${transparentize(0.87, Colors.fontDarkGray)};
  align-items: flex-start;
`;

export const CardContent = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
`;

export const StyledButton = styled(Button)`
  align-self: center;
  width: 11rem;
  margin-left: 1rem;
`;

export const IconWrapper = styled(FlexContainer)`
  align-items: center;
  align-self: center;
`;

export const StyledEditIcon = styled(FaRegEdit)`
  font-size: 1.3rem;
  cursor: pointer;
  align-self: center;
`;

interface StyledReactIconProps {
  color: string;
}
export const StyledReactIcon = styled(IoIosCheckmark)<StyledReactIconProps>`
  width: 2.2rem;
  height: 2.2rem;
  color: ${({ color }) => color};
  cursor: pointer;
`;

export const MarginDiv = styled.div`
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
`;
