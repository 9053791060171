import { useEffect } from 'react';

interface UseScriptProps extends Partial<Omit<HTMLScriptElement, 'src'>> {
  onLoad?: () => any | ((this: GlobalEventHandlers, ev: Event) => any) | null;
  shouldLoad?: () => boolean;
}

const useScript = (src: string, { onLoad, shouldLoad, ...attributes }: UseScriptProps) => {
  useEffect(() => {
    if (shouldLoad && !shouldLoad()) {
      if (onLoad) {
        onLoad();
      }

      return;
    }

    const script = document.createElement<'script'>('script');

    script.src = src;

    Object.assign(script, attributes);

    if (onLoad) {
      script.onload = onLoad;
    }

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
};

export default useScript;
