import React from 'react';
import styled from 'styled-components';

// components
import {
  CenteredContainer,
  StyledTypography as Typography,
} from 'pages/MerchantPages/Authentication/components/sharedComponents';
import ParagraphDescription from './ParagraphDescription';

const StyledSpan = styled.span`
  color: red;
`;

const StyledTypography = styled(Typography)`
  font-weight: 800;
  font-size: ${({ theme }) => theme.font.sizes.extraLarge};
  line-height: initial;
`;

const MarginWrapper = styled.div`
  margin: 0 5rem 8rem 0;
`;

const RegisterInformation = () => {
  return (
    <>
      <CenteredContainer>
        <MarginWrapper>
          <StyledTypography variant="h1">
            Create Your <br /> InPlayer Account<StyledSpan>.</StyledSpan>
          </StyledTypography>

          <ParagraphDescription title="Become a merchant" description="Enter your email address to create an account" />
          <ParagraphDescription title="Simple integration" description="Enable the paywall solution or use our API" />
          <ParagraphDescription
            title="Start accepting payments"
            description="Get a demo account or just go live in minutes"
          />
        </MarginWrapper>
      </CenteredContainer>
    </>
  );
};
export default RegisterInformation;
