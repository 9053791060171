import moment, { Moment } from 'moment';
import { TimePeriodValues } from './shared/sharedTypes';

export const dateFormat = 'YYYY-MM-DD';
export const hourFormat = 'HH:mm:ss';

export const formatDates = (date: Moment, time?: Moment) =>
  `${date.format(dateFormat)} ${time ? time.format(hourFormat) : moment().format(hourFormat)}`;

export const getPriceDescription = (price: any) =>
  `${price.description} ${price.access_type.name} ${price.access_type.quantity} ${price.access_type.period} ${price.amount} ${price.currency}`;

export const formatAccessDuration = (timePeriod: string, timeValue: string) =>
  `P${timePeriod === TimePeriodValues.HOURS ? 'T' : ''}${timeValue}${timePeriod.toUpperCase()}`;

export const getAccessDurationValues = (accessDuration: string) => {
  if (!accessDuration) return { timeValue: '', timePeriod: TimePeriodValues.DAYS };
  const timePeriod = accessDuration.slice(-1);
  const timeValue = accessDuration.replace(/\D/g, '');

  return { timeValue, timePeriod };
};
