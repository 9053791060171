import React, { ReactNode } from 'react';

interface Props {
  isRestricted?: boolean;
  children: ReactNode;
}

const Restricted = ({ isRestricted = false, children }: Props) => {
  if (isRestricted) {
    return null;
  }

  return <>{children}</>;
};

export default Restricted;
