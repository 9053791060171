import { CountryGeoData } from 'data/countries';
import { SubscriptionFilterTypes } from './actions';

export interface SubscriptionDetails {
  isFetching: boolean;
  subscription_details: {
    consumer_name: string;
    country: string;
    item_title: string;
    item_type: string;
    total_rebills: number;
    currency_iso: string;
    subscription_price: number;
    payment_provider: string;
    canceled_at: number;
    next_rebill_date: number;
    setup_fee: {
      description: string;
      fee_amount: number;
    };
    total_charged_amount: number;
  } | null;
  total: number;
  size: number;
  page: number;
  subscription_history: any;
}

export enum SubscriptionActionType {
  ALL = 'all',
  CANCELLED = 'canceled',
  RECURRENT = 'recurrent',
  FREE_TRIAL = 'free-trial',
  PAST_DUE = 'past_due',
  UNPAID = 'unpaid',
  ENDED = 'ended',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAUSED = 'paused',
}

export interface SubActionType {
  action_type: string;
  total_subscriptions: number;
}

export interface TotalSubsPerState extends SubActionType {
  sub_action_type?: SubActionType;
}

export interface SubscriptionsGroupedBy {
  id: number;
  description: string;
  total_subscriptions: number;
  total_subscriptions_per_state: Array<TotalSubsPerState>;
}

export interface SubscriptionFilterDetails {
  country_name: string;
  country_code: string;
  grouped_by: string;
  subscriptions_grouped_by: Array<SubscriptionsGroupedBy>;
  total_subscriptions: number;
}

export interface SubscriptionsCountryInfo extends SubscriptionFilterDetails {
  geoData: CountryGeoData;
}

type Subscriptions = {
  [K in SubscriptionFilterTypes]: Array<SubscriptionFilterDetails>;
};

type Collection = {
  access_fee_description?: string;
  item_title?: string;
  total: number;
};

type SubscriptionGroup = {
  isFetching: boolean;
  collection: Array<Collection>;
};

type SubscriptionByStatusState = {
  price: SubscriptionGroup;
  item: SubscriptionGroup;
};

type SubscriptionStatuses = {
  isFetching: boolean;
  statuses: Record<string, any>;
};

export default interface SubscriptionState extends Subscriptions {
  collection: Array<any>;
  total: number;
  page: number;
  limit: number;
  isFetching: boolean;
  isProcessing: boolean;
  success: boolean;
  subscriptionDetails: SubscriptionDetails;
  allCountriesWithSubscriptions: Array<SubscriptionsCountryInfo>;
  totalSubscriptionsStatuses: SubscriptionStatuses;
  activeSubscriptions: SubscriptionByStatusState;
  canceledSubscriptions: SubscriptionByStatusState;
  countriesMapData: any;
}
