import React, { ReactNode } from 'react';

export interface Props {
  value: any | any[];
  children: ReactNode;
}

const Switchee = ({ children }: Props) => <>{children}</>;

export default Switchee;
