import * as React from 'react';

// pictures

import { Colors, Tooltip, AnalyticsComponent } from '@inplayer-org/inplayer-ui';
import styled, { InterpolationFunction, StyledProps } from 'styled-components';
import { css } from '@styled';
import { Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import { MdOndemandVideo, MdInfoOutline } from 'react-icons/md';

// types
import { ItemType } from 'ducks/merchantDucks/items/actions';

// utils
import { unescapeText } from 'utils/helpers';
import formatNumber from 'utils/formatNumber';
import media from 'utils/mediaQueries';

// components
import SingleTopInfo from 'pages/MerchantPages/Analytics/components/SingleTopInfo';
import ListTopInfo from 'pages/MerchantPages/Analytics/components/ListTopInfo';
import { snakeCase } from 'lodash';

type Customer = {
  full_name: string;
  number_of_payments: number;
};

interface OwnProps {
  reportInformation: any;
}

type Props = OwnProps;

const getIconStyles = () => ({
  width: '15%',
  height: '15%',
  color: Colors.fontGray,
  margin: '1rem 0',
});

export const FlexContainer = styled.li`
  display: flex;
  justify-content: space-between;
`;

const UserIcon = styled(FaUser)`
  ${getIconStyles}
`;

const VideoIcon = styled(MdOndemandVideo)`
  ${getIconStyles}
`;

const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  text-align: center;
`;

export interface SpanProps {
  isActive?: boolean;
  highlight?: boolean;
}

export const getStyledSpanActiveStyles: InterpolationFunction<StyledProps<SpanProps>> = ({
  isActive,
  highlight,
  theme,
}) =>
  isActive &&
  css`
    font-weight: ${theme.font.weights.semiBold};
    font-size: ${!highlight && theme.font.sizes.h6};
  `;

export const StyledSpan = styled.span<SpanProps>`
  ${getStyledSpanActiveStyles};
  color: ${({ highlight }) => highlight && Colors.belizeHole};

  ${media.customMaxWidth(1420)} {
    font-size: ${({ theme }) => theme.font.sizes.medium};
  }

  ${media.customMaxWidth(1040)} {
    font-size: ${({ theme }) => theme.font.sizes.small};
  }
`;

export const SpanContainer = styled.div<SpanProps>`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIcon = styled(MdInfoOutline)`
  width: 17px;
  height: 17px;
  margin-top: 5px;
  margin-left: 5px;
`;

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export enum EditRoutes {
  Audience = 'audience/edit',
  Assets = 'assets/edit',
  Packages = 'assets/packages/edit',
}

interface Resource {
  name: string;
  numberOfPayments: string;
  id?: string;
  type: string;
  editRoute: string;
}
interface TopInfoListComponentProps {
  resource: Resource;
  index: number;
  allCurrencies?: () => any;
  shouldBeSnakeCased?: boolean;
}

const TopInfoListComponent: React.FC<TopInfoListComponentProps> = ({
  resource,
  index,
  allCurrencies,
  shouldBeSnakeCased = false,
}) => {
  const { name } = resource;
  const unescapedName = unescapeText(name);

  return (
    <FlexContainer key={index} data-testid={`render-resource-${resource.id}`}>
      <AnalyticsComponent>
        {({ pages, tracker, merchantId, ip }) => (
          <StyledSpan isActive={index === 0}>
            {`${index + 1}. `}
            <StyledLink
              onClick={() => {
                tracker.track({
                  event: 'click',
                  type: 'link',
                  tag: shouldBeSnakeCased ? `link_${snakeCase(unescapedName)}` : `link_${unescapedName}`,
                  pages,
                  merchantId,
                  ip,
                });
              }}
              to={`/${resource.editRoute}/${resource.id}`}
            >
              {unescapedName}
            </StyledLink>
          </StyledSpan>
        )}
      </AnalyticsComponent>
      <SpanContainer isActive={index === 0}>
        <StyledSpan isActive={index === 0} highlight>
          {formatNumber(Number(resource.numberOfPayments))} {index === 0 && ` payments`}
        </StyledSpan>
        {resource.type === 'assets' && allCurrencies && (
          <Tooltip content={`Revenue:\n ${allCurrencies()}`} placement="top">
            <StyledIcon />
          </Tooltip>
        )}
      </SpanContainer>
    </FlexContainer>
  );
};

class TopInfo extends React.PureComponent<Props> {
  renderCustomers = (customer: { email: string; number_of_payments: string; id: string }, i: number) => (
    <TopInfoListComponent
      key={customer.id}
      resource={{
        name: customer.email,
        numberOfPayments: customer.number_of_payments,
        id: customer.id,
        type: 'audience',
        editRoute: EditRoutes.Audience,
      }}
      index={i}
    />
  );

  renderAssets = (
    asset: { title: string; number_of_purchases: string; revenues: any; id: string; type: string },
    i: number
  ) => {
    const allCurrencies = () => {
      return asset.revenues
        .map((revenue: any) => {
          return `${formatNumber(revenue.total_earned_amount.toFixed(2))} ${revenue.currency_iso}\n`;
        })
        .join('');
    };

    const editRoute = asset.type === ItemType.Package ? EditRoutes.Packages : EditRoutes.Assets;

    return (
      <TopInfoListComponent
        data-testid={`render-assets-${asset.title}`}
        key={`${asset.title}${asset.revenues}${asset.number_of_purchases}`}
        resource={{
          name: asset.title,
          numberOfPayments: asset.number_of_purchases,
          type: 'assets',
          id: asset.id,
          editRoute,
        }}
        index={i}
        allCurrencies={allCurrencies}
        shouldBeSnakeCased
      />
    );
  };

  render() {
    const { reportInformation } = this.props;
    const topCustomerEmail = reportInformation.topFiveCustomers[0]?.email;
    const topCustomerId = reportInformation.topFiveCustomers[0]?.id;
    const topAssetId = reportInformation.topFiveAssets[0]?.id;

    return (
      <Section>
        <SingleTopInfo
          title="Top Customer"
          icon={UserIcon}
          subtitle={reportInformation?.topFiveCustomers[0]?.full_name || 'N/A'}
          amount={`Number of payments: ${
            reportInformation.topFiveCustomers.length
              ? formatNumber(reportInformation.topFiveCustomers[0].number_of_payments)
              : 'N/A'
          }`}
          email={topCustomerEmail}
          itemId={topCustomerId}
        />
        <SingleTopInfo
          title="Top Asset"
          icon={VideoIcon}
          subtitle={reportInformation?.topFiveAssets[0]?.title || 'N/A'}
          amount={`Number of purchases: ${
            reportInformation.topFiveAssets.length
              ? formatNumber(reportInformation.topFiveAssets[0].number_of_purchases)
              : 'N/A'
          }`}
          itemId={topAssetId}
        />
        <ListTopInfo
          link="audience"
          title="Top customers"
          topInfoList={reportInformation.topFiveCustomers.map(this.renderCustomers)}
        />
        <ListTopInfo
          link="assets"
          title="Top assets"
          topInfoList={reportInformation.topFiveAssets.map(this.renderAssets)}
        />
      </Section>
    );
  }
}

export default TopInfo;
