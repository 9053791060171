import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { API } from 'constants/api';
import http, { ContentType } from 'utils/http';
import RootState from 'ducks/RootState';
import { NotificationActionTypes, notifRequested } from 'middleware/notifications/actions';
import { ResultKeys } from './types';

/* Fetch a list of items */
export enum FetchPaginatedDataTypes {
  START = 'FETCH_PAGINATED_DATA_START',
  SUCCESS = 'FETCH_PAGINATED_DATA_SUCCESS',
  ERROR = 'FETCH_PAGINATED_DATA_FAILURE',
}

export interface FetchPaginatedDataQueryParams {
  page?: number;
  // size doesn't exist on mailing list
  size?: number;
  // payments and subscriptions
  startDate?: string | number;
  endDate?: string | number;
  filter?: Array<string>;
  // audience
  search?: Array<string>;
  active?: boolean;
  // payments
  type?: string;
  // subscriptions
  status?: string;
  // mailing list
  limit?: number;
  blacklisted?: boolean;
  email?: string;
  customerID?: number;
  // mail templates
  merchant_id?: number;
}

type FetchPaginatedDataAction = Action<FetchPaginatedDataTypes>;

const fetchPaginatedData = (resultKey: ResultKeys, endpoint: string) => (
  params: FetchPaginatedDataQueryParams,
  isFilterChanged = false // used to reset pages in the state
): ThunkAction<Promise<any>, RootState, any, FetchPaginatedDataAction> => async (dispatch) => {
  let order;
  if (
    resultKey === ResultKeys.AUDIENCE ||
    resultKey === ResultKeys.MERCHANTS ||
    resultKey === ResultKeys.ASSET_ACCESSES
  ) {
    order = 'createdAt';
  }
  try {
    dispatch({ type: FetchPaginatedDataTypes.START, meta: { resultKey } });
    const response = await http.authenticated().get(endpoint, { params: { ...params, order } });
    dispatch({
      type: FetchPaginatedDataTypes.SUCCESS,
      payload: { paginationPage: params.page, data: response.data, resetPages: isFilterChanged },
      meta: { resultKey },
    });
  } catch (_) {
    dispatch({ type: FetchPaginatedDataTypes.ERROR, meta: { resultKey } });
  }
};

export enum ResetStateActionTypes {
  RESET_STATE = 'RESET_STATE',
}

type ResetStateAction = Action<ResetStateActionTypes> & { meta: { resultKey: ResultKeys } };

const resetState = (resultKey: ResultKeys) => (): ResetStateAction => ({
  type: ResetStateActionTypes.RESET_STATE,
  meta: { resultKey },
});

export enum RefundActionTypes {
  START = 'REFUND_PAYMENT_START',
  SUCCESS = 'REFUND_PAYMENT_SUCCESS',
  ERROR = 'REFUND_PAYMENT_FAILURE',
}

type FetchAllAudienceAction = Action<RefundActionTypes | NotificationActionTypes>;

export const refundPayment = (
  trxToken: string,
  reason: string,
  amount: number
): ThunkAction<Promise<any>, RootState, any, FetchAllAudienceAction> => async (dispatch) => {
  const endpoint = API.REFUND_PAYMENT;
  const data = { id: trxToken, reason, amount };

  try {
    dispatch({ type: RefundActionTypes.START, meta: { resultKey: ResultKeys.PAYMENTS } });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: RefundActionTypes.SUCCESS,
      payload: { data: response.data },
      meta: { resultKey: ResultKeys.PAYMENTS }, // if this is not added reducer won't be executed
    });
    dispatch(notifRequested({ type: 'success', title: 'SUCCESS', content: 'Successfully refunded payment.' }));
  } catch ({
    response: {
      data: { message },
    },
  }) {
    dispatch({ type: RefundActionTypes.ERROR, meta: { resultKey: ResultKeys.PAYMENTS } });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

export enum PutMailCategoryActionTypes {
  START = 'PUT_MAIL_CATEGORY_START',
  SUCCESS = 'PUT_MAIL_CATEGORY_SUCCESS',
  ERROR = 'PUT_MAIL_CATEGORY_FAILURE',
}

type PutMailCategoryAction = Action<PutMailCategoryActionTypes | NotificationActionTypes>;

export const putMailCategory = (
  email: string,
  blacklisted: boolean
): ThunkAction<Promise<any>, RootState, any, PutMailCategoryAction> => async (dispatch) => {
  const endpoint = API.MAILING_LIST;
  const data = { email, blacklisted };

  try {
    dispatch({ type: PutMailCategoryActionTypes.START, meta: { resultKey: ResultKeys.MAILING_LIST } });
    const response = await http.authenticated().put(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: PutMailCategoryActionTypes.SUCCESS,
      payload: { data: response.data },
      meta: { resultKey: ResultKeys.MAILING_LIST }, // if this is not added reducer won't be executed
    });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: `Email successfully ${blacklisted ? 'blacklisted' : 'whitelisted'}.`,
      })
    );
  } catch ({
    response: {
      data: { message },
    },
  }) {
    dispatch({ type: PutMailCategoryActionTypes.ERROR, meta: { resultKey: ResultKeys.MAILING_LIST } });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

export enum PostMailItemActionTypes {
  START = 'POST_MAIL_ITEM_START',
  SUCCESS = 'POST_MAIL_ITEM_SUCCESS',
  ERROR = 'POST_MAIL_ITEM_FAILURE',
}

type PostMailItemAction = Action<PostMailItemActionTypes | NotificationActionTypes>;

export const postMailItem = (
  email: string,
  blacklisted: boolean
): ThunkAction<Promise<any>, RootState, any, PostMailItemAction> => async (dispatch) => {
  const endpoint = API.MAILING_LIST;
  const data = { email, blacklisted };

  try {
    dispatch({ type: PostMailItemActionTypes.START, meta: { resultKey: ResultKeys.MAILING_LIST } });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: PostMailItemActionTypes.SUCCESS,
      payload: { data: response.data },
      meta: { resultKey: ResultKeys.MAILING_LIST }, // if this is not added reducer won't be executed
    });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: `New record was added successfully.`,
      })
    );
  } catch ({
    response: {
      data: { message },
    },
  }) {
    dispatch({ type: PostMailItemActionTypes.ERROR, meta: { resultKey: ResultKeys.MAILING_LIST } });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

const dataActionsWrapper = (resultKey: ResultKeys, endpoint: string) => ({
  fetchAction: fetchPaginatedData(resultKey, endpoint),
  resetAction: resetState(resultKey),
});

/* Fetch and reset data action creators */

export const { fetchAction: fetchAdminAudience, resetAction: resetAudienceData } = dataActionsWrapper(
  ResultKeys.AUDIENCE,
  API.ALL_AUDIENCE
);

export const { fetchAction: fetchAdminPayments, resetAction: resetPaymentsData } = dataActionsWrapper(
  ResultKeys.PAYMENTS,
  API.FETCH_PAYMENTS
);

export const { fetchAction: fetchAdminSubscriptions, resetAction: resetSubscriptionsData } = dataActionsWrapper(
  ResultKeys.SUBSCRIPTIONS,
  API.FETCH_SUBSCRIPTIONS
);
export const { fetchAction: fetchAdminMerchants, resetAction: resetMerchantsData } = dataActionsWrapper(
  ResultKeys.MERCHANTS,
  API.ALL_MERCHANTS
);
export const { fetchAction: fetchAdminAccesses, resetAction: resetAccessesData } = dataActionsWrapper(
  ResultKeys.ASSET_ACCESSES,
  API.FETCH_ACCESSES
);
export const { fetchAction: fetchAdminMailingList, resetAction: resetMailingListData } = dataActionsWrapper(
  ResultKeys.MAILING_LIST,
  API.MAILING_LIST
);
export const { fetchAction: fetchAdminMailTemplates, resetAction: resetMailTemplatesData } = dataActionsWrapper(
  ResultKeys.MAIL_TEMPLATES,
  API.MAIL_TEMPLATES
);
