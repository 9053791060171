import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers, Field, FormikProps } from 'formik';
import { object, string } from 'yup';

// utils
import isEmpty from 'lodash/isEmpty';

// selectors
import { getAdminMerchantIntegrationsState } from 'ducks/adminDucks/integrations/selectors';
import { getAdminMerchantDetailsState } from 'ducks/adminDucks/merchants/selectors';

// actions
import { addGoogleInAppIntegration } from 'ducks/adminDucks/integrations/actions';

// Components
import { Button, Grid } from '@inplayer-org/inplayer-ui';
import Input from 'components/Fields/Input';
import FieldError from 'components/Fields/FieldError';
import StyledContainer from 'components/StyledContainer';
import TextArea from 'components/Fields/TextArea';

const { Container, Cell } = Grid;

interface FormValues {
  siteToken: string;
  playstorePublicKey: string;
}

interface OwnProps {
  handleCloseModal: () => any;
}

type Props = OwnProps;

const validationSchema = object().shape({
  siteToken: string().required('*Required'),
  playstorePublicKey: string().required('*Required'),
});

const GoogleInAppIntegrationForm: FunctionComponent<Props> = ({ handleCloseModal }) => {
  const dispatch = useDispatch();

  const { googleInAppKeys } = useSelector(getAdminMerchantIntegrationsState);
  const adminMerchantDetailsState = useSelector(getAdminMerchantDetailsState);

  const { uuid: merchantUuid } = adminMerchantDetailsState;
  const { site_token: siteToken, playstore_public_key: playstorePublicKey } = googleInAppKeys;

  const initialValues = {
    siteToken: siteToken || '',
    playstorePublicKey: playstorePublicKey || '',
  };

  const onFormSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    const submitData = {
      ...values,
      merchantUuid,
    };

    try {
      await dispatch(addGoogleInAppIntegration(submitData));
      handleCloseModal();
      resetForm();
      setSubmitting(false);
    } catch (e) {
      resetForm();
      setSubmitting(false);
    }
  };

  const handleClose = (resetForm: () => void) => () => {
    resetForm();
    handleCloseModal();
  };

  const content = isEmpty(googleInAppKeys) ? 'Save' : 'Update';

  return (
    <Formik<FormValues>
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, isSubmitting, resetForm, isValid, dirty }: FormikProps<FormValues>) => {
        const disableSubmitButton = !isValid || isSubmitting || !dirty;

        return (
          <form onSubmit={handleSubmit}>
            <Container alignContent="space-around" columns={1}>
              <Cell>
                <Field name="siteToken" type="text" component={Input} label="Site token" />
                <FieldError name="siteToken" />
              </Cell>
              <Cell>
                <Field name="playstorePublicKey" component={TextArea} label="Site token" />
                <FieldError name="playstorePublicKey" />
              </Cell>
            </Container>
            <StyledContainer columns={3} alignContent="center" justifyContent="center" margin="2rem 0 0 0">
              <Cell middle left={2}>
                <Button type="button" fullWidth onClick={handleClose(resetForm)}>
                  Oops! No, cancel
                </Button>
              </Cell>
              <Cell middle left={3}>
                <Button type="submit" fullWidth buttonModifiers={['buttonPrimary']} disabled={disableSubmitButton}>
                  {content}
                </Button>
              </Cell>
            </StyledContainer>
          </form>
        );
      }}
    </Formik>
  );
};

export default GoogleInAppIntegrationForm;
