import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { MdInfoOutline } from 'react-icons/md';
import { Formik, FormikHelpers, Field, FormikProps } from 'formik';
import { object, string } from 'yup';

// utils
import isEmpty from 'lodash/isEmpty';

// hooks
import useRestrictionRules from 'restrictions/roles/hooks/useRestrictionRules';

// constants
import Rules from 'restrictions/roles/constants/rules';
import { API_ROOT } from 'constants/index';

// types
import { ButtonConfig } from 'pages/MerchantPages/Settings/Integrations/OvpIntegrations/components/BrightcoveForm';

// styled components
import { ItalicDescription } from 'components/SharedStyledElements';

// Components
import { Grid, Button } from '@inplayer-org/inplayer-ui';
import Input from 'components/Fields/Input';
import TextArea from 'components/Fields/TextArea';
import FieldError from 'components/Fields/FieldError';
import ClipBoardButton from 'components/ClipBoardButton';
import Restricted from 'restrictions/roles/components/Restricted';

const { Container, Cell } = Grid;

export const DescriptionContainer = styled(Container)`
  margin: 1rem 0;
`;

interface FormValues {
  site_token: string;
  playstore_public_key: string;
}

interface OwnProps {
  onSubmit: (data: FormValues) => any;
  googleInAppKeys: any;
  merchantUuid: string;
}

const validationSchema = object().shape({
  site_token: string().required('*Required').min(6, 'Site Verification Token must have minimum od 6 characters'),
  playstore_public_key: string()
    .required('*Required')
    .test('playstore_public_key', '*Required', (value) => value?.trim().length > 0),
});

type Props = OwnProps;

const GoogleInAppForm: FunctionComponent<Props> = ({ onSubmit, googleInAppKeys, merchantUuid }) => {
  const initialValues = {
    site_token: (googleInAppKeys && googleInAppKeys.site_token) || '',
    playstore_public_key: (googleInAppKeys && googleInAppKeys.playstore_public_key) || '',
  };

  const { hasRule } = useRestrictionRules();

  const onFormSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    await onSubmit(values);
    setSubmitting(false);
  };

  const saveButtonProps: ButtonConfig = {
    label: 'Save',
  };

  const updateButtonProps: ButtonConfig = {
    label: 'Update',
    modifier: 'buttonPrimary',
  };

  const buttonProps = isEmpty(googleInAppKeys) ? saveButtonProps : updateButtonProps;
  const buttonModifiers = buttonProps.modifier ? [buttonProps.modifier] : [];

  const googleNotificationUrl = `${API_ROOT}v2/hooks/google-in-app/${merchantUuid}`;

  const isReadOnly = hasRule(Rules.READONLY_IN_APP_INTEGRATIONS);

  return (
    <Formik
      onSubmit={onFormSubmit}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting }: FormikProps<FormValues>) => (
        <form onSubmit={handleSubmit}>
          <Container columns={3} rowGap="1rem">
            <Cell width={3}>
              <Field
                tag="text_google_play_site_verification_token"
                name="site_token"
                type="text"
                component={Input}
                label="Site Verification Token"
                disabled={isReadOnly}
              />
              <FieldError name="site_token" />
            </Cell>
            <Cell width={3}>
              <Field
                tag="text_google_play_android_service_key"
                name="playstore_public_key"
                type="text"
                component={TextArea}
                label="Android Service Key"
                disabled={isReadOnly}
              />
              <FieldError name="playstore_public_key" />
            </Cell>
            <Cell width={3}>
              <DescriptionContainer columns={6}>
                <Cell width={1} middle>
                  <ClipBoardButton
                    tag="google_play_copy_url"
                    clipboardText={googleNotificationUrl}
                    tooltipText="Copied!"
                    buttonModifiers={['buttonPrimary']}
                    fullWidth
                    placement="top"
                  >
                    Copy URL
                  </ClipBoardButton>
                </Cell>
                <Cell width={5} left={2}>
                  <ItalicDescription variant="p">
                    <MdInfoOutline />
                    Add this notification URL to your Google dashboard to connect the InPlayer and Google platforms.
                  </ItalicDescription>
                </Cell>
              </DescriptionContainer>
            </Cell>
            <Restricted isRestricted={isReadOnly}>
              <Cell width={1} left={3}>
                <Button
                  tag={`button_google_play_${buttonProps.label.toLowerCase()}`}
                  type="submit"
                  buttonModifiers={buttonModifiers}
                  fullWidth
                  disabled={isSubmitting}
                >
                  {buttonProps.label}
                </Button>
              </Cell>
            </Restricted>
          </Container>
        </form>
      )}
    </Formik>
  );
};

export default GoogleInAppForm;
