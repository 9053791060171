import React, { memo, ComponentType } from 'react';
import { Route, Redirect } from 'react-router-dom';

interface Props {
  component: ComponentType<any>;
  exact?: boolean;
  path: string;
  isRestricted: boolean;
  redirectTo?: string;
}

const RestrictedRoute = ({
  component: Component,
  exact = false,
  path,
  isRestricted,
  redirectTo = '/',
  ...rest
}: Props) => {
  if (isRestricted) {
    return <Redirect to={redirectTo} />;
  }

  return <Route exact={exact} path={path} render={(props) => <Component {...props} {...rest} />} />;
};

export default memo(RestrictedRoute);
