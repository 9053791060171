import React, { PureComponent } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

// Types
import RootState from 'ducks/RootState';
import InAppIntegrationsState from 'ducks/merchantDucks/inAppIntegrations/types';

// Actions
import {
  addGoogleInAppIntegration as addGoogleInAppIntegrationAction,
  addAppleInAppIntegration as addAppleInAppIntegrationAction,
  loadGoogleInAppKeys as loadGoogleInAppKeysAction,
  loadAppleInAppKeys as loadAppleInAppKeysAction,
  addAmazonInAppIntegration as addAmazonInAppIntegrationAction,
  loadAmazonInAppKeys as loadAmazonInAppKeysAction,
  loadRokuInAppKeys as loadRokuInAppKeysAction,
  addRokuInAppIntegration as addRokuInAppIntegrationAction,
} from 'ducks/merchantDucks/inAppIntegrations/actions';

// Components
import { AnalyticsPage } from '@inplayer-org/inplayer-ui';
import InAppIntegrationsForm from '../components/InAppIntegrationsForm';

export enum InAppType {
  GoogleInApp = 'google-in-app',
  AppleInApp = 'apple-in-app',
  AmazonInApp = 'amazon-in-app',
  RokuInApp = 'roku-in-app',
}

interface StateProps {
  inAppIntegrations: InAppIntegrationsState;
  user: { id: number; uuid: string };
}

interface DispatchProps {
  addGoogleInAppIntegration: (data: any) => any;
  addAppleInAppIntegration: (data: any) => any;
  addAmazonInAppIntegration: (data: any) => any;
  addRokuInAppIntegration: (data: any) => any;
  loadGoogleInAppKeys: () => any;
  loadAppleInAppKeys: () => any;
  loadAmazonInAppKeys: () => any;
  loadRokuInAppKeys: () => any;
}

interface OwnProps extends RouteComponentProps<{ type: string }> {}

type Props = StateProps & OwnProps & DispatchProps;

export class InAppForm extends PureComponent<Props> {
  componentDidMount() {
    const {
      match: {
        params: { type: inAppType },
      },
    } = this.props;

    this.loadInAppKeys(inAppType);
  }

  loadInAppKeys = (inAppType: string) => {
    const {
      loadGoogleInAppKeys,
      loadAppleInAppKeys,
      loadAmazonInAppKeys,
      loadRokuInAppKeys,
      inAppIntegrations: { isFetching },
    } = this.props;

    const inAppTypes: any = {
      [InAppType.GoogleInApp]: loadGoogleInAppKeys,
      [InAppType.AppleInApp]: loadAppleInAppKeys,
      [InAppType.AmazonInApp]: loadAmazonInAppKeys,
      [InAppType.RokuInApp]: loadRokuInAppKeys,
    };

    return !isFetching && inAppTypes[inAppType]();
  };

  handleChangeKeys = (metadata: any) => {
    const {
      addGoogleInAppIntegration,
      match: {
        params: { type: inAppType },
      },
      addAppleInAppIntegration,
      addAmazonInAppIntegration,
      addRokuInAppIntegration,
    } = this.props;

    const inAppTypes: any = {
      [InAppType.GoogleInApp]: addGoogleInAppIntegration,
      [InAppType.AppleInApp]: addAppleInAppIntegration,
      [InAppType.AmazonInApp]: addAmazonInAppIntegration,
      [InAppType.RokuInApp]: addRokuInAppIntegration,
    };

    return inAppTypes[inAppType](metadata);
  };

  render() {
    const {
      match: {
        params: { type: inAppType },
      },
      inAppIntegrations,
      user: { uuid: merchantUuid, id: merchantId },
    } = this.props;

    return (
      <AnalyticsPage tag="in-app-integrations" type="page">
        <AnalyticsPage tag={inAppType} type="page">
          <InAppIntegrationsForm
            changeKeys={this.handleChangeKeys}
            inAppIntegrations={inAppIntegrations}
            inAppType={inAppType}
            merchantUuid={merchantUuid}
            merchantId={merchantId}
          />
        </AnalyticsPage>
      </AnalyticsPage>
    );
  }
}

export const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  inAppIntegrations: state.inAppIntegrations,
  user: state.auth.user,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  addGoogleInAppIntegration: addGoogleInAppIntegrationAction,
  addAppleInAppIntegration: addAppleInAppIntegrationAction,
  loadGoogleInAppKeys: loadGoogleInAppKeysAction,
  loadAppleInAppKeys: loadAppleInAppKeysAction,
  addAmazonInAppIntegration: addAmazonInAppIntegrationAction,
  loadAmazonInAppKeys: loadAmazonInAppKeysAction,
  loadRokuInAppKeys: loadRokuInAppKeysAction,
  addRokuInAppIntegration: addRokuInAppIntegrationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(InAppForm);
