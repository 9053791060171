import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

// utils
import { getStripeModalMessage } from 'pages/AdminPages/AdminMerchants/utils/merchantData';

// types
import { ModalNames } from 'ducks/merchantDucks/modal/types';
import { PaymentMethodsTableData } from 'ducks/adminDucks/merchants/types';

// selectors
import { getPaymentSetup } from 'ducks/adminDucks/merchants/selectors';

// components
import { Grid, Typography, Button } from '@inplayer-org/inplayer-ui';
import BaseModal, { ModalActions } from 'components/Modals/BaseModal';
import StyledContainer from 'components/StyledContainer';

const { Container, Cell } = Grid;

interface OwnProps {
  hasInheritance: boolean;
  selectedPayment: PaymentMethodsTableData;
}

type Props = OwnProps;

const StripeSettingsModal: FunctionComponent<Props> = ({ hasInheritance, selectedPayment }: Props) => {
  const { isDisconnected } = useSelector(getPaymentSetup);
  const { metadata } = selectedPayment;

  let stripeUserId = '';
  if (!isDisconnected) {
    stripeUserId = metadata && metadata.stripeUserId;
  }

  return (
    <BaseModal
      isOverflowVisible
      title="Stripe setting"
      modalName={ModalNames.StripeSettingsModal}
      render={({ closeModal }: ModalActions) => (
        <Container alignContent="space-around" columns={1}>
          <Cell>
            <Typography variant="h6">{getStripeModalMessage(stripeUserId, hasInheritance)}</Typography>
          </Cell>
          <StyledContainer columns={4} alignContent="center" justifyContent="center" margin="2rem 0 0 0">
            <Cell middle left={4}>
              <Button type="button" fullWidth onClick={closeModal}>
                Close
              </Button>
            </Cell>
          </StyledContainer>
        </Container>
      )}
    />
  );
};

export default StripeSettingsModal;
