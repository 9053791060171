import React, { useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import styled from 'styled-components';
import { Colors, Tooltip } from '@inplayer-org/inplayer-ui';
import { MdInfoOutline } from 'react-icons/md';
import truncate from 'lodash/truncate';

// components
import EmptyMessage from 'components/EmptyMessage';
import { StyledSpan, FlexContainer } from 'pages/MerchantPages/Analytics/components/TopInfo';

interface Props {
  groupedSubscribers?: Array<any>;
  totalCancelled?: number;
}

const Container = styled.div`
  width: 100%;
`;

const Resource = styled.li`
  list-style: none;
  padding: 0.3rem 0;
`;

const ResourceDescription = styled(StyledSpan)`
  color: ${({ isActive }) => isActive && Colors.blue};
  font-size: ${({ isActive, theme }) => (isActive && theme.font.sizes.h5) || theme.font.sizes.large};
  cursor: default;
`;

const StyledIcon = styled(MdInfoOutline)`
  width: 20px;
  height: 20px;
  padding: 5px 0 0 5px;
`;

const sortResources = (groupedSubscribers: Array<any>) => {
  const returnValue = orderBy(groupedSubscribers, ['total'], ['desc']).slice(0, 5);
  return returnValue;
};
const TopResources: React.FC<Props> = ({ groupedSubscribers, totalCancelled }) => {
  const resources = useMemo(() => sortResources(groupedSubscribers ?? []), [groupedSubscribers]);

  return (
    <Container>
      {resources && groupedSubscribers?.length ? (
        <ul>
          {resources.map((resource, index) => {
            const percent = totalCancelled && Number((resource.total_subscriptions / totalCancelled).toFixed(2)) * 100;
            return (
              <Resource key={resource.description} data-testid={resource.description}>
                <FlexContainer>
                  <ResourceDescription isActive={index === 0}>
                    {`${index + 1}. `}
                    {truncate(resource.item_title || resource.access_fee_description, { length: 40 })}
                  </ResourceDescription>
                  <ResourceDescription isActive={index === 0}>
                    {resource.total}
                    {totalCancelled && (
                      <Tooltip content={`${percent}% of total cancelled subscriptions`}>
                        <StyledIcon />
                      </Tooltip>
                    )}
                  </ResourceDescription>
                </FlexContainer>
              </Resource>
            );
          })}
        </ul>
      ) : (
        <EmptyMessage variant="p">There is no available data for this time period.</EmptyMessage>
      )}
    </Container>
  );
};

export default TopResources;
