import React, { FunctionComponent, InputHTMLAttributes, useState } from 'react';
import { FieldProps } from 'formik';
import { MdInfoOutline } from 'react-icons/md';
import {
  Input as InPlayerInput,
  Label,
  Grid,
  InputSize,
  Tooltip,
  AnalyticsProps,
  Colors,
} from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { snakeCase } from 'lodash';

const { Container, Cell } = Grid;

interface OwnProps extends AnalyticsProps {
  label?: string;
  size?: InputSize;
  inlineLabel?: boolean;
  tooltip?: string;
  showPasswordIcon?: boolean;
}

const InputContainer = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  grid-column-end: span 12;
  grid-row-end: span 1;

  input:disabled {
    color: ${Colors.fontLightGray};
  }
`;

const PasswordEyeWrapper = styled.div`
  color: ${({ theme }) => theme.palette.text.main};
  position: absolute;
  top: 35%;
  right: 3%;
  cursor: pointer;
`;

const RowCell = styled(Cell)`
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
`;

const MarginTooltip = styled(Tooltip)`
  margin-left: 0.3rem;
`;

const StyledIcon = styled(MdInfoOutline)`
  width: 19px;
  height: 19px;
`;

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'form'> & FieldProps & OwnProps;

const Input: FunctionComponent<Props> = ({
  id,
  label,
  required,
  field,
  inlineLabel,
  tooltip,
  disabled,
  type,
  showPasswordIcon = false,
  value,
  form: _,
  tag = '',
  ...rest
}) => {
  const { name: fieldName, ...fieldRest } = field;

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => setIsPasswordShown(!isPasswordShown);

  const renderPasswordIcon = () => (
    <PasswordEyeWrapper>
      {isPasswordShown ? (
        <AiOutlineEye onClick={togglePasswordVisiblity} />
      ) : (
        <AiOutlineEyeInvisible onClick={togglePasswordVisiblity} />
      )}
    </PasswordEyeWrapper>
  );

  return label || tooltip ? (
    <Container>
      {(label || tooltip) && (
        <RowCell width={inlineLabel ? 2 : 12} middle>
          {label && <Label htmlFor={fieldName} disabled={disabled}>{`${label}${required ? ' *' : ''}`}</Label>}
          {tooltip && (
            <MarginTooltip tag={tooltip && `tooltip_${snakeCase(label)}`} placement="right" content={tooltip}>
              <StyledIcon />
            </MarginTooltip>
          )}
        </RowCell>
      )}
      <InputContainer>
        <InPlayerInput
          tag={tag}
          type={isPasswordShown ? 'text' : type}
          id={id || fieldName}
          name={fieldName}
          disabled={disabled}
          {...fieldRest}
          {...rest}
          value={value ?? fieldRest.value}
        />
        {showPasswordIcon && renderPasswordIcon()}
      </InputContainer>
    </Container>
  ) : (
    <InputContainer>
      <InPlayerInput
        tag={tag}
        type={isPasswordShown ? 'text' : type}
        id={id || fieldName}
        name={fieldName}
        disabled={disabled}
        {...fieldRest}
        {...rest}
        value={value ?? fieldRest.value}
      />
      {showPasswordIcon && renderPasswordIcon()}
    </InputContainer>
  );
};

export default Input;
