import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { LogoutUserActionTypes } from 'ducks/merchantDucks/user/actions';
import RootState from 'ducks/RootState';
import auth from 'ducks/merchantDucks/user/reducers';
import items from 'ducks/merchantDucks/items/reducers';
import notifications from 'ducks/merchantDucks/notifications/reducers';
import audience from 'ducks/merchantDucks/audience/reducers';
import externalAccount from 'ducks/merchantDucks/externalAccount/reducers';
import inAppIntegrations from 'ducks/merchantDucks/inAppIntegrations/reducers';
import packages from 'ducks/merchantDucks/packages/reducers';
import webhooks from 'ducks/merchantDucks/webhooks/reducers';
import forgotPasswordForm from 'ducks/merchantDucks/form/reducers';
import vouchers from 'ducks/merchantDucks/vouchers/reducers';
import subscriptions from 'ducks/merchantDucks/subscriptions/reducers';
import reports from 'ducks/merchantDucks/reports/reducers';
import branding from 'ducks/merchantDucks/branding/reducers';
import customFields from 'ducks/merchantDucks/customFields/reducers';
import accesses from 'ducks/merchantDucks/accesses/reducers';
import payments from 'ducks/merchantDucks/payments/reducers';
import oauth from 'ducks/merchantDucks/oauth/reducers';
import restrictions from 'ducks/merchantDucks/restrictions/reducers';
import agency from 'ducks/merchantDucks/agency/reducers';
import paymentMethods from 'ducks/merchantDucks/paymentMethods/reducers';
import segmentation from 'ducks/merchantDucks/segmentation/reducers';
import modal from 'ducks/merchantDucks/modal/reducers';
import embedCodeOptions from 'ducks/merchantDucks/embedding/reducers';
import paginations from 'ducks/adminDucks/paginations/reducers';
import merchants from 'ducks/adminDucks/merchants/reducers';
import integrations from 'ducks/adminDucks/integrations/reducers';
import datepicker from 'ducks/merchantDucks/datepicker/reducers';
import subscriptionGroups from 'ducks/merchantDucks/subscriptionGroup/reducers';

const createAppReducer = (history: History): Reducer<RootState> =>
  combineReducers({
    auth,
    items,
    accesses,
    notifications,
    payments,
    audience,
    externalAccount,
    packages,
    forgotPasswordForm,
    webhooks,
    reports,
    subscriptions,
    branding,
    customFields,
    oauth,
    restrictions,
    agency,
    paymentMethods,
    inAppIntegrations,
    router: connectRouter(history),
    modal,
    segmentation,
    vouchers,
    embedCodeOptions,
    paginations,
    merchants,
    integrations,
    datepicker,
    subscriptionGroups,
  });

const createRootReducer = (history: History): Reducer<RootState> => {
  const appReducer = createAppReducer(history);

  return (state, action) => {
    const stateToUse = action.type === LogoutUserActionTypes.SUCCESS ? undefined : state;

    return appReducer(stateToUse, action);
  };
};

export default createRootReducer;
