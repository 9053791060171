import { Colors } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';

const ActionMenuContent = styled.div`
  position: absolute;
  display: block;
  height: 0;
  padding-top: 30px;
  cursor: pointer;
  z-index: -1;
  transition: all 300ms ease;
  min-width: 150px;
  top: -3px;

  &:hover {
    z-index: 99;
    height: auto;
  }
`;

const ActionMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-left: auto;

  &:active {
    ${ActionMenuContent} {
      z-index: 99;
      height: auto;
    }
  }
`;

const ActionMenuDropdown = styled.ul`
  background: ${Colors.white};
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  color: ${({ theme }) => theme.palette.text.light};
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-box-shadow: -3px 7px 10px 1px rgba(136, 152, 170, 0.1), 0 15px 35px 0 rgba(49, 49, 93, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
  box-shadow: -3px 7px 10px 1px rgba(136, 152, 170, 0.1), 0 15px 35px 0 rgba(49, 49, 93, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
`;

const ActionMenuDropdownItem = styled.li`
  list-style: none;
  text-align: left;
  font-size: ${({ theme }) => theme.font.sizes.small};
  padding: 5px 20px;

  &:hover {
    color: ${({ theme }) => theme.palette.text.main};
  }
`;

const ActionMenuLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.light};

  &:hover {
    color: ${({ theme }) => theme.palette.text.main};
  }
`;

export default { ActionMenuContent, ActionMenuContainer, ActionMenuDropdown, ActionMenuDropdownItem, ActionMenuLink };
