import React, { FunctionComponent } from 'react';
import { object, string } from 'yup';
import { Formik, FormikHelpers, Field, FormikProps } from 'formik';
import { Button, Grid } from '@inplayer-org/inplayer-ui';

// components
import FieldError from 'components/Fields/FieldError';
import Input from 'components/Fields/Input';
import AuthFormContainer from 'components/AuthFormContainer';

const { Container, Cell } = Grid;

interface FormValues {
  email: string;
}

interface OwnProps {
  onSubmit: (data: FormValues) => any;
}

const validationSchema = object().shape({
  email: string().email('Please enter a valid email address').required('*Required'),
});

type Props = OwnProps;

const ResendCodeForm: FunctionComponent<Props> = ({ onSubmit }) => {
  const initialValues = {
    email: '',
  };

  const onFormSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    await onSubmit(values);
    setSubmitting(false);
  };

  return (
    <Formik onSubmit={onFormSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ handleSubmit, isSubmitting }: FormikProps<FormValues>) => (
        <AuthFormContainer
          title="Enter your new password"
          description="Please enter your merchant account email address provided upon registration. Then press the Resend
                  Code button to finalize the process. New activation code will be send by InPlayer."
        >
          <form onSubmit={handleSubmit}>
            <Container alignContent="space-around" columns={1} gap="1em">
              <Cell>
                <Field name="email" type="email" component={Input} placeholder="Email" />
                <FieldError name="email" />
              </Cell>
              <Cell>
                <Button fullHeight fullWidth buttonModifiers={['buttonPrimary']} type="submit" disabled={isSubmitting}>
                  Resend code
                </Button>
              </Cell>
            </Container>
          </form>
        </AuthFormContainer>
      )}
    </Formik>
  );
};

export default ResendCodeForm;
