import { ChangeEvent, Dispatch } from 'react';
import { zxcvbn } from 'zxcvbn-typescript';

const MIN_PASS_SCORE = 3;

export const onPassChangeValidate = (
  e: ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  setPasswordWarning: Dispatch<React.SetStateAction<string>>,
  setValidPassword: Dispatch<React.SetStateAction<boolean>>
) => {
  const {
    target: { value },
  } = e;
  const evaluation = zxcvbn(value);
  const {
    score,
    feedback: { warning },
  } = evaluation;
  setFieldValue('password', value);
  setValidPassword(score >= MIN_PASS_SCORE);
  if (warning) {
    setPasswordWarning(warning);
  } else {
    setPasswordWarning('Add another word or two.Uncommon words are better.');
  }
};
