import styled from 'styled-components';
import { Typography, Colors } from '@inplayer-org/inplayer-ui';

export type StyledTypographyProps = {
  isHeading?: boolean;
};

export const StyledTypographyMargin = styled(Typography)<StyledTypographyProps>`
  margin: 1rem 0;
  ${({ isHeading }: StyledTypographyProps) => isHeading && `color: ${Colors.blue};`};
`;
