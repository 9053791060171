import { ItemAccessTypes, LiveLikeChatContentFilter } from 'ducks/merchantDucks/items/actions';
import { Optional } from 'utils/types';
import { Moment } from 'moment';
import { ErrorFile } from 'ducks/merchantDucks/vouchers/types';

export interface ExternalAsset {
  total: number;
  videos: Array<any>;
  isFetching: boolean;
  error: boolean;
}

export interface AccessControlType {
  id: number;
  name: string;
  auth: number;
}

export interface ItemType {
  id: number;
  name: string;
  content_type: string;
  host: string;
  description: string;
}

interface GiftDetails {
  created_at: number;
  description: string;
  id: number;
  item_id: number;
  updated_at: number;
}

export interface TemplatePrice {
  id: number;
  template_id: number;
  access_type_id: number;
  amount: number;
  currency: string;
  description: string;
  country_set_id: number;
  created_at: number;
  updated_at: number;
  country_iso?: string;
  type?: string;
  geo_restriction?: any;
  access_type?: any;
  starts_at?: any;
  expires_at?: any;
  setup_fee?: any;
  setup_fee_amount?: number;
  setup_fee_description?: string;
}

export interface Restriction {
  created_at: number;
  updated_at: number;
  additional_data: string;
  type: string;
  category: string;
  id: number;
  template_id: number;
}

interface EventDetails {
  id: number;
  industry: string;
  content_category: string;
  event_name: string;
  web_page: string;
  event_date: number;
  expected_viewership: string;
  replay_available: boolean;
  is_advertised: boolean;
  event_description: string;
}

export interface TemplateDetails {
  id: number;
  merchant_id: number;
  name: string;
  preview_text: string;
  access_control_type_id: number;
  metadata: Record<string, string>[];
  created_at: number;
  updated_at: number;
  prices: Array<TemplatePrice>;
  restrictions: Array<Restriction>;
  selectedTemplateFeeId: number;
  item_type_id: number;
  event_details: EventDetails;
  isSaving: boolean;
  event_type: string;
}

export interface LiveLikeIntegration {
  chatrooms: Array<ChatRoomDetails>;
}

export interface ExternalIntegrations {
  livelike: LiveLikeIntegration;
}

export interface ItemDetails {
  id: number;
  content: string;
  merchant_id: number;
  merchant_uuid: string;
  is_active: boolean;
  title: string;
  access_control_type: AccessControlType;
  item_type: ItemType;
  metadata: Record<string, string>;
  created_at: number;
  updated_at: number;
  source: string;
  asset_file: any;
  thumbnail_file: any;
  isSynching?: boolean;
  accessFees: Array<AccessFee>;
  selectedFeeId: number;
  isCreated?: boolean;
  isSaving?: boolean;
  template_id: number;
  isAccessFeesFetching: boolean;
  selectedSectionIndex: number;
  shouldCloseSection: boolean;
  shouldClosePanel: boolean;
  seasonPhases: Array<SeasonPhase>;
  is_giftable: boolean;
  gift_metadata: GiftDetails;
  donationDetails: DonationDetails;
  event_type: string;
  event_details: EventDetails;
  external_integrations: ExternalIntegrations;
  isFetching: boolean;
  plan_switch_enabled: boolean;
}

export interface OvpVideoDetails {
  id: number;
  isLoading: boolean;
  isFetchingBrightcove: boolean;
  isFetchedBrightcove: boolean;
  isPulled: boolean;
  isFromOvp: boolean;
  custom: { created: number };
  catalog: { id: number };
  tags: Array<any>;
  metadata?: any;
  asset?: any;
  images?: any;
  description?: string;
  account_id?: number;
  name?: string;
  title?: string;
  thumbnail_url?: string;
  key?: any;
  player_name?: string;
  player_id?: number;
  caption?: string;
  thumbnailUrl?: string;
  thumbnailUrlSmall?: string;
  eventId?: number;
  preview_image_url?: string;
  Title?: any;
  Description?: string;
  posterUrl?: string;
  uuid?: any;
  embed_code?: any;
  asset_type?: string;
  thumbnails?: Array<any>;
  created: string;
  drm_policy_id?: string;
}

export interface ItemPriceInput {
  inputs: Array<any>;
  nextInput: number;
}

export interface ItemInfoInput {
  inputs: Array<any>;
  nextInput: number;
}

export interface JwPlayers {
  isFetching: boolean;
  players: Array<any>;
}

interface AccessFeeTrialPeriod {
  quantity: number;
  period: string;
  description: string;
}

interface AccessSetupFee {
  fee_amount: number;
  description: string;
}

export interface AccessType {
  id: number;
  name: string;
  quantity: number;
  period: string;
}

interface AccessGeoRestriction {
  country_iso: string;
  created_at: number;
  id: number;
  type: string;
  updated_at: number;
}

export interface ExternalFee {
  id: number;
  payment_provider_id: number;
  external_id: string;
}

export interface VoucherRule {
  id: number;
  rule_type: string;
  value: string;
}

export interface CurrentPhase {
  access_fee_id: number;
  anchor_date: number;
  created_at: number;
  currency: string;
  current_price: number;
  expires_at: number;
  id: number;
  season_price: number;
  starts_at: number;
  status: string;
  updated_at: number;
}

export interface AccessFee {
  id: number;
  merchant_id: number;
  amount: number;
  currency: string;
  description: string;
  access_type: AccessType;
  setup_fee: AccessSetupFee;
  trial_period: AccessFeeTrialPeriod;
  geo_restriction: AccessGeoRestriction;
  expires_at: number;
  item: {
    id: number;
    item_type: {
      content_type: string;
      name: string;
    };
  };
  external_fees: ExternalFee;
  voucher_rule: VoucherRule;
  current_phase: CurrentPhase;
}

export interface TemplatesCollection {
  isFetching: boolean;
  collection: Array<any>;
}

export interface Templates {
  templateDetails: TemplateDetails;
  templateCollection: TemplatesCollection;
}

export interface ChatRoomDetails {
  id: string;
  title: string;
  content_filter: LiveLikeChatContentFilter;
}

export interface ChatRooms {
  count: number;
  collection: Array<ChatRoomDetails>;
  isFetching: boolean;
  isFetched: boolean;
  isCreating: boolean;
  total: number;
  page: number;
  size: number;
}
export interface LiveLikeResources {
  savedChatroom: {
    isFetching: boolean;
    isFetched: boolean;
    chatroomData: ChatRoomDetails | null;
  };
  chatRooms: ChatRooms;
}

export default interface ItemsState {
  collection: Array<any>;
  isFetching: boolean;
  isExist: boolean;
  assetId: number;
  total: number;
  limit: number;
  page: number;
  offset: number;
  firstTotal: number;
  externalAssets: ExternalAsset;
  itemDetails: ItemDetails;
  fromAssetDetails: boolean;
  jwPlayers: JwPlayers;
  ovpVideoDetails: OvpVideoDetails;
  accessTypes: ItemAccessTypes;
  priceInputs: ItemPriceInput;
  infoInputs: ItemInfoInput;
  syncInProgress: boolean;
  isFetchingFormData: boolean;
  isFormDataFetched: boolean;
  formdata: Record<string, any>;
  launch_url: string;
  panoptoContent: object;
  codes: Codes;
  templates: Templates;
  livelikeResources: LiveLikeResources;
  ovps: {
    ivs: {
      channels: {
        status: {
          isFetching: boolean;
        };
        list: Array<{
          arn: string;
          name: string;
          authorized: boolean;
          latency_mode: string;
          type?: string;
          stream_url?: string;
        }>;
      };
    };
  };
}

export interface CodeDetails {
  id: number;
  code: string;
  concurrent_sessions: number;
  start_date: number;
  end_date: number;
  type: string;
}

export interface SessionDetails {
  id: number;
  agent_info: string;
  last_used: number;
  code: string;
  browser_fingerprint: string;
}

export interface Report {
  name: string;
  created_at: number;
}

export interface Codes {
  total: number;
  page: number;
  limit: number;
  collection: Array<CodeDetails>;
  isFetching: boolean;
  isCreating: boolean;
  isUploaded: boolean;
  isUpdating: boolean;
  codeSessions: {
    isFetching: boolean;
    collection: Array<SessionDetails>;
  };
  errorFiles: {
    isFetching: boolean;
    files: Array<ErrorFile>;
  };
  reports: {
    isFetching: boolean;
    collection: Array<Report>;
  };
}

export interface CodeType {
  id: number;
  code: string;
  startDate: number;
  endDate: number;
  type: string;
  // empty string for bulk action
  concurrentSessions: number | string;
}

export interface BulkCodeType {
  itemId: number;
  startDate: Optional<Moment>;
  endDate: Optional<Moment>;
  type: string;
  // empty string for bulk action
  concurrentSessions: number | string;
}

export interface SeasonPhase {
  id: number;
  starts_at: number;
  expires_at: number;
  anchor_date: number;
  current_price: string;
  season_price: string;
  currency: string;
  status: string;
}

export interface DonationDetails {
  donations: Array<DonationFee> | null;
  donation_options: CustomDonationFee | null;
  isLoading: boolean;
}

export interface DonationFee {
  id: number;
  item_id: number;
  amount: number;
  currency: string;
  description?: string;
}

export interface CustomDonationFee {
  id: number;
  item_id: number;
  custom_price_enabled: boolean;
}

export interface CreateTemplateRequestData {
  name: string;
  access_control_type_id?: number;
  preview_text?: string;
  metadata: any;
  item_type_name: string;
  event_type?: string;
  content?: string;
  external_asset_id?: number;
}

export enum DefaultPreviewMetadata {
  PREVIEW_DESCRIPTION = '<p>Asset description</p>',
  PREVIEW_TITLE = '<h3>Asset title</h3>',
  PREVIEW_COVER_PHOTO = 'https://inplayer-paywall-v2.s3.amazonaws.com/images/ip-preview-premium.jpg',
  PREVIEW_BUTTON = 'Buy',
  PREVIEW_IMAGE_NAME = 'preview image',
  THUMBNAIL_IMAGE = 'https://inplayer-paywall-v2.s3.amazonaws.com/images/ip-preview-premium.jpg',
  THUMBNAIL_IMAGE_NAME = 'thumbnail image',
}

export enum PreviewMetadataNames {
  PREVIEW_DESCRIPTION = 'preview_description',
  PREVIEW_TITLE = 'preview_title',
  PREVIEW_BUTTON = 'preview_button_label',
  COVER_PHOTO = 'paywall_cover_photo',
  PREVIEW_IMAGE_NAME = 'preview_image_name',
  THUMBNAIL_IMAGE = 'thumbnail_image',
  THUMBNAIL_IMAGE_NAME = 'thumbnail_image_name',
}

export type Details = ItemDetails & TemplateDetails;
