import axios from 'axios';
import moment from 'moment';

import { API } from 'constants/api';
import http, { isUserAdmin } from 'utils/http';
import TokenManager from 'utils/TokenManager';
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';
import { ApiAction } from 'ducks/types';

// Unsubscribe
export enum UnsubscribeActionTypes {
  START = 'UNSUBSCRIBE_REQUEST',
  SUCCESS = 'UNSUBSCRIBE_SUCCESS',
  FAILURE = 'UNSUBSCRIBE_FAILURE',
}

export const unsubscribe = (
  unsubscribeUrl: string
): ApiAction<UnsubscribeActionTypes | NotificationActionTypes> => async (dispatch) => {
  const authenticatedHeaders = {
    Authorization: `Bearer ${isUserAdmin() ? TokenManager.admin?.adminToken : TokenManager.token}`,
  };

  try {
    dispatch({ type: UnsubscribeActionTypes.START });
    const response = await axios.get(unsubscribeUrl, { headers: authenticatedHeaders });
    dispatch({ type: UnsubscribeActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Subscription successfully canceled.',
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: UnsubscribeActionTypes.FAILURE });
    dispatch(
      notifRequested({
        title: 'ERROR',
        content: message,
        type: 'danger',
      })
    );
  }
};

// Load subscription details
export enum SubscriptionDetailsActionTypes {
  START = 'SUBSCRIPTION_DETAILS_REQUEST',
  SUCCESS = 'SUBSCRIPTION_DETAILS_SUCCESS',
  FAILURE = 'SUBSCRIPTION_DETAILS_FAILURE',
}

export const loadSubscriptionDetails = (
  id: string,
  page: number,
  size = 5
): ApiAction<SubscriptionDetailsActionTypes> => async (dispatch) => {
  const endpoint = API.SUBSCRIPTION_DETAILS;
  const params = { page, size };

  try {
    dispatch({ type: SubscriptionDetailsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        id,
      },
      params,
    });
    dispatch({ type: SubscriptionDetailsActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: SubscriptionDetailsActionTypes.FAILURE });
  }
};

export const RESET_SUBSCRIPTION_DETAILS = 'RESET_SUBSCRIPTION_DETAILS';

export const resetSubscriptionDetails = () => ({
  type: RESET_SUBSCRIPTION_DETAILS,
});

export enum SubscriptionActionTypes {
  START = 'ONGOING_SUBSCRIPTION_REQUEST',
  SUCCESS = 'ONGOING_SUBSCRIPTION_SUCCESS',
  FAILURE = 'ONGOING_SUBSCRIPTION_FAILURE',
}
export interface SubscriptionParams {
  groupedBy?: string;
  actionType?: string;
  country?: string;
  country_code?: string;
}

export enum SubscriptionFilterTypes {
  ACTIVE = 'groupedActiveSubscriptions',
  ALL = 'allActiveSubscriptions',
  CANCELLED = 'cancelledSubscriptions',
  COUNTRY = 'allCountriesWithSubscriptions',
}

export const fetchSubscriptions = (
  type?: SubscriptionFilterTypes,
  params?: SubscriptionParams
): ApiAction<SubscriptionActionTypes> => async (dispatch) => {
  const endpoint = API.SUBSCRIPTIONS;
  const { groupedBy, actionType, country, country_code = '' } = params || {};
  const queryParams = {
    grouped_by: groupedBy,
    action_type: actionType,
    country,
    country_code,
  };

  try {
    dispatch({ type: SubscriptionActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      params: queryParams,
    });

    dispatch({
      type: SubscriptionActionTypes.SUCCESS,
      payload: {
        data: [...response.data],
        type,
      },
    });
  } catch (_) {
    dispatch({
      type: SubscriptionActionTypes.FAILURE,
      payload: {
        type,
      },
    });
  }
};

// subscriptions by status - new endpoint
export enum SubscriptionsByStatusActionTypes {
  START = 'SUBSCRIPTIONS_BY_STATUS_REQUEST',
  SUCCESS = 'SUBSCRIPTIONS_BY_STATUS_SUCCESS',
  FAILURE = 'SUBSCRIPTIONS_BY_STATUS_ERROR',
}

type SubscriptionsByStatusTypes = SubscriptionsByStatusActionTypes;

export const fetchSubscriptionsByStatus = (
  status: string,
  groupBy: string,
  startDate?: any,
  endDate?: any
): ApiAction<SubscriptionsByStatusTypes> => async (dispatch) => {
  const dateFormat = 'YYYY-MM-DD';
  const startDateFormatted = moment(startDate).format(dateFormat);
  const endDateFormatted = moment(endDate).format(dateFormat);

  const endpoint = API.SUBSCRIPTIONS_BY_STATUS;

  const pathVariables = { groupBy };

  try {
    dispatch({ type: SubscriptionsByStatusActionTypes.START, payload: { status, groupBy } });
    const response = await http.authenticated().get(endpoint, {
      pathVariables,
      params: {
        start_date: startDateFormatted,
        end_date: endDateFormatted,
        status,
      },
    });
    dispatch({
      type: SubscriptionsByStatusActionTypes.SUCCESS,
      payload: { collection: response.data, status, groupBy },
    });
  } catch (e) {
    dispatch({ type: SubscriptionsByStatusActionTypes.FAILURE });
  }
};

export enum SubscriptionsStatusActionTypes {
  START = 'SUBSCRIPTIONS_STATUS_REQUEST',
  SUCCESS = 'SUBSCRIPTIONS_STATUS_SUCCESS',
  FAILURE = 'SUBSCRIPTIONS_STATUS_ERROR',
}

type SubscriptionStatusTypes = SubscriptionsStatusActionTypes;

export const fetchSubscriptionsStatus = (startDate: any, endDate: any): ApiAction<SubscriptionStatusTypes> => async (
  dispatch
) => {
  const endpoint = API.SUBSCRIPTIONS;

  const dateFormat = 'YYYY-MM-DD';
  const startDateFormatted = moment(startDate).format(dateFormat);
  const endDateFormatted = moment(endDate).format(dateFormat);
  try {
    dispatch({ type: SubscriptionsStatusActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      params: {
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      },
    });
    dispatch({ type: SubscriptionsStatusActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    dispatch({ type: SubscriptionsStatusActionTypes.FAILURE });
  }
};

// subscriptions map - new endpoint
export enum SubscriptionCountriesMapActionTypes {
  START = 'SUBSCRIPTIONS_MAP_REQUEST',
  SUCCESS = 'SUBSCRIPTIONS_MAP_SUCCESS',
  FAILURE = 'SUBSCRIPTIONS_MAP_ERROR',
}

type SubscriptionCountriesMapTypes = SubscriptionCountriesMapActionTypes;

export const fetchSubscriptionsCountriesMap = (
  startDate: any,
  endDate: any
): ApiAction<SubscriptionCountriesMapTypes> => async (dispatch) => {
  const endpoint = API.SUBSCRIPTIONS_COUNTRIES;

  const dateFormat = 'YYYY-MM-DD';
  const startDateFormatted = moment(startDate).format(dateFormat);
  const endDateFormatted = moment(endDate).format(dateFormat);

  try {
    dispatch({ type: SubscriptionCountriesMapActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      params: {
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      },
    });
    dispatch({ type: SubscriptionCountriesMapActionTypes.SUCCESS, payload: response.data });
  } catch (_) {
    dispatch({ type: SubscriptionCountriesMapActionTypes.FAILURE });
  }
};
