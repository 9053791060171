import React, { Component } from 'react';
import { History } from 'history';
import Error from '../Error';

type Props = {
  history: History;
};

interface State {
  hasError: boolean;
  info: string;
  error: string;
}

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      info: '',
      error: '',
    };

    // Once the state in the ErrorBoundary has been set
    // it will always render the error message until the state changes
    // listen on location change to toggle hasError
    props.history.listen(() => {
      const { hasError } = this.state;

      if (hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true, info, error });

    // TODO: user proper loggin service
    console.error(`Error: ${error}`);
    console.error(`Error: ${JSON.stringify(info)}`);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <Error title="500" subtitle="Something went wrong" description="We are sorry for the inconvenience" />;
    }
    return children;
  }
}
