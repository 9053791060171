import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Loader, AnalyticsPage, useAnalytics } from '@inplayer-org/inplayer-ui';

// hooks
import { useUncontrolledSwitch } from 'components/Switcher';

// actions
import { saveExternalAccount } from 'ducks/merchantDucks/externalAccount/actions';

// selectors
import { getExternalAccount } from 'ducks/merchantDucks/externalAccount/selectors';

// styled components
import { BlueTypography } from 'components/SharedStyledElements';
import { StyledTypography } from 'pages/MerchantPages/Settings/Integrations/ThirdPartyIntegrations/ThirdPartyIntegration';

// components
import MainHolder from 'components/MainHolder';
import SubheaderWithBorder from 'components/SubheaderWithBorder';
import ContentHolder from 'components/ContentHolder';
import ButtonLink from 'components/ButtonLink';
import ThemedMessage, { MessageVariants } from 'components/ThemedMessage';

// local components
import InputsForm from './InputsForm';

// local types
import { SwitchableStates } from './MainContainer';

export interface ThirdPartyKeys {
  [key: string]: any;
}

const FormContainer = styled.div`
  width: 50%;
  margin: 0 2.5rem;

  @media screen and (max-width: 871px) {
    width: 86%;
  }
`;

const DescriptionContainer = styled.div`
  margin: 2.5rem 2.5rem 0;
`;

const CenteredFlex = styled.div`
  display: flex;
  height: 50vh;
  justify-content: center;
  align-items: center;
`;

const ConnectExistingAccount: React.FC = () => {
  const dispatch = useDispatch();

  const { trackCallback } = useAnalytics();

  const externalAccount = useSelector(getExternalAccount);

  const { type: thirdPartyName } = useParams<{ type: string }>();

  const { switchTo } = useUncontrolledSwitch();

  const thirdPartyKeys = externalAccount.keys[thirdPartyName]?.keys;
  const { isFetching, isSaving } = externalAccount;

  useLayoutEffect(() => {
    if (thirdPartyKeys) {
      switchTo(SwitchableStates.INTRO, { dontFetch: true });
    }
  }, [thirdPartyKeys]);

  const handleSubmit = (keys: ThirdPartyKeys) => {
    dispatch(saveExternalAccount(thirdPartyName, keys));
  };

  if (isSaving || isFetching) {
    return (
      <CenteredFlex>
        <Loader />
      </CenteredFlex>
    );
  }

  return (
    <AnalyticsPage tag={thirdPartyName} type="page">
      <MainHolder>
        <SubheaderWithBorder>
          <ContentHolder>
            <BlueTypography variant="h1">Connect to LiveLike</BlueTypography>
          </ContentHolder>
        </SubheaderWithBorder>
        <DescriptionContainer>
          <StyledTypography variant="p">
            <a
              onClick={trackCallback({
                event: 'click',
                type: 'link',
                tag: `link_livelike`,
              })}
              href="https://livelike.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LiveLike
            </a>
            &nbsp; is an audience engagement platform. You can use this integration to transform streaming into
            viewer-driven interactive experience.
          </StyledTypography>
          <ThemedMessage variant={MessageVariants.HELP}>
            Please make sure to read <ButtonLink tag="button_link_our_guide">our guide</ButtonLink> for integrating
            InPlayer with LiveLike
          </ThemedMessage>
        </DescriptionContainer>
        <FormContainer>
          <InputsForm livelikeKeys={thirdPartyKeys} onSubmit={handleSubmit} />
        </FormContainer>
      </MainHolder>
    </AnalyticsPage>
  );
};

export default ConnectExistingAccount;
