import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import http, { ContentType } from 'utils/http';
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';
import { API } from 'constants/api';
import RootState from 'ducks/RootState';

// Load added custom fields
export enum LoadCustomFieldsActionTypes {
  START = 'LOAD_CUSTOM_FIELDS_REQUEST',
  SUCCESS = 'LOAD_CUSTOM_FIELDS_SUCCESS',
  ERROR = 'LOAD_CUSTOM_FIELDS_FAILURE',
}

type LoadCustomFieldsAction = Action<LoadCustomFieldsActionTypes>;

export const loadCustomFields = (
  tenantUuid: string
): ThunkAction<Promise<any>, RootState, void, LoadCustomFieldsAction> => async (dispatch) => {
  const endpoint = API.FETCH_CUSTOM_FIELDS;

  try {
    dispatch({ type: LoadCustomFieldsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        tenantUuid,
      },
    });
    dispatch({ type: LoadCustomFieldsActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: LoadCustomFieldsActionTypes.ERROR });
  }
};

// Add custom fields
export enum AddCustomFieldActionTypes {
  START = 'ADD_CUSTOM_FIELD_REQUEST',
  SUCCESS = 'ADD_CUSTOM_FIELD_SUCCESS',
  ERROR = 'ADD_CUSTOM_FIELD_FAILURE',
}

type AddCustomFieldAction = Action<AddCustomFieldActionTypes | NotificationActionTypes>;

export const createCustomField = (
  customField: any,
  isSplit?: boolean
): ThunkAction<Promise<any>, RootState, void, AddCustomFieldAction> => async (dispatch) => {
  const endpoint = API.ADD_CUSTOM_FIELD;
  const successData = { customField, isSplit };

  try {
    dispatch({ type: AddCustomFieldActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data: customField,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: AddCustomFieldActionTypes.SUCCESS, payload: { ...successData, ...response.data } });
    const notificationContent = isSplit
      ? 'Full name successfully split into First name and Last name.'
      : 'Custom field successfully created.';
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        type: 'success',
        content: notificationContent,
      })
    );
  } catch (_) {
    dispatch({ type: AddCustomFieldActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: 'Validation error. Please check the field values.',
      })
    );
  }
};

// Update custom fields
export enum UpdateCustomFieldActionTypes {
  START = 'UPDATE_FIELD_REQUEST',
  SUCCESS = 'UPDATE_FIELD_SUCCESS',
  ERROR = 'UPDATE_FIELD_FAILURE',
}

type UpdateCustomFieldAction = Action<UpdateCustomFieldActionTypes | NotificationActionTypes>;

export const updateCustomField = (
  id: number,
  customField: any
): ThunkAction<Promise<any>, RootState, void, UpdateCustomFieldAction> => async (dispatch) => {
  const endpoint = API.CUSTOM_FIELD;
  const successData = { customField };

  try {
    dispatch({ type: UpdateCustomFieldActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        id,
      },
      data: customField,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: UpdateCustomFieldActionTypes.SUCCESS, payload: { ...successData, ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Custom field successfully updated.',
        type: 'success',
      })
    );
  } catch (_) {
    dispatch({ type: UpdateCustomFieldActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: 'Validation error. Please check the field values.',
      })
    );
  }
};

// Remove custom field
export enum RemoveCustomFieldActionTypes {
  START = 'REMOVE_FIELD_REQUEST',
  SUCCESS = 'REMOVE_FIELD_SUCCESS',
  ERROR = 'REMOVE_FIELD_FAILURE',
}

type RemoveCustomFieldAction = Action<RemoveCustomFieldActionTypes | NotificationActionTypes>;

export const removeCustomerField = (
  id: number,
  merge?: any
): ThunkAction<Promise<any>, RootState, void, RemoveCustomFieldAction> => async (dispatch) => {
  const endpoint = API.CUSTOM_FIELD;
  const successData = { id, merge };

  try {
    dispatch({ type: RemoveCustomFieldActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        id,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: RemoveCustomFieldActionTypes.SUCCESS, payload: { ...successData, ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Custom field successfully deleted.',
        type: 'success',
      })
    );
  } catch (_) {
    dispatch({ type: RemoveCustomFieldActionTypes.ERROR });
  }
};

export const CUSTOM_FIELD_DETAILS_SUCCESS = 'CUSTOM_FIELD_DETAILS_SUCCESS';

export const loadCustomFieldDetails = (id: string | number) => ({
  type: CUSTOM_FIELD_DETAILS_SUCCESS,
  payload: {
    id,
  },
});

export const RESET_FIELD_DETAILS = 'RESET_FIELD_DETAILS';

export const resetFieldDetails = () => ({
  type: RESET_FIELD_DETAILS,
});

export const ADD_OPTION_INPUT = 'ADD_OPTION_INPUT';

type AddOptionInputAction = Action<typeof ADD_OPTION_INPUT>;

export const addOptionInput = (input: any): ThunkAction<void, RootState, void, AddOptionInputAction> => (
  dispatch,
  getState
) => {
  const { length } = getState().customFields.optionInputs.inputs;

  if (length >= 80) {
    return;
  }

  dispatch({
    type: ADD_OPTION_INPUT,
    input,
  });
};

export const RESET_OPTION_INPUT = 'RESET_OPTION_INPUT';

export const resetOptionInput = () => ({
  type: RESET_OPTION_INPUT,
});

export const REMOVE_OPTION_INPUT = 'REMOVE_OPTION_INPUT';

export const removeOptionInput = (id: string | number) => ({
  type: REMOVE_OPTION_INPUT,
  payload: {
    id,
  },
});

export const RESET_FIELD_COLLECTION = 'RESET_FIELD_COLLECTION';

export const resetFieldCollection = () => ({
  type: RESET_FIELD_COLLECTION,
});
