import * as React from 'react';
import { Colors, Typography, AnalyticsComponent } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';
import { transparentize } from 'polished';

// utils
import media from 'utils/mediaQueries';

// styled components
import { Container, Title } from 'pages/MerchantPages/Analytics/components/shared';

interface Props {
  title: string;
  icon: string;
  active: boolean;
  color: string;
  handleIconClick: () => any;
  renderContent: () => any;
}
interface ContainerProps {
  color: string;
  active?: boolean;
}

const StyledContainer = styled(Container)<ContainerProps>`
  background-color: ${({ color }) => color};
  width: 100%;
  box-shadow: inset 0 -3em 3em ${transparentize(0.9, Colors.black)},
    0.3em 0.3em 1em ${transparentize(0.7, Colors.black)};
  cursor: pointer;
  transform: ${({ active }) => active && 'scale(1.14)'};

  :hover {
    transform: scale(1.14);
    transition: all 0.2s;
  }
`;

const StyledTitle = styled(Title)`
  color: ${Colors.white};

  ${media.customMaxWidth(1400)} {
    font-size: ${({ theme }) => theme.font.sizes.medium};
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDescription = styled(Typography)`
  display: flex;
  flex-direction: column;
  color: ${Colors.white};
  justify-content: space-evenly;
`;

const StyledIcon = styled.img`
  width: 90px;
  height: 80px;
  color: ${Colors.gray};

  ${media.customMaxWidth(1500)} {
    width: 60px;
    height: 50px;
  }
`;
const AnalyticsInfoBox: React.FunctionComponent<Props> = ({
  active,
  icon,
  handleIconClick,
  renderContent,
  title,
  color,
}) => (
  <AnalyticsComponent>
    {({ pages, tracker, merchantId, ip }) => (
      <StyledContainer
        onClick={() => {
          tracker.track({
            event: 'click',
            type: 'box',
            tag: `box_${title.toLowerCase()}`,
            pages,
            merchantId,
            ip,
          });
          handleIconClick();
        }}
        color={color}
        active={active}
      >
        <StyledTitle variant="h5">{title}</StyledTitle>
        <Wrapper>
          <StyledDescription variant="h5">{renderContent()}</StyledDescription>
          <StyledIcon alt="analytics-icon" src={icon} />
        </Wrapper>
      </StyledContainer>
    )}
  </AnalyticsComponent>
);

export default AnalyticsInfoBox;
