import React, { HTMLAttributes } from 'react';

// types
import { Follower } from 'ducks/merchantDucks/agency/types';
import { ModalNames } from 'ducks/merchantDucks/modal/types';

// components
import BaseModal, { ModalActions } from 'components/Modals/BaseModal';
import ImpersonateAsFollowerForm, { LoginAsFollowerFormValues } from '../forms/ImpersonateAsFollowerForm';

interface OwnProps {
  follower: Partial<Follower>;
  handleLoginAsFollower: (data: LoginAsFollowerFormValues) => any;
}

type Props = OwnProps & HTMLAttributes<HTMLDivElement>;

const ImpersonateModal = ({ follower: { full_name: fullName, email }, handleLoginAsFollower }: Props) => (
  <BaseModal
    title={`Sign in as: ${fullName} (${email})`}
    type="impersonate"
    modalName={ModalNames.Impersonate}
    render={({ closeModal }: ModalActions) => {
      return <ImpersonateAsFollowerForm onSubmit={handleLoginAsFollower} handleCloseModal={closeModal} />;
    }}
  />
);

export default ImpersonateModal;
