import React, { PureComponent, ReactNode } from 'react';
import { Switch, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { History } from 'history';
import { Colors } from '@inplayer-org/inplayer-ui';
import styled, { css } from 'styled-components';
import qs from 'qs';

// utils
import tokenManager from 'utils/TokenManager';

// actions
import {
  logoutUser as logoutUserAction,
  loginUser as loginUserAction,
  loadUser as loadUserAction,
  loadIAMToken as loadIAMTokenAction,
  setTourGuideState as setTourGuideStateAction,
} from 'ducks/merchantDucks/user/actions';
import { notifyUser as notifyUserAction } from 'ducks/merchantDucks/notifications/actions';
import {
  loginAsFollower as loginAsFollowerAction,
  loginAsMaster as loginAsMasterAction,
} from 'ducks/merchantDucks/agency/actions';

// components
import Footer from 'components/Footer/Footer';
import Loader from 'components/Loader';
import { SideNav } from 'components/Navigation';

// Types
import RootState from 'ducks/RootState';
import NotificationState from 'ducks/merchantDucks/notifications/types';
import { Roles } from 'ducks/merchantDucks/user/types';

// images
import backgroundImage from 'assets/pics/background.jpg';

// Pages
import RestrictedRoute from 'restrictions/routes/RestrictedRoute';
import NewPassword from 'pages/MerchantPages/Authentication/NewPassword/NewPassword';
import ForgotPassword from 'pages/MerchantPages/Authentication/ForgotPassword/ForgotPassword';
import ResendCode from 'pages/MerchantPages/Authentication/ResendCode/ResendCode';
import Register from 'pages/MerchantPages/Authentication/Register/Register';
import ActivateAccount from 'pages/MerchantPages/Authentication/ActivateAccount/ActivateAccount';
import Login from 'pages/MerchantPages/Authentication/Login/Login';
import Dashboard from 'pages/App/Dashboard';
import ActivationMessage from 'pages/App/ActivationMessage';
import AdminDashboard from 'pages/App/AdminDashboard';

// styles
import GlobalStyles from 'pages/App/GlobalStyles';
import { Colors as NewColors } from 'pages/MerchantPages/Authentication/components/colors';

const AppContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

interface ContentContainerProps {
  hideBackgroundImage: boolean;
  showGradientBackground: boolean;
}

const ContentContainer = styled.div<ContentContainerProps>`
  width: 100%;
  overflow-x: scroll;

  ${({ hideBackgroundImage }: ContentContainerProps) =>
    hideBackgroundImage
      ? css`
          background: ${Colors.pale};
        `
      : css`
          background-image: url(${backgroundImage});
          background-size: cover;
          background-repeat: no-repeat;
        `}
  ${({ showGradientBackground }) =>
    showGradientBackground && `background: linear-gradient(${NewColors.blue}, 20%, ${Colors.white});`}
`;

interface OwnProps extends RouteComponentProps {
  history: History;
}

interface DispatchProps {
  notifyUser: (uuid: string) => any;
  logoutUser: () => any;
  loginUser: (email: string, password: string) => any;
  loginAsFollower: (data: any) => any;
  loginAsMaster: (data: any) => any;
  loadUser: (isSocialAuth: boolean, isAdmin: boolean) => any;
  loadIAMToken: (isAdmin?: boolean) => any;
  setTourGuideState: Function;
}

interface StateProps {
  isAuthenticated: boolean;
  userGuid: string;
  userRoles: Array<Roles>;
  isLoading: boolean;
  notifications: NotificationState;
  isAdmin: boolean;
}

export type Props = OwnProps & StateProps & DispatchProps;

interface State {
  isReady: boolean;
}

const pathNames = ['/login', '/register', '/forgot-password', '/new-password'];

const pathNamesWithoutSideNav = [...pathNames, '/activation-message', '/activate', '/resend'];

export class App extends PureComponent<Props, State> {
  state = { isReady: false };

  componentDidMount() {
    const {
      isAuthenticated,
      notifications: { isSubscribed, isFailed },
      notifyUser,
      loadIAMToken,
      userGuid,
      location: { search, pathname },
    } = this.props;

    const isAdmin = pathname.includes('/admin');

    // Logic when token is present in the URL, like when logging in as from admin panel
    const { t: token, rt: refreshToken, exp: expires } = qs.parse(search.replace(/^\?/, ''));

    if (pathname === '/login' && token && refreshToken && expires) {
      tokenManager.token = token as string;
      tokenManager.refreshToken = refreshToken as string;
      tokenManager.expires = expires as string;
    }

    /*
    If there is merchant token and no admin token load merchant
    If there is merchant and admin token on merchant route load merchant
    Else load admin
    If there is only admin token load admin
    If no tokens do nothing
    */
    if (tokenManager.token) {
      if (!tokenManager.admin || !isAdmin) {
        this.loadUser(false);
      } else {
        this.loadUser(true);
      }
      if (!tokenManager.IAMToken) {
        loadIAMToken(false);
      }
    } else if (tokenManager.admin) {
      this.loadUser(true);
      if (!tokenManager.IAMAdminToken) {
        loadIAMToken(true);
      }
    } else {
      this.setReady();
    }

    if (isAuthenticated && !isSubscribed && !isFailed) {
      notifyUser(userGuid);
    }
  }

  componentDidUpdate() {
    const {
      isAuthenticated,
      notifications: { isSubscribed, isFailed },
      notifyUser,
      userGuid,
    } = this.props;

    if (isAuthenticated && !isSubscribed && !isFailed) {
      notifyUser(userGuid);
    }
  }

  loadUser = async (isAdmin: boolean) => {
    const { loadUser } = this.props;
    await loadUser(false, isAdmin);
    this.setReady();
  };

  setReady = () => {
    this.setState({ isReady: true });
  };

  render(): ReactNode {
    const {
      isAuthenticated,
      isLoading,
      userRoles,
      isAdmin,
      location: { pathname },
      setTourGuideState,
    } = this.props;
    const { isReady } = this.state;

    const showGradientBackground = pathNames.includes(pathname);
    const hideSideNav = pathNamesWithoutSideNav.includes(pathname);

    if (isLoading || !isReady) {
      return <Loader />;
    }

    return (
      <AppContainer>
        <GlobalStyles />
        {isAuthenticated && !hideSideNav && (
          <SideNav userRoles={userRoles} onLogoClick={() => setTourGuideState({ isLogoClicked: true })} />
        )}
        <ContentContainer hideBackgroundImage={isAuthenticated} showGradientBackground={showGradientBackground}>
          <main>
            <Switch>
              <RestrictedRoute isRestricted={isAuthenticated && !isAdmin} path="/login" component={Login} />
              <RestrictedRoute isRestricted={isAuthenticated && !isAdmin} path="/register" component={Register} />
              <RestrictedRoute
                isRestricted={isAuthenticated && !isAdmin}
                path="/activate"
                component={ActivateAccount}
              />
              <RestrictedRoute isRestricted={isAuthenticated && !isAdmin} path="/resend" component={ResendCode} />
              <RestrictedRoute
                isRestricted={isAuthenticated && !isAdmin}
                path="/forgot-password"
                component={ForgotPassword}
              />
              <RestrictedRoute
                isRestricted={isAuthenticated && !isAdmin}
                path="/new-password"
                component={NewPassword}
              />
              <RestrictedRoute
                isRestricted={isAuthenticated && !isAdmin}
                path="/activation-message"
                component={ActivationMessage}
              />
              <RestrictedRoute
                isRestricted={!isAuthenticated ? !isAuthenticated : !isAdmin}
                path="/admin"
                redirectTo="/merchant"
                component={AdminDashboard}
              />
              <RestrictedRoute
                isRestricted={!isAuthenticated ? !isAuthenticated : isAdmin}
                path="/"
                redirectTo="/login"
                component={Dashboard}
              />
              <Redirect to={isAdmin ? '/admin' : '/'} />
            </Switch>
          </main>
          <Footer />
        </ContentContainer>
      </AppContainer>
    );
  }
}

export const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAdmin: state.auth.isAdmin,
  isLoading: state.auth.isLoading,
  userGuid: state.auth.user.uuid,
  userRoles: state.auth.user.roles,
  notifications: state.notifications,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  notifyUser: notifyUserAction,
  logoutUser: logoutUserAction,
  loginUser: loginUserAction,
  loginAsFollower: loginAsFollowerAction,
  loginAsMaster: loginAsMasterAction,
  loadUser: loadUserAction,
  loadIAMToken: loadIAMTokenAction,
  setTourGuideState: setTourGuideStateAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
