import React, { Children, ReactElement, isValidElement } from 'react';
import isArray from 'lodash/isArray';

// local types
import { Props as SwitcheeProps } from './Switchee';

// local hooks
import useUncontrolledSwitch from './useUncontrolledSwitch';

interface Props {
  switchId?: string;
  children: ReactElement<SwitcheeProps> | ReactElement<SwitcheeProps>[];
}

const SwitchIterator = ({ switchId, children }: Props) => {
  const { value } = useUncontrolledSwitch(switchId);

  let matchingForm: ReactElement<SwitcheeProps> | null = null;

  Children.forEach(children, (child) => {
    if (!isValidElement(child) || matchingForm) {
      return;
    }

    const { props } = child;

    const values = isArray(props.value) ? props.value : [props.value];

    if (values.includes(value)) {
      matchingForm = child;
    }
  });

  if (!matchingForm) {
    return null;
  }

  return <>{matchingForm}</>;
};

export default SwitchIterator;
