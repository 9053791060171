import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

// components
import NewPasswordForm from 'pages/MerchantPages/Authentication/NewPassword/forms/NewPassword';
import Loader from 'components/Loader';
import Header from 'pages/MerchantPages/Authentication/components/Header';

// actions
import { addNewPassword } from 'ducks/merchantDucks/user/actions';

// selectors
import { getForgotPasswordForm } from 'ducks/merchantDucks/form/selectors';

const NewPassword = () => {
  const dispatch = useDispatch();
  const forgotPasswordForm = useSelector(getForgotPasswordForm);
  const history = useHistory();
  const { search } = useLocation();
  useEffect(() => {
    if (forgotPasswordForm.setNewPassword) {
      history.push('/login');
    }
  }, [forgotPasswordForm]);

  const query = new URLSearchParams(search);

  const handleAddNewPassword = (data: { password: string; password_confirmation: string; token: string }) => {
    return dispatch(
      addNewPassword({
        password: data.password,
        passwordConfirmation: data.password_confirmation,
        token: data.token,
      })
    );
  };
  const { isUpdating } = forgotPasswordForm;

  if (isUpdating) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <NewPasswordForm
        token={query.get('token')}
        organization={query.get('organization-name')}
        onSubmit={handleAddNewPassword}
      />
    </>
  );
};

export default NewPassword;
