import styled from 'styled-components';
import { Dropdown, DropdownProps } from '@inplayer-org/inplayer-ui';

interface ExtendedDropdownProps extends DropdownProps {
  fullWidth?: boolean;
  fullHeight?: boolean;
}

const ExtendedDropdown = styled(Dropdown)`
  ${({ fullWidth }: ExtendedDropdownProps) => fullWidth && `width: 100%`};
  ${({ fullHeight }: ExtendedDropdownProps) => fullHeight && `height: 100%`};
`;

export default ExtendedDropdown;
