import React, { FunctionComponent } from 'react';
import { Grid } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';
import { MdDone } from 'react-icons/md';

import { Colors } from 'pages/MerchantPages/Authentication/components/colors';
import { StyledTypography } from 'pages/MerchantPages/Authentication/components/sharedComponents';

const { Container, Cell } = Grid;

const InPlayerIconStyled = styled(MdDone)`
  color: ${Colors.black};
`;

const Title = styled(StyledTypography)`
  font-weight: ${({ theme }) => theme.font.weights.bold};
  margin-bottom: 0.5rem;
`;

const StyledContainer = styled(Container)`
  margin-top: 2rem;
`;

interface Props {
  title: string;
  description: string;
}

const ParagraphDescription: FunctionComponent<Props> = ({ title, description }) => {
  return (
    <StyledContainer columns={8}>
      <Cell width={1}>
        <InPlayerIconStyled name="done" size={30} />
      </Cell>

      <Cell width={7} left={2}>
        <Container columns={1} gap="0">
          <Cell width={1}>
            <Title variant="h6">{title}</Title>
          </Cell>
          <Cell width={1}>
            <StyledTypography variant="p">{description}</StyledTypography>
          </Cell>
        </Container>
      </Cell>
    </StyledContainer>
  );
};

export default ParagraphDescription;
