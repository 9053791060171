import styled, { css } from 'styled-components';
import { Form } from 'formik';
import { Grid, Button, Colors, Typography, AnalyticsProps } from '@inplayer-org/inplayer-ui';
import { ifProp, prop } from 'styled-tools';
import { transparentize, darken } from 'polished';
import { FiDownload } from 'react-icons/fi';

// utils
import media from 'utils/mediaQueries';

const { Container } = Grid;

export const Label = styled(Typography)<{ title?: boolean }>`
  color: ${Colors.fontGray};
  text-transform: uppercase;

  ${({ title }) =>
    title &&
    css`
      font-weight: ${({ theme }: any) => theme.font.weights.bold};
    `}
`;

export const FullHeightForm = styled(Form)`
  height: 100%;
`;

interface ContentContainerProps {
  withPadding?: boolean;
  height?: string;
}

const sharedStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${ifProp('height', prop('height'), '100%')};
  padding: ${ifProp('withPadding', '2rem 6%')};
`;

export const ContentContainer = styled.div<ContentContainerProps>`
  ${sharedStyle}
`;

export const FullHeightContainer = styled(Container)<ContentContainerProps>`
  ${sharedStyle}
`;

export const CenterContent = styled.div`
  padding: 2rem 6%;
  ${media.customMaxHeight(800)} {
    overflow: auto;
  }
`;

export const CenterContentContainer = styled(Container)`
  padding: 2rem 6%;
`;

interface StyledButtonProps extends AnalyticsProps {
  isNavLink?: boolean;
  padding?: string;
  width?: string;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  border-radius: 5px;
  color: ${Colors.white};
  background-color: ${Colors.skyBlue};
  border: none;
  box-shadow: 0 0 4px 1px ${transparentize(0.87, Colors.fontDarkGray)};
  transition: all 200ms linear;
  padding: ${({ padding }) => padding || '0.5rem'};
  font-weight: ${({ theme }) => theme.font.weights.bold};
  text-align: center;
  ${({ width }) =>
    width &&
    `
  width: ${width};
  `}
  :hover {
    background-color: ${darken(0.04, Colors.skyBlue)};
    box-shadow: 0 0 8px 1px ${transparentize(0.87, Colors.fontDarkGray)};
    color: ${Colors.white};
  }

  ${({ isNavLink }) =>
    isNavLink &&
    css`
      color: ${Colors.skyBlue};
      background-color: transparent;
      box-shadow: none;
      display: grid;
      place-content: center;
      :hover {
        color: ${Colors.skyBlue};
        background-color: ${transparentize(0.9, Colors.skyBlue)};
        box-shadow: none;
      }
    `}
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
`;

export const StyledTypography = styled(Typography)`
  margin-bottom: 1rem;
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 1rem;
`;

export const BlueTypography = styled(Typography)`
  color: ${Colors.blue};
`;

export const CancelButton = styled(Button)`
  color: ${Colors.fontDarkGray};
  border: none;
  background: transparent;
  box-shadow: none;
  &:hover {
    background-color: ${transparentize(0.9, Colors.fontDarkGray)};
    box-shadow: none;
    color: ${Colors.fontDarkGray};
  }
`;

export const StyledSpan = styled.span<{ isColor?: boolean }>`
  color: ${({ isColor }) => (isColor ? Colors.skyBlue : Colors.fontDarkGray)};
  cursor: pointer;

  :hover {
    color: ${({ isColor }) => (isColor ? darken(0.1, Colors.skyBlue) : darken(0.1, Colors.fontDarkGray))};
  }
`;

interface ItalicDescriptionProps {
  margin?: string;
}

export const ItalicDescription = styled(Typography)<ItalicDescriptionProps>`
  font-style: italic;
  font-size: ${({ theme }) => theme.font.sizes.medium};
  ${({ margin }) => margin && `margin: ${margin}`};
`;

export const BulkHeader = styled(BlueTypography)`
  margin-bottom: 1rem;
`;

export const FlexContainer = styled.div<{ isMarginBottom?: boolean }>`
  display: flex;
  align-items: center;
  ${({ isMarginBottom }) => isMarginBottom && 'margin-bottom: 2rem;'}
`;

export const StyledButtonSpan = styled.span`
  padding-left: 5px;
`;
export const StyledIcon = styled(FiDownload)`
  width: 1.3rem;
  height: 1.3rem;
  vertical-align: middle;
  color: ${Colors.darkGray};

  :hover {
    color: ${Colors.fontDarkGray};
    cursor: pointer;
    transition: color 200ms ease-in-out;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  margin-bottom: 0.6rem;
  padding-bottom: 0.3rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px ${Colors.gray};
  cursor: pointer;
`;

export const Description = styled(Typography)`
  white-space: pre-wrap;
`;

export const InsideWrapper = styled.div`
  width: 86%;
  margin: 0 auto;
  padding: 15px 0 15px;
  max-width: 1400px;
  position: relative;
`;

export const PreviewWrapper = styled.div<{
  isFullWidth?: boolean;
  isAnalyticsView?: boolean;
  shouldSetMargin?: boolean;
}>`
  ${({ isFullWidth }) =>
    isFullWidth
      ? css`
          width: 100%;
          display: grid;
          grid-template-columns: 8rem 1fr;
          grid-template-rows: 5rem 1fr;
        `
      : css`
          width: 80%;
          margin: auto 0;
          padding: 0 8rem;
          display: flex;
          flex-direction: column;
          justify-content: center;

          @media screen and (max-width: 1400px) {
            padding: 0 6rem;
          }
          @media screen and (max-width: 1200px) {
            padding: 0 4rem;
          }
          @media screen and (max-width: 1100px) {
            padding: 0;
          }
        `};
  ${({ isAnalyticsView }) =>
    isAnalyticsView &&
    css`
      width: 100%;
      padding: 0 5.5rem;
    `}
  ${({ shouldSetMargin }) =>
    shouldSetMargin &&
    css`
      margin-top: 100px;
    `}
  transition: width 0.5s;
`;

export const AssetDividedWrapper = styled.div<{ marginTop?: string }>`
  display: flex;
  width: 100%;
  position: relative;
  ${({ marginTop }) => marginTop && `margin-top:${marginTop};`}
`;
