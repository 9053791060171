export interface MetadataType {
  key: string;
  value: string;
  isKeyEditingDisabled?: boolean;
  id?: number;
}

export interface OvpMetadata {
  title: string;
  description?: string;
  image: string;
}

export enum AssetType {
  Video = 'video',
  Html = 'html_asset',
  File = 'dlc_asset',
  RSS = 'rss_asset',
  JWOTT = 'jw_ott_app_asset',
}

export interface QuickAddData {
  videoId: string | number;
  eventId?: string;
  player?: string;
}

export enum Steps {
  BasicAssetInfo,
  AssetContentType,
  Pricing,
  OvpVideos,
  OvpVideoQuickAdd,
  SyncJwPlayer,
}

export enum OvpCategoryType {
  AddOneVideo = 'add-one-video',
  QuickAdd = 'quick-add',
  SyncVideos = 'sync-videos',
}

export enum AccessControlTypeNames {
  CODE_OPTIONS = 'Passcode options',
  DATA_CAPTURE_FIELDS = 'Data capture fields',
  PRICE_OPTIONS = 'Price options',
  ACCESS_VOUCHER_OPTIONS = 'Access voucher options',
}
