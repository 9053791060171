export const packages = {
  PACKAGE: 'items/packages/{id}',
  PACKAGE_ITEMS: 'items/packages/{id}/items',
  SAVE_PACKAGE: 'items/packages',
  DELETE_ALL_ITEMS_PACKAGE: 'items/packages/{packageId}/items',
  FETCH_PACKAGES: 'items/packages/{tenantUuid}',
  REACTIVATE_PACKAGE: 'items/packages/reactivate-package/{id}',
  SAVE_PACKAGE_ASSET: 'items/packages/{packageId}/item/{id}',
  REMOVE_PACKAGE_ASSET: 'items/packages/{packageId}/item/{itemId}',
  PACKAGE_IMAGE: 'images/packages',
  SAVE_BULK_ASSETS: 'v2/items/packages/{id}/bulk',
  ADD_ALL_ASSETS_TO_PACKAGE: 'v2/items/packages/{packageId}/items',
};
