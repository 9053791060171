import { analytics } from './modules/analytics';
import { assets } from './modules/assets';
import { items } from './modules/items';
import { accesses } from './modules/accesses';
import { audience } from './modules/audience';
import { branding } from './modules/branding';
import { customFields } from './modules/customFields';
import { packages } from './modules/packages';
import { reports } from './modules/reports';
import { vouchers } from './modules/vouchers';
import { user } from './modules/user';
import { externalAccount } from './modules/externalAccount';
import { webhooks } from './modules/webhooks';
import { oauth } from './modules/oauth';
import { restrictions } from './modules/restrictions';
import { agency } from './modules/agency';
import { inAppintegration } from './modules/inAppintegration';
import { segmentation } from './modules/segmentation';
import { AWS_IOT_URL } from './index';
import { subscriptions } from './modules/subscriptions';
import { payments } from './modules/payments';
import { mailingList } from './modules/mailingList';
import { merchants } from './modules/merchants';
import { subscriptionGroups } from './modules/subscriptionGroups';

export const API = {
  ...assets,
  ...accesses,
  ...audience,
  ...branding,
  ...customFields,
  ...packages,
  ...reports,
  ...vouchers,
  ...user,
  ...externalAccount,
  ...webhooks,
  ...oauth,
  ...restrictions,
  ...agency,
  ...inAppintegration,
  ...items,
  ...segmentation,
  ...subscriptions,
  ...payments,
  ...mailingList,
  ...merchants,
  ...analytics,
  ...subscriptionGroups,
  /* AMAZON IAM */
  AMAZON_IAM: AWS_IOT_URL,
};
