import React, { MouseEventHandler } from 'react';
import { FaRegTrashAlt, FaRegEdit, FaArrowRight, FaRegFileAlt, FaCode } from 'react-icons/fa';
import { MdInfoOutline, MdContentCopy, MdOndemandVideo, MdRssFeed } from 'react-icons/md';
import { AiOutlineExport, AiOutlineSetting, AiOutlineCloseCircle } from 'react-icons/ai';
import { FiUserX, FiMoreVertical, FiCreditCard, FiRepeat, FiDownload } from 'react-icons/fi';
import styled, { css } from 'styled-components';
import { switchProp, ifProp } from 'styled-tools';
import { Colors, AnalyticsProps, useAnalytics } from '@inplayer-org/inplayer-ui';

interface Props extends AnalyticsProps {
  name: string;
  hoverStatus?: string;
  onClick?: MouseEventHandler;
  isLabelIcon?: boolean;
  isIconDisabled?: boolean;
  styles?: any;
}

const Container = styled.span<Props>`
  cursor: pointer;
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  & > svg {
    transition: color 200ms ease-in-out;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.2rem;
    color: ${ifProp({ name: 'copy' }, Colors.white, Colors.darkGray)};
  }
  :hover {
    & > svg {
      color: ${switchProp('hoverStatus', {
        info: ({ theme }) => theme.palette.primary.main,
        danger: Colors.red,
        copy: Colors.white,
        more: ({ theme }) => theme.palette.text.main,
        settings: ({ theme }) => theme.palette.text.main,
        edit: ({ theme }) => theme.palette.text.main,
        arrowRight: ({ theme }) => theme.palette.text.main,
        delete: ({ theme }) => theme.palette.text.main,
      })};
    }
  }

  ${({ isLabelIcon }: Props) =>
    isLabelIcon &&
    css`
      width: 1rem;
      height: 1rem;
      margin-left: 0.25rem;
      vertical-align: middle;
      > svg {
        width: 1rem;
        height: 1rem;
        color: ${Colors.fontDarkGray};
      }
    `};

  ${({ isIconDisabled }) =>
    isIconDisabled &&
    css`
      color: ${Colors.darkGray};
      cursor: default;

      :hover {
        & > svg {
          color: ${Colors.darkGray};
          cursor: not-allowed;
        }
      }
    `}

  ${({ styles }) => styles}
`;

const CommonIcon = ({ name, onClick, hoverStatus, isLabelIcon, isIconDisabled, styles, tag }: Props) => {
  const { track } = useAnalytics();

  const renderIcon = () => {
    switch (name) {
      case 'delete':
        return <FaRegTrashAlt />;
      case 'info':
        return <MdInfoOutline />;
      case 'userExport':
        return <AiOutlineExport />;
      case 'userDeactivate':
        return <FiUserX />;
      case 'copy':
        return <MdContentCopy />;
      case 'more':
        return <FiMoreVertical />;
      case 'settings':
        return <AiOutlineSetting />;
      case 'edit':
        return <FaRegEdit />;
      case 'arrowRight':
        return <FaArrowRight />;
      case 'video':
        return <MdOndemandVideo />;
      case 'scriptTags':
        return <FaCode />;
      case 'file':
        return <FaRegFileAlt />;
      case 'rss':
        return <MdRssFeed />;
      case 'ppv':
        return <FiCreditCard />;
      case 'subscription':
        return <FiRepeat />;
      case 'download':
        return <FiDownload />;
      case 'close':
        return <AiOutlineCloseCircle />;
      default:
        return null;
    }
  };

  const handleClick: MouseEventHandler = (e) => {
    if (isIconDisabled) {
      return;
    }

    if (tag) {
      track({
        event: 'click',
        type: 'icon',
        tag,
      });
    }

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Container
      name={name}
      hoverStatus={hoverStatus}
      isLabelIcon={isLabelIcon}
      isIconDisabled={isIconDisabled}
      styles={styles}
      onClick={handleClick}
    >
      {renderIcon()}
    </Container>
  );
};

export default CommonIcon;
