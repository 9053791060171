import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import { UserNotificationActionTypes } from 'ducks/merchantDucks/notifications/actions';
import NotificationState from 'ducks/merchantDucks/notifications/types';

export const initialState: NotificationState = {
  isSubscribed: false,
  isSubscribing: false,
  isFailed: false,
};

const notifyRequest: Reducer<NotificationState> = (state) => ({
  ...state,
  isSubscribing: true,
  isSubscribed: false,
});

const notifySuccess: Reducer<NotificationState> = (state) => ({
  ...state,
  isSubscribed: true,
  isSubscribing: false,
});

const notifyFailure: Reducer<NotificationState> = (state) => ({
  ...state,
  isFailed: true,
  isSubscribed: false,
  isSubscribing: false,
});

const actionsMap: ActionMap<NotificationState> = {
  [UserNotificationActionTypes.NOTIFY_REQUEST]: notifyRequest,
  [UserNotificationActionTypes.NOTIFY_SUCCESS]: notifySuccess,
  [UserNotificationActionTypes.NOTIFY_ERROR]: notifyFailure,
};

export default reducerWithActionMap(actionsMap, initialState);
