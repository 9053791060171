import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeWrapper, ROOT_ANALYTICS_CONTEXT, AnalyticsHandlerFn } from '@inplayer-org/inplayer-ui';
import { ThemeProvider } from 'styled-components';
import { createBrowserHistory } from 'history';
import loadFonts from 'utils/loadFonts';
import { sha256 } from 'js-sha256';

// store
import { configureStore } from 'store/configureStore';

// helpers
import { getClientIp } from 'utils/getUserIp';

// components
import ErrorBoundary from 'components/ErrorBoundary';
import { App } from 'pages/App';

import './index.css';
import 'rc-time-picker/assets/index.css';

// types
import RootState from 'ducks/RootState';

// api
import { API } from 'constants/api';

const history = createBrowserHistory();
const store = configureStore(undefined, history);
loadFonts();

let GLOBAL_MERCHANT_ID = 0;

const analyticsHandler: AnalyticsHandlerFn = (event) => {
  (async () => {
    try {
      const ipAddress = await getClientIp();
      const hashedIP = sha256(ipAddress);

      const isProduction = process.env.REACT_APP_NODE_ENV === 'production';
      const url = new URL(isProduction ? API.ANALYTICS_PRODUCTION : API.ANALYTICS_STAGING);

      url.searchParams.append('tag', event.tag);
      url.searchParams.append('action', event.event);
      url.searchParams.append('type', event.type);

      // index - 1 => in order to get p0 since first page is empty string
      event.pages.forEach((page: any, index: number) => page.tag && url.searchParams.append(`p${index - 1}`, page.tag));

      url.searchParams.append('timestamp', (Date.now() as unknown) as string);
      url.searchParams.append('mid', `${GLOBAL_MERCHANT_ID}`);
      url.searchParams.append('hash', hashedIP);

      await fetch(url.href);
    } catch (error) {}
  })();
};

ROOT_ANALYTICS_CONTEXT.tracker.registerHandler(analyticsHandler);

const Root = () => {
  const merchantId = useSelector((state: RootState) => state.auth.user.id);

  useEffect(() => {
    GLOBAL_MERCHANT_ID = merchantId;
  }, [merchantId]);

  return (
    <ThemeWrapper withProvider={ThemeProvider}>
      <ConnectedRouter history={history}>
        <ErrorBoundary history={history}>
          <App />
        </ErrorBoundary>
      </ConnectedRouter>
    </ThemeWrapper>
  );
};

render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root')
);
