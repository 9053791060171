export enum ModalNames {
  None = 'none',
  Impersonate = 'impersonate',
  CreateAsset = 'createAsset',
  ChangeAssetContentType = 'changeAssetContentType',
  Extend = 'extend',
  Report = 'report',
  ScheduleReport = 'scheduleReport',
  DeleteScheduledReport = 'deleteScheduledReport',
  RestrictedFunctionality = 'restrictedFunctionality',
  Client = 'client',
  InitialPackageCreate = 'initialPackageCreate',
  VoucherType = 'voucherType',
  Embed = 'embed',
  EraseAccount = 'eraseAccount',
  ExportData = 'exportData',
  GrantAccess = 'grantAccess',
  RevokeAccess = 'revokeAccess',
  Commercials = 'commercials',
  DeleteCommercialReport = 'deleteCommercialReport',
  CustomFields = 'customFields',
  Country = 'country',
  DefaultCountry = 'defaultCountry',
  EnableSSO = 'enableSso',
  SecretKey = 'secretKey',
  SocialSetup = 'socialSetup',
  Branding = 'branding',
  Payments = 'payments',
  Subscription = 'subscription',
  InteractionReport = 'interactionReport',
  CanceledSubscriptionReport = 'canceledSubscriptionReport',
  PayingAudienceModal = 'payingAudienceReport',
  UniqueCustomersModal = 'uniqueCustomersReport',
  DeleteAssetPrice = 'deleteAssetPrice',
  DeleteSeasonAccessPrice = 'deleteSeasonAccessPrice',
  ReportRemove = 'reportRemove',
  ConfirmRevoke = 'confirmRevoke',
  RemoveRelation = 'removeRelation',
  DeleteAllWebhooks = 'deleteAllWebhooks',
  AssetRemove = 'assetRemove',
  DomainAssetRestriction = 'domainAssetRestriction',
  RemoveVoucherAssets = 'removeVoucherAssets',
  RemoveAllPackages = 'removeAllPackages',
  RemoveAccessTypes = 'removeAccessTypes',
  DeleteEmails = 'deleteEmails',
  DeleteVoucher = 'deleteVoucher',
  ReactivateAccount = 'reactivateAccount',
  Unsubscribe = 'unsubscribe',
  Deactivate = 'deactivate',
  ResetPassword = 'resetPassword',
  Metadata = 'metadata',
  SegmentationReportRemove = 'segmentationReportRemove',
  AgeRestriction = 'ageRestriction',
  DomainRestriction = 'domainRestriction',
  GeoRestriction = 'geoRestriction',
  ConfirmEnableSSO = 'confirmEnableSSO',
  GenerateSecretKey = 'generateSecretKey',
  ThemeRemove = 'themeRemove',
  DeleteIntegration = 'deleteIntegration',
  CancelSubscription = 'cancelSubscription',
  RegisteredOnly = 'registeredOnlyReport',
  RemoveItemsFromPackage = 'removeItemsFromPackage',
  PayPalSettings = 'payPalSettings',
  EditOrganisationMember = 'editOrganisationMember',
  DeleteMetadata = 'deleteMetadata',
  Refund = 'refund',
  Mails = 'mails',
  DeleteAssetCode = 'deleteAssetCode',
  UpdateAllAssetCodes = 'updateAllAssetCodes',
  ConfirmCloseSection = 'confirmCloseSection',
  AddMailingItem = 'addMailingItem',
  TerminateSession = 'terminateSession',
  RemoveItemsFromVoucher = 'removeItemsFromVoucher',
  DeletePriceDonation = 'deletePriceDonation',
  EditMailTemplate = 'editMailTemplate',
  DeleteMailTemplate = 'deleteMailTemplate',
  ActivateRelate = 'activateRelate',
  ViewAnalytics = 'viewAnalytics',
  SetGateway = 'setGateway',
  SetFees = 'setFees',
  StripeSettingsModal = 'stripeSettingsModal',
  IntegrationAppModal = 'integrationAppModal',
  DeletePaymentMethod = 'deletePaymentMethod',
  ManageFeatures = 'manageFeatures',
  AdminAgeRestriction = 'adminAgeRestriction',
  ImpersonateAsAgent = 'impersonateAsAgent',
  AddAccessFeeDuration = 'addAccessFeeDuration',
  ChangeContentCategory = 'changeContentCategory',
  WidgetRemove = 'widgetRemove',
  ChatRoomRemove = 'chatRoomRemove',
  ShowLandingPage = 'showLandingPage',
  ResetPreviewTemplate = 'resetPreviewTemplate',
  DeleteTemplate = 'deleteTemplate',
  AddNonRecurringFee = 'addNonRecurringFee',
  DeleteNonRecurringFee = 'deleteNonRecurringFee',
  DisconnectLiveLike = 'disconnectLiveLike',
}

export interface ModalState {
  activeModalName: ModalNames;
  activeModalInstance: string;
}
