export enum CustomFieldVariants {
  TEXT_INPUT = 'input',
  DROPDOWN_LIST = 'select',
  RADIO_BUTTON = 'radio',
  CHECKBOX = 'checkbox',
  COUNTRY_LIST = 'country',
  DATE_PICKER = 'datepicker',
  US_STATE_LIST = 'us_state',
}

export interface CustomField {
  id: number;
  name: string;
  label: string;
  default_value: string;
  placeholder: string;
  type: CustomFieldVariants;
  required: boolean;
  options: Array<any>;
}

export interface OptionInputs {
  inputs: Array<any>;
  nextInput: number;
}

export interface CustomFieldDetails {
  id: number;
  name: string;
  label: string;
  default_value: string;
  placeholder: string;
  type: CustomFieldVariants;
  required: boolean;
  options: Array<any>;
  isFetching: boolean;
}

export default interface CustomFieldsState {
  collection: Array<CustomField>;
  newCollection: Array<any>;
  isSaved: boolean;
  isFetching: boolean;
  customFieldDetails: CustomFieldDetails;
  optionInputs: OptionInputs;
}
