import React, { FunctionComponent } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { Formik, FormikHelpers, Field, FormikProps } from 'formik';
import { object, string } from 'yup';

// utils
import isEmpty from 'lodash/isEmpty';

// hooks
import useRestrictionRules from 'restrictions/roles/hooks/useRestrictionRules';

// constants
import Rules from 'restrictions/roles/constants/rules';
import { API_ROOT } from 'constants/index';

// types
import { ButtonConfig } from 'pages/MerchantPages/Settings/Integrations/OvpIntegrations/components/BrightcoveForm';

// styled components
import { ItalicDescription } from 'components/SharedStyledElements';
import { DescriptionContainer } from 'pages/MerchantPages/Settings/Integrations/InAppIntegrations/components/GoogleInAppForm';

// Components
import { Grid, Button } from '@inplayer-org/inplayer-ui';
import Restricted from 'restrictions/roles/components/Restricted';
import Input from 'components/Fields/Input';
import FieldError from 'components/Fields/FieldError';
import ClipBoardButton from 'components/ClipBoardButton';

const { Container, Cell } = Grid;

interface FormValues {
  api_key: string;
}

interface OwnProps {
  onSubmit: (data: FormValues) => any;
  rokuInAppKeys: any;
  merchantId: number;
}

const validationSchema = object().shape({
  api_key: string()
    .required('*Required')
    .test('api_key', '*Required', (value) => value?.trim().length > 0),
});

type Props = OwnProps;

const RokuInAppForm: FunctionComponent<Props> = ({ onSubmit, rokuInAppKeys, merchantId }) => {
  const initialValues = {
    api_key: (rokuInAppKeys && rokuInAppKeys.api_key) || '',
  };

  const { hasRule } = useRestrictionRules();

  const onFormSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    await onSubmit(values);
    setSubmitting(false);
  };

  const saveButtonProps: ButtonConfig = {
    label: 'Save',
  };

  const updateButtonProps: ButtonConfig = {
    label: 'Update',
    modifier: 'buttonPrimary',
  };

  const buttonProps = !isEmpty(rokuInAppKeys) ? updateButtonProps : saveButtonProps;
  const buttonModifiers = buttonProps.modifier ? [buttonProps.modifier] : [];

  const rokuNotificationUrl = `${API_ROOT}v2/hooks/roku-in-app/${merchantId}`;

  const isReadOnly = hasRule(Rules.READONLY_IN_APP_INTEGRATIONS);

  return (
    <Formik
      onSubmit={onFormSubmit}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting }: FormikProps<FormValues>) => (
        <form onSubmit={handleSubmit}>
          <Container columns={3} rowGap="1rem">
            <Cell width={3}>
              <Field
                tag="text_roku_roku_api_key"
                name="api_key"
                type="text"
                component={Input}
                label="Roku API Key"
                disabled={isReadOnly}
              />
              <FieldError name="api_key" />
            </Cell>
            <Cell width={3}>
              <DescriptionContainer columns={6}>
                <Cell width={1} middle>
                  <ClipBoardButton
                    tag="roku_copy_url"
                    clipboardText={rokuNotificationUrl}
                    tooltipText="Copied!"
                    buttonModifiers={['buttonPrimary']}
                    fullWidth
                    placement="top"
                  >
                    Copy URL
                  </ClipBoardButton>
                </Cell>
                <Cell width={5} left={2}>
                  <ItalicDescription variant="p">
                    <MdInfoOutline />
                    Add this notification URL to your Roku dashboard to connect the InPlayer and Roku platforms.
                  </ItalicDescription>
                </Cell>
              </DescriptionContainer>
            </Cell>
            <Restricted isRestricted={isReadOnly}>
              <Cell width={1} left={3}>
                <Button
                  tag={`button_roku_${buttonProps.label.toLowerCase()}`}
                  type="submit"
                  buttonModifiers={buttonModifiers}
                  fullWidth
                  disabled={isSubmitting}
                >
                  {buttonProps.label}
                </Button>
              </Cell>
            </Restricted>
          </Container>
        </form>
      )}
    </Formik>
  );
};

export default RokuInAppForm;
