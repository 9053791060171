import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// actions
import RootState from 'ducks/RootState';
import { fetchSubscriptionsByStatus } from 'ducks/merchantDucks/subscriptions/actions';

// components
import { Loader } from '@inplayer-org/inplayer-ui';
import Tile from './Tile';
import TopResources from './TopResources';

export enum GroupByType {
  ITEM = 'item',
  PRICE = 'price',
}

export enum SubscriptionStatusType {
  ACTIVE = 'active',
  CANCELED = 'canceled',
}

const options = [
  { value: 'item', displayName: 'Asset' },
  { value: 'price', displayName: 'Price' },
];

const ActiveSubscriptions: React.FC = () => {
  const [resource, setResource] = useState<string>(GroupByType.ITEM);
  const groupedActiveSubscriptions = useSelector(
    (state: RootState) => state.subscriptions.activeSubscriptions[resource].collection
  );
  const isFetchingActiveSubs = useSelector(
    (state: RootState) => state.subscriptions.activeSubscriptions[resource].isFetching
  );
  const datePickerPeriod = useSelector((state: RootState) => state.datepicker);
  const dispatch = useDispatch();

  const { startDate, endDate } = datePickerPeriod;

  useEffect(() => {
    dispatch(fetchSubscriptionsByStatus(SubscriptionStatusType.ACTIVE, resource, startDate, endDate));
  }, [fetchSubscriptionsByStatus, startDate, endDate]);

  const handleResource = (value: string) => {
    setResource(value);
    dispatch(
      fetchSubscriptionsByStatus(
        SubscriptionStatusType.ACTIVE,
        value === GroupByType.PRICE ? GroupByType.PRICE : GroupByType.ITEM,
        startDate,
        endDate
      )
    );
  };

  return (
    <Tile
      title="Active subscriptions"
      tileWidth="100%"
      dropdown
      dropdownOptions={options}
      activeOption={resource}
      handleOptionChange={handleResource}
      tileMargin="0 0 1rem 0"
      tileContent={isFetchingActiveSubs ? <Loader /> : <TopResources groupedSubscribers={groupedActiveSubscriptions} />}
    />
  );
};

export default ActiveSubscriptions;
