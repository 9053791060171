import { API_ROOT } from '../index';

export const assets = {
  FETCH_ACCESS_TYPES: 'items/access-types',
  FETCH_ASSETS: 'items/collection',
  FETCH_EXTERNAL_ASSETS: 'ovps/{type}/videos',
  SAVE_ASSET: 'items/asset',
  DELETE_ACCESS_FEE: 'items/access-fees/{id}',
  ASSET_DETAILS: 'items/assets/{id}',
  DELETE_ASSET: 'items/{id}',
  REACTIVATE_ASSET: 'items/reactivate-item/{id}',
  ADD_PREVIEW_IMAGE: 'images/assets',
  DELETE_METADATA: 'items/{itemId}/metadata/{key}',
  DELETE_FREETRIAL: 'items/trial-period/{feeId}',
  DELETE_SETUP_FEE: 'items/setup-fee/{feeId}',
  PULL_OVP_DETAILS: 'ovps/{ovpType}/video',
  GET_JWPLAYERS: 'ovps/jwplayer/players',
  UPLOAD_FILE: 'dlc',
  GET_URL_RAW_DATA: 'rss/fetch',
  GET_RAW_DATA: 'rss/feed/{id}/content',
  CHECK_SYNC_PROGRESS: 'ovps/sync/check-status',
  SYNC_OVP_VIDEOS: 'ovps/sync',
  SESSION_CHOOSER: 'ovps/panopto/session-chooser',
  PANOPTO_VIDEO: `${API_ROOT}ovps/panopto/content-item`,
  // In-app Voucher
  ACCESS_FEE_DETAILS: 'v2/items/{id}/access-fees/{feeId}',
};
