export const replaceNAWithDescriptiveName = (registrationDevices: Array<any> = [], replaceBotWithAPI?: boolean) => {
  const newRegistrationDevices = registrationDevices?.map((item) => {
    if (item.name.toLowerCase() === 'n/a') {
      return { ...item, name: 'info not available' };
    }
    if (item.name.toLowerCase() === 'bot' && replaceBotWithAPI) {
      return { ...item, name: 'API' };
    }
    return item;
  });

  return newRegistrationDevices;
};
