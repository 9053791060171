import RootState from 'ducks/RootState';
import countries, { CountryGeoData } from 'data/countries';
import { StatsPerCountryChartData } from 'ducks/merchantDucks/subscriptions/selectors';
import { replaceNAWithDescriptiveName } from './utils';

const getAudienceState = (state: RootState) => state.audience;

const getRegistrationsState = (state: RootState) => getAudienceState(state).registrations;

export const getTotalRegistrations = (state: RootState) => getRegistrationsState(state).total ?? 0;

export const getRegistrationDevices = (state: RootState) =>
  replaceNAWithDescriptiveName(getRegistrationsState(state).per_device, true);

export const getRegistrationMethod = (state: RootState) =>
  replaceNAWithDescriptiveName(getRegistrationsState(state).per_register_source);

const getLoginsState = (state: RootState) => getAudienceState(state).logins;

export const getTotalLogins = (state: RootState) => getLoginsState(state).total ?? 0;

export const getLoginDevices = (state: RootState) =>
  replaceNAWithDescriptiveName(getLoginsState(state).per_device, true);

export const getLoginsPerCountryChartData = (state: RootState): Array<StatsPerCountryChartData> =>
  replaceNAWithDescriptiveName(
    getLoginsState(state).per_country_code?.map((country) => {
      const geoData = countries.find((c: CountryGeoData) => c.code === country.name);

      return {
        name: geoData?.name || 'N/A',
        code: geoData?.code || 'N/A',
        total: country.total,
        lat: geoData?.coordinates.lat || 0,
        lon: geoData?.coordinates.lng || 0,
      };
    }) ?? []
  );

export const getUserApplications = (state: RootState) => getAudienceState(state).userApplications;
