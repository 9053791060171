import React, { useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import { postMailItem } from 'ducks/adminDucks/paginations/actions';

// Types
import { ModalNames } from 'ducks/merchantDucks/modal/types';

// styled components
import { FlexContainer, StyledLabel } from 'pages/AdminPages/components/SharedStyledElements';

// Components
import { Input, Dropdown, Option, Button, Typography } from '@inplayer-org/inplayer-ui';
import BaseModal from 'components/Modals/BaseModal';

const dropdownOptions: Array<Option> = [
  { value: 'whitelisted', displayName: 'Whitelisted' },
  { value: 'blacklisted', displayName: 'Blacklisted' },
];

const MailingListItemModal = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [blacklisted, setBlacklisted] = useState(false);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget) {
      setEmail(event.currentTarget.value);
    }
  };

  const handleDropdownChange = (type: string) => {
    setBlacklisted(type === 'blacklisted');
  };

  const resetState = () => {
    setEmail('');
    setBlacklisted(false);
  };

  const handleItemAdd = (closeModal: () => void) => () => {
    dispatch(postMailItem(email, blacklisted));
    closeModal();
    resetState();
  };

  const handleCancel = (closeModal: () => void) => () => {
    closeModal();
    resetState();
  };

  return (
    <BaseModal
      modalName={ModalNames.AddMailingItem}
      title="Add new record"
      onClose={resetState}
      render={({ closeModal }) => (
        <>
          <Typography variant="p">Please enter email address and choose status.</Typography>
          <FlexContainer flexDirection="column">
            <StyledLabel htmlFor="email">Email</StyledLabel>
            <Input id="email" name="email" value={email} onChange={handleEmailChange} />
            <StyledLabel htmlFor="status">Status</StyledLabel>
            <Dropdown
              options={dropdownOptions}
              id="status"
              name="status"
              value={blacklisted ? 'blacklisted' : 'whitelisted'}
              onChange={handleDropdownChange}
            />
          </FlexContainer>
          <FlexContainer justifyContent="space-between" margin="1rem 0">
            <Button buttonModifiers={['buttonLink']} onClick={handleCancel(closeModal)}>
              Cancel
            </Button>
            <Button onClick={handleItemAdd(closeModal)}>Add Item</Button>
          </FlexContainer>
        </>
      )}
    />
  );
};

export default MailingListItemModal;
