import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { replace } from 'connected-react-router';
import { Notification } from '@inplayer-org/inplayer-ui';
import moment, { Moment } from 'moment';
import pickBy from 'lodash/pickBy';
import upperFirst from 'lodash/upperFirst';
import reduce from 'lodash/reduce';

// constants
import { API } from 'constants/api';

// utils
import { Optional } from 'utils/types';
import http, { isAxiosError, ContentType } from 'utils/http';
import { setItemType } from 'utils/setItemType';
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';

// images
import inplayerBrandImage from 'assets/pics/ip-preview-premium.jpg';

// types
import RootState from 'ducks/RootState';
import { MetadataType, OvpMetadata } from 'pages/MerchantPages/Assets/assetTypes';
import {
  CreateTemplateRequestData,
  DefaultPreviewMetadata,
  ExternalFee,
  PreviewMetadataNames,
} from 'ducks/merchantDucks/items/types';
import { ApiAction, RouterApiAction } from 'ducks/types';
import { AccessControlType } from 'ducks/merchantDucks/accesses/actions';
import { EventbriteMetadata } from 'pages/MerchantPages/Assets/components/Eventbrite/EventbriteContainer';
import { SeasonPhaseType } from 'pages/MerchantPages/Assets/Price/SeasonSubscription/SeasonSubscriptionInfo';
import { EventType } from 'pages/MerchantPages/Assets/InitialCreate/ContentType';
import { FormValues } from 'pages/MerchantPages/Assets/ContentDetails/containers/AssetContentDetails';

// actions
import { downloadFile, mapDataWithNewKey } from 'ducks/merchantDucks/common/utils';
import { getSignedUrl, postFileBulk, uploadFileBulk } from 'ducks/merchantDucks/common/api';
import { addAssetAgeRestriction, changeAssetDomains, createAssetCountrySet } from '../restrictions/actions';

export interface UpdateItem {
  itemId: number;
  itemType: string;
  content?: string;
  title: string;
  externalAssetId?: number;
  accessControlTypeId?: number;
  metadata?: Record<string, string>;
  isQuickAdd?: boolean;
  assetContentType?: string;
  previewId?: number;
}

export interface ItemDetails {
  itemId?: number;
  title: Optional<string>;
  itemType: string;
  content: any;
  metadata: any;
  accessFees?: Array<any>;
  externalAssetId?: string;
  rawData?: any;
}

export enum ItemType {
  Asset = 'asset',
  Package = 'package',
  Voucher = 'voucher',
}

export type CreateItem = Omit<UpdateItem, 'itemId'>;

// Create asset
export enum CreateItemActionTypes {
  START = 'CREATE_ITEM_REQUEST',
  SUCCESS = 'CREATE_ITEM_SUCCESS',
  ERROR = 'CREATE_ITEM_ERROR',
}

type CreateItemTypes = CreateItemActionTypes | NotificationActionTypes;

export const createItem = ({
  itemType,
  content,
  title,
  metadata,
  externalAssetId,
  isQuickAdd,
  accessControlTypeId,
  assetContentType,
  previewId = 1,
}: CreateItem): RouterApiAction<CreateItemTypes> => async (dispatch) => {
  const endpoint = API.SAVE_ITEM;
  const access_control_type_id = accessControlTypeId || AccessControlType.PAID;
  const successMessageText = setItemType(itemType);

  const asset = {
    item_type: itemType,
    title,
    metadata,
    access_control_type_id,
    event_type: assetContentType,
    template_id: previewId || 1,
  };

  if (isQuickAdd) {
    Object.assign(asset, {
      content,
      external_asset_id: externalAssetId,
    });
  }

  try {
    dispatch({ type: CreateItemActionTypes.START });
    const { data } = await http.authenticated().post(endpoint, {
      data: asset,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: CreateItemActionTypes.SUCCESS, payload: { ...data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `${upperFirst(successMessageText)} successfully created.`,
        type: 'success',
      })
    );
    // Redirect after save asset/package
    if (itemType === ItemType.Package) {
      dispatch(replace(`/assets/packages/edit/${data.id}`));
    } else {
      dispatch(replace(`/assets/edit/${data.id}`));
    }

    return data?.id;
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: CreateItemActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// change Subscription plan

export enum ChangeItemPlanActionTypes {
  START = 'CHANGE_ITEM_PLAN_REQUEST',
  SUCCESS = 'CHANGE_ITEM_PLAN_SUCCESS',
  ERROR = 'CHANGE_ITEM_PLAN_ERROR',
}

export const changeItemPlan = (
  id: number,
  isPlanSwitchEnabled?: boolean
): RouterApiAction<ChangeItemPlanActionTypes> => async (dispatch) => {
  const endpoint = API.UPDATE_ITEM;

  try {
    dispatch({ type: ChangeItemPlanActionTypes.START });

    const response = await http.authenticated().patch(endpoint, {
      pathVariables: { id },
      data: { plan_switch_enabled: isPlanSwitchEnabled },
      contentType: ContentType.URLENCODED,
    });

    dispatch({ type: ChangeItemPlanActionTypes.SUCCESS, payload: { ...response.data } });
  } catch {
    dispatch({ type: ChangeItemPlanActionTypes.ERROR });
  }
};

//

export enum ChangeItemContentTypeActionTypes {
  START = 'CHANGE_ITEM_CONTENT_TYPE_REQUEST',
  SUCCESS = 'CHANGE_ITEM_CONTENT_TYPE_SUCCESS',
  ERROR = 'CHANGE_ITEM_CONTENT_TYPE_ERROR',
}

type ChangeItemContentTypeTypes = ChangeItemContentTypeActionTypes | NotificationActionTypes;

export interface ChangeItemContentTypeParams {
  itemId: number;
  itemType: string;
  rawContent: string;
}

export const changeItemContentType = ({
  itemId: id,
  itemType: item_type,
  rawContent: content,
}: ChangeItemContentTypeParams): RouterApiAction<ChangeItemContentTypeTypes> => async (dispatch) => {
  const endpoint = API.UPDATE_ITEM;

  const data = { id, item_type, content };

  try {
    dispatch({ type: ChangeItemContentTypeActionTypes.START });

    const response = await http.authenticated().patch(endpoint, {
      pathVariables: { id },
      data,
      contentType: ContentType.URLENCODED,
    });

    dispatch({
      type: ChangeItemContentTypeActionTypes.SUCCESS,
      payload: { ...response.data },
    });

    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Asset content type successfully changed.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;

      if (response) {
        dispatch({ type: ChangeItemContentTypeActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Delete item metadata
export enum DeleteItemMetadataActionTypes {
  START = 'DELETE_ITEM_METADATA_REQUEST',
  SUCCESS = 'DELETE_ITEM_METADATA_SUCCESS',
  ERROR = 'DELETE_ITEM_METADATA_ERROR',
}

type DeleteMetadataTypes = DeleteItemMetadataActionTypes | NotificationActionTypes;

export interface DeleteItemMetadataProps {
  metadataKeys: string[];
  itemType: string;
  assetId: number;
}

export const deleteItemMetadata = ({
  metadataKeys: keys,
  itemType,
  assetId,
}: DeleteItemMetadataProps): ApiAction<DeleteMetadataTypes> => async (dispatch) => {
  const endpoint = API.DELETE_ITEM_METADATA;
  const successMessageText = setItemType(itemType);

  try {
    dispatch({ type: DeleteItemMetadataActionTypes.START });

    await http.authenticated().delete(endpoint, {
      pathVariables: {
        id: assetId,
      },
      params: { keys },
      contentType: ContentType.URLENCODED,
    });

    dispatch({ type: DeleteItemMetadataActionTypes.SUCCESS, payload: { keys } });

    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `${upperFirst(successMessageText)} successfully updated.`,
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: DeleteItemMetadataActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// this is a backend flaw, we save the metadata with 'asset_' prefix
// to distinguish custom and paywall metadata
// do not add 'asset_' if updating paywall metadata
const transformMetadata = (isCustomMetadata: boolean, metadata: MetadataType[]) => {
  if (isCustomMetadata) {
    // custom item metadata with 'asset_' prefix
    return metadata.reduce(
      (acc, cur) => ({
        ...acc,
        [`asset_${cur.key}`]: cur.value,
      }),
      {}
    );
  }

  // paywall metadata
  return metadata.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.key]: cur.value,
    }),
    {}
  );
};

// Update metadata
export enum UpdateItemMetadataActionTypes {
  START = 'UPDATE_ITEM_METADATA_REQUEST',
  SUCCESS = 'UPDATE_ITEM_METADATA_SUCCESS',
  ERROR = 'UPDATE_ITEM_METADATA_ERROR',
}

type UpdateMetadataTypes = UpdateItemMetadataActionTypes | NotificationActionTypes;

export interface ItemMetadataProps {
  metadata: Array<MetadataType> | EventbriteMetadata;
  deletedMetadata?: Array<MetadataType>;
  isCustomMetadata?: boolean;
  isExternalAccountMetadata?: boolean;
  deletedExternalMetadata?: Array<keyof EventbriteMetadata>;
  itemType: string;
}

export const updateItemMetadata = ({
  metadata,
  deletedMetadata,
  deletedExternalMetadata,
  isCustomMetadata = true,
  isExternalAccountMetadata = false,
  itemType,
}: ItemMetadataProps): ApiAction<UpdateMetadataTypes> => async (dispatch, getState) => {
  const endpoint = API.UPDATE_ITEM;
  const successMessageText = setItemType(itemType);

  const {
    items: {
      itemDetails: { id: assetId },
    },
  } = getState();

  let newMetadata: any = metadata;

  if (!isExternalAccountMetadata && Array.isArray(metadata)) {
    // this is a backend flaw, we save the metadata with 'asset_' prefix
    // to distinguish custom and paywall metadata
    // do not add 'asset_' if updating paywall metadata
    newMetadata = isCustomMetadata
      ? metadata.reduce(
          (acc, cur) => ({
            ...acc,
            [`asset_${cur.key}`]: cur.value,
          }),
          {}
        )
      : metadata.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.key]: cur.value,
          }),
          {}
        );
  }

  const data = {
    id: assetId,
    metadata: newMetadata,
  };

  if (deletedExternalMetadata && isExternalAccountMetadata) {
    await dispatch(deleteItemMetadata({ metadataKeys: deletedExternalMetadata, itemType, assetId }));
  }

  if (deletedMetadata && deletedMetadata.length) {
    const metadataKeys = Object.keys(transformMetadata(isCustomMetadata, deletedMetadata));
    await dispatch(deleteItemMetadata({ metadataKeys, itemType, assetId }));
  }

  try {
    dispatch({ type: UpdateItemMetadataActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        id: assetId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateItemMetadataActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `${upperFirst(successMessageText)} successfully updated.`,
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateItemMetadataActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Update asset title
export enum UpdateItemTitleActionTypes {
  START = 'UPDATE_ITEM_TITLE_REQUEST',
  SUCCESS = 'UPDATE_ITEM_TITLE_SUCCESS',
  ERROR = 'UPDATE_ITEM_TITLE_ERROR',
}

type UpdateItemTitleTypes = UpdateItemTitleActionTypes | NotificationActionTypes;

export const updateItemTitle = (
  title: string,
  itemId: number,
  itemType: string
): ApiAction<UpdateItemTitleTypes> => async (dispatch) => {
  const endpoint = API.UPDATE_ITEM;
  const successMessageText = setItemType(itemType);

  const data = {
    itemId,
    title,
  };

  try {
    dispatch({ type: UpdateItemTitleActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        id: itemId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateItemTitleActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `${upperFirst(successMessageText)} successfully updated.`,
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateItemTitleActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

const saveItemPreviewImage = async (image: File) => {
  const endpoint = API.ADD_PREVIEW_IMAGE;
  const data = new FormData();
  data.append('asset_preview', image);

  try {
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.FORM_DATA,
    });
    return response;
  } catch (_) {
    Notification.create({
      variant: 'danger',
      title: 'ERROR',
      content: 'An error occurred while adding preview image.',
      duration: 3,
    });
  }
};
// Add preview image
export enum AddPreviewImageActionTypes {
  START = 'ADD_PREVIEW_IMAGE_REQUEST',
  SUCCESS = 'ADD_PREVIEW_IMAGE_SUCCESS',
  ERROR = 'ADD_PREVIEW_MESSAGE_FAILURE',
}

type AddPreviewImageTypes = AddPreviewImageActionTypes | NotificationActionTypes;

export const addPreviewImage = (image: File): ApiAction<AddPreviewImageTypes> => async (dispatch) => {
  try {
    dispatch({ type: AddPreviewImageActionTypes.START });
    dispatch({
      type: AddPreviewImageActionTypes.SUCCESS,
      payload: { ...image },
    });
  } catch (_) {
    dispatch({ type: AddPreviewImageActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: 'An error occurred while adding preview image.',
      })
    );
  }
};

// generate metadata for asset
interface ImageData {
  full_size_image_url: string;
  full_size_image_name: string;
  thumbnail_image_url: string;
  thumbnail_image_name: string;
}

const metadataForItem = (previewImage: ImageData, paywall_cover_photo: string) => {
  if (previewImage) {
    const { full_size_image_url, full_size_image_name, thumbnail_image_url, thumbnail_image_name } = previewImage;

    return {
      paywall_cover_photo: full_size_image_url,
      preview_image_name: full_size_image_name,
      thumbnail_image: thumbnail_image_url,
      thumbnail_image_name,
    };
  }
  if (paywall_cover_photo === inplayerBrandImage) {
    return {
      paywall_cover_photo: 'https://inplayer-paywall-v2.s3.amazonaws.com/images/ip-preview-premium.jpg',
    };
  }
  return null;
};

// Update asset preview
export enum UpdateItemPreviewActionTypes {
  START = 'UPDATE_ITEM_PREVIEW_REQUEST',
  SUCCESS = 'UPDATE_ITEM_PREVIEW_SUCCESS',
  ERROR = 'UPDATE_ITEM_PREVIEW_ERROR',
}

type UpdateItemPreviewTypes = UpdateItemPreviewActionTypes | NotificationActionTypes;

export interface UpdateItemPreview {
  itemType: string;
  metadata: any;
}

export const updateItemPreview = (metadataForUpdate: UpdateItemPreview): ApiAction<UpdateItemPreviewTypes> => async (
  dispatch,
  getState
) => {
  const endpoint = API.UPDATE_ITEM;
  const { itemType, metadata: patchMetadata } = metadataForUpdate;
  const successMessageText = setItemType(itemType);

  const {
    items: {
      itemDetails: { id: assetId, metadata },
    },
  } = getState();

  const { paywall_cover_photo } = patchMetadata;
  let previewImage;
  if (paywall_cover_photo && typeof paywall_cover_photo !== 'string') {
    const response = await saveItemPreviewImage(paywall_cover_photo);

    if (response) {
      previewImage = await response.data;
      dispatch(addPreviewImage(previewImage));
    }
  }

  const data = {
    id: assetId,
    metadata: { ...patchMetadata, ...metadataForItem(previewImage, metadata.paywall_cover_photo) },
  };

  try {
    dispatch({ type: UpdateItemPreviewActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        id: assetId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateItemPreviewActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `${upperFirst(successMessageText)} successfully updated.`,
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateItemPreviewActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Load item details
export enum LoadItemDetailsActionTypes {
  START = 'LOAD_ITEM_DETAILS_REQUEST',
  SUCCESS = 'LOAD_ITEM_DETAILS_SUCCESS',
  ERROR = 'LOAD_ITEM_DETAILS_ERROR',
}

export const loadItemDetails = (tenantUuid: string, itemId: number): ApiAction<LoadItemDetailsActionTypes> => async (
  dispatch
) => {
  const endpoint = API.ITEM_DETAILS;

  try {
    dispatch({ type: LoadItemDetailsActionTypes.START });
    const { data } = await http.authenticated().get(endpoint, {
      pathVariables: {
        tenantUuid,
        id: itemId,
      },
    });

    // Set a default paid asset for old assets that don't have access control type set
    if (!data.access_control_type) {
      data.access_control_type = {
        auth: 1,
        id: AccessControlType.PAID,
        name: 'Paid',
      };
    }

    dispatch({
      type: LoadItemDetailsActionTypes.SUCCESS,
      payload: { ...data },
    });
  } catch (_) {
    dispatch({ type: LoadItemDetailsActionTypes.ERROR });
  }
};

// Load all access fees
export enum LoadAllAccessFeesActionTypes {
  START = 'ALL_ACCESS_FEES_REQUEST',
  SUCCESS = 'ALL_ACCESS_FEES_SUCCESS',
  ERROR = 'ALL_ACCESS_FEES_ERROR',
}

export const loadAllAccessFees = (
  itemId: number,
  voucherId?: number
): ApiAction<LoadAllAccessFeesActionTypes> => async (dispatch) => {
  const endpoint = API.ACCESS_FEES;

  try {
    dispatch({ type: LoadAllAccessFeesActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        id: itemId,
      },
      params: voucherId && { 'expand[voucher]': voucherId },
    });
    dispatch({
      type: LoadAllAccessFeesActionTypes.SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (_) {
    dispatch({ type: LoadAllAccessFeesActionTypes.ERROR });
  }
};

// Delete asset
export enum DeleteItemActionTypes {
  START = 'DELETE_ITEM_REQUEST',
  SUCCESS = 'DELETE_ITEM_SUCCESS',
  ERROR = 'DELETE_ITEM_ERROR',
}

type DeleteItemTypes = DeleteItemActionTypes | NotificationActionTypes;

export const deleteItem = (id: number, itemType: string): RouterApiAction<DeleteItemTypes> => async (dispatch) => {
  const endpoint = API.REMOVE_ITEM;

  try {
    dispatch({ type: DeleteItemActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        item_id: id,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteItemActionTypes.SUCCESS,
      payload: { id, ...response.data },
    });

    // replace route after delete item
    if (itemType !== ItemType.Package) {
      dispatch(
        notifRequested({
          title: 'SUCCESS',
          content: 'Asset successfully deleted.',
          type: 'success',
        })
      );

      dispatch(replace('/assets'));
    } else {
      dispatch(
        notifRequested({
          title: 'SUCCESS',
          content: 'Package successfully deleted.',
          type: 'success',
        })
      );

      dispatch(replace('/assets/packages'));
    }
  } catch (e) {
    dispatch({ type: DeleteItemActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: 'Error while deleting asset.',
      })
    );
  }
};

// Load access fee
export enum LoadAccessFeeActionTypes {
  START = 'ACCESS_FEE_REQUEST',
  SUCCESS = 'ACCESS_FEE_SUCCESS',
  ERROR = 'ACCESS_FEE_ERROR',
}

export const loadAccessFee = (itemId: number, feeId: number): ApiAction<LoadAccessFeeActionTypes> => async (
  dispatch
) => {
  const endpoint = API.ACCESS_FEE;

  try {
    dispatch({ type: LoadAccessFeeActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        feeId,
        id: itemId,
      },
    });
    dispatch({
      type: LoadAccessFeeActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadAccessFeeActionTypes.ERROR });
  }
};

// Create access fee
export enum CreateAccessFeesActionTypes {
  START = 'CREATE_ACCESS_FEE_REQUEST',
  SUCCESS = 'CREATE_ACCESS_FEE_SUCCESS',
  ERROR = 'CREATE_ACCESS_FEE_ERROR',
}

type CreateAccessFeesTypes = CreateAccessFeesActionTypes | NotificationActionTypes;

interface SetUpFeeProps {
  fee_amount: number;
  description: string;
}

interface TrialPeriodProps {
  quantity: number;
  period: string;
  description: string;
}

export interface CreateAccessFeesProps {
  id: number;
  access_type_id: number;
  setup_fee: SetUpFeeProps;
  trialPeriod: TrialPeriodProps;
  amount: number;
  currency: string;
  description: string;
  expires_at: number;
}

export const createAccessFees = (
  accessFees: CreateAccessFeesProps,
  id: number
): ApiAction<CreateAccessFeesTypes> => async (dispatch) => {
  const endpoint = API.ACCESS_FEES;

  const data = { accessFees };

  try {
    dispatch({ type: CreateAccessFeesActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        id,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: CreateAccessFeesActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Access fees successfully saved.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: CreateAccessFeesActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

type RemoveAccessFeesTypes = RemoveAccessFeeActionTypes | NotificationActionTypes;

// Delete access fee
export enum RemoveAccessFeeActionTypes {
  START = 'REMOVE_ACCESS_FEE_REQUEST',
  SUCCESS = 'REMOVE_ACCESS_FEE_SUCCESS',
  ERROR = 'REMOVE_ACCESS_FEE_ERROR',
}

export const removeAccessFee = (itemId: number, feeId: number): ApiAction<RemoveAccessFeesTypes> => async (
  dispatch
) => {
  const endpoint = API.ACCESS_FEE;
  const successData = { feeId };

  try {
    dispatch({ type: RemoveAccessFeeActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        id: itemId,
        feeId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: RemoveAccessFeeActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Access fee successfully deleted.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: RemoveAccessFeeActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Pull OVP details
export enum PullOvpDetailsActionTypes {
  START = 'PULL_OVP_DETAILS_REQUEST',
  SUCCESS = 'PULL_OVP_DETAILS_SUCCESS',
  ERROR = 'PULL_OVP_DETAILS_ERROR',
}

type PullOvpDetailsTypes = PullOvpDetailsActionTypes | NotificationActionTypes;

interface OvpArguments {
  [key: string]: number | string;
}

interface OvpParams {
  [ovpType: string]: (args: OvpArguments) => OvpArguments;
}

export interface PullOvpParams {
  ovpType: string;
  eventId?: number | string;
  videoId?: number | string;
  playerId?: number | string;
  playerName?: string;
  token?: string;
}

const ovpParams: OvpParams = {
  livestream: ({ eventId, videoId }: OvpArguments) => ({
    event_id: eventId,
    video_id: videoId,
  }),
  jwplayer: ({ videoId }: OvpArguments) => ({ video_key: videoId }),
  ooyala: ({ videoId }: OvpArguments) => ({ embed_code: videoId }),
  piksel: ({ videoId }: OvpArguments) => ({ program_uuid: videoId }),
  brightcove: ({ videoId }: OvpArguments) => ({ video_id: videoId }),
  kaltura: ({ videoId }: OvpArguments) => ({ video_id: videoId }),
  qbrick: ({ videoId, token }: OvpArguments) => ({
    video_id: videoId,
    access_token: token,
  }),
};

export const pullOvpDetails = ({
  ovpType,
  eventId = '',
  videoId = '',
  playerId = '',
  playerName = '',
  token = '',
}: PullOvpParams): ApiAction<PullOvpDetailsTypes> => async (dispatch) => {
  const url = API.PULL_OVP_DETAILS;
  const params = ovpParams[ovpType]({ videoId, eventId, token });

  const successData = { ovpType, player_id: playerId, player_name: playerName };

  try {
    dispatch({ type: PullOvpDetailsActionTypes.START });
    const response = await http.authenticated().get(url, {
      pathVariables: {
        ovpType,
      },
      params,
    });
    dispatch({
      type: PullOvpDetailsActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      } else {
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: 'There was an issue with establishing connection to the Qbrick account. Please try again.',
          })
        );
      }
      dispatch({ type: PullOvpDetailsActionTypes.ERROR });
    }
  }
};

// Check if sync is in progress
export enum CheckSyncProgressActionTypes {
  START = 'SYNC_PROGRESS_REQUEST',
  SUCCESS = 'SYNC_PROGRESS_SUCCESS',
  ERROR = 'SYNC_PROGRESS_ERROR',
}

export const checkSyncProgress = (ovpName: string): ApiAction<CheckSyncProgressActionTypes> => async (dispatch) => {
  const endpoint = API.CHECK_SYNC_PROGRESS;
  const data = { ovp_name: ovpName };

  try {
    dispatch({ type: CheckSyncProgressActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: CheckSyncProgressActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: CheckSyncProgressActionTypes.ERROR });
  }
};

// Load initial external assets
export enum LoadInitialExternalAssetsActionTypes {
  START = 'LOAD_INITIAL_EXERTNAL_ASSETS_REQUEST',
  SUCCESS = 'LOAD_INITIAL_EXTERNAL_ASSETS_SUCCESS',
  ERROR = 'LOAD_INITIAL_EXTERNAL_ASSETS_FAILURE',
}

export interface LoadExternalAssetsParams {
  type: string;
  page?: number;
  limit?: number;
  token?: string;
}

export const loadInitialExternalAssets = ({
  type,
  page: page_number = 0,
  limit = 15,
  token: access_token,
}: LoadExternalAssetsParams): ApiAction<LoadInitialExternalAssetsActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_EXTERNAL_ASSETS;

  const params = {
    page_number,
    limit,
    access_token,
  };

  try {
    dispatch({ type: LoadInitialExternalAssetsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        type,
      },
      params,
    });

    dispatch({
      type: LoadInitialExternalAssetsActionTypes.SUCCESS,
      payload: { ...response.data, type },
    });
  } catch (_) {
    dispatch({ type: LoadInitialExternalAssetsActionTypes.ERROR });
  }
};

// Reset external assets
export const RESET_EXTERNAL_ASSETS = 'RESET_EXTERNAL_ASSETS';

export const resetExternalAssets = (): Action<typeof RESET_EXTERNAL_ASSETS> => ({
  type: RESET_EXTERNAL_ASSETS,
});

// Load jwPlayers
export enum LoadJwPlayersActionTypes {
  START = 'LOAD_JWPLAYERS_REQUEST',
  SUCCESS = 'LOAD_JWPLAYERS_SUCCESS',
  ERROR = 'LOAD_JWPLAYERS_FAILURE',
}

export const loadJWPlayers = (): ApiAction<LoadJwPlayersActionTypes> => async (dispatch) => {
  const endpoint = API.GET_JWPLAYERS;
  try {
    dispatch({ type: LoadJwPlayersActionTypes.START });
    const response = await http.authenticated().get(endpoint);
    dispatch({
      type: LoadJwPlayersActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadJwPlayersActionTypes.ERROR });
  }
};

// Pull brightcove details
export enum PullBrightcoveDetailsActionTypes {
  START = 'PULL_BRIGHTCOVE_DETAILS_REQUEST',
  SUCCESS = 'PULL_BRIGHTCOVE_DETAILS_SUCCESS',
  ERROR = 'PULL_BRIGHTCOVE_DETAILS_FAILURE',
}

export const pullBrightcoveDetails = (
  ovpType: string,
  videoId: number | string
): ApiAction<PullBrightcoveDetailsActionTypes> => async (dispatch) => {
  const url = API.PULL_OVP_DETAILS;

  const successData = { ovpType };

  try {
    dispatch({ type: PullBrightcoveDetailsActionTypes.START });
    const response = await http.authenticated().get(url, {
      pathVariables: {
        ovpType,
      },
      params: {
        video_id: videoId,
      },
    });
    dispatch({
      type: PullBrightcoveDetailsActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
  } catch (_) {
    dispatch({ type: PullBrightcoveDetailsActionTypes.ERROR });
  }
};

// Load access types
export enum LoadItemAccessTypesActionTypes {
  START = 'LOAD_ITEM_ACCESS_TYPES_REQUEST',
  SUCCESS = 'LOAD_ITEM_ACCCESS_TYPES_SUCCESS',
  ERROR = 'LOAD_ITEM_ACCESS_TYPES_FAILURE',
}

enum AccessTypeFlag {
  PPV = 'ppv',
  PPV_CUSTOM = 'ppv_custom',
  SUBSCRIPTION = 'subscription',
  FREMIUM = 'freemium',
  SEASON = 'season',
}

export interface AccessType {
  id: number;
  name: string;
  quantity: number;
  period: string;
}

export interface AccessTypeOption {
  value: string;
  displayName: string;
}

export interface ItemAccessTypes {
  ppv: AccessTypeOption[];
  subscriptions: AccessTypeOption[];
  season: AccessTypeOption[];
}

export const loadItemAccessTypes = (): ApiAction<LoadItemAccessTypesActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_ACCESS_TYPES;

  try {
    dispatch({ type: LoadItemAccessTypesActionTypes.START });
    const { data } = await http.authenticated().get(endpoint);

    const payload: ItemAccessTypes = {
      ppv: [],
      subscriptions: [],
      season: [],
    };

    const accessTypeOptionBuilder = (accType: AccessType) => {
      return {
        value: accType.id.toString(),
        displayName:
          accType.name === AccessTypeFlag.PPV_CUSTOM ? accType.period : `${accType.quantity} ${accType.period}`,
        tag: accType.name === AccessTypeFlag.PPV_CUSTOM ? accType.period : `${accType.quantity} ${accType.period}`,
      };
    };

    const accessTypeOptionMapper = (accType: AccessType) => {
      if (accType.name === AccessTypeFlag.SUBSCRIPTION) {
        payload.subscriptions = [...payload.subscriptions, accessTypeOptionBuilder(accType)];
      }
      if (accType.name === AccessTypeFlag.SEASON) {
        payload.season = [...payload.season, accessTypeOptionBuilder(accType)];
      }
      if (accType.name === AccessTypeFlag.PPV || accType.name === AccessTypeFlag.PPV_CUSTOM) {
        payload.ppv = [...payload.ppv, accessTypeOptionBuilder(accType)];
      }
    };

    data
      .filter((accType: AccessType) => accType.name !== AccessTypeFlag.FREMIUM)
      .map((accType: AccessType) => accessTypeOptionMapper(accType));

    dispatch({ type: LoadItemAccessTypesActionTypes.SUCCESS, payload });
  } catch (_) {
    dispatch({ type: LoadItemAccessTypesActionTypes.ERROR });
  }
};

// Update asset content
export enum UpdateAssetContentActionTypes {
  START = 'UPDATE_ASSET_CONTENT_REQUEST',
  SUCCESS = 'UPDATE_ASSET_CONTENT_SUCCESS',
  ERROR = 'UPDATE_ASSET_CONTENT_ERROR',
}

type UpdateContentTypes = UpdateAssetContentActionTypes | NotificationActionTypes;

export const updateAssetContent = (
  content: string,
  externalAssetId?: string,
  streamURL?: string,
  source?: any,
  ovpMetadata?: OvpMetadata
): ApiAction<UpdateContentTypes> => async (dispatch, getState) => {
  const endpoint = API.UPDATE_ITEM;

  const {
    items: {
      itemDetails: { id: assetId },
    },
  } = getState();

  const { title = '', image = '', description = '' } = ovpMetadata || {};

  const data = { id: assetId, content };

  if (source) {
    Object.assign(data, { source });
  }

  if (externalAssetId) {
    Object.assign(data, { external_asset_id: externalAssetId });
  }

  if (streamURL) {
    Object.assign(data, { streamURL });
  }

  try {
    dispatch({ type: UpdateAssetContentActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        id: assetId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateAssetContentActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Asset successfully updated.',
        type: 'success',
      })
    );
    // This updates the asset details for ovps with video selection
    if (title) {
      dispatch(updateItemTitle(title, assetId, ItemType.Asset));
      const newMetadata: MetadataType[] = [
        { key: 'paywall_cover_photo', value: image },
        { key: 'preview_title', value: title },
        { key: 'thumbnail_image', value: image },
      ];
      if (description) {
        newMetadata.push({ key: 'preview_description', value: description });
      }
      dispatch(
        updateItemMetadata({
          metadata: newMetadata,
          isCustomMetadata: false,
          itemType: ItemType.Asset,
        })
      );
    }
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateAssetContentActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Load external assets
export enum LoadExternalAssetsActionTypes {
  START = 'LOAD_EXTERNAL_ASSETS_REQUEST',
  SUCCESS = 'LOAD_EXTERNAL_ASSETS_SUCCESS',
  ERROR = 'LOAD_EXTERNAL_ASSETS_FAILURE',
}

type LoadExternalAssetsTypes = LoadExternalAssetsActionTypes | NotificationActionTypes;

export const loadExternalAssets = ({
  type,
  token: access_token,
  page: page_number = 0,
  limit = 15,
}: LoadExternalAssetsParams): ApiAction<LoadExternalAssetsTypes> => async (dispatch) => {
  const endpoint = API.FETCH_EXTERNAL_ASSETS;

  const params = {
    page_number,
    limit,
    access_token,
  };

  try {
    dispatch({ type: LoadExternalAssetsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        type,
      },
      params,
    });
    dispatch({
      type: LoadExternalAssetsActionTypes.SUCCESS,
      payload: { ...response.data, type },
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      let payload: any = {
        type,
      };
      if (response) {
        payload = {
          ...payload,
          ...response.data,
        };
      }
      dispatch({ type: LoadExternalAssetsActionTypes.ERROR, payload });
      dispatch(
        notifRequested({
          type: 'danger',
          title: 'ERROR',
          content: `Please check your linked ${type} account.`,
        })
      );
    }
  }
};

// Load session chooser
export enum LoadSessionChooserActionTypes {
  START = 'LOAD_SESSION_CHOOSER_REQUEST',
  SUCCESS = 'LOAD_SESSION_CHOOSER_SUCCESS',
  ERROR = 'LOAD_SESSION_CHOOSER_FAILURE',
}

export const loadSessionChooser = (): ApiAction<LoadSessionChooserActionTypes> => async (dispatch) => {
  const endpoint = API.SESSION_CHOOSER;

  try {
    dispatch({ type: LoadSessionChooserActionTypes.START });
    const response = await http.authenticated().get(endpoint);
    dispatch({
      type: LoadSessionChooserActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadSessionChooserActionTypes.ERROR });
  }
};

// Upload file
export enum UploadFileActionTypes {
  START = 'UPLOAD_FILE_REQUEST',
  SUCCESS = 'UPLOAD_FILE_SUCCESS',
  ERROR = 'UPLOAD_FILE_FAILURE',
}

export interface UploadFileArgs {
  asset: any;
  assetId: number;
  filename: string;
  thumbnail: boolean;
  assetFilename?: string;
  assetUrl?: string;
  assetFilesize?: string;
}

type UploadFileTypes = UploadFileActionTypes | NotificationActionTypes;

export const uploadFile = ({
  asset,
  assetId,
  filename,
  thumbnail,
  assetFilename = '',
  assetUrl = '',
  assetFilesize = '',
}: UploadFileArgs): ApiAction<UploadFileTypes> => async (dispatch) => {
  const endpoint = API.UPLOAD_FILE;

  const data = new FormData();
  data.append('asset', asset);
  data.append('assetId', assetId.toString());
  data.append('filename', filename);
  if (thumbnail) {
    data.append('isThumb', thumbnail.toString());
  }

  const successData = {
    thumbnail,
    asset_filename: assetFilename,
    asset_url: assetUrl,
    asset_filesize: assetFilesize,
  };

  try {
    dispatch({ type: UploadFileActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.FORM_DATA,
    });
    dispatch({
      type: UploadFileActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
  } catch (e) {
    dispatch({ type: UploadFileActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: e.response.data.message,
      })
    );
  }
};

// Load all assets
export enum LoadAssetsActionTypes {
  START = 'LOAD_ASSETS_REQUEST',
  SUCCESS = 'LOAD_ASSETS_SUCCESS',
  ERROR = 'LOAD_ASSETS_FAILURE',
}

export interface LoadAssetsParams {
  page: number;
  isActive: boolean;
  limit?: number;
  meta?: any;
  search?: any[];
  isInAppVoucher?: boolean;
}

export const loadAssets = ({
  page,
  isActive,
  limit = 15,
  meta = {},
  search = [],
  isInAppVoucher,
}: LoadAssetsParams): ApiAction<LoadAssetsActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_ASSETS;
  const successData = { meta, search, concat: isInAppVoucher };

  try {
    dispatch({ type: LoadAssetsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      params: {
        page,
        limit,
        is_active: isActive,
        search,
      },
    });
    dispatch({
      type: LoadAssetsActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
  } catch (_) {
    dispatch({ type: LoadAssetsActionTypes.ERROR });
  }
};

// Fetch all videos from ovp
export enum SyncOvpVideosActionTypes {
  START = 'SYNC_OVP_VIDEOS_REQUEST',
  SUCCESS = 'SYNC_OVP_VIDEOS_SUCCESS',
  ERROR = 'SYNC_OVP_VIDEOS_FAILURE',
}

export interface SyncOvpVideosArgs {
  ovpName: string;
  videosIds: Array<string>;
  syncAll?: boolean;
  isFromAssetDetails?: boolean;
  isUpdate?: boolean;
  accessControlType?: number;
  playerId?: string;
  playerIdUpdateExisting?: boolean;
}

interface DataAttributes {
  ovp_name: string;
  sync_all: boolean;
  videos_ids: Array<string>;
  access_control_type_id?: number;
  player_id?: string;
  player_id_update_existing?: boolean;
}

type SyncOvpVideosTypes = SyncOvpVideosActionTypes | NotificationActionTypes;

export const syncOvpVideos = ({
  ovpName,
  videosIds,
  syncAll = false,
  isFromAssetDetails = false,
  isUpdate,
  accessControlType,
  playerId,
  playerIdUpdateExisting,
}: SyncOvpVideosArgs): RouterApiAction<SyncOvpVideosTypes> => async (dispatch) => {
  const endpoint = API.SYNC_OVP_VIDEOS;
  const data: DataAttributes = {
    ovp_name: ovpName,
    sync_all: syncAll,
    videos_ids: videosIds,
    access_control_type_id: accessControlType,
  };

  // In case of chosen jw player asset with selected player id
  if (playerId) {
    data.player_id = playerId;
    data.player_id_update_existing = playerIdUpdateExisting;
  }

  try {
    dispatch({ type: SyncOvpVideosActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: SyncOvpVideosActionTypes.SUCCESS,
      payload: { ...response.data, isFromAssetDetails },
    });
    dispatch(checkSyncProgress(ovpName));

    // if sync is dispatched on new asset, not in edit asset view to replace the route
    if (!isUpdate) {
      dispatch(replace('/assets'));
      dispatch(loadAssets({ page: 1, isActive: true }));
    }
  } catch (error) {
    dispatch({ type: SyncOvpVideosActionTypes.ERROR });
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Reactivate asset
export enum ReactivateAssetActionTypes {
  START = 'REACTIVATE_ASSET_REQUEST',
  SUCCESS = 'REACTIVATE_ASSET_SUCCESS',
  ERROR = 'REACTIVATE_ASSET_FAILURE',
}

type ReactivateAssetTypes = ReactivateAssetActionTypes | NotificationActionTypes;

export const reactivateAsset = (id: number): ApiAction<ReactivateAssetTypes> => async (dispatch) => {
  const endpoint = API.REACTIVATE_ASSET;

  try {
    dispatch({ type: ReactivateAssetActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        id,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: ReactivateAssetActionTypes.SUCCESS,
      payload: { id, ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Asset successfully reactivated.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response && response.data) {
        dispatch({
          type: ReactivateAssetActionTypes.ERROR,
          payload: { ...response.data },
        });
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
        return;
      }
    }
    dispatch({ type: ReactivateAssetActionTypes.ERROR });
    dispatch(
      notifRequested({
        title: 'ERROR',
        type: 'danger',
        content: 'An unknown error occurred.',
      })
    );
  }
};

// Save selected fee id to store
export const SAVE_SELECTED_FEE_ID = 'SAVE_SELECTED_FEE_ID';

interface SaveSelectedFeeIdAction extends Action<typeof SAVE_SELECTED_FEE_ID> {
  id: number;
}

export const saveSelectedFeeId = (id: number): SaveSelectedFeeIdAction => ({
  type: SAVE_SELECTED_FEE_ID,
  id,
});

// Create access fees
export enum CreateItemAccessFeeActionTypes {
  START = 'CREATE_ITEM_ACCESS_FEE_REQUEST',
  SUCCESS = 'CREATE_ITEM_ACCESS_FEE_SUCCESS',
  ERROR = 'CREATE_ITEM_ACCESS_FEE_ERROR',
}

type CreateItemAccessFeesTypes = CreateItemAccessFeeActionTypes | NotificationActionTypes;

export type ItemExternalFee = Omit<ExternalFee, 'id'>;
export interface ItemAccessFee {
  accessTypeId: number | string;
  amount: number;
  currency: string;
  accessFeeDescription: string;
  freeTrialDays?: number;
  country?: string;
  countrySet?: string;
  additionalFeeAmount?: number;
  additionalFeeDescription?: string;
  restrictionType?: string;
  startsAt?: Moment;
  expiresAt?: Moment;
  itemId: number;
  itemType: string;
  externalFees?: Array<ItemExternalFee>;
  seasonAnchorDate?: Moment;
  seasonPriceAmount?: number;
  offSeasonAccess?: boolean;
  isSeasonAccessFee: boolean;
}

export const createItemAccessFee = ({
  accessTypeId,
  amount,
  currency,
  accessFeeDescription,
  freeTrialDays,
  country,
  countrySet,
  additionalFeeAmount,
  additionalFeeDescription,
  restrictionType,
  startsAt,
  expiresAt,
  itemId,
  itemType,
  externalFees,
  seasonAnchorDate,
  seasonPriceAmount,
  offSeasonAccess,
  isSeasonAccessFee,
}: ItemAccessFee): ApiAction<CreateItemAccessFeesTypes | typeof SAVE_SELECTED_FEE_ID> => async (dispatch) => {
  const endpoint = API.ACCESS_FEES;
  const successMessageText = setItemType(itemType);

  const data = {
    access_type_id: accessTypeId,
    description: accessFeeDescription,
    amount,
    currency,
    restriction_type: restrictionType,
    trial_period_quantity: freeTrialDays,
    trial_period_period: freeTrialDays && 'day',
    setup_fee_amount: additionalFeeAmount,
    setup_fee_description: additionalFeeDescription,
    country_iso: country,
    country_set_id: countrySet,
    starts_at: startsAt ? startsAt.toISOString() : startsAt,
    expires_at: expiresAt ? expiresAt.toISOString() : expiresAt,
    season_anchor_date: seasonAnchorDate ? seasonAnchorDate.toISOString() : seasonAnchorDate,
    season_price_amount: seasonPriceAmount,
    season_off_season_access: offSeasonAccess,
  };

  // external fees need to be in format external_fees[provider_id] = value
  externalFees?.forEach((fee) => {
    data[`external_fees[${fee.payment_provider_id}]`] = fee.external_id;
  });

  try {
    dispatch({ type: CreateItemAccessFeeActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      pathVariables: {
        id: itemId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: CreateItemAccessFeeActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `${upperFirst(successMessageText)} successfully updated.`,
        type: 'success',
      })
    );
    if (isSeasonAccessFee) {
      dispatch(saveSelectedFeeId(response.data.id));
    }
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({
          type: CreateItemAccessFeeActionTypes.ERROR,
        });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );

        return response.data.message;
      }
    }
  }
};

// Update access fees
export enum UpdateItemAccessFeeActionTypes {
  START = 'UPDATE_ITEM_ACCESS_FEE_REQUEST',
  SUCCESS = 'UPDATE_ITEM_ACCESS_FEE_SUCCESS',
  ERROR = 'UPDATE_ITEM_ACCESS_FEE_ERROR',
}

type UpdateAssetAccessFeeTypes = UpdateItemAccessFeeActionTypes | NotificationActionTypes;

export const updateItemAccessFee = (
  feeId: number,
  {
    accessTypeId,
    amount,
    currency,
    accessFeeDescription,
    freeTrialDays,
    country,
    countrySet,
    additionalFeeAmount,
    additionalFeeDescription,
    restrictionType,
    startsAt,
    expiresAt,
    itemId,
    itemType,
    externalFees,
    seasonAnchorDate,
    seasonPriceAmount,
    offSeasonAccess,
  }: ItemAccessFee
): ApiAction<UpdateAssetAccessFeeTypes> => async (dispatch) => {
  const endpoint = API.ACCESS_FEE;
  const successMessageText = setItemType(itemType);

  const data = {
    access_type_id: accessTypeId,
    description: accessFeeDescription,
    amount,
    currency,
    restriction_type: restrictionType,
    trial_period_quantity: freeTrialDays,
    trial_period_period: freeTrialDays && 'day',
    setup_fee_amount: additionalFeeAmount,
    setup_fee_description: additionalFeeDescription,
    country_iso: country,
    country_set_id: countrySet,
    starts_at: startsAt ? startsAt.toISOString() : startsAt,
    expires_at: expiresAt ? expiresAt.toISOString() : expiresAt,
    season_anchor_date: seasonAnchorDate ? seasonAnchorDate.toISOString() : seasonAnchorDate,
    season_current_price_amount: seasonPriceAmount,
    season_off_season_access: offSeasonAccess,
  };

  externalFees?.forEach((fee) => {
    data[`external_fees[${fee.payment_provider_id}]`] = fee.external_id;
  });

  try {
    dispatch({ type: UpdateItemAccessFeeActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        id: itemId,
        feeId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateItemAccessFeeActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `${upperFirst(successMessageText)} successfully updated.`,
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateItemAccessFeeActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );

        return response.data.message;
      }
    }
  }
};

export const RESET_ASSET_DETAILS = 'RESET_ASSET_DETAILS';

export const resetAssetDetails = (): Action<typeof RESET_ASSET_DETAILS> => ({
  type: RESET_ASSET_DETAILS,
});

// Load asset details after synced is finished
export const SYNC_PROGRESS_DONE = 'SYNC_PROGRESS_DONE';

type SyncProgressNotificationAction = Action<typeof SYNC_PROGRESS_DONE | LoadItemDetailsActionTypes>;

export const syncProgressNotification = (): ThunkAction<void, RootState, void, SyncProgressNotificationAction> => (
  dispatch,
  getState
) => {
  dispatch({ type: SYNC_PROGRESS_DONE });

  const {
    fromAssetDetails,
    itemDetails: { id, merchant_uuid },
  } = getState().items;

  if (fromAssetDetails) {
    dispatch(loadItemDetails(merchant_uuid, id));
  }
};

// Get panopto content parameters from notification
export const SET_PANOPTO_CONTENT = 'SET_PANOPTO_CONTENT';

interface SetPanoptoContentAction extends Action<typeof SET_PANOPTO_CONTENT> {
  content: any;
}

export const setPanoptoContent = (content: any): SetPanoptoContentAction => {
  return { type: SET_PANOPTO_CONTENT, content };
};

// Clear panopto content from state
export const CLEAR_PANOPTO_CONTENT = 'CLEAR_PANOPTO_CONTENT';

type ClearPanoptoContentAction = Action<typeof CLEAR_PANOPTO_CONTENT>;

export const clearPanoptoContent = (): ClearPanoptoContentAction => {
  return { type: CLEAR_PANOPTO_CONTENT };
};

// Load external assets - brightcove sync
export enum LoadExternalBrightcoveAssetsActionTypes {
  START = 'BRIGHTCOVE_EXTERNAL_ASSETS_REQUEST',
  SUCCESS = 'BRIGHTCOVE_EXTERNAL_ASSETS_SUCCESS',
  ERROR = 'BRIGHTCOVE_EXTERNAL_ASSETS_FAILURE',
}

export const loadBrightcoveExternalAssets = ({
  type,
  token: access_token,
  page: page_number = 0,
  limit = 15,
}: LoadExternalAssetsParams): ApiAction<LoadExternalBrightcoveAssetsActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_EXTERNAL_ASSETS;

  const params = {
    page_number,
    limit,
    access_token,
  };

  try {
    dispatch({ type: LoadExternalBrightcoveAssetsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        type,
      },
      params,
    });
    dispatch({
      type: LoadExternalBrightcoveAssetsActionTypes.SUCCESS,
      payload: { ...response.data, type },
    });
  } catch (_) {
    dispatch({ type: LoadExternalBrightcoveAssetsActionTypes.ERROR });
  }
};

// Update item template id
export enum UpdateItemTemplateIdActionTypes {
  START = 'UPDATE_ITEM_TEMPLATE_ID_REQUEST',
  SUCCESS = 'UPDATE_ITEM_TEMPLATE_ID_SUCCESS',
  ERROR = 'UPDATE_ITEM_TEMPLATE_ID_ERROR',
}

type UpdateItemTemplateIdTypes = UpdateItemTemplateIdActionTypes | NotificationActionTypes;

export interface ItemTemplateIdProps {
  templateId: number | null;
  itemType: string;
  isTemplate: boolean;
}

export const updateItemTemplateId = ({
  templateId,
  itemType,
  isTemplate,
}: ItemTemplateIdProps): ApiAction<UpdateItemTemplateIdTypes> => async (dispatch, getState) => {
  const endpoint = isTemplate ? API.UPDATE_TEMPLATE_PREVIEW_TEMPLATE : API.UPDATE_ITEM;
  const successMessageText = setItemType(itemType);

  const {
    items: {
      itemDetails: { id: assetId },
      templates: {
        templateDetails: { id },
      },
    },
  } = getState();

  let data: any = {
    id: assetId,
    // If template id is null then it means that use default branding preview
    // template option is selected.
    // In that case 0 should be sent as template id to BE
    template_id: templateId || 0,
  };

  let pathVariables: any = {
    id: assetId,
  };

  if (isTemplate) {
    data = {
      preview_id: templateId || 0,
    };

    pathVariables = {
      template_id: id,
    };
  }

  try {
    dispatch({ type: UpdateItemTemplateIdActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables,
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateItemTemplateIdActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `${upperFirst(isTemplate ? 'Template' : successMessageText)} successfully updated.`,
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateItemTemplateIdActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Code only
export enum LoadCodesActionTypes {
  START = 'LOAD_CODES_REQUEST',
  SUCCESS = 'LOAD_CODES_SUCCESS',
  ERROR = 'LOAD_CODES_ERROR',
}

export interface LoadCodesParams {
  itemId: number;
  page: number;
  limit?: number;
  code?: string;
}

export const loadCodes = ({
  itemId,
  page,
  code,
  limit = 10,
}: LoadCodesParams): ApiAction<LoadCodesActionTypes> => async (dispatch) => {
  const params: { page: number; limit: number; code?: string } = {
    page,
    limit,
  };

  if (code) {
    params.code = code;
  }

  const endpoint = API.LOAD_CODES;

  try {
    dispatch({ type: LoadCodesActionTypes.START });

    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        itemId,
      },
      params,
    });
    dispatch({ type: LoadCodesActionTypes.SUCCESS, payload: { ...response.data } });
  } catch {
    dispatch({ type: LoadCodesActionTypes.ERROR });
  }
};

export enum SaveCodeActionTypes {
  START = 'SAVE_CODE_REQUEST',
  SUCCESS = 'SAVE_CODE_SUCCESS',
  ERROR = 'SAVE_CODE_ERROR',
}

type SaveCodeTypes = SaveCodeActionTypes | NotificationActionTypes;

interface SaveCodeProps {
  itemId: number;
  code: string;
  type: string;
  startDate: Optional<Moment>;
  endDate: Optional<Moment>;
  concurrentSessions: number | string;
}

export const saveCode = ({
  itemId,
  code,
  type,
  startDate,
  endDate,
  concurrentSessions,
}: SaveCodeProps): RouterApiAction<SaveCodeTypes> => async (dispatch) => {
  const endpoint = API.SAVE_CODE;
  const data = {
    item_id: itemId,
    code,
    start_date: startDate && startDate.format('YYYY-MM-DD HH:mm:ss'),
    end_date: endDate && endDate.format('YYYY-MM-DD HH:mm:ss'),
    type,
    concurrent_sessions: concurrentSessions,
  };

  try {
    dispatch({ type: SaveCodeActionTypes.START });

    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });

    dispatch({ type: SaveCodeActionTypes.SUCCESS, payload: { ...response.data } });

    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Passcode successfully created.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;

      if (response) {
        dispatch({ type: SaveCodeActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

export enum UpdateCodeActionTypes {
  START = 'UPDATE_CODE_REQUEST',
  SUCCESS = 'UPDATE_CODE_SUCCESS',
  ERROR = 'UPDATE_CODE_ERROR',
}

type UpdateCodeTypes = UpdateCodeActionTypes | NotificationActionTypes;

interface UpdateCodeProps {
  id: number;
  code: string;
  type: string;
  startDate: Optional<Moment>;
  endDate: Optional<Moment>;
  concurrentSessions: number | string;
}

export const updateCode = ({
  id,
  type,
  startDate,
  endDate,
  concurrentSessions,
}: UpdateCodeProps): RouterApiAction<UpdateCodeTypes> => async (dispatch) => {
  const endpoint = API.UPDATE_CODE;
  const data = {
    start_date: startDate && startDate.format('YYYY-MM-DD HH:mm:ss'),
    end_date: endDate && endDate.format('YYYY-MM-DD HH:mm:ss'),
    type,
    concurrent_sessions: concurrentSessions,
  };

  try {
    dispatch({ type: UpdateCodeActionTypes.START });

    const response = await http.authenticated().patch(endpoint, {
      pathVariables: { codeId: id },
      data,
      contentType: ContentType.URLENCODED,
    });

    dispatch({ type: UpdateCodeActionTypes.SUCCESS, payload: { ...response.data } });

    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Passcode successfully changed.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;

      if (response) {
        dispatch({ type: UpdateCodeActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

export enum UpdateAllCodeActionTypes {
  START = 'UPDATE_CODES_REQUEST',
  SUCCESS = 'UPDATE_CODES_SUCCESS',
  ERROR = 'UPDATE_CODES_ERROR',
}

type UpdateAllCodeTypes = UpdateAllCodeActionTypes | NotificationActionTypes;

export interface UpdateAllCodeProps {
  itemId: number;
  type: string;
  startDate: Optional<Moment>;
  endDate: Optional<Moment>;
  concurrentSessions: number | string;
}

export const updateAllCodes = ({
  itemId,
  type,
  startDate,
  endDate,
  concurrentSessions,
}: UpdateAllCodeProps): RouterApiAction<UpdateAllCodeTypes> => async (dispatch) => {
  const endpoint = API.UPDATE_ALL_CODES;
  let data = {};
  if (type !== '') {
    data = {
      ...data,
      type,
    };
  }
  if (startDate) {
    data = {
      ...data,
      start_date: startDate.format('YYYY-MM-DD HH:mm:ss'),
    };
  }
  if (endDate) {
    data = {
      ...data,
      end_date: endDate.format('YYYY-MM-DD HH:mm:ss'),
    };
  }
  if (concurrentSessions !== '') {
    data = {
      ...data,
      concurrent_sessions: concurrentSessions,
    };
  }

  try {
    dispatch({ type: UpdateAllCodeActionTypes.START });

    const response = await http.authenticated().patch(endpoint, {
      pathVariables: { itemId },
      data,
      contentType: ContentType.URLENCODED,
    });

    dispatch({ type: UpdateAllCodeActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(loadCodes({ itemId, page: 0 }));
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Passcodes successfully changed.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;

      if (response) {
        dispatch({ type: UpdateAllCodeActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

export enum LoadCodeSessionsActionTypes {
  START = 'LOAD_CODE_SESSIONS_REQUEST',
  SUCCESS = 'LOAD_CODE_SESSIONS_SUCCESS',
  ERROR = 'LOAD_CODE_SESSIONS_ERROR',
}

export const loadCodeSessions = (codeId: number): ApiAction<LoadCodeSessionsActionTypes> => async (dispatch) => {
  const endpoint = API.LOAD_CODE_SESSIONS;

  try {
    dispatch({ type: LoadCodeSessionsActionTypes.START });

    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        codeId,
      },
    });
    dispatch({ type: LoadCodeSessionsActionTypes.SUCCESS, payload: { ...response.data } });
  } catch {
    dispatch({ type: LoadCodeSessionsActionTypes.ERROR });
  }
};

export enum TerminateCodeSessionActionTypes {
  START = 'TERMINATE_CODE_SESSION_REQUEST',
  SUCCESS = 'TERMINATE_CODE_SESSION_SUCCESS',
  ERROR = 'TERMINATE_CODE_SESSION_ERROR',
}

export const terminateCodeSession = (
  codeId: number,
  browserFingerprint: string
): ApiAction<TerminateCodeSessionActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.TERMINATE_CODE_SESSION;
  const successData = { browserFingerprint };

  try {
    dispatch({ type: TerminateCodeSessionActionTypes.START });

    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        codeId,
        browserFingerprint,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: TerminateCodeSessionActionTypes.SUCCESS, payload: { ...response.data, ...successData } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Session successfully terminated.',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: TerminateCodeSessionActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Get bulk codes template
export enum CodesTemplateActionTypes {
  START = 'CODE_TEMPLATE_REQUEST',
  SUCCESS = 'CODE_TEMPLATE_SUCCESS',
  ERROR = 'CODE_TEMPLATE_ERROR',
}

export const getBulkCodeTemplate = (): ApiAction<CodesTemplateActionTypes> => async (dispatch) => {
  const endpoint = API.GET_CODE_TEMPLATE;
  const fileName = 'access-codes-example.csv';
  const template = 'access-codes';

  try {
    dispatch({ type: CodesTemplateActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        template,
      },
    });

    const { download_url: url } = response.data;

    downloadFile(url, fileName);

    dispatch({ type: CodesTemplateActionTypes.SUCCESS });
  } catch (_) {
    dispatch({ type: CodesTemplateActionTypes.ERROR });
  }
};

// Upload codes csv file
export enum BulkCodeActionTypes {
  START = 'BULK_CODE_REQUEST',
  UPLOADING = 'BULK_CODE_UPLOADING',
  SUCCESS = 'BULK_CODE_SUCCESS',
  ERROR = 'BULK_CODE_ERROR',
}

export const uploadCodeFile = (file: File, itemId: number): ApiAction<BulkCodeActionTypes> => async (dispatch) => {
  try {
    dispatch({ type: BulkCodeActionTypes.START });
    const pathVariables = { item_id: itemId };

    const response = await getSignedUrl(API.GET_CODE_SIGNED_URL, pathVariables);

    const { url: signedUrl, file_name: fileName, headers } = response;
    const endpoint = API.BULK_CODES;

    await uploadFileBulk(signedUrl, file, headers);
    await postFileBulk({ fileName, endpoint, pathVariables });

    dispatch({ type: BulkCodeActionTypes.UPLOADING });
  } catch (_) {
    dispatch({ type: BulkCodeActionTypes.ERROR });
    return true;
  }
};

// Load error files
export enum LoadErrorFilesActionTypes {
  START = 'LOAD_ERROR_FILES_REQUEST',
  SUCCESS = 'LOAD_ERROR_FILES_SUCCESS',
  ERROR = 'LOAD_ERROR_FILES_ERROR',
}

export const getBulkErrorFiles = (): ApiAction<LoadErrorFilesActionTypes> => async (dispatch) => {
  const endpoint = API.GET_BULK_ERROR_FILES;

  try {
    dispatch({ type: LoadErrorFilesActionTypes.START });
    const response = await http.authenticated().get(endpoint);

    const mappedResponse = mapDataWithNewKey(response.data, 'created_at', 'createdAt');

    dispatch({ type: LoadErrorFilesActionTypes.SUCCESS, payload: mappedResponse });
  } catch (_) {
    dispatch({ type: LoadErrorFilesActionTypes.ERROR });
  }
};

// Load codes reports
export enum LoadCodesReportsTypes {
  START = 'LOAD_CODES_REPORTS_REQUEST',
  SUCCESS = 'LOAD_CODES_REPORTS_SUCCESS',
  ERROR = 'LOAD_CODES_REPORTS_ERROR',
}

export const loadCodesReports = (itemId: number): ApiAction<LoadCodesReportsTypes> => async (dispatch) => {
  const endpoint = API.CODES_REPORTS;
  const reportType = 'access-codes';

  try {
    dispatch({ type: LoadCodesReportsTypes.START });

    const response = await http.authenticated().get(endpoint, { pathVariables: { itemId, reportType } });
    dispatch({ type: LoadCodesReportsTypes.SUCCESS, payload: response.data });
  } catch (_) {
    dispatch({ type: LoadCodesReportsTypes.ERROR });
  }
};

// Generate codes report
export enum GenerateCodesReportTypes {
  START = 'GENERATE_CODES_REPORT_REQUEST',
  SUCCESS = 'GENERATE_CODES_REPORT_SUCCESS',
  ERROR = 'GENERATE_CODES_REPORT_ERROR',
}

export const generateCodesReport = (
  itemId: number
): ApiAction<GenerateCodesReportTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.GENERATE_CODES_REPORT;

  try {
    dispatch({ type: GenerateCodesReportTypes.START });

    const response = await http
      .authenticated()
      .post(endpoint, { data: { item_id: itemId }, contentType: ContentType.URLENCODED });
    dispatch({ type: GenerateCodesReportTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: response.data.message,
      })
    );
    dispatch(loadCodesReports(itemId));
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: GenerateCodesReportTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Download code report
export enum DownloadCodeReportTypes {
  START = 'DOWNLOAD_CODE_REPORT_REQUEST',
  SUCCESS = 'DOWNLOAD_CODE_REPORT_SUCCESS',
  ERROR = 'DOWNLOAD_CODE_REPORT_ERROR',
}

export const downloadReport = (
  filename: string,
  reportType: string,
  isMissingTransaction?: boolean,
  merchantId?: number
): ApiAction<DownloadCodeReportTypes> => async (dispatch) => {
  const endpoint = isMissingTransaction ? API.DOWNLOAD_MISSING_TRANSACTION(filename, reportType) : API.DOWNLOAD_REPORT;
  const config = {
    pathVariables: {
      filename,
      ...(!isMissingTransaction && {
        reportType,
      }),
    },

    ...(!isMissingTransaction && {
      ...(merchantId && {
        params: {
          merchant_id: merchantId,
        },
      }),
    }),
  };

  try {
    dispatch({ type: DownloadCodeReportTypes.START });

    const response = await http.authenticated().get(endpoint, config);
    const { redirect_url: url, url: missingUrl } = response.data;
    downloadFile(isMissingTransaction ? missingUrl : url, filename);
    dispatch({ type: DownloadCodeReportTypes.SUCCESS });
  } catch (_) {
    dispatch({ type: DownloadCodeReportTypes.ERROR });
  }
};
// End - code only

export const SET_SELECTED_SECTION_INDEX = 'SET_SELECTED_SECTION_INDEX';

interface SetSelectedSectionIndexAction extends Action<typeof SET_SELECTED_SECTION_INDEX> {
  payload: number;
}

export const setSelectedSectionIndex = (value: number): SetSelectedSectionIndexAction => ({
  type: SET_SELECTED_SECTION_INDEX,
  payload: value,
});

export const SET_SHOULD_CLOSE_SECTION = 'SET_SHOULD_CLOSE_SECTION';

interface SetShouldCloseSectionAction extends Action<typeof SET_SHOULD_CLOSE_SECTION> {
  payload: boolean;
}

export const setShouldCloseSection = (value: boolean): SetShouldCloseSectionAction => ({
  type: SET_SHOULD_CLOSE_SECTION,
  payload: value,
});

export const SET_SHOULD_CLOSE_PANEL = 'SET_SHOULD_CLOSE_PANEL';

interface SetShouldClosePanelAction extends Action<typeof SET_SHOULD_CLOSE_PANEL> {
  payload: boolean;
}

export const setShouldClosePanel = (value: boolean): SetShouldClosePanelAction => ({
  type: SET_SHOULD_CLOSE_PANEL,
  payload: value,
});

export enum CreateSeasonPhaseActionTypes {
  START = 'CREATE_SEASON_PHASE_REQUEST',
  SUCCESS = 'CREATE_SEASON_PHASE_SUCCESS',
  ERROR = 'CREATE_SEASON_PHASE_ERROR',
}

type CreateSeasonPhaseTypes = CreateSeasonPhaseActionTypes | NotificationActionTypes;

export const createSeasonPhase = (
  itemId: number,
  feeId: number,
  { startsAt, expiresAt, anchorDate, currentPrice, seasonPrice, currency }: SeasonPhaseType
): ApiAction<CreateSeasonPhaseTypes> => async (dispatch) => {
  const endpoint = API.SEASON_PHASES;
  const data = {
    season_price: seasonPrice,
    currency,
    anchor_date: anchorDate.toISOString(),
    starts_at: startsAt.toISOString(),
    expires_at: expiresAt.toISOString(),
    current_price: currentPrice,
  };

  try {
    dispatch({ type: CreateSeasonPhaseActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      pathVariables: {
        id: itemId,
        fee_id: feeId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });

    dispatch({
      type: CreateSeasonPhaseActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `Season phase successfully created.`,
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: CreateSeasonPhaseActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );

        return response.data.message;
      }
    }
  }
};

export enum UpdateSeasonPhaseActionTypes {
  START = 'UPDATE_SEASON_PHASE_REQUEST',
  SUCCESS = 'UPDATE_SEASON_PHASE_SUCCESS',
  ERROR = 'UPDATE_SEASON_PHASE_ERROR',
}

type UpdateSeasonPhaseTypes = UpdateSeasonPhaseActionTypes | NotificationActionTypes;

export const updateSeasonPhase = (
  itemId: number,
  feeId: number,
  { startsAt, expiresAt, anchorDate, currentPrice, seasonPrice, currency, id }: SeasonPhaseType
): ApiAction<UpdateSeasonPhaseTypes> => async (dispatch) => {
  const endpoint = API.SEASON_PHASE;

  const data = {
    season_price: seasonPrice,
    currency,
    anchor_date: anchorDate.toISOString(),
    starts_at: startsAt.toISOString(),
    expires_at: expiresAt.toISOString(),
    current_price: currentPrice,
  };
  try {
    dispatch({ type: UpdateSeasonPhaseActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        id: itemId,
        fee_id: feeId,
        phase_id: id,
      },
      data,
      contentType: ContentType.URLENCODED,
    });

    dispatch({
      type: UpdateSeasonPhaseActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `Season phase successfully updated.`,
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateSeasonPhaseActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );

        return response.data.message;
      }
    }
  }
};

export enum GetSeasonPhasesActionTypes {
  START = 'GET_SEASON_PHASE_REQUEST',
  SUCCESS = 'GET_SEASON_PHASE_SUCCESS',
  ERROR = 'GET_SEASON_PHASE_ERROR',
}

export const getSeasonPhases = (itemId: number, feeId: number): ApiAction<GetSeasonPhasesActionTypes> => async (
  dispatch
) => {
  const endpoint = API.SEASON_PHASES;

  try {
    dispatch({ type: GetSeasonPhasesActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        id: itemId,
        fee_id: feeId,
      },
    });
    dispatch({
      type: GetSeasonPhasesActionTypes.SUCCESS,
      payload: [...response.data],
    });
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: GetSeasonPhasesActionTypes.ERROR });
        return response.data.message;
      }
    }
  }
};

export enum GetDonationOptionsActionTypes {
  START = 'GET_DONATION_OPTIONS_REQUEST',
  SUCCESS = 'GET_DONATION_OPTIONS_SUCCESS',
  ERROR = 'GET_DONATION_OPTIONS_ERROR',
}

export const fetchDonations = (itemId: number): ApiAction<GetDonationOptionsActionTypes> => async (dispatch) => {
  const endpoint = API.GET_DONATIONS;

  try {
    dispatch({ type: GetDonationOptionsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        item_id: itemId,
      },
    });
    dispatch({
      type: GetDonationOptionsActionTypes.SUCCESS,
      payload: response.data,
    });
  } catch (_) {
    dispatch({ type: GetDonationOptionsActionTypes.ERROR });
  }
};

export enum CreateDonationOptionActionTypes {
  START = 'CREATE_DONATION_OPTION_REQUEST',
  SUCCESS = 'CREATE_DONATION_OPTION_SUCCESS',
  ERROR = 'CREATE_DONATION_OPTION_ERROR',
}

export const createDonationOption = (
  itemId: number,
  amount: number,
  currency: string,
  description?: string
): ApiAction<CreateDonationOptionActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.CREATE_DONATION;

  try {
    dispatch({ type: CreateDonationOptionActionTypes.START });

    const data = description ? { amount, currency, description } : { amount, currency };

    await http.authenticated().post(endpoint, {
      pathVariables: {
        item_id: itemId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: CreateDonationOptionActionTypes.SUCCESS,
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Donation option successfully added.',
        type: 'success',
      })
    );
  } catch (error) {
    dispatch({ type: CreateDonationOptionActionTypes.ERROR });

    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        const {
          data: { message },
        } = response;
        dispatch(
          notifRequested({
            title: 'ERROR',
            content: message,
            type: 'danger',
          })
        );
      }
    }
  }
};

export enum EditDonationOptionActionTypes {
  START = 'EDIT_DONATION_REQUEST',
  SUCCESS = 'EDIT_DONATION_SUCCESS',
  ERROR = 'EDIT_DONATION_ERROR',
}

export const editDonationOption = (
  itemId: number,
  donationId: number,
  amount: number,
  currency: string,
  description?: string
): ApiAction<EditDonationOptionActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.EDIT_DONATIONS;

  try {
    dispatch({ type: EditDonationOptionActionTypes.START });
    const data = {
      amount,
      currency,
      description,
    };
    await http.authenticated().patch(endpoint, {
      pathVariables: {
        item_id: itemId,
        donation_id: donationId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: EditDonationOptionActionTypes.SUCCESS,
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Donation option successfully updated.',
        type: 'success',
      })
    );
  } catch (error) {
    dispatch({ type: EditDonationOptionActionTypes.ERROR });
    if (isAxiosError(error)) {
      const { response } = error;

      if (response) {
        const {
          data: { message },
        } = response;
        dispatch(
          notifRequested({
            title: 'ERROR',
            content: message,
            type: 'danger',
          })
        );
      }
    }
  }
};

export enum DeleteDonationOption {
  START = 'DELETE_DONATION_OPTION_REQUEST',
  SUCCESS = 'DELETE_DONATION_OPTION_SUCCESS',
  ERROR = 'DELETE_DONATION_OPTION_ERROR',
}

export const deleteDonation = (
  itemId: number,
  donationId: number
): ApiAction<DeleteDonationOption | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.EDIT_DONATIONS;

  try {
    dispatch({ type: DeleteDonationOption.START });

    await http.authenticated().delete(endpoint, {
      pathVariables: {
        item_id: itemId,
        donation_id: donationId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteDonationOption.SUCCESS,
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Donation option successfully deleted.',
        type: 'success',
      })
    );

    dispatch(fetchDonations(itemId));
  } catch (error) {
    dispatch({ type: DeleteDonationOption.ERROR });
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        const {
          data: { message },
        } = response;

        dispatch(
          notifRequested({
            title: 'ERROR',
            content: message,
            type: 'danger',
          })
        );
      }
    }
  }
};

export enum UpdateCustomDonationActionTypes {
  START = 'UPDATE_CUSTOM_DONATION_REQUEST',
  SUCCESS = 'UPDATE_CUSTOM_DONATION_SUCCESS',
  ERROR = 'UPDATE_CUSTOM_DONATION_ERROR',
}

export const updateCustomDonation = (
  itemId: number,
  isCustomDonationEnabled: boolean
): ApiAction<UpdateCustomDonationActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.UPDATE_CUSTOM_DONATION;

  try {
    dispatch({ type: UpdateCustomDonationActionTypes.START });

    const data = {
      custom_price_enabled: isCustomDonationEnabled,
    };

    await http.authenticated().patch(endpoint, {
      pathVariables: {
        item_id: itemId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateCustomDonationActionTypes.SUCCESS,
    });

    dispatch(fetchDonations(itemId));

    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `Custom donations successfully ${isCustomDonationEnabled ? 'enabled' : 'disabled'}.`,
        type: 'success',
      })
    );
  } catch (error) {
    dispatch({ type: UpdateCustomDonationActionTypes.ERROR });
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        const {
          data: { message },
        } = response;
        dispatch(
          notifRequested({
            title: 'ERROR',
            content: message,
            type: 'danger',
          })
        );
      }
    }
  }
};

// Update gift details
export enum UpdateGiftDetailsActionTypes {
  START = 'UPDATE_GIFT_DETAILS_REQUEST',
  SUCCESS = 'UPDATE_GIFT_DETAILS_SUCCESS',
  ERROR = 'UPDATE_GIFT_DETAILS_ERROR',
}

type UpdateGiftDetailsTypes = UpdateGiftDetailsActionTypes | NotificationActionTypes;

export interface GiftDetailsProps {
  isGiftable: boolean;
  giftDescription?: string;
}

export const updateGiftDetails = ({
  isGiftable,
  giftDescription,
}: GiftDetailsProps): ApiAction<UpdateGiftDetailsTypes> => async (dispatch, getState) => {
  const endpoint = API.UPDATE_ITEM;

  const {
    items: {
      itemDetails: { id: assetId },
    },
  } = getState();

  const data = {
    id: assetId,
    giftable: Number(isGiftable),
    ...(isGiftable && { gift_description: giftDescription }),
  };

  try {
    dispatch({ type: UpdateGiftDetailsActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        id: assetId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateGiftDetailsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `Asset successfully updated.`,
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateGiftDetailsActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// TEMPLATES
// Load templates
export enum LoadTemplatesActionTypes {
  START = 'LOAD_TEMPLATES_REQUEST',
  SUCCESS = 'LOAD_TEMPLATES_SUCCESS',
  ERROR = 'LOAD_TEMPLATES_ERROR',
}

export const loadTemplates = (): ApiAction<LoadTemplatesActionTypes> => async (dispatch) => {
  const endpoint = API.CREATE_TEMPLATE;

  try {
    dispatch({ type: LoadTemplatesActionTypes.START });
    const { data } = await http.authenticated().get(endpoint);

    dispatch({
      type: LoadTemplatesActionTypes.SUCCESS,
      payload: [...data],
    });
  } catch (_) {
    dispatch({ type: LoadTemplatesActionTypes.ERROR });
  }
};

// Create template
export enum CreateTemplateActionTypes {
  START = 'CREATE_TEMPLATE_REQUEST',
  SUCCESS = 'CREATE_TEMPLATE_SUCCESS',
  ERROR = 'CREATE_TEMPLATE_ERROR',
}
export const createTemplate = ({
  itemType,
  content,
  title,
  metadata,
  externalAssetId,
  isQuickAdd,
  accessControlTypeId,
  assetContentType,
}: CreateItem): RouterApiAction<CreateTemplateActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.CREATE_TEMPLATE;

  let data: CreateTemplateRequestData = {
    name: title,
    access_control_type_id: accessControlTypeId,
    preview_text: content,
    metadata,
    item_type_name: itemType,
    event_type: assetContentType,
  };

  if (isQuickAdd) {
    data = {
      ...data,
      content,
      external_asset_id: externalAssetId,
    };
  }

  try {
    dispatch({ type: CreateTemplateActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: CreateTemplateActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `Template successfully created.`,
        type: 'success',
      })
    );
    dispatch(replace(`/assets/templates/edit/${response.data.id}`));
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;

    dispatch({ type: CreateTemplateActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Load template details
export enum LoadTemplateDetailsActionTypes {
  START = 'LOAD_TEMPLATE_DETAILS_REQUEST',
  SUCCESS = 'LOAD_TEMPLATE_DETAILS_SUCCESS',
  ERROR = 'LOAD_TEMPLATE_DETAILS_ERROR',
}

export const loadTemplateDetails = (itemId: number): ApiAction<LoadTemplateDetailsActionTypes> => async (dispatch) => {
  const endpoint = API.TEMPLATE_DETAILS;

  try {
    dispatch({ type: LoadTemplateDetailsActionTypes.START });
    const { data } = await http.authenticated().get(endpoint, {
      pathVariables: {
        template_id: itemId,
      },
    });

    // Set a default paid asset for old assets that don't have access control type set
    if (!data.access_control_type) {
      data.access_control_type = {
        auth: 1,
        id: AccessControlType.PAID,
        name: 'Paid',
      };
    }
    dispatch({
      type: LoadTemplateDetailsActionTypes.SUCCESS,
      payload: { ...data },
    });
  } catch (_) {
    dispatch({ type: LoadTemplateDetailsActionTypes.ERROR });
  }
};

// Delete template metadata
export enum DeleteTemplateMetadataActionTypes {
  START = 'DELETE_TEMPLATE_METADATA_REQUEST',
  SUCCESS = 'DELETE_TEMPLATE_METADATA_SUCCESS',
  ERROR = 'DELETE_TEMPLATE_METADATA_ERROR',
}

type DeleteTemplateMetadataTypes = DeleteTemplateMetadataActionTypes | NotificationActionTypes;

export const deleteTemplateMetadata = (keys: string[]): ApiAction<DeleteTemplateMetadataTypes> => async (
  dispatch,
  getState
) => {
  const endpoint = API.DELETE_TEMPLATE_METADATA;

  try {
    dispatch({ type: DeleteTemplateMetadataActionTypes.START });

    const {
      items: {
        templates: {
          templateDetails: { id: templateId },
        },
      },
    } = getState();

    await http.authenticated().delete(endpoint, {
      contentType: ContentType.URLENCODED,
      params: { keys },
      pathVariables: {
        template_id: templateId,
      },
    });
    dispatch({
      type: DeleteTemplateMetadataActionTypes.SUCCESS,
      payload: { keys },
    });

    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `Template successfully updated.`,
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response) {
        dispatch({ type: DeleteTemplateMetadataActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );

        return response.data.message;
      }
    }
  }
};

// Update template title
export enum UpdateTemplateTitleActionTypes {
  START = 'UPDATE_TEMPLATE_TITLE_REQUEST',
  SUCCESS = 'UPDATE_TEMPLATE_TITLE_SUCCESS',
  ERROR = 'UPDATE_TEMPLATE_TITLE_ERROR',
}

type UpdateTemplateTitleTypes = UpdateTemplateTitleActionTypes | NotificationActionTypes;

export const updateTemplateTitle = (name: string): ApiAction<UpdateTemplateTitleTypes> => async (
  dispatch,
  getState
) => {
  const endpoint = API.TEMPLATE_DETAILS;
  const {
    items: {
      templates: {
        templateDetails: { id: templateId },
      },
    },
  } = getState();

  const data = {
    template_id: templateId,
    name,
  };

  try {
    dispatch({ type: UpdateTemplateTitleActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
      pathVariables: {
        template_id: templateId,
      },
    });
    dispatch({
      type: UpdateTemplateTitleActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `Template successfully updated.`,
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateTemplateTitleActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Update template preview
export enum UpdateTemplatePreviewActionTypes {
  START = 'UPDATE_TEMPLATE_PREVIEW_REQUEST',
  SUCCESS = 'UPDATE_TEMPLATE_PREVIEW_SUCCESS',
  ERROR = 'UPDATE_TEMPLATE_PREVIEW_ERROR',
}

type UpdateTemplatePreviewTypes = UpdateTemplatePreviewActionTypes | NotificationActionTypes;

export const updateTemplatePreview = (metadataForUpdate: any): ApiAction<UpdateTemplatePreviewTypes> => async (
  dispatch,
  getState
) => {
  const endpoint = API.TEMPLATE_DETAILS;

  const {
    items: {
      templates: {
        templateDetails: { id: templateId, metadata },
      },
    },
  } = getState();

  const { paywall_cover_photo } = metadataForUpdate;
  let previewImage;
  if (paywall_cover_photo && typeof paywall_cover_photo !== 'string') {
    const response = await saveItemPreviewImage(paywall_cover_photo);

    if (response) {
      previewImage = await response.data;
      dispatch(addPreviewImage(previewImage));
    }
  }

  // this is a backend flaw, metadata is sent as object
  // which containes array of objects
  const templateMetadata = reduce(metadata, (obj: any, { name, value }: any) => ({ ...obj, [name]: value }), {});

  const data = {
    template_id: templateId,
    metadata: { ...metadataForUpdate, ...metadataForItem(previewImage, templateMetadata.paywall_cover_photo) },
  };

  try {
    dispatch({ type: UpdateTemplatePreviewActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        template_id: templateId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateTemplatePreviewActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `Template successfully updated.`,
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateTemplatePreviewActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Update template metadata
export enum UpdateTemplateMetadataActionTypes {
  START = 'UPDATE_TEMPLATE_METADATA_REQUEST',
  SUCCESS = 'UPDATE_TEMPLATE_METADATA_SUCCESS',
  ERROR = 'UPDATE_TEMPLATE_METADATA_ERROR',
}

type UpdateTemplateMetadataTypes = UpdateTemplateMetadataActionTypes | NotificationActionTypes;

export interface TemplateMetadataProps {
  metadata: Array<MetadataType>;
  deletedMetadata: Array<MetadataType>;
  isCustomMetadata?: boolean;
}

export const updateTemplateMetadata = ({
  metadata,
  deletedMetadata,
  isCustomMetadata = true,
}: TemplateMetadataProps): ApiAction<UpdateTemplateMetadataTypes> => async (dispatch, getState) => {
  const endpoint = API.TEMPLATE_DETAILS;
  const {
    items: {
      templates: {
        templateDetails: { id: templateId, access_control_type_id, preview_text, item_type_id },
      },
    },
  } = getState();

  // this is a backend flaw, we save the metadata with 'asset_' prefix
  // to distinguish custom and paywall metadata
  // do not add 'asset_' if updating paywall metadata
  const newMetadata = metadata.reduce(
    (acc: any, cur: any) => ({
      ...acc,
      [`asset_${cur.key}`]: cur.value,
    }),
    {}
  );

  const data = {
    template_id: templateId,
    metadata: newMetadata,
    access_control_type_id,
  };

  if (deletedMetadata && deletedMetadata.length) {
    const metadataKeys = Object.keys(transformMetadata(isCustomMetadata, deletedMetadata));
    await dispatch(deleteTemplateMetadata(metadataKeys));
  }

  try {
    dispatch({ type: UpdateTemplateMetadataActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
      pathVariables: {
        template_id: templateId,
      },
    });
    dispatch({
      type: UpdateTemplateMetadataActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `Template successfully updated.`,
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;

    dispatch({ type: UpdateTemplateMetadataActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: message,
      })
    );
  }
};

// Create template price
export enum CreateTemplatePriceActionTypes {
  START = 'CREATE_TEMPLATE_PRICE_REQUEST',
  SUCCESS = 'CREATE_TEMPLATE_PRICE_SUCCESS',
  ERROR = 'CREATE_TEMPLATE_PRICE_ERROR',
}
type CreateTemplatePriceTypes = CreateTemplatePriceActionTypes | NotificationActionTypes;

export const createTemplatePrice = ({
  accessTypeId,
  amount,
  currency,
  accessFeeDescription,
  freeTrialDays,
  country,
  countrySet,
  additionalFeeAmount,
  additionalFeeDescription,
  restrictionType,
  startsAt,
  expiresAt,
  itemId,
  externalFees,
}: ItemAccessFee): ApiAction<CreateTemplatePriceTypes> => async (dispatch) => {
  const endpoint = API.CREATE_TEMPLATE_PRICE;

  try {
    dispatch({ type: CreateTemplatePriceActionTypes.START });

    const data = pickBy({
      access_type_id: accessTypeId,
      amount,
      currency,
      description: accessFeeDescription,
      type: restrictionType,
      country_iso: country,
      country_set_id: countrySet,
      starts_at: startsAt ? startsAt.toISOString() : startsAt,
      expires_at: expiresAt ? expiresAt.toISOString() : expiresAt,
      setup_fee_amount: additionalFeeAmount,
      setup_fee_description: additionalFeeDescription,
      trial_period_quantity: freeTrialDays,
      trial_period_period: freeTrialDays && 'day',
    });

    if (externalFees) {
      externalFees.forEach((fee: { payment_provider_id: any; external_id: any }) => {
        data[`external_fees[${fee.payment_provider_id}]`] = fee.external_id;
      });
    }

    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
      pathVariables: {
        template_id: itemId,
      },
    });
    dispatch({
      type: CreateTemplatePriceActionTypes.SUCCESS,
      payload: { ...response.data },
    });

    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `Price option successfully created.`,
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response) {
        dispatch({ type: CreateTemplatePriceActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );

        return response.data.message;
      }
    }
  }
};

// Update template price
export enum UpdateTemplatePriceActionTypes {
  START = 'UPDATE_TEMPLATE_PRICE_REQUEST',
  SUCCESS = 'UPDATE_TEMPLATE_PRICE_SUCCESS',
  ERROR = 'UPDATE_TEMPLATE_PRICE_ERROR',
}

type UpdateTemplatePriceTypes = UpdateTemplatePriceActionTypes | NotificationActionTypes;

export const updateTemplatePrice = (
  feeId: number,
  {
    accessTypeId,
    amount,
    currency,
    accessFeeDescription,
    freeTrialDays,
    country,
    countrySet,
    additionalFeeAmount,
    additionalFeeDescription,
    restrictionType,
    startsAt,
    expiresAt,
    itemId,
    itemType,
    externalFees,
  }: ItemAccessFee
): ApiAction<UpdateTemplatePriceTypes> => async (dispatch) => {
  const endpoint = API.UPDATE_TEMPLATE_PRICE;
  const successMessageText = setItemType(itemType);

  const data = pickBy({
    access_type_id: accessTypeId,
    amount,
    currency,
    description: accessFeeDescription,
    type: restrictionType,
    country_iso: country,
    country_set_id: countrySet,
    starts_at: startsAt ? startsAt.toISOString() : startsAt,
    expires_at: expiresAt ? expiresAt.toISOString() : expiresAt,
    setup_fee_amount: additionalFeeAmount,
    setup_fee_description: additionalFeeDescription,
    trial_period_quantity: freeTrialDays,
    trial_period_period: freeTrialDays && 'day',
  });

  if (externalFees) {
    externalFees.forEach((fee: { payment_provider_id: any; external_id: any }) => {
      data[`external_fees[${fee.payment_provider_id}]`] = fee.external_id;
    });
  }

  try {
    dispatch({ type: UpdateTemplatePriceActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        template_id: itemId,
        price_id: feeId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateTemplatePriceActionTypes.SUCCESS,
      payload: { ...response.data },
    });

    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `${upperFirst(successMessageText)} successfully updated.`,
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateTemplatePriceActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );

        return response.data.message;
      }
    }
  }
};

// Delete template price
export enum DeleteTemplatePriceActionTypes {
  START = 'DELETE_TEMPLATE_PRICE_REQUEST',
  SUCCESS = 'DELETE_TEMPLATE_PRICE_SUCCESS',
  ERROR = 'DELETE_TEMPLATE_PRICE_ERROR',
}

type DeleteTemplatePriceTypes = DeleteTemplatePriceActionTypes | NotificationActionTypes;

export const deleteTemplatePrice = (templateId: number, priceId: number): ApiAction<DeleteTemplatePriceTypes> => async (
  dispatch
) => {
  const endpoint = API.UPDATE_TEMPLATE_PRICE;
  const successData = { priceId };

  try {
    dispatch({ type: DeleteTemplatePriceActionTypes.START });

    const response = await http.authenticated().delete(endpoint, {
      contentType: ContentType.URLENCODED,
      pathVariables: {
        template_id: templateId,
        price_id: priceId,
      },
    });
    dispatch({
      type: DeleteTemplatePriceActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: `Price option successfully deleted.`,
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response) {
        dispatch({ type: DeleteTemplatePriceActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );

        return response.data.message;
      }
    }
  }
};

// Delete template
export enum DeletetemplateActionTypes {
  START = 'DELETE_TEMPLATE_REQUEST',
  SUCCESS = 'DELETE_TEMPLATE_SUCCESS',
  ERROR = 'DELETE_TEMPLATE_ERROR',
}

export const deleteTemplate = (id: number): ApiAction<DeletetemplateActionTypes | NotificationActionTypes> => async (
  dispatch
) => {
  const endpoint = API.TEMPLATE_DETAILS;

  try {
    dispatch({ type: DeletetemplateActionTypes.START });
    await http.authenticated().delete(endpoint, {
      pathVariables: {
        template_id: id,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeletetemplateActionTypes.SUCCESS,
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Template successfully deleted.',
        type: 'success',
      })
    );
    dispatch(loadTemplates());
  } catch (e) {
    dispatch({ type: DeletetemplateActionTypes.ERROR });
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: 'Error while deleting template.',
      })
    );
  }
};

// Save selected template fee id
export const SAVE_SELECTED_TEMPLATE_FEE_ID = 'SAVE_SELECTED_TEMPLATE_FEE_ID';

interface SaveSelectedTemplateFeeIdAction extends Action<typeof SAVE_SELECTED_TEMPLATE_FEE_ID> {
  id: number;
}

export const saveSelectedTemplateFee = (id: number): SaveSelectedTemplateFeeIdAction => ({
  type: SAVE_SELECTED_TEMPLATE_FEE_ID,
  id,
});

// Create or update asset event details
export enum UpdateEventDetailsActionTypes {
  START = 'UPDATE_ASSET_EVENT_DETAILS_REQUEST',
  SUCCESS = 'UPDATE_ASSET_EVENT_DETAILS_SUCCESS',
  ERROR = 'UPDATE_ASSET_EVENT_DETAILS_ERROR',
}

interface DataProps {
  id: number;
  item_id: number;
  content_category: string;
  industry: string;
  event_name: string;
  web_page: string;
  event_date: string | null;
  expected_viewership: number | string;
  replay_available: boolean;
  is_advertised: boolean;
  event_description?: string;
}

type UpdateEventtDetailsTypes = UpdateEventDetailsActionTypes | NotificationActionTypes;

export const updateAssetEventDetails = (eventDetails: FormValues): ApiAction<UpdateEventtDetailsTypes> => async (
  dispatch,
  getState
) => {
  const {
    items: {
      itemDetails: { id: assetId },
    },
  } = getState();

  const {
    id,
    contentCategory,
    industry,
    eventName,
    webPage,
    eventDate,
    expectedViewership,
    replayAvailable,
    isAdvertised,
    eventDescription,
  } = eventDetails;

  const data: DataProps = {
    id,
    item_id: assetId,
    content_category: contentCategory,
    industry,
    event_name: eventName,
    web_page: webPage,
    event_date: eventDate && eventDate.toISOString(),
    expected_viewership: expectedViewership,
    // BE accepts boolean value for replayAvailable
    replay_available: replayAvailable === 'true',
    is_advertised: isAdvertised,
  };

  if (eventDescription) {
    data.event_description = eventDescription;
  }

  const endpoint = API.EVENT_DETAILS;

  try {
    dispatch({ type: UpdateEventDetailsActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        id: assetId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateEventDetailsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Asset content details successfully updated.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateEventDetailsActionTypes.ERROR, payload: { eventDetails } });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Create or update template event details
export enum UpdateTemplateEventDetailsActionTypes {
  START = 'UPDATE_TEMPLATE_EVENT_DETAILS_REQUEST',
  SUCCESS = 'UPDATE_TEMPLATE_EVENT_DETAILS_SUCCESS',
  ERROR = 'UPDATE_TEMPLATE_EVENT_DETAILS_ERROR',
}

interface EventDetailsProps {
  merchant_id: number;
  content_category: string;
  industry: string;
  event_name: string;
  web_page: string;
  event_date: string | null;
  expected_viewership: number | string;
  replay_available: boolean;
  is_advertised: boolean;
  event_description?: string;
}

type UpdateTemplateEventtDetailsTypes = UpdateTemplateEventDetailsActionTypes | NotificationActionTypes;

export const updateTemplateEventDetails = (
  eventDetails: FormValues
): ApiAction<UpdateTemplateEventtDetailsTypes> => async (dispatch, getState) => {
  const {
    items: {
      itemDetails: { merchant_id: merchantId },
      templates: {
        templateDetails: { id: templateId },
      },
    },
  } = getState();

  const {
    contentCategory,
    industry,
    eventName,
    webPage,
    eventDate,
    expectedViewership,
    replayAvailable,
    isAdvertised,
    eventDescription,
  } = eventDetails;

  const data: EventDetailsProps = {
    merchant_id: merchantId,
    content_category: contentCategory,
    industry,
    event_name: eventName,
    web_page: webPage,
    event_date: eventDate && eventDate.toISOString(),
    expected_viewership: expectedViewership,
    replay_available: replayAvailable === 'true',
    is_advertised: isAdvertised,
  };

  if (eventDescription) {
    data.event_description = eventDescription;
  }

  const endpoint = API.TEMPLATE_EVENT_DETAILS;

  try {
    dispatch({ type: UpdateTemplateEventDetailsActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        template_id: templateId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateTemplateEventDetailsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Template content details successfully updated.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateTemplateEventDetailsActionTypes.ERROR, payload: { eventDetails } });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Update template event type
export enum UpdateTemplateEventTypeActionTypes {
  START = 'UPDATE_TEMPLATE_EVENT_TYPE_REQUEST',
  SUCCESS = 'UPDATE_TEMPLATE_EVENT_TYPE_SUCCESS',
  ERROR = 'UPDATE_TEMPLATE_EVENT_TYPE_ERROR',
}

type UpdateTemplateEventTypeTypes = UpdateTemplateEventTypeActionTypes | NotificationActionTypes;

export const updateTemplateEventType = (
  eventType: string,
  eventDetails?: FormValues
): ApiAction<UpdateTemplateEventTypeTypes> => async (dispatch, getState) => {
  const endpoint = API.TEMPLATE_DETAILS;

  const {
    items: {
      templates: {
        templateDetails: { id: templateId },
      },
    },
  } = getState();

  const data = { id: templateId, event_type: eventType };

  try {
    dispatch({ type: UpdateTemplateEventTypeActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        template_id: templateId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateTemplateEventTypeActionTypes.SUCCESS,
      payload: { ...response.data },
    });

    // If chosen content category is LIVE, then update
    // the event details object
    if (eventType === EventType.LIVE && eventDetails) {
      dispatch(updateTemplateEventDetails(eventDetails));
      return;
    }

    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Template content details successfully updated.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateTemplateEventTypeActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Update asset event type
export enum UpdateAssetEventTypeActionTypes {
  START = 'UPDATE_ASSET_EVENT_TYPE_REQUEST',
  SUCCESS = 'UPDATE_ASSET_EVENT_TYPE_SUCCESS',
  ERROR = 'UPDATE_ASSET_EVENT_TYPE_ERROR',
}

type UpdateEventTypeTypes = UpdateAssetEventTypeActionTypes | NotificationActionTypes;

export const updateAssetEventType = (
  eventType: string,
  eventDetails?: FormValues
): ApiAction<UpdateEventTypeTypes> => async (dispatch, getState) => {
  const endpoint = API.UPDATE_ITEM;

  const {
    items: {
      itemDetails: { id: assetId },
    },
  } = getState();

  const data = { id: assetId, event_type: eventType };

  try {
    dispatch({ type: UpdateAssetEventTypeActionTypes.START });
    const response = await http.authenticated().patch(endpoint, {
      pathVariables: {
        id: assetId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateAssetEventTypeActionTypes.SUCCESS,
      payload: { ...response.data },
    });

    // If chosen content category is LIVE, then update
    // the event details object
    if (eventType === EventType.LIVE && eventDetails) {
      dispatch(updateAssetEventDetails(eventDetails));
      return;
    }

    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Asset content details successfully updated.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: UpdateAssetEventTypeActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Create asset from template
export enum CreateAssetFromTemplateActionTypes {
  START = 'CREATE_ASSET_FROM_TEMPLATE_REQUEST',
  SUCCESS = 'CREATE_ASSET_FROM_TEMPLATE_SUCCESS',
  ERROR = 'CREATE_ASSET_FROM_TEMPLATE_ERROR',
}
export const createAssetFromTemplate = (templateId: number): ApiAction<CreateAssetFromTemplateActionTypes> => async (
  dispatch
) => {
  const endpoint = API.TEMPLATE_DETAILS;
  try {
    dispatch({ type: CreateAssetFromTemplateActionTypes.START });

    // Get the template details
    const { data: templateDetails } = await http.authenticated().get(endpoint, {
      pathVariables: {
        template_id: templateId,
      },
    });

    const {
      name,
      access_control_type: { id: access_control_type_id },
      restrictions,
      prices,
      item_type: { name: itemType },
      metadata,
      event_type: eventType,
      event_details = {},
      preview_id: previewId,
    } = templateDetails;

    // const {
    //   content_category: contentCategory,
    //   event_date: eventDate,
    //   event_description: eventDescription,
    //   event_name: eventName,
    //   expected_viewership: expectedViewership,
    //   id,
    //   industry,
    //   is_advertised: isAdvertised,
    //   replay_available: replayAvailable,
    //   web_page: webPage,
    // } = event_details;

    // Create object for asset creation
    const initialMetadata = {
      preview_description:
        metadata.find((prop: { name: string }) => prop.name === PreviewMetadataNames.PREVIEW_DESCRIPTION)?.value ||
        DefaultPreviewMetadata.PREVIEW_DESCRIPTION,
      preview_title:
        metadata.find((prop: { name: string }) => prop.name === PreviewMetadataNames.PREVIEW_TITLE)?.value ||
        DefaultPreviewMetadata.PREVIEW_TITLE,
      paywall_cover_photo:
        metadata.find((prop: { name: string }) => prop.name === PreviewMetadataNames.COVER_PHOTO)?.value ||
        DefaultPreviewMetadata.PREVIEW_COVER_PHOTO,
      preview_button_label:
        metadata.find((prop: { name: string }) => prop.name === PreviewMetadataNames.PREVIEW_BUTTON)?.value ||
        DefaultPreviewMetadata.PREVIEW_BUTTON,
      preview_image_name:
        metadata.find((prop: { name: string }) => prop.name === PreviewMetadataNames.PREVIEW_IMAGE_NAME)?.value ||
        DefaultPreviewMetadata.PREVIEW_IMAGE_NAME,
      thumbnail_image:
        metadata.find((prop: { name: string }) => prop.name === PreviewMetadataNames.THUMBNAIL_IMAGE)?.value ||
        DefaultPreviewMetadata.THUMBNAIL_IMAGE,
      thumbnail_image_name:
        metadata.find((prop: { name: string }) => prop.name === PreviewMetadataNames.THUMBNAIL_IMAGE_NAME)?.value ||
        DefaultPreviewMetadata.THUMBNAIL_IMAGE_NAME,
    };

    const asset = {
      title: name,
      itemType,
      metadata: initialMetadata,
      accessControlTypeId: access_control_type_id,
      previewId: previewId || 1,
      assetContentType: eventType,
    };

    // Step 1: Create asset
    const assetId = await dispatch(createItem(asset));

    // Step 2: restrictions
    if (restrictions) {
      const domainArray: any[] = [];
      restrictions.forEach((restriction: { category: any; additional_data: any; id: any }) => {
        const { category, additional_data: additionalData } = restriction;
        if (category === 'domain') {
          domainArray.push({
            id: 0,
            domain: additionalData,
          });
        } else if (category === 'geo') {
          dispatch(createAssetCountrySet(additionalData, assetId));
        } else {
          dispatch(addAssetAgeRestriction(additionalData, assetId));
        }
      });

      if (domainArray.length) {
        dispatch(changeAssetDomains(domainArray, assetId));
      }
    }

    // Step 3: access fees/prices
    if (prices) {
      prices.forEach((price: any) => {
        const {
          amount,
          access_type_id: accessTypeId,
          currency,
          description: accessFeeDescription,
          expires_at: expiresAt = '',
          starts_at: startsAt = '',
          restriction_type: restrictionType = '',
          country_iso: country = '',
          country_set_id: countrySet = '',
        } = price;

        const priceObj = {
          amount,
          accessTypeId,
          currency,
          accessFeeDescription,
          expiresAt: moment(expiresAt),
          startsAt: moment(startsAt),
          itemType: 'asset',
          itemId: assetId,
          isSeasonAccessFee: false,
          restrictionType,
          country,
          countrySet,
        };
        dispatch(createItemAccessFee(priceObj));
      });
    }

    // Step 4: Add asset metadata
    if (metadata) {
      const assetMetadata = metadata
        .filter((item: { name: string; value: string }) => item.name.startsWith('asset_'))
        .map((item: { name: any; value: any }) => ({ key: item.name, value: item.value }));

      if (assetMetadata.length) {
        dispatch(updateItemMetadata({ metadata: assetMetadata, itemType }));
      }
    }

    // commentted since we decided to post MVP passcodes
    // // Step 5: Event management
    // if (eventType === 'vod' || eventType === 'live') {
    //   const eventDetails =
    //     eventType === 'live'
    //       ? {
    //           id,
    //           contentCategory,
    //           industry,
    //           eventName,
    //           webPage,
    //           eventDate: moment.unix(eventDate),
    //           expectedViewership,
    //           replayAvailable,
    //           isAdvertised,
    //           eventDescription,
    //         }
    //       : undefined;
    //   dispatch(updateAssetEventType(eventType, eventDetails));
    // }

    dispatch({ type: CreateAssetFromTemplateActionTypes.SUCCESS });
  } catch (e) {
    dispatch({ type: CreateAssetFromTemplateActionTypes.ERROR });
  }
};

// LiveLike widgets
export enum LoadResourceActionTypes {
  START = 'LOAD_RESOURCE_REQUEST',
  SUCCESS = 'LOAD_RESOURCE_SUCCESS',
  ERROR = 'LOAD_RESOURCE_ERROR',
}

interface PageParams {
  page: number;
}

export const loadResource = (resource: string, { page }: PageParams): ApiAction<LoadResourceActionTypes> => async (
  dispatch
) => {
  const endpoint = API.LOAD_RESOURCE(page);

  try {
    dispatch({ type: LoadResourceActionTypes.START });

    const { data } = await http.authenticated().get(endpoint, {
      pathVariables: { resource, page },
    });
    dispatch({
      type: LoadResourceActionTypes.SUCCESS,
      payload: { ...data },
    });
  } catch (_) {
    dispatch({ type: LoadResourceActionTypes.ERROR });
  }
};

export enum CreateLiveLikeChatroomActionTypes {
  START = 'CREATE_LIVELIKE_CHATROOM_REQUEST',
  SUCCESS = 'CREATE_LIVELIKE_CHATROOM_SUCCESS',
  ERROR = 'CREATE_LIVELIKE_CHATROOM_ERROR',
}

export enum LiveLikeChatContentFilter {
  FILTERED = 'filtered',
  NONE = 'none',
}

interface ChatRoomParams {
  id?: string;
  title: string;
  content_filter: LiveLikeChatContentFilter;
  itemId: number;
  patch?: boolean;
}

interface CreateChatRoomData {
  title: string;
  item_id: number;
  content_filter: LiveLikeChatContentFilter;
  id?: string;
}

export const createChatRoom = (
  { id, title, content_filter = LiveLikeChatContentFilter.NONE, itemId }: ChatRoomParams,
  patch = false
): ApiAction<CreateLiveLikeChatroomActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.CREATE_LIVELIKE_CHATROOM;

  const chatRoomData: CreateChatRoomData = {
    title,
    item_id: itemId,
    content_filter,
  };

  if (title) {
    chatRoomData.title = title;
  }

  if (id) {
    chatRoomData.id = id;
  }

  const endpointMethod = patch ? http.authenticated().patch : http.authenticated().post;

  try {
    dispatch({ type: CreateLiveLikeChatroomActionTypes.START });

    const { data } = await endpointMethod(endpoint, {
      data: chatRoomData,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: CreateLiveLikeChatroomActionTypes.SUCCESS,
      payload: { ...data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Chat room successfully created.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;

      if (response) {
        dispatch({ type: CreateLiveLikeChatroomActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

export enum GetLiveLikeChatroomActionTypes {
  START = 'GET_LIVELIKE_CHATROOM_START',
  SUCCESS = 'GET_LIVELIKE_CHATROOM_SUCCESS',
  ERROR = 'GET_LIVELIKE_CHATROOM_ERROR',
}

export const getLiveLikeChatroom = (chatroomId: string): ApiAction<GetLiveLikeChatroomActionTypes> => async (
  dispatch
) => {
  const endpoint = API.GET_LIVELIKE_CHATROOM;

  try {
    dispatch({ type: GetLiveLikeChatroomActionTypes.START });

    const { data } = await http.authenticated().get(endpoint, {
      pathVariables: {
        chatroomId,
      },
    });

    dispatch({ type: GetLiveLikeChatroomActionTypes.SUCCESS, payload: { ...data } });
  } catch (err) {
    dispatch({ type: GetLiveLikeChatroomActionTypes.ERROR });
  }
};

export enum DeleteLiveLikeChatroomActionTypes {
  START = 'DELETE_LIVELIKE_CHATROOM_REQUEST',
  SUCCESS = 'DELETE_LIVELIKE_CHATROOM_SUCCESS',
  ERROR = 'DELETE_LIVELIKE_CHATROOM_ERROR',
}

export const deleteResource = (
  resourceId: number | string,
  itemId: number
): ApiAction<DeleteLiveLikeChatroomActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.DELETE_LIVELIKE_CHATROOM;

  try {
    dispatch({ type: DeleteLiveLikeChatroomActionTypes.START });

    const { data } = await http.authenticated().delete(endpoint, {
      pathVariables: {
        resourceId,
        itemId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteLiveLikeChatroomActionTypes.SUCCESS,
      payload: { ...data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Resource successfully deleted.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response) {
        dispatch({ type: DeleteLiveLikeChatroomActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

export enum GetIvsChannelsActionTypes {
  START = 'GET_IVS_CHANNELS_START',
  SUCCESS = 'GET_IVS_CHANNELS_SUCCESS',
  ERROR = 'GET_IVS_CHANNELS_ERROR',
}

export const getIvsChannels = (): ApiAction<GetIvsChannelsActionTypes> => async (dispatch) => {
  const endpoint = API.IVS_CHANNELS;

  dispatch({ type: GetIvsChannelsActionTypes.START });

  try {
    const { data } = await http.authenticated().get(endpoint);

    dispatch({ type: GetIvsChannelsActionTypes.SUCCESS, payload: data?.collection });
  } catch (err) {
    dispatch({ type: GetIvsChannelsActionTypes.ERROR });
  }
};

export enum GetIvsChannelDetailsActionTypes {
  START = 'GET_IVS_CHANNEL_DETAILS_START',
  SUCCESS = 'GET_IVS_CHANNEL_DETAILS_SUCCESS',
  ERROR = 'GET_IVS_CHANNEL_DETAILS_ERROR',
}

export enum IvsChannelType {
  STANDARD = 'STANDARD',
  BASIC = 'BASIC',
}

export enum IvsChannelVideoLatencyOption {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
}

export interface IvsChannel {
  name: string;
  arn: string;
  stream_url: string;
  type: IvsChannelType;
  latency_mode: IvsChannelVideoLatencyOption;
  authorized: boolean;
}

type GetIvsChannelDataActionTypesWithNotification = GetIvsChannelDetailsActionTypes | NotificationActionTypes;

export const getIvsChannelDetails = (
  channelArn: string
): ApiAction<GetIvsChannelDataActionTypesWithNotification> | IvsChannel => async (dispatch) => {
  const endpoint = API.GET_IVS_CHANNEL(channelArn);

  dispatch({ type: GetIvsChannelDetailsActionTypes.START });

  try {
    const { data } = await http.authenticated().get(endpoint);

    dispatch({ type: GetIvsChannelDetailsActionTypes.SUCCESS });

    return data as IvsChannel;
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;

      if (response) {
        dispatch({ type: GetIvsChannelDetailsActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

export enum CreateIvsChannelActionTypes {
  START = 'CREATE_IVS_CHANNEL_START',
  SUCCESS = 'CREATE_IVS_CHANNEL_SUCCESS',
  ERROR = 'CREATE_IVS_CHANNEL_ERROR',
}

type CreateIvsChannelActionTypesWithNotification = CreateIvsChannelActionTypes | NotificationActionTypes;

export type NewIvsChannelParams = Omit<IvsChannel, 'arn' | 'stream_url'>;

export const createIvsChannel = (
  channelParams: NewIvsChannelParams
): ApiAction<CreateIvsChannelActionTypesWithNotification> | IvsChannel => async (dispatch) => {
  const endpoint = API.IVS_CHANNELS;

  dispatch({ type: CreateIvsChannelActionTypes.START });

  try {
    const { data } = await http.authenticated().post(endpoint, {
      data: channelParams,
      contentType: ContentType.URLENCODED,
    });

    dispatch({ type: CreateIvsChannelActionTypes.SUCCESS, payload: data?.collection });

    return data as IvsChannel;
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;

      if (response) {
        dispatch({ type: CreateIvsChannelActionTypes.ERROR });
        dispatch(
          notifRequested({
            type: 'danger',
            title: 'ERROR',
            content: response.data.message,
          })
        );
      }
    }
  }
};

export enum UpdateIvsChannelDetailsActionTypes {
  START = 'UPDATE_IVS_CHANNEL_DETAILS_START',
  SUCCESS = 'UPDATE_IVS_CHANNEL_DETAILS_SUCCESS',
  ERROR = 'UPDATE_IVS_CHANNEL_DETAILS_ERROR',
}

export const updateIvsChannelDetails = (
  channelData: IvsChannel
): ApiAction<UpdateIvsChannelDetailsActionTypes> => async (dispatch) => {
  const endpoint = API.IVS_CHANNELS;

  dispatch({ type: UpdateIvsChannelDetailsActionTypes.START });

  try {
    const { data } = await http.authenticated().put(endpoint, {
      data: channelData,
      contentType: ContentType.URLENCODED,
    });

    dispatch({ type: UpdateIvsChannelDetailsActionTypes.SUCCESS, payload: data?.collection });
  } catch (err) {
    dispatch({ type: UpdateIvsChannelDetailsActionTypes.ERROR });
  }
};
