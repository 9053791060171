import React, { FC } from 'react';
import styled from 'styled-components';
import { AiOutlineInfoCircle, AiOutlineQuestionCircle, AiOutlineWarning } from 'react-icons/ai';
import { Typography, Colors } from '@inplayer-org/inplayer-ui';

interface ColorProps {
  color?: string;
}

const ContentWrapper = styled.div<ColorProps>`
  ${({ theme, color }) => `
    display: flex;
    padding: 15px;
    margin-bottom: 30px;
    border: 1px solid ${color || theme.palette.primary.main};
    border-radius: 5px;
    background: ${Colors.lightGray};
  `}
`;

const StyledIcon = styled(AiOutlineInfoCircle)<ColorProps>`
  ${({ theme, color }) => `
    color: ${color || theme.palette.primary.main};
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5rem;
  `}
`;

export const MessageVariants = {
  INFO: { icon: AiOutlineInfoCircle, color: '' },
  HELP: { icon: AiOutlineQuestionCircle, color: '' },
  WARNING: { icon: AiOutlineWarning, color: Colors.yellow },
};

type ValueOf<T> = T[keyof T];

interface Props {
  variant: ValueOf<typeof MessageVariants>;
}

const ThemedMessage: FC<Props> = ({ variant, children }) => (
  <ContentWrapper color={variant.color}>
    <StyledIcon color={variant.color} as={variant.icon} />
    <Typography variant="p">{children}</Typography>
  </ContentWrapper>
);

export default ThemedMessage;
