export const reports = {
  FETCH_PAYMENTS: 'payments/transactions',
  FETCH_SUBSCRIPTIONS: 'subscriptions',
  FETCH_SUBSCRIPTIONS_COUNT: 'subscriptions/count',
  FETCH_SUBSCRIPTIONS_COUNT_COUNTRIES: 'subscriptions/count/country',
  REGISTERED_USERS_COUNT: 'accounts/registered-users/count',
  COUNTRY_USERS_COUNT: 'accounts/registered-users/count/country',
  SALES_COUNT: 'payments/sales/count',
  COUNTRY_SALES_COUNT: 'payments/sales/count/country',
  REVENUE_COUNT: 'payments/revenue',
  REPORTS: 'reporting/reports/{type}',
  RENAME_REPORT: '/v2/reporting/rename/{report_type}',
  DELETE_REPORT: 'reporting/{type}/{filename}',
  PAYMENT_DETAILS: 'payments/transactions/{id}',
  REPORT_INFORMATION: 'reporting',
  REVENUE: 'accounting/revenue-per-currency',
  GENERATE_REPORT: 'reporting/report/{reportType}',
  SCHEDULE_REPORT: '/v2/reporting/schedule/{report_type}',
  GENERATE_COMMERCIAL_REPORT: '/v2/reporting/report/{report_type}',
  GET_REPORT_FIELDS: 'reporting/report-fields/{reportType}',
  SUBSCRIPTION_DETAILS: 'subscriptions/{id}',
  GENERATE_UNIQUE_AUDIENCE_REPORT: 'v2/analytics/content-requests/generate',
  GET_GENERATED_FILES_FOR_UNIQUE_AUDIENCE: 'v2/analytics/content-requests',
  GET_URL_FOR_UNIQUE_AUDIENCE_REPORT: 'v2/analytics/content-requests/download',
  DELETE_UNIQUE_CUSTOMERS_REPORT: 'v2/analytics/content-requests/delete/{filename}',
};
