import * as React from 'react';
import {
  Table as InPlayerTable,
  TableRowData,
  TableProps,
  Typography,
  TypographyVariant,
} from '@inplayer-org/inplayer-ui';
import AssetsPagination from 'pages/MerchantPages/Assets/components/AssetsPagination';
import { StyledTypographyMargin } from 'components/StyledTypographyMargin';

export interface Page {
  selected: number;
}

interface Props<DataType extends TableRowData> extends TableProps<DataType> {
  title?: string;
  renderEmptyTable?: boolean;
  hasBottomPagination?: boolean;
  handlePageClick?: (page: number) => any;
  currentPage?: number;
  agency?: boolean;
  pageRangeDisplayed?: number;
  showLoader?: boolean;
  titleVariant?: TypographyVariant;
  type?: string;
  total?: number;
  itemsPerPage?: number;
  noDataMessage?: string;
  editableId?: string;
}

class GenericTable<DataType extends TableRowData> extends React.Component<Props<DataType>> {
  static defaultProps = {
    pageRangeDisplayed: 5,
    hasBottomPagination: false,
    handlePageClick: () => {},
    currentPage: 1,
    type: 'entries',
    itemsPerPage: 15,
  };

  handlePageChange = (page: number) => {
    const { handlePageClick } = this.props;
    if (handlePageClick) {
      handlePageClick(page);
    }
  };

  render() {
    const {
      data,
      renderEmptyTable,
      hasBottomPagination,
      title,
      titleVariant,
      type,
      noDataMessage,
      total,
      showLoader,
      currentPage = 1,
      pageRangeDisplayed = 5,
      itemsPerPage = 15,
      options = {},
      ...rest
    } = this.props;

    const totalData = total || data.length;
    const shouldRenderPagination = hasBottomPagination;

    options.headerSection = (
      <>
        {options.headerSection && <div>{options.headerSection}</div>}
        {!showLoader && !totalData && !renderEmptyTable ? (
          <Typography variant="p">{noDataMessage || `No ${type} found.`}</Typography>
        ) : null}
      </>
    );

    return (
      <>
        {title && (
          <StyledTypographyMargin isHeading variant={titleVariant || 'h2'}>
            {title}
          </StyledTypographyMargin>
        )}
        <InPlayerTable<DataType>
          data={data}
          options={options}
          showLoader={showLoader}
          renderEmptyTable={renderEmptyTable}
          {...rest}
        />
        {shouldRenderPagination && (
          <AssetsPagination
            totalData={totalData}
            paginationPage={currentPage}
            handlePageChange={this.handlePageChange}
            numberOfPagesDisplayed={pageRangeDisplayed}
            limit={itemsPerPage}
          />
        )}
      </>
    );
  }
}

export default GenericTable;
