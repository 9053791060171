import React, { memo, FunctionComponent } from 'react';
import moment from 'moment';
import media from 'utils/mediaQueries';
import { Typography } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';
import { Colors } from 'pages/MerchantPages/Authentication/components/colors';
import FooterLinks from './FooterLinks';

const FooterContainer = styled.footer`
  width: 100%;
  text-align: center;
  padding: 20px 0;
  font-family: Montserrat, 'sans-serif';

  ${media.phone} {
    display: none;
  }
`;

const FooterCenter = styled.div`
  width: 90%;
  margin: 24px auto;
  position: relative;
`;

const FooterLinksContainer = styled.div`
  margin-bottom: 10px;
`;

const Paragraph = styled(Typography)`
  color: ${Colors.black};
  line-height: 1.5em;
  margin: 0;
  letter-spacing: 0.02em;
  font-size: ${({ theme }) => theme.font.sizes.small};
  font-weight: ${({ theme }) => theme.font.weights.light};
  font-style: normal;
  max-width: 100%;
`;

export const footerLinks = [
  {
    text: 'Privacy',
    options: {
      href: 'https://inplayer.com/privacy-policy/',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    text: 'Terms',
    options: {
      href: 'https://inplayer.com/terms/',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    text: 'DMCA',
    options: {
      href: 'https://inplayer.com/dmca/',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    text: 'GDPR',
    options: {
      href: 'https://inplayer.com/gdpr/',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    text: 'Cookies',
    options: {
      href: 'https://inplayer.com/cookies/',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
];

const Footer: FunctionComponent = () => {
  return (
    <FooterContainer>
      <FooterCenter>
        <FooterLinksContainer>
          {footerLinks.map((link, i) => {
            return <FooterLinks key={i} link={link} displayBorder={i !== 0} />;
          })}
        </FooterLinksContainer>
        <Paragraph variant="p">Copyright © {moment().format('YYYY')}, InPlayer Ltd. All rights reserved.</Paragraph>
      </FooterCenter>
    </FooterContainer>
  );
};

export default memo(Footer);
