import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled, { StyledComponent, css } from 'styled-components';
import { ifProp } from 'styled-tools';
import {
  TableRowData,
  TableColumn,
  TableColumn$RenderProps,
  RowActionsRender,
  PillLabel,
  PillLabelModifier,
  Theme,
  Colors,
  Tooltip,
  AnalyticsComponent,
} from '@inplayer-org/inplayer-ui';
import { transparentize } from 'polished';
import map from 'lodash/fp/map';
import truncate from 'lodash/truncate';
import values from 'lodash/values';

// utils
import { convertArrayToCamelCase, unescapeText } from 'utils/helpers';
import linkTextWithTooltip from 'utils/linkTextWithTooltip';

// actions
import { resetSubscriptionDetails } from 'ducks/merchantDucks/subscriptions/actions';

// types
import PaymentsState from 'ducks/merchantDucks/payments/types';
import { ItemType } from 'ducks/merchantDucks/items/actions';
import { SubscriptionActionType } from 'ducks/merchantDucks/subscriptions/types';

// components
import TableActionMenu from 'components/Table/TableActionMenu';
import GenericTable from 'components/Table/GenericTable';
import CommonIcon from 'components/CommonIcon';
import TableClipBoardIcon from 'components/Table/TableClipBoardIcon';

const { ActionMenuContainer } = TableActionMenu;

interface ValueContainerOtherProps {
  refund?: boolean;
}

export const ValueContainer: StyledComponent<'span', Theme, ValueContainerOtherProps> = styled.span`
  color: ${ifProp('refund', Colors.red, 'inherit')};
`;

export const StyledPill = styled(PillLabel)<{ isDonationActionType?: boolean }>`
  ${({ isDonationActionType }) =>
    isDonationActionType &&
    css`
      color: ${Colors.amethyst};
      border-color: ${Colors.amethyst};
      background-color: ${transparentize(0.8, Colors.amethyst)};
    `}
`;

export interface Payment {
  action_type: string;
  country: string;
  currency_iso: string;
  charged_amount: number;
  payment_history_id: number;
  item_type: string;
  item_id: number;
  item_title: string;
  consumer_id: number;
  consumer_email: string;
  timestamp: number;
  access_fee_description: string;
  donation_description: string;
}

export interface PaymentTableData extends TableRowData {
  actionType: string;
  country: string;
  currencyIso: string;
  chargedAmount: string;
  paymentHistoryId: string;
  itemType: string;
  itemId: string;
  itemTitle: string;
  consumerId: string;
  consumerEmail: string;
  timestamp: number;
  transactionToken: string;
  trxToken: string;
  accessFeeDescription: string;
  donationDescription: string;
}

export const pillLabelTypes: {
  [key: string]: PillLabelModifier;
} = {
  refund: 'danger',
  recurrent: 'success',
  charge: 'info',
  past_due: 'primary',
};

interface Props {
  payments: PaymentsState;
  handlePageClick: (page: number) => void;
  paginationPage: number;
  renderHeaderSection: () => JSX.Element;
  redirectToPaymentDetails: (trxToken: string) => void;
}

const renderActionTypePill = ({
  actionType,
  isDonationActionType,
  transactionToken,
}: {
  actionType: string;
  isDonationActionType: boolean;
  transactionToken: string;
}) => {
  const subscriptionTypes: string[] = values(SubscriptionActionType);
  const renderPill = () => (
    <StyledPill isDonationActionType={isDonationActionType} size="xs" modifiers={[pillLabelTypes[actionType]]}>
      {actionType.toUpperCase()}
    </StyledPill>
  );

  if (subscriptionTypes.includes(actionType)) {
    return (
      <Tooltip placement="top" content="Go to subscription details">
        <AnalyticsComponent>
          {({ pages, tracker, merchantId, ip }) => (
            <Link
              onClick={() =>
                tracker.track({
                  event: 'click',
                  type: 'button',
                  tag: `link_payment_type_${transactionToken}`,
                  pages,
                  merchantId,
                  ip,
                })
              }
              to={`/transactions/subscriptions/${transactionToken}`}
            >
              {renderPill()}
            </Link>
          )}
        </AnalyticsComponent>
      </Tooltip>
    );
  }

  return renderPill();
};

const generateColumns = (): Array<TableColumn<PaymentTableData>> => [
  {
    title: 'Asset/Package name',
    key: 'itemId',
    render: ({ value, rowValues }) => {
      const itemType = rowValues.itemType === ItemType.Package ? ItemType.Package : ItemType.Asset;
      const itemLink =
        rowValues.itemType === ItemType.Package ? `/assets/${itemType}s/edit/${value}` : `/${itemType}s/edit/${value}`;

      return (
        <TableClipBoardIcon
          tag={`icon_copy_asset_name_${rowValues.itemId}`}
          clipboardText={rowValues.itemTitle}
          isLinkText
        >
          {linkTextWithTooltip({
            text: truncate(unescapeText(rowValues.itemTitle), {
              length: 40,
            }),
            linkTo: itemLink,
            tooltipContent: `Go to ${itemType}`,
            tag: `link_asset_name_${rowValues.itemId}`,
          })}
        </TableClipBoardIcon>
      );
    },
  },
  {
    title: 'Type',
    key: 'actionType',
    render: ({ value, rowValues: { transactionToken } }) => {
      const actionType = value === 'store-payment' ? 'freemium' : value;
      const isDonationActionType = value === 'donation';

      return renderActionTypePill({ actionType, isDonationActionType, transactionToken });
    },
  },
  {
    title: 'Amount',
    key: 'chargedAmount',
    render: ({ value, rowValues: { currencyIso, actionType } }) => {
      const tablePrice = actionType === 'refund' ? `- ${currencyIso} ${value}` : `+ ${currencyIso} ${value}`;

      return <ValueContainer refund={actionType === 'refund'}>{tablePrice}</ValueContainer>;
    },
  },
  {
    title: 'Customer email',
    key: 'consumerEmail',
    render: ({ value, rowValues: { consumerId } }) => (
      <TableClipBoardIcon tag={`icon_copy_customer_email_${consumerId}`} clipboardText={value} isLinkText>
        {linkTextWithTooltip({
          text: truncate(value, {
            length: 40,
          }),
          linkTo: `/audience/edit/${consumerId}`,
          tooltipContent: 'Go to customer account',
          tag: `link_customer_email_${consumerId}`,
        })}
      </TableClipBoardIcon>
    ),
  },
  {
    title: 'Country',
    key: 'country',
    render: ({ value }) => value || 'N/A',
  },
  {
    title: 'Created at (UTC)',
    key: 'timestamp',
    render: ({ value }: TableColumn$RenderProps<PaymentTableData, number>) =>
      value ? moment(value).utc().format('YYYY/MM/DD HH:mm') : null,
  },
];

const PaymentsTable = ({
  payments: { total, limit, collection, isFetching },
  handlePageClick,
  paginationPage,
  redirectToPaymentDetails,
  renderHeaderSection,
}: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetSubscriptionDetails());
  }, []);

  const paymentsCollection = convertArrayToCamelCase<Payment, PaymentTableData>(collection);

  const mapPaymentHistoryIdToId = map<PaymentTableData, PaymentTableData>((item) => ({
    ...item,
    id: item.paymentHistoryId,
  }));

  const paymentsCollectionWithId = mapPaymentHistoryIdToId(paymentsCollection);

  const renderRowActions: RowActionsRender<PaymentTableData> = ({ row }) => {
    return (
      <ActionMenuContainer>
        <CommonIcon
          tag={`icon_details_${row.itemId}`}
          hoverStatus="arrowRight"
          name="arrowRight"
          onClick={(e) => {
            e.preventDefault();
            redirectToPaymentDetails(row.trxToken);
          }}
        />
      </ActionMenuContainer>
    );
  };

  return (
    <GenericTable<PaymentTableData>
      hasBottomPagination
      data={paymentsCollectionWithId}
      total={total}
      columns={generateColumns()}
      currentPage={paginationPage}
      handlePageClick={handlePageClick}
      pageRangeDisplayed={3}
      itemsPerPage={limit}
      showLoader={isFetching}
      options={{
        rowActions: renderRowActions,
        headerSection: renderHeaderSection(),
      }}
      type="payments"
      actionsRowTitle="Edit"
    />
  );
};

export default PaymentsTable;
