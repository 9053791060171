import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Grid } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';

// components
import AuthFormContainer from 'components/AuthFormContainer';

// styled components
import { BlueTypography } from 'components/SharedStyledElements';

const { Cell, Container } = Grid;

const ActivationMessageWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Link = styled(NavLink)`
  text-decoration: underline;
`;

const TypographyStyled = styled(BlueTypography)`
  font-weight: ${({ theme }) => theme.font.weights.light};
`;

const ActivationMessage: FunctionComponent = () => {
  return (
    <ActivationMessageWrapper>
      <AuthFormContainer gap="3rem" showLogo={false}>
        <Cell center>
          <TypographyStyled variant="h2">Congratulations!</TypographyStyled>
        </Cell>
        <Container columns={1} gap="2rem">
          <Cell center>
            <BlueTypography variant="h5">Your account has been successfully activated</BlueTypography>
          </Cell>
          <Cell center>
            <Typography variant="p">Thank you for joining! You may start using your account immediately.</Typography>
          </Cell>
          <Cell center>
            <Link to="/login">Sign in</Link>
          </Cell>
        </Container>
      </AuthFormContainer>
    </ActivationMessageWrapper>
  );
};

export default ActivationMessage;
