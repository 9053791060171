import { Reducer } from 'redux';
import orderBy from 'lodash/orderBy';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';

// utils
import reducerWithActionMap, { ActionMap } from 'utils/reducers';

// actions
import {
  LoadClientsActionTypes,
  LoadFollowersActionTypes,
  CreateFollowerAccountActionTypes,
  UpdateFollowerAccountActionTypes,
  DeleteFollowerAccountActionTypes,
} from 'ducks/merchantDucks/agency/actions';
import { ImpersonateAsAgentTypes } from 'ducks/adminDucks/merchants/actions';

// types
import AgencyState from 'ducks/merchantDucks/agency/types';
import { Scopes } from 'pages/AdminPages/AdminMerchants/PaymentManagement/components/PaymentSetup';

// constants
import { MERCHANT_PANEL_URL, NEW_DASHBOARD_URL } from 'constants/index';

export const initialState: AgencyState = {
  collection: [],
  total: 0,
  size: 6,
  isFetching: false,
  impersonated: false,
  scopeOptions: [
    { value: Scopes.INHERITANCE, label: 'Payment setup' },
    { value: Scopes.EMAILINHERITANCE, label: 'Email templates' },
  ],
  clients: {
    collection: [],
    total: 0,
    size: 10,
    isFetching: false,
  },
};

type AgencyReducer = Reducer<AgencyState>;

const fetchFollowers: AgencyReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const setFollowers: AgencyReducer = (state = initialState, { payload: { collection, total } }) => ({
  ...state,
  collection: [...collection],
  total,
  isFetching: false,
});

const fetchFollowersFailure: AgencyReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const promptFetchClients: AgencyReducer = (state = initialState) => ({
  ...state,
  clients: {
    ...state.clients,
    isFetching: true,
  },
});

const fetchClientsFailure: AgencyReducer = (state = initialState) => ({
  ...state,
  clients: {
    ...state.clients,
    isFetching: false,
  },
});

const fetchClientsSuccess: AgencyReducer = (state = initialState, { payload: { collection, total } }) => ({
  ...state,
  clients: {
    ...state.clients,
    collection: [...collection],
    total,
    isFetching: false,
  },
});

// impersonate as agent
const promptImpersonateAgent: AgencyReducer = (state = initialState) => ({
  ...state,
  impersonated: false,
});

const setImpersonateAsAgentSuccess: AgencyReducer = (state = initialState, { payload }) => {
  const { token, refresh_token, expires } = payload;
  const url = `${MERCHANT_PANEL_URL}/login?t=${token}&rt=${refresh_token}&exp=${expires}`;
  const name = 'Merchant Panel';

  window.open(url, name);
  return {
    ...state,
    impersonated: true,
  };
};

const setImpersonateNewDashboardSuccess: AgencyReducer = (state = initialState, { payload }) => {
  const { access_token, expires, thoughtspot_session } = payload;
  const url = `${NEW_DASHBOARD_URL}/login?t=${access_token}&exp=${expires}&ts=${thoughtspot_session}`;
  window.open(url, '_blank');

  return {
    ...state,
    impersonated: true,
  };
};

const setImpersonateAsAgentFailure: AgencyReducer = (state = initialState) => ({
  ...state,
  impersonated: false,
});

const createFollowerSuccess: AgencyReducer = (state = initialState, { payload: { account } }) => {
  const newFollowerCollection = orderBy([...state.collection, account], 'created_at', 'desc');
  const newClientCollection = orderBy([...state.clients.collection, account], 'created_at', 'desc');

  return {
    ...state,
    collection: [...newFollowerCollection],
    total: newFollowerCollection.length,
    clients: {
      ...state.clients,
      collection: [...newClientCollection],
      total: newClientCollection.length,
    },
  };
};

const updateFollowerSuccess: AgencyReducer = (state = initialState, { payload }) => {
  const { id: followerId } = payload;
  const { collection } = state;
  const updatedFollowerIndex = findIndex(collection, (item) => item.id === followerId);

  if (updatedFollowerIndex === -1) {
    return state;
  }

  const updatedFollower = { ...collection[updatedFollowerIndex], ...payload };

  return {
    ...state,
    collection: [
      ...collection.slice(0, updatedFollowerIndex),
      { ...updatedFollower },
      ...collection.slice(updatedFollowerIndex + 1),
    ],
  };
};

const deleteFollowerSuccess: AgencyReducer = (state = initialState, { payload: { followerId } }) => {
  const { collection: followerCollection, clients } = state;
  const { collection: clientCollection } = clients;
  const newFollowerCollection = filter(followerCollection, (follower) => follower.id !== followerId);
  const newClientCollection = filter(clientCollection, (client) => client.id !== followerId);

  return {
    ...state,
    collection: newFollowerCollection,
    total: newFollowerCollection.length,
    clients: {
      ...clients,
      collection: newClientCollection,
      total: newClientCollection.length,
    },
  };
};

const actionsMap: ActionMap<AgencyState> = {
  /* FETCH FOLLOWERS FOR RELATED ACCOUNTS MENU */
  [LoadFollowersActionTypes.START]: fetchFollowers,
  [LoadFollowersActionTypes.FAILURE]: fetchFollowersFailure,
  [LoadFollowersActionTypes.SUCCESS]: setFollowers,
  [LoadClientsActionTypes.START]: promptFetchClients,
  [LoadClientsActionTypes.FAILURE]: fetchClientsFailure,
  [LoadClientsActionTypes.SUCCESS]: fetchClientsSuccess,
  [CreateFollowerAccountActionTypes.SUCCESS]: createFollowerSuccess,
  [UpdateFollowerAccountActionTypes.SUCCESS]: updateFollowerSuccess,
  [DeleteFollowerAccountActionTypes.SUCCESS]: deleteFollowerSuccess,
  // impersonate as agent
  [ImpersonateAsAgentTypes.START]: promptImpersonateAgent,
  [ImpersonateAsAgentTypes.SUCCESS]: setImpersonateAsAgentSuccess,
  [ImpersonateAsAgentTypes.ERROR]: setImpersonateAsAgentFailure,
  [ImpersonateAsAgentTypes.NEW_DASHBOARD_SUCCESS]: setImpersonateNewDashboardSuccess,
};

export default reducerWithActionMap(actionsMap, initialState);
