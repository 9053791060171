import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  CountrySetsActionTypes,
  LoadCountriesActionTypes,
  CreateCountrySetActionTypes,
  DeleteCountrySetActionTypes,
  LoadDomainsRestActionTypes,
  DeleteDomainActionTypes,
  AssetCountrySetActionTypes,
  AssetDomainsActionTypes,
  DeleteAllAssetDomainsActionTypes,
  DeleteAssetCountrySetActionTypes,
  CreateAssetCountrySetActionTypes,
  UpdateAssetCountrySetActionTypes,
  SET_ASSET_DOMAIN_VALUE,
  ADD_ASSET_DOMAIN_INPUT,
  REMOVE_ASSET_DOMAIN_INPUT,
  RESET_RESTRICTIONS,
  SetRestDomainValueActionTypes,
  REMOVE_REST_DOMAIN_INPUT,
  RESET_COUNTRIES,
  GlobalAgeRestrictionActionTypes,
  AddGlobalAgeRestrictionActionTypes,
  DeleteGlobalAgeRestrictionActionTypes,
  AssetAgeRestrictionActionTypes,
  AddAssetAgeRestrictionActionTypes,
  DeleteAssetAgeRestrictionActionTypes,
  CreateTemplateRestrictionsActionTypes,
  UpdateTemplateRestrictionsActionTypes,
} from 'ducks/merchantDucks/restrictions/actions';
import { ADD_DOMAIN_INPUT } from 'ducks/merchantDucks/oauth/actions';
import { CreateItemActionTypes } from 'ducks/merchantDucks/items/actions';
import RestrictionsState from 'ducks/merchantDucks/restrictions/types';

export const initialState: RestrictionsState = {
  geoCollection: [],
  countries: [],
  regions: [],
  cities: [],
  domainInputs: {
    inputs: [],
    nextInput: 0,
  },
  assetCountrySet: {
    id: '',
  },
  assetDomainsInputs: {
    inputs: [],
    nextInput: 0,
  },
  isDomainChanged: false,
  ageRestriction: '',
  isAgeRestrictionEnabled: false,
  isLoading: false,
  templateRestrictions: [],
};

type RestrictionsReducer = Reducer<RestrictionsState>;

const countrySetsSucess: RestrictionsReducer = (state = initialState, { payload }) => ({
  ...state,
  geoCollection: payload ? [...Object.values(payload)] : [],
});

const loadCountriesSuccess: RestrictionsReducer = (state = initialState, { payload }) => ({
  ...state,
  countries: [...(payload.countries || [])],
  regions: [...(payload.regions || [])],
  cities: [...(payload.cities || [])],
});

const createCountrySetSuccess: RestrictionsReducer = (state = initialState, { payload }) => {
  const { geoCollection } = state;
  const newCollection = [
    ...geoCollection,
    {
      global: 0,
      id: payload.id,
      merchant_id: payload.merchantId,
      name: payload.name,
      type: payload.type,
      countries: [...(payload.countries || [])],
      regions: [...(payload.regions || [])],
      cities: [...(payload.cities || [])],
    },
  ];

  return {
    ...state,
    geoCollection: [...newCollection],
  };
};

const deleteCountrySetSuccess: RestrictionsReducer = (
  state = initialState,
  { payload: { country_set_id: countrySetId } }
) => ({
  ...state,
  geoCollection: [...state.geoCollection.filter((item) => item.id !== countrySetId)],
});

const loadDomainsRestrictionsSuccess: RestrictionsReducer = (state = initialState, { payload }) => {
  const domainsCollection = Object.values(payload);

  return {
    ...state,
    domainInputs: { inputs: [...domainsCollection], nextInput: payload.length + 1 },
  };
};

const addDomainInput: RestrictionsReducer = (state = initialState) => {
  const randomGeneratedNumber = Math.random() * (1000 - 1) + 1;
  const newInput = { key: randomGeneratedNumber, id: randomGeneratedNumber };

  return {
    ...state,
    domainInputs: {
      ...state.domainInputs,
      inputs: [...state.domainInputs.inputs, newInput],
      nextInput: state.domainInputs.nextInput + 1,
    },
    isDomainChanged: true,
  };
};

const setRestrictionsDomainValue: RestrictionsReducer = (state = initialState, { successData }) => {
  const {
    domainInputs: { inputs, nextInput },
  } = state;

  const newInputs = inputs.map((item) => {
    if (item.id === successData.id || item.id === 0) {
      return {
        ...item,
        domain: successData.domain,
        id: successData.id,
        merchantId: successData.merchant_id,
        type: successData.type,
      };
    }
    return item;
  });

  return {
    ...state,
    domainInputs: {
      ...state.domainInputs,
      inputs: [...newInputs],
      nextInput: nextInput + 1,
    },
    isDomainChanged: true,
  };
};

const deleteDomainSuccess: RestrictionsReducer = (state = initialState, { payload }) => {
  const {
    domain: { id: domainId },
  } = payload;
  const {
    domainInputs: { inputs, nextInput },
  } = state;

  return {
    ...state,
    domainInputs: {
      ...state.domainInputs,
      inputs: inputs.filter((item) => item.id !== domainId),
      nextInput: nextInput - 1,
    },
  };
};

const setAssetCountrySuccess: RestrictionsReducer = (state = initialState, { payload }) => ({
  ...state,
  assetCountrySet: payload,
});

const createAssetCountrySetSuccess: RestrictionsReducer = (state = initialState, { payload }) => ({
  ...state,
  assetCountrySet: payload,
});

const updateAssetCountrySetSuccess: RestrictionsReducer = (state = initialState, { payload }) => ({
  ...state,
  assetCountrySet: payload,
});

const assetDomainAccessTypeSuccess: RestrictionsReducer = (state = initialState, { payload }) => {
  const assetDomainsCollection = Object.values(payload);

  return {
    ...state,
    assetDomainsInputs: {
      inputs: [...assetDomainsCollection],
      nextInput: payload.length + 1,
    },
  };
};

const addAssetDomainInput: RestrictionsReducer = (state = initialState) => {
  const {
    assetDomainsInputs: { inputs, nextInput },
  } = state;
  const randomGeneratedNumber = Math.random() * (1000 - 1) + 1;
  const newInput = { key: randomGeneratedNumber, id: randomGeneratedNumber };

  return {
    ...state,
    assetDomainsInputs: {
      ...state.assetDomainsInputs,
      inputs: [...inputs, newInput],
      nextInput: nextInput + 1,
    },
    isDomainChanged: true,
  };
};

const setAssetDomainValue: RestrictionsReducer = (state = initialState, { successData }) => {
  const {
    assetDomainsInputs: { inputs, nextInput },
  } = state;
  const newInputs = inputs.map((item) => {
    if (item.id === successData.id || item.id === 0) {
      return {
        ...item,
        domain: successData.domain,
        id: successData.id,
        merchantId: successData.merchant_id,
        type: successData.type,
      };
    }
    return item;
  });

  return {
    ...state,
    assetDomainsInputs: {
      ...state.assetDomainsInputs,
      inputs: [...newInputs],
      nextInput: nextInput + 1,
    },
    isDomainChanged: true,
    isDomainUpdated: true,
  };
};

const removeRestrictionDomainInput: RestrictionsReducer = (state = initialState, { item: { id: itemId } }) => {
  const {
    domainInputs: { inputs, nextInput },
  } = state;

  return {
    ...state,
    domainInputs: {
      ...state.domainInputs,
      inputs: inputs.filter((item) => item.id !== itemId),
      nextInput: nextInput - 1,
    },
    isDomainChanged: true,
  };
};

const removeAssetDomainInput: RestrictionsReducer = (state = initialState, { item: { id: itemId } }) => {
  const {
    assetDomainsInputs: { inputs, nextInput },
  } = state;

  return {
    ...state,
    assetDomainsInputs: {
      ...state.assetDomainsInputs,
      inputs: inputs.filter((item) => item.id !== itemId),
      nextInput: nextInput - 1,
    },
    isDomainChanged: true,
  };
};

const resetRestrictions: RestrictionsReducer = (state = initialState) => ({
  ...state,
  assetCountrySet: { id: '' },
  assetDomainsInputs: {
    inputs: [],
    nextInput: 0,
  },
  isDomainChanged: false,
  isDomainUpdated: false,
});

const resetCountries: RestrictionsReducer = (state = initialState) => ({
  ...state,
  countries: [],
  regions: [],
  cities: [],
  isDomainChanged: false,
  isDomainUpdated: false,
});

const deleteAllAssetDomainsSuccess: RestrictionsReducer = (state = initialState) => ({
  ...state,
  assetDomainsInputs: {
    inputs: [],
    nextInput: 0,
  },
});

const deleteAssetCountrySetSuccess: RestrictionsReducer = (state = initialState) => ({
  ...state,
  assetCountrySet: { id: '' },
});

const loadAgeRestriction: RestrictionsReducer = (state = initialState) => ({
  ...state,
  isLoading: true,
});

const ageRestrictionSuccess: RestrictionsReducer = (
  state = initialState,
  { payload: { min_age: minAge, age_verification_enabled: enabled } }
) => ({
  ...state,
  ageRestriction: minAge,
  isAgeRestrictionEnabled: enabled == null ? true : enabled, // this checks for both null and undefined
  isLoading: false,
});

const resetAgeRestriction: RestrictionsReducer = (state = initialState) => ({
  ...state,
  ageRestriction: '',
  // This can only be called if age restriction is enabled
  isAgeRestrictionEnabled: true,
  isLoading: false,
});

const createTemplateRestrictionsStart: RestrictionsReducer = (state = initialState) => ({
  ...state,
  isLoading: true,
});

const createTemplateRestrictionsFailure: RestrictionsReducer = (state = initialState) => ({
  ...state,
  isLoading: false,
  templateRestrictions: [],
});

const createTemplateRestrictionsSuccess: RestrictionsReducer = (state = initialState, { payload }) => ({
  ...state,
  isLoading: false,
  templateRestrictions: payload,
});

const actionsMap: ActionMap<RestrictionsState> = {
  [CountrySetsActionTypes.SUCCESS]: countrySetsSucess,
  [LoadCountriesActionTypes.SUCCESS]: loadCountriesSuccess,
  [CreateCountrySetActionTypes.SUCCESS]: createCountrySetSuccess,
  [DeleteCountrySetActionTypes.SUCCESS]: deleteCountrySetSuccess,
  [LoadDomainsRestActionTypes.SUCCESS]: loadDomainsRestrictionsSuccess,
  [ADD_DOMAIN_INPUT]: addDomainInput,
  [SetRestDomainValueActionTypes.SUCCESS]: setRestrictionsDomainValue,
  [DeleteDomainActionTypes.SUCCESS]: deleteDomainSuccess,
  [AssetCountrySetActionTypes.SUCCESS]: setAssetCountrySuccess,
  [CreateAssetCountrySetActionTypes.SUCCESS]: createAssetCountrySetSuccess,
  [UpdateAssetCountrySetActionTypes.SUCCESS]: updateAssetCountrySetSuccess,
  [AssetDomainsActionTypes.SUCCESS]: assetDomainAccessTypeSuccess,
  [ADD_ASSET_DOMAIN_INPUT]: addAssetDomainInput,
  [SET_ASSET_DOMAIN_VALUE]: setAssetDomainValue,
  [REMOVE_REST_DOMAIN_INPUT]: removeRestrictionDomainInput,
  [REMOVE_ASSET_DOMAIN_INPUT]: removeAssetDomainInput,
  [RESET_RESTRICTIONS]: resetRestrictions,
  [RESET_COUNTRIES]: resetCountries,
  [DeleteAllAssetDomainsActionTypes.SUCCESS]: deleteAllAssetDomainsSuccess,
  [DeleteAssetCountrySetActionTypes.SUCCESS]: deleteAssetCountrySetSuccess,
  [GlobalAgeRestrictionActionTypes.START]: loadAgeRestriction,
  [GlobalAgeRestrictionActionTypes.SUCCESS]: ageRestrictionSuccess,
  [GlobalAgeRestrictionActionTypes.FAILURE]: resetAgeRestriction,
  [AddGlobalAgeRestrictionActionTypes.START]: loadAgeRestriction,
  [AddGlobalAgeRestrictionActionTypes.SUCCESS]: ageRestrictionSuccess,
  [AddGlobalAgeRestrictionActionTypes.FAILURE]: resetAgeRestriction,
  [DeleteGlobalAgeRestrictionActionTypes.START]: loadAgeRestriction,
  [DeleteGlobalAgeRestrictionActionTypes.SUCCESS]: resetAgeRestriction,
  [DeleteGlobalAgeRestrictionActionTypes.FAILURE]: resetAgeRestriction,
  [AssetAgeRestrictionActionTypes.SUCCESS]: ageRestrictionSuccess,
  [AssetAgeRestrictionActionTypes.FAILURE]: resetAgeRestriction,
  [AddAssetAgeRestrictionActionTypes.START]: loadAgeRestriction,
  [AddAssetAgeRestrictionActionTypes.SUCCESS]: ageRestrictionSuccess,
  [AddAssetAgeRestrictionActionTypes.FAILURE]: resetAgeRestriction,
  [DeleteAssetAgeRestrictionActionTypes.START]: loadAgeRestriction,
  [DeleteAssetAgeRestrictionActionTypes.SUCCESS]: resetAgeRestriction,
  [DeleteAssetAgeRestrictionActionTypes.FAILURE]: resetAgeRestriction,
  [CreateItemActionTypes.SUCCESS]: resetRestrictions,
  [CreateTemplateRestrictionsActionTypes.START]: createTemplateRestrictionsStart,
  [CreateTemplateRestrictionsActionTypes.SUCCESS]: createTemplateRestrictionsSuccess,
  [CreateTemplateRestrictionsActionTypes.FAILURE]: createTemplateRestrictionsFailure,
  [UpdateTemplateRestrictionsActionTypes.START]: createTemplateRestrictionsStart,
  [UpdateTemplateRestrictionsActionTypes.SUCCESS]: createTemplateRestrictionsSuccess,
  [UpdateTemplateRestrictionsActionTypes.FAILURE]: createTemplateRestrictionsFailure,
};

export default reducerWithActionMap(actionsMap, initialState);
