export const formatNumber = (number: number) => {
  // The String.prototype.replace() function accepts the following arguments:
  // 1. regular expression: (\d)(?=(\d{3})+(?!\d)) - matches a single digit \d
  // followed by a three-digit sets only (?=(\d{3})+(?!\d))
  // 2. replacement value: $1, - The matched digit is then replaced with $1,

  // The $1 is a special replacement pattern which holds a value of the first
  // parenthesized sub-match string (in our case it is the matched digit).
  // The , (comma) character is the separator character for thousands.
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export default formatNumber;
