import styled, { StyledComponent } from 'styled-components';
import { Theme } from '@inplayer-org/inplayer-ui';

interface ContentHolderProps {
  maxWidth?: string;
  margin?: string;
}

const ContentHolder: StyledComponent<'div', Theme, ContentHolderProps> = styled.div`
  width: 90%;
  margin: ${({ margin }) => margin || '1.5rem'} auto;
  position: relative;
  ${({ maxWidth }: ContentHolderProps) => maxWidth && `max-width: ${maxWidth}`};
`;

export default ContentHolder;
