import { useCallback, useMemo } from 'react';
import { useStatePiece } from 'react-pieceful-state';

// constants
import { SWITCHER_BASE, SWITCHER_REGION } from './Switcher';

const useUncontrolledSwitch = (switchId = SWITCHER_REGION) => {
  const [{ value: currentValue, meta: metaValue, initialValue, isControlled }, setValue] = useStatePiece(
    SWITCHER_BASE,
    {
      value: (undefined as unknown) as any,
      initialValue: (undefined as unknown) as any,
      isControlled: false,
      meta: (undefined as unknown) as any,
    },
    switchId
  );

  const switchTo = useCallback((value: any, meta?: any) => {
    if (isControlled) {
      console.error(`A controlled Switch cannot be switched imperatively from the ${useUncontrolledSwitch.name} hook.`);
      return;
    }

    setValue((state) => ({ ...state, value, meta }));
  }, []);

  const stateSwitcher = useCallback(
    (value: any, meta?: any) => () => {
      switchTo(value, meta);
    },
    []
  );

  const resetToDefault = useCallback(
    (meta?: any) => {
      switchTo(initialValue, meta);
    },
    [initialValue]
  );

  return useMemo(() => ({ value: currentValue, metaValue, switchTo, stateSwitcher, resetToDefault }), [
    switchTo,
    stateSwitcher,
    resetToDefault,
    currentValue,
    metaValue,
  ]);
};

export default useUncontrolledSwitch;
