import { logoutUser } from 'ducks/merchantDucks/user/actions';

export default (store) => (next) => (action) => {
  // Checks to see if the action has a payload and if the payload is an ApiError
  if (action.payload && action.payload.name === 'ApiError' && action.payload.status === 401) {
    store.dispatch(logoutUser());
  } else {
    // So the middleware doesn't get applied to every single action
    return next(action);
  }
};
