import { API } from 'constants/api';
import http, { ContentType } from 'utils/http';
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';
import { ApiAction } from 'ducks/types';

// Create new application
export enum CreateApplicationActionTypes {
  START = 'CREATE_APPLICATION_REQUEST',
  SUCCESS = 'CREATE_APPLICATION_SUCCESS',
  FAILURE = 'CREATE_APPLICATION_FAILURE',
}

export const createApplication = (
  name: string
): ApiAction<CreateApplicationActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.OAUTH;
  const data = new FormData();
  data.append('name', name);

  try {
    dispatch({ type: CreateApplicationActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.FORM_DATA,
    });
    dispatch({ type: CreateApplicationActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Application successfully added.',
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: CreateApplicationActionTypes.FAILURE });
    dispatch(
      notifRequested({
        title: 'ERROR',
        content: message,
        type: 'danger',
      })
    );
  }
};

// Update application
export enum UpdateApplicationActionTypes {
  START = 'UPDATE_APPLICATION_REQUEST',
  SUCCESS = 'UPDATE_APPLICATION_SUCCESS',
  FAILURE = 'UPDATE_APPLICATION_FAILURE',
}

export interface UpdateApplication {
  id: string | number;
  sso?: number;
  name?: string;
}

export interface SsoParams {
  sso?: number;
  name?: string;
}

export const updateApplication = ({
  id,
  sso,
  name,
}: UpdateApplication): ApiAction<UpdateApplicationActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.OAUTH_ID;
  const params: SsoParams = {};

  if (name) {
    params.name = name;
  }
  if (sso === 1 || sso === 0) {
    params.sso = sso;
  }

  try {
    dispatch({ type: UpdateApplicationActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        id,
      },
      data: params,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: UpdateApplicationActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Appication successfully updated.',
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: UpdateApplicationActionTypes.FAILURE });
    dispatch(
      notifRequested({
        title: 'ERROR',
        content: message,
        type: 'danger',
      })
    );
  }
};

// Get secret key to application
export enum ApplicationSecretActionTypes {
  START = 'SECRET_KEY_REQUEST',
  SUCCESS = 'SECRET_KEY_SUCCESS',
  FAILURE = 'SECRET_KEY_FAILURE',
}

export const loadApplicationSecret = (id: string | number): ApiAction<ApplicationSecretActionTypes> => async (
  dispatch
) => {
  const endpoint = API.OAUTH_SECRET;

  try {
    dispatch({ type: ApplicationSecretActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        id,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: ApplicationSecretActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: ApplicationSecretActionTypes.FAILURE });
  }
};

// Load all added application
export enum ApplicationsActionTypes {
  START = 'APPLICATIONS_REQUEST',
  SUCCESS = 'APPLICATIONS_SUCCESS',
  FAILURE = 'APPLICATIONS_FAILURE',
}

export const loadApplications = (): ApiAction<ApplicationsActionTypes> => async (dispatch) => {
  const endpoint = API.OAUTH;

  try {
    dispatch({ type: ApplicationsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {});
    dispatch({ type: ApplicationsActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: ApplicationsActionTypes.FAILURE });
  }
};

// Remove application - TO BE USED in feature
export enum RemoveApplicationActionTypes {
  START = 'DELETE_APPLICATION_REQUEST',
  SUCCESS = 'DELETE_APPLICATION_SUCCESS',
  FAILURE = 'DELETE_APPLICATION_FAILURE',
}

export const removeApplication = (
  id: string | number
): ApiAction<RemoveApplicationActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.OAUTH_ID;

  try {
    dispatch({ type: RemoveApplicationActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        id,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: RemoveApplicationActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Application successfully deleted.',
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: RemoveApplicationActionTypes.FAILURE });
    dispatch(
      notifRequested({
        title: 'ERROR',
        content: message,
        type: 'danger',
      })
    );
  }
};

// DOMAINS
export enum AddDomainsActionTypes {
  START = 'ADD_DOMAINS_REQUEST',
  SUCCESS = 'ADD_DOMAINS_SUCCESS',
  FAILURE = 'ADD_DOMAINS_FAILURE',
}

export const addApplicationDomains = (
  subDomainName: string,
  allowedOrigins: Array<any>
): ApiAction<AddDomainsActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.DOMAINS;
  const params: any = {};

  if (subDomainName) {
    params.sub_domain_name = subDomainName;
    if (allowedOrigins.length !== 0) {
      params.allowed_origins = allowedOrigins;
    }
  } else if (allowedOrigins.length !== 0) {
    params.allowed_origins = allowedOrigins;
  } else {
    params.allowed_origins = [''];
  }

  try {
    dispatch({ type: AddDomainsActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      data: params,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: AddDomainsActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Domains successfully updated.',
        type: 'success',
      })
    );
  } catch (e) {
    const {
      response: {
        data: { message },
      },
    } = e;
    dispatch({ type: AddDomainsActionTypes.FAILURE });
    dispatch(
      notifRequested({
        title: 'ERROR',
        content: message,
        type: 'danger',
      })
    );
  }
};

// Load domain name and domains
export enum LoadDomainsActionTypes {
  START = 'DOMAINS_REQUEST',
  SUCCESS = 'DOMAINS_SUCCESS',
  FAILURE = 'DOMAINS_FAILURE',
}

export const loadDomains = (): ApiAction<LoadDomainsActionTypes> => async (dispatch) => {
  const endpoint = API.DOMAINS;

  try {
    dispatch({ type: LoadDomainsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {});
    dispatch({ type: LoadDomainsActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: LoadDomainsActionTypes.FAILURE });
  }
};

export const ADD_DOMAIN_INPUT = 'ADD_DOMAIN_INPUT';

export const addDomainInput = (input: any, isInitial = false) => ({
  type: ADD_DOMAIN_INPUT,
  payload: {
    input,
  },
  successData: {
    isInitial,
  },
});
