import { useSelector } from 'react-redux';
import { convertArrayToCamelCase, transformSearchParams } from 'utils/helpers';
import { TableRowData } from '@inplayer-org/inplayer-ui';
import {
  getTotalCount,
  getIsFetchingPaginatedData,
  getItemsOnPage,
  getIsRefundInProgress,
} from 'ducks/adminDucks/paginations/selectors';
import { ResultKeys, DataItemsType } from 'ducks/adminDucks/paginations/types';
import { useState } from 'react';
import { FetchPaginatedDataQueryParams } from 'ducks/adminDucks/paginations/actions';
import { getReportFields } from 'ducks/merchantDucks/reports/selectors';
import { getUserApplications } from 'ducks/merchantDucks/audience/selectors';

export enum FilterNames {
  SEARCH = 'search',
  FILTER = 'filter',
}

export const useAdminItemsState = <T extends DataItemsType, U extends TableRowData>(
  page: number,
  resultKey: ResultKeys
) => {
  const adminData = useSelector(getItemsOnPage<Array<T>>(page, resultKey));
  const totalItemsCount = useSelector(getTotalCount(resultKey));
  const isFetching = useSelector(getIsFetchingPaginatedData(resultKey));
  const isRefundInProgress = useSelector(getIsRefundInProgress(resultKey));

  const camelCaseData = convertArrayToCamelCase<T, U>(adminData);

  return { itemsData: camelCaseData, totalItemsCount, isFetching, isRefundInProgress };
};

export const useFilter = (
  onFilterChanged: (params: FetchPaginatedDataQueryParams, isFilterChanged: boolean) => void,
  params: FetchPaginatedDataQueryParams,
  searchParamName: FilterNames
): [number, (page: number) => void, Array<string>, (searchParams: Record<string, any>) => void] => {
  const [page, setPage] = useState(1);
  const [filterTags, setFilterTags] = useState<Array<string>>([]);

  const handleFilterChange = (searchParams: Record<string, any>) => {
    const transformedParams = transformSearchParams(searchParams);

    onFilterChanged({ ...params, [searchParamName]: transformedParams }, true);
    setFilterTags(transformedParams);
    setPage(1);
  };

  return [page, setPage, filterTags, handleFilterChange];
};

export const useReportState = () => {
  const reportFields = useSelector(getReportFields);
  const userApplications = useSelector(getUserApplications);

  return { reportFields, userApplications };
};
