import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Formik, FormikHelpers, Field, FormikProps } from 'formik';
import { object, string, number } from 'yup';

// actions
import { setPaymentFees } from 'ducks/adminDucks/merchants/actions';

// selectors
import { getPaymentFees, getPaymentSetup } from 'ducks/adminDucks/merchants/selectors';

// types
import { accessTypeNames } from 'components/PaywallPreviews/PaywallElements/PriceCard';
import { PaymentGateway, RevenueSharesItem } from 'ducks/adminDucks/merchants/types';

// styled components
import { CancelButton } from 'components/SharedStyledElements';

// components
import { Button, Grid, Tabs, Typography } from '@inplayer-org/inplayer-ui';
import FieldError from 'components/Fields/FieldError';
import StyledContainer from 'components/StyledContainer';
import Select from 'components/Fields/Select';
import Input from 'components/Fields/Input';
import Loader from 'components/Loader';

const { Container, Cell } = Grid;

const InfoSection = styled(Typography)`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export enum TabsIndexes {
  SUBSCRIPTION = 0,
  ONETIME = 1,
}

export enum Currencies {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
}

export enum currenciesMapping {
  EUR = 1,
  USD = 2,
  GBP = 3,
}

interface FormValues {
  currency: string;
  fixedFee: number;
  sharePercent: number;
}

interface OwnProps {
  handleCloseModal: () => void;
  canEditPaymentGateways: boolean;
  hasInheritance: boolean;
  selectedGateway: string;
}

type Props = OwnProps;

const validationSchema = object().shape({
  currency: string().required('*Required'),
  fixedFee: number()
    .min(0, 'Fixed fee must be greater than or equal to 0')
    .required('*Required')
    .typeError('The fixes fee must be a number.'),
  sharePercent: number()
    .min(0, 'Share percent must be greater than or equal to 0')
    .required('*Required')
    .typeError('The share percent must be a number.'),
});

const currencies = [
  { value: Currencies.EUR, displayName: Currencies.EUR },
  { value: Currencies.USD, displayName: Currencies.USD },
  { value: Currencies.GBP, displayName: Currencies.GBP },
];

const tabs: Array<{ name: string }> = [{ name: 'Subscription' }, { name: 'One-Time' }];
const paymentTypes = ['subscription', 'ppv'];

const PaymentFeesForm: FunctionComponent<Props> = ({
  handleCloseModal,
  canEditPaymentGateways,
  hasInheritance,
  selectedGateway,
}: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const merchantId = Number(id);

  const [activeTab, setActiveTab] = useState(TabsIndexes.ONETIME);

  const { defaultRevenueShare, accountRevenueShares, isFetching: isPaymentFeesFetching } = useSelector(getPaymentFees);
  const { paymentMethods, currentPaymentMethodsCollection } = useSelector(getPaymentSetup);

  const { isFetching: isPaymentMethodsFetching } = paymentMethods;
  const paymentType = activeTab === TabsIndexes.SUBSCRIPTION ? accessTypeNames.SUBSCRIPTION : accessTypeNames.PPV;
  const shares = defaultRevenueShare
    ? accountRevenueShares.find((share: RevenueSharesItem) => share.payment_type === paymentType) || defaultRevenueShare
    : null;
  const isDefaultFee =
    shares &&
    shares.fixed_fee === defaultRevenueShare.fixed_fee &&
    shares.share_percent === defaultRevenueShare.share_percent;

  const currencyValue = shares ? shares.fixed_fee_currency : defaultRevenueShare.fixed_fee_currency;
  const fixedFeeValue = shares ? shares.fixed_fee : 0;
  const sharePercentValue = shares ? shares.share_percent : 0;
  const initialValues = {
    currency: currencyValue,
    fixedFee: fixedFeeValue,
    sharePercent: sharePercentValue,
  };

  const handleSetActiveTab = (resetForm: () => void) => (tabIndex: number) => {
    setActiveTab(tabIndex);
    resetForm();
  };

  const getPaymentProviderId = () => {
    const paymentProvider = currentPaymentMethodsCollection.find(
      (method: PaymentGateway) => method.name === selectedGateway
    );

    return paymentProvider.account_payment_provider_id || 0;
  };

  const handleReset = () => {
    const accountPaymentProviderId = getPaymentProviderId();

    dispatch(
      setPaymentFees({
        merchantId,
        sharePercent: defaultRevenueShare.share_percent,
        fixedFee: defaultRevenueShare.fixed_fee,
        isSet: true,
        accountPaymentProviderId,
        paymentType: paymentTypes[activeTab],
        currency: defaultRevenueShare.fixed_fee_currency,
      })
    );
  };

  const onFormSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    const { sharePercent, fixedFee, currency } = values;
    const accountPaymentProviderId = getPaymentProviderId();
    const accountRevenue = accountRevenueShares.find((share) => share.payment_type === paymentTypes[activeTab]);

    try {
      await dispatch(
        setPaymentFees({
          merchantId,
          sharePercent,
          fixedFee,
          isSet: !!accountRevenue,
          accountPaymentProviderId,
          paymentType: paymentTypes[activeTab],
          currency,
        })
      );
      resetForm();
      setSubmitting(false);
    } catch (e) {
      resetForm();
      setSubmitting(false);
    }
  };

  const handleClose = (resetForm: () => void) => () => {
    resetForm();
    handleCloseModal();
  };

  if (isPaymentMethodsFetching || isPaymentFeesFetching) {
    return <Loader position="absolute" />;
  }

  return (
    <Formik<FormValues>
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, isSubmitting, resetForm, isValid, dirty }: FormikProps<FormValues>) => {
        const disableSubmitButton = !isValid || isSubmitting || !dirty;
        const showActionButtons = canEditPaymentGateways && !hasInheritance;
        const showResetButton = !isDefaultFee;
        const infoSectionText = `${activeTab === TabsIndexes.SUBSCRIPTION ? 'Subscription' : 'One-Time'} revenue setup`;

        return (
          <form onSubmit={handleSubmit}>
            <Container alignContent="space-around" columns={1}>
              <Cell>
                <Tabs tabs={tabs} selectedTabIndex={activeTab} onTabClick={handleSetActiveTab(resetForm)} />
              </Cell>
              <Cell>
                <InfoSection variant="p">{infoSectionText}</InfoSection>
              </Cell>
              <Cell>
                <Field
                  name="currency"
                  label="Currency"
                  component={Select}
                  defaultOption={{ displayName: 'Select currency' }}
                  options={currencies}
                  value={currencyValue}
                />
                <FieldError name="currency" />
              </Cell>
              <Cell>
                <Field name="fixedFee" type="text" component={Input} label="Fixed fee" disabled={hasInheritance} />
                <FieldError name="fixedFee" />
              </Cell>
              <Cell>
                <Field name="sharePercent" type="text" component={Input} label="Share (%)" disabled={hasInheritance} />
                <FieldError name="sharePercent" />
              </Cell>
            </Container>
            <StyledContainer columns={3} margin="2rem 0 0 0">
              <Cell middle left={2}>
                <CancelButton type="button" fullWidth onClick={handleClose(resetForm)}>
                  Cancel
                </CancelButton>
              </Cell>
              {showActionButtons && (
                <>
                  <Cell middle left={3}>
                    <Button type="submit" fullWidth buttonModifiers={['buttonPrimary']} disabled={disableSubmitButton}>
                      Update Fees
                    </Button>
                  </Cell>
                  {showResetButton && (
                    <Cell middle left={4}>
                      <Button type="button" onClick={handleReset}>
                        Reset To Default
                      </Button>
                    </Cell>
                  )}
                </>
              )}
            </StyledContainer>
          </form>
        );
      }}
    </Formik>
  );
};

export default PaymentFeesForm;
