import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  UnsubscribeActionTypes,
  SubscriptionDetailsActionTypes,
  RESET_SUBSCRIPTION_DETAILS,
  SubscriptionActionTypes,
  SubscriptionFilterTypes,
  SubscriptionsStatusActionTypes,
  SubscriptionCountriesMapActionTypes,
  SubscriptionsByStatusActionTypes,
} from 'ducks/merchantDucks/subscriptions/actions';
import { RESET_FORM } from 'ducks/merchantDucks/form/actions';
import { LoadSubscriptionsActionTypes } from 'ducks/merchantDucks/reports/actions';
import SubscriptionState, { SubscriptionFilterDetails } from 'ducks/merchantDucks/subscriptions/types';

// countries
import countries, { CountryGeoData } from 'data/countries';
import { SubscriptionStatusType } from 'pages/MerchantPages/Analytics/components/ActiveSubscriptions';

export const initialState: SubscriptionState = {
  collection: [],
  total: 0,
  page: 0,
  limit: 15,
  isFetching: false,
  isProcessing: false,
  success: false,
  allActiveSubscriptions: [],
  groupedActiveSubscriptions: [],
  allCountriesWithSubscriptions: [],
  cancelledSubscriptions: [],
  subscriptionDetails: {
    isFetching: false,
    subscription_details: null,
    subscription_history: [],
    total: 0,
    page: 0,
    size: 5,
  },
  totalSubscriptionsStatuses: {
    isFetching: false,
    statuses: {},
  },
  activeSubscriptions: {
    price: {
      isFetching: false,
      collection: [],
    },
    item: {
      isFetching: false,
      collection: [],
    },
  },
  canceledSubscriptions: {
    price: {
      isFetching: false,
      collection: [],
    },
    item: {
      isFetching: false,
      collection: [],
    },
  },
  countriesMapData: {
    isFetching: false,
    collection: [],
  },
};

type SubscriptionReducer = Reducer<SubscriptionState>;

const promptUnsubscribe: SubscriptionReducer = (state = initialState) => ({
  ...state,
  isProcessing: true,
});

const unsubscribeFailure: SubscriptionReducer = (state = initialState) => ({
  ...state,
  isProcessing: false,
  success: false,
});

const unsubscribeSuccess: SubscriptionReducer = (state = initialState, { payload }) => ({
  ...state,
  ...payload,
  isProcessing: false,
  success: true,
});

const promptSubscribe: SubscriptionReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const subscribeSuccess: SubscriptionReducer = (state = initialState, { payload }) => ({
  ...state,
  ...payload,
  isFetching: false,
});

const subscribeFailure: SubscriptionReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const subscriptionDetailsStart: SubscriptionReducer = (state = initialState) => ({
  ...state,
  subscriptionDetails: {
    ...state.subscriptionDetails,
    isFetching: true,
  },
});

const subscriptionDetailsSuccess: SubscriptionReducer = (state = initialState, { payload }) => ({
  ...state,
  subscriptionDetails: {
    ...state.subscriptionDetails,
    isFetching: false,
    ...payload,
  },
});

const subsciptionDetailsFailure: SubscriptionReducer = (state = initialState) => ({
  ...state,
  subscriptionDetails: {
    ...state.subscriptionDetails,
    isFetching: false,
  },
});

const resetSubscriptionDetails: SubscriptionReducer = (state = initialState) => ({
  ...state,
  subscriptionDetails: initialState.subscriptionDetails,
});

const fetchSubscriptionsSuccess: SubscriptionReducer = (state = initialState, { payload: { type, data } }) => {
  if (type !== SubscriptionFilterTypes.COUNTRY) {
    return {
      ...state,
      [type]: [data],
    };
  }

  // get country geo data
  const countriesWithGeoData = data.map((d: SubscriptionFilterDetails) => {
    return {
      ...d,
      geoData: countries.find((c: CountryGeoData) => c.code === d.country_code),
    };
  });

  return { ...state, [type]: countriesWithGeoData, isFetching: false };
};

const fetchSubscriptionsFailure: SubscriptionReducer = (state = initialState, { payload: { type } }) => ({
  ...state,
  [type]: [],
  isFetching: false,
});

const fetchSubscriptionsStart: SubscriptionReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

// subs statuses - totals
const promptFetchSubcriptionsStatuses: SubscriptionReducer = (state = initialState) => ({
  ...state,
  totalSubscriptionsStatuses: {
    ...state.totalSubscriptionsStatuses,
    isFetching: true,
  },
});

const fetchSubscriptionsStatusesSuccess: SubscriptionReducer = (state = initialState, { payload }) => ({
  ...state,
  totalSubscriptionsStatuses: {
    isFetching: false,
    statuses: { ...payload },
  },
});

const fetchSubscriptionsStatusesFailure: SubscriptionReducer = (state = initialState) => ({
  ...state,
  totalSubscriptionsStatuses: {
    ...state.totalSubscriptionsStatuses,
    isFetching: false,
  },
});

enum SubscriptionsGroupedBy {
  PRICE = 'price',
  ITEM = 'item',
}

enum ActiveCanceled {
  ACTIVE = 'activeSubscriptions',
  CANCELED = 'canceledSubscriptions',
}

// subs statuses (active)
const propmtFetchActiveSubscriptions: SubscriptionReducer = (state = initialState, { payload }) => {
  const groupedBy: SubscriptionsGroupedBy = payload.groupBy;
  const activeOrDisabledName: ActiveCanceled =
    payload.status === SubscriptionStatusType.ACTIVE ? ActiveCanceled.ACTIVE : ActiveCanceled.CANCELED;

  return {
    ...state,
    [activeOrDisabledName]: {
      ...state[activeOrDisabledName],
      [groupedBy]: {
        ...state[activeOrDisabledName][groupedBy],
        isFetching: true,
      },
    },
  };
};

const fetchActiveSubscriptionsSuccess: SubscriptionReducer = (state = initialState, { payload }) => {
  const subscriptionsByItem: SubscriptionsGroupedBy = payload.groupBy;
  const activeOrDisabledName: ActiveCanceled =
    payload.status === SubscriptionStatusType.ACTIVE ? ActiveCanceled.ACTIVE : ActiveCanceled.CANCELED;
  return {
    ...state,
    [activeOrDisabledName]: {
      ...state[activeOrDisabledName],
      [subscriptionsByItem]: {
        collection: payload.collection,
        isFetching: false,
      },
    },
  };
};

const fetchActiveSubscriptionsFailure: SubscriptionReducer = (state = initialState, { payload }) => {
  const groupedBy: SubscriptionsGroupedBy = payload.groupBy;
  const activeOrDisabledName: ActiveCanceled =
    payload.status === SubscriptionStatusType.ACTIVE ? ActiveCanceled.ACTIVE : ActiveCanceled.CANCELED;

  return {
    ...state,
    [activeOrDisabledName]: {
      ...state[activeOrDisabledName],
      [groupedBy]: {
        ...state[activeOrDisabledName][groupedBy],
        isFetching: false,
      },
    },
  };
};

// subs countries - maps
const promptFetchSubcriptionsCountries: SubscriptionReducer = (state = initialState) => ({
  ...state,
  countriesMapData: {
    ...state.countriesMapData,
    isFetching: true,
  },
});

const fetchSubscriptionsCountriesSuccess: SubscriptionReducer = (state = initialState, { payload }) => ({
  ...state,
  countriesMapData: {
    isFetching: false,
    collection: payload,
  },
});

const fetchSubscriptionsCountriesFailure: SubscriptionReducer = (state = initialState) => ({
  ...state,
  countriesMapData: {
    ...state.countriesMapData,
    isFetching: false,
  },
});

const actionsMap: ActionMap<SubscriptionState> = {
  [UnsubscribeActionTypes.START]: promptUnsubscribe,
  [UnsubscribeActionTypes.FAILURE]: unsubscribeFailure,
  [UnsubscribeActionTypes.SUCCESS]: unsubscribeSuccess,
  [RESET_FORM]: () => initialState,
  [LoadSubscriptionsActionTypes.START]: promptSubscribe,
  [LoadSubscriptionsActionTypes.ERROR]: subscribeFailure,
  [LoadSubscriptionsActionTypes.SUCCESS]: subscribeSuccess,
  [SubscriptionDetailsActionTypes.START]: subscriptionDetailsStart,
  [SubscriptionDetailsActionTypes.SUCCESS]: subscriptionDetailsSuccess,
  [SubscriptionDetailsActionTypes.FAILURE]: subsciptionDetailsFailure,
  [RESET_SUBSCRIPTION_DETAILS]: resetSubscriptionDetails,
  [SubscriptionActionTypes.START]: fetchSubscriptionsStart,
  [SubscriptionActionTypes.SUCCESS]: fetchSubscriptionsSuccess,
  [SubscriptionActionTypes.FAILURE]: fetchSubscriptionsFailure,
  // new subs statuses endpoint
  [SubscriptionsStatusActionTypes.START]: promptFetchSubcriptionsStatuses,
  [SubscriptionsStatusActionTypes.SUCCESS]: fetchSubscriptionsStatusesSuccess,
  [SubscriptionsStatusActionTypes.FAILURE]: fetchSubscriptionsStatusesFailure,
  // active/canceled subs
  [SubscriptionsByStatusActionTypes.START]: propmtFetchActiveSubscriptions,
  [SubscriptionsByStatusActionTypes.SUCCESS]: fetchActiveSubscriptionsSuccess,
  [SubscriptionsByStatusActionTypes.FAILURE]: fetchActiveSubscriptionsFailure,
  // new subs countries (map) endpoint
  [SubscriptionCountriesMapActionTypes.START]: promptFetchSubcriptionsCountries,
  [SubscriptionCountriesMapActionTypes.SUCCESS]: fetchSubscriptionsCountriesSuccess,
  [SubscriptionCountriesMapActionTypes.FAILURE]: fetchSubscriptionsCountriesFailure,
};

export default reducerWithActionMap(actionsMap, initialState);
