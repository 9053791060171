import { Action } from 'redux';
import { ModalNames } from 'ducks/merchantDucks/modal/types';

// External account details
export enum ModalActionTypes {
  OPEN = 'OPEN_MODAL',
  CLOSE = 'CLOSE_MODAL',
}

export const openModal = (
  modalName: ModalNames,
  instance?: string
): Action<ModalActionTypes> & { payload: { modalName: ModalNames; instance?: string } } => {
  return { type: ModalActionTypes.OPEN, payload: { modalName, instance } };
};

export const closeModal = (): Action<ModalActionTypes> => {
  return { type: ModalActionTypes.CLOSE };
};
