import React, { FunctionComponent } from 'react';

// types
import { ModalNames } from 'ducks/merchantDucks/modal/types';
import { InAppTypes } from 'pages/AdminPages/AdminMerchants/PaymentManagement/components/PaymentSetup';

// components
import BaseModal, { ModalActions } from 'components/Modals/BaseModal';
import GoogleInAppForm from 'pages/AdminPages/AdminMerchants/PaymentManagement/components/forms/GoogleInAppForm';
import AppleInAppForm from 'pages/AdminPages/AdminMerchants/PaymentManagement/components/forms/AppleInAppForm';

interface OwnProps {
  inAppType: string;
}

type Props = OwnProps;

const GoogleInAppModal: FunctionComponent<Props> = ({ inAppType }: Props) => {
  const modalTitle = inAppType === InAppTypes.GOOGLE ? 'Google' : 'Apple  in-app';
  return (
    <BaseModal
      isOverflowVisible
      title={`${modalTitle} settings`}
      modalName={ModalNames.IntegrationAppModal}
      render={({ closeModal }: ModalActions) => {
        if (inAppType === InAppTypes.GOOGLE) {
          return <GoogleInAppForm handleCloseModal={closeModal} />;
        }

        return <AppleInAppForm handleCloseModal={closeModal} />;
      }}
    />
  );
};

export default GoogleInAppModal;
