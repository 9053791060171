import React, { PureComponent } from 'react';
import { Loader, Colors } from '@inplayer-org/inplayer-ui';

// images
import subscriptionIcon from 'assets/pics/icons/subscriptions.svg';

// utils
import formatNumber from 'utils/formatNumber';

// types
import { ReportInformation } from 'ducks/merchantDucks/reports/types';
import { FetchReportParams, LoadReportsCommonParams } from 'ducks/merchantDucks/reports/actions';
import { SubscriptionParams, SubscriptionFilterTypes } from 'ducks/merchantDucks/subscriptions/actions';

// components
import AnalyticsInfoBox from 'pages/MerchantPages/Analytics/components/AnalyticsInfoBox';

export const SUBSCRIPTIONS = 4;

interface Props {
  reportInformation: ReportInformation;
  active: boolean;
  startDate: string | number;
  endDate: string | number;
  interval: string;
  lineChart: boolean;
  color: string;
  loadReportInformations: (params: LoadReportsCommonParams) => any;
  loadRevenuePerCurrency: (params: LoadReportsCommonParams) => any;
  fetchReport: (params: FetchReportParams) => any;
  handleIconClick: (icon: number) => any;
  fetchSubscriptions: (type: SubscriptionFilterTypes, params?: SubscriptionParams) => any;
  subscriptionsStatus: any;
}

export class SubscriptionsInfoBox extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { active: prevActive, startDate: prevStartDate, endDate: prevEndDate } = prevProps;
    const {
      fetchReport,
      loadReportInformations,
      loadRevenuePerCurrency,
      active,
      startDate,
      endDate,
      interval,
      lineChart,
      fetchSubscriptions,
      subscriptionsStatus,
    } = this.props;

    if (active && (prevActive !== active || prevStartDate !== startDate || prevEndDate !== endDate)) {
      fetchReport({ icon: SUBSCRIPTIONS, startDate, endDate, interval, lineChart });
      const params = { startDate, endDate };
      loadReportInformations(params);
      loadRevenuePerCurrency(params);
      // fetchSubscriptions(SubscriptionFilterTypes.ALL);
      // uncomment when endpoint ready
      // subscriptionsStatus(startDate, endDate);
    }
  }

  handleIconClick = () => {
    const { handleIconClick } = this.props;
    handleIconClick(SUBSCRIPTIONS);
  };

  renderContent = () => {
    const {
      reportInformation: { numberOfCanceledSubscriptions, numberOfCreatedSubscriptions, isFetching },
    } = this.props;

    if (isFetching) {
      return <Loader color={Colors.white} />;
    }

    return (
      <div>
        <h6>{formatNumber(numberOfCreatedSubscriptions.total)} new</h6>
        <h6>{formatNumber(numberOfCanceledSubscriptions.total)} canceled</h6>
      </div>
    );
  };

  render() {
    const { active, color } = this.props;
    return (
      <AnalyticsInfoBox
        title="Subscriptions"
        icon={subscriptionIcon}
        active={active}
        color={color}
        handleIconClick={this.handleIconClick}
        renderContent={this.renderContent}
      />
    );
  }
}

export default SubscriptionsInfoBox;
