enum Rules {
  DISABLE_ORGANISATION_SECTION = 'disable-organisation-section',
  DISABLE_TRANSACTIONS_SECTION = 'disable-transactions-section',
  DISABLE_AUDIENCE_SECTION = 'disable-audience-section',
  DISABLE_ASSET_ACCESS_SECTION = 'disable-asset-access-section',
  DISABLE_ANALYTICS_SECTION = 'disable-analytics-section',
  DISABLE_RELATE_SECTION = 'disable-relate-section',
  DISABLE_BILLING_AND_PLAN_SECTION = 'disable-billing-and-plan-section',
  DISABLE_WEBHOOKS_SECTION = 'disable-webhooks-section',
  DISABLE_ASSETS_SECTION = 'disable-assets-section',
  DISABLE_SETTINGS_SECTION = 'disable-settings-section',
  DISABLE_SEGMENTATION_SECTION = 'disable-segmentation-section',
  DISABLE_SEGMENTATION_REPORTS_SECTION = 'disable-segmentation-reports-section',
  DISABLE_ALL_REPORTS_SECTIONS = 'disable-all-reports-sections',
  DISABLE_ADD_NEW_VOUCHER_SECTION = 'disable-add-new-voucher-section',
  DISABLE_ADD_NEW_BRANDING_THEME_SECTION = 'disable-add-new-branding-theme-section',
  DISABLE_DEACTIVATING_CUSTOMER_ACCOUNT = 'disable-deactivating-customer-account',
  DISABLE_DELETING_CUSTOMER_ACCOUNT = 'disable-deleting-customer-account',
  READONLY_CUSTOMER_DETAILS_AND_METADATA = 'readonly-customer-details-and-metadata',
  READONLY_PAYMENTS_SUBSCRIPTIONS_AND_ACCESSES = 'readonly-payments-subscriptions-and-accesses',
  READONLY_ASSETS_AND_PACKAGES = 'readonly-assets-and-packages',
  READONLY_VOUCHERS = 'readonly-vouchers',
  READONLY_SOCIAL_AUTH_SETUP = 'readonly-social-auth-setup',
  READONLY_OAUTH_AND_SSO_SETUP = 'readonly-oauth-and-sso-setup',
  READONLY_CONCURRENT_SESSIONS = 'readonly-concurrent-sessions',
  READONLY_GEO_RESTRICTIONS = 'readonly-geo-restrictions',
  READONLY_DOMAIN_RESTRICTIONS = 'readonly-domain-restrictions',
  READONLY_CUSTOM_FIELDS = 'readonly-custom-fields',
  READONLY_AGE_RESTRICTIONS = 'readonly-age-restrictions',
  READONLY_BRANDING_THEMES = 'readonly-branding-themes',
  READONLY_OVP_INTEGRATIONS = 'readonly-ovp-integrations',
  READONLY_IN_APP_INTEGRATIONS = 'readonly-in-app-integrations',
  HIDE_ASSETS_SECTION_TOP_STATS = 'hide-assets-section-top-stats',
  HIDE_VOUCHERS_SECTION_TOP_STATS = 'hide-vouchers-section-top-stats',
  HIDE_AUDIENCE_SECTION_TOP_STATS = 'hide-audience-section-top-stats',
  DISABLE_LANDING_PAGE_BUILDR_SECTION = 'disable-landing-page-builder-section',
  DISABLE_EMAIL_TEMPLATES_SECTION = 'disable-email-templates-section',
}

export default Rules;
