import React, { FunctionComponent } from 'react';
import { Typography, Colors, ContainerProps, Theme } from '@inplayer-org/inplayer-ui';
import styled, { css, StyledComponent } from 'styled-components';
import { ifProp } from 'styled-tools';
import moment from 'moment';
import { FaRegImages, FaRegImage } from 'react-icons/fa';
import { AiOutlineSync } from 'react-icons/ai';

// types
import { AccessType, CurrentPhase } from 'ducks/merchantDucks/items/types';
import { ItemType } from 'ducks/merchantDucks/items/actions';

const PriceCardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3px;
  border: 1px solid ${Colors.gray};
  margin-bottom: 1rem;
`;
const IconContainer = styled.div`
  border-right: 1px solid ${Colors.gray};
  height: 73px;
  display: flex;
  align-items: center;
  width: 35px;
  color: ${Colors.darkGray};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const PriceInformation = styled.div`
  padding: 8px;
`;

const Description = styled(Typography)`
  font-size: 12px;
  color: ${Colors.fontDarkGray};
`;

const IconDiv: StyledComponent<'div', Theme, ContainerProps & { displayTopBorder?: boolean }> = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem;

  ${ifProp(
    'displayTopBorder',
    css`
      border-top: 1px solid ${Colors.gray};
    `
  )}
`;

interface Props {
  currency: string;
  description: string;
  contentType: string;
  amount: number;
  accessType: AccessType;
  expiresAt: number;
  currentPhase: CurrentPhase;
}

export enum accessTypeNames {
  PPV = 'ppv',
  PPVCUSTOM = 'ppv_custom',
  SUBSCRIPTION = 'subscription',
  SEASON = 'season',
}

const PriceCard: FunctionComponent<Props> = ({
  currency,
  description,
  contentType,
  amount,
  accessType,
  expiresAt,
  currentPhase,
}) => {
  const { name, quantity, period } = accessType;
  const isPackagePrice = contentType === ItemType.Package;
  const isSubscription = name === accessTypeNames.SUBSCRIPTION;
  const isSeason = name === accessTypeNames.SEASON;
  const isPpv = name === accessTypeNames.PPV;
  const isPpvCustom = name === accessTypeNames.PPVCUSTOM;

  let priceAmount = amount;
  let priceCurrency = currency;

  if (isSeason && currentPhase) {
    const { current_price: currentPrice, currency: currentCurrency } = currentPhase;
    priceAmount = currentPrice;
    priceCurrency = currentCurrency;
  }

  const getAccessTypeInfo = () => {
    let accessTypeInfo = '';

    if (name === 'subscription') {
      accessTypeInfo = `/ ${quantity !== 1 ? quantity : ''} ${period}`;
    }
    if (name === 'season') {
      accessTypeInfo = '/ season';
    }

    return accessTypeInfo;
  };

  return (
    <PriceCardContainer>
      <IconContainer>
        <IconDiv>{isPackagePrice ? <FaRegImages /> : <FaRegImage />}</IconDiv>
        {isSubscription && (
          <IconDiv displayTopBorder>
            <AiOutlineSync />
          </IconDiv>
        )}
      </IconContainer>
      <PriceInformation>
        <Typography variant="p">
          {priceAmount} {priceCurrency} {getAccessTypeInfo()}
        </Typography>
        <Description variant="p">{description}</Description>
        {isPpv && quantity && period && (
          <Description variant="p">
            {quantity}-{period.charAt(0).toUpperCase() + period.slice(1)} Access
          </Description>
        )}
        {isPpvCustom && (
          <Description variant="p">
            Access until&nbsp;
            {moment(expiresAt * 1000)
              .utc()
              .format('DD/MM/YYYY HH:mm')}
            UTC
          </Description>
        )}
        {(isSubscription || isSeason) && (
          <Description variant="p">*recurring {isSeason && 'season'} payment</Description>
        )}
      </PriceInformation>
    </PriceCardContainer>
  );
};

export default PriceCard;
