import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  FetchAdminMerchantDetailsActionTypes,
  SubscriptionAnalyticsActionTypes,
  AccountingAnalyticsActionTypes,
  ResetAnalyticsStateType,
  EnableMasterRoleActionTypes,
  DisableMasterRoleActionTypes,
  FetchPaymentProvidersActionTypes,
  LoadSingleFeeActionTypes,
  SetProviderActionTypes,
  SetUpdateFeesActionTypes,
  SetAgeRestrictionActionTypes,
  GetAgeRestrictionActionTypes,
  LoadDefaultFeeActionTypes,
  FetchMerchantFeaturesActionTypes,
  DisconnectStripeActionTypes,
  ApprovedForConnectActionTypes,
  FetchAccessFeeTypesActionTypes,
  UpdateRevenueSetupTypes,
  GetRevenueSetupTypes,
  UpdateRevenueShareTypes,
  GetRecurringFeesTypes,
  SetRecurringFeesTypes,
  GetNonRecurringFeesType,
  AddNonRecurringFeeType,
  DeleteNonRecurringFeeType,
} from 'ducks/adminDucks/merchants/actions';
import map from 'lodash/map';
import find from 'lodash/find';
import fromPairs from 'lodash/fromPairs';
import { Roles } from 'ducks/merchantDucks/user/types';
import { AdminMerchantDetails, ProviderItem, PaymentMethodKeyItem, RevenueSharesItem, Feature } from './types';

export const initialState: AdminMerchantDetails = {
  details: {
    account: {
      completed: false,
      created_at: 0,
      date_of_birth: null,
      email: '',
      full_name: '',
      geodata: {
        continent: '',
        country: '',
        country_name: '',
      },
      id: 0,
      merchant_id: 0,
      merchant_uuid: '',
      metadata: {},
      organisation_id: 0,
      referrer: '',
      roles: [],
      scopes: [],
      social_apps_metadata: [],
      tenant_id: 0,
      tenant_uuid: '',
      updated_at: 0,
      username: '',
      uuid: '',
      master_id: 0,
    },
    isFetching: false,
  },
  analytics: {
    subscriptions: {
      isFetching: false,
      isFetched: false,
      data: {
        active_subscribers: 0,
        new_subscriptions: 0,
      },
    },
    accounting: {
      isFetching: false,
      isFetched: false,
      data: {
        merchant_gross_rev_share: 15.3,
        merchant_net_rev_share: 6.8,
        inplayer_net_rev_share: 5.16,
        payment_processing_fees: 14,
        currency: 'usd',
      },
      revenueSetup: {
        isRevenueSetupFetching: false,
        isRevenueShareFetching: false,
        contract_currency: 'USD',
        created_at: 0,
        mau_fee: 0.1,
        inplayer_share_minimum_amount: 30,
        inplayer_share_percent: 5.1,
        payment_provider_fee_percent: 2.9,
        payment_provider_fixed_fee: 30,
        platform_fee: 200,
        platform_fee_cycle: 'monthly',
        platform_fee_rebill_month: '',
        updated_at: 0,
      },
      recurringFees: {
        isFetching: false,
        streaming: {
          active: false,
          one_off_setup_fee: {
            active: false,
            currency: 'USD',
            amount: 0,
          },
          per_event: {
            active: false,
            currency: 'USD',
            amount: 0,
          },
          per_viewing_hour: {
            active: false,
          },
        },
        hosting_page: {
          active: false,
          monthly_fee: {
            currency: 'USD',
            amount: 150,
          },
        },
      },
      nonRecurringFees: {
        isFetching: false,
        total: 0,
        collection: [],
      },
    },
  },
  ageVerificationEnabled: false,
  paymentSetup: {
    isFetching: false,
    paymentMethods: {},
    currentPaymentMethodsCollection: [],
    stripeConnect: false,
    isDisconnected: false,
    bankStatementName: '',
  },
  paymentFees: {
    accountRevenueShares: [],
    defaultRevenueShare: {
      id: 0,
      in_account_id: 0,
      out_account_id: 0,
      account_payment_provider_id: 0,
      fixed_fee: 0,
      fixed_fee_currency: '',
      payment_type: '',
      share_percent: 0,
      created_at: 0,
      updated_at: 0,
    },
    isFetching: false,
  },
  featureFlags: [],
  accessFeeTypes: {
    collection: [],
    isFetching: false,
  },
};

type AdminMerchantDetailsReducer = Reducer<AdminMerchantDetails>;

// merchant details
const promptAdminMerchantDetails: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  details: {
    ...state.details,
    isFetching: true,
  },
});

const setAdminMerchantDetailsSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  details: {
    ...state.details,
    ...payload,
    isFetching: false,
  },
});

const setAdminMerchantDetailsFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  details: {
    ...state.details,
    isFetching: false,
  },
});

// subscription analytics
const promptSubscriptionAnalytics: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    subscriptions: {
      ...state.analytics.subscriptions,
      isFetching: true,
      isFetched: false,
    },
  },
});

const resetAdminDetails: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  details: initialState.details,
});

const setSubscriptionAnalyticsSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  analytics: {
    ...state.analytics,
    subscriptions: {
      isFetching: false,
      isFetched: true,
      data: { ...payload },
    },
  },
});

const setSubscriptionAnalyticsFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    subscriptions: {
      isFetching: false,
      isFetched: true,
      data: { ...state.analytics.subscriptions.data },
    },
  },
});

// accounting analytics
const promptAccountingAnalytics: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      isFetching: true,
      isFetched: false,
    },
  },
});

const setAccountingAnalyticsSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      isFetching: false,
      isFetched: true,
      data: { ...payload },
    },
  },
});

const setAccountingAnalyticsFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      isFetching: false,
      isFetched: false,
      data: { ...state.analytics.accounting.data },
    },
  },
});

const resetAnalyticsState: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...initialState.analytics,
  },
});

// activate relate - enable master role
const promptActivateRelate: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  details: {
    ...state.details,
    isFetching: true,
  },
});

const setActivateRelateSuccess: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  details: {
    ...state.details,
    isFetching: false,
    account: {
      ...state.details.account,
      roles: state.details.account.roles.concat(Roles.MASTER),
    },
  },
});

const setActivateRelateFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  details: {
    ...state.details,
    isFetching: false,
  },
});

// deactivate relate - disable master role
const promptDeactivateRelate: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  details: {
    ...state.details,
    isFetching: true,
  },
});

const setDeactivateRelateSuccess: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  details: {
    ...state.details,
    isFetching: false,
    account: {
      ...state.details.account,
      roles: state.details.account.roles.filter((role) => role !== Roles.MASTER),
    },
  },
});

const setDeactivateRelateFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  details: {
    ...state.details,
    isFetching: false,
  },
});

// payment setup
const promptPaymentProviders: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  paymentSetup: {
    ...state.paymentSetup,
    isFetching: true,
  },
});

const setPaymentProvidersSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => {
  if (payload) {
    const {
      payment_methods: paymentMethods,
      stripe_connect: stripeConnect,
      bank_statement: bankStatementName,
    } = payload;

    const currentPaymentMethodsCollection = map(paymentMethods, (item: PaymentMethodKeyItem) => {
      if (find(item.providers, ['current', true])) {
        return find(item.providers, ['current', true]);
      }
    }).filter((item) => item);

    return {
      ...state,
      paymentSetup: {
        ...state.paymentSetup,
        isFetching: false,
        paymentMethods,
        currentPaymentMethodsCollection,
        stripeConnect,
        bankStatementName: bankStatementName || '',
      },
    };
  }

  return state;
};

const setPaymentProvidersFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  paymentSetup: {
    ...state.paymentSetup,
    isFetching: false,
  },
});

const promptSetUpdateFees: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  paymentFees: {
    ...state.paymentFees,
    isFetching: true,
  },
});

const setUpdateFeesSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => {
  const newShares = payload;
  const { accountRevenueShares } = state.paymentFees;

  if (!accountRevenueShares) return state;
  const arrayIndex = accountRevenueShares
    ? accountRevenueShares.findIndex((item: RevenueSharesItem) => item.id === newShares.id)
    : -1;

  return {
    ...state,
    paymentFees: {
      ...state.paymentFees,
      accountRevenueShares: [
        ...accountRevenueShares.slice(0, arrayIndex),
        newShares,
        ...accountRevenueShares.slice(arrayIndex + 1),
      ],
      isFetching: false,
    },
  };
};

const setUpdateFeesFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  paymentFees: {
    ...state.paymentFees,
    isFetching: false,
  },
});

const promptLoadSingleFee: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  paymentFees: {
    ...state.paymentFees,
    isFetching: true,
  },
});

const loadSingleFeeSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => {
  const { account_revenue_shares: accountRevenueShares, default_revenue_share: defaultRevenueShare } = payload;

  return {
    ...state,
    paymentFees: {
      ...state.paymentFees,
      accountRevenueShares,
      defaultRevenueShare,
      isFetching: false,
    },
  };
};

const loadSingleFeeFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  paymentFees: {
    ...state.paymentFees,
    isFetching: false,
  },
});

const promptLoadDefaultFee: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  paymentFees: {
    ...state.paymentFees,
    isFetching: true,
  },
});

const loadDefaultFeeSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => {
  const { defaultRevenueShare } = payload;

  return {
    ...state,
    paymentFees: {
      ...state.paymentFees,
      defaultRevenueShare,
      isFetching: false,
    },
  };
};

const loadDefaultFeeFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  paymentFees: {
    ...state.paymentFees,
    isFetching: false,
  },
});

const promptSetProviders: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  paymentSetup: {
    ...state.paymentSetup,
    isFetching: true,
  },
});

const setProvidersSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => {
  const { methodId, paymentProviderId, current } = payload;
  const { paymentMethods } = state.paymentSetup;

  const newPaymentMethods = fromPairs(
    map(paymentMethods, (item) => {
      const { id, name, providers } = item;
      return id === methodId
        ? [
            name,
            {
              ...item,
              providers: providers.map((provider: ProviderItem) => {
                return provider.id === paymentProviderId
                  ? {
                      ...provider,
                      current,
                    }
                  : {
                      ...provider,
                      current: false,
                    };
              }),
            },
          ]
        : [name, item];
    })
  );

  const newPaymentMethodsCollection = map(newPaymentMethods, (item: { providers: Array<ProviderItem> }) => {
    if (find(item.providers, ['current', true])) {
      return find(item.providers, ['current', true]);
    }
  }).filter((item) => item);

  return {
    ...state,
    paymentSetup: {
      ...state.paymentSetup,
      paymentMethods: newPaymentMethods,
      paymentMethodsCollection: newPaymentMethodsCollection,
      isFetching: false,
    },
  };
};

const setProvidersFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  paymentSetup: {
    ...state.paymentSetup,
    isFetching: false,
  },
});

// merchan features
const loadMerchantFeaturesSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  featureFlags: map(payload, (item: Feature) => item),
});

// disconnect stripe
const disconnectStripeSuccess: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  paymentSetup: {
    ...state.paymentSetup,
    isDisconnected: true,
  },
});

// request for connect with stripe account
const approvedForConnectSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  paymentSetup: {
    ...state.paymentSetup,
    stripeConnect: payload.stripeConnect,
  },
});

// get age restriction
const getAgeRestrictionSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  ageVerificationEnabled: payload.age_verification_enabled,
});

// set age restriction
const setAgeRestrictionSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  ageVerificationEnabled: payload.ageVerificationEnabled,
});

// Access fee types
const promptLoadAccessFeeTypes: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  accessFeeTypes: {
    ...state.accessFeeTypes,
    isFetching: true,
  },
});

const loadAccessFeeTypesSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  accessFeeTypes: {
    ...state.accessFeeTypes,
    collection: payload,
    isFetching: false,
  },
});

const loadAccessFeeTypesFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  accessFeeTypes: {
    ...state.accessFeeTypes,
    isFetching: false,
  },
});

const promptGetRevenueSetup: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      isFetching: true,
      revenueSetup: {
        ...state.analytics.accounting.revenueSetup,
        ...payload,
      },
    },
  },
});

const getRevenueSetupSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      isFetching: false,
      revenueSetup: {
        ...state.analytics.accounting.revenueSetup,
        ...payload,
      },
    },
  },
});

const getRevenueSetupFailure: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      isFetching: false,
      revenueSetup: {
        ...state.analytics.accounting.revenueSetup,
        ...payload,
      },
    },
  },
});

const promptUpdateRevenueSetup: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      revenueSetup: {
        ...state.analytics.accounting.revenueSetup,
        ...payload,
      },
    },
  },
});

const updateRevenueSetupSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      revenueSetup: {
        ...state.analytics.accounting.revenueSetup,
        ...payload,
      },
    },
  },
});

const updateRevenueSetupFailure: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      revenueSetup: {
        ...state.analytics.accounting.revenueSetup,
        ...payload,
      },
    },
  },
});

const promptGetRecurringFees: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      recurringFees: {
        ...state.analytics.accounting.recurringFees,
        isFetching: true,
      },
    },
  },
});

const getRecurringFeesSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      recurringFees: {
        ...state.analytics.accounting.recurringFees,
        ...payload,
        isFetching: false,
      },
    },
  },
});

const getRecurringFeesFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      recurringFees: {
        ...state.analytics.accounting.recurringFees,
        isFetching: false,
      },
    },
  },
});

const promptSetRecurringFees: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      recurringFees: {
        ...state.analytics.accounting.recurringFees,
        isFetching: true,
      },
    },
  },
});

const setRecurringFeesSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      recurringFees: {
        ...state.analytics.accounting.recurringFees,
        ...payload,
        isFetching: false,
      },
    },
  },
});

const setRecurringFeesFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      recurringFees: {
        ...state.analytics.accounting.recurringFees,
        isFetching: false,
      },
    },
  },
});

const promptGetNonRecurringFees: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      nonRecurringFees: {
        ...state.analytics.accounting.nonRecurringFees,
        isFetching: true,
      },
    },
  },
});

const getNonRecurringFeesSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      nonRecurringFees: {
        ...state.analytics.accounting.nonRecurringFees,
        isFetching: false,
        total: payload.total,
        collection: payload.non_recurring_fees,
      },
    },
  },
});

const getNonRecurringFeesFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      nonRecurringFees: {
        ...state.analytics.accounting.nonRecurringFees,
        isFetching: false,
      },
    },
  },
});

const promptAddNonRecurringFee: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      nonRecurringFees: {
        ...state.analytics.accounting.nonRecurringFees,
        isFetching: true,
      },
    },
  },
});

const addNonRecurringFeeSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      nonRecurringFees: {
        ...state.analytics.accounting.nonRecurringFees,
        isFetching: false,
        collection: [...state.analytics.accounting.nonRecurringFees.collection, payload],
      },
    },
  },
});

const addNonRecurringFeeFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      nonRecurringFees: {
        ...state.analytics.accounting.nonRecurringFees,
        isFetching: false,
      },
    },
  },
});

const promptDeleteNonRecurringFee: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      nonRecurringFees: {
        ...state.analytics.accounting.nonRecurringFees,
        isFetching: true,
      },
    },
  },
});

const deleteNonRecurringFeeSuccess: AdminMerchantDetailsReducer = (state = initialState, { payload }) => {
  const filteredNonRecurringFees = state.analytics.accounting.nonRecurringFees.collection.filter(
    (fee) => fee.id !== payload
  );

  return {
    ...state,
    analytics: {
      ...state.analytics,
      accounting: {
        ...state.analytics.accounting,
        nonRecurringFees: {
          ...state.analytics.accounting.nonRecurringFees,
          collection: filteredNonRecurringFees,
          isFetching: false,
        },
      },
    },
  };
};

const deleteNonRecurringFeeFailure: AdminMerchantDetailsReducer = (state = initialState) => ({
  ...state,
  analytics: {
    ...state.analytics,
    accounting: {
      ...state.analytics.accounting,
      nonRecurringFees: {
        ...state.analytics.accounting.nonRecurringFees,
        isFetching: false,
      },
    },
  },
});

const actionsMap: ActionMap<any> = {
  // merchant details
  [FetchAdminMerchantDetailsActionTypes.START]: promptAdminMerchantDetails,
  [FetchAdminMerchantDetailsActionTypes.SUCCESS]: setAdminMerchantDetailsSuccess,
  [FetchAdminMerchantDetailsActionTypes.ERROR]: setAdminMerchantDetailsFailure,
  [FetchAdminMerchantDetailsActionTypes.RESET]: resetAdminDetails,
  // subscription analytics
  [SubscriptionAnalyticsActionTypes.START]: promptSubscriptionAnalytics,
  [SubscriptionAnalyticsActionTypes.SUCCESS]: setSubscriptionAnalyticsSuccess,
  [SubscriptionAnalyticsActionTypes.ERROR]: setSubscriptionAnalyticsFailure,
  // accounting analytics
  [AccountingAnalyticsActionTypes.START]: promptAccountingAnalytics,
  [AccountingAnalyticsActionTypes.SUCCESS]: setAccountingAnalyticsSuccess,
  [AccountingAnalyticsActionTypes.ERROR]: setAccountingAnalyticsFailure,
  // reset analytics state
  [ResetAnalyticsStateType.RESET_STATE]: resetAnalyticsState,
  // activate relate
  [EnableMasterRoleActionTypes.START]: promptActivateRelate,
  [EnableMasterRoleActionTypes.SUCCESS]: setActivateRelateSuccess,
  [EnableMasterRoleActionTypes.ERROR]: setActivateRelateFailure,
  // deactivate relate
  [DisableMasterRoleActionTypes.START]: promptDeactivateRelate,
  [DisableMasterRoleActionTypes.SUCCESS]: setDeactivateRelateSuccess,
  [DisableMasterRoleActionTypes.ERROR]: setDeactivateRelateFailure,
  // payment setup
  [FetchPaymentProvidersActionTypes.START]: promptPaymentProviders,
  [FetchPaymentProvidersActionTypes.SUCCESS]: setPaymentProvidersSuccess,
  [FetchPaymentProvidersActionTypes.ERROR]: setPaymentProvidersFailure,
  [SetUpdateFeesActionTypes.START]: promptSetUpdateFees,
  [SetUpdateFeesActionTypes.SUCCESS]: setUpdateFeesSuccess,
  [SetUpdateFeesActionTypes.ERROR]: setUpdateFeesFailure,
  [LoadSingleFeeActionTypes.START]: promptLoadSingleFee,
  [LoadSingleFeeActionTypes.SUCCESS]: loadSingleFeeSuccess,
  [LoadSingleFeeActionTypes.ERROR]: loadSingleFeeFailure,
  [LoadDefaultFeeActionTypes.START]: promptLoadDefaultFee,
  [LoadDefaultFeeActionTypes.SUCCESS]: loadDefaultFeeSuccess,
  [LoadDefaultFeeActionTypes.ERROR]: loadDefaultFeeFailure,
  [SetProviderActionTypes.START]: promptSetProviders,
  [SetProviderActionTypes.SUCCESS]: setProvidersSuccess,
  [SetProviderActionTypes.ERROR]: setProvidersFailure,
  // feature flags
  [FetchMerchantFeaturesActionTypes.SUCCESS]: loadMerchantFeaturesSuccess,
  // disconnect stripe
  [DisconnectStripeActionTypes.SUCCESS]: disconnectStripeSuccess,
  // request for connect with stripe account
  [ApprovedForConnectActionTypes.SUCCESS]: approvedForConnectSuccess,
  // get age restriction
  [GetAgeRestrictionActionTypes.SUCCESS]: getAgeRestrictionSuccess,
  // set age restriction
  [SetAgeRestrictionActionTypes.SUCCESS]: setAgeRestrictionSuccess,
  // Access fee types
  [FetchAccessFeeTypesActionTypes.START]: promptLoadAccessFeeTypes,
  [FetchAccessFeeTypesActionTypes.SUCCESS]: loadAccessFeeTypesSuccess,
  [FetchAccessFeeTypesActionTypes.ERROR]: loadAccessFeeTypesFailure,
  // Get Revenue setup
  [GetRevenueSetupTypes.START]: promptGetRevenueSetup,
  [GetRevenueSetupTypes.SUCCESS]: getRevenueSetupSuccess,
  [GetRevenueSetupTypes.ERROR]: getRevenueSetupFailure,
  // Update Revenue setup
  [UpdateRevenueSetupTypes.START || UpdateRevenueShareTypes.START]: promptUpdateRevenueSetup,
  [UpdateRevenueSetupTypes.SUCCESS || UpdateRevenueShareTypes.SUCCESS]: updateRevenueSetupSuccess,
  [UpdateRevenueSetupTypes.ERROR || UpdateRevenueShareTypes.ERROR]: updateRevenueSetupFailure,
  // Update Revenue share
  [UpdateRevenueShareTypes.START]: promptUpdateRevenueSetup,
  [UpdateRevenueShareTypes.SUCCESS]: updateRevenueSetupSuccess,
  [UpdateRevenueShareTypes.ERROR]: updateRevenueSetupFailure,
  // Get recurring fees
  [GetRecurringFeesTypes.START]: promptGetRecurringFees,
  [GetRecurringFeesTypes.SUCCESS]: getRecurringFeesSuccess,
  [GetRecurringFeesTypes.ERROR]: getRecurringFeesFailure,
  // Set recurring fees
  [SetRecurringFeesTypes.START]: promptSetRecurringFees,
  [SetRecurringFeesTypes.SUCCESS]: setRecurringFeesSuccess,
  [SetRecurringFeesTypes.ERROR]: setRecurringFeesFailure,
  // Get Non recurring fees
  [GetNonRecurringFeesType.START]: promptGetNonRecurringFees,
  [GetNonRecurringFeesType.SUCCESS]: getNonRecurringFeesSuccess,
  [GetNonRecurringFeesType.ERROR]: getNonRecurringFeesFailure,
  // Add non recurring fee
  [AddNonRecurringFeeType.START]: promptAddNonRecurringFee,
  [AddNonRecurringFeeType.SUCCESS]: addNonRecurringFeeSuccess,
  [AddNonRecurringFeeType.ERROR]: addNonRecurringFeeFailure,
  // Delete non recurring fee
  [DeleteNonRecurringFeeType.START]: promptDeleteNonRecurringFee,
  [DeleteNonRecurringFeeType.SUCCESS]: deleteNonRecurringFeeSuccess,
  [DeleteNonRecurringFeeType.ERROR]: deleteNonRecurringFeeFailure,
};

export default reducerWithActionMap(actionsMap, initialState);
