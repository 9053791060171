import { Action } from 'redux';

export enum DatePickerTypes {
  SAVE_DATEPICKER_PERIOD = 'SAVE_DATEPICKER_PERIOD',
}

export const saveDatePickerPeriod = (startDate: any, endDate: any): Action<DatePickerTypes> & { payload: any } => {
  return {
    type: DatePickerTypes.SAVE_DATEPICKER_PERIOD,
    payload: { startDate, endDate },
  };
};
