import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { API } from 'constants/api';
import { APPLE_PAYMENT_PROVIDER_ID } from 'constants/index';
import http, { ContentType } from 'utils/http';
import RootState from 'ducks/RootState';
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';

// GOOGLE INTEGRATION
/* Load google in-app integration keys */
export enum FetchGoogleInAppKeysActionTypes {
  START = 'LOAD_GOOGLE_IN_APP_KEYS_REQUEST',
  SUCCESS = 'LOAD_GOOGLE_IN_APP_KEYS_SUCCESS',
  ERROR = 'LOAD_GOOGLE_IN_APP_KEYS_FAILURE',
}

type FetchGoogleInAppKeysAction = Action<FetchGoogleInAppKeysActionTypes>;

export const loadGoogleInAppKeys = (
  merchantUuid: string
): ThunkAction<Promise<any>, RootState, any, FetchGoogleInAppKeysAction> => async (dispatch) => {
  const endpoint = API.GOOGLE_IN_APP_INTEGRATION;

  try {
    dispatch({ type: FetchGoogleInAppKeysActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      params: { merchant_uuid: merchantUuid },
    });
    dispatch({ type: FetchGoogleInAppKeysActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    dispatch({ type: FetchGoogleInAppKeysActionTypes.ERROR });
  }
};

/* Add google in-app */
export enum AddGoogleInAppIntegrationTypes {
  START = 'GOOGLE_IN_APP_INTEGRATION_REQUEST',
  SUCCESS = 'GOOGLE_IN_APP_INTEGRATION_SUCCESS',
  ERROR = 'GOOGLE_IN_APP_INTEGRATION_FAILURE',
}

export interface AddGoogleInAppIntegrationProps {
  siteToken: string;
  playstorePublicKey: string;
  merchantUuid: string;
}

type AddGoogleInAppIntegrationAction = Action<AddGoogleInAppIntegrationTypes | NotificationActionTypes>;

export const addGoogleInAppIntegration = ({
  siteToken,
  playstorePublicKey,
  merchantUuid,
}: AddGoogleInAppIntegrationProps): ThunkAction<
  Promise<any>,
  RootState,
  any,
  AddGoogleInAppIntegrationAction
> => async (dispatch) => {
  const endpoint = API.GOOGLE_IN_APP_INTEGRATION;

  const data = {
    site_token: siteToken,
    playstore_public_key: playstorePublicKey,
    merchant_uuid: merchantUuid,
  };

  try {
    dispatch({ type: AddGoogleInAppIntegrationTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: AddGoogleInAppIntegrationTypes.SUCCESS, payload: { ...response.data } });
    dispatch(loadGoogleInAppKeys(merchantUuid));
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Google in-app integration was successfully saved.',
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: AddGoogleInAppIntegrationTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

// APPLE INTEGRATION
/* Load Apple in-app integration keys */
export enum FetchAppleInAppKeysActionTypes {
  START = 'LOAD_APPLE_IN_APP_KEYS_REQUEST',
  SUCCESS = 'LOAD_APPLE_IN_APP_KEYS_SUCCESS',
  ERROR = 'LOAD_APPLE_IN_APP_KEYS_FAILURE',
}

type FetchAppleInAppKeysAction = Action<FetchAppleInAppKeysActionTypes>;

export const loadAppleInAppKeys = (
  merchantId: number
): ThunkAction<Promise<any>, RootState, any, FetchAppleInAppKeysAction> => async (dispatch) => {
  const paymentProviderId = APPLE_PAYMENT_PROVIDER_ID;
  const endpoint = API.LOAD_APPLE_IN_APP_INTEGRATION;

  try {
    dispatch({ type: FetchAppleInAppKeysActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: { paymentProviderId },
      params: { merchant_id: merchantId },
    });
    dispatch({ type: FetchAppleInAppKeysActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    dispatch({ type: FetchAppleInAppKeysActionTypes.ERROR });
  }
};

/* Add Aplle in-app */
export enum AddAppleInAppIntegrationTypes {
  START = 'APPLE_IN_APP_INTEGRATION_REQUEST',
  SUCCESS = 'APPLE_IN_APP_INTEGRATION_SUCCESS',
  ERROR = 'APPLE_IN_APP_INTEGRATION_FAILURE',
}

export interface AddAppleInAppIntegrationProps {
  metadata: any;
  id: number;
}

type AddAppleInAppIntegrationAction = Action<AddAppleInAppIntegrationTypes | NotificationActionTypes>;

export const addAppleInAppIntegration = ({
  metadata,
  id,
}: AddAppleInAppIntegrationProps): ThunkAction<Promise<any>, RootState, any, AddAppleInAppIntegrationAction> => async (
  dispatch
) => {
  const endpoint = API.ADD_IN_APP_INTEGRATION;
  const payment_provider_id = APPLE_PAYMENT_PROVIDER_ID;

  const data = {
    metadata,
    payment_provider_id,
    merchant_id: id,
  };

  try {
    dispatch({ type: AddAppleInAppIntegrationTypes.START });
    const response = await http.authenticated().put(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: AddAppleInAppIntegrationTypes.SUCCESS, payload: { ...response.data } });
    dispatch(loadAppleInAppKeys(id));
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: 'Apple in-app integration was successfully saved.',
      })
    );
  } catch (error) {
    const {
      response: {
        data: { message },
      },
    } = error;

    dispatch({ type: AddAppleInAppIntegrationTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};
