import RootState from 'ducks/RootState';

export const getReportsState = (state: RootState) => state.reports;

export const getCommercialReports = (state: RootState) => getReportsState(state).commercialSetup;

export const getReportFields = (state: RootState) => getReportsState(state).reportFields;

export const getFilterMerchantId = (state: RootState) => getReportsState(state).csvReports.reportsForMerchantId;

export const getMissingTransactions = (state: RootState) => getReportsState(state).missingTransactions;
