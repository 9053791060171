import styled, { StyledComponent } from 'styled-components';
import { ifProp } from 'styled-tools';
import { Theme } from '@inplayer-org/inplayer-ui';

// utils
import media from 'utils/mediaQueries';

interface OvpContainerProps {
  assetSection?: boolean;
}

const OvpContainer: StyledComponent<'div', Theme, OvpContainerProps> = styled.div`
  display: grid;
  grid-template-columns: ${ifProp('assetSection', 'repeat(4, 1fr)', 'repeat(3, 1fr)')};
  grid-gap: 1rem;
  width: ${ifProp('assetSection', '100%', '70%')};
  margin: ${ifProp('assetSection', '0 auto')};

  ${media.customMaxWidth(1024)} {
    width: 100%;
  }

  ${media.customMaxWidth(800)} {
    grid-template-columns: 1fr 1fr;
  }

  ${media.customMaxWidth(600)} {
    width: 90%;
    grid-template-columns: 1fr;
  }
`;

export default OvpContainer;
