import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  CustomerAccessActionTypes,
  ExtendAccessActionTypes,
  RevokeAccessActionTypes,
} from 'ducks/merchantDucks/audience/actions';
import {
  BulkAccessActionActionTypes,
  FetchAccessActionTypes,
  ListErrorFilesActionTypes,
} from 'ducks/merchantDucks/accesses/actions';
import AccessState from 'ducks/merchantDucks/accesses/types';

export const initialState: AccessState = {
  collection: [],
  total: 0,
  limit: 15,
  isFetching: false,
  revokeRequest: true,
  revokeError: false,
  accessesRequest: false,
  accessesError: false,
  isFetchingErrorFiles: false,
  errorFiles: [],
};

type AccessReducer = Reducer<AccessState>;

const promptFetchAccess: AccessReducer = (state = initialState) => ({
  ...state,
  accessesRequest: true,
  isFetching: true,
});

const fetchAccessError: AccessReducer = (state = initialState) => ({
  ...state,
  accessesError: true,
  isFetching: false,
});

const fetchAccessSuccess: AccessReducer = (state = initialState, { payload }) => ({
  ...state,
  accessesRequest: false,
  total: payload.total,
  collection: [...payload.collection],
  isFetching: false,
});

const promptRevokeAccess: AccessReducer = (state = initialState) => ({
  ...state,
  revokeRequest: true,
});

const revokeAccessFailure: AccessReducer = (state = initialState) => ({
  ...state,
  revokeError: true,
});

const revokeAccessSuccess: AccessReducer = (
  state = initialState,
  {
    payload: {
      successData: { itemAccessId },
    },
  }
) => ({
  ...state,
  revokeRequest: false,
  collection: state.collection.filter((item) => item.item_access_id !== itemAccessId),
});

const customerAccessSuccess: AccessReducer = (state = initialState, { payload }) => ({
  ...state,
  ...payload,
});

const extendAccessSuccess: AccessReducer = (state = initialState, { payload }) => {
  const { collection } = state;
  const newCollection = collection.map((item, index) => {
    return index === collection.findIndex((innerItem) => innerItem.item_access_id === payload.id)
      ? {
          consumer_email: payload.email,
          created_at: payload.created_at,
          customer_id: payload.customer_id,
          expires_at: payload.expires_at,
          item_access_id: payload.id,
          item_id: payload.item.id,
          item_title: payload.item.title,
          merchant_id: payload.account_id,
          type: payload.type,
        }
      : item;
  });

  return {
    ...state,
    collection: [...newCollection],
  };
};
const loadErrorFilesStart: AccessReducer = (state = initialState) => {
  return {
    ...state,
    isFetchingErrorFiles: true,
  };
};
const loadErrorFilesSuccess: AccessReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    isFetchingErrorFiles: false,
    errorFiles: payload,
  };
};

const loadErrorFilesFailure: AccessReducer = (state = initialState) => {
  return {
    ...state,
    isFetchingErrorFiles: false,
    errorFiles: [],
  };
};

const bulkUploadStart: AccessReducer = (state = initialState) => {
  return {
    ...state,
    isFetching: true,
  };
};

const bulkUploadEnd: AccessReducer = (state = initialState) => {
  return {
    ...state,
    isFetching: false,
  };
};
const actionsMap: ActionMap<AccessState> = {
  /* FETCH ACCESSES */
  [FetchAccessActionTypes.START]: promptFetchAccess,
  [FetchAccessActionTypes.ERROR]: fetchAccessError,
  [FetchAccessActionTypes.SUCCESS]: fetchAccessSuccess,
  /* REVOKE ACCESS */
  [RevokeAccessActionTypes.START]: promptRevokeAccess,
  [RevokeAccessActionTypes.ERROR]: revokeAccessFailure,
  [RevokeAccessActionTypes.SUCCESS]: revokeAccessSuccess,
  /* Filter Customers */
  [CustomerAccessActionTypes.SUCCESS]: customerAccessSuccess,
  [ExtendAccessActionTypes.SUCCESS]: extendAccessSuccess,
  /* Bulk Access Actions */
  [BulkAccessActionActionTypes.START]: bulkUploadStart,
  [BulkAccessActionActionTypes.SUCCESS]: bulkUploadEnd,
  [BulkAccessActionActionTypes.ERROR]: bulkUploadEnd,
  /* Bulk Access Actions Error Files */
  [ListErrorFilesActionTypes.START]: loadErrorFilesStart,
  [ListErrorFilesActionTypes.SUCCESS]: loadErrorFilesSuccess,
  [ListErrorFilesActionTypes.ERROR]: loadErrorFilesFailure,
};

export default reducerWithActionMap(actionsMap, initialState);
