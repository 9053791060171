import React from 'react';
import { Tooltip, TooltipProps, Placement, AnalyticsProps } from '@inplayer-org/inplayer-ui';
import { MdInfoOutline } from 'react-icons/md';
import { StyledTooltip } from 'pages/AdminPages/components/SharedStyledElements';

interface Props extends AnalyticsProps {
  placement?: Placement;
  additionalContent?: string;
  isAdmin?: boolean;
}

interface TooltipWrapperProps extends TooltipProps {
  isAdmin: boolean;
}

const TooltipWraper = ({ isAdmin, ...rest }: TooltipWrapperProps) => {
  if (isAdmin) return <StyledTooltip {...rest} />;
  return <Tooltip {...rest} />;
};

const SearchTooltip = ({
  placement = 'right',
  additionalContent = '',
  isAdmin = false,
  tag = 'tooltip_search',
}: Props) => {
  return (
    <TooltipWraper
      tag={tag}
      isAdmin={isAdmin}
      placement={placement}
      content={`To do a search, select the filter from the dropdown menu, enter your keyword, and press Enter.\n${additionalContent}`}
    >
      need help searching? <MdInfoOutline name="info" />
    </TooltipWraper>
  );
};

export default SearchTooltip;
