export enum SetEmbedCodeObjectActionTypes {
  GLOBAL_OBJECT = 'GLOBAL_EMBED_CODE_OBJECT',
  PER_ASSET = 'PER_ASSET_EMBED_CODE_OBJECT',
  RESET_PER_ASSET_OPTIONS = 'RESET_PER_ASSET_OPTIONS',
}

export const setGlobalEmbedCodeObject = (key: string, value: any) => ({
  type: SetEmbedCodeObjectActionTypes.GLOBAL_OBJECT,
  payload: { [key]: value },
});

export const setPerAssetEmbedCodeObject = (key: string, value: any) => ({
  type: SetEmbedCodeObjectActionTypes.PER_ASSET,
  payload: { [key]: value },
});

export const resetPerAssetOptions = () => ({ type: SetEmbedCodeObjectActionTypes.RESET_PER_ASSET_OPTIONS });
