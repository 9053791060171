import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { transparentize } from 'polished';
import { Colors, TabNavigation, AnalyticsTag } from '@inplayer-org/inplayer-ui';

// types
import { Follower } from 'ducks/merchantDucks/agency/types';
import { Roles } from 'ducks/merchantDucks/user/types';
import { ModalNames } from 'ducks/merchantDucks/modal/types';
import { LoginAsFollowerFormValues } from 'pages/MerchantPages/Account/AgencySettings/components/forms/ImpersonateAsFollowerForm';

// utils
import TokenManager from 'utils/TokenManager';
import { findRouteByPath, AppRoute } from 'components/Navigation/navRoutes';
import { unescapeText } from 'utils/helpers';
import includes from 'lodash/includes';
import findLastIndex from 'lodash/findLastIndex';

// selectors
import { getUserMetadata, getAuthState } from 'ducks/merchantDucks/user/selectors';

// restrictions
import { restrictRelatedAccountForRoles } from 'components/Navigation/restrictions';

// actions
import { logoutUser } from 'ducks/merchantDucks/user/actions';
import { loginAsFollower, loginAsMaster } from 'ducks/merchantDucks/agency/actions';
import { openModal } from 'ducks/merchantDucks/modal/actions';

// components
import ImpersonateModal from 'pages/MerchantPages/Account/AgencySettings/components/modals/ImpersonateModal';
import UserMenu from 'components/Navigation/TopMenu';
import RootState from 'ducks/RootState';

const Container = styled.header`
  align-items: center;
  padding: 0 2%;
  background: ${Colors.white};
  box-shadow: 0px 3px 5px ${transparentize(0.87, Colors.fontDarkGray)};
  display: flex;
  height: ${prop('height', 50)}px;
  justify-content: space-between;
  margin: 0;
  z-index: 3;
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface UserMenuItem {
  title: string;
  href: string;
}

const merchantItems: Array<UserMenuItem> = [
  {
    title: 'Billing & plan',
    href: '/billing-plan',
  },
  {
    title: 'Webhooks',
    href: '/webhooks',
  },
];

const commonItems = (isAdmin: boolean): UserMenuItem[] => [
  {
    title: 'Account',
    href: `${isAdmin ? '/admin' : ''}/account`,
  },
];

const avatarImg = 'https://assets.inplayer.com/images/avatar.jpg';

interface Props {
  tabs: Array<AppRoute>;
}

const Header: FunctionComponent<Props> = ({ tabs }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const [open, setOpen] = useState<Partial<{ userMenu: boolean; relateMenu: boolean }>>({
    userMenu: false,
    relateMenu: false,
  });
  const [follower, setFollower] = useState({
    active: false,
    avatar_url: '',
    country: '',
    created_at: 0,
    email: '',
    full_name: '',
    id: 0,
    is_master: false,
    master_id: 0,
    merchant_id: 0,
    scopes: [''],
    password: '',
    referrer: '',
  });

  // selectors
  const userMetadata = useSelector(getUserMetadata);
  const followers = useSelector((state: RootState) => state.agency.clients);
  const auth = useSelector(getAuthState);
  const {
    user,
    user: { full_name: fullName, roles, merchant_uuid: merchantUuid, master_id: masterId, roleRestrictions },
    isAdmin,
  } = auth;

  useEffect(() => {
    const { full_name: clientFullName } = follower;
    if (clientFullName !== '') {
      setOpen((prevState) => ({
        ...prevState,
        relateMenu: false,
      }));
      dispatch(openModal(ModalNames.Impersonate));
    }
  }, [follower]);

  const openImpersonateModal = (client: Follower) => {
    setFollower(client);
  };

  const handleLoginAsFollower = async (data: LoginAsFollowerFormValues) => {
    const { id: sign_in_as, email } = follower;

    const newData = {
      ...data,
      sign_in_as,
      uuid: merchantUuid,
      email,
      client_id: merchantUuid,
      grant_type: 'social',
    };

    try {
      await dispatch(loginAsFollower(newData));
    } catch (_) {}
  };

  const handleReturnToMaster = () => {
    if (!masterId) {
      return;
    }

    const { master } = TokenManager;

    if (master && master.masterRefreshToken) {
      dispatch(loginAsMaster(master.masterRefreshToken));
    }
  };

  const isAgencyEnabled = () => {
    let isAgencyEnabledValue = false;
    if (user) {
      isAgencyEnabledValue = includes(user.roles, Roles.MASTER);
    }

    return isAgencyEnabledValue;
  };

  const onTabClick = (index: number) => {
    const clickedTab = tabs[index];

    if (!isAgencyEnabled()) {
      if (index === tabs.length) {
        handleReturnToMaster();
        return;
      }
    }

    if (!clickedTab) {
      return;
    }

    history.push(`${match.url}${clickedTab.path}`);

    localStorage.removeItem('reportInformation');
  };

  const renderClients = (client: Follower) => {
    const { full_name: clientFullName, avatar_url: avatarUrl } = client;
    let followerAvatar = avatarImg;
    if (avatarUrl) {
      followerAvatar = `${avatarUrl}#${new Date().getTime()}`; // Added date for cache busting
    }

    return {
      image: followerAvatar,
      title: unescapeText(clientFullName),
      onClick: () => openImpersonateModal(client),
    };
  };

  const redirect = (path: string) => () => {
    history.push(path);
  };

  const getUnrestrictedUserMenuItems = () => {
    const userMenuItems = roles.includes(Roles.INPLAYER)
      ? [...commonItems(isAdmin)]
      : [...commonItems(isAdmin), ...merchantItems];

    return userMenuItems
      .filter(({ href }: UserMenuItem) => {
        const { restrictionRule } = findRouteByPath(href) || {};

        if (!restrictionRule) {
          return true;
        }

        return !roleRestrictions.includes(restrictionRule);
      })
      .map(({ title, href }: UserMenuItem) => ({ title, onClick: redirect(href) }));
  };

  const renderLoginAsBtn = () => {
    if (isAdmin) {
      if (TokenManager.token) {
        return (
          <NavLink to="/dashboard" target="_blank">
            Go to Merchant
          </NavLink>
        );
      }

      return (
        <NavLink to="/login" target="_blank">
          Login as
        </NavLink>
      );
    }

    if (!isAdmin && TokenManager.admin) {
      return (
        <NavLink to="/admin/dashboard" target="_blank">
          Back to Admin
        </NavLink>
      );
    }

    return null;
  };

  const handleLogoutUser = () => {
    dispatch(logoutUser());
  };

  const selectedTabIndex = findLastIndex(tabs, (tab) => includes(window.location.pathname, tab.path));

  const featureShouldBeRestricted = roles.filter((role) => restrictRelatedAccountForRoles.includes(role)).length > 0;

  let avatar = avatarImg;
  if (userMetadata) {
    const { avatar_url: avatarUrl } = userMetadata;
    if (avatarUrl) {
      avatar = `${avatarUrl}#${new Date().getTime()}`;
    }
  }

  const { master } = TokenManager;

  let newTabs = [...tabs];

  if (!isAgencyEnabled() && master) {
    newTabs = [...newTabs, { title: 'Back to master', path: '/' }];
  }

  const commonUserMenuProps = {
    open,
    setOpen,
  };

  const renderClientsMenu = isAgencyEnabled() && followers.total > 0 && !featureShouldBeRestricted;

  let additionalUserMenuProps;

  if (renderClientsMenu) {
    additionalUserMenuProps = {
      ...commonUserMenuProps,
      id: 'relateMenu',
      menuTitle: 'Related accounts',
      menuItems: followers.collection.map(renderClients),
      actionItem: {
        title: 'See all related accounts',
        href: '/account/relate/related-accounts',
        smallSize: true,
      },
      tag: 'dropdown_related',
    };
  }

  const userMenuProps: {
    id: string;
    menuTitle: string;
    image: string;
    menuItems: Array<any>;
    actionItem: {
      title: string;
      onClick: () => void;
    };
    linkItem?: {
      title: string;
      onClick: () => void;
      tag?: AnalyticsTag;
    };
    open: Partial<{ userMenu: boolean; relateMenu: boolean }>;
    setOpen: (open: Partial<{ userMenu: boolean; relateMenu: boolean }>) => void;
    tag?: AnalyticsTag;
  } = {
    ...commonUserMenuProps,
    id: 'userMenu',
    menuTitle: fullName,
    image: avatar,
    menuItems: getUnrestrictedUserMenuItems(),
    actionItem: {
      title: 'Logout',
      onClick: handleLogoutUser,
    },
    tag: `dropdown_${fullName}`,
  };

  return (
    <>
      <Container>
        <TabNavigation tabs={newTabs} onTabClick={onTabClick} selectedTabIndex={selectedTabIndex} />
        <MenuWrapper>
          {additionalUserMenuProps && <UserMenu {...additionalUserMenuProps} />}
          <UserMenu {...userMenuProps} />
          {renderLoginAsBtn()}
        </MenuWrapper>
      </Container>
      <ImpersonateModal follower={follower} handleLoginAsFollower={handleLoginAsFollower} />
    </>
  );
};

export default Header;
