import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Colors, Typography, Dropdown } from '@inplayer-org/inplayer-ui';
import { Title, Container } from 'pages/MerchantPages/Analytics/components/shared';
import { transparentize } from 'polished';

const Content = styled(Typography)`
  color: ${Colors.fontGray};
  font-weight: ${({ theme }) => theme.font.weights.light};
  padding-bottom: 0.5rem;
`;

const TitleContainer = styled.div<{ isBorder?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: ${({ isBorder }) => isBorder && `1px solid ${Colors.gray}`};
`;

const TileDropdown = styled(Dropdown)`
  height: 2.2rem;
  border: none;
  font-weight: ${({ theme }) => theme.font.weights.bold} !important;
`;

const StyledContainer = styled(Container)`
  border: none;
  box-shadow: 0 0 4px 1px ${transparentize(0.87, Colors.fontDarkGray)};
`;

interface Props {
  title: string;
  subtitle?: string;
  tileContent: React.ReactElement<any>;
  tileWidth?: string;
  tileMargin?: string;
  dropdown?: boolean;
  dropdownOptions?: Array<any>;
  activeOption?: string;
  handleOptionChange?: (value: string) => void;
  legend?: ReactNode;
  isBorder?: boolean;
}
const Tile: React.FC<Props> = ({
  title,
  subtitle,
  tileContent,
  tileWidth,
  tileMargin,
  dropdown,
  activeOption,
  dropdownOptions,
  handleOptionChange,
  legend,
  isBorder,
}) => {
  return (
    <StyledContainer width={tileWidth} margin={tileMargin}>
      <TitleContainer isBorder={isBorder}>
        <Title variant="p"> {title}</Title>
        {dropdown && dropdownOptions && activeOption && (
          <TileDropdown
            modifiers={['fontSizeSmall']}
            options={dropdownOptions}
            value={activeOption}
            onChange={handleOptionChange}
          />
        )}
        {subtitle && <Content variant="p">{subtitle}</Content>}
      </TitleContainer>
      {tileContent}
      {legend}
    </StyledContainer>
  );
};

export default Tile;
