import { Reducer } from 'redux';
import find from 'lodash/find';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  RESET_BRANDING_DETAILS,
  LoadBrandingActionTypes,
  LoadBrandingDetailsActionTypes,
  UpdateBrandingActionTypes,
  CreateBrandingActionTypes,
  RemoveBrandingActionTypes,
} from 'ducks/merchantDucks/branding/actions';
import BrandingState from 'ducks/merchantDucks/branding/types';

const defaultBrandingItem = {
  default: false,
  paywall_cover_photo: '',
  preview_top_border: true,
  inplayer_protected_label: true,
  preview_buttons_bg_color: '#56c0a4',
  preview_buttons_text_color: '#FFFFFF',
  paywall_brand_logo: '',
  paywall_primary_color: '#52ceb0',
  paywall_secondary_color: '#44a7c8',
  paywall_buttons_bg_color: '#56c0a4',
  paywall_buttons_text_color: '#FFFFFF',
  paywall_footer: '',
  exists: false,
  brand_name: '',
  template_id: 1,
};

export const initialState: BrandingState = {
  collection: [],
  isFetching: false,
  brandingDetails: {
    ...defaultBrandingItem,
    collection: [],
    isFetching: false,
    isCreated: false,
    isSaving: false,
    data: '',
  },
  defaultBrandingSet: {
    ...defaultBrandingItem,
  },
};

type BrandingReducer = Reducer<BrandingState>;

const setIsFetching = (isFetching: boolean): BrandingReducer => (state = initialState) => ({
  ...state,
  isFetching,
});

const setBrandingSuccess: BrandingReducer = (state = initialState, { payload }) => {
  const defaultBrandingSet = find(payload, { default: true }) || defaultBrandingItem;

  return {
    ...state,
    collection: payload,
    isFetching: false,
    defaultBrandingSet,
  };
};

const promptSetBranding: BrandingReducer = (state = initialState) => ({
  ...state,
  brandingDetails: {
    ...state.brandingDetails,
    isFetching: true,
  },
});

const setBrandingDetailsSuccess: BrandingReducer = (state = initialState, { payload }) => ({
  ...state,
  brandingDetails: {
    ...state.brandingDetails,
    ...payload,
    isFetching: false,
  },
});

const setBrandingDetailsFailure: BrandingReducer = (state = initialState, { payload }) => ({
  ...state,
  brandingDetails: {
    ...state.brandingDetails,
    isFetching: false,
    data: payload.message,
  },
});

const promptCreateBranding: BrandingReducer = (state = initialState) => ({
  ...state,
  brandingDetails: {
    ...state.brandingDetails,
    isSaving: true,
    isCreated: false,
  },
});

const createBrandingSuccess: BrandingReducer = (state = initialState, { payload: { id } }) => ({
  ...state,
  brandingDetails: {
    ...state.brandingDetails,
    id,
    exists: true,
    isSaving: false,
    isCreated: true,
  },
});

const createBrandingFailure: BrandingReducer = (state = initialState) => ({
  ...state,
  brandingDetails: {
    ...state.brandingDetails,
    isSaving: false,
    isCreated: false,
  },
});

const promptUpdateBranding: BrandingReducer = (state = initialState) => ({
  ...state,
  brandingDetails: {
    ...state.brandingDetails,
    isSaving: true,
  },
});

const updateBrandingSuccess: BrandingReducer = (state = initialState, { payload: { branding } }) => ({
  ...state,
  brandingDetails: {
    ...state.brandingDetails,
    ...branding,
    exists: true,
    isSaving: false,
  },
});

const updateBrandingFailure: BrandingReducer = (state = initialState) => ({
  ...state,
  brandingDetails: {
    ...state.brandingDetails,
    isSaving: false,
  },
});

const removeBrandingSuccess: BrandingReducer = (state = initialState, { payload: { id } }) => ({
  ...state,
  collection: state.collection.filter((item) => item.id !== id),
});

const resetBrandingDetails: BrandingReducer = (state = initialState) => ({
  ...state,
  brandingDetails: {
    ...initialState.brandingDetails,
  },
});

const actionsMap: ActionMap<BrandingState> = {
  [LoadBrandingActionTypes.SUCCESS]: setBrandingSuccess,
  [LoadBrandingActionTypes.START]: setIsFetching(true),
  [LoadBrandingActionTypes.ERROR]: setIsFetching(false),
  [LoadBrandingDetailsActionTypes.SUCCESS]: setBrandingDetailsSuccess,
  [LoadBrandingDetailsActionTypes.START]: promptSetBranding,
  [LoadBrandingDetailsActionTypes.ERROR]: setBrandingDetailsFailure,
  [CreateBrandingActionTypes.START]: promptCreateBranding,
  [CreateBrandingActionTypes.SUCCESS]: createBrandingSuccess,
  [CreateBrandingActionTypes.ERROR]: createBrandingFailure,
  [UpdateBrandingActionTypes.START]: promptUpdateBranding,
  [UpdateBrandingActionTypes.SUCCESS]: updateBrandingSuccess,
  [UpdateBrandingActionTypes.ERROR]: updateBrandingFailure,
  [RemoveBrandingActionTypes.SUCCESS]: removeBrandingSuccess,
  [RESET_BRANDING_DETAILS]: resetBrandingDetails,
};

export default reducerWithActionMap(actionsMap, initialState);
