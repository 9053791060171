import RootState from 'ducks/RootState';
import { DataItemsType, ResultKeys } from './types';

export const getTotalCount = (resultKey: ResultKeys) => (state: RootState) => state.paginations[resultKey].total;

export const getIsFetchingPaginatedData = (resultKey: ResultKeys) => (state: RootState) =>
  state.paginations[resultKey].isFetching;

export const getItemsOnPage = <T extends Array<DataItemsType>>(page: number, resultKey: ResultKeys) => (
  state: RootState
): T => state.paginations[resultKey][page] as T;

export const getIsRefundInProgress = (resultKey: ResultKeys) => (state: RootState) =>
  state.paginations[resultKey].refundRequest;
