import React, { PureComponent } from 'react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

// components
import ContentHolder from 'components/ContentHolder';
import SubheaderWithBorder from 'components/SubheaderWithBorder';
import MainHolder from 'components/MainHolder';
import Loader from 'components/Loader';
import { BlueTypography } from 'components/SharedStyledElements';
import AppleInAppForm from './AppleInAppForm';
import GoogleInAppForm from './GoogleInAppForm';
import AmazonInAppForm from './AmazonInAppForm';
import RokuInAppForm from './RokuInAppForm';

// types
import { InAppType } from '../containers/InAppForm';

export const MarginTypography = styled(BlueTypography)`
  margin-bottom: 2rem;
`;

export const FormContainer = styled.div`
  width: 80%;
  margin: 1.5rem 5%;
  padding: 1rem 0;
`;

interface Props {
  changeKeys: (data: string) => any;
  inAppType: string;
  inAppIntegrations: any;
  merchantUuid: string;
  merchantId: number;
}

export default class InAppIntegrationsForm extends PureComponent<Props> {
  handleSubmit = (data: any) => {
    const { changeKeys } = this.props;
    return changeKeys(data);
  };

  renderInAppForm = (type: string) => {
    const { inAppIntegrations, merchantUuid, merchantId } = this.props;
    const { googleInAppKeys, appleInAppKeys, amazonInAppKeys, rokuInAppKeys } = inAppIntegrations;

    switch (type) {
      case InAppType.AppleInApp:
        return (
          <AppleInAppForm
            onSubmit={this.handleSubmit}
            merchantUuid={merchantUuid}
            appleInAppKeys={!isEmpty(appleInAppKeys) ? appleInAppKeys : {}}
          />
        );
      case InAppType.GoogleInApp:
        return (
          <GoogleInAppForm
            onSubmit={this.handleSubmit}
            merchantUuid={merchantUuid}
            googleInAppKeys={!isEmpty(googleInAppKeys) ? googleInAppKeys : {}}
          />
        );
      case InAppType.AmazonInApp:
        return (
          <AmazonInAppForm
            onSubmit={this.handleSubmit}
            amazonInAppKeys={!isEmpty(amazonInAppKeys) ? amazonInAppKeys : {}}
            merchantUuid={merchantUuid}
          />
        );
      case InAppType.RokuInApp:
        return (
          <RokuInAppForm
            onSubmit={this.handleSubmit}
            merchantId={merchantId}
            rokuInAppKeys={!isEmpty(rokuInAppKeys) ? rokuInAppKeys : {}}
          />
        );
      default:
        break;
    }

    return null;
  };

  getTitleName = (type: string) => {
    switch (type) {
      case InAppType.AppleInApp:
        return 'Apple App Store';
      case InAppType.GoogleInApp:
        return 'Google Play';
      case InAppType.AmazonInApp:
        return 'Amazon';
      case InAppType.RokuInApp:
        return 'Roku';
      default:
        break;
    }
  };

  render() {
    const {
      inAppType,
      inAppIntegrations: { isFetching },
    } = this.props;

    if (isFetching) {
      return <Loader />;
    }

    return (
      <>
        <MainHolder>
          <SubheaderWithBorder>
            <ContentHolder>
              <BlueTypography variant="h1">Connect your {this.getTitleName(inAppType)} account</BlueTypography>
            </ContentHolder>
          </SubheaderWithBorder>
          <FormContainer>{this.renderInAppForm(inAppType)}</FormContainer>
        </MainHolder>
      </>
    );
  }
}
