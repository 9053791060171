import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  PackagesActionTypes,
  PackageAssetsActionTypes,
  AllAssetsActionTypes,
  PackageDetailsActionTypes,
  ReactivatePackageActionTypes,
  DeleteAllAssetsActionTypes,
  ResetPackageAssetsActionTypes,
  AddAllAssetsActionTypes,
} from 'ducks/merchantDucks/packages/actions';
import PackagesState from 'ducks/merchantDucks/packages/types';

export const initialState: PackagesState = {
  total: 0,
  page: 1,
  offset: 0,
  limit: 10,
  collection: [],
  isFetching: false,
  access_fees: [],
  packageAssets: {
    collection: [],
    isFetching: false,
    total: 0,
    limit: 10,
    page: 1,
    offset: 0,
    isDeleted: false,
  },
  allAssets: {
    collection: [],
    isFetching: false,
    total: 0,
    limit: 10,
    page: 1,
    offset: 0,
  },
  packageDetails: {
    id: 0,
    merchant_id: 0,
    title: '',
    content: '',
    is_active: false,
    item_type: {},
    metadata: {
      description: '',
      thumbnail_image: '',
      preview_image: '',
      preview_image_name: '',
      thumbnail_image_name: '',
    },
    access_fees: [],
    isSaving: false,
    isFetching: false,
    isCreated: false,
    number_of_items: 0,
    data: '',
    isFailed: false,
  },
};

type PackagesReducer = Reducer<PackagesState>;

const loadPackagesSuccess: PackagesReducer = (state = initialState, { payload }) => ({
  ...state,
  ...payload,
  collection: payload.concat ? [...state.collection, ...payload.collection] : payload.collection,
  isFetching: false,
});

const loadPackagesError: PackagesReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const loadPackages: PackagesReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const deletePackageFromCollection: PackagesReducer = (state = initialState, { payload: { id } }) => ({
  ...state,
  collection: state.collection.filter((item) => item.id !== id),
});

const resetPackageAssets: PackagesReducer = (state = initialState) => ({
  ...state,
  ...initialState,
  allAssets: state.allAssets,
  packageDetails: state.packageDetails,
});

const loadPackageAssetsSuccess: PackagesReducer = (
  state = initialState,
  { payload: { collection, total, appendData } }
) => ({
  ...state,
  packageAssets: {
    ...state.packageAssets,
    collection: appendData ? [...state.packageAssets.collection, ...collection] : [...collection],
    isFetching: false,
    total,
  },
});

const loadPackageAssetsError: PackagesReducer = (state = initialState) => ({
  ...state,
  packageAssets: {
    ...state.packageAssets,
    isFetching: false,
  },
});

const loadPackageAssets: PackagesReducer = (state = initialState) => ({
  ...state,
  packageAssets: {
    ...state.packageAssets,
    isFetching: true,
  },
});

const deleteAllPackageAssetsSuccess: PackagesReducer = (state = initialState) => ({
  ...state,
  packageAssets: {
    ...initialState.packageAssets,
    isDeleted: true,
  },
});

const deleteAllPackageAssets: PackagesReducer = (state = initialState) => ({
  ...state,
  packageAssets: {
    ...state.packageAssets,
    isFetching: true,
  },
  allAssets: {
    ...state.allAssets,
    isFetching: true,
  },
});

const loadAllAssetsSuccess: PackagesReducer = (
  state = initialState,
  { payload: { collection, total, appendData } }
) => ({
  ...state,
  allAssets: {
    ...state.allAssets,
    collection: appendData ? [...state.allAssets.collection, ...collection] : [...collection],
    isFetching: false,
    total,
  },
});

const loadAllAssetsFailure: PackagesReducer = (state = initialState) => ({
  ...state,
  allAssets: {
    ...state.allAssets,
    isFetching: false,
  },
});

const loadAllAssets: PackagesReducer = (state = initialState) => ({
  ...state,
  allAssets: {
    ...state.allAssets,
    isFetching: true,
  },
});

const loadPackageDetailsSuccess: PackagesReducer = (state = initialState, { payload }) => ({
  ...state,
  packageDetails: {
    ...state.packageDetails,
    ...payload,
    isFetching: false,
  },
});

const loadPackageDetailsFailure: PackagesReducer = (state = initialState, { payload }) => ({
  ...state,
  packageDetails: {
    ...state.packageDetails,
    isFetching: false,
    data: payload.message,
  },
});

const loadPackageDetails: PackagesReducer = (state = initialState) => ({
  ...state,
  packageDetails: {
    ...state.packageDetails,
    isFetching: true,
  },
});

const addAllAssetsToPackageSuccess: PackagesReducer = (state = initialState) => ({
  ...state,
  allAssets: {
    ...initialState.allAssets,
  },
  packageAssets: {
    ...state.packageAssets,
    isFetching: false,
  },
});

const addAllAssetsToPackage: PackagesReducer = (state = initialState) => ({
  ...state,
  allAssets: {
    ...state.allAssets,
    isFetching: true,
  },
  packageAssets: {
    ...state.packageAssets,
    isFetching: true,
  },
});

const actionsMap: ActionMap<PackagesState> = {
  [PackagesActionTypes.SUCCESS]: loadPackagesSuccess,
  [PackagesActionTypes.FAILURE]: loadPackagesError,
  [PackagesActionTypes.START]: loadPackages,
  [ReactivatePackageActionTypes.SUCCESS]: deletePackageFromCollection,
  [ResetPackageAssetsActionTypes.SUCCESS]: resetPackageAssets,
  [PackageAssetsActionTypes.SUCCESS]: loadPackageAssetsSuccess,
  [PackageAssetsActionTypes.FAILURE]: loadPackageAssetsError,
  [PackageAssetsActionTypes.START]: loadPackageAssets,
  [DeleteAllAssetsActionTypes.SUCCESS]: deleteAllPackageAssetsSuccess,
  [DeleteAllAssetsActionTypes.START]: deleteAllPackageAssets,
  [AllAssetsActionTypes.SUCCESS]: loadAllAssetsSuccess,
  [AllAssetsActionTypes.START]: loadAllAssets,
  [AllAssetsActionTypes.FAILURE]: loadAllAssetsFailure,
  [PackageDetailsActionTypes.SUCCESS]: loadPackageDetailsSuccess,
  [PackageDetailsActionTypes.ERROR]: loadPackageDetailsFailure,
  [PackageDetailsActionTypes.START]: loadPackageDetails,
  [AddAllAssetsActionTypes.SUCCESS]: addAllAssetsToPackageSuccess,
  [AddAllAssetsActionTypes.START]: addAllAssetsToPackage,
};

export default reducerWithActionMap(actionsMap, initialState);
