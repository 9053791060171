import React, { lazy } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router';

// HOC
import { withTopNav } from 'components/Navigation';

// types
import { AdminComponentTypes } from 'pages/AdminPages/types';

// components
import SubPayments from 'pages/AdminPages/subpages/SubPayments';
import SubMerchants from 'pages/AdminPages/subpages/SubMerchants';
import SubAudience from 'pages/AdminPages/subpages/SubAudience';

// Pages
const SubscriptionDetails = lazy(
  () => import('pages/MerchantPages/Transactions/Subscriptions/components/SubscriptionDetails')
);
const ViewReports = lazy(() => import('components/Reports/ViewReports'));
const AdminAccesses = lazy(() => import('pages/AdminPages/AdminAccesses/AdminAccesses'));
const AdminSubscriptions = lazy(() => import('pages/AdminPages/AdminSubscriptions/AdminSubscriptions'));
const MissingTransactions = lazy(() => import('../AdminPayments/MissingTransactions'));

type Props = {
  componentType: AdminComponentTypes;
};

const GenericAdminRouteComponents = ({ componentType }: Props) => {
  const { path } = useRouteMatch();

  // since payments is using the title prop as 'transaction' in ViewReports component
  const payments = componentType === AdminComponentTypes.PAYMENTS ? 'transactions' : '';

  let AdminComponentRoute;
  if (componentType === AdminComponentTypes.AUDIENCE) {
    AdminComponentRoute = SubAudience;
  } else if (componentType === AdminComponentTypes.PAYMENTS) {
    AdminComponentRoute = SubPayments;
  } else if (componentType === AdminComponentTypes.SUBSCRIPTIONS) {
    AdminComponentRoute = AdminSubscriptions;
  } else if (componentType === AdminComponentTypes.MERCHANTS) {
    AdminComponentRoute = SubMerchants;
  } else AdminComponentRoute = AdminAccesses;

  return (
    <Switch>
      <Route exact path={`${path}/reports`}>
        <ViewReports
          title={payments && 'payments'}
          type={payments || componentType}
          backUrl={`admin/${componentType}`}
        />
      </Route>
      <Route exact path={`${path}/missing-transactions`}>
        <MissingTransactions />
      </Route>
      {path === '/admin/subscriptions' && (
        <Route exact path={`${path}/:subscriptionId`}>
          <SubscriptionDetails />
        </Route>
      )}
      <Route path={path}>
        <AdminComponentRoute />
      </Route>
    </Switch>
  );
};

export default withTopNav(GenericAdminRouteComponents);
