import React from 'react';
import styled from 'styled-components';
import { Colors, AnalyticsComponent } from '@inplayer-org/inplayer-ui';
import { NavLink } from 'react-router-dom';
import snakeCase from 'lodash/snakeCase';

// utils
import { unescapeText } from 'utils/helpers';

// components
import { Container, Title } from 'pages/MerchantPages/Analytics/components/shared';

const InfoContainer = styled.div`
  margin: 1rem 0;
  color: ${Colors.greenSea};
  font-weight: ${({ theme }) => theme.font.weights.bold};
  font-size: ${({ theme }) => theme.font.sizes.large};
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: underline;
  width: fit-content;
  margin: 0 auto;
  color: ${Colors.belizeHole};
`;

interface Props {
  title: string;
  icon: string;
  subtitle: string;
  amount: string;
  itemId: number;
  email?: string;
}

interface NavUrlParams {
  itemId?: number;
  email?: string;
  subtitle: string;
}

const buildNavUrl = ({ itemId, email, subtitle }: NavUrlParams) => {
  if (!itemId) return <h3>{unescapeText(subtitle)}</h3>;

  const linkRoute = email ? `audience/edit/${itemId}` : `assets/edit/${itemId}`;

  return (
    <AnalyticsComponent>
      {({ pages, tracker, merchantId, ip }) => (
        <StyledNavLink
          onClick={() => {
            tracker.track({
              event: 'click',
              type: 'link',
              tag: `link_${snakeCase(subtitle)}`,
              pages,
              merchantId,
              ip,
            });
          }}
          data-testid={`subtitle-${itemId}`}
          to={linkRoute}
        >
          {unescapeText(subtitle)}
        </StyledNavLink>
      )}
    </AnalyticsComponent>
  );
};

const SingleTopInfo: React.FunctionComponent<Props> = ({ title, icon: Icon, subtitle, amount, email, itemId }) => (
  <Container width="49%">
    <Title variant="p">{unescapeText(title)}</Title>
    <Icon />
    <InfoContainer>
      {buildNavUrl({ itemId, subtitle, email })}
      {email && <h6>{unescapeText(email)}</h6>}
      <h3>{amount}</h3>
    </InfoContainer>
  </Container>
);

export default SingleTopInfo;
