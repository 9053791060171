import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  LoadRegisteredUsersCountActionTypes,
  LoadCountryUsersCountActionTypes,
  LoadCountrySubscriptionsCountActionTypes,
  LoadSalesCountActionTypes,
  LoadCountrySalesCountActionTypes,
  LoadSubscriptionsCountActionTypes,
  LoadSubscriptionsActionTypes,
  LoadReportsActionTypes,
  RemoveReportActionTypes,
  LoadReportInformationActionTypes,
  LoadRevenueActionTypes,
  LoadReportFieldsActionTypes,
  LoadRevenueCountActionTypes,
  RESET_REPORT_FIELDS,
  RenameReportTypes,
  RenameReportsSuccessData,
  FilterMerchantId,
  LoadScheduledReportsActionTypes,
  StoreEditingScheduledReport,
  CommercialReportActionTypes,
  LoadMissingTransactionsActionTypes,
} from 'ducks/merchantDucks/reports/actions';
import ReportsState from 'ducks/merchantDucks/reports/types';

export const initialState: ReportsState = {
  histogram: {
    allSales: {
      total: 0,
      collection: [],
    },
    countrySales: {
      total: 0,
      collection: [],
    },
    revenue: {
      total: 0,
      collection: [],
    },
    assetSales: {
      total: 0,
      collection: [],
    },
    countryAssetSales: {
      total: 0,
      collection: [],
    },
    packageSales: {
      total: 0,
      collection: [],
    },
    countryPackageSales: {
      total: 0,
      collection: [],
    },
    customers: {
      total: 0,
      collection: [],
    },
    countryCustomers: {
      total: 0,
      collection: [],
    },
    subscriptions: {
      total: 0,
      collection: [],
    },
    countrySubscriptions: {
      total: 0,
      collection: [],
    },
  },
  isFetching: false,
  customers: {
    total: 0,
    collection: [],
  },
  subscriptions: {
    total: 0,
    page: 0,
    offset: 0,
    limit: 15,
    collection: [],
  },
  csvReports: {
    reports: [],
    isFetching: false,
    reportsForMerchantId: 0,
  },
  scheduledReports: {
    reports: [],
    editingReport: {
      active: false,
      id: 0,
      merchant_id: 0,
      report_type: '',
      scheduled_at: '',
      recurrent: false,
      created_at: '',
      report_name: '',
      start_date_resolution: '',
      start_date_resolution_before: 0,
      data_start_range: '',
      data_end_range: '',
      scheduled_resolution: '',
      scheduled_resolution_before: 0,
    },
    isFetching: false,
  },
  missingTransactions: {
    transactions: [],
    isFetching: false,
    total: 0,
  },
  commercialSetup: {
    reports: [],
    isFetching: false,
  },
  reportInformation: {
    numberOfCanceledSubscriptions: { total: 0, histogram: [] },
    numberOfCreatedSubscriptions: { total: 0, histogram: [] },
    numberOfPayments: 0,
    numberOfRegisteredUsers: 0,
    topFiveCustomers: [],
    topFiveAssets: [],
    isFetching: false,
  },
  revenuePerCurrency: {
    revenue: [],
    isFetching: false,
  },
  reportFields: {
    fields: [],
    isFetching: false,
  },
};

type ReportsReducer = Reducer<ReportsState>;

const setIsFetching = (isFetching: boolean): ReportsReducer => (state = initialState) => ({
  ...state,
  isFetching,
});

const loadSubscriptionsCountSuccess: ReportsReducer = (state = initialState, { payload }) => ({
  ...state,
  isFetching: false,
  histogram: {
    ...state.histogram,
    subscriptions: {
      ...state.subscriptions,
      ...payload,
    },
  },
});

const loadSubscriptionsCountByCountrySuccess: ReportsReducer = (state = initialState, { payload }) => ({
  ...state,
  isFetching: false,
  histogram: {
    ...state.histogram,
    countrySubscriptions: {
      ...state.histogram.countrySubscriptions,
      ...payload,
    },
  },
});

const loadRegisteredUsersCountSuccess: ReportsReducer = (state = initialState, { payload }) => ({
  ...state,
  isFetching: false,
  histogram: {
    ...state.histogram,
    customers: {
      ...state.customers,
      ...payload,
    },
  },
});

const loadRegisteredUsersCountByCountrySuccess: ReportsReducer = (state = initialState, { payload }) => ({
  ...state,
  isFetching: false,
  histogram: {
    ...state.histogram,
    countryCustomers: {
      ...state.histogram.countryCustomers,
      ...payload,
    },
  },
});

const loadSalesCountSuccess: ReportsReducer = (state = initialState, { payload }) => ({
  ...state,
  isFetching: false,
  histogram: {
    ...state.histogram,
    allSales: {
      ...state.histogram.allSales,
      ...payload,
    },
  },
});

const loadSalesCountByCountrySuccess: ReportsReducer = (state = initialState, { payload }) => ({
  ...state,
  isFetching: false,
  histogram: {
    ...state.histogram,
    countrySales: {
      ...state.histogram.countrySales,
      ...payload,
    },
  },
});

const loadRevenueCountSuccess: ReportsReducer = (state = initialState, { payload }) => ({
  ...state,
  isFetching: false,
  histogram: {
    ...state.histogram,
    revenue: {
      ...state.histogram.revenue,
      ...payload,
    },
  },
});

const loadSubscriptionsSuccess: ReportsReducer = (state = initialState, { payload }) => ({
  ...state,
  subscriptions: {
    ...state.subscriptions,
    ...payload,
  },
  isFetching: false,
});

const loadReports: ReportsReducer = (state = initialState, { payload: { reportType } }) => ({
  ...state,
  [reportType]: {
    ...state[reportType],
    isFetching: true,
  },
});

const loadMissingTransactionsStart: ReportsReducer = (state = initialState) => ({
  ...state,
  missingTransactions: {
    ...state.missingTransactions,
    isFetching: true,
  },
});

const loadMissingTransactionsSuccess: ReportsReducer = (
  state = initialState,
  { payload: { ListErrorFiles, total_pages } }
) => ({
  ...state,
  missingTransactions: {
    ...state.missingTransactions,
    transactions: [...ListErrorFiles],
    total: total_pages,
    isFetching: false,
  },
});

const loadMissingTransactionsError: ReportsReducer = (state = initialState) => ({
  ...state,
  missingTransactions: {
    ...state.missingTransactions,
    isFetching: false,
  },
});

const loadSchedulingReports: ReportsReducer = (state = initialState) => ({
  ...state,
  scheduledReports: {
    ...state.scheduledReports,
    isFetching: true,
  },
});

const loadScheduledReportsSuccess: ReportsReducer = (state = initialState, { payload }) => ({
  ...state,
  scheduledReports: {
    ...state.scheduledReports,
    reports: [...payload],
    isFetching: false,
  },
});

const loadScheduledReportsError: ReportsReducer = (state = initialState) => ({
  ...state,
  scheduledReports: {
    ...state.scheduledReports,
    isFetching: false,
  },
});

const storeEditingScheduledReport: ReportsReducer = (state = initialState, { payload }) => ({
  ...state,
  scheduledReports: {
    ...state.scheduledReports,
    editingReport: { active: true, ...payload },
  },
});

const resetEditingScheduledReport: ReportsReducer = (state = initialState) => ({
  ...state,
  scheduledReports: {
    ...state.scheduledReports,
    editingReport: initialState.scheduledReports.editingReport,
  },
});

const saveFilterMechantId: ReportsReducer = (state = initialState, { payload }) => ({
  ...state,
  csvReports: {
    ...state.csvReports,
    reportsForMerchantId: payload,
  },
});

const renameReport: ReportsReducer | RenameReportsSuccessData = (
  state = initialState,
  { payload: { id, newFieldName } }
) => {
  const newReports = state.csvReports.reports.map((report) => {
    if (report.created_at !== id) return report;

    return {
      ...report,
      filename: newFieldName,
    };
  });

  return {
    ...state,
    csvReports: {
      ...state.csvReports,
      reports: newReports,
    },
  };
};

const loadReportsError: ReportsReducer = (state = initialState, { payload: { reportType } }) => ({
  ...state,
  [reportType]: {
    ...state[reportType],
    isFetching: false,
  },
});

const loadReportsSuccess: ReportsReducer = (state = initialState, { payload: { reports, reportType } }) => ({
  ...state,
  [reportType]: {
    ...state[reportType],
    reports: [...reports],
    isFetching: false,
  },
});

const deleteReportSuccess: ReportsReducer = (state = initialState, { payload }) => {
  const { filename } = payload;

  const {
    csvReports: { reports: allReports },
  } = state;

  const reports = allReports.filter((item) => item.filename.split('.')[0] !== filename && item.filename !== filename);

  return {
    ...state,
    csvReports: {
      ...state.csvReports,
      reports,
    },
  };
};

const loadReportInformationSuccess: ReportsReducer = (state = initialState, { payload }) => ({
  ...state,
  reportInformation: {
    ...state.reportInformation,
    ...payload,
    isFetching: false,
  },
});

const loadReportInformation: ReportsReducer = (state = initialState) => ({
  ...state,
  reportInformation: {
    ...state.reportInformation,
    isFetching: true,
  },
});

const loadReportInformationError: ReportsReducer = (state = initialState) => ({
  ...state,
  reportInformation: {
    ...state.reportInformation,
    isFetching: false,
  },
});

const loadRevenueSuccess: ReportsReducer = (state = initialState, { payload }) => ({
  ...state,
  revenuePerCurrency: {
    ...payload,
    isFetching: false,
  },
});

const loadRevenue: ReportsReducer = (state = initialState) => ({
  ...state,
  revenuePerCurrency: {
    ...state.revenuePerCurrency,
    isFetching: true,
  },
});

const loadRevenueError: ReportsReducer = (state = initialState) => ({
  ...state,
  revenuePerCurrency: {
    ...state.revenuePerCurrency,
    isFetching: false,
  },
});

const loadReportFieldsSuccess: ReportsReducer = (state = initialState, { payload: { fields } }) => {
  const newFields = Object.values(fields).map((field: any) => {
    return { value: field, label: field };
  });

  return {
    ...state,
    reportFields: {
      fields: newFields,
      isFetching: false,
    },
  };
};

const loadReportFields: ReportsReducer = (state = initialState) => ({
  ...state,
  reportFields: {
    ...state.reportFields,
    isFetching: true,
  },
});

const loadReportFieldsError: ReportsReducer = (state = initialState) => ({
  ...state,
  reportFields: {
    ...state.reportFields,
    isFetching: false,
  },
});

const resetReportFields: ReportsReducer = (state = initialState) => ({
  ...state,
  reportFields: initialState.reportFields,
});

const actionsMap: ActionMap<ReportsState> = {
  // Histrogram reducers
  [LoadRegisteredUsersCountActionTypes.START]: setIsFetching(true),
  [LoadCountryUsersCountActionTypes.START]: setIsFetching(true),
  [LoadSubscriptionsCountActionTypes.START]: setIsFetching(true),
  [LoadCountrySubscriptionsCountActionTypes.START]: setIsFetching(true),
  [LoadSalesCountActionTypes.START]: setIsFetching(true),
  [LoadCountrySalesCountActionTypes.START]: setIsFetching(true),
  [LoadRevenueCountActionTypes.START]: setIsFetching(true),
  [LoadRegisteredUsersCountActionTypes.ERROR]: setIsFetching(false),
  [LoadCountryUsersCountActionTypes.ERROR]: setIsFetching(false),
  [LoadSubscriptionsCountActionTypes.ERROR]: setIsFetching(false),
  [LoadCountrySubscriptionsCountActionTypes.ERROR]: setIsFetching(false),
  [LoadSalesCountActionTypes.ERROR]: setIsFetching(false),
  [LoadCountrySalesCountActionTypes.ERROR]: setIsFetching(false),
  [LoadRevenueCountActionTypes.ERROR]: setIsFetching(false),
  [LoadSubscriptionsCountActionTypes.SUCCESS]: loadSubscriptionsCountSuccess,
  [LoadCountrySubscriptionsCountActionTypes.SUCCESS]: loadSubscriptionsCountByCountrySuccess,
  [LoadRegisteredUsersCountActionTypes.SUCCESS]: loadRegisteredUsersCountSuccess,
  [LoadCountryUsersCountActionTypes.SUCCESS]: loadRegisteredUsersCountByCountrySuccess,
  [LoadSalesCountActionTypes.SUCCESS]: loadSalesCountSuccess,
  [LoadCountrySalesCountActionTypes.SUCCESS]: loadSalesCountByCountrySuccess,
  [LoadRevenueCountActionTypes.SUCCESS]: loadRevenueCountSuccess,
  [FilterMerchantId.SAVE_MERCHANT_ID_ON_FILTER]: saveFilterMechantId,
  // Full reports
  [LoadSubscriptionsActionTypes.START]: setIsFetching(true),
  [LoadSubscriptionsActionTypes.ERROR]: setIsFetching(false),
  [LoadSubscriptionsActionTypes.SUCCESS]: loadSubscriptionsSuccess,
  [LoadReportsActionTypes.START]: loadReports,
  [CommercialReportActionTypes.SUCCESS]: loadReports,
  [RenameReportTypes.SUCCESS]: renameReport,
  [LoadReportsActionTypes.ERROR]: loadReportsError,
  [LoadReportsActionTypes.SUCCESS]: loadReportsSuccess,
  [LoadScheduledReportsActionTypes.START]: loadSchedulingReports,
  [LoadScheduledReportsActionTypes.SUCCESS]: loadScheduledReportsSuccess,
  [LoadScheduledReportsActionTypes.ERROR]: loadScheduledReportsError,
  [LoadMissingTransactionsActionTypes.START]: loadMissingTransactionsStart,
  [LoadMissingTransactionsActionTypes.SUCCESS]: loadMissingTransactionsSuccess,
  [LoadMissingTransactionsActionTypes.ERROR]: loadMissingTransactionsError,
  [StoreEditingScheduledReport.STORE]: storeEditingScheduledReport,
  [StoreEditingScheduledReport.RESET]: resetEditingScheduledReport,
  [RemoveReportActionTypes.SUCCESS]: deleteReportSuccess,
  [LoadReportInformationActionTypes.SUCCESS]: loadReportInformationSuccess,
  [LoadReportInformationActionTypes.START]: loadReportInformation,
  [LoadReportInformationActionTypes.ERROR]: loadReportInformationError,
  [LoadRevenueActionTypes.SUCCESS]: loadRevenueSuccess,
  [LoadRevenueActionTypes.START]: loadRevenue,
  [LoadRevenueActionTypes.ERROR]: loadRevenueError,
  [LoadReportFieldsActionTypes.SUCCESS]: loadReportFieldsSuccess,
  [LoadReportFieldsActionTypes.START]: loadReportFields,
  [LoadReportFieldsActionTypes.ERROR]: loadReportFieldsError,
  [RESET_REPORT_FIELDS]: resetReportFields,
};

export default reducerWithActionMap(actionsMap, initialState);
