import { API } from 'constants/api';
import http from 'utils/http';
import { ApiAction } from 'ducks/types';
import { LoadReportsCommonParams } from 'ducks/merchantDucks/reports/actions';

// Unsubscribe
export enum FetchPaymentAnalyticsTypes {
  START = 'FETCH_PAYMENT_ANALYTICS_REQUEST',
  SUCCESS = 'FETCH_PAYMENT_ANALYTICS_SUCCESS',
  FAILURE = 'FETCH_PAYMENT_ANALYTICS_FAILURE',
}

export const fetchPaymentAnalytics = ({
  startDate,
  endDate,
}: LoadReportsCommonParams): ApiAction<FetchPaymentAnalyticsTypes> => async (dispatch) => {
  const endpoint = API.PAYMENTS_ANALYTICS;
  const params = { startDate, endDate };

  try {
    dispatch({ type: FetchPaymentAnalyticsTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: FetchPaymentAnalyticsTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({
      type: FetchPaymentAnalyticsTypes.FAILURE,
    });
  }
};

export enum FetchAssetPaymentPerCountryTypes {
  START = 'FETCH_ASSET_PAYMENT_PER_COUNTRY_REQUEST',
  SUCCESS = 'FETCH_ASSET_PAYMENT_PER_COUNTRY_SUCCESS',
  FAILURE = 'FETCH_ASSET_PAYMENT_PER_COUNTRY_FAILURE',
}

export const fetchAssetPaymentPerCountry = (
  itemId: number,
  startDate: string,
  endDate: string
): ApiAction<FetchAssetPaymentPerCountryTypes> => async (dispatch) => {
  const endpoint = API.ASSET_PAYMENT_PER_COUNTRY;
  const params = {
    itemId,
    startDate,
    endDate,
  };

  try {
    dispatch({ type: FetchAssetPaymentPerCountryTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: FetchAssetPaymentPerCountryTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({
      type: FetchAssetPaymentPerCountryTypes.FAILURE,
    });
  }
};
export enum FetchPaymentMethodsPerCountryTypes {
  START = 'FETCH_PAYMENT_METHODS_PER_COUNTRY_REQUEST',
  SUCCESS = 'FETCH_PAYMENT_METHODS_PER_COUNTRY_SUCCESS',
  FAILURE = 'FETCH_PAYMENT_METHODS_PER_COUNTRY_FAILURE',
}
export const fetchPaymentMethodsPerCountry = (
  startDate: string,
  endDate: string
): ApiAction<FetchPaymentMethodsPerCountryTypes> => async (dispatch) => {
  const endpoint = API.PAYMENT_METHODS_PER_COUNTRY;
  const params = {
    startDate,
    endDate,
  };

  try {
    dispatch({ type: FetchPaymentMethodsPerCountryTypes.START });
    const response = await http.authenticated().get(endpoint, { params });
    dispatch({
      type: FetchPaymentMethodsPerCountryTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({
      type: FetchPaymentMethodsPerCountryTypes.FAILURE,
    });
  }
};
