import React, { useState, useMemo, useEffect } from 'react';
import {
  TableColumn,
  Grid,
  RowActionsRender,
  Dropdown,
  Typography,
  TableColumn$RenderProps,
  Option,
} from '@inplayer-org/inplayer-ui';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// components
import GenericTable from 'components/Table/GenericTable';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { convertArrayToCamelCase } from 'utils/helpers';
import { StyledButton } from 'components/SharedStyledElements';

// actions
import { downloadReport } from 'ducks/merchantDucks/items/actions';
import { openModal } from 'ducks/merchantDucks/modal/actions';
import { generateCommercialReport, removeReport, loadReports } from 'ducks/merchantDucks/reports/actions';

// types
import RootState from 'ducks/RootState';
import { ModalNames } from 'ducks/merchantDucks/modal/types';
import { CommercialsTableDetails, CommercialsTable } from 'ducks/adminDucks/paginations/types';

// selectors
import { getCommercialReports } from 'ducks/merchantDucks/reports/selectors';

// styles
import ContentHolder from 'components/ContentHolder';
import CommonIcon from 'components/CommonIcon';
import SubpageHeader from 'components/SubpageHeader/SubpageHeader';
import { TableContainer } from 'pages/AdminPages/components/SharedStyledElements';

// options
import { monthOptions, yearOptions, reportOptions, ReportTypes } from './options';

const { Container, Cell } = Grid;

enum Intervals {
  FIRST_FIVE_MONTHS = 5,
  STRING_2022 = '2022',
  NUMBER_2022 = 2022,
  FIRST_MONTH = 0,
  STRING_ONE = '01',
}

const availableMonths = (reportType: ReportTypes, year: string) => {
  if (reportType !== ReportTypes.MAU) return monthOptions;

  let returnedOptions: Option[] = monthOptions;

  const currentMonth = moment().month();
  const currentYear = moment().year();

  // if year is 2022, all months till May should be disabled since we don't have MAU reports
  returnedOptions = monthOptions.map((month) => {
    return {
      ...month,
      disabled: Number(year) === Intervals.NUMBER_2022 && Number(month.value) <= Intervals.FIRST_FIVE_MONTHS,
    };
  });

  if (Number(year) > currentYear && currentMonth === Intervals.FIRST_MONTH) {
    returnedOptions = monthOptions.map((month) => {
      return { ...month, disabled: true };
    });
  }

  returnedOptions = returnedOptions.map((month) => {
    if (Number(year) >= currentYear && Number(month.value) > currentMonth) {
      return { ...month, disabled: true };
    }
    return { ...month };
  });

  return returnedOptions;
};

const availableYears = (reportType: ReportTypes) => {
  if (reportType !== ReportTypes.MAU) return yearOptions;
  const currentYear = moment().year();

  return yearOptions.map((year) => {
    return { ...year, disabled: Number(year.value) > currentYear };
  });
};

const CommercialReports = () => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState('');

  const [report, setReport] = useState(ReportTypes.COMMERCIALS);
  const years = useMemo(() => availableYears(report), [report]);
  const [year, setYear] = useState<string>(Intervals.STRING_2022);

  useEffect(() => {
    const firstAvailableYear = years.find((y) => !y.disabled)?.value || Intervals.STRING_2022;
    setYear(firstAvailableYear || Intervals.STRING_2022);
  }, [report]);

  const months = useMemo(() => availableMonths(report, year), [year, report]);

  const [month, setMonth] = useState<string>(Intervals.STRING_ONE);

  useEffect(() => {
    const firstAvailableMonth = months.find((m) => !m.disabled)?.value;
    if (report === ReportTypes.MAU) {
      setMonth(firstAvailableMonth || Intervals.STRING_ONE);
    }
  }, [year, report]);

  const { reports: commercialReports, isFetching } = useSelector((state: RootState) => getCommercialReports(state));

  useEffect(() => {
    dispatch(loadReports(ReportTypes.COMMERCIALS));
    if (!isFetching) {
      dispatch(loadReports(ReportTypes.COMMERCIALS));
    }
  }, []);

  const generateColumns = (): Array<TableColumn<CommercialsTableDetails>> => {
    const columns: Array<TableColumn<CommercialsTableDetails>> = [
      {
        title: 'Report name',
        key: 'filename',
      },
      {
        title: 'Created at (UTC)',
        key: 'createdAt',
        render: ({ value }: TableColumn$RenderProps<CommercialsTableDetails, number>) =>
          value > 0
            ? moment(value * 1000)
                .utc()
                .format('YYYY/MM/DD HH:mm')
            : null,
      },
    ];

    return columns;
  };

  const handleGenerate = () => {
    if (report === ReportTypes.MAU) {
      const mauFilename = `mau_${year}_${month}.csv`;
      return dispatch(downloadReport(mauFilename, ReportTypes.MAU));
    }
    dispatch(generateCommercialReport(`${year}-${month}-01`));
  };

  const handleDownloadReport = (filename: string) => () => {
    dispatch(downloadReport(filename, ReportTypes.COMMERCIALS));
  };

  const renderRowActions: RowActionsRender<CommercialsTableDetails> = ({ row }) => (
    <>
      <CommonIcon
        tag={`icon_delete_${row.createdAt}`}
        hoverStatus="more"
        name="download"
        onClick={handleDownloadReport(row.filename)}
      />
      <CommonIcon
        tag={`icon_delete_${row.createdAt}`}
        hoverStatus="danger"
        name="delete"
        onClick={() => {
          setFileName(row.filename);
          dispatch(openModal(ModalNames.DeleteCommercialReport));
        }}
      />
    </>
  );

  const reportsCollection = convertArrayToCamelCase<CommercialsTable, CommercialsTableDetails>(commercialReports);

  return (
    <ContentHolder>
      <SubpageHeader title="Commercial setup reports" />
      <Container>
        <Cell middle width={1}>
          <Typography variant="p">pick a month</Typography>
          <Dropdown value={month} options={months} onChange={(value: string) => setMonth(value)} />
        </Cell>
        <Cell middle width={1}>
          <Typography variant="p">pick a year</Typography>
          <Dropdown value={year} options={years} onChange={(value: string) => setYear(value)} />
        </Cell>
        <Cell middle width={2}>
          <Typography variant="p">pick a report</Typography>
          <Dropdown
            value={report}
            options={reportOptions}
            onChange={(value: string) => setReport(value as ReportTypes)}
          />
        </Cell>
        <Cell style={{ justifyContent: 'end' }} middle width={2}>
          <StyledButton onClick={handleGenerate}>Generate report</StyledButton>
        </Cell>
      </Container>
      <TableContainer>
        <GenericTable
          columns={generateColumns()}
          data={reportsCollection}
          options={{ rowActions: renderRowActions }}
          noDataMessage="There are no available commercial reports."
          showLoader={isFetching}
        />
      </TableContainer>
      <ConfirmModal
        tag="delete_scheduled_report_modal"
        modalName={ModalNames.DeleteCommercialReport}
        handleConfirm={() => dispatch(removeReport({ type: ReportTypes.COMMERCIALS, filename: fileName }))}
        message="Are you sure you want to delete the commercial report?"
        title="Delete commercial report"
      />
    </ContentHolder>
  );
};

export default CommercialReports;
