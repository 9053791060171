export enum NotificationActionTypes {
  NOTIFICATION_DISPLAY_REQUEST = 'NOTIFICATION_DISPLAY_REQUEST',
}

export interface Notification {
  type: string;
  title: string;
  content: any;
}

export const notifRequested = (notification: Notification) => ({
  type: NotificationActionTypes.NOTIFICATION_DISPLAY_REQUEST,
  payload: {
    ...notification,
  },
});
