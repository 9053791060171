import { Notification } from '@inplayer-org/inplayer-ui';
import { NotificationActionTypes } from 'middleware/notifications/actions';

export default (store) => (next) => (action) => {
  if (action.type !== NotificationActionTypes.NOTIFICATION_DISPLAY_REQUEST) {
    return next(action);
  }

  const { type, content, title } = action.payload;

  Notification.create({
    variant: type,
    title,
    content,
    duration: 3,
  });
};
