import React, { FunctionComponent } from 'react';

// types
import { ModalNames } from 'ducks/merchantDucks/modal/types';

// types
import { ContentIndexes } from 'pages/AdminPages/AdminMerchants/PaymentManagement/components/PaymentSetup';

// components
import BaseModal, { ModalActions } from 'components/Modals/BaseModal';
import PaymentGatewayForm from 'pages/AdminPages/AdminMerchants/PaymentManagement/components/forms/PaymentGatewayForm';
import PaymentFeesForm from 'pages/AdminPages/AdminMerchants/PaymentManagement/components/forms/PaymentFeesForm';
import { useSelector } from 'react-redux';
import { getMerchantFeatures } from 'ducks/adminDucks/merchants/selectors';
import { FeatureNames } from 'ducks/merchantDucks/user/types';
import PaymentGatewayFormFF from '../forms/PaymentGatewayFormFF';

interface OwnProps {
  canEditPaymentGateways: boolean;
  hasInheritance: boolean;
  selectedGateway: string;
  contentIndex: number;
  handleSelectedGatewayChange: (name: string) => void;
  handleContentIndexChange: (index: number) => void;
}

type Props = OwnProps;

const modalTitles = ['Payment Gateway', 'Revenue Setup'];

const SetGatewayModal: FunctionComponent<Props> = ({
  canEditPaymentGateways,
  hasInheritance,
  selectedGateway,
  contentIndex,
  handleSelectedGatewayChange,
  handleContentIndexChange,
}: Props) => {
  const modalTitle = modalTitles[contentIndex];

  const isNewPricingFeatureEnabled = useSelector(getMerchantFeatures).find((x) => x.name === FeatureNames.NEW_PRICING)
    ?.enabled;

  const getModalContent = (closeModal: () => void) => {
    if (contentIndex === ContentIndexes.GATEWAY) {
      return isNewPricingFeatureEnabled ? (
        <PaymentGatewayFormFF handleCloseModal={closeModal} />
      ) : (
        <PaymentGatewayForm
          handleCloseModal={closeModal}
          handleContentIndexChange={handleContentIndexChange}
          handleSelectedGatewayChange={handleSelectedGatewayChange}
        />
      );
    }

    return (
      <PaymentFeesForm
        handleCloseModal={closeModal}
        canEditPaymentGateways={canEditPaymentGateways}
        hasInheritance={hasInheritance}
        selectedGateway={selectedGateway}
      />
    );
  };

  return (
    <BaseModal
      isOverflowVisible
      title={modalTitle}
      modalName={ModalNames.SetGateway}
      render={({ closeModal }: ModalActions) => getModalContent(closeModal)}
    />
  );
};

export default SetGatewayModal;
