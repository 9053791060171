export enum AdminComponentTypes {
  AUDIENCE = 'audience',
  PAYMENTS = 'payments',
  SUBSCRIPTIONS = 'subscriptions',
  ACCESS = 'access',
  MERCHANTS = 'merchants',
}

export enum ReportTypes {
  AUDIENCE = 'audience',
  PAYMENTS = 'transactions',
  SUBSCRIPTIONS = 'subscriptions',
  ACCESS = 'access',
}
