export const items = {
  SAVE_ITEM: 'v2/items',
  UPDATE_ITEM: 'v2/items/{id}',
  DELETE_ITEM_METADATA: 'v2/items/{id}/metadata',
  // Additional parameter event_details = 1 is added in order to retrieve item event details information
  ITEM_DETAILS: 'v2/items/{tenantUuid}/{id}?event_details=1',
  ACCESS_FEES: 'v2/items/{id}/access-fees',
  ACCESS_FEE: 'v2/items/{id}/access-fees/{feeId}',
  REMOVE_ITEM: 'v2/items/{item_id}',
  SEASON_PHASES: '/v2/items/{id}/access-fees/{fee_id}/phases',
  SEASON_PHASE: '/v2/items/{id}/access-fees/{fee_id}/phases/{phase_id}',
  EVENT_DETAILS: '/v2/items/{id}/event_details',
  // Code only
  LOAD_CODES: '/items/access/codes/item/{itemId}',
  SAVE_CODE: '/items/access/codes/create',
  UPDATE_CODE: '/items/access/codes/{codeId}',
  UPDATE_ALL_CODES: '/items/access/codes/{itemId}/bulk',
  LOAD_CODE_SESSIONS: '/items/access/codes/{codeId}/sessions',
  TERMINATE_CODE_SESSION: '/items/access/codes/{codeId}/{browserFingerprint}/merchant',
  GET_CODE_TEMPLATE: 'v2/items/bulk/template/{template}',
  GET_CODE_SIGNED_URL: '/items/access/codes/item/{item_id}/signed-url',
  BULK_CODES: '/items/access/codes/item/{item_id}/upload',
  GET_BULK_ERROR_FILES: '/items/access/codes/bulk/failed/list',
  DOWNLOAD_BULK_FAILED_FILE: '/items/access/codes/bulk/failed/download/{file_name}',
  GENERATE_CODES_REPORT: '/v2/reporting/report/access-codes',
  CODES_REPORTS: '/v2/reporting/reports/{itemId}/{reportType}',
  DOWNLOAD_REPORT: '/v2/reporting/download/{reportType}/{filename}',
  DOWNLOAD_MISSING_TRANSACTION: (filename: string, reportType: string) =>
    `v2/accounting/transactions/import/${reportType}:download?file=${filename}`,
  LIST_SCHEDULED_REPORTS: '/v2/reporting/schedule',
  DELETE_SCHEDULED_REPORT: '/v2/reporting/schedule/delete/{report_id}',
  // Donations
  CREATE_DONATION: 'v2/items/{item_id}/donations',
  GET_DONATIONS: 'v2/items/{item_id}/donations/merchant',
  EDIT_DONATIONS: 'v2/items/{item_id}/donations/{donation_id}',
  UPDATE_CUSTOM_DONATION: 'v2/items/{item_id}/donations/options',
  // Templates
  CREATE_TEMPLATE: '/v2/items/templates',
  TEMPLATE_DETAILS: '/v2/items/templates/{template_id}',
  CREATE_TEMPLATE_PRICE: '/v2/items/templates/{template_id}/prices',
  UPDATE_TEMPLATE_PRICE: '/v2/items/templates/{template_id}/prices/{price_id}',
  DELETE_TEMPLATE_METADATA: 'v2/items/templates/{template_id}/metadata',
  TEMPLATE_EVENT_DETAILS: '/v2/items/templates/{template_id}/event_details',
  UPDATE_TEMPLATE_PREVIEW_TEMPLATE: '/v2/items/templates/{template_id}',
  // LiveLike resource
  LOAD_RESOURCE: (page: number) => `/v2/items/livelike/{resource}?page=${page}`,
  CREATE_LIVELIKE_CHATROOM: '/v2/items/livelike/chatrooms',
  GET_LIVELIKE_CHATROOM: '/v2/items/livelike/chatroom/{chatroomId}',
  DELETE_LIVELIKE_CHATROOM: '/v2/items/livelike/{itemId}/{resourceId}',
  // IVS OVP
  IVS_CHANNELS: '/v2/ovps/ivs/channels',
  GET_IVS_CHANNEL: (arn: string) => `/v2/ovps/ivs/channel?arn=${arn}`,
};
