import React, { lazy } from 'react';
import { Switch, Route } from 'react-router';
import CommercialReports from '../AdminMerchants/Commercials/CommercialReports';

// components
const AdminMerchants = lazy(() => import('pages/AdminPages/AdminMerchants/AdminMerchants'));
const AdminMerchantDetails = lazy(() => import('pages/AdminPages/AdminMerchants/AdminMerchantDetails'));
const AdminRelatedAccounts = lazy(() => import('pages/MerchantPages/Account/AgencySettings/containers/ClientAccounts'));

const SubMerchants = () => {
  return (
    <Switch>
      <Route path="/admin/merchants/:id/related-accounts">
        <AdminRelatedAccounts />
      </Route>
      <Route path="/admin/merchants/edit/:id">
        <AdminMerchantDetails />
      </Route>
      <Route key="/merchants" exact path="/admin/merchants/commercials">
        <CommercialReports />
      </Route>
      <Route key="/merchants" exact path="/admin/merchants">
        <AdminMerchants />
      </Route>
    </Switch>
  );
};

export default SubMerchants;
