import React, { useState, useEffect, useMemo } from 'react';
import Tour, { ReactourStep } from 'reactour';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { Colors, AnalyticsComponent } from '@inplayer-org/inplayer-ui';

// utils
import { getAllTourSteps, getSectionTourSteps } from 'components/TourGuide/tourGuideConfig';

// actions
import { setTourGuideState } from 'ducks/merchantDucks/user/actions';

// selectors
import { getAuthState } from 'ducks/merchantDucks/user/selectors';

// types
import RootState from 'ducks/RootState';
import { Roles } from 'ducks/merchantDucks/user/types';
import { TourTypes } from 'components/TourGuide/tourGuideConfig/TourGuideTypes';

// components
import TourGuideButton from 'components/TourGuide/TourGuideButton';

// hooks
import { useTourGuideState } from 'components/TourGuide/useTourGuideState';

interface Props {
  showSectionTour?: boolean;
  onClose?: Function;
}

export interface TourStep extends ReactourStep {
  location?: string;
  restrictionFeature?: string;
  restrictForRole?: Array<string>;
}

const StyledTour = styled(Tour)`
  max-width: 400px;
  padding-top: 2.25rem;
  line-height: 1.5rem;

  [data-tour-elem='controls'] {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    justify-content: space-between;
  }

  [data-tour-elem='badge'] {
    background: ${Colors.white};
    color: ${Colors.black};
    left: 40%;
    top: initial;
    bottom: 1.75rem;
    box-shadow: none;
    color: inherit;
  }
`;

const TourGuide = ({ showSectionTour, onClose }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const createdAt = useSelector((state: RootState) => state.auth.user.created_at);
  const features = useSelector((state: RootState) => state.auth.features);
  const userRoles = useSelector((state: RootState) => getAuthState(state).user.roles);

  const { isTourActive, isLogoClicked, isSectionTour, isCloseTourClicked, previousLocation } = useTourGuideState();

  const isNewUser = moment().diff(moment(createdAt * 1000), 'hours') < 48;
  const isTourGuideDisabled = localStorage.getItem('tourGuideDisabled');
  const isInitialStep = location.pathname === TourTypes.Analytics;
  const showInitialTour = isInitialStep && isNewUser ? !isTourGuideDisabled : isTourActive;

  const [isTourOpen, setTourOpen] = useState(showInitialTour);
  const [config, setConfig] = useState<Array<TourStep>>(
    getAllTourSteps(history, location.pathname, features, userRoles)
  );
  const [firstStep, setFirstStep] = useState(0);

  const closeTour = () => {
    setTourOpen(false);
    if (onClose) onClose();
    dispatch(
      setTourGuideState({
        isTourGuideActive: false,
        isLogoClicked: false,
        isCloseTourClicked: false,
        isRequestCloseClicked: false,
        isSectionTour: false,
      })
    );
  };

  const openTour = (newConfig: Array<TourStep>) => {
    setConfig(newConfig);
    setTourOpen(true);
    setFirstStep(0);
  };

  useEffect(() => {
    if (isInitialStep) {
      if (isTourActive && previousLocation === TourTypes.Assets) {
        setFirstStep(config.map((item) => item.location).lastIndexOf(location.pathname));
      } else {
        setFirstStep(0);
      }
      dispatch(
        setTourGuideState({
          isTourGuideActive: showInitialTour,
          isRequestCloseClicked: false,
          previousLocation: TourTypes.Analytics,
        })
      );
    } else if (isSectionTour) {
      const newConfig = getSectionTourSteps(history, location.pathname, features, userRoles);
      setFirstStep(newConfig.findIndex((item: TourStep) => item.location === location.pathname));
      setConfig(newConfig);
    } else if (isTourActive) {
      const lastSectionIndex = config.findIndex((item: TourStep) => item.location === previousLocation);
      const currentIndex = config.findIndex((item: TourStep) => item.location === location.pathname);
      if (currentIndex > lastSectionIndex) {
        setFirstStep(currentIndex);
      } else {
        setFirstStep(config.map((item) => item.location).lastIndexOf(location.pathname));
      }
      dispatch(setTourGuideState({ previousLocation: location.pathname }));
    }
  }, []);

  useEffect(() => {
    if (isCloseTourClicked) {
      closeTour();
    }
  }, [isCloseTourClicked]);

  const userRolesNotAllowed = userRoles.some(
    (elem): boolean => [Roles.CONTENT_MANAGER, Roles.CUSTOMER_SUPPORT].indexOf(elem) > -1
  );

  useEffect(() => {
    if (isLogoClicked) {
      if (userRolesNotAllowed) {
        history.push(TourTypes.Assets);
      } else {
        history.push(TourTypes.Analytics);
      }
      dispatch(setTourGuideState({ isTourGuideActive: true, isLogoClicked: false }));
      openTour(getAllTourSteps(history, location.pathname, features, userRoles));
    }
  }, [isLogoClicked]);

  useEffect(() => {
    if (showSectionTour) {
      dispatch(setTourGuideState({ isTourGuideActive: true, isSectionTour: true }));
      openTour(getSectionTourSteps(history, location.pathname, features, userRoles));
    }
  }, [showSectionTour]);

  const onRequestClose = () => {
    dispatch(setTourGuideState({ isRequestCloseClicked: true }));
  };

  const getLastStepButton = () => (
    <TourGuideButton
      onClick={() => {
        closeTour();
        history.push(userRolesNotAllowed ? TourTypes.Assets : TourTypes.Analytics);
      }}
    >
      Go to Dashboard
    </TourGuideButton>
  );

  const tour = useMemo(() => {
    return (
      <AnalyticsComponent>
        {({ pages, tracker, merchantId, ip }) => (
          <StyledTour
            onRequestClose={() => {
              onRequestClose();
              tracker.track({
                event: 'click',
                type: 'icon',
                tag: 'close_tour_guide_modal',
                pages,
                merchantId,
                ip,
              });
            }}
            steps={config}
            isOpen={isTourOpen}
            rounded={5}
            goToStep={firstStep}
            startAt={firstStep}
            showNavigation={false}
            badgeContent={(curr, tot) => `${curr} / ${tot}`}
            nextButton={<TourGuideButton>Next</TourGuideButton>}
            prevButton={<TourGuideButton buttonLink>Back</TourGuideButton>}
            closeWithMask={false}
            lastStepNextButton={getLastStepButton()}
            maskClassName="reactour-custom-mask"
            inViewThreshold={3000}
            disableInteraction
            getCurrentStep={(curr) => {
              dispatch(setTourGuideState({ currentStep: curr + 1 }));
            }}
          />
        )}
      </AnalyticsComponent>
    );
  }, [isTourOpen, showSectionTour, firstStep, isInitialStep]);

  return tour;
};

export default TourGuide;
