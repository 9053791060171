import React, { PureComponent } from 'react';
import { Loader, Colors } from '@inplayer-org/inplayer-ui';

// images
import customersIcon from 'assets/pics/icons/customers.svg';

// utils
import formatNumber from 'utils/formatNumber';

// types
import { ReportInformation } from 'ducks/merchantDucks/reports/types';
import { FetchReportParams, LoadReportsCommonParams } from 'ducks/merchantDucks/reports/actions';

// components
import AnalyticsInfoBox from 'pages/MerchantPages/Analytics/components/AnalyticsInfoBox';

export const REGISTERED_CUSTOMERS = 1;

interface Props {
  reportInformation: ReportInformation;
  active: boolean;
  startDate: string | number;
  endDate: string | number;
  interval: string;
  lineChart: boolean;
  color: string;
  loadReportInformations: (params: LoadReportsCommonParams) => any;
  loadRevenuePerCurrency: (params: LoadReportsCommonParams) => any;
  fetchReport: (params: FetchReportParams) => any;
  handleIconClick: (icon: number) => any;
}

class CustomersInfoBox extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { active: prevActive, startDate: prevStartDate, endDate: prevEndDate } = prevProps;
    const {
      fetchReport,
      loadReportInformations,
      loadRevenuePerCurrency,
      active,
      startDate,
      endDate,
      interval,
      lineChart,
    } = this.props;

    if (active && (prevActive !== active || prevStartDate !== startDate || prevEndDate !== endDate)) {
      fetchReport({ icon: REGISTERED_CUSTOMERS, startDate, endDate, interval, lineChart });

      const params = { startDate, endDate };
      loadReportInformations(params);
      loadRevenuePerCurrency(params);
    }
  }

  handleIconClick = () => {
    const { handleIconClick } = this.props;
    handleIconClick(REGISTERED_CUSTOMERS);
  };

  renderContent = () => {
    const {
      reportInformation: { isFetching, numberOfRegisteredUsers },
    } = this.props;

    if (isFetching) {
      return <Loader color={Colors.white} />;
    }

    return <h6>Total: {formatNumber(numberOfRegisteredUsers)}</h6>;
  };

  render() {
    const { active, color } = this.props;
    return (
      <AnalyticsInfoBox
        title="Audience"
        color={color}
        icon={customersIcon}
        active={active}
        handleIconClick={this.handleIconClick}
        renderContent={this.renderContent}
      />
    );
  }
}

export default CustomersInfoBox;
