import React, { FunctionComponent, ReactNode } from 'react';
import { Grid, Colors, Typography } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';

// images
import logo from 'assets/pics/icons/inplayer-icon-small.svg';

// styled components
import { BlueTypography } from './SharedStyledElements';

const { Container, Cell } = Grid;

const FormBorder = styled(Container)`
  padding: 2rem;
  background: ${Colors.white};
  border: 1px solid ${Colors.gray};
`;

export const CenteredForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FormMargin = styled.div`
  max-width: 35rem;
  width: 100%;
  margin-bottom: 3rem;
`;

const StyledInplayerLogo = styled.img`
  max-width: 200px;
  width: 80%;
  margin-bottom: 2rem;
`;

const TypographyStyled = styled(BlueTypography)`
  font-weight: ${({ theme }) => theme.font.weights.normal};
  padding: 20px;
  color: ${Colors.midnightBlue};
`;

const CenteredCell = styled(Cell)`
  align-items: center;
`;

const HeadingContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
`;

const Heading = styled.h1`
  font-weight: ${({ theme }) => theme.font.weights.normal};
  color: ${Colors.blue};
`;

interface Props {
  children: ReactNode;
  title?: string;
  description?: string;
  gap?: string;
  showLogo?: boolean;
  heading?: string;
}

const AuthFormContainer: FunctionComponent<Props> = ({ children, title, description, gap, showLogo, heading }) => {
  return (
    <CenteredForm>
      <FormMargin>
        {showLogo && (
          <Container columns={1}>
            <CenteredCell top={4} center middle>
              <StyledInplayerLogo src={logo} alt="inplayer-logo" title="InPlayer" />
            </CenteredCell>
          </Container>
        )}
        {heading && (
          <HeadingContainer>
            <Heading>{heading}</Heading>
          </HeadingContainer>
        )}
        <FormBorder alignContent="space-around" columns={1} gap={gap || '1em'}>
          {title && (
            <Cell center>
              <TypographyStyled variant="h6">{title}</TypographyStyled>
            </Cell>
          )}
          {description && (
            <Cell center>
              <Typography variant="p">{description}</Typography>
            </Cell>
          )}
          {children}
        </FormBorder>
      </FormMargin>
    </CenteredForm>
  );
};

AuthFormContainer.defaultProps = {
  showLogo: true,
};

export default AuthFormContainer;
