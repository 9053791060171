import React, { useState, FormEvent, KeyboardEvent, useEffect, useCallback, useMemo, ReactNode } from 'react';
import { Input, Dropdown, PillLabel, Colors, Option, AnalyticsComponent } from '@inplayer-org/inplayer-ui';
import styled, { css } from 'styled-components';
import some from 'lodash/some';
import { MdClose } from 'react-icons/md';
import { snakeCase } from 'lodash';

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const StyledInput = styled(Input)`
  input {
    border-radius: 0 5px 5px 0;
  }
`;

const StyledDropdown = styled(Dropdown)`
  border-radius: 5px 0 0 5px;
  border-right: none;
`;

const Pills = styled.div`
  display: flex;
  margin: 1rem 0 0;
  flex-wrap: wrap;
`;

interface StyledPillProps {
  isDeletePill?: boolean;
}

const DeleteFilterIcon = styled(MdClose)`
  color: ${Colors.skyBlue};
  transition: color 0.5s ease;
  margin: 0 0 0 0.2rem;
  vertical-align: middle;
  font-weight: ${({ theme }) => theme.font.weights.bold};
  :hover {
    cursor: pointer;
  }
`;

const StyledPill = styled(PillLabel)<StyledPillProps>`
  margin: 1rem 1rem 0 0;
  transition: background-color 0.5s ease, color 0.5s ease;
  :hover {
    color: ${Colors.white};
    ${({ isDeletePill }) =>
      isDeletePill
        ? css`
            background: ${Colors.red};
            cursor: pointer;
          `
        : css`
            background: ${Colors.skyBlue};
          `}
    ${DeleteFilterIcon} {
      color: ${Colors.white};
    }
  }
`;

const KEYS = {
  ENTER: 13,
};

interface OwnProps {
  options: Array<Option>;
  onSearch: (searchParams: any, resetFilter?: boolean) => void;
  externalFilters?: { [key: string]: any };
  clearExternalFilter?: (newFilterState: { [key: string]: any }) => void;
  onOptionSelect?: (selectedSearchOption: string) => void;
  children?: ReactNode;
  dropdownTag?: string;
  inputTag?: string;
}

type Props = OwnProps;

const Filter = ({
  options,
  onSearch,
  externalFilters,
  clearExternalFilter,
  onOptionSelect,
  children,
  dropdownTag = 'dropdown_search_by',
  inputTag = 'text_input_search',
}: Props) => {
  const [activeFilter, setActiveFilter] = useState(options[0].value);
  const [filters, setFilters] = useState({});
  const [inputValue, setInputValue] = useState('');

  const remappedOptions = useMemo(() => {
    return options.reduce((acc, option) => {
      acc[option.value] = option.displayName;
      return acc;
    }, {});
  }, [options]);

  useEffect(() => {
    const allFilters = options.reduce((acc, item) => {
      acc[item.value] = '';
      return acc;
    }, {});

    setFilters(externalFilters || allFilters);
  }, [options]);

  useEffect(() => {
    if (externalFilters) {
      setFilters(externalFilters);

      if (!externalFilters[activeFilter]) {
        setInputValue('');
      } else {
        setInputValue(externalFilters[activeFilter]);
      }
    }
  }, [externalFilters]);

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const newValue = event?.currentTarget?.value;
    setInputValue(newValue);
    if (!newValue) {
      if (!externalFilters) {
        setFilters({ ...filters, [activeFilter]: newValue });
      }
      onSearch({ ...filters, [activeFilter]: newValue });
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const keyPressed = event.keyCode;

    if (keyPressed === KEYS.ENTER) {
      if (!externalFilters) {
        setFilters({ ...filters, [activeFilter]: inputValue });
      }
      onSearch({ ...filters, [activeFilter]: inputValue });
    }
  };

  const handlePillClick = (filterName: string) => () => {
    setActiveFilter(filterName);
    setInputValue(filters[filterName]);
  };

  const clearFilter = (filterName: string) => {
    if (clearExternalFilter) {
      clearExternalFilter({ ...filters, [filterName]: '' });
    } else setFilters({ ...filters, [filterName]: '' });
    if (filterName === activeFilter) {
      setInputValue('');
    }
    onSearch({ ...filters, [filterName]: '' });
  };

  const clearAllFilters = () => {
    const allFilters = options.reduce((acc, item) => {
      acc[item.value] = '';
      return acc;
    }, {});
    if (clearExternalFilter) {
      clearExternalFilter(allFilters);
    } else setFilters(allFilters);
    setInputValue('');
    onSearch(allFilters);
  };

  const handleOptionChange = (value: string) => {
    setActiveFilter(value);
    setInputValue(filters[value] ?? '');
    if (onOptionSelect) {
      onOptionSelect(value);
    }
  };

  const renderFilters = useCallback(
    (pages, tracker, merchantId, ip) => {
      return Object.keys(filters)
        .filter((filterName) => filters[filterName])
        .map((filterName) => (
          <StyledPill size="sm" key={filterName} modifiers={['primary']}>
            <span onClick={handlePillClick(filterName)}>
              {remappedOptions[filterName]}: {filters[filterName]}
            </span>
            <DeleteFilterIcon
              onClick={() => {
                tracker.track({
                  event: 'click',
                  type: 'button',
                  tag: `button_search_term_remove_${snakeCase(filterName)}`,
                  pages,
                  merchantId,
                  ip,
                });
                return clearFilter(filterName);
              }}
            />
          </StyledPill>
        ));
    },
    [filters]
  );

  return (
    <>
      <Container>
        <StyledDropdown tag={dropdownTag} options={options} value={activeFilter} onChange={handleOptionChange} />
        <StyledInput
          tag={inputTag}
          type="search"
          onChange={handleInputChange}
          value={inputValue}
          onKeyDown={handleKeyDown}
        />
      </Container>
      {children}
      {some(filters, Boolean) && (
        <AnalyticsComponent>
          {({ pages, tracker, merchantId, ip }) => (
            <Pills>
              {renderFilters(pages, tracker, merchantId, ip)}
              <StyledPill
                size="sm"
                onClick={() => {
                  tracker.track({
                    event: 'click',
                    type: 'button',
                    tag: 'button_clear_all_filters',
                    pages,
                    merchantId,
                    ip,
                  });
                  return clearAllFilters();
                }}
                modifiers={['danger']}
                isDeletePill
              >
                Clear All Filters
              </StyledPill>
            </Pills>
          )}
        </AnalyticsComponent>
      )}
    </>
  );
};

export default Filter;
