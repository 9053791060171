import React, { FunctionComponent, ChangeEvent } from 'react';
import { Grid, Radio, Button, Tooltip, Typography } from '@inplayer-org/inplayer-ui';
import styled from 'styled-components';
import { MdInfoOutline } from 'react-icons/md';
import upperFirst from 'lodash/upperFirst';

// components
import StyledContainer from 'components/StyledContainer';

// styled components
import { BlueTypography } from 'components/SharedStyledElements';

const { Container, Cell } = Grid;

const RadioWrapper = styled(Radio)`
  justify-content: flex-start;
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  label,
  span {
    width: initial;
  }
`;

const MarginTooltip = styled(Tooltip)`
  margin-left: 0.3rem;
`;

interface OwnProps {
  assetContentType: string;
  setAssetContentType: (val: string) => void;
  onSubmitAssetContentType: (contentType: string) => void;
  onBackButtonClick: () => void;
}

type Props = OwnProps;

export enum EventType {
  LIVE = 'live',
  VOD = 'vod',
  OTHER = 'other',
}

const eventTooltipContent = {
  live:
    'Choose this for live streamed video content. This includes live streams where you plan on publishing\n a replay afterwards.',
  vod: 'Choose this for Video-on-demand-only content that isn’t going to be live streamed.',
  other: 'Choose this for content other than video, or for test assets.',
};

const ContentTypeForm: FunctionComponent<Props> = ({
  assetContentType,
  setAssetContentType,
  onSubmitAssetContentType,
  onBackButtonClick,
}) => {
  const handleSetContentType = (e: ChangeEvent<HTMLInputElement>) => {
    setAssetContentType(e.target.value);
  };

  const getRadioLabel = (eventType: string) =>
    eventType === EventType.VOD ? eventType.toUpperCase() : upperFirst(eventType);

  const renderEventItem = (eventType: string, tooltipContent: string) => (
    <Cell>
      <TooltipWrapper>
        <RadioWrapper
          id={eventType}
          label={getRadioLabel(eventType)}
          checked={assetContentType === eventType}
          onChange={handleSetContentType}
          name={eventType}
          value={eventType}
          tag={`radiobutton_${eventType}`}
        />
        <MarginTooltip tag={`tooltip_${eventType}`} content={tooltipContent} placement="right">
          <MdInfoOutline />
        </MarginTooltip>
      </TooltipWrapper>
    </Cell>
  );

  const handleSubmit = () => onSubmitAssetContentType(assetContentType);

  return (
    <>
      <StyledContainer margin="2rem" columns={1}>
        <Cell>
          <Container columns={1} rowGap="1rem">
            <Cell>
              <BlueTypography variant="h5">Select content category</BlueTypography>
            </Cell>
            <Cell>
              <Typography variant="p">
                This step is for info purposes only. Your asset won&apos;t be limited in any way by your selection.
              </Typography>
            </Cell>
            {renderEventItem(EventType.LIVE, eventTooltipContent[EventType.LIVE])}
            {renderEventItem(EventType.VOD, eventTooltipContent[EventType.VOD])}
            {renderEventItem(EventType.OTHER, eventTooltipContent[EventType.OTHER])}
          </Container>
        </Cell>
      </StyledContainer>
      <StyledContainer columns={3} margin="2rem 0 0 0">
        <Cell>
          <Button tag="button_back" fullWidth onClick={onBackButtonClick} buttonModifiers={['buttonLink']}>
            Back
          </Button>
        </Cell>
        <Cell left="3">
          <Button
            tag="button_next"
            fullWidth
            type="submit"
            disabled={!assetContentType}
            onClick={handleSubmit}
            buttonModifiers={['buttonPrimary']}
          >
            Next
          </Button>
        </Cell>
      </StyledContainer>
    </>
  );
};

export default ContentTypeForm;
