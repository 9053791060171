import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Colors, AnalyticsComponent } from '@inplayer-org/inplayer-ui';
import { transparentize } from 'polished';
import { useSelector } from 'react-redux';

// state
import RootState from 'ducks/RootState';
import { snakeCase } from 'lodash';
import { useTourGuideState } from './useTourGuideState';

// types

const Button = styled.span`
  border-radius: 5px;
  text-align: center;
  font-size: ${({ theme }) => theme.font.sizes.medium};
  padding: 0.5rem 1.25rem;
  display: inline-block;
  line-height: 1.15;
`;

export const StyledButton = styled(Button)`
  color: ${Colors.white};
  background-color: ${Colors.skyBlue};
`;

export const StyledButtonLink = styled(Button)`
  color: ${Colors.skyBlue};
  :hover {
    background-color: ${transparentize(0.9, Colors.skyBlue)};
  }
`;

interface Props {
  children: ReactNode;
  buttonLink?: boolean;
  onClick?: (e: any) => void;
}

const TourGuideButton = ({ buttonLink, onClick, children }: Props) => {
  const isRequestCloseClicked = useSelector((state: RootState) => state.auth.tourGuide.isRequestCloseClicked);
  const { currentStep } = useTourGuideState();
  if (isRequestCloseClicked) return null;

  return (
    <AnalyticsComponent>
      {({ pages, tracker, merchantId, ip }) => (
        <>
          {buttonLink ? (
            <StyledButtonLink
              onClick={() => {
                tracker.track({
                  event: 'click',
                  type: 'button',
                  tag: `button_back_${currentStep}`,
                  pages,
                  merchantId,
                  ip,
                });
              }}
            >
              {children}
            </StyledButtonLink>
          ) : (
            <StyledButton
              onClick={(e) => {
                tracker.track({
                  event: 'click',
                  type: 'button',
                  tag: `button_${snakeCase(children?.toString())}_${currentStep}`,
                  pages,
                  merchantId,
                  ip,
                });
                return onClick?.(e);
              }}
            >
              {children}
            </StyledButton>
          )}
        </>
      )}
    </AnalyticsComponent>
  );
};

export default TourGuideButton;
