import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import http, { ContentType, isAxiosError } from 'utils/http';
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';
import { API } from 'constants/api';
import { ApiAction } from 'ducks/types';
import RootState from 'ducks/RootState';
import { Notification } from '@inplayer-org/inplayer-ui';
import { Optional } from 'utils/types';

// images
import previewImg from 'assets/pics/ip-preview-premium.jpg';

// Load all branging themes
export enum LoadBrandingActionTypes {
  START = 'LOAD_BRANDING_REQUEST',
  SUCCESS = 'LOAD_BRANDING_SUCCESS',
  ERROR = 'LOAD_BRANDING_ERROR',
}

type LoadBrandingAction = Action<LoadBrandingActionTypes>;

export const loadBranding = (
  tenantUuid: string
): ThunkAction<Promise<any>, RootState, void, LoadBrandingAction> => async (dispatch) => {
  const endpoint = API.FETCH_THEMES;

  try {
    dispatch({ type: LoadBrandingActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        tenantUuid,
      },
    });
    dispatch({ type: LoadBrandingActionTypes.SUCCESS, payload: [...response.data] });
  } catch (_) {
    dispatch({ type: LoadBrandingActionTypes.ERROR });
  }
};

// Load branding details
export enum LoadBrandingDetailsActionTypes {
  START = 'LOAD_BRANDING_DETAILS_REQUEST',
  SUCCESS = 'LOAD_BRANDING_DETAILS_SUCCESS',
  ERROR = 'LOAD_BRANDING_DETAILS_ERROR',
}

type LoadBrandingDetailsAction = Action<LoadBrandingDetailsActionTypes>;

export const loadBrandingDetails = (
  tenantUuid: string,
  id: number | string
): ThunkAction<Promise<any>, RootState, void, LoadBrandingDetailsAction> => async (dispatch) => {
  const endpoint = API.FETCH_THEME;

  try {
    dispatch({ type: LoadBrandingDetailsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        tenantUuid,
        id,
      },
    });
    dispatch({ type: LoadBrandingDetailsActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (e) {
    const { response } = e;
    dispatch({ type: LoadBrandingDetailsActionTypes.ERROR, payload: { ...response.data } });
  }
};

// Add cover image for paywall
export const addCoverPhoto = async (image: File) => {
  const data = new FormData();
  data.append('paywall_cover_photo', image);

  const endpoint = API.PAYWALL_COVER;

  try {
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.FORM_DATA,
    });
    return response;
  } catch (_) {
    Notification.create({
      variant: 'danger',
      title: 'ERROR',
      content: 'An error occurred while adding preview cover image.',
      duration: 3,
    });
  }
};

// Add logo image for paywall
export const addBrandPhoto = async (image: File) => {
  const data = new FormData();
  data.append('paywall_brand_logo', image);

  const endpoint = API.PAYWALL_LOGO;

  try {
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.FORM_DATA,
    });
    return response;
  } catch (_) {
    Notification.create({
      variant: 'danger',
      title: 'ERROR',
      content: 'An error occurred while adding preview logo image.',
      duration: 3,
    });
  }
};

export enum AddCoverActionTypes {
  START = 'ADD_PAYWALL_COVER_REQUEST',
  SUCCESS = 'ADD_PAYWALL_COVER_SUCCESS',
  ERROR = 'ADD_PAYWALL_COVER_FAILURE',
}

type AddCoverAction = AddCoverActionTypes | NotificationActionTypes;

export const addPaywallCoverPhoto = (image: File): ApiAction<AddCoverAction> => async (dispatch) => {
  try {
    dispatch({ type: AddCoverActionTypes.START });
    dispatch({ type: AddCoverActionTypes.SUCCESS, payload: { ...image } });
  } catch (error) {
    let message = error.message || 'An error occurred during the image upload.';

    if (isAxiosError(error)) {
      const { response } = error;

      if (response && response.data) {
        message = response.data.message;
      }
    }

    dispatch({ type: AddCoverActionTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

export enum AddLogoActionTypes {
  START = 'ADD_PAYWALL_LOGO_REQUEST',
  SUCCESS = 'ADD_PAYWALL_LOGO_SUCCESS',
  ERROR = 'ADD_PAYWALL_LOGO_FAILURE',
}

type AddLogoAction = AddLogoActionTypes | NotificationActionTypes;

export const addPaywallLogoPhoto = (image: File): ApiAction<AddLogoAction> => async (dispatch) => {
  try {
    dispatch({ type: AddLogoActionTypes.START });
    dispatch({ type: AddLogoActionTypes.SUCCESS, payload: { ...image } });
  } catch (error) {
    let message = error.message || 'An error occurred during the image upload.';

    if (isAxiosError(error)) {
      const { response } = error;

      if (response && response.data) {
        message = response.data.message;
      }
    }

    dispatch({ type: AddLogoActionTypes.ERROR });
    dispatch(notifRequested({ type: 'danger', title: 'ERROR', content: message }));
  }
};

const coverImageForPaywall = (uploadImage: any, paywall_cover_photo: string) => {
  if (uploadImage) {
    const { full_size_image_url } = uploadImage;

    return full_size_image_url;
  }
  if (paywall_cover_photo === previewImg) {
    return '';
  }
  return paywall_cover_photo;
};

const logoImageForPaywall = (uploadImage: any, paywall_brand_logo: string) => {
  if (uploadImage) {
    const { full_size_image_url } = uploadImage;

    return full_size_image_url;
  }

  return paywall_brand_logo;
};

// Add branding
export enum CreateBrandingActionTypes {
  START = 'CREATE_BRANDING_REQUEST',
  SUCCESS = 'CREATE_BRANDING_SUCCESS',
  ERROR = 'CREATE_BRANDING_ERROR',
}

type CreateBrandingAction = Action<CreateBrandingActionTypes | NotificationActionTypes>;

export interface CreateBrandingPayload {
  paywallConfig: any;
  coverImage?: Optional<File>;
  logoImage?: Optional<File>;
}

export const createBranding = ({
  paywallConfig,
  coverImage,
  logoImage,
}: CreateBrandingPayload): ThunkAction<Promise<any>, RootState, void, CreateBrandingAction> => async (dispatch) => {
  const endpoint = API.BRANDING;
  dispatch({ type: CreateBrandingActionTypes.START });

  let previewCover;
  let previewLogo;

  if (coverImage) {
    const response = await addCoverPhoto(coverImage);

    if (response) {
      previewCover = await response.data;
      dispatch(addPaywallCoverPhoto(previewCover));
    }
  }

  if (logoImage) {
    const response = await addBrandPhoto(logoImage);

    if (response) {
      previewLogo = await response.data;
      dispatch(addPaywallLogoPhoto(previewLogo));
    }
  }

  const newPaywallConfig = {
    ...paywallConfig,
    paywall_cover_photo: coverImageForPaywall(previewCover, paywallConfig.paywall_cover_photo),
    paywall_brand_logo: logoImageForPaywall(previewLogo, paywallConfig.paywall_brand_logo),
  };

  try {
    const response = await http.authenticated().post(endpoint, {
      data: newPaywallConfig,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: CreateBrandingActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Paywall branding successfully saved.',
        type: 'success',
      })
    );
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response && response.data) {
        dispatch({ type: CreateBrandingActionTypes.ERROR });
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
    }
  }
};

export enum UpdateBrandingActionTypes {
  START = 'UPDATE_BRANDING_REQUEST',
  SUCCESS = 'UPDATE_BRANDING_SUCCESS',
  ERROR = 'UPDATE_BRANDING_FAILURE',
}

type UpdateBrandingAction = Action<UpdateBrandingActionTypes | LoadBrandingActionTypes | NotificationActionTypes>;

export interface UpdateBrandingPayload {
  paywallConfig: any;
  id: number | string;
  update: boolean;
  tenantUuid?: string;
  coverImage?: Optional<File>;
  logoImage?: Optional<File>;
}

export const updateBranding = ({
  paywallConfig,
  id,
  update,
  tenantUuid,
  coverImage,
  logoImage,
}: UpdateBrandingPayload): ThunkAction<Promise<any>, RootState, void, UpdateBrandingAction> => async (dispatch) => {
  const endpoint = API.BRANDING_ID;
  dispatch({ type: UpdateBrandingActionTypes.START });

  let previewCover;
  let previewLogo;

  if (coverImage) {
    const response = await addCoverPhoto(coverImage);

    if (response) {
      previewCover = await response.data;
      dispatch(addPaywallCoverPhoto(previewCover));
    }
  }

  if (logoImage) {
    const response = await addBrandPhoto(logoImage);

    if (response) {
      previewLogo = await response.data;
      dispatch(addPaywallLogoPhoto(previewLogo));
    }
  }

  const newPaywallConfig = {
    ...paywallConfig,
    paywall_cover_photo: coverImageForPaywall(previewCover, paywallConfig.paywall_cover_photo),
    paywall_brand_logo: logoImageForPaywall(previewLogo, paywallConfig.paywall_brand_logo),
  };

  const successData = {
    branding: newPaywallConfig,
    update: true,
  };

  try {
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        id,
      },
      data: newPaywallConfig,
      contentType: ContentType.URLENCODED,
      responseType: 'text',
    });
    dispatch({ type: UpdateBrandingActionTypes.SUCCESS, payload: { ...successData, ...response.data } });

    if (update) {
      if (tenantUuid) {
        dispatch(loadBranding(tenantUuid));
      }
      dispatch(
        notifRequested({
          title: 'SUCCESS',
          content: 'Paywall default theme successfully updated.',
          type: 'success',
        })
      );
    } else {
      dispatch(
        notifRequested({
          title: 'SUCCESS',
          content: 'Paywall branding successfully updated.',
          type: 'success',
        })
      );
    }
  } catch (error) {
    if (isAxiosError(error)) {
      const { response } = error;
      if (response && response.data) {
        dispatch({ type: UpdateBrandingActionTypes.ERROR, payload: { ...response.data } });
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
    }
  }
};

// Remove branding
export enum RemoveBrandingActionTypes {
  START = 'DELETE_BRANDING_REQUEST',
  SUCCESS = 'DELETE_BRANDING_SUCCESS',
  ERROR = 'DELETE_BRANDING_FAILURE',
}

type RemoveBrandingAction = Action<RemoveBrandingActionTypes | NotificationActionTypes>;

export const removeBranding = (
  id: number | string
): ThunkAction<Promise<any>, RootState, void, RemoveBrandingAction> => async (dispatch) => {
  const successData = { id };

  const endpoint = API.BRANDING_ID;

  try {
    dispatch({ type: RemoveBrandingActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        id,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: RemoveBrandingActionTypes.SUCCESS, payload: { ...successData, ...response.data } });
    dispatch(notifRequested({ title: 'SUCCESS', content: 'Paywall branding successfully deleted.', type: 'success' }));
  } catch (e) {
    const { response } = e;
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: response.data.message,
      })
    );
    dispatch({ type: RemoveBrandingActionTypes.ERROR });
  }
};

export const RESET_BRANDING_DETAILS = 'RESET_BRANDING_DETAILS';

export const resetBrandingDetails = () => ({
  type: RESET_BRANDING_DETAILS,
});
