import camelCase from 'lodash/fp/camelCase';
import mapKeys from 'lodash/fp/mapKeys';
import map from 'lodash/fp/map';
import { Dictionary, isArray } from 'lodash';
import reduce from 'lodash/reduce';
import unescape from 'lodash/unescape';
import { MultiSelectOption } from 'components/Filters/options';

export const convertArrayToCamelCase = <T extends object, V extends object = Dictionary<any>>(
  array: Array<T>
): Array<V> => {
  const objectToCamelCase = (object: T): V => mapKeys(camelCase, object) as V;

  const mapToCamelCase = map<T, V>(objectToCamelCase);

  return mapToCamelCase(array);
};

export const convertParamsForFilters = (filtersDataFormat: string[], values: any) => {
  const params = {};

  Object.keys(filtersDataFormat).forEach((dataFormat: string) => {
    const dataFormatType = camelCase(filtersDataFormat[dataFormat]);
    if (values[dataFormatType].checkboxValue) {
      if (isArray(values[dataFormatType].value)) {
        const arrayValues = values[dataFormatType].value.map(
          ({ value }: MultiSelectOption) => `${value}:${values[dataFormatType].radioValue}`
        );
        params[filtersDataFormat[dataFormat]] = arrayValues;
        // do not send is or isnot, since it is a single value and
        // it is not required in BE
      } else if (values[dataFormatType].disabledRadio) {
        params[filtersDataFormat[dataFormat]] = values[dataFormatType].value;
      } else {
        params[filtersDataFormat[dataFormat]] = `${values[dataFormatType].value}:${values[dataFormatType].radioValue}`;
      }
    }
  });

  return params;
};

export const randomColor = () =>
  '#000000'.replace(/0/g, () => {
    return (~~(Math.random() * 16)).toString(16);
  });

export const transformSearchParams = (tags: Record<string, any>) => {
  return reduce(
    tags,
    (acc: Array<string>, value, key) => {
      // remove all empty values, empty means filter is not applied fot the key
      if (value) {
        acc.push(`${key}:${value}`);
      }
      return acc;
    },
    []
  );
};

export const capitalizeFirstChar = (word: string) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`;

export const convertObjectToCamelCase = <T extends object, V extends object = Dictionary<any>>(object: T): V => {
  return mapKeys(camelCase, object) as V;
};

export const unescapeText = (text: string) => {
  return unescape(text).replace(new RegExp('&apos;', 'g'), "'");
};

const { REACT_APP_NODE_ENV } = process.env;
export const isDevelopmentEnv = REACT_APP_NODE_ENV !== 'production';
