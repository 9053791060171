import Rules from 'restrictions/roles/constants/rules';

export interface AuthState {
  user: User;
  token: string | null;
  refresh_token: string;
  expires: string;
  isAuthenticating: boolean;
  isLoading: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isRegistered: boolean;
  isActive: boolean;
  isUpdating: boolean;
  isRegistering: boolean;
  isActivating: boolean;
  isFetching: boolean;
  isSubscribed: boolean;
  social: Record<any, any>;
  socialAppsMetadata: Array<any>;
  concurrent_sessions: any;
  sub_domain_name: string;
  stripeRequest: boolean;
  paymentProviders: any;
  getFeesRequest: boolean;
  fees: any;
  bankStatementName: string;
  social_fields: Array<any>;
  share: string | number | null;
  isFetchingPaymentProviders: boolean;
  hasOrganisation: boolean;
  organisation: Organisation;
  organisationDetails: any;
  features: Array<Feature>;
  tourGuide: TourGuideState;
  isFetchingRevenueSetup: boolean;
}

export enum Roles {
  INPLAYER = 'inplayer',
  OWNER = 'owner',
  MERCHANT = 'merchant',
  MASTER = 'master',
  FOLLOWER = 'follower',
  ADMIN = 'admin',
  CONTENT_MANAGER = 'content_manager',
  ANALYST = 'analyst',
  CUSTOMER_SUPPORT = 'customer_support',
}

export interface User {
  id: number;
  master_id: number;
  uuid: string;
  tenant_uuid: string;
  email: string;
  username: string;
  full_name: string;
  referrer: string;
  isUpdating: boolean;
  completed: boolean;
  merchant_id: number;
  merchant_uuid: string;
  created_at: number;
  updated_at: number;
  date_of_birth: number | null;
  roles: Array<Roles>;
  roleRestrictions: Array<Rules>;
  scopes: Array<string>;
  metadata: UserMetadata;
  social_apps_metadata?: Record<string, any>;
}

interface UserMetadata {
  avatar_url: string;
  currency: string;
  social: string;
  probability_segment: boolean;
}

export interface Organisation {
  collection: Array<any>;
  total: number;
  size: number;
  page: number;
}

export interface Feature {
  name: string;
  enabled: boolean;
}

interface TourGuideState {
  isTourGuideActive: boolean;
  isLogoClicked: boolean;
  isSectionTour: boolean;
  isRequestCloseClicked: boolean;
  isCloseTourClicked: boolean;
  previousLocation: string;
  currentStep: number;
}

export enum FeatureNames {
  GIFTS = 'Gifts',
  DONATIONS = 'Donations',
  LIVELIKE = 'LivelikeIntegration',
  LANDINGPAGE = 'LandingPageBuilder',
  SEASON = 'Season Subscription',
  STRIPE = 'StripeConnectIntegration',
  PAYPAL = 'ExternalPaypalIntegration',
  AGE_VERIFICATION = 'Age Verification',
  ACTIVATE_RELATE = 'Activate Relate',
  EMAIL_TEMPLATES = 'EmailTemplatesEditor',
  NEW_PRICING = 'NewPricing',
}
