import React, { useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import includes from 'lodash/includes';
import filter from 'lodash/fp/filter';

// Types
import { Roles } from 'ducks/merchantDucks/user/types';

// Images
import logo from 'assets/pics/icons/inplayer-icon-large.svg';

// Hooks
import useRestrictionRules from 'restrictions/roles/hooks/useRestrictionRules';
import useRestrictionFeatures from 'restrictions/roles/hooks/useRestrictionFeatures';

// Components
import SideMenu from 'components/Navigation/SideMenu';

// Navigation Routes
import getRoutesByRole, { AppRoute } from 'components/Navigation/navRoutes';

interface Props {
  userRoles: Array<Roles>;
  onLogoClick: () => any;
}

const Navigation = ({ userRoles, onLogoClick }: Props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { hasRule } = useRestrictionRules();
  const { hasFeature } = useRestrictionFeatures();

  const [isOpen, setIsOpen] = useState(false);

  const routes = getRoutesByRole(userRoles.includes(Roles.INPLAYER) ? Roles.INPLAYER : Roles.MERCHANT);
  const navigationRoutes = useMemo(() => filter<AppRoute>((route: AppRoute) => route.showInSideNav!)(routes), [
    userRoles,
  ]);
  const routesToRender = navigationRoutes.filter(({ restrictionFeature }: AppRoute) => {
    if (restrictionFeature) {
      return hasFeature(restrictionFeature);
    }

    return true;
  });

  return (
    <SideMenu
      logo={logo}
      onLogoClick={onLogoClick}
      logoTitle="Take a tour of the InPlayer Dashboard!"
      onToggleOpen={setIsOpen}
    >
      {routesToRender
        .filter(({ restrictionRule: rule }: AppRoute) => (rule ? !hasRule(rule) : true))
        .map((item: AppRoute) => (
          <div data-tut={`reactour__tab-${item.title?.toLowerCase()}`} key={item.path} title={isOpen ? '' : item.title}>
            <SideMenu.MenuItem
              key={item.path}
              icon={item.icon}
              onClick={() => {
                if (['/support', '/pagebuilder', '/emailtemplates'].includes(item.path)) {
                  if (item.onClick) {
                    item.onClick();
                  }
                  window.open(item.redirectTo, '_blank');
                  return;
                }
                localStorage.removeItem('reportInformation');
                history.push(item.path);
              }}
              active={includes(pathname, item.path)}
              tag={item.tag}
            >
              {item.title}
            </SideMenu.MenuItem>
          </div>
        ))}
    </SideMenu>
  );
};

export default Navigation;
