import { useSelector } from 'react-redux';

// constants
import Rules from 'restrictions/roles/constants/rules';

// types
import RootState from 'ducks/RootState';

const useRestrictionRules = () => {
  const roleRestrictions = useSelector((state: RootState) => state.auth.user.roleRestrictions);

  const hasRule = (rule: Rules) => roleRestrictions.includes(rule);

  return { hasRule };
};

export default useRestrictionRules;
