import styled from 'styled-components';
import { Colors } from '@inplayer-org/inplayer-ui';

export const ErrorMessage = styled.span`
  color: ${Colors.red};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  margin-top: 0.6rem;
  display: block;
`;

export default ErrorMessage;
