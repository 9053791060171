import { replace } from 'connected-react-router';
import { Action } from 'redux';
import axios, { AxiosResponse } from 'axios';
import includes from 'lodash/includes';

import { API } from 'constants/api';
import { notifRequested, NotificationActionTypes } from 'middleware/notifications/actions';
import http, { ContentType, isAxiosError } from 'utils/http';
import tokenManager, { AdminToken } from 'utils/TokenManager';
import { Optional } from 'utils/types';
import { notifyUser, UserNotificationActionTypes } from 'ducks/merchantDucks/notifications/actions';
import {
  RegisterUserFormValues,
  Services,
  Interests,
} from 'pages/MerchantPages/Authentication/Register/forms/Register';
import { INPLAYER_UUID } from 'constants/index';
import { ApiAction, RouterApiAction } from 'ducks/types';
import { loadClients } from 'ducks/merchantDucks/agency/actions';
import { Roles } from './types';

// Register user
export enum RegisterUserActionTypes {
  START = 'REGISTER_USER_REQUEST',
  SUCCESS = 'REGISTER_USER_SUCCESS',
  FAILURE = 'REGISTER_USER_FAILURE',
}

type RegisterUserTypes = RegisterUserActionTypes | NotificationActionTypes;

export const registerUser = (
  data: RegisterUserFormValues,
  captchaToken: Optional<string>
): ApiAction<RegisterUserTypes> => async (dispatch, getState) => {
  const fd = new FormData();
  fd.append('email', data.email);
  fd.append('full_name', data.fullName);
  fd.append('password', data.password);
  fd.append('password_confirmation', data.passwordConfirmation);
  fd.append('merchant_uuid', INPLAYER_UUID);
  fd.append('type', Roles.MERCHANT);
  fd.append('response_token', captchaToken as string);

  if (data.serviceQuestion === Services.Other) {
    fd.append('metadata[services_question]', data.serviceOtherReason);
  } else {
    fd.append('metadata[services_question]', data.serviceQuestion);
  }

  if (data.interestQuestion === Interests.Other) {
    fd.append('metadata[interest_question]', data.interestOtherReason);
  } else {
    fd.append('metadata[interest_question]', data.interestQuestion);
  }

  if (data.company) {
    fd.append('metadata[company]', data.company);
  }

  if (data.country) {
    fd.append('metadata[country]', data.country.toString());
  }

  if (window.location.href.includes('x-amznmarketplace-token')) {
    const token = window.location.href.split('=')[1];
    fd.append('x-amznmarketplace-token', token);
  }

  const { isAuthenticated, isAdmin } = getState().auth;
  const endpoint = API.ACCOUNTS;

  if (!isAuthenticated || isAdmin) {
    try {
      dispatch({ type: RegisterUserActionTypes.START });
      const response = await http.post(endpoint, { data: fd, contentType: ContentType.FORM_DATA });
      dispatch({ type: RegisterUserActionTypes.SUCCESS, payload: { ...response.data } });
    } catch (e) {
      if (isAxiosError(e)) {
        const { response } = e;
        if (response && response.data) {
          dispatch(
            notifRequested({
              type: 'danger',
              title: 'ERROR',
              content: response.data.message,
            })
          );
        }
      }
      dispatch({ type: RegisterUserActionTypes.FAILURE });
    }
  }
};

// Update user data
export enum UpdateUserActionTypes {
  START = 'UPDATE_USER_REQUEST',
  SUCCESS = 'UPDATE_USER_SUCCESS',
  FAILURE = 'UPDATE_USER_FAILURE',
}

type UpdateMetadataAction = UpdateUserActionTypes | NotificationActionTypes;

export const updateMetadata = (metadata: any, accountId: number): ApiAction<UpdateMetadataAction> => async (
  dispatch
) => {
  const endpoint = API.ACCOUNTS;
  const data = {
    accountId,
    metadata,
  };

  const successData = { metadata };

  try {
    dispatch({ type: UpdateUserActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: UpdateUserActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: UpdateUserActionTypes.FAILURE });
    }
  }
};

// Logout
export enum LogoutUserActionTypes {
  START = 'LOGOUT_USER_REQUEST',
  SUCCESS = 'LOGOUT_USER_SUCCESS',
  FAILURE = 'LOGOUT_USER_FAILURE',
}

export const logoutUser = (): ApiAction<LogoutUserActionTypes | NotificationActionTypes> => async (
  dispatch,
  getState
) => {
  const endpoint = API.LOGOUT;
  const successData = { logout: true };
  const {
    auth: { isAdmin },
  } = getState();

  try {
    dispatch({ type: LogoutUserActionTypes.START });
    const response = await http.authenticated().get(endpoint, {});
    dispatch({
      type: LogoutUserActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    if (!isAdmin) {
      tokenManager.clear();
    } else {
      tokenManager.clearAdmin();
    }
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: LogoutUserActionTypes.FAILURE });
    }
  }
};

// Load features
export enum GetFeaturesTypes {
  START = 'GET_FEATURES_REQUEST',
  SUCCESS = 'GET_FEATURES_SUCCESS',
  FAILURE = 'GET_FEATURES_FAILURE',
}

export const getFeatures = (): ApiAction<GetFeaturesTypes> => async (dispatch) => {
  const endpoint = API.FEATURES;
  try {
    dispatch({ type: GetFeaturesTypes.START });
    const response = await http.authenticated().get(endpoint);
    dispatch({
      type: GetFeaturesTypes.SUCCESS,
      payload: [...response.data],
    });
  } catch (e) {
    dispatch({ type: GetFeaturesTypes.FAILURE });
  }
};

// Load user
export enum UserActionTypes {
  START = 'USER_REQUEST',
  SUCCESS = 'USER_SUCCESS',
  FAILURE = 'USER_FAILURE',
}

export const loadUser = (isSocialAuth = false, isAdmin = false): ApiAction<UserActionTypes> => async (
  dispatch,
  getState
) => {
  const endpoint = API.ACCOUNTS;
  const { user } = getState().auth;

  if (user.uuid && !isSocialAuth) {
    return null;
  }

  const authenticatedHeaders = {
    Authorization: `Bearer ${isAdmin ? tokenManager.admin?.adminToken : tokenManager.token}`,
  };

  try {
    dispatch({ type: UserActionTypes.START, payload: isSocialAuth });
    const response = await http.get(endpoint, { headers: authenticatedHeaders });
    dispatch({
      type: UserActionTypes.SUCCESS,
      payload: { ...response.data },
    });

    if (!response.data.roles.includes(Roles.INPLAYER)) {
      dispatch(getFeatures());
    }

    if (includes(response.data.roles, Roles.MASTER)) {
      const queryParams = {
        size: 6,
        order: 'createdAt',
      };

      dispatch(loadClients(queryParams));
    }
  } catch (_) {
    dispatch({ type: UserActionTypes.FAILURE });
    if (isAdmin) {
      tokenManager.clearAdmin();
    } else {
      tokenManager.clear();
    }
  }
};

// Load updated user information
export const loadUpdatedUserInfo = (): ApiAction<UserActionTypes> => async (dispatch) => {
  const endpoint = API.ACCOUNTS;
  try {
    dispatch({ type: UserActionTypes.START });
    const response = await http.authenticated().get(endpoint, {});
    dispatch({ type: UserActionTypes.SUCCESS, payload: { ...response.data } });
  } catch (_) {
    dispatch({ type: UserActionTypes.FAILURE });
  }
};

// Change password
export enum ChangePasswordActionTypes {
  START = 'CHANGE_PASSWORD_REQUEST',
  SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  FAILURE = 'CHANGE_PASSWORD_FAILURE',
}

export interface ChangePasswordValues {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}

type ChangeUserTypes = ChangePasswordActionTypes | NotificationActionTypes;

export const changeUserPassword = ({
  oldPassword,
  password,
  passwordConfirmation,
}: ChangePasswordValues): ApiAction<ChangeUserTypes> => async (dispatch) => {
  const data = new FormData();
  data.append('old_password', oldPassword);
  data.append('password', password);
  data.append('password_confirmation', passwordConfirmation);

  const endpoint = API.PASSWORD;
  try {
    dispatch({ type: ChangePasswordActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.FORM_DATA,
    });
    dispatch({ type: ChangePasswordActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Your password has been changed successfully.',
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: ChangePasswordActionTypes.FAILURE });
    }
  }
};

// Forgot password
export enum ForgotPasswordActionTypes {
  START = 'FORGOT_PASSWORD_REQUEST',
  SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FAILURE = 'FORGOT_PASSWORD_FAILURE',
}

type RequestNewPasswordTypes = ForgotPasswordActionTypes | NotificationActionTypes;

export const requestNewPassword = (email: string): ApiAction<RequestNewPasswordTypes> => async (dispatch) => {
  const data = new FormData();
  data.append('email', email);
  data.append('merchant_uuid', INPLAYER_UUID);

  const endpoint = API.FORGOT_PASSWORD;

  try {
    dispatch({ type: ForgotPasswordActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.FORM_DATA,
    });
    dispatch({ type: ForgotPasswordActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Please check your email.',
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: ForgotPasswordActionTypes.FAILURE });
    }
  }
};

// Enter the new password form
export enum NewPasswordActionTypes {
  START = 'NEW_PASSWORD_REQUEST',
  SUCCESS = 'NEW_PASSWORD_SUCCESS',
  FAILURE = 'NEW_PASSWORD_FAILURE',
}

export interface AddNewPasswordValues {
  password: string;
  passwordConfirmation: string;
  token: string;
}

type NewPasswordTypes = NewPasswordActionTypes | NotificationActionTypes;

export const addNewPassword = ({
  password,
  passwordConfirmation,
  token,
}: AddNewPasswordValues): ApiAction<NewPasswordTypes> => async (dispatch) => {
  const endpoint = API.NEW_PASSWORD;
  const data = {
    password,
    password_confirmation: passwordConfirmation,
  };

  try {
    dispatch({ type: NewPasswordActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        token,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: NewPasswordActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'You have entered your new password successfully. Please proceeed to log-in.',
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: NewPasswordActionTypes.FAILURE });
    }
  }
};

// Add avatar image
export enum AddAvatarActionTypes {
  START = 'ADD_AVATAR_REQUEST',
  SUCCESS = 'ADD_AVATAR_SUCCESS',
  FAILURE = 'ADD_AVATAR_FAILURE',
}

type AddAvatarTypes = AddAvatarActionTypes | NotificationActionTypes | UserActionTypes;

export const addAvatar = (image: File | string, accountId: number): ApiAction<AddAvatarTypes> => async (dispatch) => {
  const endpoint = API.ADD_AVATAR;

  const data = new FormData();
  data.append('avatar', image);

  const successData = { accountId };

  try {
    dispatch({ type: AddAvatarActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.FORM_DATA,
    });
    // metadata with the image url from s3
    const metadata = {
      avatar_url: response.data.full_size_image_url,
    };
    dispatch({ type: AddAvatarActionTypes.SUCCESS, payload: { ...successData, ...response.data } });
    dispatch(updateMetadata(metadata, accountId));
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Avatar successfully updated.',
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: AddAvatarActionTypes.FAILURE });
    }
  }
};

// Activate account
export enum ActivateAccountActionTypes {
  START = 'ACTIVATE_USER_REQUEST',
  SUCCESS = 'ACTIVATE_USER_SUCCESS',
  FAILURE = 'ACTIVATE_USER_FAILURE',
}

type ActivateAccountTypes = ActivateAccountActionTypes | NotificationActionTypes;

export const activateUser = (code: string): RouterApiAction<ActivateAccountTypes> => async (dispatch, getState) => {
  const endpoint = API.ACTIVATE_USER;
  const { isActive } = getState().auth;

  if (!isActive) {
    try {
      dispatch({ type: ActivateAccountActionTypes.START });
      const response = await http.put(endpoint, {
        pathVariables: {
          code,
        },
        contentType: ContentType.URLENCODED,
      });
      dispatch({ type: ActivateAccountActionTypes.SUCCESS, payload: { ...response.data } });
      dispatch(replace('/activation-message'));
    } catch (e) {
      if (isAxiosError(e)) {
        const { response } = e;
        if (response && response.data) {
          dispatch(
            notifRequested({
              title: 'ERROR',
              type: 'danger',
              content: response.data.message,
            })
          );
        }
        dispatch({ type: ActivateAccountActionTypes.FAILURE });
      }
    }
  } else {
    return null;
  }
};

/* Amazon Notification Token */

export enum IAMTokenActionTypes {
  START = 'IAM_TOKEN_REQUEST',
  SUCCESS = 'IAM_TOKEN_SUCCESS',
  FAILURE = 'IAM_TOKEN_FAILURE',
}

type LoadTokenTypes = IAMTokenActionTypes | UserNotificationActionTypes;

export const loadIAMToken = (isAdmin = false): ApiAction<LoadTokenTypes> => async (dispatch) => {
  const endpoint = API.AMAZON_IAM;
  const authenticatedHeaders = {
    Authorization: `Bearer ${isAdmin ? tokenManager.admin?.adminToken : tokenManager.token}`,
  };

  try {
    dispatch({ type: IAMTokenActionTypes.START });
    const response = await axios.get(endpoint, { headers: authenticatedHeaders });
    dispatch({
      type: IAMTokenActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    if (isAdmin) {
      tokenManager.IAMAdminToken = response.data;
    } else {
      tokenManager.IAMToken = response.data;
    }
    dispatch(notifyUser(''));
  } catch (_) {
    dispatch({ type: IAMTokenActionTypes.FAILURE });
  }
};

// LOGIN
export enum LoginActionTypes {
  START = 'LOGIN_USER_REQUEST',
  SUCCESS = 'LOGIN_USER_SUCCESS',
  FAILURE = 'LOGIN_USER_FAILURE',
}

type LoginUserTypes = LoginActionTypes | NotificationActionTypes | UserNotificationActionTypes | IAMTokenActionTypes;

export const loginUser = (email: string, password: string): RouterApiAction<LoginUserTypes> => async (dispatch) => {
  const endpoint = API.LOGIN;

  const data = new FormData();
  data.append('username', email);
  data.append('password', password);
  data.append('client_id', INPLAYER_UUID);
  data.append('grant_type', 'password');

  const notifCallback = async (responseData: any) => {
    /* Notifications */
    const {
      access_token: accessToken,
      refresh_token: refreshToken,
      expires,
      account: { roles, full_name },
    } = responseData;

    const isUserAdmin = roles.includes(Roles.INPLAYER);

    if (isUserAdmin) {
      const adminToken: AdminToken = {
        adminToken: accessToken,
        adminExpires: expires,
        adminRefreshToken: refreshToken,
        full_name,
      };

      tokenManager.admin = adminToken;
      dispatch(replace('/admin'));
    } else {
      tokenManager.token = accessToken;
      tokenManager.refreshToken = refreshToken;
      tokenManager.expires = expires;
    }

    const AWSToken = isUserAdmin ? tokenManager.IAMAdminToken : tokenManager.IAMToken;

    if (!AWSToken) {
      dispatch(loadIAMToken(isUserAdmin));
    } else {
      dispatch(notifyUser(''));
    }
  };

  try {
    dispatch({ type: LoginActionTypes.START });
    const response = await http.post(endpoint, {
      data,
      contentType: ContentType.FORM_DATA,
    });
    dispatch({
      type: LoginActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    notifCallback(response.data);

    if (!response.data.account.roles.includes(Roles.INPLAYER)) {
      dispatch(getFeatures());
    }

    if (includes(response.data.account.roles, Roles.MASTER)) {
      const queryParams = {
        size: 6,
        order: 'createdAt',
      };

      dispatch(loadClients(queryParams));
    }
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: LoginActionTypes.FAILURE });
    }
  }
};

// Add social app
export enum SocialAppsActionTypes {
  START = 'SOCIAL_APPS_REQUEST',
  SUCCESS = 'SOCIAL_APPS_SUCCESS',
  FAILURE = 'SOCIAL_APPS_FAILURE',
}

type AddSocialAppsTypes = SocialAppsActionTypes | NotificationActionTypes;

export const addSocialApps = (social: Array<string>): ApiAction<AddSocialAppsTypes> => async (dispatch) => {
  const endpoint = API.SOCIAL;
  const data = { social };
  const successData = { social };

  try {
    dispatch({ type: SocialAppsActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: SocialAppsActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Social buttons list updated.',
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: SocialAppsActionTypes.FAILURE });
    }
  }
};

// Load social fields
export enum SocialFieldsActionTypes {
  START = 'SOCIAL_FIELDS_REQUEST',
  SUCCESS = 'SOCIAL_FIELDS_SUCCESS',
  FAILURE = 'SOCIAL_FIELDS_FAILURE',
}

export const loadSocialFields = (type: string): ApiAction<SocialFieldsActionTypes> => async (dispatch) => {
  const endpoint = API.SOCIAL_FIELDS;
  try {
    dispatch({ type: SocialFieldsActionTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        type,
      },
    });
    dispatch({
      type: SocialFieldsActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: SocialFieldsActionTypes.FAILURE });
  }
};

// Add social set up
export enum AddSocialActionTypes {
  START = 'ADD_SOCIAL_SETUP_REQUEST',
  SUCCESS = 'ADD_SOCIAL_SETUP_SUCCESS',
  FAILURE = 'ADD_SOCIAL_SETUP_FAILURE',
}

type AddSocialSetupTypes = AddSocialActionTypes | NotificationActionTypes;

export const addSocialSetup = (socialNetwork: string, metadata: any): ApiAction<AddSocialSetupTypes> => async (
  dispatch
) => {
  const endpoint = API.SOCIAL_SETUP;
  const data = { social_network: socialNetwork, metadata };

  try {
    dispatch({ type: AddSocialActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: AddSocialActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Social input fields successfully updated.',
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: AddSocialActionTypes.FAILURE });
    }
  }
};

// Delete social set up
export enum DeleteSocialActionTypes {
  START = 'DELETE_SOCIAL_SETUP_REQUEST',
  SUCCESS = 'DELETE_SOCIAL_SETUP_SUCCESS',
  FAILURE = 'DELETE_SOCIAL_SETUP_FAILURE',
}

type DeleteSocialAction = DeleteSocialActionTypes | UserActionTypes | NotificationActionTypes;

export const removeSocial = (type: string): ApiAction<DeleteSocialAction> => async (dispatch) => {
  const endpoint = API.DELETE_SOCIAL;

  try {
    dispatch({ type: DeleteSocialActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        type,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteSocialActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(loadUpdatedUserInfo());
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'The social set up was successfully deleted.',
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: DeleteSocialActionTypes.FAILURE });
    }
  }
};

// Add session number per user
export enum AddSessionActionTypes {
  START = 'ADD_SESSION_USER_REQUEST',
  SUCCESS = 'ADD_SESSION_USER_SUCCESS',
  FAILURE = 'ADD_SESSION_USER_FAILURE',
}

export const addSessionUser = (
  concurrentSessions: number
): ApiAction<AddSessionActionTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.SESSION;
  const data = { concurrent_sessions: concurrentSessions };
  const successData = { concurrent_sessions: concurrentSessions };

  try {
    dispatch({ type: AddSessionActionTypes.START });
    const response = await http.authenticated().put(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: AddSessionActionTypes.SUCCESS,
      payload: { ...successData, ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Number of simultaneous logins was successfully updated.',
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: AddSessionActionTypes.FAILURE });
    }
  }
};

// Load session number per user
export enum SessionUserActionTypes {
  START = 'SESSION_USER_REQUEST',
  SUCCESS = 'SESSION_USER_SUCCESS',
  FAILURE = 'SESSION_USER_FAILURE',
}

export const loadSessionUser = (): ApiAction<SessionUserActionTypes> => async (dispatch) => {
  const endpoint = API.SESSION;

  try {
    dispatch({ type: SessionUserActionTypes.START });
    const response = await http.authenticated().get(endpoint, {});
    dispatch({
      type: SessionUserActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: SessionUserActionTypes.FAILURE });
  }
};

// Delete session number
export enum DeleteSessionActionTypes {
  START = 'DELETE_SESSION_USER_REQUEST',
  SUCCESS = 'DELETE_SESSION_USER_SUCCESS',
  FAILURE = 'DELETE_SESSION_USER_FAILURE',
}

type RemoveSessionUserTypes = DeleteSessionActionTypes | NotificationActionTypes;

export const removeSessionUser = (setting: string | number): ApiAction<RemoveSessionUserTypes> => async (dispatch) => {
  const endpoint = API.SESSION;
  const data = { setting };

  try {
    dispatch({ type: DeleteSessionActionTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteSessionActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(loadSessionUser());
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Simultaneous logins limit was successfully deleted.',
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: DeleteSessionActionTypes.FAILURE });
    }
  }
};

// Resend code
export enum ResendCodeActionTypes {
  START = 'RESEND_CODE_REQUEST',
  SUCCESS = 'RESEND_CODE_SUCCESS',
  FAILURE = 'RESEND_CODE_FAILURE',
}

type ResendCodeTypes = ResendCodeActionTypes | NotificationActionTypes;

export const resendCode = (email: string): RouterApiAction<ResendCodeTypes> => async (dispatch) => {
  const data = new FormData();
  data.append('email', email);
  data.append('merchant_uuid', INPLAYER_UUID);

  const endpoint = API.RESEND_CODE;

  try {
    dispatch({ type: ResendCodeActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      contentType: ContentType.FORM_DATA,
      data,
    });
    dispatch({
      type: ResendCodeActionTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(replace('/activate'));
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Please check your email for your account activation code.',
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: ResendCodeActionTypes.FAILURE });
    }
  }
};

// Load payment PROVIDERS
export enum PaymentProvidersActionTypes {
  START = 'PAYMENT_PROVIDERS_REQUEST',
  SUCCESS = 'PAYMENT_PROVIDERS_SUCCESS',
  FAILURE = 'PAYMENT_PROVIDERS_FAILURE',
}

export const fetchPaymentProviders = (): ApiAction<PaymentProvidersActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_PAYMENT_PROVIDERS;

  try {
    dispatch({ type: PaymentProvidersActionTypes.START });
    const response = await http.authenticated().get(endpoint, {});
    dispatch({
      type: PaymentProvidersActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: PaymentProvidersActionTypes.FAILURE });
  }
};

/* STRIPE */
export enum StripeConnectActionTypes {
  START = 'STRIPE_REQUEST',
  SUCCESS = 'STRIPE_SUCCESS',
  FAILURE = 'STRIPE_FAILURE',
}

type ConnectStripeTypes = StripeConnectActionTypes | NotificationActionTypes;

export const connectStripe = (code: string): RouterApiAction<ConnectStripeTypes> => async (dispatch) => {
  const data = { authorization_code: code };
  const endpoint = API.STRIPE_CONNECT;

  try {
    dispatch({ type: StripeConnectActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.URLENCODED,
    });

    dispatch({ type: StripeConnectActionTypes.SUCCESS });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: response.data.message,
        type: 'success',
      })
    );
    dispatch(fetchPaymentProviders());
  } catch (_) {
    dispatch({ type: StripeConnectActionTypes.FAILURE });
    dispatch(
      notifRequested({
        title: 'ERROR',
        content: 'There was an error caused by Stripe connect, please try again later.',
        type: 'danger',
      })
    );
  } finally {
    dispatch(replace('/billing-plan/payment-settings'));
  }
};

export const displayErrorMessageRedirected = (message: string): RouterApiAction<NotificationActionTypes> => async (
  dispatch
) => {
  dispatch(replace('/billing-plan/payment-settings'));
  dispatch(
    notifRequested({
      type: 'danger',
      title: 'ERROR',
      content: message,
    })
  );
};

// Save BANK STATEMENT NAME
export enum BankStatementActionTypes {
  START = 'BANK_STATEMENT_REQUEST',
  SUCCESS = 'BANK_STATEMENT_SUCCESS',
  FAILURE = 'BANK_STATEMENT_FAILURE',
}

export const saveBankStatementName = (newName: string, empty: boolean): ApiAction<BankStatementActionTypes> => async (
  dispatch
) => {
  const endpoint = API.SEND_BANK_STATEMENT;

  const data = new FormData();
  data.append('bank_statement', newName);

  try {
    dispatch({ type: BankStatementActionTypes.START });
    let response: AxiosResponse<any>;
    if (!empty) {
      response = await http.authenticated().post(endpoint, {
        data,
        contentType: ContentType.URLENCODED,
      });
    } else {
      response = await http.authenticated().delete(endpoint, {
        contentType: ContentType.URLENCODED,
      });
    }
    dispatch({
      type: BankStatementActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: BankStatementActionTypes.FAILURE });
  }
};

/* PAYMENT FEES */
export enum FeesActionTypes {
  START = 'GET_FEES_REQUEST',
  SUCCESS = 'GET_FEES_SUCCESS',
  FAILURE = 'GET_FEES_FAILURE',
}

export const fetchPaymentFees = (): ApiAction<FeesActionTypes> => async (dispatch) => {
  const endpoint = API.FETCH_PAYMENT_FEES;
  try {
    dispatch({ type: FeesActionTypes.START });
    const response = await http.authenticated().get(endpoint, {});
    dispatch({
      type: FeesActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: FeesActionTypes.FAILURE });
  }
};

export enum RevenueSetupActionTypes {
  START = 'GET_REVENUE_SETUP_REQUEST',
  SUCCESS = 'GET_REVENUE_SETUP_SUCCESS',
  FAILURE = 'GET_REVENUE_SETUP_FAILURE',
}

export const fetchRevenueSetup = (): ApiAction<RevenueSetupActionTypes> => async (dispatch) => {
  const endpoint = API.GET_REVENUE_SETUP;
  try {
    dispatch({ type: RevenueSetupActionTypes.START });
    const response = await http.authenticated().get(endpoint, {});
    dispatch({
      type: RevenueSetupActionTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: RevenueSetupActionTypes.FAILURE });
  }
};

export enum RequestForConnectActionTypes {
  START = 'POST_CONNECT_REQUEST',
  SUCCESS = 'POST_CONNECT_SUCCESS',
  FAILURE = 'POST_CONNECT_ERROR',
}

export const sendRequestForConnect = (): ApiAction<RequestForConnectActionTypes | NotificationActionTypes> => async (
  dispatch
) => {
  const endpoint = API.POST_REQUEST_FOR_CONNECT;

  try {
    dispatch({ type: RequestForConnectActionTypes.START });
    const response = await http.authenticated().post(endpoint, {
      contentType: ContentType.URLENCODED,
    });
    dispatch({ type: RequestForConnectActionTypes.SUCCESS, payload: { ...response.data } });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'A request for enabling Stripe Connect was sent to our support team, we will respond shortly.',
        type: 'success',
      })
    );
  } catch (e) {
    if (isAxiosError(e)) {
      const { response } = e;
      if (response && response.data) {
        dispatch(
          notifRequested({
            title: 'ERROR',
            type: 'danger',
            content: response.data.message,
          })
        );
      }
      dispatch({ type: RequestForConnectActionTypes.FAILURE });
    }
  }
};

export enum CreateOrganisationTypes {
  START = 'CREATE_ORGANISATION_REQUEST',
  SUCCESS = 'CREATE_ORGANISATION_SUCCESS',
  FAILURE = 'CREATE_ORGANISATION_FAILURE',
}

export const createOrganisation = (
  organisationName: string
): ApiAction<CreateOrganisationTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.ORGANISATION;
  const data = new FormData();
  data.append('name', organisationName);
  try {
    dispatch({ type: CreateOrganisationTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      contentType: ContentType.FORM_DATA,
    });
    dispatch({
      type: CreateOrganisationTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Organisation created successfully.',
        type: 'success',
      })
    );
  } catch (_) {
    dispatch({ type: CreateOrganisationTypes.FAILURE });
  }
};

export enum GetOrganisationMembersTypes {
  START = 'GET_ORGANISATION_MEMBER_REQUEST',
  SUCCESS = 'GET_ORGANISATION_MEMBER_SUCCESS',
  FAILURE = 'GET_ORGANISATION_MEMBER_FAILURE',
}

export const getOrganisationMembers = (
  organisationId: number,
  page?: number
): ApiAction<GetOrganisationMembersTypes> => async (dispatch) => {
  const endpoint = API.ORGANISATION_MEMBERS;
  try {
    dispatch({ type: GetOrganisationMembersTypes.START });
    const response = await http.authenticated().get(endpoint, {
      pathVariables: {
        org_id: organisationId,
      },
      params: {
        page,
      },
    });
    dispatch({
      type: GetOrganisationMembersTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (_) {
    dispatch({ type: GetOrganisationMembersTypes.FAILURE });
  }
};

export enum AddOrganisationMemberTypes {
  START = 'ADD_ORGANISATION_MEMBER_REQUEST',
  SUCCESS = 'ADD_ORGANISATION_MEMBER_SUCCESS',
  FAILURE = 'ADD_ORGANISATION_MEMBER_FAILURE',
}

export const addOrganisationMember = (
  organisationId: number,
  email: string,
  role: string,
  name: string
): ApiAction<AddOrganisationMemberTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.ORGANISATION_MEMBERS;
  const data = {
    email,
    role,
    full_name: name,
  };

  try {
    dispatch({ type: AddOrganisationMemberTypes.START });
    const response = await http.authenticated().post(endpoint, {
      data,
      pathVariables: {
        org_id: organisationId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: AddOrganisationMemberTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: response.data.message,
        type: 'success',
      })
    );
  } catch (e) {
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: e.response.data.message,
      })
    );
    dispatch({ type: AddOrganisationMemberTypes.FAILURE });
  }
};

export enum EditOrganisationMemberTypes {
  START = 'EDIT_ORGANISATION_MEMBER_REQUEST',
  SUCCESS = 'EDIT_ORGANISATION_MEMBER_SUCCESS',
  FAILURE = 'EDIT_ORGANISATION_MEMBER_FAILURE',
}

export const editOrganisationMember = (
  organisationId: number,
  accountId: number,
  role: string
): ApiAction<EditOrganisationMemberTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.ORGANISATION_MEMBERS_ACCOUNT;
  const data = {
    role,
  };
  try {
    dispatch({ type: EditOrganisationMemberTypes.START });
    const response = await http.authenticated().put(endpoint, {
      pathVariables: {
        org_id: organisationId,
        account_id: accountId,
      },
      data,
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: EditOrganisationMemberTypes.SUCCESS,
      payload: { ...response.data },
    });
    dispatch(
      notifRequested({
        title: 'SUCCESS',
        content: 'Member role updated successfully.',
        type: 'success',
      })
    );
  } catch (e) {
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: e.response.data.message,
      })
    );
    dispatch({ type: EditOrganisationMemberTypes.FAILURE });
  }
};

export enum DeleteOrganisationMemberTypes {
  START = 'DELETE_ORGANISATION_MEMBER_REQUEST',
  SUCCESS = 'DELETE_ORGANISATION_MEMBER_SUCCESS',
  FAILURE = 'DELETE_ORGANISATION_MEMBER_FAILURE',
}

export const deleteOrganisationMember = (
  organisationId: number,
  accountId: number
): ApiAction<DeleteOrganisationMemberTypes | NotificationActionTypes> => async (dispatch) => {
  const endpoint = API.ORGANISATION_MEMBERS_ACCOUNT;
  try {
    dispatch({ type: DeleteOrganisationMemberTypes.START });
    const response = await http.authenticated().delete(endpoint, {
      pathVariables: {
        org_id: organisationId,
        account_id: accountId,
      },
      contentType: ContentType.URLENCODED,
    });
    dispatch({
      type: DeleteOrganisationMemberTypes.SUCCESS,
      payload: { ...response.data, accountId },
    });
    dispatch(
      notifRequested({
        type: 'success',
        title: 'SUCCESS',
        content: response.data.message,
      })
    );
  } catch (e) {
    dispatch(
      notifRequested({
        type: 'danger',
        title: 'ERROR',
        content: e.response.data.message,
      })
    );
  }
};

export enum GetOrganisationTypes {
  START = 'GET_ORGANISATION_REQUEST',
  SUCCESS = 'GET_ORGANISATION_SUCCESS',
  FAILURE = 'GET_ORGANISATION_FAILURE',
}

export const getOrganisation = (): ApiAction<GetOrganisationTypes> => async (dispatch) => {
  const endpoint = API.ORGANISATION;
  try {
    dispatch({ type: GetOrganisationTypes.START });
    const response = await http.authenticated().get(endpoint);
    dispatch({
      type: GetOrganisationTypes.SUCCESS,
      payload: { ...response.data },
    });
  } catch (e) {
    dispatch({ type: GetOrganisationTypes.FAILURE });
  }
};

export const SET_TOUR_GUIDE_STATE = 'SET_TOUR_GUIDE_STATE';

interface TourStateParams {
  isTourGuideActive?: boolean;
  isLogoClicked?: boolean;
  isSectionTour?: boolean;
  isRequestCloseClicked?: boolean;
  isCloseTourClicked?: boolean;
  previousLocation?: string;
  currentStep?: number;
}

interface SetTourGuideAction extends Action<typeof SET_TOUR_GUIDE_STATE> {
  payload: TourStateParams;
}

export const setTourGuideState = (tourState: TourStateParams): SetTourGuideAction => ({
  type: SET_TOUR_GUIDE_STATE,
  payload: tourState,
});
