import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  LoadPaymentMethodsActionTypes,
  LoadFollowerPaymentMethodsActionTypes,
} from 'ducks/merchantDucks/agency/actions';
import PaymentMethodsState from 'ducks/merchantDucks/paymentMethods/types';

const initialState: PaymentMethodsState = {
  masterMethods: [],
  followerMethods: [],
  isFetching: false,
};

type PaymentMethodsReducer = Reducer<PaymentMethodsState>;

const loadMasterPaymentMethods: PaymentMethodsReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const setMasterPaymentMethods: PaymentMethodsReducer = (state = initialState, { payload: { methods } }) => ({
  ...state,
  masterMethods: [...methods],
  isFetching: false,
});

const loadMasterPaymentMethodsFailure: PaymentMethodsReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const loadFollowerPaymentMethods: PaymentMethodsReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const setFollowerPaymentMethods: PaymentMethodsReducer = (state = initialState, { payload: { methods } }) => ({
  ...state,
  followerMethods: [...methods],
  isFetching: false,
});

const loadFollowerPaymentMethodsFailure: PaymentMethodsReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const actionMap: ActionMap<PaymentMethodsState> = {
  [LoadPaymentMethodsActionTypes.START]: loadMasterPaymentMethods,
  [LoadPaymentMethodsActionTypes.SUCCESS]: setMasterPaymentMethods,
  [LoadPaymentMethodsActionTypes.FAILURE]: loadMasterPaymentMethodsFailure,
  [LoadFollowerPaymentMethodsActionTypes.START]: loadFollowerPaymentMethods,
  [LoadFollowerPaymentMethodsActionTypes.SUCCESS]: setFollowerPaymentMethods,
  [LoadFollowerPaymentMethodsActionTypes.FAILURE]: loadFollowerPaymentMethodsFailure,
};

export default reducerWithActionMap(actionMap, initialState);
