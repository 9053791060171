import React from 'react';
import { History } from 'history';

// components
import TourGuideCustomStep from 'components/TourGuide/TourGuideCustomStep';
import { Roles } from 'ducks/merchantDucks/user/types';
import TourGuideStep from '../TourGuideStep';

// types
import { TourTypes, TourNames, ArrowType } from './TourGuideTypes';
import { goToSection } from './index';

const sharedStyle = {
  minWidth: '500px',
};

export const analyticsTourConfig = (history: History, location: string) => [
  {
    content: (
      <TourGuideCustomStep title="Welcome to the Analytics section guided tour!">
        Analytics is where you can see an overview of your account with data such as total revenue, number of payments
        and customers, login devices used, and more.
      </TourGuideCustomStep>
    ),
    location: TourTypes.Analytics,
    action: goToSection(history, location, TourTypes.Analytics),
  },
  {
    selector: '[data-tut="reactour__analytics-subheader"] > * > *',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        Here, you can select the time period for which you are seeing the analytics data.
      </TourGuideStep>
    ),
    location: TourTypes.Analytics,
    action: goToSection(history, location, TourTypes.Analytics),
    position: 'bottom',
    restrictForRole: [Roles.CONTENT_MANAGER, Roles.CUSTOMER_SUPPORT],
  },
  {
    selector: '[data-tut="reactour__analytics-sections"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        You can click on one of these sections to see the analytics for it.
      </TourGuideStep>
    ),
    location: TourTypes.Analytics,
    action: goToSection(history, location, TourTypes.Analytics),
    style: { left: '30%' },
    restrictForRole: [Roles.CONTENT_MANAGER, Roles.CUSTOMER_SUPPORT],
  },
  {
    selector: '[data-tut="reactour__analytics-tabs"]',
    content: (
      <TourGuideStep arrowType={ArrowType.left}>
        In MAIN, you can see the basic data for the selected period and section, while the ADVANCED tab provides more
        in-depth data.
      </TourGuideStep>
    ),
    location: TourTypes.Analytics,
    action: goToSection(history, location, TourTypes.Analytics),
    restrictForRole: [Roles.CONTENT_MANAGER, Roles.CUSTOMER_SUPPORT],
  },
  {
    content: (
      <TourGuideCustomStep
        title="That’s it! You have completed the tour!"
        isLastSectionStep
        sectionLink="https://inplayer.com/docs/dashboard/analytics/"
        sectionName={TourNames.Analytics}
      />
    ),
    style: sharedStyle,
  },
];

export const assetsTourConfig = (history: History, location: string) => [
  {
    content: (
      <TourGuideCustomStep title="Welcome to the Content section guided tour!">
        Content is where you’ll be setting up all the content you’ll be monetizing. This is where you create assets,
        packages and vouchers, set up prices and access periods, and more.
      </TourGuideCustomStep>
    ),
  },
  {
    selector: '[id="assets"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        The Assets tab is the main part of this section. This is where you create the assets that will contain the
        content you want to monetize.
      </TourGuideStep>
    ),
    location: TourTypes.Assets,
    action: goToSection(history, location, TourTypes.Assets),
    position: 'bottom',
    restrictForRole: [Roles.ANALYST],
  },
  {
    selector: '[id="packages"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        In the Packages tab, you can bundle up multiple assets and sell them under one price.
      </TourGuideStep>
    ),
    location: TourTypes.Packages,
    action: goToSection(history, location, TourTypes.Packages),
    position: 'bottom',
    restrictForRole: [Roles.ANALYST],
  },
  {
    content: (
      <TourGuideCustomStep
        title="That’s it! You have completed the tour!"
        isLastSectionStep
        sectionLink="https://inplayer.com/docs/dashboard/assets/"
        sectionName={TourNames.Assets}
      />
    ),
    style: sharedStyle,
  },
];

export const vouchersTourConfig = (history: History, location: string) => [
  {
    content: (
      <TourGuideCustomStep title="Welcome to the Promotions section guided tour!">
        Promotions is where you can create discount and free access codes for your customers, as well as offer
        promotional pricing for your in-app assets.
      </TourGuideCustomStep>
    ),
  },
  {
    selector: '[id="vouchers"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        In the Vouchers section you can create and manage your discount and access vouchers.
      </TourGuideStep>
    ),
    location: TourTypes.Vouchers,
    action: goToSection(history, location, TourTypes.Vouchers),
    position: 'bottom',
    restrictForRole: [Roles.ANALYST],
  },
  {
    selector: '[id="in-app introductory offers"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        In the In-app promotional offers tab you can create temporary discounted prices for your in-app assets that will
        be available to all in-app customers.
      </TourGuideStep>
    ),
    location: TourTypes.InAppVouchers,
    action: goToSection(history, location, TourTypes.InAppVouchers),
    position: 'bottom',
    restrictForRole: [Roles.ANALYST],
  },
  {
    selector: '[id="gifts"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        In the Gift vouchers tab you can see the vouchers that were generated and sent as gifts upon a customer
        purchasing an asset as a gift for someone.
      </TourGuideStep>
    ),
    location: TourTypes.GiftVouchers,
    action: goToSection(history, location, TourTypes.GiftVouchers),
    position: 'bottom',
    restrictionFeature: 'Gifts',
    restrictForRole: [Roles.ANALYST],
  },
  {
    content: (
      <TourGuideCustomStep
        title="That’s it! You have completed the tour!"
        isLastSectionStep
        sectionLink="https://inplayer.com/docs/dashboard/assets/"
        sectionName={TourNames.Vouchers}
      />
    ),
    style: sharedStyle,
  },
];

export const audienceTourConfig = (history: History, location: string) => [
  {
    content: (
      <TourGuideCustomStep title="Welcome to the Audience section guided tour!">
        Audience is where you manage and review customer accounts. You can see customer details, grant and revoke
        access, cancel subscriptions, generate reports and more.
      </TourGuideCustomStep>
    ),
  },
  {
    selector: '[id="audience"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        In the Audience tab you can manage and review all customer accounts, grant and revoke access, cancel
        subscriptions, and much more. You can also create customer reports.
      </TourGuideStep>
    ),
    location: TourTypes.Audience,
    action: goToSection(history, location, TourTypes.Audience),
    position: 'bottom',
    restrictForRole: [Roles.CONTENT_MANAGER],
  },
  {
    selector: '[id="segmentation"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        In Segmentation, you can create reports based on predetermined criteria that will help you nurture and grow your
        customer base.
      </TourGuideStep>
    ),
    location: TourTypes.Segmentation,
    action: goToSection(history, location, TourTypes.Segmentation),
    position: 'bottom',
    restrictForRole: [Roles.CONTENT_MANAGER, Roles.CUSTOMER_SUPPORT],
  },
  {
    selector: '[id="audience reports"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        Audience reports is where you’ll find the reports you’ve created in the Audience tab.
      </TourGuideStep>
    ),
    location: TourTypes.AudienceReports,
    action: goToSection(history, location, TourTypes.AudienceReports),
    position: 'bottom',
    restrictForRole: [Roles.CONTENT_MANAGER, Roles.CUSTOMER_SUPPORT],
  },
  {
    selector: '[id="segmentation reports"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        Segmentation reports is where you’ll find the reports you’ve created in the Segmentation tab.
      </TourGuideStep>
    ),
    location: TourTypes.SegmentationReports,
    action: goToSection(history, location, TourTypes.SegmentationReports),
    position: 'bottom',
    restrictForRole: [Roles.CONTENT_MANAGER, Roles.CUSTOMER_SUPPORT],
  },
  {
    content: (
      <TourGuideCustomStep
        title="That’s it! You have completed the tour!"
        isLastSectionStep
        sectionLink="https://inplayer.com/docs/dashboard/audience/"
        sectionName={TourNames.Audience}
      />
    ),
    style: sharedStyle,
  },
];

export const paymentsTourConfig = (history: History, location: string) => [
  {
    content: (
      <TourGuideCustomStep title="Welcome to the Transactions section guided tour!">
        Transactions is where you can manage and review customer payments and subscriptions, as well as generate payment
        and subscription reports.
      </TourGuideCustomStep>
    ),
  },
  {
    selector: '[id="payments"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        The Payments tab contains info on all one-time payments, as well as the individual subscription rebills. You can
        also generate payment reports. Note that refunds can only be processed by the InPlayer support team.
      </TourGuideStep>
    ),
    location: TourTypes.Payments,
    action: goToSection(history, location, TourTypes.Payments),
    position: 'bottom',
    restrictForRole: [Roles.CONTENT_MANAGER],
  },
  {
    selector: '[id="subscriptions"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        In Subscriptions, you can manage and review customer subscriptions. You can also generate subscription reports.
      </TourGuideStep>
    ),
    location: TourTypes.Subscriptions,
    action: goToSection(history, location, TourTypes.Subscriptions),
    position: 'bottom',
    restrictForRole: [Roles.CONTENT_MANAGER],
  },
  {
    content: (
      <TourGuideCustomStep
        title="That’s it! You have completed the tour!"
        isLastSectionStep
        sectionLink="https://inplayer.com/docs/dashboard/transactions/"
        sectionName={TourNames.Payments}
      />
    ),
    style: sharedStyle,
  },
];

export const accessTourConfig = (history: History, location: string) => [
  {
    content: (
      <TourGuideCustomStep title="Welcome to the Access section guided tour!">
        Access shows you which customer has access to what, and lets you manage that access. Access reports can also be
        generated here.
      </TourGuideCustomStep>
    ),
  },
  {
    selector: '[id="access"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        In the Access tab, you can review and manage the individual accesses for each customer. Access reports can also
        be generated here.
      </TourGuideStep>
    ),
    location: TourTypes.Access,
    action: goToSection(history, location, TourTypes.Access),
    position: 'bottom',
    restrictForRole: [Roles.CONTENT_MANAGER],
  },
  {
    selector: '[id="access reports"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        The Access reports tab is where you’ll find the reports you’ve created in the Asset access tab.
      </TourGuideStep>
    ),
    location: TourTypes.AccessReports,
    action: goToSection(history, location, TourTypes.AccessReports),
    position: 'bottom',
    restrictForRole: [Roles.CONTENT_MANAGER, Roles.CUSTOMER_SUPPORT],
  },
  {
    content: (
      <TourGuideCustomStep
        title="That’s it! You have completed the tour!"
        isLastSectionStep
        sectionLink="https://inplayer.com/docs/dashboard/asset-access/"
        sectionName={TourNames.Access}
      />
    ),
    style: sharedStyle,
  },
];

export const settingsTourTourConfig = (history: History, location: string) => [
  {
    content: (
      <TourGuideCustomStep title="Welcome to the Settings section guided tour!">
        Settings is where you set up features such as social login, geo- and URL- restrictions, branding themes for your
        paywall, integrations with other platforms, and much, much more.
      </TourGuideCustomStep>
    ),
  },
  {
    selector: '[id="authentication"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        The Authentication tab contains settings related to access and restrictions. The social login option, the number
        of simultaneous logins, geo-restriction, can all be found here, and much more.
      </TourGuideStep>
    ),
    location: TourTypes.Auth,
    action: goToSection(history, location, TourTypes.Auth),
    position: 'bottom',
    restrictForRole: [Roles.ANALYST],
  },
  {
    selector: '[id="branding"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        Branding is where you can customize your paywall to match the color and style of your brand and webpage.
      </TourGuideStep>
    ),
    location: TourTypes.Branding,
    action: goToSection(history, location, TourTypes.Branding),
    position: 'bottom',
    restrictForRole: [Roles.ANALYST],
  },
  {
    selector: '[id="integrations"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        In the Integrations tab you can connect your Online Video Platform account to use content from it for your
        InPlayer assets, your In-App platform account to sell your assets via your mobile app, and more.
      </TourGuideStep>
    ),
    location: TourTypes.Integrations,
    action: goToSection(history, location, TourTypes.Integrations),
    position: 'bottom',
    restrictForRole: [Roles.ANALYST],
  },
  {
    content: (
      <TourGuideCustomStep
        title="That’s it! You have completed the tour!"
        isLastSectionStep
        sectionLink="https://inplayer.com/docs/settings/auth/"
        sectionName={TourNames.Settings}
      />
    ),
    style: sharedStyle,
  },
];

export const accountDetailsConfig = (history: History, location: string) => [
  {
    content: (
      <TourGuideCustomStep title="Welcome to the Account details section guided tour!">
        Account details is where you can review and manage the basic details for your account, but also create
        role-specific logins, as well as child accounts to your main InPlayer account.
      </TourGuideCustomStep>
    ),
  },
  {
    selector: '[id="account details"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        In Account details you can manage you basic account info, but also the Organisation feature, used for creating
        login roles with limited access to your InPlayer account.
      </TourGuideStep>
    ),
    location: TourTypes.AccountDetails,
    action: goToSection(history, location, TourTypes.AccountDetails),
    position: 'bottom',
  },
  {
    selector: '[id="inplayer relate"]',
    content: (
      <TourGuideStep arrowType={ArrowType.top}>
        InPlayer Relate is a powerful feature you can use for creating child InPlayer accounts that will be connected to
        your main account.
      </TourGuideStep>
    ),
    location: TourTypes.InPlayerRelate,
    action: goToSection(history, location, TourTypes.InPlayerRelate),
    position: 'bottom',
    restrictForRole: [Roles.ANALYST, Roles.CONTENT_MANAGER, Roles.CUSTOMER_SUPPORT],
  },
  {
    content: (
      <TourGuideCustomStep
        title="That’s it! You have completed the tour!"
        isLastSectionStep
        sectionLink="https://inplayer.com/docs/settings/auth/"
        sectionName={TourNames.AccountDetails}
      />
    ),
    style: sharedStyle,
  },
];
