import { Reducer } from 'redux';
import map from 'lodash/map';
import fromPairs from 'lodash/fromPairs';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import {
  ExternalAccountActionTypes,
  ExternalAccountDetailsActionTypes,
  DeleteExternalAccountActionTypes,
  RESET_EXTERNAL_KEYS,
  EventbriteOrganizationsActionTypes,
  EventbriteVideosActionTypes,
  CreateLiveLikeAccountActionTypes,
  DisconnectLiveLikeAccountActionTypes,
} from 'ducks/merchantDucks/externalAccount/actions';
import ExternalAccountState, { Event } from 'ducks/merchantDucks/externalAccount/types';

export const initialState: ExternalAccountState = {
  keys: {},
  eventbriteAccount: {
    organizations: {
      organizations: [],
      pagination: {
        has_more_items: false,
      },
    },
    events: {
      events: [],
      pagination: {
        has_more_items: false,
      },
    },
  },
  isSaving: false,
  isFetching: false,
  saveOvpError: false,
};

type ExternalAccountReducer = Reducer<ExternalAccountState>;

const setIsSaving = (isSaving: boolean): ExternalAccountReducer => (state = initialState) => ({
  ...state,
  isSaving,
});

const setIsFetching = (isFetching: boolean): ExternalAccountReducer => (state = initialState) => ({
  ...state,
  isFetching,
});

const saveExternalAccountError: ExternalAccountReducer = (state = initialState) => ({
  ...state,
  isSaving: false,
  saveOvpError: true,
});

const loadExternalAccountSuccess: ExternalAccountReducer = (
  state = initialState,
  { payload: { ovp_name: ovpName, ...items } }
) => {
  if (items && Object.keys(items).length) {
    try {
      const keys = map(items, (item) => [item.key_name, item.key_value]);

      return {
        ...state,
        keys: {
          ...state.keys,
          [ovpName]: {
            keys: fromPairs(keys),
          },
        },
        isFetching: false,
      };
    } catch (e) {
      return {
        ...state,
        isFetching: false,
      };
    }
  }

  return {
    ...state,
    isFetching: false,
  };
};

const deleteExternalAccountSuccess: ExternalAccountReducer = (state = initialState) => ({
  ...state,
  keys: {},
  isSaving: false,
  isFetching: false,
});

const resetExternalKeys = (state = initialState) => ({
  ...state,
  keys: {},
});

const fetchEventbriteOrganizations: ExternalAccountReducer = (state = initialState, { payload: { data, isNew } }) => ({
  ...state,
  eventbriteAccount: {
    ...state.eventbriteAccount,
    organizations: isNew
      ? { ...data.organizations }
      : {
          ...data.organizations,
          organizations: [...state.eventbriteAccount.organizations.organizations, ...data.organizations],
        },
  },
  isFetching: false,
});

const fetchEventbriteVideos: ExternalAccountReducer = (state = initialState, { payload: { data, isNew } }) => ({
  ...state,
  eventbriteAccount: {
    ...state.eventbriteAccount,
    events: isNew
      ? {
          ...data,
          events: data.events.map((event: Event) => ({
            ...event,
            id: Number(event.id),
            organization_id: Number(event.organization_id),
          })),
        }
      : {
          ...data,
          events: [...state.eventbriteAccount.events.events, ...data.events].map((event: Event) => ({
            ...event,
            id: Number(event.id),
            organization_id: Number(event.organization_id),
          })),
        },
  },
  isFetching: false,
});

const disconnectLiveLikeAccountSuccess: ExternalAccountReducer = (state = initialState, { payload: { ovpName } }) => {
  const {
    keys: { [ovpName]: _, ...keys },
  } = state;

  return {
    ...state,
    keys,
    isSaving: false,
    isFetching: false,
  };
};

const actionsMap: ActionMap<ExternalAccountState> = {
  [ExternalAccountActionTypes.SUCCESS]: setIsSaving(false),
  [ExternalAccountActionTypes.START]: setIsSaving(true),
  [ExternalAccountActionTypes.ERROR]: saveExternalAccountError,
  [ExternalAccountDetailsActionTypes.SUCCESS]: loadExternalAccountSuccess,
  [ExternalAccountDetailsActionTypes.ERROR]: setIsFetching(false),
  [ExternalAccountDetailsActionTypes.START]: setIsFetching(true),
  [DeleteExternalAccountActionTypes.SUCCESS]: deleteExternalAccountSuccess,
  [RESET_EXTERNAL_KEYS]: resetExternalKeys,
  [EventbriteOrganizationsActionTypes.START]: setIsFetching(true),
  [EventbriteOrganizationsActionTypes.ERROR]: setIsFetching(false),
  [EventbriteOrganizationsActionTypes.SUCCESS]: fetchEventbriteOrganizations,
  [EventbriteVideosActionTypes.START]: setIsFetching(true),
  [EventbriteVideosActionTypes.ERROR]: setIsFetching(false),
  [EventbriteVideosActionTypes.SUCCESS]: fetchEventbriteVideos,
  [CreateLiveLikeAccountActionTypes.START]: setIsFetching(true),
  [CreateLiveLikeAccountActionTypes.ERROR]: setIsFetching(false),
  [CreateLiveLikeAccountActionTypes.SUCCESS]: loadExternalAccountSuccess,
  [DisconnectLiveLikeAccountActionTypes.START]: setIsFetching(true),
  [DisconnectLiveLikeAccountActionTypes.ERROR]: setIsFetching(false),
  [DisconnectLiveLikeAccountActionTypes.SUCCESS]: disconnectLiveLikeAccountSuccess,
};

export default reducerWithActionMap(actionsMap, initialState);
