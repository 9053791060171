import React, { memo, FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Typography } from '@inplayer-org/inplayer-ui';

// utils
import media from 'utils/mediaQueries';

// pictures
import robot from 'assets/pics/robot.png';
import { withTopNav } from './Navigation';

// styled components
import { BlueTypography } from './SharedStyledElements';

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;

  ${media.tablet} {
    width: 100%;
    height: 100%;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    margin: 5rem 0;
  }
`;

const ErrorContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;

  ${media.tablet} {
    order: 2;
    margin-top: 1rem;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
`;

const ErrorImageContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  margin-left: 5rem;

  ${media.phone} {
    display: none;
  }

  ${media.tablet} {
    margin-top: 2rem;
    margin-left: 0;
    margin-right: inherit;
  }
`;

const StyledTitle = styled(BlueTypography)`
  font-size: 5rem;
  font-weight: ${({ theme }) => theme.font.weights.bold};
  line-height: 1;
  margin: 0 0 2rem;
`;

const StyledSubTitle = styled(BlueTypography)`
  font-size: ${({ theme }) => theme.font.sizes.extraLarge};
  margin: 0 0 1rem;
  line-height: 1.2;
`;

const StyledDescription = styled(Typography)`
  font-size: ${({ theme }) => theme.font.sizes.medium};
  margin: 0 0 0.5rem;

  ${media.phone} {
    padding: 0 1rem;
  }
`;

const StyledImage = styled.img`
  max-height: 100%;
`;

type Props = {
  title?: string;
  subtitle?: string;
  description?: string;
};

const Error: FunctionComponent<Props> = ({
  title = '404',
  subtitle = 'Looks like you got lost',
  description = 'The page you are looking for does not exist or has been moved.',
}) => {
  return (
    <ErrorWrapper>
      <ErrorContent>
        <StyledTitle variant="h1">{title}</StyledTitle>
        <StyledSubTitle variant="h3">{subtitle}</StyledSubTitle>
        <StyledDescription variant="p">{description}</StyledDescription>
        <Button as={NavLink} to="/dashboard">
          Back home
        </Button>
      </ErrorContent>
      <ErrorImageContent>
        <StyledImage alt="logo-img" src={robot} />
      </ErrorImageContent>
    </ErrorWrapper>
  );
};

export default withTopNav(memo(Error));
