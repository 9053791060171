import React, { PureComponent, ReactNode } from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

// actions
import { loginUser as loginUserAction } from 'ducks/merchantDucks/user/actions';

// components
import LoginForm from 'pages/MerchantPages/Authentication/Login/forms/Login';

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  loginUser: (email: string, password: string) => any;
}

type Props = OwnProps & DispatchProps;

export class Login extends PureComponent<Props> {
  loginUser = (data: { email: string; password: string }) => {
    const { loginUser } = this.props;
    return loginUser(data.email, data.password);
  };

  render(): ReactNode {
    return <LoginForm onSubmit={this.loginUser} />;
  }
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  loginUser: loginUserAction,
};

export default connect(null, mapDispatchToProps)(Login);
