import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Formik, FormikHelpers, Field, FormikProps, FieldArray, Form } from 'formik';
import { array, object, string } from 'yup';

// utils
import values from 'lodash/values';
import styled from 'styled-components';

// selectors
import { getPaymentSetup } from 'ducks/adminDucks/merchants/selectors';

// types
import { PaymentMethodKeyItem, ProviderItem } from 'ducks/adminDucks/merchants/types';

// styled components
import { CancelButton } from 'components/SharedStyledElements';

// components
import { Button, Grid } from '@inplayer-org/inplayer-ui';
import FieldError from 'components/Fields/FieldError';
import StyledContainer from 'components/StyledContainer';
import Select from 'components/Fields/Select';
import { FlexContainer } from 'pages/AdminPages/components/SharedStyledElements';
import ThemedMessage, { MessageVariants } from 'components/ThemedMessage';
import { setPaymentProviders } from 'ducks/adminDucks/merchants/actions';
import { closeModal } from 'ducks/merchantDucks/modal/actions';

const { Cell } = Grid;

const IconClose = styled.span`
  height: 2.375rem;
  width: 2.375rem;
  cursor: pointer;
  display: flex;
  justifty-content: center;
  align-items: center;
  &::before {
    content: '✕';
    color: ${({ theme }) => theme.palette.secondary.fontDarkGray};
  }
`;

interface GatewayOptions {
  displayName: string;
  value: number;
}

interface GatewayValues {
  method: string;
  gateway: string;
  isMethodSelected?: boolean;
  descriptionPaymentName?: string;
  gatewayOptions?: GatewayOptions[];
}

interface FormValues {
  gateways: Array<GatewayValues>;
}

interface OwnProps {
  handleCloseModal: () => void;
}

type Props = OwnProps;

const validationSchema = array().of(
  object().shape({
    method: string().required('*Required'),
    gateway: string().required('*Required'),
  })
);

const PaymentGatewayForm: FunctionComponent<Props> = ({ handleCloseModal }) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const merchantId = Number(id);
  const gatewaySample = {
    method: '',
    gateway: '',
    isMethodSelected: false,
    descriptionPaymentName: '',
    gatewayOptions: [],
  };

  const [gateways, _] = useState<Array<GatewayValues>>([gatewaySample]);

  const { paymentMethods } = useSelector(getPaymentSetup);

  const paymentMethodOptions = values(paymentMethods).map((method: PaymentMethodKeyItem) => ({
    displayName: method.name,
    value: method.id,
  }));

  const methodDescription = (descriptionPaymentName: string) => (
    <>
      {descriptionPaymentName} is a payment method which happens via direct bank transfers. Because of this, InPlayer
      can be vulnerable to fraud via customer cancelations or refunds. To avoid this risk, we strongly suggest enabling
      these payment methods only for merchants with <b>Stripe Connect.</b>
    </>
  );

  const handlePaymentMethodChange = (methodId: string, gateway: GatewayValues) => {
    const paymentMethod = values(paymentMethods).find((method: PaymentMethodKeyItem) => method.id === Number(methodId));
    const { name, providers } = paymentMethod;
    const paymentName = ['Ideal', 'Direct Debit'].includes(name) ? name : '';
    const providersOptions = values(providers).map((provider: ProviderItem) => ({
      displayName: provider.name,
      value: provider.id,
    }));
    return {
      ...gateway,
      method: methodId,
      isMethodSelected: true,
      descriptionPaymentName: paymentName,
      gatewayOptions: providersOptions,
    };
  };

  const formatSubmitData = (data: GatewayValues) => {
    const { method, gateway } = data;
    const methodId = Number(method);
    const paymentProviderId = Number(gateway);

    return { merchantId, methodId, paymentProviderId, current: true };
  };

  const onFormSubmit = async (formikValues: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    const { gateways: formikGateways } = formikValues;
    const formattedGateways = formikGateways.map((gateway) => formatSubmitData(gateway));
    dispatch(setPaymentProviders({ merchantId, gateways: formattedGateways }));
    setSubmitting(false);
    resetForm();
    dispatch(closeModal());
  };

  const handleClose = (resetForm: () => void) => () => {
    resetForm();
    handleCloseModal();
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      initialValues={{ gateways }}
    >
      {({ values: formikValues, isSubmitting, resetForm, isValid, dirty }: FormikProps<FormValues>) => {
        const disableSubmitButton = !isValid || isSubmitting || !dirty;

        return (
          <Form>
            <FieldArray name="gateways">
              {(arrayHelpers) => (
                <>
                  {formikValues.gateways &&
                    formikValues.gateways.map((g: any, i: number) => {
                      return (
                        <StyledContainer alignContent="space-around" columns={11} key={i} margin="0 0 1rem 0">
                          <Cell width={5}>
                            <Field
                              name={`gateways[${i}].method`}
                              label="Payment method"
                              component={Select}
                              defaultOption={{ displayName: 'Select payment method' }}
                              options={paymentMethodOptions}
                              handleChange={(e: any) => arrayHelpers.replace(i, handlePaymentMethodChange(e, g))}
                            />
                            <FieldError name={`gateways[${i}].method`} />
                          </Cell>
                          <Cell width={5}>
                            <Field
                              name={`gateways[${i}].gateway`}
                              label="Payment gateway"
                              component={Select}
                              defaultOption={{ displayName: 'Select payment gateway' }}
                              options={g.gatewayOptions}
                              disabled={!g.isMethodSelected}
                              handleChange={(e: any) => arrayHelpers.replace(i, { ...g, gateway: e })}
                            />
                            <FieldError name={`gateways[${i}].gateway`} />
                          </Cell>
                          <Cell width={1} height={1}>
                            {formikValues.gateways.length > 1 && (
                              <FlexContainer alignItems="flex-end" height="inherit">
                                <IconClose onClick={() => arrayHelpers.remove(i)} />
                              </FlexContainer>
                            )}
                          </Cell>
                          {g.descriptionPaymentName && (
                            <Cell width={10}>
                              <ThemedMessage variant={MessageVariants.WARNING}>
                                {methodDescription(g.descriptionPaymentName)}
                              </ThemedMessage>
                            </Cell>
                          )}
                        </StyledContainer>
                      );
                    })}
                  <Button
                    buttonModifiers={['buttonLink']}
                    type="button"
                    onClick={() => arrayHelpers.push(gatewaySample)}
                    disabled={disableSubmitButton}
                  >
                    + Add another method
                  </Button>
                </>
              )}
            </FieldArray>

            <StyledContainer columns={4} alignContent="center" justifyContent="center" margin="2rem 0 0 0">
              <Cell middle left={3}>
                <CancelButton type="button" fullWidth onClick={handleClose(resetForm)}>
                  Cancel
                </CancelButton>
              </Cell>
              <Cell middle left={4}>
                <Button type="submit" fullWidth buttonModifiers={['buttonPrimary']} disabled={disableSubmitButton}>
                  Save
                </Button>
              </Cell>
            </StyledContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PaymentGatewayForm;
