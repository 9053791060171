import React, { FunctionComponent } from 'react';
import { MapStateToProps, connect } from 'react-redux';
import { Card, Button, CardProps, Colors, AnalyticsProps } from '@inplayer-org/inplayer-ui';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

// utils
import isEmpty from 'lodash/isEmpty';

// selectors
import { getIntegrationKeysForOvp } from 'ducks/merchantDucks/externalAccount/selectors';

// types
import { OvpCategoryType } from 'pages/MerchantPages/Assets/assetTypes';
import RootState from 'ducks/RootState';

interface ButtonsWrapperProps {
  isSelected?: boolean;
}

const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
  transition: all 0.3s ease-in-out;
  height: 100%;
  opacity: ${ifProp('isSelected', '1', '0')};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;

const ButtonStyled = styled(Button)`
  width: 8rem;
  transition: all 0.3s ease;
`;

interface FullWidthCardProps {
  assetSection?: boolean;
  isSelected?: boolean;
}

const FullWidthCard = styled(Card)<CardProps & FullWidthCardProps>`
  width: 100%;
  height: 8rem;
  padding: 0;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: ${ifProp('isSelected', `0 1px 8px ${Colors.gray}`, '')};

  img {
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 4.5em;
    opacity: ${ifProp('isSelected', '0', '1')};
    transition: all 0.3s ease-in-out;
  }

  ${({ assetSection }: FullWidthCardProps) =>
    assetSection &&
    css`
      &:hover {
        img {
          opacity: 0;
        }

        ${ButtonsWrapper} {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-direction: column;
          opacity: 1;
          position: absolute;
        }
      }
    `}
`;

const CardContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  transition: transform 0.2s;
`;

interface OwnProps extends AnalyticsProps {
  logo?: string;
  hasQuickAdd?: boolean;
  ovpType?: string | undefined;
  assetSection?: boolean;
  isSelected?: boolean;
  handleOvpSelect?: (ovpType: string, buttonType: string) => void;
  isOvpIntegration?: boolean;
}

interface StateProps {
  externalAccountIntegrationKeys: any;
}

type Props = OwnProps & StateProps;

export const OvpCard: FunctionComponent<Props> = ({
  logo,
  hasQuickAdd,
  ovpType = '',
  assetSection,
  handleOvpSelect,
  isSelected,
  externalAccountIntegrationKeys,
  tag = '',
}) => {
  const onOvpSelect = (buttonType: string) => () => {
    if (handleOvpSelect && ovpType) handleOvpSelect(ovpType, buttonType);
  };

  const shouldRenderQuickAdd = () => {
    return hasQuickAdd && !isEmpty(externalAccountIntegrationKeys);
  };

  const shouldRenderSyncVideos = () => {
    return (
      (['brightcove'].includes(ovpType) || ['jwplayer'].includes(ovpType)) && !isEmpty(externalAccountIntegrationKeys)
    );
  };

  return (
    <FullWidthCard assetSection={assetSection} isSelected={isSelected}>
      <CardContentContainer>
        <img alt="logo" src={logo} />
        <ButtonsWrapper isSelected={isSelected}>
          <ButtonStyled
            tag={(assetSection && `box_add_${tag}`) || ''}
            onClick={onOvpSelect(OvpCategoryType.AddOneVideo)}
            size="xs"
            buttonModifiers={['buttonLink']}
          >
            add one video
          </ButtonStyled>
          {shouldRenderQuickAdd() && (
            <ButtonStyled
              tag={`box_quick_add_${tag}`}
              size="xs"
              onClick={onOvpSelect(OvpCategoryType.QuickAdd)}
              buttonModifiers={['buttonLink']}
            >
              quick add
            </ButtonStyled>
          )}
          {shouldRenderSyncVideos() && (
            <ButtonStyled
              tag={`box_sync_all_${tag}`}
              size="xs"
              onClick={onOvpSelect(OvpCategoryType.SyncVideos)}
              buttonModifiers={['buttonLink']}
            >
              sync all videos
            </ButtonStyled>
          )}
        </ButtonsWrapper>
      </CardContentContainer>
    </FullWidthCard>
  );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state, ownProps) => ({
  externalAccountIntegrationKeys: getIntegrationKeysForOvp(ownProps.ovpType)(state),
});

export default connect(mapStateToProps)(OvpCard);
