import { Reducer } from 'redux';

import reducerWithActionMap, { ActionMap } from 'utils/reducers';
import { LoadPaymentsActionTypes, LoadPaymentDetailsActionTypes } from 'ducks/merchantDucks/reports/actions';
import {
  FetchPaymentAnalyticsTypes,
  FetchAssetPaymentPerCountryTypes,
  FetchPaymentMethodsPerCountryTypes,
} from 'ducks/merchantDucks/payments/actions';
import PaymentsState from 'ducks/merchantDucks/payments/types';

export const initialState: PaymentsState = {
  collection: [],
  total: 0,
  page: 0,
  limit: 15,
  offset: 0,
  isFetching: false,
  unique_paying_customers: 0,
  paymentAnalytics: {
    per_method: {
      'Direct Debit': { total: 0, total_amount_per_currency: [] },
      Card: { total: 0, total_amount_per_currency: [] },
      PayPal: { total: 0, total_amount_per_currency: [] },
      'Amazon In-App': { total: 0, total_amount_per_currency: [] },
      'Roku In-App': { total: 0, total_amount_per_currency: [] },
      Ideal: { total: 0, total_amount_per_currency: [] },
      'Apple In-App': { total: 0, total_amount_per_currency: [] },
      'Google In-App': { total: 0, total_amount_per_currency: [] },
      Wirecard: { total: 0, total_amount_per_currency: [] },
    },
    total: 0,
  },
  paymentDetails: {
    payment_history_id: 0,
    merchant_id: 0,
    consumer_id: 0,
    trx_token: '',
    payment_tool_info: '',
    payment_tool_token: '',
    payment_method_name: '',
    gateway_name: '',
    action_type: '',
    item_access_id: 0,
    item_id: 0,
    item_type: '',
    item_title: '',
    charged_amount: 0,
    currency_iso: '',
    issued_by: 0,
    created_at: '',
    note: '',
    consumer_email: '',
    timestamp: 0,
    ip_address: '',
    continent: '',
    country: '',
    country_iso: '',
    voucher_code: '',
    voucher_discount: 0,
    expires_at: '',
    access_fee_description: '',
    exchange_rate: 0,
    settlement_currency: '',
    client_id: '',
    consumer_name: '',
    refunds: [],
    isFetching: false,
    device: {
      type: '',
      os: '',
      model: '',
    },
    gift: {
      buyer_email: '',
      code: '',
      receiver_email: '',
      id: 0,
    },
  },
  assetPaymentPerCountry: {
    collection: [],
    isFetching: false,
  },
  paymentDetailsPerCountry: {
    isFetching: false,
    perCountry: undefined,
    total: 0,
  },
};

type PaymentsReducer = Reducer<PaymentsState>;

const loadPaymentsSuccess: PaymentsReducer = (state = initialState, { payload }) => ({
  ...state,
  ...payload,
  isFetching: false,
});

const loadPaymentsFailure: PaymentsReducer = (state = initialState) => ({
  ...state,
  isFetching: false,
});

const loadPayments: PaymentsReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const loadPaymentDetailsSuccess: PaymentsReducer = (state = initialState, { payload }) => ({
  ...state,
  paymentDetails: {
    ...state.paymentDetails,
    ...payload,
    id: payload.payment_history_id,
    isFetching: false,
  },
});

const loadPaymentDetails: PaymentsReducer = (state = initialState) => ({
  ...state,
  paymentDetails: {
    ...state.paymentDetails,
    isFetching: true,
  },
});

const loadPaymentDetailsFailure = (state = initialState) => ({
  ...state,
  paymentDetails: {
    ...state.paymentDetails,
    isFetching: false,
  },
});

const fetchPaymentAnalyticsRequest: PaymentsReducer = (state = initialState) => ({
  ...state,
  isFetching: true,
});

const fetchPaymentAnalyticsSuccess: PaymentsReducer = (state = initialState, { payload: { payment_analytics } }) => ({
  ...state,
  paymentAnalytics: payment_analytics,
  isFetching: false,
});

const fetchPaymentAnalyticsFailure: PaymentsReducer = (state = initialState) => ({
  ...state,
  paymentAnalytics: initialState.paymentAnalytics,
  isFetching: false,
});

const fetchAssetPaymentPerCountryRequest: PaymentsReducer = (state = initialState) => ({
  ...state,
  assetPaymentPerCountry: {
    isFetching: true,
    collection: [],
  },
});

const fetchAssetPaymentPerCountrySuccess: PaymentsReducer = (state = initialState, { payload }) => ({
  ...state,
  assetPaymentPerCountry: {
    isFetching: false,
    collection: payload.collection,
  },
});

const fetchAssetPaymentPerCountryFailure: PaymentsReducer = (state = initialState) => ({
  ...state,
  assetPaymentPerCountry: {
    isFetching: false,
    collection: [],
  },
});

const fetchPaymentMethodsPerCountryRequest: PaymentsReducer = (state = initialState) => ({
  ...state,
  paymentDetailsPerCountry: {
    ...state.paymentDetailsPerCountry,
    isFetching: true,
  },
});

const fetchPaymentMethodsPerCountrySuccess: PaymentsReducer = (state = initialState, { payload }) => ({
  ...state,
  paymentDetailsPerCountry: {
    isFetching: false,
    total: payload.total,
    perCountry: payload.per_country,
  },
});

const fetchPaymentMethodsPerCountryFailure: PaymentsReducer = (state = initialState) => ({
  ...state,
  paymentDetailsPerCountry: {
    ...state.paymentDetailsPerCountry,
    isFetching: false,
  },
});

const actionsMap: ActionMap<PaymentsState> = {
  [LoadPaymentsActionTypes.SUCCESS]: loadPaymentsSuccess,
  [LoadPaymentsActionTypes.ERROR]: loadPaymentsFailure,
  [LoadPaymentsActionTypes.START]: loadPayments,
  [LoadPaymentDetailsActionTypes.SUCCESS]: loadPaymentDetailsSuccess,
  [LoadPaymentDetailsActionTypes.START]: loadPaymentDetails,
  [LoadPaymentDetailsActionTypes.ERROR]: loadPaymentDetailsFailure,
  [FetchPaymentAnalyticsTypes.START]: fetchPaymentAnalyticsRequest,
  [FetchPaymentAnalyticsTypes.SUCCESS]: fetchPaymentAnalyticsSuccess,
  [FetchPaymentAnalyticsTypes.FAILURE]: fetchPaymentAnalyticsFailure,
  [FetchAssetPaymentPerCountryTypes.START]: fetchAssetPaymentPerCountryRequest,
  [FetchAssetPaymentPerCountryTypes.SUCCESS]: fetchAssetPaymentPerCountrySuccess,
  [FetchAssetPaymentPerCountryTypes.FAILURE]: fetchAssetPaymentPerCountryFailure,
  [FetchPaymentMethodsPerCountryTypes.START]: fetchPaymentMethodsPerCountryRequest,
  [FetchPaymentMethodsPerCountryTypes.SUCCESS]: fetchPaymentMethodsPerCountrySuccess,
  [FetchPaymentMethodsPerCountryTypes.FAILURE]: fetchPaymentMethodsPerCountryFailure,
};

export default reducerWithActionMap(actionsMap, initialState);
