/**
 * Needed because styled-components does not offer another way to set the Theme interface,
 * so Typescript will throw errors.
 * Use import styled from 'styled-components' to use styled-components.
 */
import * as styledComponents from 'styled-components';
import { Theme } from '@inplayer-org/inplayer-ui';

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>;

export { css, createGlobalStyle, keyframes, ThemeProvider, withTheme };
export default styled;
