import RootState from 'ducks/RootState';
import { ItemType } from 'ducks/merchantDucks/items/actions';
import orderBy from 'lodash/orderBy';

export const getVoucherDetails = (state: RootState) => state.vouchers.voucherDetails;

export const getAvailableItems = (state: RootState) => state.vouchers.rules.excluded.collection;

export const getTotalAvailableItems = (state: RootState) => state.vouchers.rules.excluded.total;

export const getAddedItems = (state: RootState) => state.vouchers.rules.included.collection;

export const getTotalAddedItems = (state: RootState) => state.vouchers.rules.included.total;

export const getIsFetchingExcluded = (state: RootState) => state.vouchers.rules.isFetchingExcluded;

export const getIsFetchingIncluded = (state: RootState) => state.vouchers.rules.isFetchingIncluded;

export const getAssetAccessFees = (state: RootState) =>
  state.vouchers.accessFees.filter((item) => item.item.item_type.name !== ItemType.Package);

export const getPackageAccessFees = (state: RootState) =>
  state.vouchers.accessFees.filter((item) => item.item.item_type.name === ItemType.Package);

export const getIsFetchingAccessFees = (state: RootState) => state.vouchers.accessFeesFetching;

export const getVouchers = (state: RootState) => state.vouchers;

export const getIsFetching = (state: RootState) => state.vouchers.isFetching;

export const getFirstTotal = (state: RootState) => state.vouchers.firstTotal;

export const getBulkEmailErrorFiles = (state: RootState) =>
  orderBy(getVoucherDetails(state).errorFiles, ['createdAt'], ['desc']);

export const getGiftVoucherDetails = (state: RootState) => state.vouchers.giftVoucherDetails;
