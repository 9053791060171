export const vouchers = {
  VOUCHERS: 'vouchers',
  VOUCHER_DETAILS: 'vouchers/{id}',
  RULES: 'items/{voucherId}/voucher',
  VOUCHER_RULE: 'vouchers/{id}/rules/{ruleType}',
  DELETE_VOUCHER_RULE: 'vouchers/{id}/rules/{ruleId}',
  VOUCHER_ACCESS_TYPE: 'vouchers/{id}/rules/access-types',
  UPDATE_VOUCHER: 'v2/vouchers/{id}',
  VOUCHER_EMAILS: 'vouchers/{id}/rules/emails',
  ACCESS_FEES_RULES: 'v2/vouchers/{voucher_id}/item/{item_id}/rules/access-fees',
  ITEM_RULE: 'v2/vouchers/{voucher_id}/item/{item_id}',
  BULK_EMAILS: 'v2/vouchers/{voucher_id}/rules/bulk/emails',
  GET_EMAIL_TEMPLATE: 'v2/vouchers/bulk/template/{template}',
  GET_SIGNED_URL: 'v2/vouchers/rules/bulk/signed-url',
  GET_BULK_FAILED_LIST: 'v2/vouchers/bulk/{action}/failed/list',
  DOWNLOAD_FAILED_BULK_FILE: 'v2/vouchers/bulk/{action}/failed/download/{file_name}',
  GIFT_VOUCHERS: 'v2/vouchers/gifts',
  GIFT_VOUCHER_DETAILS: 'v2/vouchers/gifts/{id}',
};
