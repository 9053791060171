import React, { FunctionComponent, useState } from 'react';
import { object, string, ref } from 'yup';
import { Formik, FormikProps, Field, FormikHelpers } from 'formik';
import { Grid } from '@inplayer-org/inplayer-ui';

// utils
import { onPassChangeValidate } from 'utils/validatePassword';

// Components
import Input from 'pages/MerchantPages/Authentication/components/Input';
import FieldError, { ErrorMessageWrapperSpan } from 'components/Fields/FieldError';
import Button from 'pages/MerchantPages/Authentication/components/Button';
import { Optional } from 'utils/types';
import { StyledNavLink, ButtonWrapper } from 'pages/MerchantPages/Authentication/components/sharedComponents';
import FormContainer from '../../components/FormContainer';

const { Container, Cell } = Grid;

interface FormValues {
  token: string;
  password: string;
  password_confirmation: string;
}

interface OwnProps {
  onSubmit: (data: { password: string; password_confirmation: string; token: string }) => any;
  token: Optional<string>;
  organization: Optional<string>;
}

type Props = OwnProps;

const validationSchema = object().shape({
  token: string().required('*Required'),
  password: string().required('*Required'),
  password_confirmation: string()
    .required('*Required')
    .oneOf([ref('password'), ''], 'Passwords do not match'),
});

export const NewPasswordForm: FunctionComponent<Props> = ({ onSubmit, token, organization }) => {
  const [validPassword, setValidPassword] = useState(false);
  const [passwordWarning, setPasswordWarning] = useState('');

  const onFormSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    await onSubmit(values);
    setSubmitting(false);
  };

  const headingText = organization ? `Welcome to the ${organization}` : undefined;

  const initialValues = {
    token: token || '',
    password: '',
    password_confirmation: '',
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onFormSubmit} validationSchema={validationSchema}>
      {({ handleSubmit, isSubmitting, setFieldValue, errors }: FormikProps<FormValues>) => (
        <FormContainer
          title={headingText || 'New Password'}
          description={
            organization
              ? `You have been invited as a member to the ${organization} organization. Please fill in the form below to complete your registration`
              : 'Enter your new password'
          }
          paddingTop="5rem"
        >
          <form onSubmit={handleSubmit}>
            <Container alignContent="space-around" columns={1} gap="1em">
              <Cell>
                <Field name="token" type="text" component={Input} placeholder="Token" />
                <FieldError name="token" />
              </Cell>
              <Cell>
                <Field
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onPassChangeValidate(e, setFieldValue, setPasswordWarning, setValidPassword)
                  }
                  name="password"
                  type="password"
                  component={Input}
                  placeholder="Password"
                />
                {errors.password ? (
                  <FieldError name="password" />
                ) : (
                  !validPassword && <ErrorMessageWrapperSpan>{passwordWarning}</ErrorMessageWrapperSpan>
                )}
              </Cell>
              <Cell>
                <Field name="password_confirmation" type="password" component={Input} placeholder="Confirm Password" />
                <FieldError name="password_confirmation" />
              </Cell>
              <Cell center>
                <StyledNavLink to="/login">Back to login</StyledNavLink>
              </Cell>
              <ButtonWrapper textAlign="center">
                <Button
                  buttonModifiers={['buttonPrimary']}
                  type="submit"
                  disabled={isSubmitting || !validPassword}
                  padding="1rem 2.75rem"
                >
                  Submit
                </Button>
              </ButtonWrapper>
            </Container>
          </form>
        </FormContainer>
      )}
    </Formik>
  );
};

export default NewPasswordForm;
