import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

// Actions
import { openModal } from 'ducks/merchantDucks/modal/actions';
import { resetMailingListData, fetchAdminMailingList, putMailCategory } from 'ducks/adminDucks/paginations/actions';

// HOC
import { withTopNav } from 'components/Navigation';

// Types
import { ModalNames } from 'ducks/merchantDucks/modal/types';
import { ResultKeys, MailingListTableDetails, MailingListDetails } from 'ducks/adminDucks/paginations/types';

// Utils
import { transformSearchParams } from 'utils/helpers';

// Hooks
import { useAdminItemsState } from 'pages/AdminPages/hooks';

// Styled components
import {
  TableContainer,
  HeaderContainer,
  FlexContainer,
  Heading,
} from 'pages/AdminPages/components/SharedStyledElements';

// Components
import { TableColumn, TableColumn$RenderProps, RowActionsRender, Option, Button } from '@inplayer-org/inplayer-ui';
import GenericTable from 'components/Table/GenericTable';
import ContentHolder from 'components/ContentHolder';
import Filter from 'components/Filter';
import ExtendedDropdown from 'components/ExtendedDropdown';
import ConfirmModal from 'components/Modals/ConfirmModal';
import SearchTooltip from 'components/SearchTooltip';
import TableClipBoardIcon from 'components/Table/TableClipBoardIcon';
import MailingListItemModal from './MailingListItemModal';

const AddButton = styled(Button)`
  width: 10rem;
`;

interface SearchParams {
  email: string;
}

const dropdownOptions: Array<Option> = [
  { value: 'true', displayName: 'blacklisted' },
  { value: 'false', displayName: 'whitelisted' },
];

const suggestions: Array<Option> = [{ value: 'email', displayName: 'Email' }];

const generateColumns = (): Array<TableColumn<MailingListTableDetails>> => {
  const columns: Array<TableColumn<MailingListTableDetails>> = [
    {
      title: 'Email',
      key: 'email',
      render: ({ value }) => <TableClipBoardIcon clipboardText={value}>{value}</TableClipBoardIcon>,
    },
    {
      title: 'Status',
      key: 'blacklisted',
      render: ({ value }: TableColumn$RenderProps<MailingListTableDetails, string>) =>
        value ? 'Blacklisted' : 'Whitelisted',
    },
  ];

  return columns;
};

export const AdminMailingList = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [isMerchantBlacklisted, setIsMerchcantBlacklisted] = useState(true);
  const [email, setEmail] = useState('');
  const [emailForEdit, setEmailForEdit] = useState({ blacklisted: false, email: '' });

  const { itemsData: mailingListData, totalItemsCount: totalMailingListCount, isFetching } = useAdminItemsState<
    MailingListDetails,
    MailingListTableDetails
  >(page - 1, ResultKeys.MAILING_LIST);

  useEffect(
    () => () => {
      dispatch(resetMailingListData());
    },
    []
  );

  useEffect(() => {
    if (!mailingListData.length) {
      dispatch(fetchAdminMailingList({ page: page - 1, limit: 15, blacklisted: isMerchantBlacklisted, email }));
    }
  }, [page]);

  const handleStatusChange = (status: string) => {
    setIsMerchcantBlacklisted(status === 'true');
    setPage(1);

    dispatch(fetchAdminMailingList({ page: 0, limit: 15, blacklisted: status === 'true', email }, true));
  };

  const handleFilterChange = (searchParams: SearchParams) => {
    const transformedParams = transformSearchParams(searchParams);
    const newEmail = transformedParams[0]?.split(':')[1];

    dispatch(fetchAdminMailingList({ page: 0, limit: 15, blacklisted: isMerchantBlacklisted, email: newEmail }, true));
    setEmail(newEmail);
    setPage(1);
  };

  const handleOpenMailingModal = (blacklisted: boolean, newEmail: string) => {
    setEmailForEdit({ blacklisted, email: newEmail });
    dispatch(openModal(ModalNames.Mails));
  };

  const handleOpenMailingItemModal = () => {
    dispatch(openModal(ModalNames.AddMailingItem));
  };

  const handleEditEmail = async () => {
    await dispatch(putMailCategory(emailForEdit.email, emailForEdit.blacklisted));
    await dispatch(fetchAdminMailingList({ page: 0, limit: 15, blacklisted: isMerchantBlacklisted, email }, true));
    setPage(1);
  };

  const renderTableHeaderSection = () => {
    return (
      <HeaderContainer>
        <SearchTooltip isAdmin />
        <div>
          <Filter options={suggestions} onSearch={handleFilterChange} />
        </div>
        <div>
          <ExtendedDropdown
            fullWidth
            options={dropdownOptions}
            value={isMerchantBlacklisted.toString()}
            onChange={handleStatusChange}
          />
        </div>
      </HeaderContainer>
    );
  };

  const renderRowActions: RowActionsRender<MailingListTableDetails> = ({ row }) => {
    return (
      <a
        data-testid={row.blacklisted ? 'whitelist-button' : 'blacklist-button'}
        href="#/"
        onClick={(e) => {
          e.preventDefault();
          handleOpenMailingModal(!row.blacklisted, row.email);
        }}
      >
        {row.blacklisted ? 'Whitelist record' : 'Blacklist record'}
      </a>
    );
  };

  return (
    <ContentHolder>
      <FlexContainer justifyContent="space-between" margin="5rem 0 0">
        <Heading variant="h1">Mailing List</Heading>
        <AddButton data-testid="add-item-button" onClick={handleOpenMailingItemModal}>
          Add item
        </AddButton>
      </FlexContainer>
      <TableContainer>
        <GenericTable<MailingListTableDetails>
          hasBottomPagination
          data={mailingListData}
          total={totalMailingListCount}
          columns={generateColumns()}
          showLoader={isFetching}
          handlePageClick={setPage}
          currentPage={page}
          pageRangeDisplayed={3}
          options={{
            rowActions: renderRowActions,
            headerSection: renderTableHeaderSection(),
          }}
          actionsRowTitle="Edit"
        />
      </TableContainer>
      <ConfirmModal
        modalName={ModalNames.Mails}
        message={`Are you sure you want to ${emailForEdit.blacklisted ? 'blacklist' : 'whitelist'} this record?`}
        buttonText={emailForEdit.blacklisted ? 'blacklist' : 'whitelist'}
        handleConfirm={handleEditEmail}
      />
      <MailingListItemModal />
    </ContentHolder>
  );
};

export default withTopNav(AdminMailingList);
